/* eslint-disable max-nested-callbacks */
import { countBy, every, groupBy, round, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import { PARAMETER_NATURES } from '../../../referencial/components/parameter/constants/ParameterConstants'
import { getLabel } from '../../../utils/StoreUtils'
import { hasValue } from 'utils/NumberUtil'
import useActions from 'utils/customHook/useActions'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import { Grid } from '@mui/material'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'

const CustomTable = ({
    referenceThreshold,
    limitThreshold,
    label = '',
    producerIds = [],
    microbioData = {},
    pcData = {},
}) => {
    const {
        producers,
        thresholds,
    } = useSelector(store => ({
        producers: store.ContributorReducer.producers,
        thresholds: store.QualityReducer.thresholds,
    }))

    const nbColThreshold = !!hasValue(referenceThreshold) + !!hasValue(limitThreshold)

    return (
        <table>
            <thead>
                <tr>
                    {hasValue(referenceThreshold) && <th rowSpan='2' className='center-align width-10 aepTableCell'>{getLabel(thresholds, referenceThreshold)}</th>}
                    {hasValue(limitThreshold) && <th rowSpan='2' className='center-align width-10 aepTableCell'>{getLabel(thresholds, limitThreshold)}</th>}
                    {
                        producerIds.map(id => (<th colSpan={3} className='center-align width-10 aepTableCell' >{getLabel(producers, id, 'mnemonique', 'id') || i18n.unknownProducer}</th>))
                    }
                </tr>
                <tr>
                    {
                        producerIds.map(() => (
                            <>
                                <td className='center-align width-10 aepTableCell padding-1' >&nbsp;&nbsp;{label}&nbsp;&nbsp;</td>
                                <td className='center-align width-10 aepTableCell padding-1' >&nbsp;&nbsp;{i18n.nonConform}&nbsp;&nbsp;</td>
                                <td className='center-align width-10 aepTableCell padding-1' >&nbsp;&nbsp;{i18n.conformity}&nbsp;&nbsp;</td>
                            </>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={nbColThreshold} className='center-align aepTableCell' >{i18n.microbiological}</td>
                    {
                        producerIds.map(id => {
                            const key = `${id}`
                            return (
                                <>
                                    <td className='center-align aepTableCell'>{microbioData[key]?.nbTotal ?? ''}</td>
                                    <td className={`center-align aepTableCell ${microbioData[key]?.nbNonConform ? 'red' : ''}`}>{microbioData[key]?.nbNonConform ?? ''}</td>
                                    <td className='center-align aepTableCell'>{microbioData[key]?.percent ?? ''}</td>
                                </>
                            )
                        })
                    }
                </tr>
                <tr>
                    <td colSpan={nbColThreshold} className='center-align aepTableCell' >{i18n.physicochemic}</td>
                    {
                        producerIds.map(id => {
                            const key = `${id}`
                            return (
                                <>
                                    <td className='center-align aepTableCell'>{pcData[key]?.nbTotal ?? ''}</td>
                                    <td className={`center-align aepTableCell ${pcData[key]?.nbNonConform ? 'red' : ''}`}>{pcData[key]?.nbNonConform ?? ''}</td>
                                    <td className='center-align aepTableCell'>{pcData[key]?.percent ?? ''}</td>
                                </>
                            )
                        })
                    }
                </tr>
            </tbody>
        </table>
    )
}

CustomTable.propTypes = {
    label: PropTypes.string,
    producerIds: PropTypes.arrayOf(PropTypes.number),
    microbioData: PropTypes.objectOf(PropTypes.shape({
        nbData: PropTypes.number,
        nbNonConform: PropTypes.number,
        percent: PropTypes.string,
    })),
    pcData: PropTypes.objectOf(PropTypes.shape({
        nbData: PropTypes.number,
        nbNonConform: PropTypes.number,
        percent: PropTypes.string,
    })),
    referenceThreshold: PropTypes.number,
    limitThreshold: PropTypes.number,
}

const NATURES_MICROBIO = [PARAMETER_NATURES.MICROBIOLOGICAL]
const NATURES_PC = [PARAMETER_NATURES.CHEMICAL, PARAMETER_NATURES.PHYSICAL]

const AEPoverviewPanel = ({
    filter,
    onExport,
    thresholdList = [],
    operations = [],
    analysis = [],
}) => {
    const {
        thresholds,
        producers,
        parametersIndex,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
        producers: store.ContributorReducer.producers,
        parametersIndex: store.ParameterReducer.parametersIndex,
    }))

    const formatedAnalysis = useMemo(() => {
        return analysis.filter(a => thresholdList.some(t => !t.parameterCode || t.parameterCode === a.parameter))
    }, [analysis, thresholdList])

    const producerIds = useMemo(() => {
        if (hasValue(filter.producer)) {
            return [filter.producer]
        }
        return uniqBy(analysis, 'producer').map(a => a.producer)
    }, [analysis, filter.producer])

    const groupAnalysis = useMemo(() => {
        return groupBy(formatedAnalysis, a => `${a.qualitometer}:${a.operation}`)
    }, [formatedAnalysis])

    const groupOperationsProducer = useMemo(() => {
        return groupBy(operations, a => a.producer)
    }, [operations])

    const calculateDataSample = useCallback((opeList, natures) => {
        const { conform = 0, notConform = 0 } = countBy(opeList, operation => {
            const key = `${operation.qualitometer}:${operation.id}`
            const {
                [key]: analysisList = [],
            } = groupAnalysis
            const filteredAnalysis = analysisList.filter(a => {
                const param = parametersIndex[a.parameter]
                return param && natures.includes(parseInt(param.typeParam))
            })
            if (!filteredAnalysis.length) {
                return 'noData'
            }
            if (every(filteredAnalysis, a => a.color !== 'red')) {
                return 'conform'
            }
            return 'notConform'
        })
        const total = conform + notConform
        return {
            nbTotal: total,
            nbNonConform: notConform,
            percent: total ? `${round(conform * 100 / total, 1)} %` : '0 %',
        }
    }, [groupAnalysis, parametersIndex])

    const calculateDataAnalysis = useCallback((analysisList, natures) => {
        const filteredAnalysis = analysisList.filter(a => {
            const param = parametersIndex[a.parameter]
            return param && natures.includes(parseInt(param.typeParam))
        })
        const { conform = 0, notConform = 0 } = countBy(filteredAnalysis, a => {
            if (a.color === 'red') {
                return 'notConform'
            }
            return 'conform'
        })
        const total = conform + notConform
        return {
            nbTotal: total,
            nbNonConform: notConform,
            percent: total ? `${round(conform * 100 / total, 1)} %` : '0 %',
        }
    }, [parametersIndex])

    useActions(() => ({
        exportList: [{
            onClick: () => {
                const headers = ['year', 'nature', 'producer', 'nbSample', 'nonConformPLV', 'plvConformity', 'nbAnalysis', 'nonConformAnalysis', 'analysisConformity']
                const data = producerIds.flatMap(id => {
                    const base = {
                        producer: { value: getLabel(producers, id) || i18n.unknownProducer },
                        year: { value: filter.year, format: '0', cellType: 'number' },
                    }
                    const {
                        [id]: listOperations = [],
                    } = groupOperationsProducer
                    const listAnalysis = listOperations.flatMap(o => groupAnalysis[`${o.qualitometer}:${o.id}`] || [])

                    const microbioDataSample = calculateDataSample(listOperations, NATURES_MICROBIO)
                    const microbioDataAnalysis = calculateDataAnalysis(listAnalysis, NATURES_MICROBIO)

                    const pcDataSample = calculateDataSample(listOperations, NATURES_PC)
                    const pcDataAnalysis = calculateDataAnalysis(listAnalysis, NATURES_PC)
                    return [{
                        ...base,
                        nbSample: { value: microbioDataSample.nbTotal, format: '0', cellType: 'number' },
                        nonConformPLV: { value: microbioDataSample.nbNonConform, format: '0', cellType: 'number' },
                        plvConformity: { value: microbioDataSample.percent, cellType: 'right' },
                        nbAnalysis: { value: microbioDataAnalysis.nbTotal, format: '0', cellType: 'number' },
                        nonConformAnalysis: { value: microbioDataAnalysis.nbNonConform, format: '0', cellType: 'number' },
                        analysisConformity: { value: microbioDataAnalysis.percent, cellType: 'right' },
                        nature: { value: i18n.microbiological },
                    }, {
                        ...base,
                        nbSample: { value: pcDataSample.nbTotal, format: '0', cellType: 'number' },
                        nonConformPLV: { value: pcDataSample.nbNonConform, format: '0', cellType: 'number' },
                        plvConformity: { value: pcDataSample.percent, cellType: 'right' },
                        nbAnalysis: { value: pcDataAnalysis.nbTotal, format: '0', cellType: 'number' },
                        nonConformAnalysis: { value: pcDataAnalysis.nbNonConform, format: '0', cellType: 'number' },
                        analysisConformity: { value: pcDataAnalysis.percent, cellType: 'right' },
                        nature: { value: i18n.physicochemic },
                    }]
                })
                onExport({
                    data: data.length ? [{ ...data[0], headers }, ...data.slice(1)] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.AEPoverview,
                })
            },
            label: i18n.excel,
        }],
    }), [calculateDataAnalysis, calculateDataSample, filter.limitThreshold, filter.referenceThreshold, filter.year, groupAnalysis, producerIds, producers, thresholds])

    const formatDataSample = useCallback(natures => {
        return producerIds.reduce((acc, id) => {
            const {
                [id]: listOperations = [],
            } = groupOperationsProducer
            acc[`${id}`] = calculateDataSample(listOperations, natures)
            return acc
        }, {})
    }, [producerIds, groupOperationsProducer, calculateDataSample])

    const microbioDataSample = useMemo(() => {
        return formatDataSample(NATURES_MICROBIO)
    }, [formatDataSample])

    const pcDataSample = useMemo(() => {
        return formatDataSample(NATURES_PC)
    }, [formatDataSample])

    const formatDataAnalysis = useCallback(natures => {
        return producerIds.reduce((acc, id) => {
            const {
                [id]: listOperations = [],
            } = groupOperationsProducer
            const listAnalysis = listOperations.flatMap(o => groupAnalysis[`${o.qualitometer}:${o.id}`] || [])
            acc[`${id}`] = calculateDataAnalysis(listAnalysis, natures)
            return acc
        }, {})
    }, [producerIds, groupOperationsProducer, calculateDataAnalysis, groupAnalysis])

    const microbioDataAnalysis = useMemo(() => {
        return formatDataAnalysis(NATURES_MICROBIO)
    }, [formatDataAnalysis])

    const pcDataAnalysis = useMemo(() => {
        return formatDataAnalysis(NATURES_PC)
    }, [formatDataAnalysis])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card title={i18n.nbSample}>
                    <div className={'scrollable-card'}>
                        <CustomTable
                            label={i18n.plv}
                            producerIds={producerIds}
                            microbioData={microbioDataSample}
                            pcData={pcDataSample}
                            referenceThreshold={filter.referenceThreshold}
                            limitThreshold={filter.limitThreshold}
                        />
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card title={i18n.nbAnalysis}>
                    <div className={'scrollable-card'}>
                        <CustomTable
                            label={i18n.analysis}
                            producerIds={producerIds}
                            microbioData={microbioDataAnalysis}
                            pcData={pcDataAnalysis}
                            referenceThreshold={filter.referenceThreshold}
                            limitThreshold={filter.limitThreshold}
                        />
                    </div>
                </Card>
            </Grid>
        </Grid>
    )
}

AEPoverviewPanel.propTypes = {
    filter: PropTypes.shape({
        referenceThreshold: PropTypes.number,
        limitThreshold: PropTypes.number,
        year: PropTypes.number,
        producer: PropTypes.number,
    }),
    onExport: PropTypes.func,
    thresholdList: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysisLight
        // calculateThresholdResult
        producer: PropTypes.number,
    })),
}

export default AEPoverviewPanel
