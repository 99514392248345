import {
    RECEIVE_ALL_VARIOUS_MATERIEL_SITUATIONS,
    RECEIVE_ALL_VARIOUS_MATERIELS,
    RECEIVE_MAT_EVENTS_EXPLOITATION,
    RECEIVE_MAT_EVENTS_TYPE,
    RECEIVE_VARIOUS_MATERIEL,
    RECEIVE_VARIOUS_MATERIEL_FILES,
    RECEIVE_VARIOUS_MATERIEL_PICTURES,
    RECEIVE_VARIOUS_MATERIEL_TYPES,
    RECEIVE_VARIOUS_MATERIELS_LAST_SITUATIONS,
    RESET_VARIOUS_MATERIEL,
} from '../constants/VariousMaterielConstants'
import ApplicationConf from 'conf/ApplicationConf'
import {
    checkAuth,
    checkAuthWithoutKicking,
    checkError,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../../components/sieau/SieauAction'
import { hasValue } from '../../../../utils/NumberUtil'
import fetch from 'isomorphic-fetch'
import { push } from 'connected-react-router'
import { setMessageModal } from '../../../../utils/FormUtils'
import LogAction from '../../../../log/actions/LogAction'

const VariousMaterielAction = {

    fetchAllVariousMatsAndSituations: () => dispatch => Promise.all([
        VariousMaterielAction.promiseVariousMateriels(),
        VariousMaterielAction.promiseVariousMaterielsLastSituations(),
        VariousMaterielAction.promiseMatEventsTypes(),
    ]).then(jsonTab => {
        dispatch(VariousMaterielAction.receiveVariousMateriels(jsonTab[0]))
        dispatch(VariousMaterielAction.receiveVariousMaterielLastSituations(jsonTab[1]))
        dispatch(VariousMaterielAction.receiveMatEventsTypes(jsonTab[2]))
    }),

    receiveVariousMateriels(variousMateriels) {
        return { type: RECEIVE_ALL_VARIOUS_MATERIELS, variousMateriels }
    },

    promiseVariousMateriels() {
        return fetch(ApplicationConf.materiel.variousMateriels(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchVariousMateriels() {
        return (dispatch) => {
            return VariousMaterielAction.promiseVariousMateriels()
                .then((json = []) => {
                    dispatch(VariousMaterielAction.receiveVariousMateriels(json))
                    dispatch(SieauAction.receiveProps('variousMateriels'))
                    if (json.length) {
                        dispatch(VariousMaterielAction.fetchVariousMaterielsLastSituations())
                    }
                })
        }
    },

    receiveVariousMateriel(variousMateriel) {
        return { type: RECEIVE_VARIOUS_MATERIEL, variousMateriel }
    },

    promiseVariousMateriel(id) {
        return fetch(ApplicationConf.materiel.variousMateriel(id), {
            headers: getAuthorization(),
        })
            .then(checkAuthWithoutKicking)
            .then(getJson)
            .then(checkError)
    },

    fetchVariousMateriel: id => dispatch => {
        return VariousMaterielAction.promiseVariousMateriel(id)
            .then((json = []) => {
                dispatch(VariousMaterielAction.receiveVariousMateriel(json))
                return json
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.variousMateriel} : ${e}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.variousMateriel))
            })
    },

    receiveVariousMaterielTypes(variousMaterielTypes) {
        return { type: RECEIVE_VARIOUS_MATERIEL_TYPES, variousMaterielTypes }
    },

    promiseVariousMaterielTypes() {
        return fetch(ApplicationConf.materiel.variousMaterielTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchVariousMaterielTypes() {
        return (dispatch) => {
            return VariousMaterielAction.promiseVariousMaterielTypes()
                .then((json = {}) => {
                    dispatch(VariousMaterielAction.receiveVariousMaterielTypes(json))
                })
        }
    },

    updateVariousMaterielTypes(id, materielType) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMaterielType(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(VariousMaterielAction.fetchVariousMaterielTypes())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielType))
                })
        }
    },

    deleteVariousMaterielType(id, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMaterielType(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(r => checkAuth(r, {
                    206: () => setMessageModal(i18n.deletingUsedMaterielTypeMessage),
                }))
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(VariousMaterielAction.fetchVariousMaterielTypes())
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.materielType))
                })
        }
    },

    addVariousMaterielTypes(materielType, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMaterielTypes(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(VariousMaterielAction.fetchVariousMaterielTypes())
                        dispatch(ToastrAction.success(i18n.addSuccess))
                        callback({ ...materielType, materielType: json.id })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.materielType))
                })
        }
    },

    receiveVariousMaterielSituations(variousMaterielSituations) {
        return { type: RECEIVE_ALL_VARIOUS_MATERIEL_SITUATIONS, variousMaterielSituations }
    },

    fetchVariousMaterielSituations(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMaterielSituations(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(VariousMaterielAction.receiveVariousMaterielSituations(json))
                })
        }
    },

    saveVariousMaterielSituation(situation, shouldFetch = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.addVariousMaterielSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(VariousMaterielAction.fetchVariousMaterielsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationAddSuccess))
                    if (shouldFetch) {
                        dispatch(VariousMaterielAction.fetchVariousMaterielSituations(situation.idVarious))
                    }
                })
        }
    },

    createAllVariousMaterielSituation: (situations = []) => dispatch => fetch(ApplicationConf.materiel.variousSituations(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(situations),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => {
            if (json.insert >= 0) {
                dispatch(VariousMaterielAction.fetchVariousMaterielsLastSituations())
                dispatch(ToastrAction.success(i18n.situationAddSuccess))
                return
            }
            throw new Error()
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.createError} ${err.message}`))
            dispatch(ToastrAction.error(i18n.createError))
        }),

    deleteVariousMaterielSituation(id, idVarious) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.deleteVariousMaterielSituation(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(() => {
                    dispatch(ToastrAction.success(i18n.situationDeleteSuccess))
                    if (idVarious) {
                        dispatch(VariousMaterielAction.fetchVariousMaterielsLastSituations())
                        dispatch(VariousMaterielAction.fetchVariousMaterielSituations(idVarious))
                    }
                })
        }
    },

    updateVariousMaterielSituation(idSituation, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.saveVariousMaterielSituation(idSituation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(VariousMaterielAction.fetchVariousMaterielsLastSituations())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                    if (openCampaginModal) {
                        openCampaginModal(situation)
                    }
                })
        }
    },
    createEventAndUpdateSituation(urlEvent, event, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(urlEvent, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            }).then(getJson)
                .then((json) => {
                    if (json.eventId) {
                        const result = Object.assign({}, situation, {
                            eventCode: json.eventId,
                        })
                        dispatch(VariousMaterielAction.updateVariousMaterielSituation(result.id, result))
                        if (openCampaginModal) {
                            dispatch(openCampaginModal(result))
                        }
                    } else {
                        dispatch(ToastrAction.error(i18n.saveError))
                    }
                })
        }
    },

    updateVariousMateriel(variousMateriel) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMateriel(variousMateriel.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(variousMateriel),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(VariousMaterielAction.fetchVariousMateriels(true))
                        dispatch(VariousMaterielAction.fetchVariousMateriel(variousMateriel.id))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        return true
                    }
                    throw new Error()
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.variousMateriel)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    createVariousMateriel(variousMateriel, noRedirection) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMateriels(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(variousMateriel),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert >= 1 && hasValue(json.id)) {
                        if (!noRedirection) {
                            dispatch(push(`/materiel/variousMateriel/${json.id}`))
                        }
                        dispatch(VariousMaterielAction.fetchVariousMateriels(true))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        return json.id
                    }
                    throw new Error()
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.variousMateriel)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    deleteVariousMateriel(id, callback) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMateriel(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete > 0) {
                        dispatch(VariousMaterielAction.fetchVariousMateriels(true))
                        if (callback) {
                            callback()
                        } else {
                            dispatch(push('/materiel/variousMateriel'))
                        }
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.variousMateriel} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.variousMateriel))
                })
        }
    },

    promiseVariousMaterielSituation(id) {
        return fetch(ApplicationConf.materiel.variousMaterielSituations(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    receiveVariousMaterielLastSituations(variousMaterielsLastSituations) {
        return { type: RECEIVE_VARIOUS_MATERIELS_LAST_SITUATIONS, variousMaterielsLastSituations }
    },

    promiseVariousMaterielsLastSituations: () => fetch(ApplicationConf.materiel.variousMaterielsLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchVariousMaterielsLastSituations: () => dispatch => VariousMaterielAction.promiseVariousMaterielsLastSituations()
        .then((json = []) => {
            dispatch(VariousMaterielAction.receiveVariousMaterielLastSituations(json))
        }),

    receiveVariousMaterielPictures(variousMaterielPictures) {
        return {
            type: RECEIVE_VARIOUS_MATERIEL_PICTURES,
            variousMaterielPictures,
        }
    },

    fetchVariousMaterielPictures(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMaterielPicture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(VariousMaterielAction.receiveVariousMaterielPictures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                })
        }
    },

    receiveVariousMaterielFiles(variousMaterielFiles) {
        return {
            type: RECEIVE_VARIOUS_MATERIEL_FILES,
            variousMaterielFiles,
        }
    },

    fetchVariousMaterielFiles(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.variousMaterielFile(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(VariousMaterielAction.receiveVariousMaterielFiles(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
                })
        }
    },

    receiveMatEventsByExploitation(matEventsExploitation) {
        return { type: RECEIVE_MAT_EVENTS_EXPLOITATION, matEventsExploitation }
    },

    promiseMatEventsByExploitation(exploitationId) {
        return fetch(ApplicationConf.materiel.eventsExploitation(exploitationId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
    },

    fetchMatEventsByExploitation(exploitationId) {
        return (dispatch) => {
            return VariousMaterielAction.promiseMatEventsByExploitation(exploitationId)
                .then((json = []) => {
                    dispatch(VariousMaterielAction.receiveMatEventsByExploitation(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.events))
                })
        }
    },

    receiveMatEventsTypes(matEventsTypes) {
        return { type: RECEIVE_MAT_EVENTS_TYPE, matEventsTypes }
    },

    promiseMatEventsTypes() {
        return fetch(ApplicationConf.materiel.eventsTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
    },

    fetchMatEventsTypes() {
        return (dispatch) => {
            return VariousMaterielAction.promiseMatEventsTypes()
                .then((json = []) => {
                    dispatch(VariousMaterielAction.receiveMatEventsTypes(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.types} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.types))
                })
        }
    },

    createMatEvent(event, callback = () => {}) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.events(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            })
                .then(checkAuth)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.eventAddSuccess))
                    callback()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.event))
                })
        }
    },

    updateMatEvent(event, callback = () => {}) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.event(event.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            })
                .then(checkAuth)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.eventUpdateSuccess))
                    callback()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.event))
                })
        }
    },

    deleteMatEvent(eventId, callback = () => {}) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.event(eventId), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.eventDeleteSuccess))
                    callback()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.event))
                })
        }
    },

    resetVariousMateriel() {
        return {
            type: RESET_VARIOUS_MATERIEL,
        }
    },
}

export default VariousMaterielAction
