import React, { useMemo, useState } from 'react'
import { push } from 'connected-react-router'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import HomeAction from '../../../../home/actions/HomeAction'
import SectorAction from '../actions/SectorAction'
import SearchTable from 'referencial/components/SearchTable'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { REFERENCIAL_TYPE_NAME, SANDRE } from 'referencial/constants/ReferencialConstants'
import { PATH_REFERENCIAL, PATH_REFERENCIAL_SECTOR, PATH_REFERENCIAL_SECTOR_NEW } from 'home/constants/RouteConstants'
import { getSandreLabel } from 'utils/StringUtil'
import { getLabel } from 'utils/StoreUtils'

const headers = ['id', 'name', 'comment', 'x', 'y', 'projection', 'administrator', 'managementMode']

const SectorsApp = () => {
    const {
        sectors,
        contributors,
        sandreCodes,
    } = useSelector(store => ({
        sectors: store.SectorReducer.sectors,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SectorAction.fetchSectors())
        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.sectors,
        href: PATH_REFERENCIAL_SECTOR,
    }], [])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_SECTOR_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.sectors,
                    },
                },
            },
        }
    }, [exportData])

    const data = useMemo(() => {
        return sectors.map(s => ({
            ...s,
            projection: getSandreLabel(sandreCodes, SANDRE.PROJECTION, s.typeprojection),
            managementMode: getSandreLabel(sandreCodes, SANDRE.MODE_GESTION, s.managementMode),
            administrator: getLabel(contributors, s.administratorCode),
        }))
    }, [contributors, sectors, sandreCodes])

    return (
        <SearchTable
            title={i18n.sectors}
            sessionKey={REFERENCIAL_TYPE_NAME.sector}
            data={data}
            setExportData={setExportData}
            headers={headers}
            onClick={sector => dispatch(push(`/referencial/sector/${sector.id}/dashboard`))}
        />
    )
}

export default SectorsApp
