import React, { useEffect, useState } from 'react'
import Input from 'components/forms/Input'
import CartographyPanel from 'components/map/CartographyPanel'
import ProgressBar from 'components/progress/ProgressBar'
import Row from 'components/react/Row'
import { find } from 'lodash'
import { useMemo } from 'react'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import AdministrationAction from '../../../../administration/actions/AdministrationAction'
import Card from '../../../../components/card/Card'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import SieauAction from '../../../../components/sieau/SieauAction'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    LINK_REFERENCIAL_CONTACT,
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CONTACT,
    PATH_REFERENCIAL_CONTACT_NEW,
} from '../../../../home/constants/RouteConstants'
import ReferentialAction from '../../../action/ReferencialAction'
import CityAction from '../../city/actions/CityAction'
import CountryAction from '../../country/actions/CountryAction'
import ReferencialAppList from '../../ReferencialAppList'
import ContactAction from '../actions/ContactAction'
import { CONTACT, GROUP, MAP } from '../constants/ContactConstants'
import ContactsGroupsApp from './ContactsGroupsApp'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import { push } from 'connected-react-router'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'

const ContactMapPanel = () => {
    const {
        contacts,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    useActions(() => ({}), [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.map,
        href: PATH_REFERENCIAL_CONTACT,
    }], [])

    return (
        <div style={{ padding: '5px 10px' }}>
            <CartographyPanel layers={['STATIONS_POINTS']}
                componentType='all'
                stationsPoints={contacts}
                stationsPanelTitle={i18n.contacts}
                heightToSubstract={450}
            />
        </div>
    )
}

const ContactsPanel = () => {
    const {
        applicationSettings,
        contactsTable,
        cities,
        countries,
        referencialSandreCodes,
        globalResearch,
        contacts,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
        contactsTable: store.ContactReducer.contactsTable,
        cities: store.CityReducer.cities,
        countries: store.CountryReducer.countries,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        globalResearch: store.HomeReducer.globalResearch,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const [filter, setFilter] = useLocalStorage(`referencial_${REFERENCIAL_TYPE_NAME.contact}`, {})
    const [dataLoaded, setDataLoaded] = useState(false)

    const dispatch = useDispatch()

    const fetchData = () => dispatch(ContactAction.fetchContactsTable({
        ...filter,
        countryCode: filter.countrySelected,
        city: filter.citySelected?.id,
    }))

    useEffect(() => {
        dispatch(HomeAction.setHelpLink('', ''))
        fetchData().then(() => setDataLoaded(true))

        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
        if (!countries.length) {
            dispatch(CountryAction.fetchCountries())
        }
        if (!referencialSandreCodes.length) {
            dispatch(ReferentialAction.fetchReferencialSandreCodes())
        }
        if (globalResearch) {
            setFilter({ ...filter, searchValue: globalResearch })
            dispatch(HomeAction.updateGlobalResearch(''))
        }
        if (!applicationSettings.length) {
            dispatch(AdministrationAction.fetchApplicationSettings())
        }
        if (!contacts.length) {
            dispatch(ContactAction.fetchContacts())
        }
    }, [])

    const onResetFilters = () => {
        setFilter({})
        dispatch(ContactAction.fetchContactsTable())
    }

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'CONTACTS'), [referencialSandreCodes])

    const contactsTableFormatted = useMemo(() => {
        setDataLoaded(true)
        return contactsTable.map(o => ({
            ...o,
            nullValue: o.isUser ? <i className='material-icons small' style={{ lineHeight: '19px' }}>person</i> : '',
        }))
    }, [contactsTable])

    const internationalContext = useMemo(() => applicationSettings.find(a => a.parameter === 'ContexteInternational'), [applicationSettings])
    const isInternationalContext = useMemo(() => internationalContext ? internationalContext.value.toLowerCase() === 'oui' : false, [internationalContext])
    const countryCodeHeader = useMemo(() => isInternationalContext ? ['country'] : [], [isInternationalContext])
    const headers = useMemo(() => ['nullValue', 'name', 'postalCode', 'city', 'phoneTel', 'desktopTel', 'mobile', 'email', 'socialReason', ...countryCodeHeader], [countryCodeHeader])

    const exportData = useMemo(() => getDataWithHeaders(contactsTableFormatted, headers), [contactsTableFormatted, headers])

    return (
        <div className='row'>
            <Card title={i18n.filters} className='row margin-left-1 margin-right-1' contentClassName='margin-top-2' noMargin={ false } >
                <Row>
                    <Input
                        col={8}
                        title={ i18n.search }
                        value={ filter.searchValue }
                        onChange={ (value) => setFilter({ ...filter, searchValue: value }) }
                        onEnterKeyPress={fetchData}
                    />
                </Row>
                <Row>
                    {isInternationalContext && (<Select
                        col={3}
                        label={i18n.country}
                        options={countries}
                        value={filter.countrySelected}
                        keyValue='countryCode'
                        keyLabel='name'
                        onChange={value => setFilter({ ...filter, countrySelected: value })}
                    />)}
                    <Select
                        col={isInternationalContext ? 3 : 4}
                        options={ cities }
                        label={ i18n.city }
                        onChange={(_, value) => setFilter({ ...filter, citySelected: value })}
                        value={ filter.citySelected?.id }
                    />
                </Row>
                <Row>
                    <div className='row no-margin padding-top-1'>
                        <div className='col s6'>
                            <Checkbox
                                col={3}
                                label={i18n.admin}
                                checked={filter.isAdmin}
                                onChange={value => setFilter({ ...filter, isAdmin: value })}
                            />
                            <Checkbox
                                col={3}
                                label={i18n.administrator}
                                checked={filter.metadata}
                                onChange={value => setFilter({ ...filter, metadata: value })}
                            />
                            <Checkbox
                                col={3}
                                label={i18n.technicien}
                                checked={filter.labo}
                                onChange={value => setFilter({ ...filter, labo: value })}
                            />
                            <Checkbox
                                col={3}
                                label={i18n.consultant}
                                checked={filter.consultant}
                                onChange={value => setFilter({ ...filter, consultant: value })}
                            />
                        </div>
                        <div className='col s6'>
                            <a className='margin-1 waves-effect waves-teal center btn-flat primary-button right' onClick={fetchData}>
                                {i18n.search}
                            </a>
                            <a className='margin-1 waves-effect waves-teal center btn-flat secondary-button right' onClick={onResetFilters}>
                                {i18n.reinit}
                            </a>
                        </div>
                    </div>
                </Row>
            </Card>
            <div className='margin-top-1 margin-bottom-1'>
                {dataLoaded ? (
                    <ReferencialAppList
                        lastUpdate={ lastUpdate }
                        title={ i18n.contactsList }
                        data={ contactsTable }
                        type={{ headers }}
                        link={ LINK_REFERENCIAL_CONTACT }
                        action={ ContactAction.fetchContacts }
                        newAction={ () => dispatch(push(PATH_REFERENCIAL_CONTACT_NEW)) }
                        setTitleAction={ SieauAction.forceFetch('title', [{
                            title: i18n.referencials,
                            href: PATH_REFERENCIAL,
                        }, {
                            title: i18n.contacts,
                            href: PATH_REFERENCIAL_CONTACT,
                        }]) }
                        actions={{
                            export: {
                                data: exportData,
                                titleFile: i18n.contactsList,
                            },
                            purge: {
                                onPurge: () => dispatch(ContactAction.purgeContact()),
                            },
                        }}
                        showUpdateButton={false}
                        showPurgeButton={false}
                        showExportButton={false}
                        searchable={false}
                    />
                ) : (
                    <Row className='padding-1'>
                        <ProgressBar indeterminate />
                    </Row>
                )}
            </div>
        </div>
    )
}

const ContactsApp = () => {
    const [panel, setPanel] = useState(CONTACT)

    const onChangePanel = (newPanel) => {
        if (panel !== newPanel) {
            setPanel(newPanel)
        }
    }

    return (
        <div className='row no-margin'>
            <div className='col-s12 margin-left-1 margin-right-1'>
                <ul className='tabs tabs-fixed-width'>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(CONTACT) }>{ i18n.contacts }</a>
                    </li>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(GROUP) }>{ i18n.contactsGroups }</a>
                    </li>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(MAP) }>{ i18n.map }</a>
                    </li>
                </ul>
            </div>
            { panel === CONTACT && <ContactsPanel /> }
            { panel === GROUP && <ContactsGroupsApp /> }
            { panel === MAP && <ContactMapPanel />}
        </div>
    )
}

export default ContactsApp
