import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Table from 'components/datatable/Table'
import i18n from 'simple-react-i18n'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { PRODUCTION_UNIT_LINK_TYPES } from 'productionUnit/constants/ProductionUnitConstants'
import ProdLinkDialog from './ProdLinkDialog'
import { push } from 'connected-react-router'
import { getNewStationTypeNameFromTypeCode } from 'utils/StationUtils'
import { useDispatch } from 'react-redux'

const ProdUnitLinkType = ({
    data = {},
    linkType,
    readMode = false,
    dispatchStateLinks = () => {},
}) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const params = (() => {
        switch (linkType) {
            case PRODUCTION_UNIT_LINK_TYPES.RESOURCES:
                return {
                    title: i18n.resources,
                    headers: ['nullLabel', 'code', 'type', 'name', 'nbFollowPoints'],
                    filterTypes: ['resource'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.MONITORING:
                return {
                    title: i18n.monitoring,
                    headers: ['nullLabel', 'code', 'nature', 'city', 'name'],
                    filterTypes: ['quality', 'piezometry', 'hydrometry'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.PRODUCTION:
                return {
                    title: i18n.production,
                    headers: ['nullLabel', 'code', 'nature', 'city', 'name'],
                    filterTypes: ['quality', 'piezometry', 'hydrometry', 'installation'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.JOB:
                return {
                    title: i18n.job,
                    headers: ['nullLabel', 'code', 'nature', 'city', 'name'],
                    filterTypes: ['quality', 'installation'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.DISTRIBUTION:
                return {
                    title: i18n.distributionUnit,
                    headers: ['nullLabel', 'code', 'nature', 'city', 'name'],
                    filterTypes: ['quality'],
                }
            case PRODUCTION_UNIT_LINK_TYPES.UDI:
                return {
                    title: i18n.distributionUnitName,
                    headers: ['nullLabel', 'code', 'nature', 'sector', 'cityNumber', 'subscriberNumber'],
                    filterTypes: ['distributionUnit'],
                }
            default:
                return {}
        }
    })()

    const actions = [readMode && {
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: () => {},
    } || {
        iconName: 'edit',
        tooltip: i18n.export,
        onClick: () => setIsOpen(true),
    }]

    return (
        <>
            <Table
                title={params.title}
                data={data}
                type={{ headers: params.headers }}
                customHeaders={{ nullLabel: ' ' }}
                condensed sortable paging
                nbPerPageLabel={nbPerPageLabelShort}
                actions={actions}
                onClick={(d) => {
                    dispatch(push(`/station/${getNewStationTypeNameFromTypeCode(d.siteType)}/${d.siteCode}`))
                }}
            />
            {isOpen && (
                <ProdLinkDialog
                    links={data}
                    open={isOpen}
                    setOpen={setIsOpen}
                    filterTypes={params.filterTypes}
                    linkType={linkType}
                    title={params.title}
                    dispatchStateLinks={dispatchStateLinks}
                />
            )}
        </>
    )
}

ProdUnitLinkType.propTypes = {
    data: PropTypes.array,
    linkType: PropTypes.number.isRequired,
    readMode: PropTypes.bool,
    stateLinks: PropTypes.array,
    dispatchStateLinks: PropTypes.func,
}

export default ProdUnitLinkType