import fetch from 'isomorphic-fetch'
import ApplicationConf from 'conf/ApplicationConf'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { checkAuth, checkError, getAuthorization, getJson } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_RESOURCES,
    RECEIVE_RESOURCE,
    RESET_RESSOURCE,
} from 'resource/constants/ResourceConstants'
import { push } from 'connected-react-router'
import {
    PATH_RESOURCE,
} from 'home/constants/RouteConstants'
import LogAction from 'log/actions/LogAction'

const ResourceAction = {
    receiveResources(resources) {
        return { type: RECEIVE_RESOURCES, payload: resources }
    },

    promiseResources: () => fetch(ApplicationConf.resource.resources(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchResources: () => dispatch => ResourceAction.promiseResources()
        .then((json = []) => {
            dispatch(ResourceAction.receiveResources(json))
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.resources} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.resources))
        }),

    receiveResource(resource) {
        return { type: RECEIVE_RESOURCE, payload: resource }
    },

    fetchResource(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.resource.resource(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = {}) => {
                    dispatch(ResourceAction.receiveResource(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.resource} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.resource))
                })
        }
    },

    createResource(resource, callback = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.resource.resources(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(resource),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                    if (json.insert === 1 && json.id) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ResourceAction.fetchResource(json.id))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.resource} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.resource))
                })
        }
    },

    updateResource(resource) {
        return (dispatch) => {
            return fetch(ApplicationConf.resource.resources(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(resource),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(ResourceAction.fetchResource(resource.id))
                        dispatch(ToastrAction.success(i18n.resourceSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.resource} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.resource))
                })
        }
    },

    deleteResource(id) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.resource.resource(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_RESOURCE))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.resource} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.resource))
                })
        }
    },

    reset() {
        return { type: RESET_RESSOURCE }
    },
}

export default ResourceAction
