import PcMonitoring from 'quality/components/pcMonitoring/PcMonitoring'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import useTitle from 'utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { hasValue } from 'utils/NumberUtil'

const CatchmentPcMonitoringApp = ({
    match,
}) => {
    const {
        catchment,
        qualitometers,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const { id } = match.params

    useTitle(() => [{
        title: i18n.catchment,
        href: 'catchment',
    }, {
        title: catchment.code || i18n.unknown,
        href: `station/catchment/${id}`,
    }, {
        title: i18n.followUpPC,
        href: `station/catchment/${id}/pcMonitoring`,
    }], [catchment])

    const ids = useMemo(() => {
        const qualito = catchment.catchmentPoints?.map(point => qualitometers.find(p => p.code === point.codeWithoutDesignation)) || []
        return qualito.filter(q => hasValue(q)).map(q => q.id)
    }, [catchment.catchmentPoints, qualitometers])

    return (
        <PcMonitoring
            ids={ids}
        />
    )
}

CatchmentPcMonitoringApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default CatchmentPcMonitoringApp