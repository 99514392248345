import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import Icon from '../../../components/icon/Icon'
import DtoStation from '../../../home/dto/DtoStation'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoCentral from '../../../station/dto/materiel/DtoCentral'
import DtoPowerSupply from '../../../station/dto/materiel/DtoPowerSupply'
import DtoSensor from '../../../station/dto/materiel/DtoSensor'
import DtoSim from '../../../station/dto/materiel/DtoSim'
import DtoTelecom from '../../../station/dto/materiel/DtoTelecom'
import DtoVariousMateriel from '../../../station/dto/materiel/DtoVariousMateriel'
import AppStore from '../../../store/AppStore'
import { getMaterielIconValue, getMaterielsFromType } from '../../../utils/MaterielUtils'
import { getStations } from '../../../utils/StationUtils'
import { getLabel } from '../../../utils/StoreUtils'
import MaterielAction from '../../actions/MaterielAction'
import { POWER_SUPPLY_PROBLEM } from '../../constants/MaterielConstants'
import MaterielTypeDto from '../../dto/MaterielTypeDto'
import MonitoredMaterielDto from '../../dto/MonitoredMaterielDto'
import CentralAction from '../central/actions/CentralAction'
import EquipmentAction from '../equipment/actions/EquipmentAction'
import EquipmentDto from '../equipment/dto/EquipmentDto'
import PowerSupplyAction from '../powerSupply/actions/PowerSupplyAction'
import SensorAction from '../sensor/actions/SensorAction'
import SimAction from '../sim/actions/SimAction'
import TelecomAction from '../telecom/actions/TelecomAction'
import VariousMaterielAction from '../variousMateriel/actions/VariousMaterielAction'

class MonitoredMaterielPortletPanel extends Component {
    componentDidMount() {
        if (!this.props.materielTypes.length) {
            this.props.fetchMaterielTypes()
        }
        if (!this.props.powerSupplies.length && this.props.monitoredMateriels.find(m => m.typeMateriel === 'POWER_SUPPLY')) {
            this.props.fetchPowerSupplies()
        }
        if (!this.props.centrals.length && this.props.monitoredMateriels.find(m => m.typeMateriel === 'CENTRAL')) {
            this.props.fetchCentrals()
        }
        if (!this.props.sensors.length && this.props.monitoredMateriels.find(m => m.typeMateriel === 'SENSOR')) {
            this.props.fetchSensors()
        }
        if (!this.props.telecoms.length && this.props.monitoredMateriels.find(m => m.typeMateriel === 'TELECOM')) {
            this.props.fetchTelecoms()
        }
        if (!this.props.sims.length && this.props.monitoredMateriels.find(m => m.typeMateriel === 'SIM')) {
            this.props.fetchSims()
        }
        if (!this.props.variousMateriels.length && this.props.monitoredMateriels.find(m => m.typeMateriel === 'VARIOUS')) {
            this.props.fetchVariousMateriels()
        }
        if (!this.props.equipments.length && this.props.monitoredMateriels.find(m => m.typeMateriel === 'EQUIPMENT')) {
            this.props.fetchEquipments()
        }
    }

    getPowerSupplyProblem = () => {
        return <div className='valign-wrapper'><Icon icon='battery_alert' style={{ color: 'orangeRed' }} /><div className='padding-left-1'/>{ i18n.changeBattery }</div>
    }

    render() {
        const monitored = this.props.allStationType ? this.props.monitoredMateriels : this.props.monitoredMateriels.filter(m => m.typeSite === this.props.stationType)
        if (monitored.length) {
            const tableData = monitored.map(m => {
                const station = this.props.allStationType ? getStations(this.props, m.typeSite).find(s => s.id === m.idSite) || {} : getStations(this.props, this.props.stationType).find(s => s.id === m.idSite) || {}
                const materiel = getMaterielsFromType(this.props, m.typeMateriel).find(mat => mat.id === m.id) || {}
                if (station.id && materiel.id) {
                    const { label = '' } = this.props.materielTypes.find(({ code }) => code === m.typeMateriel) || {}
                    return {
                        ...m,
                        station: `[${station.code}${station.designation ? `/${station.designation}` : ''}] ${station.name}`,
                        materiel: (<div className='valign-wrapper'><Icon style={{ color: 'grey' }} icon={ getMaterielIconValue(m.typeMateriel) } tooltip={ label }/>
                            <div className='padding-left-1'/>{ label + (materiel.serialNumber || materiel.imei ? ` - ${materiel.serialNumber || materiel.imei}` : '') }</div>),
                        description: m.eventComment === POWER_SUPPLY_PROBLEM ? this.getPowerSupplyProblem() : m.eventComment || m.eventProblem,
                    }
                }
                return null
            }).filter(m => !!m)
            if (tableData.length) {
                return (<div>
                    { !this.props.noHeader &&
                        <Table
                            showTitle={this.props.showTitle}
                            title={ (<a><i className='waves-effect material-icons'>router</i>{ i18n.monitoredMateriels }</a>) }
                            onClick={ v => AppStore.dispatch(push(`/materiel/${getLabel(this.props.materielTypes, v.typeMateriel, 'link')}/${v.id}`)) }
                            data={ tableData }
                            type={ { headers: ['station', 'materiel', 'description'] } }
                            condensed
                            sortable
                        />
                    }
                    { this.props.noHeader &&
                        <Table
                            showNbElements={false}
                            showTitle={false}
                            showIcon={true}
                            headerIcon={
                                <a>
                                    <i className='waves-effect material-icons'>router</i>
                                </a>
                            }
                            onClick={v => AppStore.dispatch(push(`/materiel/${getLabel(this.props.materielTypes, v.typeMateriel, 'link')}/${v.id}`)) }
                            data={ tableData }
                            type={ { headers: ['station', 'materiel', 'description'] } }
                            condensed
                            sortable
                        />
                    }
                </div>
                )
            }
            return null
        }
        return null
    }
}

MonitoredMaterielPortletPanel.propTypes = {
    monitoredMateriels: PropTypes.arrayOf(PropTypes.instanceOf(MonitoredMaterielDto)),
    stationType: PropTypes.number,
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    productionUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnit)),
    centrals: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentral)),
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupply)),
    sensors: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensor)),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(DtoSim)),
    telecoms: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecom)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    equipments: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentDto)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    showTitle: PropTypes.bool,
    allStationType: PropTypes.bool,
    noHeader: PropTypes.bool,
    fetchMaterielTypes: PropTypes.func,
    fetchPowerSupplies: PropTypes.func,
    fetchCentrals: PropTypes.func,
    fetchSensors: PropTypes.func,
    fetchTelecoms: PropTypes.func,
    fetchSims: PropTypes.func,
    fetchVariousMateriels: PropTypes.func,
    fetchEquipments: PropTypes.func,
}

MonitoredMaterielPortletPanel.defaultProps = {
    noHeader: false,
}

const mapStateToProps = store => ({
    monitoredMateriels: store.MaterielReducer.monitoredMateriels,
    qualitometers: store.QualityReducer.qualitometersLight,
    piezometers: store.PiezometryReducer.piezometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    pluviometers: store.PluviometryReducer.pluviometers,
    installations: store.InstallationReducer.installations,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    centrals: store.CentralReducer.centrals,
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    sensors: store.SensorReducer.sensors,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    sims: store.SimReducer.sims,
    telecoms: store.TelecomReducer.telecoms,
    equipments: store.EquipmentReducer.equipments,
    materielTypes: store.MaterielReducer.materielTypes,
})

const mapDispatchToProps = {
    fetchMaterielTypes: MaterielAction.fetchMaterielTypes,
    fetchPowerSupplies: PowerSupplyAction.fetchPowerSupplies,
    fetchCentrals: CentralAction.fetchCentrals,
    fetchSensors: SensorAction.fetchSensors,
    fetchTelecoms: TelecomAction.fetchTelecoms,
    fetchSims: SimAction.fetchSims,
    fetchVariousMateriels: VariousMaterielAction.fetchVariousMateriels,
    fetchEquipments: EquipmentAction.fetchEquipments,
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoredMaterielPortletPanel)