/* eslint-disable camelcase */
import { Button, Divider, Grid } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import { setActions } from 'components/ActionUtil'
import Card from 'components/card/Card'
import Icon from 'components/icon/Icon'
import CartographyPanel from 'components/map/CartographyPanel'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import HomeAction from 'home/actions/HomeAction'
import { compact, groupBy, omit, orderBy, uniq, uniqWith } from 'lodash'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import i18n from 'simple-react-i18n'
import EvolVolumesChartPanel from 'survey/components/panels/EvolVolumesChartPanel'
import { getDate } from 'utils/DateUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { hasValue } from 'utils/NumberUtil'
import { getUser } from 'utils/SettingUtils'
import { formatMilliers, getI18nTitleData } from 'utils/StringUtil'
import AgriAction from '../../agriAdministration/actions/AgriAction'
import AddConsoModal from './modal/AddConsoModal'
import MatEventModal from './modal/MatEventModal'

const GridItem = styled(Grid)({
    borderBottom: '1px solid grey',
    padding: '8px 16px',
    cursor: 'pointer',
    backgroundColor: 'white',
})

const Element = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
})

const useStyles = makeStyles({
    textLeft: {
        textAlign: 'end',
    },
    textRight: {
        textAlign: 'end',
        fontWeight: 'bold',
    },
})

const TYPE_ESTIM = 1
const TYPE_INDEX = 2

const ExploitationConsumptionsApp = ({ match }) => {
    const [exploitationId] = useState(match?.params?.id)
    const [year, setYear] = useState(new Date().getFullYear())
    const [numberOfLines, setNumberOfLines] = useState(10)
    const [openModalEvent, setOpenModalEvent] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState()
    const [paramsModalConso, setParamsModalConso] = useState({ open: false, selectedMaterial: null, consoType: null })
    const [realConso, setRealConso] = useState()
    const [lowWaterConso, setLowWaterConso] = useState()
    const [declarationReal, setDeclarationReal] = useState()
    const isConsult = getUser().consultant === '1'
    const classes = useStyles()

    const {
        exploitation,
        installationsWithGeo,
        citiesIndex,
        contributor,
        variousMateriels,
        exploitationVolumes,
        matEventsExploitation,
        matEventsTypes,
        declarationsExploitation,
        declaration,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        declarationsExploitation: store.AgriReducer.declarationsExploitation,
        declaration: store.AgriReducer.declaration,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        citiesIndex: store.CityReducer.citiesIndex,
        contributor: store.ContributorReducer.contributor,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        matEventsExploitation: store.VariousMaterielReducer.matEventsExploitation,
        matEventsTypes: store.VariousMaterielReducer.matEventsTypes,
    }), shallowEqual)

    const dispatch = useDispatch()

    const getMarker = (stateCode) => {
        switch (stateCode) {
            case 1:
                return 'pictures/markers/map_qualite_green.png'
            case 2:
                return 'pictures/markers/map_qualite_yellow.png'
            case 3:
                return 'pictures/markers/map_qualite_red.png'
            case 4:
                return 'pictures/markers/map_qualite_white.png'
            default:
                return 'pictures/markers/map_qualite_green.png'
        }
    }

    const exploitationPoints = useMemo(() => compact(exploitation.link_samplings.filter((p) => !p.endDate || p.endDate > moment().valueOf()).map((linkPoint) => {
        const point = installationsWithGeo.find((i) => i.id === linkPoint.idInstallation)
        return point ? {
            ...point,
            startDate: linkPoint.startDate,
            endDate: linkPoint.endDate,
            stateCode: linkPoint.stateCode,
            typeName: 'other',
            markerIcon: getMarker(linkPoint.stateCode),
        } : null
    })), [exploitation, installationsWithGeo])

    const exploitationPointsHisto = useMemo(() => compact(exploitation.link_samplings.filter((p) => p.endDate && p.endDate <= moment().valueOf()).map((linkPoint) => {
        const point = installationsWithGeo.find((i) => i.id === linkPoint.idInstallation)
        return point ? {
            ...point,
            startDate: linkPoint.startDate,
            endDate: linkPoint.endDate,
            stateCode: linkPoint.stateCode,
            histo: true,
        } : null
    })), [exploitation, installationsWithGeo])

    const [pointSelected, setPointSelected] = useState(() => {
        if (exploitationPoints.length === 1) {
            return exploitationPoints[0]
        }
        return null
    })

    useEffect(() => {
        if (exploitation?.idExploitation) {
            dispatch(VariousMaterielAction.fetchMatEventsByExploitation(exploitation.idExploitation))
            dispatch(AgriAction.fetchDeclarationsByExploitationId(exploitation.idExploitation))
        }
    }, [exploitation])

    useEffect(() => {
        if (contributor?.id !== exploitation?.operatorCode) {
            dispatch(ContributorAction.fetchContributor(exploitation?.operatorCode))
        }
        dispatch(HomeAction.setTitle([
            {
                title: i18n.folders,
                href: 'dossiers',
            },
            {
                title: `${contributor.name || ''} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`,
                href: `dossiers/${exploitation.idExploitation}/dashboard`,
            },
            {
                title: i18n.consos,
                href: `dossiers/${exploitation.idExploitation}/consumptions`,
            },
        ]))
        if (exploitation?.idExploitation !== parseInt(exploitationId)) {
            dispatch(AgriAction.fetchExploitation(exploitationId))
        }
    }, [exploitationId, exploitation, contributor, pointSelected])

    useEffect(() => {
        dispatch(AgriAction.fetchExploitationVolumes(exploitationId))
    }, [exploitationId])

    useEffect(() => {
        if (variousMateriels?.length) {
            setActions({
                export: () => {
                    const chronicles = exploitation?.link_chronicles
                    const dataFormatted = chronicles?.length ? compact(orderBy(chronicles, ['idMat', 'measureDate'], ['asc', 'desc']).map((c) => {
                        const mat = variousMateriels.find((m) => m.id === c.idMat) || {}
                        if (hasValue(mat.administrator) && mat.administrator !== exploitation.operatorCode) {
                            return null
                        }
                        return {
                            matCode: mat.reference || mat.serialNumber || `[${mat.id}]`,
                            measureDate: getDate(c.measureDate),
                            endDate: getDate(c.endDate),
                            value: `${formatMilliers(c.value) || 0} ${c.measureType === 1 ? 'm3' : ''}`,
                        }
                    })) : []
                    if (dataFormatted.length) {
                        dataFormatted[0].headers = ['matCode', 'measureDate', 'endDate', 'value']
                    }
                    return {
                        data: dataFormatted,
                        exportType: 'xlsx',
                        titleFile: `${exploitation.codification} - ${i18n.consos}`,
                    }
                },
            })
        }
    }, [exploitation, variousMateriels])

    const pointsUsed = useMemo(() => exploitationPoints.filter(({ stateCode }) => stateCode === 1), [exploitationPoints])
    const pointsNotUsed = useMemo(() => exploitationPoints.filter(({ stateCode }) => stateCode === 2), [exploitationPoints])
    const pointsAbandonned = useMemo(() => exploitationPoints.filter(({ stateCode }) => stateCode === 3), [exploitationPoints])

    const getMateriels = (pointId) => {
        const pointPumps = exploitation.link_materiel.filter((m) => m.siteType === 7 && m.siteCode === pointId) || []
        const pointCounters = exploitation.link_materiel.filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((m) => [...pumpsIds, ...countersIds].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    const getLastChronicle = (point) => {
        const { materiels } = getMateriels(point.id)
        const pointChronicles = exploitation.link_chronicles.filter(({ idMat, measureDate }) => materiels.map(({ id }) => id).includes(idMat) &&
            (!point.startDate || measureDate >= point.startDate) &&
            (!point.endDate || measureDate < point.endDate)
        )
        const lastChronicle = pointChronicles.length ? pointChronicles.reduce((max, chronique) => (max.measureDate > chronique.measureDate) ? max : chronique) : null
        if (lastChronicle) {
            const matFound = materiels.find((m) => m.id === lastChronicle.idMat) || {}
            return (
                <>
                    {lastChronicle.measureType === 1 ? (
                        <>
                            <b className='bold'>{matFound.reference || i18n.unknown}</b>
                            <span className='italic-label'>{` - ${getDate(lastChronicle.measureDate)} -> ${getDate(lastChronicle.endDate)} : ${formatMilliers(lastChronicle.value) || 0} m3`}</span>
                        </>
                    ) : (
                        <>
                            <b className='bold'>{matFound.reference || i18n.unknown}</b>
                            <span className='italic-label'>{` - ${getDate(lastChronicle.measureDate)} : ${formatMilliers(lastChronicle.value) || 0}`}</span>
                        </>
                    )}
                </>
            )
        }
        return <span>{i18n.noConsumptionIndicated}</span>
    }

    const editModalIndex = (type, id) => {
        setParamsModalConso({ selectedMaterial: id, consoType: type, open: true })
    }

    const getEvolValue = (type, chronicles, chronicle, index) => {
        if (type === TYPE_INDEX) {
            return chronicle.value - chronicles[index + 1].value
        }
        return Math.round(chronicles.slice(index).reduce((acc, d) => acc + d.value, 0))
    }

    const getChroniclesByType = (chroniclesByType, type, materiel, readingCoefficient = 1) => {
        const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
        const currentChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() >= year)
        const pastChronicles = orderedChronicles.filter((c) => !c.event && moment(c.measureDate).year() < year)
        if (chroniclesByType?.length) {
            const props = {
                onClick: !pointSelected.histo && !isConsult ? () => editModalIndex(type, materiel.id) : () => {},
            }
            return (
                <>
                    <Element item className='italic-label' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                    </Element>
                    <Element item className='italic-label' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.endDate : ''}
                    </Element>
                    <Element item className='italic-label' xs={3} {...props}>
                        {type === TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                    </Element>
                    <Element item className='italic-label' xs={3}>
                        {type === TYPE_ESTIM ? i18n.stack : i18n.evolution}
                    </Element>
                    {currentChronicles.slice(0, numberOfLines).map((chronicle, i) => {
                        if (chronicle.event) {
                            const onClickEvent = {
                                onClick: !pointSelected.histo && !isConsult ? () => {
                                    setSelectedEvent(chronicle)
                                    setOpenModalEvent(true)
                                } : () => {},
                            }
                            return (
                                <>
                                    <Element item xs={3} {...onClickEvent}>
                                        {getDate(chronicle.measureDate)}
                                    </Element>
                                    <Element item xs={3} {...onClickEvent}>
                                        {(matEventsTypes.find(({ id }) => id === chronicle.idType) || {}).name || ''}
                                    </Element>
                                    <Element item xs={3} {...onClickEvent} {...sieauTooltip(chronicle.comment, null, 'bottom')}><Icon>warning</Icon></Element>
                                    <Element item xs={3} {...onClickEvent} />
                                </>
                            )
                        }
                        const valueEvol = i !== currentChronicles.length - 1 ? getEvolValue(type, currentChronicles, chronicle, i) : null
                        return (
                            <>
                                <Element item xs={3} {...props}>
                                    {getDate(chronicle.measureDate)}
                                </Element>
                                <Element item xs={3} {...props}>
                                    {chronicle.endDate ? getDate(chronicle.endDate) : ''}
                                </Element>
                                <Element item xs={3} {...props}>
                                    {`${formatMilliers(chronicle.value) || 0}${type === TYPE_ESTIM ? ' m3' : ''}`}
                                </Element>
                                <Element item xs={3} style={ valueEvol < 0 ? { color: 'orange' } : {}}>
                                    {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers(Math.abs((valueEvol) * readingCoefficient))} m3` : ''}
                                </Element>
                            </>
                        )
                    })}
                    {pastChronicles.length > 0 && <Element item xs={12}>...</Element>}
                    {pastChronicles[0] && (
                        <>
                            <Element item xs={3} {...props}>
                                {getDate(pastChronicles[0].measureDate)}
                            </Element>
                            <Element item xs={3} {...props}>
                                {pastChronicles[0].endDate ? getDate(pastChronicles[0].endDate) : ''}
                            </Element>
                            <Element item xs={3} {...props}>
                                {`${formatMilliers(pastChronicles[0].value) || 0}${type === TYPE_ESTIM ? ' m3' : ''}`}
                            </Element>
                            <Element item xs={3}>
                                {moment(pastChronicles[0].measureDate).year() !== year && <Icon>access_time</Icon>}
                            </Element>
                        </>
                    )}
                    <Element item xs={11}>
                        <Divider />
                    </Element>
                </>
            )
        }
        return null
    }

    const getChronicles = (groupedChronicles) => {
        return (groupedChronicles.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === TYPE_INDEX || c.event)
            const estimateChronicles = chronicles.filter((c) => c.measureType === TYPE_ESTIM)
            const readingCoefficient = variousMat?.counter?.readingCoefficient
            return (
                <Grid
                    item
                    container
                    direction='row'
                    justifyContent='center'
                    className={!isConsult ? 'clickable' : ''}
                >
                    <Element item xs={11} className='bold' style={{ textAlign: 'start', display: 'flex', alignItems: 'center' }}>
                        {variousMat.reference}{readingCoefficient ? ` - ${i18n.readingCoefficient} ${readingCoefficient}` : ''}{variousMat?.counter?.informative && ` (${i18n.informative})`}{variousMat.isShared && <Icon className='margin-left-1'>people</Icon>}
                    </Element>
                    {getChroniclesByType(indexChronicles, TYPE_INDEX, variousMat, readingCoefficient)}
                    {getChroniclesByType(estimateChronicles, TYPE_ESTIM, variousMat)}
                </Grid>
            )
        }))
    }

    const getEstimConso = (groupedChronicles) => {
        return groupedChronicles.map((chroniques) => {
            return chroniques.filter((c) => c.measureType === 1 && moment(c.measureDate).year() >= year).reduce((acc, c) => acc + c.value, 0) || 0
        }).reduce((acc, c) => acc + c, 0)
    }

    const getColorRealConso = (authorizedVolume) => {
        if (!hasValue(authorizedVolume) || !hasValue(realConso)) {
            return 'black'
        }
        if (realConso > authorizedVolume) {
            return 'red'
        }
        if (((realConso / authorizedVolume) * 100) >= 80) {
            return 'orange'
        }
        return 'green'
    }

    useEffect(() => {
        if (pointSelected) {
            dispatch(AgriAction.calculPointVolumeReal(pointSelected.id, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf())).then((res) => {
                setRealConso(res?.total)
                setLowWaterConso(res?.lowWater)
            })
        }
    }, [pointSelected, year])

    useEffect(() => {
        setDeclarationReal()
        const declRealYear = declarationsExploitation.find((d) => d.year === year + 1)
        if (declRealYear) {
            dispatch(AgriAction.fetchDeclaration(declRealYear.idDeclaration)).then(() => setDeclarationReal(declaration))
        }
    }, [year])

    const getContent = () => {
        const { pumpsIds, countersIds } = getMateriels(pointSelected.id)
        const pointVolumes = exploitationVolumes.filter((v) => v.idInstallation === pointSelected.id && v.askedYear === year) || []
        const chroniclesMat = exploitation.link_chronicles.filter(({ idMat }) => [...pumpsIds, ...countersIds].includes(idMat))
        const pointChronicles = chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year &&
            (!pointSelected.startDate || measureDate >= pointSelected.startDate) &&
            (!pointSelected.endDate || measureDate < pointSelected.endDate)
        )
        const pointChroniclePrevYear = orderBy(chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year - 1 &&
            (!pointSelected.startDate || measureDate >= pointSelected.startDate) &&
            (!pointSelected.endDate || measureDate < pointSelected.endDate)
        ), ['measureDate', 'endDate'], 'desc')
        const chroniclesToShow = pointChroniclePrevYear ? [...pointChronicles, ...pointChroniclePrevYear] : [...pointChronicles]
        const eventsMat = matEventsExploitation.filter(({ idMateriel }) => [...pumpsIds, ...countersIds].includes(idMateriel))
        const pointEvents = eventsMat.filter(({ eventDate }) => new Date(eventDate).getFullYear() === year)
        const pointEventsFormatted = pointEvents.map((e) => {
            return {
                ...e,
                idMat: e.idMateriel,
                measureDate: e.eventDate,
                event: true,
            }
        })

        const groupedByMat = Object.values(groupBy([...chroniclesToShow, ...pointEventsFormatted], 'idMat'))
        const estimConso = groupedByMat.length ? getEstimConso(groupedByMat) : null
        const maxNumberOfValues = Math.max(...groupedByMat.map(({ length }) => length)) || 0

        const askedVolume = pointVolumes.filter((p) => hasValue(p.askedVolume)).length > 0 ? pointVolumes.reduce((acc, d) => acc + (d.askedVolume || 0), 0) : null
        const askedLowWaterVolume = pointVolumes.filter((p) => hasValue(p.askedLowWaterVolume)).length > 0 ? pointVolumes.reduce((acc, d) => acc + (d.askedLowWaterVolume || 0), 0) : null
        const attributedVolume = pointVolumes.filter((p) => hasValue(p.attributedVolume)).length > 0 ? pointVolumes.reduce((acc, d) => acc + (d.attributedVolume || 0), 0) : null
        const attributedLowWaterVolume = pointVolumes.filter((p) => hasValue(p.attributedLowWaterVolume)).length > 0 ? pointVolumes.reduce((acc, d) => acc + (d.attributedLowWaterVolume || 0), 0) : null
        const authorizedVolume = pointVolumes.filter((p) => hasValue(p.authorizedVolume)).length > 0 ? pointVolumes.reduce((acc, d) => acc + (d.authorizedVolume || 0), 0) : null
        const authorizedLowWaterVolume = pointVolumes.filter((p) => hasValue(p.authorizedLowWaterVolume)).length > 0 ? pointVolumes.reduce((acc, d) => acc + (d.authorizedLowWaterVolume || 0), 0) : null

        const pointUsages = (declaration.link_declarationInstallation.find((i) => i.idInstallation === pointSelected.id) || {}).link_usagesCurrent || []
        const irrigVolume = !declarationReal ? null : pointUsages.filter((u) => u.idUsage === 7 && hasValue(u.requestedYearVolume)).reduce((acc, u) => acc + (u.requestedYearVolume || 0), null)
        const antiFreezeVolume = !declarationReal ? null : pointUsages.filter((u) => u.idUsage === 3 && hasValue(u.requestedYearVolume)).reduce((acc, u) => acc + (u.requestedYearVolume || 0), null)
        const wateringVolume = !declarationReal ? null : pointUsages.filter((u) => u.idUsage === 1 && hasValue(u.requestedYearVolume)).reduce((acc, u) => acc + (u.requestedYearVolume || 0), null)
        const othersVolume = !declarationReal ? null : pointUsages.filter((u) => ![1, 3, 7].includes(u.idUsage) && hasValue(u.requestedYearVolume)).reduce((acc, u) => acc + (u.requestedYearVolume || 0), null)
        return (
            <>
                <Grid
                    item
                    xs={12}
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{ padding: '0 8px' }}
                >
                    <Grid item>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => setYear(year - 1)}
                            style={{ color: 'white' }}
                        >
                            <Icon>keyboard_arrow_left</Icon>
                            {year - 1}
                        </Button>
                    </Grid>
                    <Grid item>
                        <h2>{year}</h2>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => setYear(year + 1)}
                            style={{ color: 'white' }}
                        >
                            {year + 1}
                            <Icon>keyboard_arrow_right</Icon>
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    style={{ padding: '8px' }}
                >
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} />
                        <Element item xs={2} className={classes.textLeft}>
                            {i18n.year}
                        </Element>
                        <Element item xs={2} className={classes.textLeft}>
                            {i18n.lowWater}
                        </Element>
                        <Element item xs={2} className={classes.textLeft}>
                            {i18n.notLowWater}
                        </Element>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.requestedVolume}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(askedVolume) ? `${formatMilliers(askedVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(askedLowWaterVolume) ? `${formatMilliers(askedLowWaterVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(askedVolume) && hasValue(askedLowWaterVolume) ? `${formatMilliers(askedVolume - askedLowWaterVolume)} m3` : '-'}
                        </Element>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.allocatedVolume}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(attributedVolume) ? `${formatMilliers(attributedVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(attributedLowWaterVolume) ? `${formatMilliers(attributedLowWaterVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(attributedVolume) && hasValue(attributedLowWaterVolume) ? `${formatMilliers(attributedVolume - attributedLowWaterVolume)} m3` : '-'}
                        </Element>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.authorizedVolume}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(authorizedVolume) ? `${formatMilliers(authorizedVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(authorizedLowWaterVolume) ? `${formatMilliers(authorizedLowWaterVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(authorizedVolume) && hasValue(authorizedLowWaterVolume) ? `${formatMilliers(authorizedVolume - authorizedLowWaterVolume)} m3` : '-'}
                        </Element>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.estimConsumption}
                        </Element>
                        <Element item xs={2} className={classes.textRight}>
                            {hasValue(estimConso) ? `${formatMilliers(estimConso)} m3` : '-'}
                        </Element>
                        <Element item xs={2} />
                        <Element item xs={2} />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.realConsumption}
                        </Element>
                        <Element item xs={2} className={`${classes.textRight} bold`} style={{ color: getColorRealConso(pointVolumes.authorizedVolume) }}>
                            {hasValue(realConso) ? `${formatMilliers(realConso)} m3` : '-'}
                        </Element>
                        <Element item xs={2} className={`${classes.textRight} bold`}>
                            {hasValue(lowWaterConso) ? `${formatMilliers(lowWaterConso)} m3` : '-'}
                        </Element>
                        <Element item xs={2} className={`${classes.textRight} bold`}>
                            {hasValue(realConso) && hasValue(lowWaterConso) ? `${formatMilliers(realConso - lowWaterConso)} m3` : '-'}
                        </Element>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.consoUseIrrig}
                        </Element>
                        <Element item xs={2} className={`${classes.textRight} bold`}>
                            {hasValue(irrigVolume) ? `${formatMilliers(irrigVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} />
                        <Element item xs={2} />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.consoUseAntiFreeze}
                        </Element>
                        <Element item xs={2} className={`${classes.textRight} bold`}>
                            {hasValue(antiFreezeVolume) ? `${formatMilliers(antiFreezeVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} />
                        <Element item xs={2} />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.consoUseWatering}
                        </Element>
                        <Element item xs={2} className={`${classes.textRight} bold`}>
                            {hasValue(wateringVolume) ? `${formatMilliers(wateringVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} />
                        <Element item xs={2} />
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent='space-around'
                    >
                        <Element item xs={4} className={classes.textLeft}>
                            {i18n.consoUseOthers}
                        </Element>
                        <Element item xs={2} className={`${classes.textRight} bold`}>
                            {hasValue(othersVolume) ? `${formatMilliers(othersVolume)} m3` : '-'}
                        </Element>
                        <Element item xs={2} />
                        <Element item xs={2} />
                    </Grid>
                    <Element item className='bold' style={{ padding: '8px', width: '100%' }}>{i18n.prelDetails}</Element>
                    {getChronicles(groupedByMat)}
                    <Grid item>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => setNumberOfLines(numberOfLines + 10)}
                            style={{ marginTop: '10px', color: 'white' }}
                            disabled={numberOfLines > maxNumberOfValues}
                        >
                            {i18n.showMoreHistory}
                        </Button>
                    </Grid>
                </Grid>
            </>
        )
    }

    const getPointsList = (pointsList, title, options = {}) => {
        const { open, histo } = options
        const formatPointsList = orderBy(pointsList.map((p) => {
            return {
                ...p,
                cityName: (citiesIndex[p.townCode] || {})?.name,
            }
        }), ['townCode', 'cityName', 'parcel', 'section', 'code'])
        return (
            <AccordionMUI defaultExpanded={open} className='margin-bottom-1' round>
                <AccordionSummaryMUI round>
                    <span className='bold'>{`${pointsList.length} ${title}`}</span>
                </AccordionSummaryMUI>
                <AccordionDetailsMUI sx={{ padding: 0 }}>
                    {formatPointsList.map((p) => (
                        <GridItem
                            key={p.id}
                            className='clickable'
                            onClick={() => setPointSelected(p)}
                            style={pointSelected?.id === p.id ? {
                                backgroundColor: 'rgba(22, 24, 50, 0.1)',
                            } : {}}
                        >
                            <Grid className='bold' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {p.cityName || ''}<span>{histo ? `${i18n.endDate} : ${getDate(p.endDate)}` : ''}</span>
                            </Grid>
                            <Grid>{p.location || ''}</Grid>
                            <Grid><b className='bold'>{p.code || ''}</b>{` ${`- ${p.parcel || ''} ${p.section || ''}${p.name ? ` - ${p.name}` : ''}`}`}</Grid>
                            <div className='padding-top-1'>
                                {getLastChronicle(p)}
                            </div>
                        </GridItem>
                    ))}
                </AccordionDetailsMUI>
            </AccordionMUI>

        )
    }
    const onCancelIndex = () => {
        setParamsModalConso({ selectedMaterial: null, consoType: null, open: false })
    }

    const onSaveChronicles = (link_chronicles) => {
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        setParamsModalConso({ selectedMaterial: null, consoType: null, open: false })
        dispatch(AgriAction.updateExploitation(updateExploitation,
            () => dispatch(AgriAction.fetchExploitation(exploitationId))
        ))
    }

    const getConsoModal = () => {
        if (paramsModalConso.open) {
            const { pumpsIds, countersIds, materiels } = getMateriels(pointSelected.id)
            const exploitationMatIds = materiels.map(({ id }) => id)
            return (
                <AddConsoModal
                    open={paramsModalConso.open}
                    point={pointSelected}
                    pumpsIds={uniq(pumpsIds.filter((id) => exploitationMatIds.includes(id)))}
                    countersIds={uniq(countersIds.filter((id) => exploitationMatIds.includes(id)))}
                    selectedMaterial={paramsModalConso.selectedMaterial}
                    consoType={paramsModalConso.consoType}
                    onSave={onSaveChronicles}
                    onCancel={onCancelIndex}
                    chronicles={exploitation.link_chronicles}
                />
            )
        }
        return null
    }

    const onCancelEvent = () => {
        setOpenModalEvent(false)
        setSelectedEvent(null)
    }

    const onValidateEvent = (event) => {
        onCancelEvent()
        if (event.id) {
            dispatch(VariousMaterielAction.updateMatEvent(event,
                () => dispatch(VariousMaterielAction.fetchMatEventsByExploitation(exploitationId))
            ))
        } else {
            dispatch(VariousMaterielAction.createMatEvent(event,
                () => dispatch(VariousMaterielAction.fetchMatEventsByExploitation(exploitationId))
            ))
        }
    }

    const onDeleteEvent = (event) => {
        onCancelEvent()
        dispatch(VariousMaterielAction.deleteMatEvent(event.id,
            () => dispatch(VariousMaterielAction.fetchMatEventsByExploitation(exploitationId))
        ))
    }

    const getEventModal = () => {
        if (openModalEvent) {
            return (
                <MatEventModal
                    open={openModalEvent}
                    event={selectedEvent}
                    materiels={getMateriels(pointSelected.id).materiels}
                    onCancel={onCancelEvent}
                    onValidate={onValidateEvent}
                    onDelete={onDeleteEvent}
                />
            )
        }
        return null
    }

    return (
        <div className='padding-1'>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    {getPointsList(pointsUsed, getI18nTitleData(i18n.usedPoint, i18n.usedPoints, pointsUsed), { open: true })}
                    {getPointsList(pointsNotUsed, getI18nTitleData(i18n.noUsedPoint, i18n.noUsedPoints, pointsNotUsed))}
                    {getPointsList(pointsAbandonned, getI18nTitleData(i18n.deletePoint, i18n.deletePoints, pointsAbandonned))}
                    {getPointsList(exploitationPointsHisto, getI18nTitleData(i18n.pointPrelevementHisto, i18n.pointsPrelevementHisto, exploitationPointsHisto), { histo: true })}
                </Grid>
                <Grid item xs={4}>
                    <Card
                        title={pointSelected ? `${i18n.consos} - ${i18n.point} ${pointSelected.code}` : i18n.noPointSelected}
                        actions={pointSelected && !pointSelected.histo && !isConsult ? [
                            { onClick: () => setParamsModalConso({ open: true }), iconName: 'edit', tooltip: i18n.change },
                            { onClick: () => setOpenModalEvent(true), iconName: 'warning', tooltip: i18n.reportFailure },
                        ] : []}
                        round
                        cardStyle={{ position: 'sticky', top: 74 }}
                    >
                        <Grid container alignItems='center'>
                            {pointSelected && getContent()}
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ position: 'sticky', top: 74 }}>
                        <EvolVolumesChartPanel statistics={uniqWith(exploitation.link_conso_real.map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume)} />
                        <div className='margin-top-1'>
                            <CartographyPanel
                                layers={['STATIONS_POINTS']}
                                satelliteMode
                                noMarkerTooltip
                                noSearchBar
                                componentType='all'
                                fullScreenable
                                stationsPoints={exploitationPoints || []}
                                height={300}
                                noStationPanel
                                noPanels
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
            {paramsModalConso.open && getConsoModal()}
            {openModalEvent && getEventModal()}
        </div>
    )
}

ExploitationConsumptionsApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default ExploitationConsumptionsApp