import { Button, Grid } from '@mui/material'
import Other from 'components/actions/Other'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import Tabs from 'components/Tabs'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { getDate, getHour } from 'utils/DateUtil'
import { onChangeHour } from 'utils/FormUtils'
import { hasValue } from 'utils/NumberUtil'
import { statusIcon } from 'utils/StatusUtil'
import { getLabel, getSandreList } from 'utils/StoreUtils'
import { getSandreLabel, substringText } from 'utils/StringUtil'
import OperationAction from '../../actions/OperationAction'
import OperationGraph from '../OperationGraph'
import ConfirmModal from 'components/modal/ConfirmModal'
import useBoolean from 'utils/customHook/useBoolean'
import { isUndefined } from 'lodash'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet } from 'components/StyledElements'

const TABLE = 'TABLE'
const GRAPH = 'GRAPH'

const DialogCondition = ({
    title = '',
    isOpen = false,
    close = () => { },
    validate = () => { },
    condition = {},
}) => {
    const {
        methods,
        units,
        parameters,
        statusList,
        qualifications,
        sandreCodes,
        environmentalConditions,
    } = useSelector(store => ({
        methods: store.MethodReducer.methods,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        statusList: store.QualityReducer.status,
        qualifications: store.QualityReducer.qualifications,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        environmentalConditions: store.OperationReducer.environmentalConditions,
    }), shallowEqual)

    const [parameterCode, setParameterCode] = useState()
    const [measure, setMeasure] = useState()
    const [unitCode, setUnitCode] = useState()
    const [status, setStatus] = useState()
    const [qualification, setQualification] = useState()
    const [method, setMethod] = useState()
    const [comment, setComment] = useState()
    const [date, setDate] = useState()

    useUpdateEffect(() => {
        close()
        setParameterCode(undefined)
        setMeasure(undefined)
        setUnitCode(undefined)
        setStatus(undefined)
        setQualification(undefined)
        setMethod(undefined)
        setComment(undefined)
        setDate(undefined)
    }, [environmentalConditions])

    useEffect(() => {
        if (!isOpen) {
            return
        }
        setParameterCode(isUndefined(condition.parameterCode) ? undefined : `${condition.parameterCode}`)
        setMeasure(condition.measure)
        setUnitCode(condition.unitCode)
        setStatus(condition.status)
        setQualification(condition.qualification)
        setMethod(isUndefined(condition.method) ? undefined : `${condition.method}`)
        setComment(condition.comment)
        setDate(condition.date)
    }, [isOpen])

    const sandreList = parameterCode ? getSandreList(sandreCodes, `PARAMETRE.${parameterCode}`) : []

    const onValidate = () => {
        const newCondition = {
            parameterCode: parseInt(parameterCode) || undefined,
            measure,
            unitCode,
            status,
            qualification,
            method: parseInt(method) || undefined,
            comment,
            date
        }
        validate(newCondition)
    }

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: 'auto',
                    maxHeight: 'auto',
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {title}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={close} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet style={{ margin: '20 0 0 0' }}>
                    <Grid container columnSpacing={2} rowSpacing={1} alignItems='center'>
                        <Grid item xs={3}>
                            <SimpleDatePicker
                                id='date'
                                label={i18n.date}
                                value={date}
                                onChange={setDate}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                title={i18n.hour}
                                value={getHour(date)}
                                onChange={v => onChangeHour(v, setDate)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={statusList}
                                value={status}
                                label={i18n.status}
                                keyLabel='name'
                                keyValue='code'
                                returnNull
                                onChange={setStatus}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={qualifications}
                                value={qualification}
                                label={i18n.qualification}
                                keyLabel='name'
                                keyValue='code'
                                returnNull
                                onChange={setQualification}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.parameter}
                                options={parameters}
                                value={parameterCode}
                                onChange={v => {
                                    setParameterCode(v)
                                    setMeasure(undefined)
                                }}
                                clearFunction
                                keyLabel='labelWithCode'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {
                                sandreList?.length ? (
                                    <Select
                                        label={i18n.measure}
                                        options={sandreList}
                                        value={measure}
                                        onChange={setMeasure}
                                        clearFunction
                                    />
                                ) : (
                                    <NumberField
                                        title={i18n.measure}
                                        value={measure}
                                        floatValue
                                        onChange={setMeasure}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.unit}
                                options={units}
                                value={unitCode}
                                onChange={setUnitCode}
                                clearFunction
                                keyLabel='symbolWithName'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                options={methods}
                                label={i18n.method}
                                value={method}
                                nullLabel='&nbsp;'
                                clearFunction
                                onChange={setMethod}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Textarea
                                title={i18n.comment}
                                value={comment}
                                onChange={setComment}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container alignItems='center' justifyContent='right' columnSpacing={2} style={{ padding: '0 10' }}>
                    <Grid container item xs={3} justifyContent='flex-end'>
                        <Button
                            onClick={onValidate}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.validate}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

DialogCondition.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    validate: PropTypes.func,
    condition: PropTypes.shape({}),
}

const EnvironmentalConditionsTable = () => {
    const dispatch = useDispatch()
    const {
        environmentalConditions,
        methods,
        units,
        parameters,
        sandreCodes,
        operation,
        qualitometer,
    } = useSelector(store => ({
        environmentalConditions: store.OperationReducer.environmentalConditions,
        methods: store.MethodReducer.methods,
        units: store.UnitReducer.units,
        parameters: store.ParameterReducer.parameters,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        operation: store.OperationReducer.operation,
        qualitometer: store.QualityReducer.qualitometer,
    }), shallowEqual)

    const {
        value: isConfirmOpen,
        setTrue: openConfirm,
        setFalse: closeConfirm,
    } = useBoolean(false)
    const {
        value: isDialogOpen,
        setTrue: openDialog,
        setFalse: closeDialog,
    } = useBoolean(false)

    const [selectedId, setSelectedId] = useState()

    const environmentalConditionsFormated = environmentalConditions.map(condition => {
        const { date, parameterCode, unitCode, measure, method, comment } = condition
        const labelMethod = hasValue(method) ? getLabel(methods, method) || `<${method}>` : ''
        const methodTooltip = labelMethod > 30 ? {
            className: 'tooltipped',
            tooltip: labelMethod,
        } : {}
        const sandre = getSandreLabel(sandreCodes, `PARAMETRE.${parameterCode}`, measure)

        return {
            nullValue: { value: statusIcon(condition, 20, true) },
            parameter: { value: getLabel(parameters, parameterCode, 'labelWithCode') },
            measure: { value: sandre || measure },
            unit: { value: getLabel(units, unitCode, 'symbolWithCode') },
            method: { value: substringText(labelMethod, 30), ...methodTooltip },
            comment: { value: comment },
            analysisDate: { value: getDate(date) },
            idCondition: condition.idCondition,
            idOperation: condition.idOperation,
            idQualitometer: condition.idQualitometer,
        }
    })

    const selectedCondition = useMemo(() => {
        return environmentalConditions.find(ec => ec.idCondition === selectedId)
    }, [environmentalConditions, selectedId])

    return (
        <div className='col s12 no-padding'>
            <Table
                showTitle={false}
                data={environmentalConditionsFormated}
                type={{ headers: ['nullValue', 'parameter', 'measure', 'unit', 'method', 'comment', 'analysisDate'] }}
                condensed
                sortable
                color
                paging
                deletable
                onDelete={({ idCondition }) => {
                    setSelectedId(idCondition)
                    openConfirm()
                }}
                alterable
                onAlter={({ idCondition }) => {
                    setSelectedId(idCondition)
                    openDialog()
                }}
                nbPerPageLabel={nbPerPageLabel}
                id={'environmentConditionTable'}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title={i18n.deletingConfirmation}
                onValidate={() => {
                    dispatch(OperationAction.deleteEnvironmentalCondition(qualitometer.id, operation.id, selectedId)).finally(closeConfirm)
                }}
                onClose={closeConfirm}
            />
            <DialogCondition
                title={i18n.updateEnvironmentalCondition}
                isOpen={isDialogOpen}
                close={closeDialog}
                validate={newCondition => {
                    dispatch(OperationAction.updateEnvironmentalCondition(qualitometer.id, operation.id, selectedId, newCondition)).finally(closeDialog)
                }}
                condition={selectedCondition}
            />
        </div>
    )
}

const EnvironmentalConditionsGraph = () => {
    const {
        environmentalConditions,
        sandreCodes,
    } = useSelector(store => ({
        environmentalConditions: store.OperationReducer.environmentalConditions,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)
    const conditions = useMemo(() => {
        return environmentalConditions.filter(({ parameterCode }) => !getSandreList(sandreCodes, `PARAMETRE.${parameterCode}`).length)
    }, [environmentalConditions, sandreCodes])
    return (
        <OperationGraph
            analysis={conditions}
            parameterKey={'parameterCode'}
            resultKey={'measure'}
            hideDataType
        />
    )
}

const EnvironmentalConditionsPanel = ({
    uploadFile,
    getOperationFiles,
    noAction = false,
}) => {
    const dispatch = useDispatch()
    const {
        files,
        operation,
        environmentalConditions,
        methods,
        units,
        parameters,
        sandreCodes,
        qualifications,
        statusList,
    } = useSelector(store => ({
        files: store.OperationReducer.files,
        operation: store.OperationReducer.operation,
        environmentalConditions: store.OperationReducer.environmentalConditions,
        methods: store.MethodReducer.methods,
        units: store.UnitReducer.units,
        parameters: store.ParameterReducer.parameters,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        qualifications: store.QualityReducer.qualifications,
        statusList: store.QualityReducer.status,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)

    const defaultCondition = useMemo(() => {
        return {}
    }, [])

    useActions(() => {
        if (noAction) {
            return {}
        }
        const actions = {
            importFile: {
                onClick: uploadFile,
                format: '',
                tooltip: i18n.importAnalysisFile,
            },
            export: () => {
                const data = environmentalConditions.map(condition => {
                    const { date, parameterCode, unitCode, measure, method, comment, qualification, status } = condition
                    const sandre = getSandreLabel(sandreCodes, `PARAMETRE.${parameterCode}`, measure)
                    return {
                        qualification: { value: qualification, cellType: 'number' },
                        qualificationCode: getLabel(qualifications, qualification),
                        statusCode: { value: status, cellType: 'number' },
                        status: getLabel(statusList, status),
                        codeParameter: { value: parameterCode, cellType: 'number' },
                        parameter: getLabel(parameters, parameterCode),
                        measure: { value: sandre || measure, cellType: sandre ? 'text' : 'number' },
                        codeUnit: { value: unitCode, cellType: 'right' },
                        unit: getLabel(units, unitCode, 'symbol'),
                        methodCode: { value: method, cellType: 'number' },
                        method: hasValue(method) ? getLabel(methods, method) || `<${method}>` : '',
                        comment,
                        analysisDate: { value: getDate(date), format: 'dd/MM/yyyy', cellType: 'date' },
                    }
                })
                return {
                    data: data.length ? [{ ...data[0], headers: Object.keys(data[0]) }, ...data.slice(1)] : [],
                    exportType: 'xlsx',
                    titleFile: `operation ${getDate(operation.dateStart)}`,
                }
            },
            new: () => setIsOpen(true),
        }
        if (files.length) {
            return {
                ...actions,
                other: {
                    other: (
                        <Other
                            className='clickable'
                            tooltip={i18n.operation}
                            icon='attach_file'
                            onClick={getOperationFiles}
                        />
                    ),
                },
            }
        }
        return actions
    }, [uploadFile, getOperationFiles, operation])

    return (
        <div className='card card-content no-margin no-padding'>
            <div className='row no-margin'>
                <Tabs
                    defaultTab={TABLE}
                    tabs={[
                        {
                            constant: TABLE,
                            label: i18n.table,
                        },
                        {
                            constant: GRAPH,
                            label: i18n.graph,
                        },
                    ]}
                >
                    {tab => (
                        <>
                            {tab === TABLE && <EnvironmentalConditionsTable />}
                            {tab === GRAPH && <EnvironmentalConditionsGraph />}
                        </>
                    )}
                </Tabs>
            </div>
            <DialogCondition
                title={i18n.newEnvironmentalCondition}
                isOpen={isOpen}
                close={() => setIsOpen(false)}
                validate={newCondition => dispatch(OperationAction.createEnvironmentalCondition(operation.qualitometer, operation.id, newCondition))}
                condition={defaultCondition}
            />
        </div>
    )
}

EnvironmentalConditionsPanel.propTypes = {
    uploadFile: PropTypes.func,
    getOperationFiles: PropTypes.func,
    noAction: PropTypes.bool,
}


export default EnvironmentalConditionsPanel
