import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import React, { useState } from 'react'
import StepperDialog from '../../../../components/modal/StepperDialog'
import { hasValue } from '../../../../utils/NumberUtil'
import JobAction from '../../../../import/actions/JobAction'
import { getManualJobId } from '../../../../utils/JobUtils'
import { PIEZO_IMPORT_TYPE } from './AddPiezoConstants'
import { getLogin } from '../../../../utils/SettingUtils'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import { push } from 'connected-react-router'
import PiezometryAction from '../../../actions/PiezometryAction'
import AddPiezoStepStation from './steps/AddPiezoStepStation'
import AddPiezoStepOptions from './steps/AddPiezoStepOptions'
import AddPiezoStepExecution from './steps/AddPiezoStepExecution'
import AddPiezoStepJob from './steps/AddPiezoStepJob'

const STEP = {
    STATION_TYPE: 0,
    OPTIONS: 1,
    EXECUTION: 2,
    JOB: 3,
}

const setJobCode = (stationType, code, filters, parameters) => {
    switch (stationType) {
        case PIEZO_IMPORT_TYPE.ADES_PIEZO:
            return { filters: { ...filters, stationCodes: [code] }, parameters }
        default:
            return filters
    }
}

const callExecuteJob = (id, cb) => JobAction.promiseExecuteJob(id).then(json => {
    if (hasValue(json.execution)) {
        cb(json.execution)
    } else {
        setTimeout(() => JobAction.promiseExecuteJob(id).then(json2 => {
            if (hasValue(json2.execution)) {
                cb(json2.execution)
            } else {
                throw new Error('Impossible to launch Job')
            }
        }), 5000)
    }
})

const createStation = (station, dispatch) => {
    dispatch(PiezometryAction.createPiezometer({ ...station, stationType: null }, id => {
        dispatch(push(`/station/piezometry/${id}/description`))
    }))
}

const AddPiezoStepper = (props) => {
    const {
        isOpen,
        setIsOpen,
    } = props

    const {
        piezometers,
        userStation,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometerListSpecific,
        userStation: store.UserReducer.userStation,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [station, setStation] = useState({})
    const [filters, setFilters] = useState({})
    const [jobDataTypes, setJobDataTypes] = useState([])

    const changeStation = (changes) => setStation({ ...station, ...changes })
    const changeFilters = changes => setFilters({ ...filters, ...changes })

    const executeJob = (nextStep) => {
        JobAction.promiseJob(getManualJobId(station.stationType)).then(job => {
            const jobWithCode = setJobCode(station.stationType, station.code, filters)
            const oldParams = JSON.parse(job.parameters)
            const newJob = { ...job, parameters: {
                jobType: oldParams.jobType,
                routingKey: oldParams.routingKey,
                filters: jobWithCode.filters ? [JSON.stringify(jobWithCode.filters)] : [],
                dataTypes: jobDataTypes,
                parameters: jobWithCode.parameters,
                alertTypes: [],
            }, login: getLogin() }
            JobAction.promiseUpdateJob(newJob).then(res => {
                if (res.status !== 200) {
                    dispatch(ToastrAction.error(i18n.updateError + i18n.jobs))
                } else {
                    // changeStation({ jobExecutionId: 2877126, job: newJob }) // TODO: pour le test, à enlever
                    // nextStep()
                    callExecuteJob(newJob.id, jobExecutionId => {
                        changeStation({ jobExecutionId, job: newJob })
                        nextStep()
                    }).then(() => {})
                }
            })
        })
    }

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.station,
                    constant: STEP.STATION_TYPE,
                    nextAvailable: !!station.stationType,
                },
                {
                    label: i18n.options,
                    constant: STEP.OPTIONS,
                    nextAvailable: hasValue(station.code) && userStation.hasRights,
                    nextLabel: station.stationType === PIEZO_IMPORT_TYPE.EMPTY ? i18n.add : i18n.execute,
                    onNext: station.stationType === PIEZO_IMPORT_TYPE.EMPTY ? () => createStation(station, dispatch) : executeJob,
                },
                {
                    label: i18n.execution,
                    constant: STEP.EXECUTION,
                    nextAvailable: (station?.jobExecution?.statusCode === 1 || station?.jobExecution?.statusCode === 2) && !!piezometers?.find(p => p.code === station.code.replaceAll(/\/.*/g, '')),
                },
                {
                    label: i18n.programmation,
                    constant: STEP.JOB,
                },
            ]}
            open={isOpen}
            title={i18n.addOrUpdateStation}
            closeDialog={() => setIsOpen(false)}
        >
            {(step, nextStep, resetStep) => {
                const fullReset = () => {
                    resetStep()
                    setStation({})
                    setFilters({})
                    setJobDataTypes([])
                }
                const params = { ...props, station, changeStation, filters, changeFilters, setFilters, nextStep, fullReset, setIsOpen, jobDataTypes, setJobDataTypes }
                switch (step) {
                    case STEP.STATION_TYPE:
                        return <AddPiezoStepStation {...params}/>
                    case STEP.OPTIONS:
                        return <AddPiezoStepOptions {...params}/>
                    case STEP.EXECUTION:
                        return <AddPiezoStepExecution {...params}/>
                    case STEP.JOB:
                    default:
                        return <AddPiezoStepJob {...params}/>
                }
            }}
        </StepperDialog>
    )
}

AddPiezoStepper.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default AddPiezoStepper