import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Row from '../../components/react/Row'
import Select from '../../components/forms/Select'
import Button from '../../components/forms/Button'
import DtoStation from '../dto/DtoStation'
import SimpleDatePicker from '../../components/forms/SimpleDatePicker'
import { getMeasureCoteList } from '../../utils/JobUtils'
import RadioButtons from '../../components/forms/RadioButtons'

const StationsModelExportPanel = ({
    onApplyExportModel = () => {},
    onChange = () => {},
    selectedStations = [],
    model = null,
    modelTypesOptions = null,
    startDate = null,
    endDate = null,
}) => (
    <div className='row no-margin padding-1'>
        <fieldset>
            <legend className='italic-label padding-right-1 padding-left-1'>{i18n.exportCriterias}</legend>
            <Row>
                <Select
                    col={5}
                    label={i18n.model}
                    options={modelTypesOptions}
                    noNullValue
                    onChange={v => onChange({ model: v })}
                    value={model}
                />
                <SimpleDatePicker col={2} label={i18n.startDate} value={startDate} onChange={v => onChange({ modelStartDate: v }) } />
                <SimpleDatePicker col={2} label={i18n.endDate} value={endDate} onChange={v => onChange({ modelEndDate: v }) } />
                <Button col={3} title={`${i18n.selectStations} (${selectedStations.length})`}
                    onClick={() => onChange({ selectStationIsOpen: true })}
                />
            </Row>
            <Row>
                <div className='col s7'/>
                <Button
                    col={2}
                    title={i18n.apply}
                    onClick={() => onApplyExportModel(modelTypesOptions.find(m => m.value === model).name)}
                />
            </Row>
        </fieldset>
    </div>
)


StationsModelExportPanel.propTypes = {
    onApplyExportModel: PropTypes.func,
    onChange: PropTypes.func,
    selectedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    model: PropTypes.number,
    modelTypesOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    displayCote: PropTypes.number,
}

export default StationsModelExportPanel
