import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import SieauAction from '../../../../components/sieau/SieauAction'
import LogAction from '../../../../log/actions/LogAction'
import {
    checkAuth,
    checkAuthWithoutKicking,
    checkError,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import { setMessageModal } from '../../../../utils/FormUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import {
    RECEIVE_ALL_SIMS, RECEIVE_ALL_SIM_SITUATIONS, RECEIVE_SIM, RECEIVE_SIMS_LAST_SITUATIONS, RECEIVE_SIM_FILES,
    RECEIVE_SIM_PICTURES,
    RECEIVE_SIM_SUBSCRIPTION_LINK,
    RECEIVE_SIM_TYPES, RESET_SIM,
} from '../constants/SimConstants'

const SimAction = {
    receiveSims(sims) {
        return { type: RECEIVE_ALL_SIMS, sims }
    },

    promiseSims(withEquipment) {
        return fetch(ApplicationConf.materiel.allSims(withEquipment), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSims(withEquipment) {
        return (dispatch) => {
            return SimAction.promiseSims(withEquipment)
                .then((json = []) => {
                    dispatch(SimAction.receiveSims(json))
                    dispatch(SieauAction.receiveProps('sims'))
                    if (json.length) {
                        dispatch(SimAction.fetchSimsLastSituations())
                    }
                })
        }
    },

    receiveSim(sim) {
        return { type: RECEIVE_SIM, sim }
    },

    promiseSim(id) {
        return fetch(ApplicationConf.materiel.sim(id), {
            headers: getAuthorization(),
        })
            .then(checkAuthWithoutKicking)
            .then(getJson)
            .then(checkError)
    },

    fetchSim: id => dispatch => {
        return SimAction.promiseSim(id)
            .then((json = []) => {
                dispatch(SimAction.receiveSim(json))
                return json
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.sim} : ${e}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.sim))
            })
    },

    receiveSimTypes(simTypes) {
        return { type: RECEIVE_SIM_TYPES, simTypes }
    },

    promiseSimTypes(withEquipmentType) {
        return fetch(ApplicationConf.materiel.simAllTypes(withEquipmentType), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchSimTypes(withEquipmentType) {
        return (dispatch) => {
            return SimAction.promiseSimTypes(withEquipmentType)
                .then((json = {}) => {
                    dispatch(SimAction.receiveSimTypes(json))
                })
        }
    },

    updateSimTypes(id, materielType) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.simType(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(SimAction.fetchSimTypes(true))
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielType))
                })
        }
    },

    deleteSimType(id, callback = () => {}) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.simType(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(r => checkAuth(r, {
                    206: () => setMessageModal(i18n.deletingUsedMaterielTypeMessage),
                }))
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(SimAction.fetchSimTypes(true))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.materielType))
                })
        }
    },

    addSimTypes(materielType, callback = () => {}) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.simTypes(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(SimAction.fetchSimTypes(true))
                        dispatch(ToastrAction.success(i18n.addSuccess))
                        callback({ ...materielType, materielType: json.id })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.materielType))
                })
        }
    },

    receiveSimSituations(simSituations) {
        return { type: RECEIVE_ALL_SIM_SITUATIONS, simSituations }
    },

    fetchSimSituations(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.simSituations(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(SimAction.receiveSimSituations(json))
                })
        }
    },

    saveSimSituation(situation, shouldFetch = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.addSimSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(SimAction.fetchSimsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationAddSuccess))
                    if (shouldFetch) {
                        dispatch(SimAction.fetchSimSituations(situation.idSim))
                    }
                })
        }
    },

    deleteSimSituation(id, idSim) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.deleteSimSituation(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(() => {
                    dispatch(SimAction.fetchSimsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationDeleteSuccess))
                    dispatch(SimAction.fetchSimSituations(idSim))
                })
        }
    },

    updateSimSituation(idSituation, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.saveSimSituation(idSituation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(SimAction.fetchSimsLastSituations())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                    if (openCampaginModal) {
                        openCampaginModal(situation)
                    }
                })
        }
    },
    createEventAndUpdateSituation(urlEvent, event, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(urlEvent, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            }).then(getJson)
                .then((json) => {
                    if (json.eventId) {
                        const result = Object.assign({}, situation, {
                            eventCode: json.eventId,
                        })
                        dispatch(SimAction.updateSimSituation(result.id, result))
                        if (openCampaginModal) {
                            dispatch(openCampaginModal(result))
                        }
                    } else {
                        dispatch(ToastrAction.error(i18n.saveError))
                    }
                })
        }
    },

    updateSim(sim) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sim(sim.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(sim),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(SimAction.fetchSims(true))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        dispatch(SimAction.fetchSim(sim.id))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.sim} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.sim))
                })
        }
    },

    createSim(sim) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sims(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(sim),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && hasValue(json.id)) {
                        dispatch(SimAction.fetchSims(true))
                        dispatch(push(`/materiel/sim/${json.id}`))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        return json.id
                    }
                    throw new Error(json)
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.sim} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.sim))
                })
        }
    },

    deleteSim(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sim(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete > 0) {
                        dispatch(SimAction.fetchSims(true))
                        dispatch(push('/materiel/sim'))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.sim} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.sim))
                })
        }
    },

    promiseSimSituation(id) {
        return fetch(ApplicationConf.materiel.simSituations(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    receiveSimLastSituations(simsLastSituations) {
        return { type: RECEIVE_SIMS_LAST_SITUATIONS, simsLastSituations }
    },

    promiseSimsLastSituations: () => fetch(ApplicationConf.materiel.simsLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchSimsLastSituations: () => dispatch => SimAction.promiseSimsLastSituations()
        .then((json = []) => {
            dispatch(SimAction.receiveSimLastSituations(json))
        }),

    receiveSimPictures(simPictures) {
        return {
            type: RECEIVE_SIM_PICTURES,
            simPictures,
        }
    },

    fetchSimPictures(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.simPicture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(SimAction.receiveSimPictures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                })
        }
    },

    receiveSimFiles(simFiles) {
        return {
            type: RECEIVE_SIM_FILES,
            simFiles,
        }
    },

    fetchSimFiles(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.simFile(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(SimAction.receiveSimFiles(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
                })
        }
    },

    receiveSubscriptionsLink: subscriptionsLink => ({ type: RECEIVE_SIM_SUBSCRIPTION_LINK, subscriptionsLink }),
    promiseSubscriptionsLink: id => fetch(ApplicationConf.materiel.simSubscription(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchSubscriptionsLink: id => dispatch => SimAction.promiseSubscriptionsLink(id)
        .then(json => {
            dispatch(SimAction.receiveSubscriptionsLink(json))
        }),

    createSimSubscription: simSubscription => dispatch => fetch(ApplicationConf.materiel.createSimSubscription(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(simSubscription),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => {
            if (json.insert === 1) {
                dispatch(ToastrAction.success(i18n.elementCreateSuccess))
            } else {
                throw new Error(json)
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.createError} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError))
        }),

    closeLastSimSubscription: idSim => dispatch => fetch(ApplicationConf.materiel.closeLastSimSubscription(idSim), {
        method: 'PUT',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.createError} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError))
        }),

    resetSim() {
        return {
            type: RESET_SIM,
        }
    },
}

export default SimAction
