import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DtoJobTable from '../../dto/DtoJobTable'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel, nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getExecStatusLabel, getJobList } from '../../../utils/JobUtils'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import JobAction from '../../actions/JobAction'
import { IMPORT } from '../../../home/constants/RouteConstants'
import SieauAction from '../../../components/sieau/SieauAction'
import JobTableExecutionsForm from './JobTableExecutionsForm'
import { getFullDate } from '../../../utils/DateUtil'
import { getUser } from '../../../utils/SettingUtils'
import { groupBy, isUndefined } from 'lodash'
import { setActions } from '../../../components/ActionUtil'
import AppStore from '../../../store/AppStore'
import { arrayOf, objectOf } from '../../../utils/StoreUtils'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import WaitAction from 'wait/WaitAction'
import { searchAllCharacters } from 'utils/StringUtil'

class JobTableExecutions extends Component {
    constructor(props) {
        super(props)
        this.state = AppStore.getState().JobReducer.jobTableCache || {
            progress: 0,
            filters: {
                searchValue: undefined,
                startDate: null,
                scheduled: undefined,
                status: [],
                endDate: null,
                file: undefined,
                station: undefined,
                displayMode: 'job',
            },
            sort: {
                column: 'date',
                sort: 'desc',
            },
            dataLoaded: false,
        }
        this.state.dataLoaded = false
    }

    componentDidMount() {
        this.setTitle()
        this.props.fetchJobTable(this.state.filters).then(() => {
            this.setComponentActions()
            this.setState({ dataLoaded: true })
        })
    }

    setTitle() {
        this.props.forceFetch('title', [
            {
                title: i18n.jobs,
                href: 'job',
            },
        ])
    }

    onChangeSort(column, sort) {
        this.setState({ sort: { column, sort } }, () => AppStore.dispatch(JobAction.setJobCache(this.state)))
    }

    setComponentActions() {
        if (this.state.dataLoaded) {
            const newJob = (() => {
                const user = getUser()
                if (user.consultant !== '1' && user.isAdmin === '1') {
                    return {
                        new: () => {
                            this.props.push('/import/0')
                        },
                    }
                }
                return null
            })()
            const action = {
                ...newJob,
                export: () => {
                    const data = this.getExportData()
                    return {
                        data,
                        exportType: 'xlsx',
                        titleFile: i18n.jobs,
                    }
                },
            }
            setActions(action)
        }
    }

    getExportData() {
        const headers = ['jobId', 'jobType', 'jobExecutionId', 'jobName', 'statusCode', 'statusLabel', 'date', 'user', 'scheduled']
        const tableData = this.props.jobs.map(job => {
            return {
                statusCode: { value: job.status, format: '0', cellType: 'number' },
                statusLabel: getExecStatusLabel(job).replace('\n', ' '),
                date: { value: getFullDate(job.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
                scheduled: job.cron ? i18n.yes : i18n.no,
                user: job.login,
                jobId: { value: job.jobId, format: '0', cellType: 'number' },
                jobExecutionId: { value: job.jobExecutionId, format: '0', cellType: 'number' },
                jobName: job.jobName,
                jobType: job.jobType,
            }
        })
        tableData[0].headers = headers
        return tableData
    }

    fetchJobTable(filters, forceLoad=false) {
        this.props.waitStart()
        const searchStationValue = searchAllCharacters(filters.station)
        this.props.fetchJobTable({
            ...filters,
            station: searchStationValue || undefined,
            forceLoad,
            scheduled: filters.scheduled && (filters.scheduled === 1 || !(filters.scheduled === 2)),
        }).then(() => this.setState({ filters, dataLoaded: true }, () => (AppStore.dispatch(JobAction.setJobCache(this.state))))).finally(() => this.props.waitStop())
    }

    getSortStatus(status) {
        const statusCodes = {
            done: 1,
            done_warning: 2,
            hourglass_full: 3,
            local_cafe: 4,
            more_horiz: 5,
            error: 6,
        }

        return {
            ...status,
            sortValue: typeof status.value.props.children === 'object' ? statusCodes.done_warning : statusCodes[status.value.props.children] ?? 7,
        }
    }

    getTable() {
        const data = getJobList(this.props.jobs, this.props.contributorsIndex)
        const width = {
            jobId: '5%',
            jobExecutionId: '5%',
            jobType: '10%',
            jobName: '23%',
            status: '5%',
            date: '10%',
            user: '5%',
            administrator: '8%',
            programmation: '15%',
            nullValue: '3%',
            nullValu2: '3%',
            nullValu3: '3%',
        }

        if (this.state.filters.displayMode === 'job') {
            const grouped = groupBy(data.jobs, j => j.job.jobNextId || j.job.jobPreviousId ? 'chained' : (j.job.cron ? 'cron' : (j.job.status === 8 ? 'draft' : 'manual')))

            return (
                <>
                    {
                        !!(grouped.chained || []).length && (
                            <div className='row no-margin padding-right-1 padding-left-1' id='table_execution'>
                                <Table
                                    title={'Traitements chainés'}
                                    data={grouped.chained?.map(c => {
                                        return {
                                            ...c,
                                            status: this.getSortStatus(c.status),
                                        }
                                    }) || []}
                                    color
                                    paging
                                    initialSort={this.state.sort}
                                    onSort={(column, sort) => this.onChangeSort(column, sort)}
                                    type={data.type}
                                    nbPerPageLabel={nbPerPageLabelShort}
                                    sortable
                                    link={ IMPORT }
                                    forceLoad={() => this.fetchJobTable(this.state.filters, true)}
                                    condensed
                                    customWidthHeaders={width}
                                    customHeaders={{ jobExecutionId: i18n.execution }}
                                />
                            </div>
                        )
                    }
                    <div className='row no-margin padding-right-1 padding-left-1' id='table_execution'>
                        <Table
                            title={'Traitements programmés'}
                            data={grouped.cron?.map(c => {
                                return {
                                    ...c,
                                    status: this.getSortStatus(c.status),
                                }
                            }) || []}
                            color
                            paging
                            initialSort={this.state.sort}
                            onSort={(column, sort) => this.onChangeSort(column, sort)}
                            type={data.type}
                            nbPerPageLabel={nbPerPageLabelShort}
                            sortable
                            link={ IMPORT }
                            forceLoad={() => this.fetchJobTable(this.state.filters, true)}
                            condensed
                            customWidthHeaders={width}
                            customHeaders={{ jobExecutionId: i18n.execution }}
                        />
                    </div>
                    <div className='row no-margin padding-right-1 padding-left-1' id='table_execution'>
                        <Table
                            title={'Traitements manuels'}
                            data={grouped.manual?.map(c => {
                                return {
                                    ...c,
                                    status: this.getSortStatus(c.status),
                                }
                            }) || ''}
                            color
                            paging
                            initialSort={this.state.sort}
                            onSort={(column, sort) => this.onChangeSort(column, sort)}
                            type={data.type}
                            nbPerPageLabel={nbPerPageLabelShort}
                            sortable
                            link={ IMPORT }
                            forceLoad={() => this.fetchJobTable(this.state.filters, true)}
                            condensed
                            customWidthHeaders={width}
                            customHeaders={{ jobExecutionId: i18n.execution }}
                        />
                    </div>
                    <div className='row no-margin padding-right-1 padding-left-1' id='table_execution'>
                        <Table
                            title={'Traitements brouillons'}
                            data={grouped.draft?.map(c => {
                                return {
                                    ...c,
                                    status: this.getSortStatus(c.status),
                                }
                            }) || []}
                            color
                            paging
                            initialSort={this.state.sort}
                            onSort={(column, sort) => this.onChangeSort(column, sort)}
                            type={data.type}
                            nbPerPageLabel={nbPerPageLabelShort}
                            sortable
                            link={ IMPORT }
                            forceLoad={() => this.fetchJobTable(this.state.filters, true)}
                            condensed
                            customWidthHeaders={width}
                            customHeaders={{ jobExecutionId: i18n.execution }}
                        />
                    </div>
                </>
            )
        }


        return (
            <div className='row no-margin padding-right-1 padding-left-1' id='table_execution'>
                <Table
                    title={i18n.executions}
                    data={data.jobs?.map(c => {
                        return {
                            ...c,
                            status: this.getSortStatus(c.status),
                        }
                    })}
                    color
                    paging
                    initialSort={this.state.sort}
                    onSort={(column, sort) => this.onChangeSort(column, sort)}
                    type={data.type}
                    nbPerPageLabel={nbPerPageLabel}
                    sortable
                    link={ IMPORT }
                    count={this.props.count}
                    countDisplayed={data.jobs.length}
                    forceLoad={() => this.fetchJobTable(this.state.filters, true)}
                    condensed
                    customWidthHeaders={width}
                />
            </div>
        )
    }

    getPanel() {
        return (
            <div className='row no-margin'>
                <div className='row no-margin padding-right-1 padding-left-1'>
                    <div className='col s12 no-padding'>
                        <div className='card-content'>
                            <JobTableExecutionsForm onSubmit={(filters) => this.fetchJobTable(filters)} contributors={this.props.contributors} />
                        </div>
                    </div>
                </div>
                {this.getTable()}
            </div>
        )
    }

    render() {
        if (this.state.dataLoaded) {
            return this.getPanel()
        }
        return <ProgressCard progress={this.state.progress} />
    }

    componentDidUpdate() {
        this.setComponentActions()
    }
}

JobTableExecutions.propTypes = {
    jobs: PropTypes.arrayOf(PropTypes.instanceOf(DtoJobTable)),
    count: PropTypes.number,
    fetchJobTable: PropTypes.func,
    contributorsIndex: objectOf(ContributorDto),
    contributors: arrayOf(ContributorDto),
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
}

const mapStateToProps = store => ({
    jobs: store.JobReducer.jobTable.data,
    count: store.JobReducer.jobTable.count,
    contributorsIndex: store.ContributorReducer.contributorsIndex,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    fetchJobTable: JobAction.fetchJobTable,
    forceFetch: SieauAction.forceFetch,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(JobTableExecutions)