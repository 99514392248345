import fetch from 'isomorphic-fetch'
import { RECEIVE_ALL_SUPPORTS, RECEIVE_SUPPORT, RESET_SUPPORT } from '../constants/SupportConstants'
import ApplicationConf from 'conf/ApplicationConf'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import AppStore from 'store/AppStore'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { PATH_REFERENCIAL_SUPPORT, PATH_REFERENCIAL_SUPPORT_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'

const SupportAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().SupportReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },
    receiveSupports(supports) {
        return { type: RECEIVE_ALL_SUPPORTS, supports }
    },
    receiveSupport(support) {
        return { type: RECEIVE_SUPPORT, support }
    },

    fetchUpdateSupport() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateSupport(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        dispatch(SupportAction.fetchSupports())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdateOrInsertion))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.support + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.support))
                })
        }
    },

    fetchSupport(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.support(code), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(SupportAction.receiveSupport(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.support} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.support))
                })
        }
    },

    promiseSupports() {
        return fetch(ApplicationConf.referencial.supports(), {
            method: 'GET',
            headers: getAuthorization(),

        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    // eslint-disable-next-line consistent-return
    fetchSupports: (forceLoad = false) => (dispatch, getState) => {
        const {
            SupportReducer: { supports },
        } = getState()
        if (!supports.length || forceLoad) {
            return SupportAction.promiseSupports()
                .then((json = []) => {
                    dispatch(SupportAction.receiveSupports(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.supports} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.supports))
                })
        }
        return Promise.resolve()
    },

    createSupport(support) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.supports(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(support),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1) {
                        dispatch(push(PATH_REFERENCIAL_SUPPORT_LINK + support.code))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                        dispatch(SupportAction.fetchSupports(true))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.support} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.support))
                })
        }
    },

    updateSupport(support, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveSupport(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(support),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(SupportAction.fetchSupport(code))
                        dispatch(SupportAction.fetchSupports(true))
                        dispatch(ToastrAction.success(i18n.supportSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.support} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.support))
                })
        }
    },

    deleteSupport(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteSupport(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_SUPPORT))
                        dispatch(SupportAction.fetchSupports(true))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.support} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.support))
                })
        }
    },

    purgeSupport() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeSupport(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(SupportAction.fetchSupports(true))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.supports} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.supports))
                })
        }
    },

    replaceSupport(support, supportReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceSupport(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: support.toString(),
                    replaceCode: supportReplace.toString(),
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.supportSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.support} : ${err}`))
                })
        }
    },

    reset() {
        return { type: RESET_SUPPORT }
    },
}

export default SupportAction
