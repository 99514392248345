import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import Checkbox from '../../../components/forms/Checkbox'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getLogin, getSetting, getUser } from '../../../utils/SettingUtils'
import moment from 'moment'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import InstallationInfosPanel from '../installations/components/InstallationInfosPanel'
import InstallationComElecPanel from '../installations/components/InstallationComElecPanel'
import InstallationAccessSitePanel from '../installations/components/InstallationAccessSitePanel'
import InstallationAccessCasingPanel from '../installations/components/InstallationAccessCasingPanel'
import StationLocationInfoPanel from '../../../station/components/location/StationLocationInfoPanel'
import Row from '../../../components/react/Row'
import InstallationStaffSafetyPanel from '../installations/components/InstallationStaffSafetyPanel'
import CaptureCivilEngPanel from './components/CaptureCivilEngPanel'
import CaptureEquipmentsPanel from './components/CaptureEquipmentsPanel'
import DtoCaptureStation from './dto/DtoCaptureStation'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import NumberField from '../../../components/forms/NumberField'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import CaptureDownstreamDistribUnitPanel from './components/CaptureDownstreamDistribUnitPanel'
import DistributionUnitAction from '../../../distributionUnit/actions/DistributionUnitAction'
import HydrogeologicalEntityAction from '../../../referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import {
    getVisitCheckBoxChange,
    getVisitDatePickerChange,
    getVisitSandreSelectChange,
    getVisitSelectChange,
} from '../../../utils/VisitUtils'
import { onChangeDate } from '../../../utils/FormUtils'
import { getDate } from '../../../utils/DateUtil'
import LocalisationMapModal from '../installations/components/modal/LocalisationMapModal'
import { H_DISTRIBUTION_MODULE, H_PRODUCTION_MODULE } from '../../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import StationSamplersPanel from 'station/components/link/StationSamplersPanel'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { greyBlue } from 'utils/constants/ColorTheme'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'

class CaptureStationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            readMode: true,
            capture: new DtoCaptureStation({}),
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationCapture(this.props.installation.id).then(() => {
                this.setState({ capture: { ...this.props.installationCapture, idStation: this.props.installation.id } })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.captureAlimAreas.length) {
            this.props.fetchAllCaptureAlimAreas()
        }

        if (!this.props.captureDownstreamDistribUnits.length) {
            this.props.fetchCaptureDownstreamDistribUnits()
        }

        if (!this.props.hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }

        if (!this.props.productionUnits.length) {
            this.props.fetchProductionUnits()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () => this.props.push('/installation'))
    }

    onSave = (visit) => {
        const { installation, capture } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    capture,
                }
            ).then(() => this.setReadOnlyMode())
        }

        return this.props.updateInstallationCapture(
            installation,
            capture
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, capture: this.props.installationCapture })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) => this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeCapture = (newObject) => this.setState({ capture: { ...this.state.capture, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, readMode, capture, localizeMap } = this.state
        const { sandreCodes, accountHabilitations } = this.props
        const mode = { readMode, editMode: !readMode }
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_CAPTAGES,
        }

        const paramsCapture = {
            onChange: this.onChangeCapture,
            onChangeVisit: this.onChangeVisit,
            capture,
            readMode,
        }

        return (
            <div className='row padding-top-1 margin-left-2 padding-bottom-5' id='CaptureStationDescriptionPanel'>
                <div className='col s9' style={ { marginBottom: '100px' } }>
                    <Card title={ i18n.description } >
                        <InstallationInfosPanel { ...params } />
                        <Row>
                            <Select
                                col={ 6 }
                                value={ capture.prodSite }
                                label={ i18n.productionUnit }
                                options={ this.props.productionUnits }
                                onChange={ v => {
                                    this.onChangeCapture({ prodSite: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.productionUnits, 'id', i18n.productionUnit, capture.prodSite, v))
                                } }
                                keyValue='id'
                                integerValue
                                readMode={ readMode }
                            />
                            <Select
                                col={ 6 }
                                value={ capture.aacCode }
                                label={ i18n.captureAlimArea }
                                options={ this.props.captureAlimAreas }
                                keyvalue='code'
                                onChange={ v => {
                                    this.onChangeCapture({ aacCode: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.captureAlimAreas, 'code', i18n.captureAlimArea, capture.aacCode, v))
                                } }
                                integerValue
                                readMode={ readMode }
                            />
                        </Row>
                        <Row>
                            <Input
                                col={ 6 }
                                value={ capture.sampleOrigin }
                                title={ i18n.sampleOrigin }
                                onChange={ v => {
                                    this.onChangeCapture({ sampleOrigin: v })
                                    this.onChangeVisit({ previousValue: capture.sampleOrigin, newValue: v, field: i18n.sampleOrigin })
                                } }
                                readMode={ readMode }
                            />
                            <NumberField
                                col={ 6 }
                                value={ capture.qMoyJ }
                                title={ 'QMoyJ' }
                                onChange={ v => {
                                    this.onChangeCapture({ qMoyJ: v })
                                    this.onChangeVisit({ previousValue: capture.qMoyJ, newValue: v, field: 'QMoyJ' })
                                } }
                                readMode={ readMode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 6 }
                                value={ capture.waterNature }
                                label={ i18n.waterNature }
                                keyvalue='code'
                                options={ getSandreList(sandreCodes, SANDRE.NATURE_EAU) }
                                onChange={ v => {
                                    this.onChangeCapture({ waterNature: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.NATURE_EAU, i18n.waterNature, capture.waterNature, v))
                                } }
                                integerValue
                                readMode={ readMode }
                            />
                            <Select
                                col={ 6 }
                                value={ capture.hydroEntityCode }
                                label={ i18n.hydrogeologicalEntities }
                                keyvalue='code'
                                options={ this.props.hydrogeologicalEntities }
                                onChange={ v => {
                                    this.onChangeCapture({ hydroEntityCode: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.hydrogeologicalEntities, 'code', i18n.hydrogeologicalEntities, capture.hydroEntityCode, v))
                                } }
                                integerValue
                                readMode={ readMode }
                            />
                        </Row>
                        <Card title={i18n.civilEngineering} headerStyle={{ backgroundColor: greyBlue }} className='padding-left-1 padding-right-1 padding-bottom-1' displayIf={ this.isDisplayed('CAPTURE_CIVIL_ENG') }>
                            <CaptureCivilEngPanel { ...paramsCapture } />
                            <Row className='padding-left-1 padding-right-1'>
                                <BuildingsPanel {...params} />
                            </Row>
                        </Card>
                        <Card title={i18n.equipments} headerStyle={{ backgroundColor: greyBlue }} className='padding-left-1 padding-right-1' displayIf={ this.isDisplayed('CAPTURE_EQUIPMENTS') }>
                            <CaptureEquipmentsPanel { ...paramsCapture } />
                            <Row>
                                <InstallationEquipmentsPanel className='padding-left-1 padding-right-1' { ...params } />
                            </Row>
                        </Card>
                    </Card>
                    <Card title={ i18n.communicationElectricity } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ELEC_COM') }>
                        <InstallationComElecPanel { ...params } />
                    </Card>
                    <Card title={ i18n.accessSite } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ACCESS_SITE') }>
                        <InstallationAccessSitePanel { ...params } />
                    </Card>
                    <Card title={ i18n.accessCasing } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ACCESS_CASING') }>
                        <InstallationAccessCasingPanel { ...params } />
                    </Card>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('STAFF_SAFETY') }>
                        <InstallationStaffSafetyPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('CONTRIBUTORS') }>
                        <StationPiezoContributorPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('USAGES') }>
                        <StationUsagePanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('ARRANGEMENTS') }>
                        <StationArrangementPanel { ...params } />
                    </Row>
                    <HabilitationRequired habilitation={H_PRODUCTION_MODULE}>
                        <div className='margin-top-1'>
                            <LinkedStationsPanel
                                title={ i18n.productionUnit }
                                filteredStations={ ['productionUnit'] }
                                station={ installation }
                                { ...mode }
                            />
                        </div>
                    </HabilitationRequired>
                    <HabilitationRequired habilitation={H_DISTRIBUTION_MODULE}>
                        <Card title={ i18n.distributionUnit } className='margin-top-1 no-margin-bottom' noMargin={ false }>
                            <CaptureDownstreamDistribUnitPanel station={ installation } />
                        </Card>
                    </HabilitationRequired>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={accountHabilitations.some((h) => h.habilitation === AGRI) && this.isDisplayed('SAMPLERS')}>
                        <StationSamplersPanel {...params}/>
                    </Row>
                </div>
                <div className='col s3' style={ { marginBottom: '100px' } }>
                    <StationUpdatePanel station={ installation } />
                    <Card noMargin={ false } className='margin-top-1 padding-bottom-1'>
                        <Row className={ `${!readMode && 'padding-top-1' || ''}` }>
                            <Input
                                col={ 12 }
                                title={ i18n.dataOrigin }
                                value={ installation.dataOrigin }
                                onChange={ v => {
                                    this.onChangeInstallation({ dataOrigin: v })
                                    this.onChangeVisit({ previousValue: installation.dataOrigin, newValue: v, field: i18n.dataOrigin })
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.status }
                                value={ installation.status }
                                onChange={ v => {
                                    this.onChangeInstallation({
                                        status: v,
                                        statusLogin: getLogin(),
                                        statusDate: moment().valueOf(),
                                    })
                                    this.onChangeVisit(getVisitSelectChange(this.props.status, 'code', i18n.status, installation.status, v))
                                } }
                                options={ this.props.status }
                                keyvalue='code'
                                integerValue
                                { ...mode }
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.statusDate) }
                                title={ i18n.controlDoneAt }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ statusDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.controlDoneAt, installation.statusDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.controlDoneBy }
                                value={ installation.statusLogin }
                                keyvalue='name'
                                onChange={ v => {
                                    this.onChangeInstallation({ statusLogin: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.users.map(u => ({ code: u.login, name: u.name || u.login })), 'name', i18n.controlDoneBy, installation.statusLogin, v))
                                } }
                                options={ this.props.users.map(u => ({ code: u.login, name: u.name || u.login })) } { ...mode }
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.endDate) }
                                title={ i18n.deliberationDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ endDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.deliberationDate, installation.endDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } value={ installation.closeComment } title={ i18n.become }
                                onChange={ v => {
                                    this.onChangeInstallation({ closeComment: v })
                                    this.onChangeVisit({ previousValue: installation.closeComment, newValue: v, field: i18n.become })
                                } } { ...mode }
                            />
                        </Row>
                        <Row>
                            <Checkbox col={ 12 } label={ i18n.confidential } checked={ installation.confidential === '1' }
                                onChange={ v => {
                                    this.onChangeInstallation({ confidential: v ? '1' : '0' })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.confidential, installation.confidential === '1', v))
                                } } disabled={ this.state.readMode }
                            />
                        </Row>
                    </Card>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={ i18n.contact }
                            contactCode={ installation.ownerCode }
                            onChange={ v => {
                                this.onChangeInstallation({ ownerCode: v })
                                this.onChangeVisit(getVisitSelectChange(this.props.contacts, 'code', i18n.contact, installation.ownerCode, v))
                            } }
                            readMode={ readMode }
                        />
                    </Row>
                    <LastEventPanel id={ installation.id } events={ this.props.installationEvents } stationId={ installation.id }
                        stationType='installation'
                    />
                    <Card
                        title={ i18n.map }
                        noMargin={ false }
                        className='margin-top-1'
                        actions={
                            !this.state.readMode ?
                                [{
                                    iconName: 'my_location',
                                    onClick: () => this.setState({ localizeMap: true }),
                                }] : []
                        }
                    >
                        <StationMapDashboardPanel noMarkerTooltip station={this.props.installation} type={'installation'} />
                        <LocalisationMapModal
                            onChangeInstallation={this.onChangeInstallation}
                            installation={installation}
                            readMode={readMode}
                            open={localizeMap}
                            onClose={ () => this.setState({ localizeMap: false }) }
                        />
                    </Card>
                    <Card title={ i18n.localisation } noMargin={ false } className='margin-top-1'>
                        <StationLocationInfoPanel
                            onChange={ this.onChangeInstallation }
                            onChangeVisit={ this.onChangeVisit }
                            station={ installation }
                            readMode={ readMode }
                        />
                    </Card>
                    <LinkedStationsPanel
                        noMargin={ false }
                        className='margin-top-1'
                        filteredStations={ ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'installation'] }
                        onReMount={ this.props.onReMount }
                        station={ installation }
                        { ...mode }
                    />
                </div>
            </div>
        )
    }

    shouldComponentUpdate(_, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

CaptureStationDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationCapture: store.InstallationReducer.installationCapture,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    captureAlimAreas: store.InstallationReducer.captureAlimAreas,
    hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    captureDownstreamDistribUnits: store.DistributionUnitReducer.captureDownstreamDistribUnits,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    productionsUnits: store.ProductionUnitReducer.productionsUnits,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationCapture: InstallationAction.updateInstallationCapture,
    fetchInstallationCapture: InstallationAction.fetchInstallationCapture,
    fetchAllCaptureAlimAreas: InstallationAction.fetchAllCaptureAlimAreas,
    fetchCaptureDownstreamDistribUnits: DistributionUnitAction.fetchCaptureDownstreamDistribUnits,
    fetchHydrogeologicalEntities: HydrogeologicalEntityAction.fetchHydrogeologicalEntities,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
    fetchProductionUnits: ProductionUnitAction.fetchProductionUnits,
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureStationDescriptionPanel)
