import React, { Component } from 'react'
import { arrayOf, getSandreList, instanceOf, removeNullKeys } from '../../../../utils/StoreUtils'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import Card from '../../../../components/card/Card'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import NumberField from '../../../../components/forms/NumberField'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { hasValue } from '../../../../utils/NumberUtil'
import AppStore from '../../../../store/AppStore'
import InstallationAction from '../../../actions/InstallationAction'
import { push } from 'connected-react-router'

// import moment from 'moment'

class InstallationsConfirmationMergeModal extends Component {
    componentWillReceiveProps(nextProps) {
        if (!this.props.popupIsOpen && nextProps.popupIsOpen) {
            // TODO: add technical event on merge
            // const event = {
            //     code: this.props.toInstallation.id,
            //     eventDate: moment().valueOf(),
            //     eventHour: moment().valueOf(),
            //     date: moment().valueOf(),
            //     eventType: 'T'
            // }
            $('#confirmMerge').on('click', () => {
                AppStore.dispatch(InstallationAction.mergeInstallation(this.props.fromInstallation.id, this.props.toInstallation.id, () => {
                    AppStore.dispatch(push(`/station/installation/${this.props.toInstallation.id}/description`))
                    $('.modal').modal('close')
                }))
            })
        }
    }

    getOriginIcon = (installation) => {
        return hasValue(installation.jobExecutionId) ?
            <i className='material-icons small tooltipped' data-tooltip={ `${i18n.automaticUpdate}</br>${installation.dataOrigin}` }>wifi</i> : null
    }

    getInstallationCard = (i) => {
        return (
            <Card title={ [this.getOriginIcon(i), `${i.code || i.id} - ${i.name}`] }>
                <div className='row no-margin padding-top-1'>
                    <Input col={ 12 } title={ i18n.code } value={ i.code } disabled/>
                </div>
                <div className='row no-margin'>
                    <Input col={ 12 } title={ i18n.name } value={ i.name } disabled/>
                </div>
                <div className='row no-margin'>
                    <Select col={ 12 } label={ i18n.city } value={ i.townCode } options={ this.props.cities } disabled/>
                </div>
                <div className='row no-margin'>
                    <Select col={ 12 } label={ i18n.owner } value={ i.ownerCode } options={ this.props.contacts } disabled/>
                </div>
                <div className='row no-margin'>
                    <NumberField col={ 6 } title={ 'X' } value={ i.x } disabled/>
                    <NumberField col={ 6 } title={ 'Y' } value={ i.y } disabled/>
                </div>
                <div className='row no-margin'>
                    <Input col={ 12 } title={ i18n.dataOrigin } value={ i.dataOrigin } disabled/>
                </div>
                <div className='row no-margin'>
                    <NumberField col={ 6 } title={ 'Z' } value={ i.altitude } disabled/>
                    <Select
                        col={6} label={i18n.projection} value={i.projection}
                        options={ getSandreList(this.props.sandreCodes, SANDRE.PROJECTION) } disabled
                    />
                </div>
            </Card>
        )
    }

    render() {
        const installation = { ...removeNullKeys(this.props.fromInstallation), ...removeNullKeys(this.props.toInstallation) }
        return (
            <div>
                { this.getInstallationCard(installation) }
            </div>
        )
    }
}

InstallationsConfirmationMergeModal.propTypes = {
    fromInstallation: instanceOf(DtoInstallation),
    toInstallation: instanceOf(DtoInstallation),
    popupIsOpen: PropTypes.bool,
    cities: arrayOf(CityDto),
    contacts: arrayOf(ContactDto),
    sandreCodes: arrayOf(DtoSandreCode),
}

const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(InstallationsConfirmationMergeModal)