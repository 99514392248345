import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import NumberField from '../../../../components/forms/NumberField'
import moment from 'moment'
import Card from '../../../../components/card/Card'
import i18n from 'simple-react-i18n'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { hasValue } from '../../../../utils/NumberUtil'
import { chunk, groupBy, max, maxBy, min, orderBy, range, take } from 'lodash'
import Table from '../../../../components/datatable/Table'
import MessageCard from '../../../../components/card/MessageCard'
import { getStateLabels } from '../../../../utils/QualityUtils'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import WatermassAction from '../actions/WatermassAction'
import WatermassStateModal from './WatermassStateModal'
import QualityAction from '../../../../quality/actions/QualityAction'
import { PATH_REFERENCIAL, PATH_REFERENCIAL_WATERMASS, PATH_REFERENCIAL_WATERMASS_ITEM_LINK } from 'home/constants/RouteConstants'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import { getYearDate } from 'utils/DateUtil'
import { ButtonMUI } from 'components/styled/Buttons'
import Checkbox from 'components/forms/Checkbox'
import { Grid } from '@mui/material'

const byYear = 1

const WatermassFilter = ({
    filter = {},
    setFilter = () => {},
}) => {
    const {
        watermassStates,
    } = useSelector(store => ({
        watermassStates: store.WatermassReducer.watermassStates,
    }), shallowEqual)

    const [startYear, setStartYear] = useState(filter.startYear)
    const [endYear, setEndYear] = useState(filter.endYear)
    const [yearChoice, setYearChoice] = useState(filter.yearChoice)
    const [notDisplayYearsWithoutData, setNotDisplayYearsWithoutData] = useState(filter.notDisplayYearsWithoutData)

    const yearChoices = [
        { code: 1, name: i18n.year },
        { code: 2, name: `${2} ${i18n.nbYears}` },
        { code: 3, name: `${3} ${i18n.nbYears}` },
    ]

    const setDefaultFilter = () => {
        const years = watermassStates.map(w => parseInt(getYearDate(w.startDate)))
        const smallDate = min(years)
        const maxDate = max(years)
        setStartYear(smallDate)
        setEndYear(maxDate)
        setYearChoice(byYear)
        setNotDisplayYearsWithoutData(false)
        setFilter({ ...filter, startYear: smallDate, endYear: maxDate, notDisplayYearsWithoutData: false, yearChoice: byYear })
    }

    useEffect(() => {
        setDefaultFilter()
    }, [watermassStates])

    return (
        <Card className='padding-1'>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <NumberField
                        title={ i18n.startYear }
                        value={ startYear }
                        onChange={v => {
                            setStartYear(v)
                            setYearChoice(hasValue(v) ? yearChoice : 1)
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <NumberField
                        title={ i18n.endYear }
                        value={ endYear }
                        onChange={v => {
                            setEndYear(v)
                            setYearChoice(hasValue(v) ? yearChoice : 1)
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <RadioButtons
                        elements={ yearChoices }
                        title={ i18n.regrouping }
                        selected={ yearChoice }
                        onChange={ setYearChoice }
                        disabled={ !hasValue(startYear) || !hasValue(endYear) }
                    />
                </Grid>
                <Grid item xs={3}>
                    <Checkbox
                        componentClassName='margin-top-1'
                        label={i18n.notDisplayYearsWithoutData}
                        checked={notDisplayYearsWithoutData}
                        onChange={setNotDisplayYearsWithoutData}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='flex-end'>
                <ButtonMUI
                    onClick={setDefaultFilter}
                    variant='outlined'
                >
                    {i18n.reinit}
                </ButtonMUI>
                <ButtonMUI style={{ margin: '0 10px' }} onClick={() => setFilter({ startYear, endYear, notDisplayYearsWithoutData, yearChoice })} variant='contained'>
                    {i18n.search}
                </ButtonMUI>
            </Grid>
        </Card>
    )
}

WatermassFilter.propTypes = {
    filter: PropTypes.shape({
        startYear: PropTypes.number,
        endYear: PropTypes.number,
        notDisplayYearsWithoutData: PropTypes.bool,
        yearChoice: PropTypes.number,
    }),
    setFilter: PropTypes.func,
    setDefaultFilter: PropTypes.func,
}

const WatermassStatesApp = ({
    match = {},
}) => {
    const {
        watermass,
        watermassStates,
        qualityStates,
    } = useSelector(store => ({
        watermass: store.WatermassReducer.watermass,
        watermassStates: store.WatermassReducer.watermassStates,
        qualityStates: store.QualityReducer.qualityStates,
    }), shallowEqual)

    const [filter, setFilter] = useState({ startYear: undefined, endYear: undefined, notDisplayYearsWithoutData: false, yearChoice: byYear })

    const dispatch = useDispatch()

    const setStateModal = useCallback((isNew, watermassState = {}) => {
        const buttons = (
            <div>
                {!isNew && <a id='deleteStates' className='waves-effect waves-teal btn-flat modal-close'>{ i18n.delete }</a>}
                <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.cancel }</a>
                <a id='saveStates' className='waves-effect waves-teal btn-flat'>{ i18n.register }</a>
            </div>
        )
        const popup = {
            id: 'statePopup',
            header: i18n.editState,
            actions: buttons,
            fixedFooter: true,
            dismissible: false,
            content: <WatermassStateModal isNew={ isNew } watermassState={ watermassState }/>,
        }
        dispatch(SieauAction.setPopup(popup))
    }, [dispatch])

    const allYears = useMemo(() => {
        if (hasValue(filter.startYear) && hasValue(filter.endYear)) {
            const years = take(range(filter.startYear, filter.endYear + 1), 100)
            if (filter.yearChoice == 1) {
                return years
            }
            return chunk(years, filter.yearChoice).map(group => group.map(y => y.toString().slice(2)).join('-'))
        } else if (hasValue(filter.startYear)) {
            return [filter.startYear]
        } else if (hasValue(filter.endYear)) {
            return [filter.endYear]
        }
        return []
    }, [filter.endYear, filter.startYear, filter.yearChoice])

    const getStateYear = useCallback((s, years) => {
        if (filter.yearChoice == byYear) {
            return moment(s.startDate).year()
        }
        return years.find(y => y.includes(moment(s.startDate).year().toString().slice(2)))
    }, [filter.yearChoice])

    const filterStates = useMemo(() => {
        const filterByYearData = filter.notDisplayYearsWithoutData ? watermassStates.filter(w => hasValue(w.value)) : watermassStates
        const filterStartYear = hasValue(filter.startYear) ? filterByYearData.filter(s => moment(s.startDate).year() >= filter.startYear) : filterByYearData
        return hasValue(filter.endYear) ? filterStartYear.filter(s => moment(s.startDate).year() <= filter.endYear) : filterStartYear
    }, [filter.endYear, filter.notDisplayYearsWithoutData, filter.startYear, watermassStates])

    const allYearsFiltered = filter.notDisplayYearsWithoutData ? allYears.filter(year => filterStates.find(s => year === moment(s.startDate).year())) : allYears

    const headers = useMemo(() => ['state', ...allYearsFiltered], [allYearsFiltered])

    const stateGroup = useMemo(() => groupBy(filterStates, 'stateId'), [filterStates])

    const stateLabels = useMemo(getStateLabels, [])

    const data = useMemo(() => orderBy(Object.keys(stateGroup).map(key => {
        const states = stateGroup[key]
        const stateByYearGroups = allYearsFiltered.length > 0 ? groupBy(states, s => getStateYear(s, allYearsFiltered)) : {}
        const years = Object.keys(stateByYearGroups).reduce((acc, val) => {
            const yearStates = stateByYearGroups[val]
            const worst = maxBy(yearStates, 'value')
            return ({
                ...acc,
                [val]: {
                    ...(stateLabels[worst?.value] || { color: 'white' }),
                    tooltip: worst?.comment,
                    className: worst?.comment && 'tooltipped clickable',
                    onClick: () => setStateModal(false, worst),
                },
            })
        }, {})
        return Object.keys(years).length > 0 ? {
            state: states[0].stateName,
            order: states[0].order,
            ...years,
        } : null
    }).filter(d => d), 'order'), [allYearsFiltered, getStateYear, setStateModal, stateGroup, stateLabels])

    const onExport = () => ({
        data: data.length ? [
            { ...data[0], headers },
            ...data.slice(1),
        ] : [],
        titleFile: `${watermass.code} - ${i18n.states}`,

    })

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    },
    {
        title: i18n.watermasses,
        href: PATH_REFERENCIAL_WATERMASS,
    },
    {
        title: match.params.code + (watermass.label ? ` - ${watermass.label}` : ''),
        href: PATH_REFERENCIAL_WATERMASS_ITEM_LINK + match.params.code,
    }], [])

    useActions(() => ({
        export: onExport,
        new: () => setStateModal(true),
    }), [data])

    useEffect(() => {
        dispatch(WatermassAction.fetchWatermass(match.params.code))
        dispatch(WatermassAction.fetchWatermassStates(match.params.code))
        if (!qualityStates.length) {
            dispatch(QualityAction.fetchQualityStates())
        }
    }, [dispatch, qualityStates.length, match.params.code])

    return (
        <div style={{ margin: '5px 5px 0 10px' }}>
            <WatermassFilter
                filter={filter}
                setFilter={setFilter}
            />
            <Card noMargin={false}>
                <div className='col s12 no-padding'>
                    {data.length ? (
                        <Table
                            sortable
                            condensed
                            type={ { headers } }
                            showTitle={ false }
                            data={ data }
                            nbPerPageLabel={ nbPerPageLabel }
                            paging
                        />
                    ) : (
                        <MessageCard>{ i18n.noStates }</MessageCard>
                    )}
                </div>
            </Card>
        </div>
    )
}

WatermassStatesApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }).isRequired,
}

export default WatermassStatesApp