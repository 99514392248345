import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../../components/sieau/SieauAction'
import ActionComponent from '../../../../components/ActionComponent'
import DtoMaterielSituation from '../../../../materiel/dto/DtoMaterielSituation'
import { isEqual, omit, orderBy } from 'lodash'
import {
    getStationTitle,
    getStationTypeCodeFromType,
    getStationTypeNameFromTypeCode,
} from '../../../../utils/StationUtils'
import DtoMaterielState from '../../../../materiel/dto/DtoMaterielState'
import Table from '../../../../components/datatable/Table'
import Card from '../../../../components/card/Card'
import SituationStationForm from './SituationStationForm'
import moment from 'moment'
import { getUser } from '../../../../utils/SettingUtils'
import { MATERIEL_STATE_ON_SITE_ID } from '../../../../materiel/constants/MaterielStateConstants'
import { createIconMaterialState } from '../../../../utils/MaterielUtils'
import DtoStation from '../../../dto/DtoStation'
import EventsAction from '../../../../events/actions/EventsAction'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import DtoEvent from '../../../../events/dto/DtoEvent'
import { getDate } from '../../../../utils/DateUtil'
import { EVENT_TYPES_CODES } from '../../../../events/constants/EventsConstants'
import InstallationAction from '../../../../installation/actions/InstallationAction'
import DtoInstallationEvent from '../../../../installation/dto/installation/DtoInstallationEvent'
import { getLabel } from '../../../../utils/StoreUtils'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'

class SituationDetailPanel extends ActionComponent {
    state = {
        situation: new DtoMaterielSituation({}),
        isEditMode: false,
        newMaterielSituation: new DtoMaterielSituation({}),
        shouldReplace: false,
        openPopup: false,
        step: 0,
        event: {},
        additionalStepFinish: !this.props.additionalStep || this.props.additionalStep === null,
    }

    componentDidMount() {
        this.setTitle()
        this.initAction()
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.station, this.props.station)) {
            this.setTitle()
        }
        if (this.state.isEditMode !== prevState.isEditMode) {
            this.initAction()
        }
    }

    setTitle = () => {
        const { id, type, materialType } = this.props.params
        this.props.forceFetch('title', [{
            title: i18n[type],
            href: type,
        }, {
            title: getStationTitle(this.props.station),
            href: `station/${type}/${id}`,
        }, {
            title: i18n.materiels,
            href: `station/${type}/${id}/materiel`,
        }, {
            title: i18n[materialType],
            href: `station/${type}/${id}/materiel/${materialType}`,
        }])
    }

    changeValue = (key, value) => {
        const { keyMaterielId } = this.props
        if (key === keyMaterielId) {
            if (!value) {
                this.props.resetMateriel()
            } else if (value !== this.state.situation[keyMaterielId]) {
                this.props.fetchMateriel(value)
            }
        }
        this.setState({
            situation: {
                ...this.state.situation,
                [key]: value,
            },
        })
    }

    resetSituation = (situation, editMode) => {
        this.props.resetMateriel()
        this.setState({
            situation: situation || this.createNewSituation(false),
            isEditMode: editMode || false,
            newMaterielSituation: new DtoMaterielSituation({}),
            shouldReplace: false,
            openPopup: false,
            step: 0,
        })
    }

    addEvent = (type, id, event) => {
        if (type === 'installation') {
            return this.props.addInstallationEvent(id, event)
        }
        return this.props.addEvent(type, id, event)
    }

    saveSituation = () => {
        const { id, type } = this.props.params
        const {
            situation,
            newMaterielSituation,
            shouldReplace,
            step,
        } = this.state

        const situationFormat = (() => {
            const result = {
                ...situation,
                situationDate: +moment(situation.situationDate),
                situationEndDate: situation.situationEndDate ? +moment(situation.situationEndDate) : undefined,
                siteName: `[${this.getStationCode(this.props.station)}] - ${this.props.station.name}`,
            }
            if (!result.situationEndDate && !result.statusCode) {
                return {
                    ...result,
                    statusCode: MATERIEL_STATE_ON_SITE_ID,
                }
            }
            return result
        })()

        if (situation.id) {
            this.props.saveSituation(situationFormat, id, type)
            this.resetSituation()
        } else if (step === 1) {
            this.props.addSituation({
                ...situationFormat,
                siteCode: Number(id),
                siteType: getStationTypeCodeFromType(type),
                statusCode: MATERIEL_STATE_ON_SITE_ID,
            }, id, type)
            if (shouldReplace) {
                this.props.addSituation({
                    ...situation,
                    ...newMaterielSituation,
                    situationDate: +moment(situation.situationDate),
                }, id, type)
            }
            this.props.updateMateriel()
            this.resetSituation()
        } else {
            const event = {
                code: parseInt(id),
                date: situationFormat.situationDate,
                eventType: 'T',
                graph: '0',
                bankExport: '0',
                eventHour: situationFormat.situationDate,
            }
            this.addEvent(type, id, event).then(eventCode => {
                if (eventCode) {
                    this.props.addSituation({
                        ...situationFormat,
                        siteCode: Number(id),
                        siteType: getStationTypeCodeFromType(type),
                        statusCode: MATERIEL_STATE_ON_SITE_ID,
                        eventCode,
                    }, id, type).then(() => {
                        if (shouldReplace) {
                            this.props.addSituation({
                                ...situation,
                                ...newMaterielSituation,
                                siteCode: Number(id),
                                siteType: getStationTypeCodeFromType(type),
                                situationDate: +moment(situation.situationDate),
                                eventCode,
                            }, id, type)
                        }
                    })
                    this.props.updateMateriel()
                    this.resetSituation()
                }
            })
        }
    }

    createNewSituation = (initDate = true) => new DtoMaterielSituation({
        situationDate: initDate ? moment() : undefined,
    })

    deleteSituation = () =>
        this.props.deleteSituation(this.state.situation, this.props.params.id, this.props.params.type).then(() => {
            this.resetSituation()
        })

    initAction = () => {
        const actions = (() => {
            if (this.state.isEditMode) {
                return (() => {
                    const result = {
                        save: () => {
                            if (this.state.situation.id) {
                                this.saveSituation()
                            } else {
                                this.setState({ openPopup: true })
                            }
                        },
                        cancel: () => {
                            this.resetSituation()
                        },
                    }
                    if (this.state.situation && this.state.situation.id) {
                        return {
                            ...result,
                            delete: () => {
                                this.deleteSituation()
                            },
                        }
                    }
                    return result
                })()
            }
            return {
                new: () => this.newAction(),
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['new', 'save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    newAction = () => this.setState({
        situation: this.createNewSituation(),
        isEditMode: true,
    })

    getStationCode = ({ code, designation }) => designation ? `${code}/${designation}` : code

    getMaterielNumber = ({ imei, serialNumber, reference, numLine, id }) => imei || serialNumber || reference || numLine || id || ''

    getMaterialLabel = (mat, materielTypes, keyMaterielType) => {
        const result = this.getMaterielNumber(mat)
        if (mat[keyMaterielType]) {
            const type = materielTypes.find(b => b.id === mat[keyMaterielType])
            if (type) {
                return `${type.label} - ${result}`
            }
        }
        return result
    }

    getMaterielData = () => {
        const { materiels, materielTypes, keyMaterielType } = this.props
        return materiels.map(mat => ({
            id: mat.id,
            label: this.getMaterialLabel(mat, materielTypes, keyMaterielType),
        }))
    }

    changeStateSituation = (materielSituation, situations) => {
        const situation = situations.find(o => o.id === materielSituation.id) || {}
        this.resetSituation(situation, true)
    }

    getValidateButton = step => {
        const {
            situation,
        } = this.state
        const isBlock = step === 1 && !situation.eventCode
        return (
            <Button disabled={isBlock} variant='contained' color='primary' onClick={() => !isBlock && this.saveSituation()} >
                {i18n.validate}
            </Button>
        )
    }

    getFinishAdditionalStepButton = () => {
        return (
            <Button variant='outlined' onClick={() => this.setState({ additionalStepFinish: true })} >
                {i18n.validate}
            </Button>
        )
    }

    render() {
        const {
            stationMaterielAssignments,
            lastSituations,
            materielStates,
            keyMaterielId,
            station,
            contributors,
        } = this.props
        const {
            openPopup,
            step,
            situation,
            shouldReplace,
            newMaterielSituation,
            isEditMode,
        } = this.state
        const materiels = this.getMaterielData()
        const materielsFiltered = materiels.filter(({ id }) => lastSituations.find(({ [keyMaterielId]: idMat, statusCode }) => idMat === id && statusCode === 2))
        const siteName = station.code ? `[${station.code}] ${station.name || ''}` : (station.name || '')
        const datas = orderBy(stationMaterielAssignments, 'id', 'desc').map(sit => {
            const {
                statusCode,
                siteType,
                id,
                materielId,
                comment,
                situationDate,
                situationEndDate,
            } = sit
            const findState = materielStates.find(state => state.code == statusCode) || {}
            const siteTypeFormat = siteType ? getStationTypeNameFromTypeCode(siteType) : ''
            const { label = '' } = materiels.find(({ id: matId }) => matId === materielId) || {}
            const administrator = getLabel(contributors, sit.administratorCode)
            return {
                id,
                siteType: siteTypeFormat ? i18n[siteTypeFormat] : '',
                siteName,
                materiel: label,
                statusCode: createIconMaterialState(findState.label, statusCode),
                comment,
                administrator,
                situationDate: getDate(situationDate),
                situationEndDate: getDate(situationEndDate),
            }
        })
        const materielsOnSite = stationMaterielAssignments.filter(a => !a.situationEndDate).map(a => materiels.find(m => m.id === a[keyMaterielId]))
        const stationEvents = this.props.params.type === 'installation' ? this.props.installationEvents : this.props.stationEvents
        const stationsEvents = stationEvents.filter(({ eventType }) => eventType === EVENT_TYPES_CODES.PREVENTIVE || eventType === EVENT_TYPES_CODES.CURATIVE)
        const isLast = situation.id ? !!stationMaterielAssignments.find(({ id }) => id === situation.id).situationEndDate : false
        return (
            <div className='card-content'>
                <div className='row no-margin'>
                    <div className='col s8 offset-s2'>
                        <SituationStationForm
                            situation={situation}
                            materiels={materielsFiltered}
                            shouldReplace={shouldReplace}
                            materielsOnSite={materielsOnSite}
                            newMaterielSituation={newMaterielSituation}
                            onChangeNewMaterielSituation={obj => this.setState(obj)}
                            changeValue={this.changeValue}
                            isEditMode={isEditMode}
                            idKey={keyMaterielId}
                            isLast={isLast}
                        />
                    </div>
                </div>
                {this.props.getMaterielPanel(isEditMode)}
                <div className='row no-margin'>
                    <div className='col s10 offset-s1'>
                        <Card title={i18n.materielAssignments}>
                            <Table
                                showTitle={false}
                                data={datas}
                                type={{ headers: ['siteType', 'siteName', 'materiel', 'statusCode', 'comment', 'administrator', 'situationDate', 'situationEndDate', 'del'] }}
                                condensed
                                sortable
                                onClick={materielSituation => this.changeStateSituation(materielSituation, stationMaterielAssignments)}
                            />
                        </Card>
                    </div>
                </div>
                {/* <Dialog
                    actions={[(
                        <a className='waves-effect waves-teal btn-flat' onClick={}>
                            {step === 0 ? i18n.close : i18n.return}
                        </a>
                    ), step !== 0 ? this.getValidateButton(step) : ((!this.state.additionalStepFinish && this.props.additionalStep) && this.getFinishAdditionalStepButton())
                    ]}
                > */}
                <Dialog
                    onClose={() => this.setState({ openPopup: false, step: 0, additionalStepFinish: !this.props.additionalStep || this.props.additionalStep === null, event: {} })}
                    fullWidth
                    maxWidth='lg'
                    open={openPopup}
                >
                    <DialogContent>
                        <div className='row col s12' style={{ minHeight: '30%' }}>
                            {
                                (step === 0 && !this.state.additionalStepFinish && this.props.additionalStep) && this.props.additionalStep({ shouldReplace, replaceMat: newMaterielSituation[keyMaterielId] })
                            }
                            {
                                (step === 0 && this.state.additionalStepFinish) && (
                                    <div className='col s12 center'>
                                        <div className='col s12 row'>
                                            <a className={`btn ${!stationsEvents.length ? 'disabled' : ''}`} style={{ width: '250px' }} onClick={() => this.setState({ step: 1 })}>{i18n.assignEvent}</a>
                                        </div>
                                        <div className='col s12 row'>
                                            <a className='btn' style={{ width: '250px' }} onClick={() => this.saveSituation()}>{i18n.doNotLink}</a>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                step === 1 && (
                                    <div className='col s12'>
                                        <div className='scrollable-card' style={{ maxHeight: '70%' }}>
                                            {
                                                orderBy(stationsEvents, 'date', 'desc').map((e, i) => (
                                                    <div className='row col s12 no-margin'>
                                                        <input name='eventGroup' type='radio' id={i} onChange={() => this.setState({
                                                            situation: {
                                                                ...situation,
                                                                eventCode: e.number,
                                                            },
                                                            situationMaterialReplace: {
                                                                ...newMaterielSituation,
                                                                eventCode: e.number,
                                                            },
                                                        })}
                                                        />
                                                        <label htmlFor={i}>{getDate(e.date)} - {e.comment}</label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ openPopup: step !== 0, step: 0, additionalStepFinish: !this.props.additionalStep || this.props.additionalStep === null, event: {} })} variant='outlined' >
                            {step === 0 ? i18n.close : i18n.return}
                        </Button>
                        {step !== 0 ? this.getValidateButton(step) : ((!this.state.additionalStepFinish && this.props.additionalStep) && this.getFinishAdditionalStepButton())}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

SituationDetailPanel.propTypes = {
    params: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    children: PropTypes.element,
    stationMaterielAssignments: PropTypes.arrayOf(PropTypes.object),
    materiels: PropTypes.arrayOf(PropTypes.object),
    lastSituations: PropTypes.arrayOf(PropTypes.object),
    materielTypes: PropTypes.arrayOf(PropTypes.object),
    keyMaterielType: PropTypes.string,
    keyMaterielId: PropTypes.string,
    station: PropTypes.instanceOf(DtoStation),
    getMaterielPanel: PropTypes.func,
    deleteSituation: PropTypes.func,
    addSituation: PropTypes.func,
    saveSituation: PropTypes.func,
    fetchMateriel: PropTypes.func,
    updateMateriel: PropTypes.func,
    resetMateriel: PropTypes.func,
    stationEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    installationEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationEvent)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),

    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    additionalStep: PropTypes.func,
}

const mapStateToProps = store => ({
    materielStates: store.MaterielReducer.materielStates,
    stationEvents: store.EventsReducer.stationEvents,
    installationEvents: store.InstallationReducer.installationEvents,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    addEvent: EventsAction.addEvent,
    addInstallationEvent: InstallationAction.addInstallationEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(SituationDetailPanel)