import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import {
    H_CAMPAIGN_INSTALLATION_DASHBOARD,
    H_CAMPAIGN_INSTALLATION_TRACKING,
    H_CAMPAIGN_INSTALLATION_VALIDATION,
} from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import InstallationAction from '../../actions/InstallationAction'
import InstallationCampaignDashboard from './InstallationCampaignDashboard'
import InstallationCampaignTrackingApp from './InstallationCampaignTrackingApp'
import InstallationCampaignValidationApp from './InstallationCampaignValidationApp'
import InstallationVisitApp from './InstallationVisitApp'
import InstallationVisitOperatorFeedback from './InstallationVisitOperatorFeedback'
import InstallationVisitSummary from './InstallationVisitSummary'

class InstallationCampaignApp extends Component {
    getLinks = () => this.props.match.params.id !== 'new' ? [
        {
            href: `/installation/campaign/${this.props.match.params.id}/dashboard`,
            icons: 'dashboard',
            name: i18n.dashboard,
            habilitation: H_CAMPAIGN_INSTALLATION_DASHBOARD,
        },
        {
            href: `/installation/campaign/${this.props.match.params.id}/tracking`,
            icons: 'insert_drive_file',
            name: i18n.tracking,
            habilitation: H_CAMPAIGN_INSTALLATION_TRACKING,
        },
        {
            href: `/installation/campaign/${this.props.match.params.id}/validation`,
            icons: 'border_color',
            name: i18n.validation,
            habilitation: H_CAMPAIGN_INSTALLATION_VALIDATION,
        },
    ] : [{
        href: `/installation/campaign/${this.props.match.params.id}/dashboard`,
        icons: 'dashboard',
        name: i18n.dashboard,
        habilitation: H_CAMPAIGN_INSTALLATION_DASHBOARD,
    }].filter(l => componentHasHabilitations(l.habilitation))

    componentDidMount = () => {
        const { id } = this.props.match.params
        if (id !== 'new' && id !== 'duplicate') {
            this.props.fetchCampaign('installation', id)
            this.props.fetchCampaignStation('installation', id)
            this.props.fetchCampaignProgress('installation', id)
        }
        this.props.fetchInstallationsLight()
        this.props.fetchCities()
    }

    componentWillUnmount = () => this.props.resetCampaign()

    render = () => (
        <div className='row no-margin'>
            <FixedSlideNav links={this.getLinks()} />
            <main>
                <div className='row no-margin'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact path='/installation/campaign/:id' to='/installation/campaign/:id/dashboard' />
                                <Route path='/installation/campaign/:id/dashboard' render={(props) => <InstallationCampaignDashboard {...props} />} />
                                <Route path='/installation/campaign/:id/tracking' render={(props) => <InstallationCampaignTrackingApp {...props} />} />
                                <Route path='/installation/campaign/:id/validation' render={(props) => <InstallationCampaignValidationApp {...props} />} />
                                <Route path='/installation/campaign/:id/visit/summary/:idInstallation' render={(props) => <InstallationVisitSummary {...props} />} />
                                <Route path='/installation/campaign/:id/visit/:idInstallation/operatorFeedback' render={(props) => <InstallationVisitOperatorFeedback {...props} />} />
                                <Route path='/installation/campaign/:id/visit/:idInstallation/:step' render={(props) => <InstallationVisitApp {...props} />} />
                            </Switch>
                            {/* {this.props.children && React.cloneElement(
                            this.props.children,
                            {
                                id: this.props.match.params.id,
                            })
                        } */}
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

InstallationCampaignApp.propTypes = {
    children: PropTypes.element,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),

    resetCampaign: PropTypes.func,
    fetchCampaign: PropTypes.func,
    fetchCampaignStation: PropTypes.func,
    fetchCampaignProgress: PropTypes.func,
    fetchInstallationsLight: PropTypes.func,
    fetchCities: PropTypes.func,
}

const mapDispatchToProps = {
    resetCampaign: CampaignAction.resetCampaign,
    fetchCampaign: CampaignAction.fetchCampaign,
    fetchCampaignStation: CampaignAction.fetchCampaignStation,
    fetchCampaignProgress: CampaignAction.fetchCampaignProgress,
    fetchInstallationsLight: InstallationAction.fetchInstallationsLight,
    fetchCities: CityAction.fetchCities,
}

export default connect(null, mapDispatchToProps)(InstallationCampaignApp)
