import Row from 'components/react/Row'
import { push } from 'connected-react-router'
import { differenceBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { searchAllCharacters } from 'utils/StringUtil'
import ActionComponent from '../../../../components/ActionComponent'
import Card from '../../../../components/card/Card'
import SelectionTable from '../../../../components/datatable/SelectionTable'
import Table from '../../../../components/datatable/Table'
import Input from '../../../../components/forms/Input'
import SieauAction from '../../../../components/sieau/SieauAction'
import ContactAction from '../actions/ContactAction'
import ContactDto from '../dto/ContactDto'
import ContactGroupDto from '../dto/ContactGroupDto'

const headers = ['name', 'email', 'phoneTel', 'socialReason']

class ContactGroupApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedData: [],
            dataContacts: [],
            group: {},
            isEdit: false,
            sort: { column: 'name', sort: 'asc' },
            tmpFilter: { searchValue: '' },
            filter: { searchValue: '' },
        }
    }

    getActions = () => {
        const { isEdit, selectedData, dataContacts } = this.state
        const { createContactsGroup, setContactsGroup, deleteContactsGroup, match: { params } } = this.props
        if (isEdit) {
            this.setActions({
                save: () => {
                    const contacts = selectedData.map(contact => new ContactDto(contact))
                    const group = {
                        name: this.state.group.name,
                        contacts,
                    }
                    if (params.code === 'new') {
                        createContactsGroup(group)
                            .then(() => {
                                this.setState({
                                    isEdit: false,
                                    group,
                                    dataContacts: selectedData,
                                })
                            })
                    } else {
                        setContactsGroup({ ...group, groupCode: parseInt(params.code) })
                            .then(() => {
                                this.setState({
                                    isEdit: false, group,
                                    dataContacts: selectedData,
                                })
                            })
                    }
                },
                cancel: () => {
                    if (params.code !== 'new') {
                        this.deleteAll()
                        this.setState({
                            isEdit: false,
                        })
                    } else {
                        this.props.push('/referencial/contact')
                    }
                },
            })
        } else {
            this.setActions({
                edit: () => {
                    this.setState({
                        isEdit: true,
                        selectedData: [...dataContacts],
                    })
                },
                delete: () => {
                    deleteContactsGroup(params.code)
                    this.props.push('/referencial/contact')
                },
            })
        }
    }

    setTitle() {
        this.props.forceFetch('title', [
            {
                title: i18n.referencial,
                href: 'referencial',
            },
            {
                title: i18n.contactsGroups,
                href: 'referencial/contact',
            },
            {
                title: this.props.contactsGroup.name,
                href: 'referencial/contactsgroups',
            },
        ])
    }

    componentDidMount() {
        if (this.props.match.params.code === 'new') {
            const group = {
                name: i18n.newGroup,
            }
            this.setState({ group, isEdit: true })
        } else {
            this.props.fetchContactsGroup(this.props.match.params.code)
                .then(group =>this.setState({
                    group,
                    dataContacts: group.contacts,
                    selectedData: group.contacts,
                }))
        }

        if (!this.props.contacts.length && this.state.isEdit) {
            this.props.fetchContacts()
        }
        this.getActions()
        this.setTitle()
    }

    onAdd(contact) {
        if (!this.state.selectedData.includes(contact)) {
            this.setState({ selectedData: [...this.state.selectedData, contact] })
        }
    }
    addAll() {
        const { selectedData } = this.state
        this.setState({ selectedData: uniqBy([...selectedData, ...this.getFilteredData([...this.props.contacts])], 'id') })
    }
    onDelete(contact) {
        if (this.state.selectedData.map(s => s.id).includes(contact.id)) {
            this.setState({ selectedData: this.state.selectedData.filter(e => e.name !== contact.name) })
        }
    }
    deleteAll() {
        const { filter, selectedData } = this.state
        if (filter.searchValue) {
            this.setState({ selectedData: differenceBy(selectedData, this.getFilteredData(selectedData), 'id') })
        } else {
            this.setState({ selectedData: [] })
        }
    }

    getDataSelectionTable() {
        return this.getFilteredData(differenceBy(this.props.contacts, this.state.selectedData, 'id'))
    }

    getFilteredData = (data) => {
        const { filter } = this.state
        const includesValue = searchAllCharacters(filter.searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (contact) => {
        return searchAllCharacters(headers.map(key => contact[key]))
    }

    getSelectionTable() {
        const { tmpFilter, selectedData } = this.state

        return (
            <div className='row'>
                <Card noMargin={false} className='row margin-1 padding-top-1'>
                    <Input
                        col={6}
                        title={ i18n.search }
                        value={ tmpFilter.searchValue }
                        onChange={(v) => this.setState({ tmpFilter: { ...tmpFilter, searchValue: v } })}
                        onEnterKeyPress={() => this.setState({ filter: tmpFilter })}
                    />
                    <Row>
                        <a className='margin-1 waves-effect waves-teal center btn-flat primary-button right' onClick={() => this.setState({ filter: tmpFilter })}>
                            {i18n.search}
                        </a>
                        <a className='margin-1 waves-effect waves-teal center btn-flat secondary-button right' onClick={() => this.setState({ filter: { searchValue: '' }, tmpFilter: { searchValue: '' } })}>
                            {i18n.reinit}
                        </a>
                    </Row>
                </Card>
                <div className='row margin-top-1 margin-bottom-1 referencial-table'>
                    <SelectionTable
                        listTitle={i18n.contacts}
                        listData={this.getDataSelectionTable() }
                        listHeaders={headers}
                        selectedHeaders={headers}
                        selectedData={this.getFilteredData(selectedData)}
                        selectedTitle={i18n.groupMembers}
                        onAdd={(contact) => this.onAdd(contact)}
                        addAll={() => this.addAll()}
                        deleteAll={() => this.deleteAll()}
                        onDelete={contact => this.onDelete(contact)}
                    />
                </div>
            </div>
        )
    }

    getData() {
        return this.state.dataContacts.map((value) => {
            return ({
                id: value.id,
                name: { value: value.name, color: 'white' },
                email: { value: value.email, color: 'white' },
                phoneTel: { value: value.phoneTel, color: 'white' },
                socialReason: { value: value.socialReason, color: 'white' },
                headers,
            })
        })
    }

    getTable() {
        const data = this.getData()
        return (
            <div className='row margin-top-1 margin-left-1 margin-right-1'>
                <Table
                    title={i18n.groupMembers}
                    data={data}
                    exportData={data}
                    exportName={this.state.group.name}
                    type={{ headers }}
                    color
                    padding
                    sortable
                    initialSort={this.state.sort}
                    link='referencial/contact'
                    exportButtonOnHeader
                />
            </div>
        )
    }

    getCard() {
        const { match: { params }, contactsGroup } = this.props
        const { group, isEdit } = this.state
        return (
            <div className='card row margin-right-1 margin-left-1'>
                <Card title={params.code === 'new' ? i18n.newGroup : contactsGroup.name}>
                    <div className='padding-1'>
                        <Input title={i18n.nameGroup} value={group.name} disabled={!isEdit} onChange={value => this.setState({ group: { name: value } })}/>
                    </div>
                </Card>
            </div>
        )
    }

    render() {
        this.getActions()
        return (
            <div className='row no-margin'>
                {this.getCard()}
                {this.state.isEdit ? this.getSelectionTable() : this.getTable()}
            </div>
        )
    }
}

ContactGroupApp.propTypes = {
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contactsGroup: PropTypes.instanceOf(ContactGroupDto),
    fetchContacts: PropTypes.func,
    fetchContactsGroup: PropTypes.func,
    forceFetch: PropTypes.func,
    createContactsGroup: PropTypes.func,
    setContactsGroup: PropTypes.func,
    deleteContactsGroup: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    contactsGroup: store.ContactReducer.contactsGroup,
})

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    fetchContacts: ContactAction.fetchContacts,
    fetchContactsGroup: ContactAction.fetchContactsGroup,
    createContactsGroup: ContactAction.createContactsGroup,
    setContactsGroup: ContactAction.setContactsGroup,
    deleteContactsGroup: ContactAction.deleteContactsGroup,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactGroupApp)