import fetch from 'isomorphic-fetch'
import { RECEIVE_ALL_UNITS, RECEIVE_UNIT, RESET_UNIT } from '../constants/UnitConstants'
import ApplicationConf from 'conf/ApplicationConf'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import AppStore from 'store/AppStore'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import {
    PATH_REFERENCIAL_UNIT_ENTITY,
    PATH_REFERENCIAL_UNIT_ENTITY_LINK,
} from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'

const UnitAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().UnitReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    receiveUnit(unit) {
        return { type: RECEIVE_UNIT, unit }
    },

    receiveUnits(units) {
        return { type: RECEIVE_ALL_UNITS, units }
    },

    fetchSynchroSandreUnit() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateUnit(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(UnitAction.fetchUnits())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.unit + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.unit))
                })
        }
    },

    fetchUnit(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.unit(code), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(UnitAction.receiveUnit(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.unit} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.unit))
                })
        }
    },

    promiseUnits() {
        return fetch(ApplicationConf.referencial.units(), {
            method: 'GET',
            headers: getAuthorization(),

        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    // eslint-disable-next-line consistent-return
    fetchUnits() {
        return (dispatch) => {
            return UnitAction.promiseUnits()
                .then((json = []) => {
                    dispatch(UnitAction.receiveUnits(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.units} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.units))
                })
        }
    },

    createUnit(unit) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.units(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(unit),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(push(PATH_REFERENCIAL_UNIT_ENTITY_LINK + unit.code))
                    dispatch(UnitAction.fetchUnits())
                    dispatch(ToastrAction.success(i18n.successfulCreation))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.units} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.units))
                })
        }
    },

    updateUnit(unit, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveUnit(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(unit),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(UnitAction.fetchUnit(code))
                        dispatch(ToastrAction.success(i18n.unitSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.units} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.units))
                })
        }
    },

    deleteUnit(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteUnit(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(UnitAction.fetchUnits())
                        dispatch(push(PATH_REFERENCIAL_UNIT_ENTITY))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.units} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.units))
                })
        }
    },

    purgeUnit() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeUnit(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(UnitAction.fetchUnits())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.units} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.units))
                })
        }
    },

    replaceUnit(unit, unitReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceUnit(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: unit.toString(),
                    replaceCode: unitReplace.toString(),
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.unitSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.unit} : ${err}`))
                })
        }
    },

    reset() {
        return { type: RESET_UNIT }
    },
}

export default UnitAction
