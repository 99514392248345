export default class DtoAquaBoxParameter {
    constructor(obj = {}) {
        this.directory = obj.directory || ''
        this.filter = obj.filter || ''
        this.stationCode = obj.stationCode || ''
        this.factor = obj.factor !== undefined ? obj.factor : ''
        this.offset = obj.offset !== undefined ? obj.offset : ''
        this.dateColumn = obj.dateColumn !== undefined ? obj.dateColumn : ''
        this.hourColumn = obj.hourColumn !== undefined ? obj.hourColumn : ''
        this.data = obj.data || []
    }
}
