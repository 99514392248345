import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import DtoPiezometerChartOptions from '../../../../station/dto/piezometer/DtoPiezometerChartOptions'
import { arrayOf } from '../../../../utils/StoreUtils'
import NumberField from '../../../../components/forms/NumberField'
import Checkbox from '../../../../components/forms/Checkbox'
import { hasValue, round } from '../../../../utils/NumberUtil'
import Row from '../../../../components/react/Row'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { getMeasureValue, getNGFValue } from '../../../../utils/PiezometryUtils'
import Button from '../../../../components/forms/Button'
import AppStore from '../../../../store/AppStore'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import { WhiteCard } from '../../../../components/styled/Card'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'

class OptionsToolPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validateActive: false,
            initialOptions: props.piezometerChartOptions,
        }
    }

    getPiezoValue = (value) => {
        if (this.props.dataType !== -1) {
            return value
        }
        if (hasValue(value)) {
            return round(getMeasureValue({ NGF: value }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti), 2)
        }
        return null
    }

    getPiezoNGF = (value) => {
        if (this.props.dataType !== -1) {
            return value
        }
        if (hasValue(value)) {
            return round(getNGFValue({ value }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti), 2)
        }
        return null
    }

    onCancel = () => {
        this.props.changeParent({ piezometerChartOptions: this.state.initialOptions })
        this.setState({ validateActive: false })
    }

    onSave = () => {
        AppStore.dispatch(PiezometerStationAction.updatePiezometerChartOptions(this.props.id, this.props.piezometerChartOptions, () => {
            this.setState({ validateActive: false, initialOptions: this.props.piezometerChartOptions })
        }))
    }

    render() {
        const { piezometerChartOptions, dataType, yScale } = this.props
        const validateActive = this.state.validateActive
        const chartOption = piezometerChartOptions.find(d => d.dataType == dataType) || new DtoPiezometerChartOptions({
            dataType: dataType.toString(),
            intervalYear: 5,
            displayXIntervalYear: '1',
            displayYIntervalYear: '1',
            displayData: true,
        })
        const change = (changes) => {
            const newOption = { ...chartOption, ...changes }
            this.props.changeParent({ piezometerChartOptions: [ ...piezometerChartOptions.filter(c => c.dataType != dataType), newOption] })
            this.setState(({ validateActive: true }))
        }
        const scales = [
            { value: 'auto', label: i18n.automatic },
            { value: 'manual', label: i18n.manual },
        ]
        const mode = hasValue(chartOption.maxYear) || hasValue(chartOption.minYear) ? 'manual' : 'auto'
        return (
            <WhiteCard className='margin-top-1' title={i18n.chartOptions} round>
                <div className='row no-margin padding-1'>
                    <StyledFieldSet>
                        <StyledLegend>&nbsp;{ i18n.yScale }&nbsp;</StyledLegend>
                        <Row>
                            <NumberField
                                col={ 12 }
                                value={ chartOption.intervalYear || 5 } min={ 1 }
                                title={ i18n.intervalCount }
                                onChange={v => change({ intervalYear: v })}
                            />
                        </Row>
                        <Row>
                            <Checkbox
                                col={ 12 }
                                checked={ hasValue(chartOption.displayYIntervalYear) ? chartOption.displayYIntervalYear === '1' : true }
                                label={ i18n.Ygrid }
                                onChange={v => change({ displayYIntervalYear: v ? '1' : '0' })}
                            />
                        </Row>
                        <Row className='padding-top-1'>
                            <RadioButtons title={ i18n.chartScale } elements={ scales } col={ 12 }
                                selected={ mode }
                                onChange={ v => {
                                    if (v !== mode) {
                                        if (v === 'manual') {
                                            change({ maxYear: this.getPiezoNGF(yScale.max), minYear: this.getPiezoNGF(yScale.min) })
                                        }
                                        if (v === 'auto') {
                                            change({ maxYear: null, minYear: null })
                                        }
                                    }
                                } }
                            />
                        </Row>
                        <Row>
                            <NumberField
                                col={ 12 } disabled={ mode === 'auto' }
                                value={ this.getPiezoValue(chartOption.maxYear) }
                                title={ i18n.max }
                                onChange={v => change({ maxYear: this.getPiezoNGF(v) })} floatValue
                            />
                        </Row>
                        <Row>
                            <NumberField
                                col={ 12 } disabled={ mode === 'auto' }
                                value={ this.getPiezoValue(chartOption.minYear) }
                                title={ i18n.min }
                                onChange={v => change({ minYear: this.getPiezoNGF(v) })} floatValue
                            />
                        </Row>
                    </StyledFieldSet>
                    <StyledFieldSet>
                        <StyledLegend>&nbsp;{i18n.xScale}&nbsp;</StyledLegend>
                        <Row>
                            <Checkbox
                                col={ 12 }
                                checked={ hasValue(chartOption.displayXIntervalYear) ? chartOption.displayXIntervalYear === '1' : true }
                                label={ i18n.Xgrid }
                                onChange={v => change({ displayXIntervalYear: v ? '1' : '0' })}
                            />
                        </Row>
                    </StyledFieldSet>
                </div>
                <Row className='padding-bottom-1 center-align'>
                    <Button
                        tooltip={ i18n.cancel }
                        onClick={ this.onCancel }
                        icon='cancel'
                        className='red btn-floating btn-large margin-left-2 margin-right-2 z-index-10 '
                        disabled={ !validateActive }
                    />
                    <Button
                        tooltip={ i18n.register }
                        onClick={ this.onSave }
                        icon='save'
                        disabled={ !validateActive }
                        className={ `btn-floating btn-large z-index-10 ${validateActive ? 'pulse' : ''}` }
                    />
                </Row>
            </WhiteCard>
        )
    }
}

OptionsToolPanel.propTypes = {
    id: PropTypes.string,
    piezometerChartOptions: arrayOf(DtoPiezometerChartOptions),
    changeParent: PropTypes.func,
    dataType: PropTypes.number,
    yScale: PropTypes.objectOf(PropTypes.number),
    displayCote: PropTypes.number,
    lastLandmark: PropTypes.number,
    groundRefAlti: PropTypes.number,
}

export default OptionsToolPanel