import { push } from 'connected-react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { H_PIEZO_EXPORT_OVERVIEW } from '../../../account/constants/AccessRulesConstants'
import User from '../../../account/dto/User'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import MessageCard from '../../../components/card/MessageCard'
import Table from '../../../components/datatable/Table'
import Button from '../../../components/forms/Button'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import Row from '../../../components/react/Row'
import SieauAction from '../../../components/sieau/SieauAction'
import JobAction from '../../../import/actions/JobAction'
import QesoutHistoryDto from '../../../import/dto/QesoutHistoryDto'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../store/AppStore'
import { getDate } from '../../../utils/DateUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { arrayOf } from '../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../utils/StringUtil'

class ExporOverviewList extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            startDate: moment().subtract(30, 'days').valueOf(),
            endDate: moment().valueOf(),
            data: [],
            searchValue: '',
            checkGreen: true,
            checkYellow: true,
            checkRed: true,
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_PIEZO_EXPORT_OVERVIEW)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: i18n.exportOverview,
            href: 'piezometry/exportOverview',
        }]))
        this.fetchHistory()
        this.setActions({
            export: () => {
                const data = this.state.data
                if (data.length) {
                    data[0].headers = ['code', 'numNum', 'fileLabel', 'startDate', 'endDate', 'sent', 'integration', 'cancelTime']
                }
                return {
                    data: this.state.data,
                    exportType: 'xlsx',
                    titleFile: i18n.exportOverview,
                }
            },
        })
    }

    getColor = (q) => {
        if (q.cancelDate) {
            return '#EF9A9A'
        }
        if (q.integrationDate) {
            return '#A5D6A7'
        }
        if (q.sendDate) {
            return '#FFF176'
        }
        return 'white'
    }

    filterColor = (q) => {
        if (q.lineColor === '#A5D6A7') {
            return this.state.checkGreen
        }
        if (q.lineColor === '#FFF176') {
            return this.state.checkYellow
        }
        if (q.lineColor === '#EF9A9A') {
            return this.state.checkRed
        }
        return true
    }

    fetchHistory = () => this.props.fetchExportHistory(this.state.startDate, this.state.endDate).then(() => {
        const data = this.props.qesoutHistory.map(q => {
            const obj = {
                code: { value: q.code },
                numNum: { value: q.num },
                startDate: { value: getDate(q.startDate), format: 'dd/MM/yyyy', cellType: 'date' },
                endDate: { value: getDate(q.endDate), format: 'dd/MM/yyyy', cellType: 'date' },
                integration: { value: getDate(q.integrationDate), format: 'dd/MM/yyyy', cellType: 'date' },
                sent: { value: getDate(q.sendDate), format: 'dd/MM/yyyy', cellType: 'date' },
                cancelTime: { value: getDate(q.cancelDate), format: 'dd/MM/yyyy', cellType: 'date' },
                fileLabel: { value: q.fileName },
                lineColor: this.getColor(q),
            }
            return { ...obj, hash: searchAllCharacters([obj.code.value, obj.numNum.value, obj.startDate.value, obj.endDate.value, obj.sent.value, obj.integration.value, obj.cancelTime.value, obj.fileLabel.value].join('   ')) }
        })
        this.setState({
            dataLoaded: true,
            data: data.filter(d => d.hash.includes(searchAllCharacters(this.state.searchValue)) && this.filterColor(d)),
        })
    })

    render() {
        if (!this.state.dataLoaded) {
            return <MessageCard>{ i18n.progressLoading }</MessageCard>
        }

        return (
            <Row className='padding-top-1 padding-right-1'>
                <Card>
                    <Row className='valign-wrapper padding-top-1 padding-right-1'>
                        <Input col={ 2 } title={ i18n.startDate } value={ getDate(this.state.startDate) } onChange={ v => onChangeDate(v, v2 => this.setState({ startDate: v2 }), { max: this.state.endDate }) } />
                        <Input col={ 2 } title={ i18n.endDate } value={ getDate(this.state.endDate) } onChange={ v => onChangeDate(v, v2 => this.setState({ endDate: v2 }), { min: this.state.startDate }) } />
                        <Input col={ 3 } title={ i18n.search } value={ this.state.searchValue } onChange={ v => this.setState({ searchValue: v }) } />
                        <Checkbox col={ 1 } checked={ this.state.checkGreen } label={ <span className={ 'green darken-1 arrests-level-panel green-text' }>__</span> } onChange={ v => this.setState({ checkGreen: v }) } />
                        <Checkbox col={ 1 } checked={ this.state.checkYellow } label={ <span className={ 'yellow darken-1 arrests-level-panel yellow-text' }>__</span> } onChange={ v => this.setState({ checkYellow: v }) } />
                        <Checkbox col={ 1 } checked={ this.state.checkRed } label={ <span className={ 'red darken-1 arrests-level-panel red-text' }>__</span> } onChange={ v => this.setState({ checkRed: v }) } />
                        <Button col={ 2 } title={ i18n.visualize } onClick={ () => this.setState({ dataLoaded: false }, this.fetchHistory) } />
                    </Row>
                </Card>
                <Table
                    id='qesoutExport'
                    title={i18n.exportOverview}
                    data={this.state.data}
                    sortable
                    condensed
                    color
                    type={ { headers: ['code', 'numNum', 'fileLabel', 'startDate', 'endDate', 'sent', 'integration', 'cancelTime'] } }
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                />
            </Row>
        )
    }
}


ExporOverviewList.propTypes = {
    accountUser: PropTypes.instanceOf(User),
    qesoutHistory: arrayOf(QesoutHistoryDto),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    accountUser: store.AccountReducer.accountUser,
    qesoutHistory: store.JobReducer.qesoutHistory,
})

const mapDispatchToProps = {
    fetchExportHistory: JobAction.fetchExportHistory,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExporOverviewList)
