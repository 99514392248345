import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import VariousMaterielAction from '../../../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import VariousMaterielDto from '../../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import { setModal } from '../../../../utils/FormUtils'
import Job from '../../../dto/DtoJob'
import MaterielDto from '../../../dto/MaterielDto'
import ImportFileModal from './ImportFileModal'

class ImportCalypsoPanel extends Component {
    constructor(props) {
        super(props)
        const [param = '{}'] = props.job.parameters.parameters
        this.state = {
            directory: undefined,
            filter: undefined,
            type: undefined,
            rename: false,
            codes: [],
            ...JSON.parse(param),
        }
    }

    componentDidMount() {
        if (!this.props.variousMateriels.length) {
            this.props.fetchVariousMateriels()
        }
    }

    onChangeParameter = obj => {
        const newParameter = {
            ...this.state,
            ...obj,
        }
        this.setState(newParameter)
        this.props.handleChangeParameters([JSON.stringify(newParameter)])
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.state.directory} onChange={name => this.setState({ filter: name }) } />,
        })
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('station'))
            if (integration) {
                return [...data, 'stationIntegration']
            }
            return data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    addCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddCode.value
            const exist = this.state.codes.find(p => p === code)
            const matExist = this.props.variousMateriels.find(mat => mat.reference === code)
            if (!code) {
                this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
            } else if (exist) {
                this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
            } else if (!matExist) {
                this.props.toastrError(i18n.matNotExist)
            } else {
                this.onChangeParameter({ codes: [... this.state.codes, code] })
                this.refs.txtAddCode.value = ''
            }
        }
    }

    onDeleteCode = ({ code }) => {
        this.onChangeParameter({ codes: this.state.codes.filter(p => p !== code) })
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'>
                        <Checkbox
                            checked={this.state.rename}
                            label={i18n.fileRenaming}
                            onChange={v => this.onChangeParameter({ rename: v })}
                            {...disabled}
                        />
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className=' input-field col s3'>
                        <label>{i18n.defineDirectory}</label>
                    </div>
                    <div className='input-field col s9'>
                        <input
                            id='txtDirDef'
                            type='text'
                            value={this.state.directory}
                            onChange={e => this.props.isEditMode && this.onChangeParameter({ directory: e.target.value })}
                            placeholder={i18n.selectDirectory}
                        />
                        <a className='btn btn-small secondary-color-back' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.filter}</label>
                    </div>
                    <div className='input-field col s9'>
                        <input
                            id='txtFilter'
                            type='text'
                            value={this.state.filter}
                            onChange={e => this.props.isEditMode && this.onChangeParameter({ filter: e.target.value })}
                            placeholder={i18n.filterExampleQuesu}
                        />
                    </div>
                </div>

                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'><label>{ i18n.addCredential }</label></div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddCode' type='text' placeholder={ i18n.matExample } ref='txtAddCode'/>
                            <label className='tinyLabel'>{ i18n.matCode }</label>
                        </div>
                        <a className='waves-effect waves-light btn col offset-s1 s3' onClick={ this.addCode }>{ i18n.add }</a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <Table
                            type={ new MaterielDto() }
                            data={ this.state.codes.map(code => new MaterielDto({ code })) }
                            title={ i18n.credentials }
                            nbPerPageLabel={ nbPerPageLabelTiny }
                            onDelete={ this.onDeleteCode }
                            deletable={ this.props.isEditMode }
                            showNbElements
                            paging
                            exportButtonOnHeader
                        />
                    </div>
                </div>

            </div>
        )
    }
}

ImportCalypsoPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeDataTypes: PropTypes.func.isRequired,
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    toastrError: PropTypes.func,
    fetchVariousMateriels: PropTypes.func,
}

const mapStateToProps = store => ({
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
})

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
    fetchVariousMateriels: VariousMaterielAction.fetchVariousMateriels,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportCalypsoPanel)
