import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { v4 as uuidv4 } from 'uuid'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Textarea from '../../../../components/forms/Textarea'
import SieauAction from '../../../../components/sieau/SieauAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_AQUIFER,
    PATH_REFERENCIAL_AQUIFER_LINK,
    PATH_REFERENCIAL_AQUIFER_NEW,
} from '../../../../home/constants/RouteConstants'
import { getUser } from '../../../../utils/SettingUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import DeleteModal from '../../DeleteModal'
import ReplaceModal from '../../ReplaceModal'
import AquiferAction from '../actions/AquiferAction'

const AquiferApp = ({ match: { params: { code } } }) => {
    const {
        aquiferProps,
        aquifers,
    } = useSelector(store => ({
        aquiferProps: store.AquiferReducer.aquifer,
        aquifers: store.AquiferReducer.aquifers,
    }), shallowEqual)

    const [aquifer, setAquifer] = useState({})
    const [isEditMode, setIsEditMode] = useState(code === 'new')

    const dispatch = useDispatch()

    useEffect(() => {
        if (code !== 'new') {
            dispatch(AquiferAction.fetchAquifer(code))
        }
        dispatch(HomeAction.setHelpLink('', ''))
        if (!aquifers.length) {
            dispatch(AquiferAction.fetchAquifers())
        }
    }, [code, dispatch])

    useEffect(() => setAquifer(aquiferProps), [aquiferProps])

    useEffect(() => () => dispatch(AquiferAction.reset()), [])

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        if (code === 'new' && (!aquiferProps || !aquiferProps.code)) {
            return {
                save: () => {
                    const existCode = aquifers.find(f => f.id === aquifer.id)
                    if (existCode) {
                        dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                    } else if (!aquifer.id) {
                        dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                    } else {
                        dispatch(AquiferAction.createAquifer(aquifer))
                        setIsEditMode(false)
                    }
                },
                cancel: () => {
                    dispatch(push(PATH_REFERENCIAL_AQUIFER))
                    setIsEditMode(false)
                },
            }
        }
        if (isEditMode) {
            return {
                save: () => {
                    dispatch(AquiferAction.updateAquifer(aquifer, aquifer.code))
                    setIsEditMode(false)
                },
                cancel: () => {
                    setAquifer(aquiferProps)
                    setIsEditMode(false)
                },
            }
        }
        return {
            edit: () => {
                setIsEditMode(true)
            },
            deleteCheck: () => {
                dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.aquiferCountDelete(code)))
                const id = uuidv4()
                const popup = {
                    id,
                    content: <DeleteModal
                        onDelete={() => dispatch(AquiferAction.deleteAquifer(code))}
                    />,
                    dismissible: false,
                }
                dispatch(SieauAction.setPopup(popup))
            },
            replace: () => {
                dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.aquiferCountDelete(code)))
                const id = uuidv4()
                const popup = {
                    id,
                    content: <ReplaceModal
                        elements={aquifers}
                        title={`${i18n.aquifer} [${code}]`}
                        label={i18n.aquifer}
                        onReplace={(newCode) => dispatch(AquiferAction.replaceAquifer(aquifer.code, newCode.id))}
                    />,
                    dismissible: false,
                }
                dispatch(SieauAction.setPopup(popup))
            },
        }
    }, [isConsultant, code, aquiferProps, aquifer, isEditMode])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.aquifers,
                    href: PATH_REFERENCIAL_AQUIFER,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_AQUIFER_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.aquifers,
                href: PATH_REFERENCIAL_AQUIFER,
            },
            {
                title: code + (aquiferProps.name ? ` - ${aquiferProps.name}` : ''),
                href: PATH_REFERENCIAL_AQUIFER_LINK + code,
            },
        ]
    }, [code, aquiferProps])

    return (
        <div className='row no-margin'>
            <div id='file' className='col s12'>
                <div className='card margin-top-0-75-rem'>
                    <div className='card-content'>
                        <div className='row no-margin'>
                            <div className='col s12 m8 l8 offset-m2 offset-l2'>
                                <div className='row no-margin padding-top-3-px'>
                                    <NumberField
                                        col={3}
                                        title={i18n.code}
                                        value={aquifer.id}
                                        onChange={value => setAquifer({ ...aquifer, id: value, code: value })}
                                        disabled={code !== 'new'}
                                        id='code_aquifers'
                                        obligatory
                                        data-cy='code_aquifer'
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Input
                                        col={8}
                                        title={i18n.name}
                                        value={aquifer.name}
                                        onChange={name => setAquifer({ ...aquifer, name })}
                                        maxLength={255}
                                        active={isEditMode}
                                        disabled={!isEditMode}
                                        data-cy='name_aquifer'
                                    />
                                    <Input
                                        col={4}
                                        title={i18n.margatCode}
                                        value={aquifer.margatCode}
                                        maxLength={255}
                                        onChange={margatCode => setAquifer({ ...aquifer, margatCode })}
                                        active={isEditMode}
                                        disabled={!isEditMode}
                                        data-cy='margatCode_aquifer'
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Textarea
                                        col={12}
                                        title={i18n.comment}
                                        value={aquifer.commentaire}
                                        onChange={commentaire => setAquifer({ ...aquifer, commentaire })}
                                        active={isEditMode}
                                        disabled={!isEditMode}
                                        data-cy='comment_aquifer'
                                    />
                                </div>
                                <div className='row no-margin padding-top-7-px'>
                                    <NumberField
                                        col={8}
                                        floatValue='true'
                                        title={i18n.extensionpartielibre}
                                        value={aquifer.extensionpartielibre}
                                        maxLength={10}
                                        onChange={extensionpartielibre => setAquifer({ ...aquifer, extensionpartielibre })}
                                        active={isEditMode}
                                        disabled={!isEditMode}
                                        data-cy='freePartExtension_aquifer'
                                    />
                                    <NumberField
                                        col={4}
                                        floatValue='true'
                                        title={i18n.precision}
                                        value={aquifer.precisionextlibre}
                                        maxLength={10}
                                        onChange={precisionextlibre => setAquifer({ ...aquifer, precisionextlibre })}
                                        active={isEditMode}
                                        disabled={!isEditMode}
                                        data-cy='freePartPrecision_aquifer'
                                    />
                                    <NumberField
                                        col={8}
                                        floatValue='true'
                                        title={i18n.extensionpartiecaptive}
                                        value={aquifer.extensionpartiecaptive}
                                        maxLength={10}
                                        onChange={extensionpartiecaptive => setAquifer({ ...aquifer, extensionpartiecaptive })}
                                        active={isEditMode}
                                        disabled={!isEditMode}
                                        data-cy='captiveAreaExtension_aquifer'
                                    />
                                    <NumberField
                                        col={4}
                                        floatValue='true'
                                        title={i18n.precision}
                                        value={aquifer.precisionextcaptive}
                                        maxLength={10}
                                        onChange={precisionextcaptive => setAquifer({ ...aquifer, precisionextcaptive })}
                                        active={isEditMode}
                                        disabled={!isEditMode}
                                        data-cy='captiveAreaPrecision_aquifer'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

AquiferApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }),
}


export default AquiferApp
