import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sieauTooltip } from 'utils/FormUtils'

const $ = window.$

class OldTabsSideBar extends Component {
    state = { }

    initialiseComponent = () => {
        const sideComponent = $('.side-component')
        $('.side-tabs > ul').tabs()
        $('.close').click(() => {
            sideComponent.removeClass('open')
        })
        $('.side-tabs .tab > a').off('click')
        $('.side-tabs .tab > a').click(e => {
            this.props.onChangeTab($(e.currentTarget).attr('id'))
            if (sideComponent.hasClass('open') && $(e.currentTarget).hasClass('active')) {
                sideComponent.removeClass('open')
                if ($('.side-component-content')) {
                    $('.side-component-content').removeClass('open')
                }
            } else {
                sideComponent.addClass('open')
                if ($('.side-component-content')) {
                    $('.side-component-content').addClass('open')
                }
            }
            if (this.props.loadOnClick) {
                this.setState({ id: $(e.currentTarget).attr('id') })
            }
        })
        this.setHeight()
    }

    componentDidMount() {
        if (this.props.openByDefault) {
            $('.side-component').addClass('open')
            const tabsHead = this.props.tabs[0]
            if (this.props.loadOnClick) {
                this.setState({ id: tabsHead.id })
            }
        }
        this.initialiseComponent()
    }

    componentDidUpdate() {
        this.initialiseComponent()
    }

    setHeight() {
        const height = $('.side-component').parent().height()
        $('.side-component').css('min-height', `${height}px`)
    }

    render() {
        if (this.props.tabs === []) {
            return null
        }
        const tabs = this.props.tabs.map((t, i) => (
            <li key={i} className='tab' {...sieauTooltip(t.title || '', null, 'left')}>
                <a href={`#sidetab${i}`} id={ t.id || '' }>
                    <i className='material-icons'>{ t.icon }</i>
                </a>
            </li>
        ))
        const tabsContent = this.props.tabs.map((t, i) => (
            <div key={i} id={`sidetab${i}`}>
                { this.props.loadOnClick && t.id !== this.state.id ? null : t.content }
            </div>
        ))
        return (
            <div className='side-component' style={this.props.containerStyle}>
                <div className='side-tabs' onClick={(e) => e.stopPropagation()}>
                    <ul>
                        { tabs }
                    </ul>
                </div>
                <div className='content side-content'>
                    { tabsContent }
                </div>
            </div>
        )
    }
}


OldTabsSideBar.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.element,
    })),
    onChangeTab: PropTypes.func,
    loadOnClick: PropTypes.bool,
    openByDefault: PropTypes.bool,
}

OldTabsSideBar.defaultProps = {
    tabs: [],
    onChangeTab: () => {},
    loadOnClick: false,
    openByDefault: false,
}

export default OldTabsSideBar