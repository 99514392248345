import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import InstallationCounterCriteriaPanel from '../../installationCounter/InstallationCounterCriteriaPanel'
import InstallationCounterResultDetailPanel from '../../installationCounter/InstallationCounterResultDetailPanel'
// import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
// import { H_INSTALLATION_COUNTER } from '../../../../account/constants/AccessRulesConstants'
// import { push } from 'connected-react-router'
import useTitle from 'utils/customHook/useTitle'
import InstallationAction from 'installation/actions/InstallationAction'
import ProgressCard from 'components/card/ProgressCard'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getFullDate } from 'utils/DateUtil'
import { getObjectLabel } from 'utils/StoreUtils'
import { round } from 'lodash'
import useActions from 'utils/customHook/useActions'

const PANEL = {
    CRITERIAS: 0,
    RESULT_DETAILS: 1,
}

const exportHeaders = ['installationId', 'installationName', 'date', 'parameter', 'parameterCode', 'result', 'unit', 'codeUnit', 'comment', 'producer', 'producerCode', 'cityName', 'inseeCode']

const InstallationCounterApp = () => {
    // if (!componentHasHabilitations(H_INSTALLATION_COUNTER)) { // A modifier quand react-router sera à jour
    //     push('/unauthorized')
    //     return
    // }

    const dispatch = useDispatch()
    const [progress, setProgress] = useState()
    const [dataLoaded, setDataloaded] = useState(false)
    const [selectedPanel, setSelectedPanel] = useState(PANEL.CRITERIAS)
    const [searching, setSearching] = useState(false)
    const [filter, setFilter] = useState({})

    useEffect(() => {
        dispatch(InstallationAction.loadInstallationCounters(setProgress)).then(() => setDataloaded(true))
    }, [])

    const searchData = (newFilter, forceLoad = false) => {
        setSearching(true)
        setFilter(newFilter)
        dispatch(InstallationAction.fetchInstallationAnalysisByType({ ...newFilter, forceLoad })).then((json) => {
            setSearching(false)
            if (json?.data?.length) {
                setSelectedPanel(PANEL.RESULT_DETAILS)
            } else {
                dispatch(ToastrAction.info(i18n.noResults))
            }
        })
    }

    const {
        installationsAnalysis,
        installationAnalysisTableCount,
        installationsLight,
        citiesIndex,
        unitsIndex,
        parametersIndex,
    } = useSelector(store => ({
        installationAnalysisTableCount: store.InstallationReducer.installationAnalysisTableCount,
        installationsAnalysis: store.InstallationReducer.installationsAnalysis,
        installationsLight: store.InstallationReducer.installationsLight,
        citiesIndex: store.CityReducer.citiesIndex,
        unitsIndex: store.UnitReducer.unitsIndex,
        parametersIndex: store.ParameterReducer.parametersIndex,
    }), shallowEqual)

    useTitle(() => {
        return [{
            title: i18n.installation,
            href: 'installation',
        }, {
            title: i18n.counters,
            href: 'installation/counter',
        }]
    }, [])

    const setPanel = (panel) => {
        if (panel !== selectedPanel && installationsAnalysis.length) {
            setSelectedPanel(panel)
        }
    }

    const getActive = (panel) => {
        return panel === selectedPanel ? 'active' : ''
    }

    const data = useMemo(() => {
        return installationsAnalysis.map((a, i) => {
            const installation = installationsLight.find(il => il.id === a.code)
            const nameLabel = installation && installation.name ? installation.name : ''
            const installationCity = citiesIndex[installation.townCode]
            const installationUnit = unitsIndex[a.unitCode]
            return ({
                date: { value: getFullDate(a.analysisDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
                comment: { value: a.comment },
                parameter: { value: getObjectLabel(parametersIndex[a.parameter], 'shortLabelWithCode') },
                parameterCode: { value: a.parameterCode },
                producer: { value: a.producer },
                producerCode: { value: a.producerCode },
                reference: { value: a.reference },
                remarkCode: { value: a.remarkCode },
                result: { value: round(a.result, 2), format: '0.00', cellType: 'number' },
                unitSymbolWithCode: { value: getObjectLabel(installationUnit, 'symbolWithCode') },
                city: { value: getObjectLabel(installationCity, 'labelWithCode') },
                code: { value: installation.code },
                installationId: { value: installation.code },
                installationName: { value: nameLabel },
                inseeCode: { value: getObjectLabel(installationCity, 'code') },
                cityName: { value: getObjectLabel(installationCity, 'name') },
                unit: { value: getObjectLabel(installationUnit, 'symbol') },
                codeUnit: { value: getObjectLabel(installationUnit, 'code') },
                headers: i === 0 ? exportHeaders : [],
            })
        })
    }, [installationsAnalysis])

    useActions(() => {
        return {
            export: () => {
                return {
                    data,
                    exportType: 'xlsx',
                    titleFile: `${i18n.research}-${i18n.counter}`,
                }
            },
        }
    }, [data])

    return dataLoaded ? (
        <div className='row no-margin'>
            <div className='col s12 padding-left-1 padding-right-1 padding-top-1'>
                <ul className='tabs tabs-fixed-width no-margin'>
                    <li className='tab'><a className={getActive(PANEL.CRITERIASCRITERIAS)} onClick={() => setPanel(PANEL.CRITERIAS)}>{i18n.criterias}</a></li>
                    <li className={installationAnalysisTableCount.length ? 'tab' : 'tab disabled'}><a className={getActive(PANEL.RESULT_DETAILS)} onClick={() => setPanel(PANEL.RESULT_DETAILS)}>{i18n.resultsDetails}</a></li>
                </ul>
            </div>
            <div className='col s12'>
                {selectedPanel === PANEL.CRITERIAS && (
                    <InstallationCounterCriteriaPanel
                        searchData={searchData}
                        searching={searching}
                        filter={filter}
                    />
                )}
                {selectedPanel === PANEL.RESULT_DETAILS && (
                    <InstallationCounterResultDetailPanel
                        data={data}
                        forceLoad={() => searchData(filter, true)}
                    />
                )}
            </div>
        </div>
    ) : <ProgressCard progress={progress} />
}

InstallationCounterApp.propTypes = {
}

export default InstallationCounterApp
