import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import { isUndefined, orderBy, uniq } from 'lodash'
import ReferencialAppList from '../../../../referencial/components/ReferencialAppList'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getLabel } from '../../../../utils/StoreUtils'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import { createIconMaterialAssignment, createIconMaterialState } from '../../../../utils/MaterielUtils'
import { LIST_PANEL, MAP_PANEL, MAT_HEADERS } from '../../../constants/MaterielConstants'
import EquipmentFilterForm from '../../filterPanel/EquipmentFilterForm'
import MaterielsMapComponent from '../../map/MaterielsMapComponent'
import { PATH_MATERIEL_EQUIPMENT } from '../../../../home/constants/RouteConstants'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import EquipmentDto from '../dto/EquipmentDto'
import EquipmentAction from '../actions/EquipmentAction'
import Checkbox from '../../../../components/forms/Checkbox'
import { getDate } from '../../../../utils/DateUtil'
import DtoEquipmentSituation from '../dto/DtoEquipmentSituation'
import EquipmentTypeDto from '../dto/EquipmentTypeDto'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import DtoInstallation from '../../../../installation/dto/installation/DtoInstallation'
import queryString from 'query-string'
import HomeAction from 'home/actions/HomeAction'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_MAT_EQUIPMENT } from '../../../../account/constants/AccessRulesConstants'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'
import MaterielTypeDto from 'materiel/dto/MaterielTypeDto'
import ContactDto from 'referencial/components/contact/dto/ContactDto'

class EquipmentsApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: LIST_PANEL,
            filter: this.getFilter(),
        }
    }

    getFilter = () => {
        const { globalResearch, location } = this.props
        const matType = queryString.parse(location.search)?.idType
        return {
            matType: isUndefined(matType) ? undefined : parseInt(matType),
            searchValue: globalResearch,
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_MAT_EQUIPMENT)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setHelpLink('materiel', '')
        if (!this.props.equipments.length) {
            this.props.fetchEquipments()
        }
        if (!this.props.equipmentTypes.length) {
            this.props.fetchEquipmentTypes()
        }
        this.props.updateGlobalResearch('')
    }

    getHash = mat => searchAllCharacters(MAT_HEADERS.map(key => mat[key]).join(''))

    getFilterMateriels = materiels => {
        const {
            network,
            state,
            administrator,
            manufacturer,
            matType,
            serialNumber,
            reference,
            searchValue,
            displayOutOfService,
        } = this.state.filter
        const filterAdministrator = administrator ? materiels.filter(({ administratorId }) => administrator === administratorId) : materiels
        const filterManufacturer = manufacturer ? filterAdministrator.filter(({ manufacturerId }) => manufacturer === manufacturerId) : filterAdministrator
        const filterStatusCode = hasValue(state) ? filterManufacturer.filter(({ statusCode }) => statusCode === state) : filterManufacturer
        const filterType = matType ? filterStatusCode.filter(({ materielType }) => materielType === matType) : filterStatusCode
        const filterSerialNumber = serialNumber ? filterType.filter(mat => mat.serialNumber && mat.serialNumber.includes(serialNumber)) : filterType
        const filterReference = reference ? filterSerialNumber.filter(mat => mat.immoNum && mat.immoNum.includes(reference)) : filterSerialNumber
        const filterNetwork = network ? filterReference.filter(({ networkCode }) => network === networkCode) : filterReference
        const filterOutOfService = displayOutOfService ? filterNetwork : filterNetwork.filter(({ statusCode }) => statusCode !== 0 && statusCode !== 5 && statusCode !== 6)
        const searchValueFormated = searchAllCharacters(searchValue)
        return searchValue ? filterOutOfService.filter(mat => this.getHash(mat).includes(searchValueFormated)) : filterOutOfService
    }

    getExportData = (materiels) => {
        if (!materiels.length) {
            return []
        }
        const [head, ...tail] = materiels
        return [{ ...head, headers: [...MAT_HEADERS, 'immoNum', 'serialNumber', ...this.getMaterialHeaders()] }, ...tail]
    }

    getMaterialHeaders = () => {
        const types = this.props.materielTypes.map(({ link }) => link)
        const { equipments } = this.props
        return uniq([
            equipments.some(({ centralIntegrated }) => centralIntegrated) && 'central',
            equipments.some(({ sensorIntegrated }) => sensorIntegrated) && 'sensor',
            equipments.some(({ powerSupplyIntegrated }) => powerSupplyIntegrated) && 'powerSupply',
            equipments.some(({ telecomIntegrated }) => telecomIntegrated) && 'telecom',
            equipments.some(({ variousMaterielIntegrated }) => variousMaterielIntegrated) && 'variousMateriel',
            equipments.some(({ simIntegrated }) => simIntegrated) && 'sim',
            ...types.filter(type => type !== 'equipment' && type !== 'subscription'),
        ].filter(type => !!type))
    }

    getPanel = equipments => {
        if (this.state.view === MAP_PANEL) {
            return (
                <MaterielsMapComponent
                    materiels={equipments}
                    materielLink={PATH_MATERIEL_EQUIPMENT}
                />
            )
        }
        const formattedEquipments = equipments.map(mat => ({
            ...mat,
            state: createIconMaterialState(mat.state, mat.statusCode),
            assignment: mat.statusCode === 1 && createIconMaterialAssignment(mat.assignment, mat.siteType) || '',
            central: mat.central === 'X' && <Checkbox col={12} checked={true} disabled={true} />,
            sensor: mat.sensor === 'X' && <Checkbox col={12} checked={true} disabled={true} />,
            powerSupply: mat.powerSupply === 'X' && <Checkbox col={12} checked={true} disabled={true} />,
            telecom: mat.telecom === 'X' && <Checkbox col={12} checked={true} disabled={true} />,
            variousMateriel: mat.variousMateriel === 'X' && <Checkbox col={12} checked={true} disabled={true} />,
            sim: mat.sim === 'X' && <Checkbox col={12} checked={true} disabled={true} />,
        }))

        return (
            <ReferencialAppList
                title={i18n.equipments}
                data={formattedEquipments}
                exportData={this.getExportData(equipments)}
                type={{ headers: [...MAT_HEADERS, ...this.getMaterialHeaders()] }}
                newAction={() => this.props.push('/materiel/equipment/new')}
                showNewButton={true}
                showPurgeButton={false}
                setTitleAction={SieauAction.forceFetch('title', [{
                    title: i18n.materiel,
                    href: '/materiel',
                }, {
                    title: i18n.equipments,
                    href: '/materiel/equipment',
                }])}
                onClick={({ materielId }) => this.props.push(`/materiel/equipment/${materielId}`)}
                lastUpdate={null}
                searchable={false}
            />
        )
    }

    onChangeView = view => this.setState({ view })

    onValidate = filter => this.setState({ filter })

    getCorrespondingSite = ({ siteType, siteCode }) => {
        switch (siteType) {
            case 1:
                return this.props.piezometers.find(({ id }) => id === siteCode)
            case 2:
                return this.props.pluviometers.find(({ id }) => id === siteCode)
            case 3:
                return this.props.qualitometers.find(({ id }) => id === siteCode)
            case 4:
                return this.props.hydrometricStations.find(({ id }) => id === siteCode)
            case 7:
                return this.props.installations.find(({ id }) => id === siteCode)
            case 9:
                return this.props.contacts.find(({ id }) => id === siteCode)
            default:
                return {}
        }
    }

    getEquipmentType = () => this.props.equipmentTypes.filter(({ id, label }) => !!this.props.equipments.find(({ equipmentType }) => equipmentType === id) && label).map(t => ({
        id: t.id,
        label: t.label,
    })).filter(t => t.label)

    render = () => {
        const { citiesIndex, equipments, equipmentsLastSituations, materielStates, equipmentTypes, contributors } = this.props
        const materiels = equipments.map(mat => {
            const lastSituation = equipmentsLastSituations.find(s => s.idEquipment === mat.id)
            const labelState = lastSituation ? getLabel(materielStates, lastSituation.statusCode) : ''
            const site = lastSituation && lastSituation.statusCode === 1 && lastSituation.siteType && lastSituation.siteCode && this.getCorrespondingSite(lastSituation) || {}
            const labelAssignment = (lastSituation && lastSituation.statusCode === 1 && lastSituation.siteName) || site.code || ''
            const city = site.townCode && citiesIndex[site.townCode] || undefined
            const cityLabel = city ? `${city.name} - [${city.code}]` : (site.townCode || '')
            const departmentLabel = city && city.departmentNumber ? city.departmentNumber : ''
            return {
                materielId: mat.id,
                type: getLabel(equipmentTypes, mat.materielType),
                manufacturer: getLabel(contributors, mat.manufacturerId, 'labelDisplay', 'id'),
                state: labelState,
                statusCode: lastSituation && lastSituation.statusCode,
                stateDate: lastSituation && getDate(lastSituation.situationDate),
                city: cityLabel,
                administratorId: mat.administrator,
                serialNumber: mat.serialNumber,
                immoNum: mat.reference,
                assignment: labelAssignment,
                siteType: lastSituation && lastSituation.siteType,
                departmentShort: departmentLabel,
                purchaseDate: getDate(mat.purchaseDate),
                manufacturerId: mat.manufacturerId,
                networkCode: mat.networkCode,
                materielType: mat.materielType,
                siteCode: lastSituation && lastSituation.siteCode,
                administrator: getLabel(contributors, mat.administrator, 'labelDisplay', 'code'),
                central: mat.centralIntegrated && 'X' || undefined,
                sensor: mat.sensorIntegrated && 'X' || undefined,
                powerSupply: mat.powerSupplyIntegrated && 'X' || undefined,
                telecom: mat.telecomIntegrated && 'X' || undefined,
                variousMateriel: mat.variousMaterielIntegrated && 'X' || undefined,
                sim: mat.simIntegrated && 'X' || undefined,
            }
        })
        const filteredMateriels = this.getFilterMateriels(materiels)
        return (
            <div className='col no-padding s12'>
                <div className='row no-margin'>
                    <div className='col s12'>
                        <div className='row no-margin-bottom'>
                            <EquipmentFilterForm
                                view={this.state.view}
                                filter={this.state.filter}
                                changeView={this.onChangeView}
                                materiels={materiels}
                                typeList={orderBy(this.getEquipmentType(), 'label')}
                                onValidate={this.onValidate}
                            />
                        </div>
                    </div>
                    {this.getPanel(filteredMateriels)}
                </div>
            </div>
        )
    }
}

EquipmentsApp.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.object,
    }),
    allowModifications: PropTypes.bool,
    getLink: PropTypes.func,
    equipments: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentDto)),
    equipmentsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipmentSituation)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    citiesIndex: PropTypes.instanceOf(PropTypes.object),
    globalResearch: PropTypes.string,
    push: PropTypes.func,
    fetchEquipments: PropTypes.func,
    fetchEquipmentTypes: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchContributors: PropTypes.func,
    setHelpLink: PropTypes.func,
    updateGlobalResearch: PropTypes.func,
}

const mapStateToProps = store => ({
    equipments: store.EquipmentReducer.equipments,
    equipmentsLastSituations: store.EquipmentReducer.equipmentsLastSituations,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    qualitometers: store.QualityReducer.qualitometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installations: store.InstallationReducer.installations,
    materielStates: store.MaterielReducer.materielStates,
    citiesIndex: store.CityReducer.citiesIndex,
    contacts: store.ContactReducer.contacts,
    contributors: store.ContributorReducer.contributors,
    materielTypes: store.MaterielReducer.materielTypes,
    globalResearch: store.HomeReducer.globalResearch,
})

const mapDispatchToProps = {
    push,
    fetchEquipments: EquipmentAction.fetchEquipments,
    fetchEquipmentTypes: EquipmentAction.fetchEquipmentTypes,
    setHelpLink: HomeAction.setHelpLink,
    updateGlobalResearch: HomeAction.updateGlobalResearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentsApp)
