import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import QualityGraphicApp from 'quality/components/graphic/QualityGraphicApp'

const ProductionUnitAssociatedGraphic = ({
    id,
}) => {
    const {
        productionUnit,
        associatedSites,
    } = useSelector(store => ({
        productionUnit: store.ProductionUnitReducer.productionUnit,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.productionUnit,
        href: 'productionUnit',
    }, {
        title: getStationTitle(productionUnit),
        href: `station/productionUnit/${id}`,
    }, {
        title: i18n.graphics,
        href: `station/productionUnit/${id}/graphic`,
    }], [productionUnit])

    const defaultStations = useMemo(() => {
        return associatedSites.filter(as => as.stationLinkedType === 3).map(as => as.stationLinkedId)
    }, [associatedSites])

    return (
        <QualityGraphicApp
            defaultStations={defaultStations}
            hideStationsCriteria
        />
    )
}

ProductionUnitAssociatedGraphic.propTypes = {
    id: PropTypes.string,
}

export default ProductionUnitAssociatedGraphic