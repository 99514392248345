import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { compact, orderBy, uniqBy } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import SectionList from 'components/list/section/SectionList'
import DtoMobileNotification from 'administration/components/user/dto/DtoMobileNotification'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import { AGRI, QAPTYS } from 'administration/components/user/constants/HabilitationConstants'
import Row from 'components/react/Row'

const SelectNotifCms = ({
    notifReceivers = [],
    mobileNotifications = [],
    onChangeNotifs = () => { },
    app,
}) => {
    const {
        accountHabilitations,
    } = useSelector(store => ({
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const uniqMobileNotifications = useMemo(() => uniqBy(mobileNotifications, 'login'), [mobileNotifications])

    const getDivWrapper = (func, isSelected, label) => {
        return (
            <div className='valign-wrapper clickable' onClick={ func }>
                <div className='col s1 no-margin'>
                    <i className='material-icons'>{ isSelected ? 'check_box' : 'check_box_outline_blank' }</i>
                </div>
                <div className='col s11 no-margin'>
                    <label style={{ fontSize: '1.3rem' }} className='clickable'>{ label }</label>
                </div>
            </div>
        )
    }
    const getNotifList = (list, notifSelected) => {
        const listNotif = list.map(n => {
            const isSelected = notifSelected.includes(n.login)
            const label = `${n.login}`
            const changesNotif = () => onChangeNotifs({ notifReceivers: isSelected ? notifSelected.filter(login => login !== n.login) : [...notifSelected, n.login] })
            return getDivWrapper(changesNotif, isSelected, label)
        })
        return (
            <Card className='row no-margin'>
                {listNotif.map((l) => <div className='col s6'>{l}</div>)}
            </Card>
        )
    }

    const hasHabilitation = (value) => accountHabilitations.some(hab => hab.habilitation === value)

    return (
        <div className='padding-top-1'>
            {(hasHabilitation(QAPTYS) || hasHabilitation(AGRI)) && (
                <Row>
                    <Select
                        col={4}
                        label={i18n.application}
                        value={app}
                        onChange={(v) => onChangeNotifs({ app: v })}
                        options={compact([
                            hasHabilitation(QAPTYS) ? { value: 'qaptys', label: i18n.qaptys } : null,
                            hasHabilitation(AGRI) ? { value: 'iryqua', label: i18n.iryqua } : null,
                        ])}
                        noNullValue
                        obligatory
                    />
                </Row>
            )}
            <SectionList
                title={ `${i18n.users} (${uniqMobileNotifications.length} ${i18n.elements} - ${notifReceivers.length} ${i18n.selected})` }
                openFirst
                actions={ [
                    { iconName: 'check_box', label: i18n.addAll, onClick: () => onChangeNotifs({ notifReceivers: uniqMobileNotifications.map(c => c.login) }) },
                    { iconName: 'check_box_outline_blank', label: i18n.deleteAll, onClick: () => onChangeNotifs({ notifReceivers: [] }) },
                ] }
            >
                { getNotifList(orderBy(uniqMobileNotifications, 'login'), notifReceivers) }
            </SectionList>
        </div>
    )
}

SelectNotifCms.propTypes = {
    notifReceivers: PropTypes.arrayOf(PropTypes.string),
    onChangeNotifs: PropTypes.func,
    mobileNotifications: PropTypes.arrayOf(PropTypes.instanceOf(DtoMobileNotification)),
    app: PropTypes.string,
}

export default SelectNotifCms