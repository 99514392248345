import { WAIT } from './WaitConstants'

const AppAction = {
    wait(display) {
        return {
            type: WAIT,
            wait: display,
        }
    },
    waitStart() {
        return AppAction.wait(true)
    },
    waitStop() {
        return AppAction.wait(false)
    },
}

export default AppAction
