import React, { useMemo, useState } from 'react'
import { push } from 'connected-react-router'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_AQUIFER,
    PATH_REFERENCIAL_AQUIFER_NEW,
} from '../../../../home/constants/RouteConstants'
import AquiferAction from '../actions/AquiferAction'
import SearchTable from 'referencial/components/SearchTable'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'

const headers = ['id', 'name', 'margatCode', 'code']

const AquifersApp = () => {
    const {
        aquifers,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AquiferAction.fetchAquifers())
        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.aquifers,
        href: PATH_REFERENCIAL_AQUIFER,
    }], [])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_AQUIFER_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.aquifers,
                    },
                },
            },
        }
    }, [exportData])

    const data = useMemo(() => getExportReferencialData(aquifers), [aquifers])

    return (
        <SearchTable
            title={i18n.aquifers}
            sessionKey={REFERENCIAL_TYPE_NAME.aquifer}
            data={data}
            setExportData={setExportData}
            headers={headers}
            onClick={aquifer => dispatch(push(`/referencial/aquifer/${aquifer.id}/dashboard`))}
        />
    )
}

export default AquifersApp
