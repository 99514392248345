import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import DtoSurveyWithStats from '../../dto/DtoSurveyWithStats'
import { getUser } from '../../../utils/SettingUtils'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import Card from 'components/card/Card'
import { Button, Dialog, Grid } from '@mui/material'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import Select from 'components/forms/Select'
import Row from 'components/react/Row'
import DtoRuleScenarios from 'agriAdministration/dto/DtoRuleScenarios'
import Table from 'components/datatable/Table'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { formatMilliers } from 'utils/StringUtil'
import { compact, isNil, orderBy, round, slice, uniq } from 'lodash'
import Checkbox from 'components/forms/Checkbox'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import ToastrAction from 'toastr/actions/ToastrAction'
import ProgressCard from 'components/card/ProgressCard'
import NumberField from 'components/forms/NumberField'
import { OPERATOR_TYPES, PERIODES, SAMPLE_TYPES, UNIT_TYPES, colorLowWater, colorNotLowWater } from 'agriAdministration/constants/AgriConstants'
import EChart from 'components/echart/EChart'
import Axis from 'components/echart/Axis'
import Line from 'components/echart/series/Line'
import { sieauTooltip } from 'utils/FormUtils'

const headersRecap = ['nullValue', 'lowWater', 'notLowWater', 'totalVolume', 'deviationFromAUP']
const headersSkimming = ['nullValue', 'ruleName', 'impactedLowWaterVolume', 'impactedNotLowWaterVolume', 'totalVolume', 'percentOfAUP', 'nullValue2']
const headersArbitration = ['nullValue', 'ruleName', 'impactedLowWaterVolume', 'impactedNotLowWaterVolume', 'volumeExemptFromRule', 'totalVolume', 'percentOfAUP', 'nullValue2']
const headersPointsImpacted = ['nullValue', 'exploitation', 'point', 'skimmedVolumeLowWater', 'skimmedVolumeNotLowWater', 'correctedVolumeLowWater', 'correctedVolumeNotLowWater', 'allocatedVolume']

const RuleDialog = ({
    rule = {},
    onClose = () => {},
    onChange = () => {},
    onValidate = () => {},
    datasRules = [],
    datasDiffRules = [],
}) => {
    const [tmpSearchValue, setTmpSearchValue] = useState()
    const [searchValue, setSearchValue] = useState()

    const dispatch = useDispatch()

    const {
        cultures,
        culturesFamilies,
        usages,
        installationsWithGeo,
        exploitationsExportFullData,
        modesIrrigations,
        PAR,
    } = useSelector(store => ({
        cultures: store.CultureReducer.cultures,
        culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
        usages: store.UsageReducer.usages,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        modesIrrigations: store.AgriReducer.modesIrrigations,
        PAR: store.AgriReducer.PAR,
    }), shallowEqual)

    const { label, description, parameters: { culture, modeIrrigation, sampleType, usage, operator, value, unit, exceptions, periode } } = rule

    const filteredPoints = useMemo(() => {
        const cultureFiltered = culture ? datasRules.filter((i) => i.idCulture === culture) : datasRules
        const usageFiltered = usage ? cultureFiltered.filter((i) => i.idUsage === usage) : cultureFiltered
        const sampleTypeFiltered = sampleType ? usageFiltered.filter((i) => i.typePrel === sampleType) : usageFiltered
        const irrigModeFiltered = modeIrrigation ? sampleTypeFiltered.filter((i) => i.irrigationMode === modeIrrigation) : sampleTypeFiltered

        return orderBy(irrigModeFiltered.map((d) => {
            const exploitation = exploitationsExportFullData.find((e) => e.idExploitation === d.idExploitation)
            const inst = installationsWithGeo.find((i) => i.id === d.idInstallation)

            const instDatasDiff = datasDiffRules.filter((i) => i.idExploitation === d.idExploitation && i.idInstallation === d.idInstallation && i.idRule < 0)
            const skimmedVolumeLowWater = d.volumeAskedLowWater - (instDatasDiff.reduce((acc, i) => acc + i.volumeDiffLowWater, 0))
            const skimmedVolumeNotLowWater = d.volumeAskedNotLowWater - (instDatasDiff.reduce((acc, i) => acc + i.volumeDiffNotlowWater, 0))

            const exempt = (exceptions || []).find((ex) => ex.idExploitation === exploitation.idExploitation && ex.idInstallation === d.idInstallation)

            const [correctedVolumeLowWater, correctedVolumeNotLowWater] = (() => {
                if (exempt) {
                    return [skimmedVolumeLowWater, skimmedVolumeNotLowWater]
                }
                const valueRule = Number((operator === OPERATOR_TYPES.ADD ? '+' : '-') + (value || 0))
                if (unit === UNIT_TYPES.VOLUME) {
                    return [skimmedVolumeLowWater + valueRule, skimmedVolumeNotLowWater + valueRule]
                }
                return [skimmedVolumeLowWater + (skimmedVolumeLowWater * (valueRule / 100)), skimmedVolumeNotLowWater + (skimmedVolumeNotLowWater * (valueRule / 100))]
            })()
            const correctedVolumeLowWaterWithPeriode = periode === PERIODES.NOT_LOW_WATER ? skimmedVolumeLowWater : (correctedVolumeLowWater > 0 ? round(correctedVolumeLowWater) : 0)
            const correctedVolumeNotLowWaterWithPeriode = periode === PERIODES.LOW_WATER ? skimmedVolumeNotLowWater : (correctedVolumeNotLowWater > 0 ? round(correctedVolumeNotLowWater) : 0)

            return {
                isSelected: !exempt,
                idExploitation: exploitation.idExploitation,
                idInstallation: d.idInstallation,
                nullValue: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Checkbox
                            col
                            checked={!exempt}
                            onChange={() => onChange({
                                ...rule,
                                parameters: {
                                    ...rule.parameters,
                                    exceptions: compact([
                                        ...(exceptions || []).filter((ex) => !(ex.idExploitation === exploitation.idExploitation && ex.idInstallation === d.idInstallation)),
                                        exempt ? null : { idExploitation: exploitation.idExploitation, idInstallation: d.idInstallation },
                                    ]),
                                },
                            })}
                        />
                    </div>
                ),
                exploitation: `[${exploitation.codification}] ${exploitation.name}`,
                point: inst.code,
                skimmedVolumeLowWater: formatMilliers(skimmedVolumeLowWater > 0 ? round(skimmedVolumeLowWater) : 0),
                skimmedVolumeNotLowWater: formatMilliers(skimmedVolumeNotLowWater > 0 ? round(skimmedVolumeNotLowWater) : 0),
                correctedVolumeLowWater: formatMilliers(round(correctedVolumeLowWaterWithPeriode)),
                correctedVolumeNotLowWater: formatMilliers(round(correctedVolumeNotLowWaterWithPeriode)),
                allocatedVolume: formatMilliers(round(correctedVolumeLowWaterWithPeriode + correctedVolumeNotLowWaterWithPeriode)),
            }
        }).filter((e) => searchValue ? (`${e.exploitation} ${e.name} ${e.point}`).includes(searchValue) : true), 'exploitation')
    }, [datasRules, datasDiffRules, exploitationsExportFullData, installationsWithGeo, rule, sampleType, exceptions, searchValue, onChange, operator, value, unit, culture, usage, modeIrrigation, periode])

    const onValidateCheck = () => {
        if (label && operator && value && unit && periode) {
            onValidate()
            onClose()
        } else {
            dispatch(ToastrAction.error(i18n.pleaseCompleteAllRequiredField))
        }
    }

    const culturesFormatted = useMemo(() => {
        return cultures.map((c) => ({
            ...c,
            cultureWithFamily: `[${(culturesFamilies.find((cF) => cF.id === c.idFamille))?.name}] ${c.name}`,
        }))
    }, [cultures, culturesFamilies])

    const isAllSelected = filteredPoints.every(p => p.isSelected)

    const onSelectAll = () => {
        onChange({
            ...rule,
            parameters: {
                ...rule.parameters,
                exceptions: isAllSelected ? uniq([
                    ...(exceptions || []),
                    ...filteredPoints.map((p) => ({ idExploitation: p.idExploitation, idInstallation: p.idInstallation })),
                ]) : [],
            },
        })
    }

    return (
        <Dialog
            fullWidth
            open
            maxWidth='lg'
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.ruleManagement}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
            </DialogTitleMUI>
            <DialogContentMUI className='padding-top-1'>
                <Card title={i18n.ruleSettings} round>
                    <Grid container className='padding-1' spacing={1}>
                        <Grid item xs={2}>
                            <Input
                                col={12}
                                title={i18n.name}
                                value={label}
                                onChange={v => onChange({ ...rule, label: v })}
                                obligatory
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                col={12}
                                label={i18n.culture}
                                options={culturesFormatted}
                                value={culture}
                                onChange={v => onChange({ ...rule, parameters: { ...rule.parameters, culture: v } })}
                                integerValue
                                keyValue='id'
                                keyLabel='cultureWithFamily'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                col={12}
                                label={i18n.usage}
                                options={usages}
                                value={usage}
                                onChange={v => onChange({ ...rule, parameters: { ...rule.parameters, usage: v } })}
                                integerValue
                                keyValue='idUsage'
                                keyLabel='description'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                col={12}
                                label={i18n.sampleType}
                                options={[{
                                    value: SAMPLE_TYPES.UNDERGROUND,
                                    label: i18n.underground,
                                }, {
                                    value: SAMPLE_TYPES.SUPERFICIAL,
                                    label: i18n.superficial,
                                }]}
                                value={sampleType}
                                onChange={v => onChange({ ...rule, parameters: { ...rule.parameters, sampleType: v } })}
                                integerValue
                                keyValue='code'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                col={12}
                                label={i18n.modeIrrigation}
                                options={modesIrrigations}
                                value={modeIrrigation}
                                onChange={v => onChange({ ...rule, parameters: { ...rule.parameters, modeIrrigation: v } })}
                                integerValue
                                keyValue='id'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                col={12}
                                label={i18n.periode}
                                options={[{
                                    value: PERIODES.ALL,
                                    label: i18n.all,
                                }, {
                                    value: PERIODES.LOW_WATER,
                                    label: i18n.lowWater,
                                }, {
                                    value: PERIODES.NOT_LOW_WATER,
                                    label: i18n.notLowWater,
                                }]}
                                value={periode}
                                onChange={v => onChange({ ...rule, parameters: { ...rule.parameters, periode: v } })}
                                disabled={[2, 3].includes(PAR.periode)}
                                integerValue
                                noNullValue
                                obligatory
                                noSort
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                col={12}
                                label={i18n.calcOperator}
                                options={[{
                                    value: OPERATOR_TYPES.SUBTRACT,
                                    label: '-',
                                }, {
                                    value: OPERATOR_TYPES.ADD,
                                    label: '+',
                                }]}
                                value={operator}
                                onChange={v => onChange({ ...rule, parameters: { ...rule.parameters, operator: v } })}
                                integerValue
                                nullLabel='&nbsp;'
                                noNullValue
                                obligatory
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <NumberField
                                col={12}
                                title={i18n.value}
                                value={value}
                                onChange={v => onChange({ ...rule, parameters: { ...rule.parameters, value: v } })}
                                obligatory
                                floatValue
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Select
                                col={12}
                                label={i18n.unit}
                                options={[{
                                    value: UNIT_TYPES.PERCENT,
                                    label: '%',
                                }, {
                                    value: UNIT_TYPES.VOLUME,
                                    label: 'm3',
                                }]}
                                value={unit}
                                onChange={v => onChange({ ...rule, parameters: { ...rule.parameters, unit: v } })}
                                integerValue
                                nullLabel='&nbsp;'
                                noNullValue
                                obligatory
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Textarea
                                col={12}
                                title={i18n.description}
                                value={description}
                                onChange={v => onChange({ ...rule, description: v })}
                            />
                        </Grid>
                    </Grid>
                </Card>
                <Card title={i18n.filters} round className='margin-top-1' noMargin={false}>
                    <Grid className='padding-1' container justifyContent='space-between'>
                        <Grid item xs={4}>
                            <Input
                                col={12}
                                containerClassName='no-margin'
                                title={ i18n.search }
                                value={ tmpSearchValue }
                                onChange={(v) => setTmpSearchValue(v)}
                                onEnterKeyPress={(v) => {
                                    setTmpSearchValue(v)
                                    setSearchValue(v)
                                }}
                            />
                        </Grid>
                        <Button variant='contained' onClick={() => setSearchValue(tmpSearchValue)}>
                            {i18n.search}
                        </Button>
                    </Grid>
                </Card>
                <div className='padding-top-1'>
                    <Table
                        title={i18n.impactedSamplingPoints}
                        data={filteredPoints}
                        type={{ headers: headersPointsImpacted }}
                        customHeaders={{
                            nullValue: (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Checkbox
                                        checked={isAllSelected}
                                        onChange={onSelectAll}
                                    />
                                </div>
                            ),
                            skimmedVolumeLowWater: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.skimmedVolumeLowWaterWrap}</span>,
                            skimmedVolumeNotLowWater: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.skimmedVolumeNotLowWaterWrap}</span>,
                            correctedVolumeLowWater: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.correctedVolumeLowWaterWrap}</span>,
                            correctedVolumeNotLowWater: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.correctedVolumeNotLowWaterWrap}</span>,
                            allocatedVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.attributedVolumeWrap}</span>,
                        }}
                        nbPerPageLabel={nbPerPageLabelShort}
                        paging
                        sortable
                        round
                    />
                </div>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={onValidateCheck} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

RuleDialog.propTypes = {
    rule: PropTypes.instanceOf(DtoRuleScenarios).isRequired,
    datasRules: PropTypes.instanceOf([]).isRequired,
    datasDiffRules: PropTypes.instanceOf([]).isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
}


const ScenarioDashboardApp = ({ match: { params: { id } } }) => {
    const {
        sandreCodes,
        scenario,
        rulesScenarios,
        PAR,
        surveysWithStats,
        managementUnits,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        scenario: store.AgriReducer.scenario,
        rulesScenarios: store.AgriReducer.rulesScenarios,
        PAR: store.AgriReducer.PAR,
        surveysWithStats: store.AgriReducer.surveysWithStats,
        managementUnits: store.ManagementUnitReducer.managementUnits,
    }), shallowEqual)

    const [localScenario, setLocalScenario] = useState({})
    const [localDatasRules, setLocalDatasRules] = useState([])
    const [localDatasDiffRules, setLocalDatasDiffRules] = useState([])
    const [duplicateScenario, setDuplicateScenario] = useState({})
    const [editMode, setEditMode] = useState(false)
    const [openDuplicate, setOpenDuplicate] = useState(false)
    const [rule, setRule] = useState()
    const [openRule, setOpenRule] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(true)

    const dispatch = useDispatch()

    const idScenario = useMemo(() => parseInt(id), [id])

    useEffect(() => {
        dispatch(AgriAction.fetchScenario(idScenario)).then((res) => {
            setLocalScenario(scenario)
            setLocalDatasRules(res?.datasScenario || [])
            setLocalDatasDiffRules(res?.datasDiffScenario || [])
        })
        if (scenario.idPAR && (scenario.idPAR !== PAR.id)) {
            dispatch(AgriAction.fetchPAR(scenario.idPAR))
        }
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!surveysWithStats.length) {
            dispatch(AgriAction.fetchSurveysWithStats())
        }
    }, [scenario.id, idScenario, scenario.idPAR, PAR.id])

    const uge = useMemo(() => {
        return managementUnits.find((u) => u.managementCode === scenario.managementCode) || {}
    }, [managementUnits, scenario.managementCode])

    useTitle(() => [{
        title: i18n.planning,
        href: 'planning/dashboard',
    }, {
        title: 'PARs',
        href: 'planning/par',
    }, {
        title: PAR.name,
        href: `par/${PAR.id}`,
    }, {
        title: `[${uge.id || ''}] ${uge.name || ''} - ${scenario.name || ''}`,
        href: `scenario/${scenario.id}`,
    }], [PAR.id, scenario.id, scenario.name, uge])

    const duplicateAction = (sc) => {
        setOpenDuplicate(true)
        setDuplicateScenario({
            ...sc,
            name: `${sc?.name || ''} (${i18n.copy})`,
            id: undefined,
        })
    }

    useActions(() => {
        if (getUser().isAdmin === '1' || getUser().metadata === '1') {
            return editMode ? {
                cancel: () => {
                    setLocalScenario(scenario)
                    setEditMode(false)
                },
                save: () => {
                    dispatch(AgriAction.updateScenario({ ...localScenario, linkRules: [...localScenario.linkRules.map((lR) => ({ ...lR, parameters: JSON.stringify(lR.parameters) }))] }))
                    setEditMode(false)
                },
            } : {
                delete: () => dispatch(AgriAction.deleteScenario(idScenario)).then(() => dispatch(push(`/par/${localScenario.idPAR}`))),
                edit: () => setEditMode(true),
                duplicate: () => duplicateAction(localScenario),
            }
        }
        return {}
    }, [editMode, scenario, localScenario])

    const onCheckRule = (active, idRule) => {
        setLocalScenario({
            ...localScenario,
            linkRules: [
                ...(localScenario.linkRules || []).filter((lr) => lr.idRule !== idRule),
                {
                    idScenario: scenario.id,
                    idRule,
                    active,
                    parameters: (localScenario.linkRules || []).find((lr) => lr.idRule === idRule)?.parameters || {},
                },
            ],
        })
    }

    const [ugeVolumes, aupVolumes] = useMemo(() => {
        const aupFound = orderBy((uge.link_aup || []).filter((aup) => aup.year <= PAR.year), 'year', 'desc')[0] || {}
        const volumesFound = PAR.linkVolumesUGE.find((u) => u.subManagementCode === scenario.managementCode) || PAR.linkVolumesUGE.find((u) => u.managementCode === scenario.managementCode) || {}
        return [volumesFound, aupFound]
    }, [PAR, scenario, uge])

    const [volumeAskedESU, volumeAskedLowWaterESU, volumeAskedNotLowWaterESU, totalAllocatedESU, lowWaterAllocatedESU, notLowWaterAllocatedESU] = useMemo(() => {
        const datasESU = localDatasRules.filter((d) => d.typePrel === 2)
        return [
            round(datasESU.reduce((acc, d) => acc + d.volumeAsked, 0)),
            round(datasESU.reduce((acc, d) => acc + d.volumeAskedLowWater, 0)),
            round(datasESU.reduce((acc, d) => acc + d.volumeAskedNotLowWater, 0)),
            round(datasESU.reduce((acc, d) => acc + d.volumeImpacted, 0)),
            round(datasESU.reduce((acc, d) => acc + d.volumeImpactedLowWater, 0)),
            round(datasESU.reduce((acc, d) => acc + d.volumeImpactedNotlowWater, 0)),
        ]
    }, [localDatasRules])

    const [rulesSkimming, totalSkimming, lowWaterSkimming, notLowWaterSkimming] = useMemo(() => {
        const rules = rulesScenarios.filter((r) => r.ruleType === 'ecremage')
        let total = 0
        let lowWater = 0
        let notLowWater = 0
        const datas = rules.map((r) => {
            const isActive = localScenario?.linkRules?.some((lR) => lR.idRule === r.id && lR.active)
            const diffs = localDatasDiffRules.filter((d) => d.idRule === r.id)
            const impactedVolume = round(diffs.reduce((acc, d) => acc + d.volumeDiff, 0))
            const impactedLowWaterVolume = round(diffs.reduce((acc, d) => acc + d.volumeDiffLowWater, 0))
            const impactedNotLowWaterVolume = round(diffs.reduce((acc, d) => acc + d.volumeDiffNotlowWater, 0))
            total = total + (isActive ? impactedVolume : 0)
            lowWater = lowWater + (isActive ? impactedLowWaterVolume : 0)
            notLowWater = notLowWater + (isActive ? impactedNotLowWaterVolume : 0)
            return {
                ...r,
                ruleName: r.label,
                nullValue: <Checkbox
                    col
                    checked={localScenario?.linkRules?.some((lr) => lr.idRule === r.id && lr.active)}
                    onChange={v => onCheckRule(v, r.id)}
                    disabled={!editMode}
                />,
                totalVolume: impactedVolume === 0 ? 0 : `-${formatMilliers(impactedVolume)}`,
                impactedLowWaterVolume: impactedLowWaterVolume === 0 ? 0 : `-${formatMilliers(impactedLowWaterVolume)}`,
                impactedNotLowWaterVolume: impactedNotLowWaterVolume === 0 ? 0 : `-${formatMilliers(impactedNotLowWaterVolume)}`,
                percentOfAUP: `${formatMilliers(round((impactedVolume * 100) / aupVolumes.aup, 0))}%`,
                nullValue2: !r.description ? '' : <i className='material-icons' {...sieauTooltip(r.description, null, 'left')}>info</i>,
            }
        })
        return [datas, total, lowWater, notLowWater]
    }, [rulesScenarios, editMode, localScenario.linkRules, localDatasDiffRules, aupVolumes.aup])

    const [rulesArbitration, totalArbitration, lowWaterArbitration, notLowWaterArbitration, totalExceptions, lowWaterExceptions, notLowWaterExceptions] = useMemo(() => {
        const rules = rulesScenarios.filter((r) => r.ruleType === 'arbitrage')
        let total = 0
        let lowWater = 0
        let notLowWater = 0
        let totalExceptionsTmp = 0
        let lowWaterExceptionsTmp = 0
        let notLowWaterExceptionsTmp = 0
        const datas = orderBy(rules.map((r) => {
            const linkRule = localScenario?.linkRules?.find((lR) => lR.idRule === r.id) || {}
            const { active, parameters } = linkRule
            const diffs = localDatasDiffRules.filter((d) => d.idRule === r.id)
            const impactedVolume = round(diffs.reduce((acc, d) => acc + d.volumeDiff, 0))
            const impactedLowWaterVolume = round(diffs.reduce((acc, d) => acc + d.volumeDiffLowWater, 0))
            const impactedNotLowWaterVolume = round(diffs.reduce((acc, d) => acc + d.volumeDiffNotlowWater, 0))
            total = total + (active ? impactedVolume : 0)
            lowWater = lowWater + (active ? impactedLowWaterVolume : 0)
            notLowWater = notLowWater + (active ? impactedNotLowWaterVolume : 0)
            const diffsExceptions = localDatasDiffRules.filter((d) => d.idRule === r.id && (d.exempt || parameters?.exceptions?.some((e) => e.idExploitation === d.idExploitation && e.idInstallation === d.idInstallation)))
            const exceptionsVolume = round(diffsExceptions.reduce((acc, d) => acc + Math.abs(d.volumeDiff), 0))
            const exceptionsLowWaterVolume = round(diffsExceptions.reduce((acc, d) => acc + Math.abs(d.volumeDiffLowWater), 0))
            const exceptionsNotLowWaterVolume = round(diffsExceptions.reduce((acc, d) => acc + Math.abs(d.volumeDiffNotlowWater), 0))
            totalExceptionsTmp = totalExceptionsTmp + (active ? exceptionsVolume : 0)
            lowWaterExceptionsTmp = lowWaterExceptionsTmp + (active ? exceptionsLowWaterVolume : 0)
            notLowWaterExceptionsTmp = notLowWaterExceptionsTmp + (active ? exceptionsNotLowWaterVolume : 0)
            return {
                ...r,
                displayLoadingRow: !dataLoaded && rule.id === r.id,
                ruleName: r.label,
                nullValue: <Checkbox
                    col
                    checked={localScenario?.linkRules?.some((lr) => lr.idRule === r.id && lr.active)}
                    onChange={v => onCheckRule(v, r.id)}
                    disabled={!editMode}
                />,
                totalVolume: formatMilliers(impactedVolume),
                impactedLowWaterVolume: formatMilliers(impactedLowWaterVolume),
                impactedNotLowWaterVolume: formatMilliers(impactedNotLowWaterVolume),
                volumeExemptFromRule: formatMilliers(exceptionsVolume),
                percentOfAUP: `${formatMilliers(round((Math.abs(impactedVolume) * 100) / aupVolumes.aup, 0))}%`,
                nullValue2: !r.description ? '' : <i className='material-icons' {...sieauTooltip(r.description, null, 'left')}>info</i>,
            }
        }), 'id')
        return [datas, total, lowWater, notLowWater, totalExceptionsTmp, lowWaterExceptionsTmp, notLowWaterExceptionsTmp]
    }, [rulesScenarios, editMode, localScenario.linkRules, localDatasDiffRules, aupVolumes.aup])

    const onCreateScenario = () => {
        if (duplicateScenario.name) {
            dispatch(AgriAction.createMultipleScenarios([{
                ...duplicateScenario,
                idPAR: scenario.idPAR,
                linkRules: [...localScenario.linkRules.map((lR) => ({ ...lR, parameters: JSON.stringify(lR.parameters) }))],
            }])).then((ids) => {
                dispatch(AgriAction.fetchPAR(scenario.idPAR))
                dispatch(push(`/scenario/${ids[0]}`))
            })
        } else {
            dispatch(ToastrAction.error(i18n.pleaseCompleteAllRequiredField))
        }
    }

    const onSaveRule = () => {
        const { exceptions } = rule.parameters
        setDataLoaded(false)
        if (rule.id) {
            dispatch(AgriAction.updateRuleScenarios({ ...rule, nullValue: undefined, nullValue2: undefined, parameters: JSON.stringify({ ...rule.parameters, exceptions: undefined }) })).then(() => {
                dispatch(AgriAction.fetchRulesScenarios())
                dispatch(AgriAction.fetchScenario(idScenario)).then((res) => {
                    setLocalScenario({
                        ...res.scenario,
                        linkRules: [
                            ...(res.scenario.linkRules || []).filter((lr) => lr.idRule !== rule.id),
                            {
                                idScenario: scenario.id,
                                idRule: rule.id,
                                active: (res.scenario.linkRules || []).find((lr) => lr.idRule === rule.id)?.active,
                                parameters: { exceptions },
                            },
                        ],
                    })
                    setDataLoaded(true)
                    setLocalDatasRules(res?.datasScenario || [])
                    setLocalDatasDiffRules(res?.datasDiffScenario || [])
                })
            })
        } else {
            dispatch(AgriAction.createRuleScenarios({ ...rule, nullValue: undefined, nullValue2: undefined, parameters: JSON.stringify({ ...rule.parameters, exceptions: undefined }) })).then((idRule) => {
                dispatch(AgriAction.fetchRulesScenarios())
                dispatch(AgriAction.fetchScenario(idScenario)).then((res) => {
                    setLocalScenario({
                        ...res.scenario,
                        linkRules: [
                            ...res.scenario.linkRules,
                            {
                                idScenario: scenario.id,
                                idRule,
                                active: true,
                                parameters: { exceptions },
                            },
                        ],
                    })
                    setDataLoaded(true)
                    setLocalDatasRules(res?.datasScenario || [])
                    setLocalDatasDiffRules(res?.datasDiffScenario || [])
                })
            })
        }
    }

    const getDialogScenario = () => (
        <Dialog
            fullWidth
            open={openDuplicate}
            maxWidth='md'
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.createNewScenario}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpenDuplicate(false)} />
            </DialogTitleMUI>
            <DialogContentMUI className='padding-top-1'>
                <Row>
                    <Input
                        col={6}
                        title={i18n.name}
                        value={duplicateScenario.name}
                        onChange={value => setDuplicateScenario({ ...duplicateScenario, name: value })}
                        obligatory
                    />
                    <Textarea
                        col={6}
                        title={i18n.description}
                        value={duplicateScenario.description}
                        onChange={value => setDuplicateScenario({ ...duplicateScenario, description: value })}
                    />
                </Row>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={onCreateScenario} variant='contained' color='primary'>
                    {i18n.create}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )

    const [totalAllocated, lowWaterAllocated, notLowWaterAllocated] = useMemo(() => {
        return [
            (ugeVolumes.volume - totalSkimming + totalArbitration + totalExceptions),
            (ugeVolumes.volumeAskedLowWater - lowWaterSkimming + lowWaterArbitration + lowWaterExceptions),
            (ugeVolumes.volumeAskedNotLowWater - notLowWaterSkimming + notLowWaterArbitration + notLowWaterExceptions),
        ]
    }, [lowWaterArbitration, lowWaterSkimming, notLowWaterArbitration, notLowWaterSkimming, totalArbitration, totalSkimming, ugeVolumes,
        totalExceptions, lowWaterExceptions, notLowWaterExceptions])

    const optionsChart = useMemo(() => ({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: (params) => {
                return params[0]?.name + slice(params, params.length === 4 ? 2 : 1).map((p) => `<br/>${p.seriesName} : ${formatMilliers(p.value)} m3`).reduce((acc, p) => acc + p, '')
            },
        },
        legend: {
            data: [ i18n.lowWater, i18n.notLowWater ],
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '10%',
            containLabel: true,
        },
        xAxis: [Axis({
            type: 'category',
            splitLine: { show: false },
            data: [i18n.asked, i18n.skimmed, i18n.arbitrated, i18n.exception, i18n.allocated, i18n.reserve, i18n.AUP],
        })],
        yAxis: [Axis({ type: 'value' })],
        series: [
            Line({
                name: i18n.lowWater,
                type: 'bar',
                stack: 'total',
                silent: true,
                itemStyle: { color: colorLowWater },
                data: [
                    0,
                    { value: ugeVolumes.volumeAskedLowWater - (lowWaterSkimming), itemStyle: { color: 'transparent' } },
                    { value: ugeVolumes.volumeAskedLowWater - (lowWaterSkimming) + lowWaterArbitration, itemStyle: { color: 'transparent' } },
                    { value: ugeVolumes.volumeAskedLowWater - (lowWaterSkimming) + lowWaterArbitration, itemStyle: { color: 'transparent' } },
                    0,
                    { value: lowWaterAllocated, itemStyle: { color: 'transparent' } },
                    0,
                ],
            }),
            Line({
                name: i18n.notLowWater,
                type: 'bar',
                stack: 'total',
                silent: true,
                itemStyle: { color: colorNotLowWater },
                data: [
                    0,
                    { value: ugeVolumes.volumeAskedNotLowWater - (notLowWaterSkimming), itemStyle: { color: 'transparent' } },
                    { value: ugeVolumes.volumeAskedNotLowWater - (notLowWaterSkimming) + notLowWaterArbitration, itemStyle: { color: 'transparent' } },
                    { value: ugeVolumes.volumeAskedNotLowWater - (notLowWaterSkimming) + notLowWaterArbitration, itemStyle: { color: 'transparent' } },
                    0,
                    { value: notLowWaterAllocated, itemStyle: { color: 'transparent' } },
                    0,
                ],
            }),
            Line({
                name: i18n.lowWater,
                type: 'bar',
                stack: 'total',
                itemStyle: { color: colorLowWater },
                data: [
                    ugeVolumes.volumeAskedLowWater,
                    lowWaterSkimming,
                    Math.abs(lowWaterArbitration),
                    lowWaterExceptions,
                    lowWaterAllocated,
                    aupVolumes.aupLowWater - lowWaterAllocated,
                    aupVolumes.aupLowWater,
                ],
            }),
            Line({
                markLine: {
                    symbol: 'none',
                    label: { show: false },
                    data: [
                        { yAxis: ugeVolumes?.volume ?? 0 },
                        { yAxis: ugeVolumes?.volume - totalSkimming ?? 0 },
                        { yAxis: ugeVolumes?.volume - totalSkimming - Math.abs(totalArbitration) ?? 0 },
                        { yAxis: totalAllocated ?? 0 },
                        { yAxis: aupVolumes?.aup ?? 0 },
                    ],
                },
            }),
            Line({
                name: i18n.notLowWater,
                type: 'bar',
                stack: 'total',
                itemStyle: { color: colorNotLowWater },
                data: [
                    ugeVolumes.volumeAskedNotLowWater,
                    notLowWaterSkimming,
                    Math.abs(notLowWaterArbitration),
                    notLowWaterExceptions,
                    notLowWaterAllocated,
                    aupVolumes.aupExceptLowWater - notLowWaterAllocated,
                    aupVolumes.aupExceptLowWater,
                ],
            }),
        ],
    }), [aupVolumes.aupExceptLowWater, aupVolumes.aupLowWater, lowWaterAllocated, lowWaterArbitration, lowWaterExceptions, lowWaterSkimming,
        notLowWaterAllocated, notLowWaterArbitration, notLowWaterExceptions, notLowWaterSkimming, ugeVolumes.volumeAskedLowWater,
        ugeVolumes.volumeAskedNotLowWater, aupVolumes.aup, totalAllocated, totalSkimming, totalArbitration, ugeVolumes.volume])

    const getTooltipVolumesTypePrel = (volumeESU, volumeESO) => (
        <>
            <span>{`${i18n.superficial} : ${isNil(volumeESU) || isNaN(volumeESU) ? i18n.unknown : `${formatMilliers(volumeESU)} m3`}`}</span><br />
            <span>{`${i18n.underground} : ${isNil(volumeESO) || isNaN(volumeESO) ? i18n.unknown : `${formatMilliers(volumeESO)} m3`}`}</span>
        </>
    )

    return (
        <div className='padding-1' style={{ paddingBottom: 80 }}>
            {scenario.id === idScenario ? (
                <Grid container spacing={1} justifyContent='stretch'>
                    <Grid item xs={12}>
                        <Card title={i18n.generalInfos} round cardStyle={{ height: '100%' }} className='padding-bottom-1'>
                            <Row className='padding-top-1 padding-bottom-1' spacing={1}>
                                <Input
                                    col={3}
                                    title={i18n.name}
                                    value={localScenario.name}
                                    onChange={value => setLocalScenario({ ...localScenario, name: value })}
                                    disabled={!editMode}
                                />
                                <Select
                                    col={3}
                                    label={i18n.status}
                                    options={getSandreList(sandreCodes, 'AGRI_SCENARIO.STATUT')}
                                    value={localScenario.statusCode}
                                    integerValue
                                    keyValue='code'
                                    onChange={value => setLocalScenario({ ...localScenario, statusCode: value })}
                                    disabled={!editMode}
                                    noSort
                                />
                                <Textarea
                                    col={6}
                                    title={i18n.description}
                                    value={localScenario.description}
                                    onChange={value => setLocalScenario({ ...localScenario, description: value })}
                                    disabled={!editMode}
                                />
                            </Row>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card title={i18n.overview} round>
                            <Table
                                showTitle={false}
                                data={[{
                                    nullValue: { value: i18n.requestedVolume },
                                    lowWater: { value: formatMilliers(ugeVolumes.volumeAskedLowWater), tooltip: getTooltipVolumesTypePrel(volumeAskedLowWaterESU, ugeVolumes.volumeAskedLowWater - volumeAskedLowWaterESU) },
                                    notLowWater: { value: formatMilliers(ugeVolumes.volumeAskedNotLowWater), tooltip: getTooltipVolumesTypePrel(volumeAskedNotLowWaterESU, ugeVolumes.volumeAskedNotLowWater - volumeAskedNotLowWaterESU) },
                                    totalVolume: { value: formatMilliers(ugeVolumes.volume), tooltip: getTooltipVolumesTypePrel(volumeAskedESU, ugeVolumes.volume - volumeAskedESU) },
                                    deviationFromAUP: { value: ugeVolumes.volume && aupVolumes.aup ? `${round(((ugeVolumes.volume - aupVolumes.aup) / aupVolumes.aup) * 100)}%` : '-' },
                                }, {
                                    nullValue: { value: i18n.skimming },
                                    lowWater: { value: lowWaterSkimming === 0 ? 0 : `-${formatMilliers(lowWaterSkimming)}` },
                                    notLowWater: { value: notLowWaterSkimming === 0 ? 0 : `-${formatMilliers(notLowWaterSkimming)}` },
                                    totalVolume: { value: totalSkimming === 0 ? 0 : `-${formatMilliers(totalSkimming)}` },
                                }, {
                                    nullValue: { value: i18n.arbitration },
                                    lowWater: { value: formatMilliers(lowWaterArbitration) },
                                    notLowWater: { value: formatMilliers(notLowWaterArbitration) },
                                    totalVolume: { value: formatMilliers(totalArbitration) },
                                }, {
                                    nullValue: { value: i18n.exception },
                                    lowWater: { value: formatMilliers(lowWaterExceptions) },
                                    notLowWater: { value: formatMilliers(notLowWaterExceptions) },
                                    totalVolume: { value: formatMilliers(totalExceptions) },
                                }, {
                                    nullValue: { value: i18n.allocatedVolume },
                                    lowWater: { value: formatMilliers(lowWaterAllocated), tooltip: getTooltipVolumesTypePrel(lowWaterAllocatedESU, lowWaterAllocated - lowWaterAllocatedESU) },
                                    notLowWater: { value: formatMilliers(notLowWaterAllocated), tooltip: getTooltipVolumesTypePrel(notLowWaterAllocatedESU, notLowWaterAllocated - notLowWaterAllocatedESU) },
                                    totalVolume: { value: formatMilliers(totalAllocated), tooltip: getTooltipVolumesTypePrel(totalAllocatedESU, totalAllocated - totalAllocatedESU) },
                                    deviationFromAUP: { value: totalAllocated && aupVolumes.aup ? `${round(((totalAllocated - aupVolumes.aup) / aupVolumes.aup) * 100)}%` : '-' },
                                }, {
                                    nullValue: { value: i18n.reserveVolume },
                                    lowWater: { value: formatMilliers(aupVolumes.aupLowWater - lowWaterAllocated), tooltip: getTooltipVolumesTypePrel(aupVolumes.aupLowWaterSup - lowWaterAllocatedESU, aupVolumes.aupLowWaterSout - (lowWaterAllocated - lowWaterAllocatedESU)) },
                                    notLowWater: { value: formatMilliers(aupVolumes.aupExceptLowWater - notLowWaterAllocated), tooltip: getTooltipVolumesTypePrel(aupVolumes.aupExceptLowWaterSup - notLowWaterAllocatedESU, aupVolumes.aupExceptLowWaterSout - (notLowWaterAllocated - notLowWaterAllocatedESU)) },
                                    totalVolume: { value: formatMilliers(aupVolumes.aup - totalAllocated), tooltip: getTooltipVolumesTypePrel(aupVolumes.aupLowWaterSup + aupVolumes.aupExceptLowWaterSup - totalAllocatedESU, aupVolumes.aupLowWaterSout + aupVolumes.aupExceptLowWaterSout - (totalAllocated - totalAllocatedESU)) },
                                    deviationFromAUP: { value: totalAllocated && aupVolumes.aup ? `${0 - round(((totalAllocated - aupVolumes.aup) / aupVolumes.aup) * 100)}%` : '-' },
                                }, {
                                    nullValue: { value: i18n.AUPVolume },
                                    lowWater: { value: formatMilliers(aupVolumes.aupLowWater), tooltip: getTooltipVolumesTypePrel(aupVolumes.aupLowWaterSup, aupVolumes.aupLowWaterSout) },
                                    notLowWater: { value: formatMilliers(aupVolumes.aupExceptLowWater), tooltip: getTooltipVolumesTypePrel(aupVolumes.aupExceptLowWaterSup, aupVolumes.aupExceptLowWaterSout) },
                                    totalVolume: { value: formatMilliers(aupVolumes.aup), tooltip: getTooltipVolumesTypePrel(aupVolumes.aupLowWaterSup + aupVolumes.aupExceptLowWaterSup, aupVolumes.aupLowWaterSout + aupVolumes.aupExceptLowWaterSout) },
                                    deviationFromAUP: { value: '-' },
                                }]}
                                type={{ headers: headersRecap }}
                                sortable
                                round
                                color
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card round>
                            <EChart
                                id='waterfall-scenario-par'
                                options={optionsChart}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            title={i18n.rulesOfInternalRegulation}
                            data={rulesSkimming}
                            type={{ headers: headersSkimming }}
                            customHeaders={{
                                impactedLowWaterVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.impactedLowWaterVolumeWrap}</span>,
                                impactedNotLowWaterVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.impactedNotLowWaterVolumeWrap}</span>,
                            }}
                            nbPerPageLabel={nbPerPageLabelShort}
                            paging
                            sortable
                            round
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            title={i18n.arbitration}
                            data={rulesArbitration}
                            type={{ headers: headersArbitration }}
                            customHeaders={{
                                impactedLowWaterVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.impactedLowWaterVolumeWrap}</span>,
                                impactedNotLowWaterVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.impactedNotLowWaterVolumeWrap}</span>,
                                volumeExemptFromRule: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.volumeExemptFromRuleWrap}</span>,
                            }}
                            nbPerPageLabel={nbPerPageLabelShort}
                            paging
                            sortable
                            round
                            actions={editMode ? [{
                                tooltip: i18n.add,
                                iconName: 'note_add',
                                onClick: () => {
                                    setRule({ ruleType: 'arbitrage', parameters: { operator: 2, unit: 1, periode: PAR.periode } })
                                    setOpenRule(true)
                                },
                            }] : []}
                            alterable={editMode}
                            onAlter={s => {
                                setRule(s)
                                setOpenRule(true)
                            }}
                        />
                    </Grid>
                    {getDialogScenario()}
                    {openRule && (
                        <RuleDialog
                            rule={{ ...rule, parameters: { ...rule.parameters, exceptions: rule?.parameters?.exceptions || localScenario?.linkRules?.find((lR) => lR.idRule === rule.id)?.parameters?.exceptions } }}
                            onChange={setRule}
                            datasRules={localDatasRules}
                            datasDiffRules={localDatasDiffRules}
                            onClose={() => setOpenRule(false)}
                            onValidate={onSaveRule}
                        />
                    )}
                </Grid>
            ) : <ProgressCard indeterminate round />}
        </div>
    )
}

ScenarioDashboardApp.propTypes = {
    surveysWithStats: arrayOf(DtoSurveyWithStats),
    managementUnits: arrayOf(DtoManagementUnit),
    rulesScenarios: arrayOf(DtoRuleScenarios),
    match: PropTypes.object,
}

export default ScenarioDashboardApp