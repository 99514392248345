import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import MethodAction from '../actions/MethodAction'
import { push } from 'connected-react-router'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_METHOD,
    PATH_REFERENCIAL_METHOD_NEW,
} from '../../../../home/constants/RouteConstants'
import { sandreMethodDefaultLink } from '../../../../conf/SieauConstants'
import { find } from 'lodash'
import ReferentialAction from '../../../action/ReferencialAction'
import HomeAction from '../../../../home/actions/HomeAction'
import { getReferencialData } from '../../../util/ReferencialUtils'
import logoSandre from 'assets/pictures/logo_sandre.png'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { nbPerPageLabel, REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import Table from 'components/datatable/Table'
import { getFullDate } from 'utils/DateUtil'
import Card from 'components/card/Card'
import Row from 'components/react/Row'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import { Button } from '@mui/material'
import useSessionStorage from 'utils/customHook/useSessionStorage'
import { hasValue } from 'utils/NumberUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import ReferencialAction from '../../../action/ReferencialAction'

const headers = ['code', 'name', 'status']

const MethodsApp = () => {
    const {
        methods,
        referencialSandreCodes,
        referencialStatus,
    } = useSelector(store => ({
        methods: store.MethodReducer.methods,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const [filter, setFilter] = useSessionStorage(`referencial_${REFERENCIAL_TYPE_NAME.method}`, {})
    const [filterTmp, setFilterTmp] = useState(filter)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(MethodAction.fetchMethods())
        dispatch(ReferentialAction.fetchReferencialSandreCodes())
        dispatch(HomeAction.setHelpLink('', ''))
        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.methods,
        href: PATH_REFERENCIAL_METHOD,
    }], [])

    const methodByStatus = useMemo(() => hasValue(filter.status) ? methods.filter(h => parseInt(h.status) === filter.status) : methods, [filter.status, methods])
    const referencialData = useMemo(() => getReferencialData(methodByStatus), [methodByStatus])
    const data = useMemo(() => {
        return referencialData
            .filter((obj) => searchAllCharacters(headers.map((key) => obj[key]).join())
                .includes(searchAllCharacters(filter.searchValue || '')))
    }, [referencialData, filter])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_METHOD_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(data, headers),
                        titleFile: i18n.methodList,
                    },
                    sync: {
                        updateAction: () => dispatch(MethodAction.fetchSynchroSandreMethod()),
                    },
                    purge: {
                        onPurge: () => dispatch(MethodAction.purgeMethod()),
                    },
                },
            },
            links: [{
                href: sandreMethodDefaultLink,
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            }],
        }
    }, [data])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'METHODES'), [referencialSandreCodes])

    const colorData = useMemo(() => data.map(p => ({
        id: p.id,
        code: { value: p.code },
        name: { value: p.name },
        status: { value: p.status },
        lineColor: p.active === '1' ? 'white' : 'grey lighten-1',
    })).sort((a, b) => parseInt(a.code.value) - parseInt(b.code.value)), [data])

    return (
        <>
            <Card noMargin={false} className='margin-left-1 margin-right-1' title={i18n.criterias}>
                <Row className='padding-top-1 padding-bottom-5-px'>
                    <Input
                        col={3}
                        title={i18n.search}
                        value={filterTmp.searchValue}
                        onChange={v => setFilterTmp({ ...filterTmp, searchValue: v })}
                        onEnterKeyPress={() => setFilter({ ...filterTmp })}
                    />
                    <Select
                        options={referencialStatus}
                        label={i18n.status}
                        col={3}
                        onChange={v => setFilterTmp({ ...filterTmp, status: v })}
                        value={filterTmp.status}
                        nullLabel='&nbsp;'
                    />
                    <div className='col s6 padding-top-1'>
                        <Button className='right' onClick={() => setFilter({ ...filterTmp })} variant='contained' color='primary'>
                            {i18n.search}
                        </Button>
                    </div>
                </Row>
            </Card>
            {lastUpdate?.updateUse && lastUpdate?.updateDate && (
                <div className='row no-margin-bottom'>
                    <div className='col s4 center offset-s8 card'>
                        <div className='col s12'>
                            { i18n.lastUpdateOn } { getFullDate(lastUpdate.updateDate) } { i18n.by } { lastUpdate.updateUser }
                        </div>
                    </div>
                </div>
            )}
            <div className='margin-1 padding-top-5-px referencial-table'>
                <Table
                    title={i18n.methodList}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabel}
                    data={colorData}
                    type={{ headers }}
                    color
                    sortable={!!colorData.length}
                    onClick={method => dispatch(push(`/referencial/method/${method.id}/dashboard`))}
                />
            </div>
        </>
    )
}

export default MethodsApp
