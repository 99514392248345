module.exports = {
    SPECIFIC_SUPPORT_CODES: ['10'],

    INDICES_RANGE: [
        { code: '5910', max: 20 },
        { code: '7613', max: 1 },
        { code: '5856', max: 20 },
        { code: '2928', max: 20 },
        { code: '7036', max: 0, min: 150, inverted: true }, // inverted
        { code: '7939', max: 1 },
        { code: '1022', max: 20 },
        { code: '8058', max: 1 },
        { code: '8049', max: 1 },
        { code: '7614', max: 1 },
        { code: '6951', max: 20 },
        { code: '5909', max: 20 },
        { code: '5908', max: 20 },
        { code: '5911', max: 20 },
        { code: '5912', max: 20 },
        { code: '8973', max: 20 },
        { code: '8969', max: 20 },
        { code: '1000', max: 20 },
        { code: '1017', max: 1 },
        { code: '9025', max: 1 },
        { code: '8562', max: 1 },
        { code: '8040', max: 20 },
        { code: '8053', max: 20 },
    ],
}