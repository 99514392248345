import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import Job from '../../../import/dto/DtoJob'
import JobAlert from './JobAlert'


class AlertHydro extends Component {
    componentDidMount() {
        this.props.fetchHydrometers()
        this.props.fetchHydrometricThresholds()
    }

    render() {
        return (
            <JobAlert
                job={this.props.job}
                isEditMode={this.props.isEditMode}
                stations={this.props.hydrometers}
                thresholds={this.props.hydrometryThresholds}
                onChangeEmails={this.props.onChangeEmails}
                onChangeContactsIds={this.props.onChangeContactsIds}
                onChangeStation={this.props.onChangeStation}
                onChangeAlertTypes={this.props.onChangeAlertTypes}
            />
        )
    }
}

AlertHydro.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    hydrometers: PropTypes.arrayOf(PropTypes.object),
    hydrometryThresholds: PropTypes.arrayOf(PropTypes.object),
    fetchHydrometers: PropTypes.func,
    fetchHydrometricThresholds: PropTypes.func,
}

const mapStateToProps = store => ({
    hydrometers: store.HydrometryReducer.hydrometricStations.map(({ id, code, name }) => ({
        id,
        code: `${id}`,
        name: `[${code}] ${name}`,
    })),
    hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds.map(({ id, stationId, value, title }) => ({
        id,
        code: stationId,
        title: `${title}: ${value}`,
    })),
})

const mapDispatchToProps = {
    fetchHydrometers: HydrometryAction.fetchHydrometricStations,
    fetchHydrometricThresholds: HydrometryAction.fetchHydrometricThresholds,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertHydro)