import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import CentralTypeDto from '../central/dto/CentralTypeDto'
import PropTypes from 'prop-types'
import { flatten, orderBy } from 'lodash'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import { CENTRAL, EQUIPMENT, POWER_SUPPLY, SENSOR, SIM, TELECOM, VARIOUS } from '../../constants/MaterielConstants'
import HomeAction from '../../../home/actions/HomeAction'
import { ADMINISTRATION, PATH_DASHBOARD, PATH_MATERIEL } from '../../../home/constants/RouteConstants'
import CentralAction from '../central/actions/CentralAction'
import PowerSupplyAction from '../powerSupply/actions/PowerSupplyAction'
import SensorAction from '../sensor/actions/SensorAction'
import TelecomAction from '../telecom/actions/TelecomAction'
import SimAction from '../sim/actions/SimAction'
import VariousMaterielAction from '../variousMateriel/actions/VariousMaterielAction'
import PowerSupplyTypeDto from '../powerSupply/dto/PowerSupplyTypeDto'
import DtoSensorType from '../sensor/dto/DtoSensorType'
import TelecomTypeDto from '../telecom/dto/TelecomTypeDto'
import DtoSimType from '../sim/dto/DtoSimType'
import DtoVariousMaterielType from '../variousMateriel/dto/DtoVariousMaterielType'
import Table from '../../../components/datatable/Table'
import MaterielTypeForm from './MaterielTypeForm'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import EquipmentAction from '../equipment/actions/EquipmentAction'
import EquipmentTypeDto from '../equipment/dto/EquipmentTypeDto'
import MaterielTypeDto from '../../dto/MaterielTypeDto'
import { getMaterielIconValue } from '../../../utils/MaterielUtils'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import MaterielAction from '../../actions/MaterielAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_MAT_OPTIONS } from '../../../account/constants/AccessRulesConstants'
import Select from 'components/forms/Select'

class MaterielTypeApp extends Component {
    state = {
        selectedMaterielType: undefined,
        selectedType: undefined,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setTitle([{
            title: i18n.materiel,
            href: `${PATH_MATERIEL}/${PATH_DASHBOARD}`,
        }, {
            title: i18n.administration,
            href: `${PATH_MATERIEL}/${ADMINISTRATION}`,
        }])
        this.props.fetchContributors()
        this.props.fetchCentralTypes(true)
        this.props.fetchPowerSupplyTypes(true)
        this.props.fetchSensorTypes(true)
        this.props.fetchTelecomTypes(true)
        this.props.fetchSimTypes(true)
        this.props.fetchVariousMaterielTypes()
        this.props.fetchEquipmentTypes()
        this.props.fetchSandreCodes()
    }

    componentWillUnmount = () => {
        this.props.resetAllMateriels()
    }

    getDatas = materielTypes => {
        const {
            selectedMaterielType,
        } = this.state
        if (selectedMaterielType) {
            return this.getTypes(selectedMaterielType)
        }
        const types = materielTypes.map(({ code }) => {
            return this.getTypes(code)
        })
        return orderBy(flatten(types), ['materielCategory', 'name'], 'asc')
    }

    getTypes = type => {
        switch (type) {
            case CENTRAL:
                return this.props.centralTypes
            case POWER_SUPPLY:
                return this.props.powerSupplyTypes
            case SENSOR:
                return this.props.sensorTypes
            case TELECOM:
                return this.props.telecomTypes
            case SIM:
                return this.props.simTypes
            case VARIOUS:
                return this.props.variousMaterielTypes
            case EQUIPMENT:
                return this.props.equipmentTypes
            default:
                return []
        }
    }

    render = () => {
        const { materielTypes } = this.props
        const { selectedMaterielType, selectedType } = this.state
        const types = this.getDatas(materielTypes).map(type => {
            const {
                materielCategory, endDate, materielLabel, label, comment,
            } = type
            return {
                nullValue: <i className='material-icons clickable' onClick={() => this.setState({ selectedType: type })}>{'edit'}</i>,
                icon: <i className='material-icons'>{getMaterielIconValue(materielCategory)}</i>,
                materielLabel,
                label,
                comment,
                color: endDate && endDate < moment().valueOf() ? '#cccccc' : 'white',
            }
        })
        return (
            <div className='col s12'>
                <div className='row no-margin'>
                    <div className='col s12'>
                        <div className='row card'>
                            <div className='card-content'>
                                <div className='row'>
                                    <Select
                                        col={6}
                                        options={materielTypes.filter(({ id }) => id !== 8)}
                                        label={i18n.type}
                                        nullLabel='&nbsp;'
                                        onChange={value => this.setState({ selectedMaterielType: value != -1 ? value : undefined })}
                                        value={selectedMaterielType}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col s12'>
                        <div className='row margin-top-2'>
                            <div className='col s6 no-padding'>
                                <div className='row'>
                                    <Table
                                        title={i18n.materielTypes}
                                        data={types}
                                        type={{ headers: ['nullValue', 'icon', 'materielLabel', 'label', 'comment'] }}
                                        condensed
                                        coloredLine
                                        maxHeight='75%'
                                    />
                                </div>
                            </div>
                            <div className='col s6'>
                                <MaterielTypeForm materielType={selectedType} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MaterielTypeApp.propTypes = {
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorType)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimType)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),

    setTitle: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchCentralTypes: PropTypes.func,
    fetchPowerSupplyTypes: PropTypes.func,
    fetchSensorTypes: PropTypes.func,
    fetchTelecomTypes: PropTypes.func,
    fetchSimTypes: PropTypes.func,
    fetchVariousMaterielTypes: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchEquipmentTypes: PropTypes.func,
    push: PropTypes.func,
    resetAllMateriels: PropTypes.func,
}

const mapStateToProps = store => ({
    materielTypes: store.MaterielReducer.materielTypes,
    centralTypes: store.CentralReducer.centralTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sensorTypes: store.SensorReducer.sensorTypes,
    telecomTypes: store.TelecomReducer.telecomTypes,
    simTypes: store.SimReducer.simTypes,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchContributors: ContributorAction.fetchContributors,
    fetchCentralTypes: CentralAction.fetchCentralTypes,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    fetchSensorTypes: SensorAction.fetchSensorTypes,
    fetchTelecomTypes: TelecomAction.fetchTelecomTypes,
    fetchSimTypes: SimAction.fetchSimTypes,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchEquipmentTypes: EquipmentAction.fetchEquipmentTypes,
    resetAllMateriels: MaterielAction.resetAllMateriels,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterielTypeApp)
