import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import { setModal } from '../../../../utils/FormUtils'
import { parseJsonObj } from '../../../../utils/JsonUtils'
import DtoAquaBoxFilter from '../../../dto/DtoAquaBoxFilter'
import DtoAquaBoxParameter from '../../../dto/DtoAquaBoxParameter'
import Job from '../../../dto/DtoJob'
import ImportFileModal from './ImportFileModal'


class AquaBoxPanel extends Component {
    constructor(props) {
        super(props)
        const { filters, parameters } = props.job.parameters
        this.state = {
            filters: this.getFilter(filters),
            parameters: this.getParameters(parameters),
        }
    }

    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            actions: (
                <div>
                    <div>
                        <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                    </div>
                </div>
            ),
            content: <ImportFileModal url={this.props.job.parameters.path} />,
        })
    }

    componentWillMount() {
        AppStore.dispatch(PiezometryAction.fetchPiezometryDataTypes())
    }

    getParameters = (parameters = []) => {
        return parameters.map(p => new DtoAquaBoxParameter(parseJsonObj(p)))
    }

    getFilter = (filters = []) => {
        return new DtoAquaBoxFilter(parseJsonObj(filters[0]))
    }

    componentWillReceiveProps(nextProps) {
        const nextParameters = nextProps.job.parameters
        const { parameters } = this.props.job
        if (!nextParameters.filters != !parameters.filters || !isEqual(nextParameters.filters, parameters.filters)) {
            this.setState({
                filters: this.getFilter(nextParameters.filters),
            })
        }
        if (!nextParameters.parameters != !parameters.parameters || !isEqual(nextParameters.parameters, parameters.parameters)) {
            this.setState({
                parameters: this.getParameters(nextParameters.parameters),
            })
        }
    }

    handleChangeFilter = e => {
        const filters = JSON.stringify({
            ...this.state.filters,
            [e.target.id]: e.target.value,
        })
        this.props.onChangeFilters([ filters ])
    }

    handleChangeFilterCheckbox = k => e => {
        const filters = JSON.stringify({
            ...this.state.filters,
            [k]: e,
        })
        this.props.onChangeFilters([ filters ])
    }

    handleChangeParameters = (param, paramidx) => value => {
        const params = [...this.state.parameters]
        params[paramidx] = {
            ...params[paramidx],
            [param]: value,
        }
        this.onChangeParameters(params)
    }

    handleChangeData = (param, paramidx) => value => {
        const params = [...this.state.parameters]
        const data = params[paramidx].data.filter(o => o.id != param)
        if ((value || value === 0) && value !== ' ') {
            params[paramidx].data = [
                ...data,
                {
                    id: parseInt(param),
                    column: value,
                },
            ]
        } else {
            params[paramidx].data = [...data]
        }
        this.onChangeParameters(params)
    }

    handleSelectStation = i => e => {
        if (e && e.code) {
            const params = [...this.state.parameters]
            params[i] = {
                ...params[i],
                stationCode: e.code,
            }
            this.onChangeParameters(params)
        }
    }

    onChangeParameters = (params = []) => {
        const parameters = params.map(o => JSON.stringify(o))
        this.props.onChangeParameters(parameters)
    }

    addParameter = () => {
        const params = [new DtoAquaBoxParameter(), ...this.state.parameters]
        this.onChangeParameters(params)
    }

    onDeleteParameter = e => {
        if (e) {
            const params = this.state.parameters.filter((o, i) => i != e.idx)
            this.onChangeParameters(params)
        }
    }

    onDuplicateParameter = e => {
        if (e) {
            const { parameters } = this.state
            const start = parameters.slice(0, e.idx)
            const end = parameters.slice(e.idx,)
            const params = [...start, parameters[e.idx], ...end]
            this.onChangeParameters(params)
        }
    }

    getParametersHeader = parameters => ({
        headers: Object.keys(parameters[0]).filter(k => k !== 'idx'),
    })

    getDataValue = value => {
        return value || value == 0 ? value : ''
    }

    getParametersRow = () => {
        const disabled = !this.props.isEditMode
        return this.state.parameters.map((p, i) => {
            const piezometryDataTypes = [
                { id: 0, label: i18n.min },
                { id: 1, label: i18n.max },
                { id: 3, label: i18n.brute },
                ...this.props.piezometryDataTypes,
            ]
            const datas = piezometryDataTypes.reduce((a, d) => {
                const data = p.data.find(o => o.id == d.id) || {}
                const value = this.getDataValue(data.column)
                return {
                    ...a,
                    [d.label]: (
                        <NumberField
                            className='min-width-40'
                            value={ value }
                            onChange={ this.handleChangeData(d.id, i) }
                            min='0'
                            noInputFieldClass
                            dispatchOnNull
                            disabled={ disabled }
                        />
                    ),
                }
            }, {})
            return {
                idx: i,
                directory: (
                    <Input
                        className='min-width-100'
                        value={ p.directory }
                        onChange={ this.handleChangeParameters('directory', i) }
                        noInputFieldClass
                        disabled={ disabled }
                    />
                ),
                filter: (
                    <Input
                        className='min-width-70'
                        value={ p.filter }
                        onChange={ this.handleChangeParameters('filter', i) }
                        noInputFieldClass
                        disabled={ disabled }
                    />
                ),
                code: (
                    <Select
                        className='min-width-150'
                        noInputFieldClass
                        options={ this.props.piezometers }
                        onChange={ (_, i) => this.handleSelectStation(i) }
                        value={ p.stationCode }
                        keyValue='code'
                        clearFunction
                        disabled={ disabled }
                    />
                ),
                coeff: (
                    <NumberField
                        className='min-width-40'
                        value={ p.factor }
                        onChange={ this.handleChangeParameters('factor', i) }
                        noInputFieldClass
                        floatValue
                        dispatchOnNull
                        disabled={ disabled }
                    />
                ),
                offset: (
                    <NumberField
                        className='min-width-40'
                        value={ p.offset }
                        onChange={ this.handleChangeParameters('offset', i) }
                        noInputFieldClass
                        floatValue
                        dispatchOnNull
                        disabled={ disabled }
                    />
                ),
                date: (
                    <NumberField
                        className='min-width-40'
                        value={ p.dateColumn }
                        onChange={ this.handleChangeParameters('dateColumn', i) }
                        min='0'
                        noInputFieldClass
                        dispatchOnNull
                        disabled={ disabled }
                    />
                ),
                hour: (
                    <NumberField
                        className='min-width-40'
                        value={ p.hourColumn }
                        onChange={ this.handleChangeParameters('hourColumn', i) }
                        min='0'
                        noInputFieldClass
                        dispatchOnNull
                        disabled={ disabled }
                    />
                ),
                ...datas,
            }
        })
    }

    render() {
        const parameters = this.getParametersRow()
        const disabled = !this.props.isEditMode
        return (
            <div className='aquabox-panel'>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s1 no-padding'>
                        <div className='right'>
                            <label htmlFor='path'>{ i18n.directory }</label>
                        </div>
                    </div>
                    <div className='input-field col s5'>
                        <div className='col s12'>
                            <input
                                id='path'
                                type='text'
                                value={ this.props.job.parameters.path }
                                onChange={ this.props.onChangePath }
                                disabled={ disabled }
                            />
                        </div>
                    </div>
                    <a className='btn btn-small secondary-color-back' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                    <div className='col s1 no-padding'>
                        <div className='right'>
                            <label htmlFor='dateFormat'>{ i18n.formatDate }</label>
                        </div>
                    </div>
                    <div className='input-field col s5'>
                        <div className='col s12'>
                            <input
                                id='dateFormat'
                                type='text'
                                value={ this.state.filters.dateFormat }
                                onChange={ this.handleChangeFilter }
                                disabled={ disabled }
                            />
                        </div>
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s1 no-padding'>
                        <div className='right'>
                            <label htmlFor='excludedValue'>{ i18n.excludedValue }</label>
                        </div>
                    </div>
                    <div className='input-field col s2'>
                        <div className='col s12'>
                            <input
                                id='excludedValue'
                                type='text'
                                value={ this.state.filters.excludedValue }
                                onChange={ this.handleChangeFilter }
                                disabled={ disabled }
                            />
                        </div>
                    </div>
                    <div className='col s1 no-padding'>
                        <div className='right'>
                            <label htmlFor='separator'>{ i18n.separator }</label>
                        </div>
                    </div>
                    <div className='input-field col s2'>
                        <div className='col s12'>
                            <input
                                id='separator'
                                type='text'
                                value={ this.state.filters.separator }
                                onChange={ this.handleChangeFilter }
                                disabled={ disabled }
                            />
                        </div>
                    </div>
                    <div className='col s2'>
                        <div className='right'>
                            <label htmlFor='hasHeader'>{ i18n.hasHeader }</label>
                        </div>
                    </div>
                    <div className='col s1 no-padding'>
                        <Checkbox
                            col={ 12 }
                            onChange={ this.handleChangeFilterCheckbox('hasHeader') }
                            disabled={ disabled }
                            checked={ this.state.filters.hasHeader }
                        />
                    </div>
                    <div className='col s2 no-padding'>
                        <div className='col s12 no-padding'>
                            <a className='right waves-effect waves-light btn' onClick={ this.addParameter }>
                                <i className='material-icons left'>add_box</i>
                                { i18n.add }
                            </a>
                        </div>
                    </div>
                </div>
                {
                    (parameters.length > 0) && (
                        <div className='job-parameter-table'>
                            <Table
                                showTitle={ false }
                                data={ parameters }
                                nbPerPageLabel={ nbPerPageLabelShort }
                                type={ this.getParametersHeader(parameters) }
                                onDelete={ this.onDeleteParameter }
                                onDuplicate={ this.onDuplicateParameter }
                                deletable
                                duplicable
                                sortable
                                condensed
                                paging
                            />
                        </div>
                    )
                }
            </div>
        )
    }
}

AquaBoxPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangePath: PropTypes.func.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    onChangeParameters: PropTypes.func.isRequired,
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.object),
    piezometers: PropTypes.arrayOf(PropTypes.object),
    isEditMode: PropTypes.bool,
}

AquaBoxPanel.defaultProps = {
    onChangePath: () => {},
    onChangeFilters: () => {},
    onChangeParameters: () => {},
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometers: store.PiezometryReducer.piezometers,
})

export default connect(mapStateToProps)(AquaBoxPanel)
