import { H_MAT_OPTIONS } from 'account/constants/AccessRulesConstants'
import { push } from 'connected-react-router'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import ReferencialSandreCodeDto from 'referencial/dto/ReferencialSandreCodeDto'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import FixedSlideNav from '../../../components/navbar/FixedSlideNav'
import MaterielAction from '../../actions/MaterielAction'
import MaterielTypeDto from '../../dto/MaterielTypeDto'
import MaterielTypeApp from '../materielType/MaterielTypeApp'
import AdministrationMaterielApp from './AdministrationMaterielApp'
import MaterielRuleApp from './MaterielRuleApp'

class MaterielOptionsApp extends Component {
    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const { materielTypes, referencialSandreCodes } = this.props
        if (!materielTypes.length) {
            this.props.fetchMaterielTypes()
        }
        if (!referencialSandreCodes.length) {
            this.props.fetchReferencialSandreCodes()
        }
    }

    render() {
        const navBarLinks = [{
            href: '/materiel/administration/materiel',
            icons: 'router',
            name: i18n.materiel,
        }, {
            href: '/materiel/administration/type',
            icons: 'devices_other',
            name: i18n.type,
        }, {
            href: '/materiel/administration/rule',
            icons: 'assignment',
            name: i18n.saveRule,
        }]

        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks} />
                <main>
                    <div className='row no-margin'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Switch>
                                    <Redirect exact path='/materiel/administration' to='/materiel/administration/materiel' />
                                    <Route path='/materiel/administration/type' render={(props) => <MaterielTypeApp {...props} />} />
                                    <Route path='/materiel/administration/materiel' render={(props) => <AdministrationMaterielApp {...props} />} />
                                    <Route path='/materiel/administration/rule' render={(props) => <MaterielRuleApp {...props} />} />
                                </Switch>
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

MaterielOptionsApp.propTypes = {
    children: PropTypes.element,
    referencialSandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(ReferencialSandreCodeDto)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    fetchMaterielTypes: PropTypes.func,
    fetchReferencialSandreCodes: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    materielTypes: store.MaterielReducer.materielTypes,
})

const mapDispatchToProps = {
    fetchReferencialSandreCodes: ReferencialAction.fetchReferencialSandreCodes,
    fetchMaterielTypes: MaterielAction.fetchMaterielTypes,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterielOptionsApp)
