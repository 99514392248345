import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import SieauAction from '../../../../components/sieau/SieauAction'
import {
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_LINK,
} from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import {
    RECEIVE_ALL_HYDROGEOLOGICAL_ENTITIES,
    RECEIVE_HYDROGEOLOGICAL_ENTITY,
    RECEIVE_HYDROGEOLOGICAL_ENTITY_STATE,
    RECEIVE_HYDROGEOLOGICAL_ENTITY_STRUCTURE,
    RECEIVE_HYDROGEOLOGICAL_ENTITY_TYPE,
    RESET_HYDROGEOLOGICAL_ENTITY,
} from '../constants/HydrogeologicalEntityConstants'

const HydrogeologicalEntityAction = {
    receiveHydrogeologicalEntities(hydrogeologicalEntities) {
        return { type: RECEIVE_ALL_HYDROGEOLOGICAL_ENTITIES, hydrogeologicalEntities }
    },

    receiveHydrogeologicalEntity(hydrogeologicalEntity) {
        return { type: RECEIVE_HYDROGEOLOGICAL_ENTITY, hydrogeologicalEntity }
    },

    receiveHydrogeologicalEntityStructure(hydrogeologicalEntityStructure) {
        return {
            type: RECEIVE_HYDROGEOLOGICAL_ENTITY_STRUCTURE,
            hydrogeologicalEntityStructure,
        }
    },

    receiveHydrogeologicalEntityState(hydrogeologicalEntityState) {
        return { type: RECEIVE_HYDROGEOLOGICAL_ENTITY_STATE, hydrogeologicalEntityState }
    },

    receiveHydrogeologicalEntityType(hydrogeologicalEntityType) {
        return { type: RECEIVE_HYDROGEOLOGICAL_ENTITY_TYPE, hydrogeologicalEntityType }
    },

    fetchHydrogeologicalEntityStructure() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.hydrogeologicalEntityStructure(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(HydrogeologicalEntityAction.receiveHydrogeologicalEntityStructure(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrogeologicalEntityStructure} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrogeologicalEntityStructure))
                })
        }
    },

    fetchHydrogeologicalEntityState() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.hydrogeologicalEntityState(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(HydrogeologicalEntityAction.receiveHydrogeologicalEntityState(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrogeologicalEntityState} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrogeologicalEntityState))
                })
        }
    },

    fetchHydrogeologicalEntityType() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.hydrogeologicalEntityType(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(HydrogeologicalEntityAction.receiveHydrogeologicalEntityType(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrogeologicalEntityType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrogeologicalEntityType))
                })
        }
    },

    fetchHydrogeologicalEntity(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.hydrogeologicalEntity(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(HydrogeologicalEntityAction.receiveHydrogeologicalEntity(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrogeologicalEntity} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrogeologicalEntity))
                })
        }
    },

    promiseHydrogeologicalEntities() {
        return fetch(ApplicationConf.referencial.hydrogeologicalEntities(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchHydrogeologicalEntities() {
        return (dispatch) => {
            dispatch(SieauAction.receiveProps('hydrogeologicalEntities'))
            return HydrogeologicalEntityAction.promiseHydrogeologicalEntities()
                .then((json = []) => {
                    dispatch(HydrogeologicalEntityAction.receiveHydrogeologicalEntities(json))
                })
        }
    },

    createHydrogeologicalEntity(hydrogeologicalEntity) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.hydrogeologicalEntities(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(hydrogeologicalEntity),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_LINK + hydrogeologicalEntity.code))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.hydrogeologicalEntity} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.hydrogeologicalEntity))
                })
        }
    },

    updateHydrogeologicalEntity(hydrogeologicalEntity, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveHydrogeologicalEntity(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(hydrogeologicalEntity),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(HydrogeologicalEntityAction.fetchHydrogeologicalEntity(code))
                        dispatch(ToastrAction.success(i18n.hydrogeologicalEntitySuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrogeologicalEntity} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.hydrogeologicalEntity))
                })
        }
    },

    deleteHydrogeologicalEntity(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteHydrogeologicalEntity(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.hydrogeologicalEntity} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.hydrogeologicalEntity))
                })
        }
    },

    purgeHydrogeologicalEntity() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeHydrogeologicalEntity(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(HydrogeologicalEntityAction.fetchHydrogeologicalEntities())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.hydrogeologicalEntities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.hydrogeologicalEntities))
                })
        }
    },

    replaceHydrogeologicalEntity(hydrogeologicalEntity, hydrogeologicalEntityReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceHydrogeologicalEntity(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: hydrogeologicalEntity,
                    replaceCode: hydrogeologicalEntityReplace,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.hydrogeologicalEntitySuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.hydrogeologicalEntity} : ${err}`))
                })
        }
    },

    reset() {
        return { type: RESET_HYDROGEOLOGICAL_ENTITY }
    },
}

export default HydrogeologicalEntityAction
