import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { DOCUMENTS_EXTENSIONS, PICTURES_EXTENSIONS } from 'components/file/constant/FileConstants'
import { push } from 'connected-react-router'
import { groupBy, isNil, keys, orderBy, sumBy, template } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getDocumentTypeIcon, getFileExtension } from 'utils/FileUtils'
import Select from '../../../components/forms/Select'
import Icon from '../../../components/icon/Icon'
import GlobalCardList from '../../../components/list/cardList/GlobalCardList'
import PanelList from '../../../components/list/panelList/PanelList'
import SmallPictureLine from '../../../components/picture/SmallPictureLine'
import { contentsPath } from '../../../conf/basepath'
import { arrestsPath } from '../../../conf/SieauConstants'
import CmsAction from '../../../events/actions/CmsAction'
import CMSCategoryDto from '../../../events/dto/CMSCategoryDto'
import CMSEventDto from '../../../events/dto/CMSEventDto'
import DtoArrest from '../../../home/dto/DtoArrest'
import { getArrestColor, getNewsColor } from '../../../utils/ColorUtil'
import { getDateWithHourString, getFullDate } from '../../../utils/DateUtil'
import { setConfirmationModal, sieauTooltip } from '../../../utils/FormUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getLogin, getUser } from '../../../utils/SettingUtils'
import { getLabel } from '../../../utils/StoreUtils'
import AlertCmsModal from './AlertCmsModal'
import AlertNewCmsStepper from './AlertNewCmsStepper'
import DtoCmsModel from 'events/dto/DtoCmsModel'
import { STATUS } from 'events/constants/CMSEventConstants'

class EventsMapTabs extends Component {
    state = {
        visibilityOption: [i18n.archived, i18n.draft],
        isOpen: false,
        openAcquittement: false,
        category: {},
        cms: {},
        statusChange: {},
        cmsStepper: {},
        openCmsStepper: false,
    }

    getCheckbox = (content, category) => content.acquittementLogin ? (
        <i
            className='material-icons small padding-right-1'
            {...sieauTooltip(template(i18n.acquittedAtDateByLogin)({
                date: getDateWithHourString(content.acquittementDate),
                login: content.acquittementLogin,
            }))}
        >
            check_box
        </i>
    ) : (
        <i
            className='material-icons small clickable padding-right-1'
            onClick={() => this.setState({ cms: content, openAcquittement: true, category })}
        >
            check_box_outline_blank
        </i>
    )

    getCms = (category = {}, content, full) => {
        const contentPictures = content.cmsDocument.filter(({ name }) => PICTURES_EXTENSIONS.find(ext => name.includes(ext)))
        const documentPictures = content.cmsDocument.filter(({ name }) => DOCUMENTS_EXTENSIONS.find(ext => name.includes(ext)))
        const pictures = this.getLinks(contentPictures) || {}
        const documents = this.getLinks(documentPictures) || {}
        const comment = content.comment || ''
        return (
            <div>
                <div className='row' style={{ marginBottom: '0px' }}>
                    <div className='col s12' onClick={() => this.setState({ cmsStepper: content, openCmsStepper: true })} style={{ display: 'flex', cursor: 'pointer' }}>
                        <i className='material-icons'>{category.icon}</i>
                        <div className='valign-wrapper'>
                            <h6 className='bold'>{content.title}</h6>
                            {
                                content.status === STATUS.DRAFTED && (
                                    <i className='material-icons small' {...sieauTooltip(i18n.edit)}>create</i>
                                )
                            }
                        </div>
                    </div>
                    <div className='col s12' style={{ display: 'flex' }}>
                        {!!pictures.length && <SmallPictureLine pictures={pictures} />}
                        {!!documents.length && (
                            <div className='row no-margin'>
                                {documents.map(doc => (
                                    <div className='col' >
                                        <img
                                            src={getDocumentTypeIcon(getFileExtension(doc.name))}
                                            onClick={() => window.open(doc.url, '_blank')}
                                            style={{ maxHeight: '30px', cursor: 'pointer' }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: !full && comment.length > 400 ? `${comment.slice(0, 400)}...` : comment }} />
                    </div>
                </div>
            </div >
        )
    }

    getLinks = (contentPictures) => {
        if (contentPictures?.length) {
            return contentPictures.map(({ name }) => ({
                name,
                url: `${contentsPath}CMS/${name}`,
            }))
        }
        return null
    }

    getContentRow = content => {
        const {
            cmsCategories = [],
            onHover = () => { },
        } = this.props
        const color = content.status != 1 ? 'grey lighten-2' : ''
        const category = cmsCategories.find(c => c.id === content.idCategory) || {}
        return (
            <div onMouseOver={() => onHover(content, true)} onMouseOut={() => onHover(content, false)}>
                <div key={content.id} className={`collection-item ${color}`}>
                    {this.getCms(category, content)}
                    <div className='event-card margin-top-1'>
                        <div className='row no-margin event-footer no-padding'>
                            <div className='left row no-margin valign-wrapper'>
                                <div className='col s12'>
                                    <h6 className='italic-label'>{`${i18n.writtenBy} ${content.author} ${i18n.atDate} ${getFullDate(content.updateDate)}`}</h6>
                                </div>
                            </div>
                            <div className='right row no-margin valign-wrapper'>
                                <div className='col s12'>
                                    {this.getCheckbox(content, category)}
                                    <Icon size='small' icon='info' onClick={() => this.setState({ cms: content, isOpen: true })} tooltip={i18n.description} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    closeCms = () =>
        this.props.updateCMSEvent({ ...this.state.cms, status: 3, dateFin: +moment(), dontGenerateNewsletter: true }, false).then(() => this.setState({ cms: {}, isOpen: false }))

    validateCms = () =>
        this.props.updateCMSEvent({ ...this.state.cms, status: 1, sendMail: true, sendNotif: true }, false).then(() => this.setState({ cms: {}, isOpen: false }))

    draftCms = () =>
        this.props.updateCMSEvent({ ...this.state.cms, status: 2 }, false).then(() => this.setState({ cms: {}, isOpen: false }))

    setVisibilityOption = (key) => {
        if (this.state.visibilityOption.find(v => v === key)) {
            this.setState({ visibilityOption: [...this.state.visibilityOption.filter(v => v !== key)] })
            this.props.onSetVisibilityOption([...this.state.visibilityOption.filter(v => v !== key)])
        } else {
            this.setState({ visibilityOption: [...this.state.visibilityOption, key] })
            this.props.onSetVisibilityOption([...this.state.visibilityOption, key])
        }
    }

    getGroupByLevelOptions(events) {
        const { cmsModels } = this.props
        const levelOptions = [
            { value: 4, label: i18n.crisis },
            { value: 3, label: i18n.warning },
            { value: 2, label: i18n.information },
            { value: 1, label: i18n.information },
            { value: undefined, label: i18n.other },
        ]
        const groups = groupBy(events, content => {
            if (!isNil(content.modelId)) {
                return cmsModels.find(cm => cm.modelId === content.modelId)?.title || i18n.other
            }
            return getLabel(levelOptions, content.levelContent, null, 'value') || i18n.other
        })
        return keys(groups).reduce((acc, key) => {
            const size = groups[key].length
            const cards = groups[key].map(e => ({
                content: this.getContentRow(e),
                color: hasValue(e.levelContent) ? getNewsColor(e.levelContent) : 'null',
            }))
            return {
                ...acc,
                [key]: {
                    title: `${key} (${size})`,
                    cards,
                    titleColor: key === i18n.other ? 'BLACK' : getNewsColor(groups[key][0].levelContent),
                    level: groups[key][0].levelContent || 0,
                },
            }
        }, {})
    }

    getEventsLength(data) {
        return sumBy(keys(data).map(key => data[key]?.cards.length))
    }

    getArrestRender = arrest => (
        <div>
            <div key={arrest.id} className='collection-item'>
                <div>
                    <div className='row' style={{ marginBottom: '0px' }}>
                        <div className='row no-padding-left padding-top-1 no-margin valign-wrapper'>
                            <div className='col s1'>
                                {[
                                    arrest.typeArrest.split(' ')[0],
                                    <br />,
                                    arrest.typeArrest.split(' ')[1] || '',
                                ]}
                            </div>
                            <div className='col s11'>
                                <div className='valign-wrapper'>
                                    <h6 className='bold'>{arrest.title}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 truncate'>
                            {arrest.comment}
                        </div>
                    </div>
                </div >
                <div className='event-card margin-top-1'>
                    <div className='row no-margin event-footer no-padding'>
                        <div className='left row no-margin valign-wrapper'>
                            <div className='col s12'>
                                <Icon size='small' icon='wifi' />
                            </div>
                        </div>
                        <div className='right row no-margin valign-wrapper' onClick={() => window.open(arrestsPath, '_blank')}>
                            <div className='col s12'>
                                <Icon size='small' icon='info' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    onChangeStatus = () => {
        switch (this.state.statusChange) {
            case 1:
                this.validateCms()
                break
            case 2:
                this.draftCms()
                break
            case 3:
                this.closeCms()
                break
            default:
                this.props.toastrError(i18n.pleaseChooseStatus)
        }
    }

    getModal = () => {
        const { cmsEvents = [], sandreCodes } = this.props
        if (!cmsEvents.length) {
            return (
                <h4 className='center-align padding-top-1 padding-bottom-1'>
                    {i18n.noContentToDisplay}
                </h4>
            )
        }
        const { cms, isOpen } = this.state
        const statusChanges = sandreCodes.filter((c) => c.field === 'CMS.STATUS')
        return (
            <Dialog
                onClose={() => this.setState({ cms: {}, isOpen: false })}
                fullWidth
                maxWidth='md'
                open={isOpen}
            >
                <DialogContent>
                    <AlertCmsModal cms={cms} />
                    <div className='row no-margin valign-wrapper padding-top-2 padding-bottom-1'>
                        <div className='col s2'>
                            {
                                getUser().admin === '1' && (
                                    <a className='waves-effect waves-teal btn-flat' onClick={() => {
                                        setConfirmationModal(() => this.props.deleteCMSEvent(cms, false))
                                        this.setState({ cms: {}, isOpen: false })
                                    }}
                                    >
                                        {i18n.delete}
                                    </a>
                                )
                            }
                        </div>
                        <div className='col s7'>
                            {
                                (getUser().admin === '1' && cms.status === STATUS.DRAFTED) && (
                                    <div className='row no-margin valign-wrapper'>
                                        <a className='padding-right-1 padding-left-1 waves-effect waves-light btn' onClick={() => this.onChangeStatus()}>
                                            {i18n.changeStatusInto}
                                        </a>
                                        <div className='padding-1' />
                                        <Select
                                            onChange={statusChange => this.setState({ statusChange })}
                                            options={statusChanges}
                                            value={this.state.statusChange}
                                            keyValue='code'
                                            keyLabel='name'
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className='col s3'>
                            <a className='padding-right-1 padding-left-1 waves-effect waves-teal btn-flat btn font-white' onClick={() => this.props.push(`/contents/${cms.idCategory}/${cms.id}`)}>
                                {i18n.accessToTheFile}
                            </a>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    getModalAcquittement = () => {
        const { cmsEvents = [] } = this.props
        if (!cmsEvents.length) {
            return (
                <h4 className='center-align padding-top-1 padding-bottom-1'>
                    {i18n.noContentToDisplay}
                </h4>
            )
        }
        const { cms, openAcquittement, category } = this.state
        return (
            <Dialog
                onClose={() => this.setState({ cms: {}, category: {}, openAcquittement: false })}
                fullWidth
                maxWidth='md'
                open={openAcquittement}
            >
                <DialogContent>
                    <h5 className='no-margin padding-bottom-2'>{i18n.doYouWantToAcquitThisEvent}</h5>
                    {this.getCms(category, cms, true)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ cms: {}, category: {}, openAcquittement: false })} variant='outlined' >
                        {i18n.cancel}
                    </Button>
                    <Button onClick={() => {
                        this.props.updateCMSEvent({ ...this.state.cms, acquittementDate: +moment(), acquittementLogin: getLogin() }, false)
                            .then(() => this.setState({ cms: {}, category: {}, openAcquittement: false }))
                    }} variant='contained' color='primary'
                    >
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render = () => {
        const { openCmsStepper, cmsStepper } = this.state
        const { cmsEvents = [] } = this.props
        if (!cmsEvents.length) {
            return (
                <h4 className='center-align padding-top-1 padding-bottom-1'>
                    {i18n.noContentToDisplay}
                </h4>
            )
        }
        const orderedEvents = orderBy(cmsEvents, ['levelContent', 'updateDate'], ['desc', 'desc'])
        const now = +moment()
        const eventPublish = orderedEvents.filter(oe => oe.status === 1 && (!oe.dateFin || oe.dateFin > now))
        const eventDraft = getUser().admin !== '1' ? orderedEvents.filter(oe => oe.status === 2 && oe.author === getUser().login)
            : orderedEvents.filter(oe => oe.status === 2)
        const eventArchived = orderedEvents.filter(oe => oe.status === 3 || oe.dateFin < now)
        const { isOpen, openAcquittement } = this.state
        const published = this.getGroupByLevelOptions(eventPublish)
        const publishedWithArrests = {
            ...published,
            Arrete: {
                title: `${i18n.arrests} (${this.props.arrests.length})`,
                cards: this.props.arrests.map(a => ({ content: this.getArrestRender(a), color: getArrestColor(a.typeArrest) })),
                level: 0,
            },
        }
        const archived = this.getGroupByLevelOptions(eventArchived)
        const drafted = this.getGroupByLevelOptions(eventDraft)
        return (
            <div className='padding-top-1'>
                {
                    this.getEventsLength(drafted) !== 0 && getUser().admin === '1' && (
                        <fieldset style={{ borderRadius: '5px' }}>
                            <legend>&nbsp;{i18n.draft}{this.getEventsLength(drafted) > 1 && 's'}&nbsp;</legend>
                            <GlobalCardList
                                data={drafted}
                                smallTitle
                                sortFunction={(a, b) => drafted[b].level - drafted[a].level}
                            />
                        </fieldset>
                    )
                }
                {
                    this.getEventsLength(published) !== 0 && (
                        <fieldset style={{ borderRadius: '5px' }}>
                            <legend>&nbsp;{i18n.published}{this.getEventsLength(published) > 1 && 's'}&nbsp;</legend>
                            <GlobalCardList
                                data={publishedWithArrests}
                                smallTitle
                                sortFunction={(a, b) => publishedWithArrests[b].level - publishedWithArrests[a].level}
                            />
                        </fieldset>
                    )
                }
                {
                    this.getEventsLength(archived) !== 0 && (
                        <fieldset style={{ borderRadius: '5px' }}>
                            <legend>&nbsp;{i18n.archived}{this.getEventsLength(archived) > 1 && 's'}&nbsp;</legend>
                            <GlobalCardList
                                data={archived}
                                smallTitle
                                sortFunction={(a, b) => archived[b].level - archived[a].level}
                            />
                        </fieldset>
                    )
                }
                {this.getEventsLength(drafted) === 0 && this.getEventsLength(archived) === 0 && this.getEventsLength(published) === 0 &&
                    <PanelList title={<h5 className='no-margin'>{i18n.noEvents}</h5>}>{null}</PanelList>
                }
                {isOpen && this.getModal()}
                {openAcquittement && this.getModalAcquittement()}
                <AlertNewCmsStepper
                    isOpen={openCmsStepper}
                    cmsToOpen={cmsStepper}
                    setIsOpen={v => this.setState({ openCmsStepper: v })}
                />
            </div>
        )
    }
}

EventsMapTabs.propTypes = {
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
    onHover: PropTypes.func,
    onSetVisibilityOption: PropTypes.func,
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
    arrests: PropTypes.arrayOf(PropTypes.instanceOf(DtoArrest)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    push: PropTypes.func,
    deleteCMSEvent: PropTypes.func,
    updateCMSEvent: PropTypes.func,
    toastrError: PropTypes.func,
    cmsModels: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsModel)),
}

const mapStateToProps = store => ({
    cmsCategories: store.EventsReducer.cmsCategories,
    cmsModels: store.EventsReducer.cmsModels,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    push,
    deleteCMSEvent: CmsAction.deleteCMSEvent,
    updateCMSEvent: CmsAction.updateCMSEvent,
    toastrError: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsMapTabs)
