import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { styled } from '@mui/styles'

export const DialogMUI = styled(({ PaperProps: { sx, ...othenPaperProps } = {}, ...other }) => (
    <Dialog
        fullWidth
        maxWidth='lg'
        PaperProps={{
            sx: {
                minHeight: '90vh',
                maxHeight: '90vh',
                ...sx,
            },
            ...othenPaperProps,
        }}
        {...other}
    />
))({})

export const DialogTitleMUI = styled(({ ...other }) => (
    <DialogTitle
        {...other}
    />))(({ theme }) => ({
    backgroundColor: theme.palette.primary.grey,
    color: 'white',
    borderBottom: 'solid 1px grey',
    fontSize: '20px',
    lineHeight: '20px',
}))

export const DialogContentMUI = styled(({ ...other }) => (
    <DialogContent
        {...other}
    />))({
    backgroundColor: '#f0f0f0',
})

export const DialogActionsMUI = styled(({ ...other }) => (
    <DialogActions
        {...other}
    />))({
    borderTop: 'solid 1px grey',
})