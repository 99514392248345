import loading from 'assets/pictures/loading.gif'
import { push } from 'connected-react-router'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../components/card/Card'
import Table from '../../components/datatable/Table'
import CampaignPortletPanel from '../../campaign/components/CampaignPortletPanel'
import MonitoredMaterielPortletPanel from '../../materiel/components/dashboard/MonitoredMaterielPortletPanel'
import ProgressCard from '../../components/card/ProgressCard'
import { getUserBookmarksByStationType } from '../../utils/UserUtil'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_AGRI_MODULE,
    H_MAT_MODULE,
} from '../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../utils/components/HabilitationRequired'
import Icon from 'components/icon/Icon'
import UserThunk from 'administration/components/user/actions/UserThunk'
import useStateProgress from 'utils/customHook/useStateProgress'
import AgriThunk from 'agriAdministration/actions/AgriThunk'
import CurrentSurveysPanel from 'survey/components/panels/CurrentSurveysPanel'

const agriStationType = 10

const AgriCard = () => {
    const {
        userBookmarks,
        exploitationsLight,
        monitoredMateriels,
        userBookmarksStatus,
        exploitationsLightStatus,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        exploitationsLight: store.AgriReducer.exploitationsLight,
        monitoredMateriels: store.MaterielReducer.monitoredMateriels,
        userBookmarksStatus: store.DataManagerReducer.user.userBookmarksStatus,
        exploitationsLightStatus: store.DataManagerReducer.agri.exploitationsLightStatus,
    }), shallowEqual)

    const { progress: progressBar, isLoaded: dataLoaded } = useStateProgress([userBookmarksStatus, exploitationsLightStatus])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(UserThunk.fetchBookmarks())
        dispatch(AgriThunk.fetchExploitationsLight())
    }, [])

    const bookmarks = useMemo(() => getUserBookmarksByStationType(userBookmarks, 'agriculture'), [userBookmarks])

    const data = useMemo(() => ({
        stations: [],
        exploitations: exploitationsLight,
        bookmarks,
        icon: 'folder',
        campaignPanel: null,
        campaigns: [],
        stationType: agriStationType,
        habilitation: componentHasHabilitations(H_AGRI_MODULE),
        redirect: 'dossiers',
        monitoredMateriels: monitoredMateriels.filter(m => m.typeSite === agriStationType),
    }), [bookmarks, exploitationsLight, monitoredMateriels])

    const redirect = (href = '') => dispatch(push(href))

    const agriPanel = useMemo(() => {
        if (exploitationsLight?.length > 0) {
            const exploitations = exploitationsLight.filter((e) => data.bookmarks.includes(String(e.idExploitation)))
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row margin-bottom-1 '>
                            <CurrentSurveysPanel />
                        </div>
                        {exploitations?.length ? (
                            <div className='row margin-bottom-1 '>
                                <Table
                                    data={exploitations}
                                    type={{ headers: ['codification', 'name', 'inseeCode', 'city', 'address'] }}
                                    onClick={(e) => redirect(`/dossiers/${e.idExploitation}/dashboard`)}
                                    sortable
                                    headerIcon={(
                                        <a onClick={() => redirect('/dossiers')}>
                                            <Icon>folder</Icon>
                                        </a>
                                    )}
                                    condensed
                                    showIcon
                                    showTitle={false}
                                />
                            </div>
                        ) : ''}
                    </div>
                </div>
            )
        }
        return null
    }, [data.bookmarks, exploitationsLight])

    const campaignsPanel = useMemo(() => {
        if (data.campaigns && data.campaigns.length > 0 && data.campaignHeader) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            <CampaignPortletPanel
                                noHeader={true}
                                campaigns={data.campaigns}
                                stationType={'agriculture'}
                                tableHeader={data.campaignHeader}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [data.campaignHeader, data.campaigns])

    if (dataLoaded) {
        if (!(data.habilitation && ((data.bookmarks.length > 0 && data.stations.length > 0) || data.campaigns.length > 0 || data.monitoredMateriels.length > 0 || data?.exploitations?.length > 0)) || !(campaignsPanel || agriPanel)) {
            return null
        }
        return (
            <div className='row no-margin padding-top-1'>
                <Card
                    title={(
                        <a className='waves-effect' onClick={() => redirect(`/${data.redirect || 'agriculture'}`)}>
                            <i className='material-icons '>{data.icon}</i>
                            {i18n.agriculture}
                        </a>
                    )}
                >
                    <div className='padding-1 padding-bottom-0'>
                        {campaignsPanel}
                        {agriPanel}
                        <HabilitationRequired habilitation={H_MAT_MODULE}>
                            <MonitoredMaterielPortletPanel noHeader={true} stationType={data.stationType} />
                        </HabilitationRequired>
                    </div>
                </Card>
            </div>
        )
    }
    return (
        <div className='padding-top-1'>
            <Card>
                <div className='row no-margin center-align padding-top-5'>
                    <img src={loading} />
                    <ProgressCard withoutCard message={i18n.agriCardInProgress} progress={progressBar} />
                </div>
            </Card>
        </div>
    )
}

export default AgriCard