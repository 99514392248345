import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { PATH_INSTALLATION, PATH_INSTALLATION_TERRITORYACTIVITIES } from '../../../../home/constants/RouteConstants'
import TerritoryTableChartsMap from './TerritoryTableChartsMap'
import InstallationAction from '../../../actions/InstallationAction'
import { exportFile, formatData } from '../../../../utils/ExportDataUtil'
import { getObjectLabel } from '../../../../utils/StoreUtils'
import ParameterAction from '../../../../referencial/components/parameter/actions/ParameterAction'
import UnitAction from '../../../../referencial/components/unit/actions/UnitAction'
import { round } from 'lodash'
import { getDate } from '../../../../utils/DateUtil'
import { formatMilliers } from '../../../../utils/StringUtil'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_INSTALLATION_TERRITORY_ACTIVITIES } from '../../../../account/constants/AccessRulesConstants'
import { push } from 'connected-react-router'
import ExportAction from 'export/actions/ExportAction'
import { WhiteCard } from 'components/styled/Card'
import { Grid } from '@mui/material'
import Table from 'components/datatable/Table'
import ExportFileModal from 'components/modal/ExportFileModal'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import useTitle from 'utils/customHook/useTitle'

const headerActivity = ['section', 'activityCode', 'activity', 'numberInstitutions']
const headerDistributionEtablishments = ['section', 'libelle', 'numberInstitutions']
const headerPollutants = ['code', 'name', 'nbActivities', 'nbInstitutions']
const headerPollutantsEmissions = ['source', 'category', 'section', 'activityCode', 'activity', 'parameterCode', 'parameter', 'nbMeasurementsCouple', 'medianDailyFlows', 'standardDeviationDailyFlows', 'nbMeasurementsCoupleWithNbEmployees', 'slopeRegression', 'constantRegression', 'rSquare']
const headerConventions = ['siret', 'name', 'analysisDate', 'result', 'parameterCode', 'parameter', 'unit', 'comment', 'STEP']
const headerEstablishmentDischarges = ['category', 'city', 'name', 'identifier', 'siret', 'cityCode', 'activityCode', 'activity', 'dischargeAgreement', 'effective', 'icpe', 'x', 'y', 'idStation', 'step']
const TerritoryActivities = ({

}) => {
    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.installation,
        href: PATH_INSTALLATION,
    }, {
        title: i18n.territoryActivities,
        href: PATH_INSTALLATION_TERRITORYACTIVITIES,
    }]
    , [])

    const {
        territoryActivities,
        pollutants,
        pollutantsEmissions,
        distributionEstablishmentsActivitySection,
        conventionsAnalysis,
        parametersIndex,
        territoryDiagnosis,
        establishmentDischarges,
    } = useSelector(store => ({
        territoryActivities: store.InstallationReducer.territoryActivities,
        pollutants: store.InstallationReducer.pollutants,
        pollutantsEmissions: store.InstallationReducer.pollutantsEmissions,
        distributionEstablishmentsActivitySection: store.InstallationReducer.distributionEstablishmentsActivitySection,
        conventionsAnalysis: store.InstallationReducer.conventionsAnalysis,
        parametersIndex: store.ParameterReducer.parametersIndex,
        territoryDiagnosis: store.InstallationReducer.territoryDiagnosis,
        establishmentDischarges: store.InstallationReducer.establishmentDischarges,
    }))

    const conventions = useMemo(() => conventionsAnalysis.map(c => ({
        ...c,
        analysisDate: getDate(c.analysisDate),
        result: round(c.result),
        parameter: getObjectLabel(parametersIndex[c.parameterCode]),
        parameterCode: c.parameterCode,
        unit: getObjectLabel(parametersIndex[c.unitCode], 'symbolWithName'),
        comment: c.analysisComment,
        STEP: c.stepName,
    })), [conventionsAnalysis])

    useEffect(() => {
        if (!componentHasHabilitations(H_INSTALLATION_TERRITORY_ACTIVITIES)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
            return
        }
        dispatch(ParameterAction.fetchParameters())
        dispatch(UnitAction.fetchUnits())
        dispatch(InstallationAction.fetchTerritoryActivities())
        dispatch(InstallationAction.fetchTerritoryDiagnosis())
        dispatch(InstallationAction.fetchTerritoryPollutants())
        dispatch(InstallationAction.fetchTerritoryPollutantsEmissions())
        dispatch(InstallationAction.fetchDistributionEstablishmentsActivitySection())
        dispatch(InstallationAction.fetchTerritoryConventionsAnalysis())
        if (!establishmentDischarges.length) {
            dispatch(InstallationAction.fetchEstablishmentDischarges())
        }
    }, [])

    const getDataEchartsDistribution = () => {
        return distributionEstablishmentsActivitySection.map((de) => ({
            value: de.numberInstitutions,
            name: `Section ${de.section}, ${de.libelle}`,
        }))
    }

    const getExportData = (tableData, headers) => {
        const [head, ...tail] = tableData
        return tableData.length ? [{ ...head, headers }, ...tail] : [{ headers }]
    }

    const getClassicExport = (data, headers, title) => {
        return {
            name: i18n.export,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => dispatch(ExportAction.export(formatData(getExportData(data, headers)), 'xlsx', title)),
            }, {
                type: i18n.csv,
                callback: () => dispatch(ExportAction.export(formatData(getExportData(data, headers)), 'csv', title)),
            }],
        }
    }

    const getExportTerritoryActivities = (type) => {
        dispatch(InstallationAction.fetchSpecificExportTerritoryActivities()).then((specificExportTerritoryActivities) => {
            const headers = ['category', 'city', 'establishment', 'id', 'siret', 'cityCode', 'activityCode', 'activity', 'dischargeAgreementLabel', 'effectiveTranche', 'icpe', 'x', 'y', 'idStation', 'step']
            dispatch(ExportAction.export(formatData(getExportData(specificExportTerritoryActivities.map(d => ({
                ...d,
                dischargeAgreementLabel: d.dischargeAgreementLabel ? i18n.yes : i18n.no,
                icpe: d.icpe ? i18n.yes : i18n.no,
            })), headers)), type, i18n.exportTerritoryActivities))
        })
    }

    const exportActivities = () => {
        return [getClassicExport(territoryActivities, headerActivity, i18n.activities), {
            name: i18n.exportAdditionalData,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => getExportTerritoryActivities('xlsx'),
            }, {
                type: i18n.csv,
                callback: () => getExportTerritoryActivities('csv'),
            }],
        }]
    }

    const exportTerritoryPolutants = () => {
        return [ {
            name: i18n.export,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => dispatch(ExportAction.export(formatData(getExportData(pollutants, headerPollutants)), 'xlsx', i18n.territoryPollutants)),
            }, {
                type: i18n.csv,
                callback: () => dispatch(ExportAction.export(formatData(getExportData(pollutants, headerPollutants)), 'csv', i18n.territoryPollutants)),
            }],
        }]
    }

    const getExportPollutantsEmissions = (type) => {
        dispatch(InstallationAction.fetchSpecificExportPollutantsEmissions()).then((specificExportPollutantsEmissions) => {
            const headers = ['categoryActivity', 'dataSource', 'activityCode', 'nbInstitutions', 'parameterCode', 'name', 'flowKgDayCompany', 'flowKgDayEmployee', 'nbEmployees', 'totalFlowsByEmployee', 'totalFlowsByCompanies', 'totalEmissionsByEstablishments']
            dispatch(ExportAction.export(formatData(this.getExportData(specificExportPollutantsEmissions, headers)), type, 'ExportTerritoireEmmissionsPolluants'))
        })
    }

    const exportPollutantsEmissions = () => {
        return [getClassicExport(pollutantsEmissions, headerPollutantsEmissions, i18n.territoryPollutantsEmissions), {
            name: i18n.exportAdditionalData,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => getExportPollutantsEmissions('xlsx'),
            }, {
                type: i18n.csv,
                callback: () => getExportPollutantsEmissions('csv'),
            }],
        }]
    }

    const exportConventionsAnalysis = () => {
        const headers = ['siret', 'name', 'analysisDate', 'result', 'parameter', 'label', 'unit', 'comment', 'STEP']
        const data = conventions.map(c => ({
            siret: { value: c.siret },
            name: { value: c.name },
            analysisDate: { value: c.analysisDate, format: 'dd/MM/yyyy', cellType: 'date' },
            result: { value: c.result, format: '0.00', cellType: 'number' },
            parameter: getObjectLabel(parametersIndex[c.parameterCode], 'labelWithCode'),
            label: { value: c.label },
            unit: { value: getObjectLabel(parametersIndex[c.unitCode], 'symbolWithName') },
            comment: { value: c.analysisComment },
            STEP: { value: c.stepName },
        }))

        return [getClassicExport(data, headers, 'ExportConventions')]
    }

    const exportEstablishmentDischarges = () => {
        return [getClassicExport(establishmentDischarges, headerEstablishmentDischarges, i18n.establishmentsTerritoryConnectionWastewaterNetworks)]
    }

    const exportDistribution = () => {
        return [getClassicExport(distributionEstablishmentsActivitySection, headerDistributionEtablishments, i18n.distributionEstablishmentsActivitySection)]
    }

    const getDiagnosisExport = () => {
        exportFile({
            data: getExportData(territoryDiagnosis, ['stat', 'value']),
            titleFile: 'ExportDiagnosticTerritoire',
        })
    }

    const [openModal, setOpenModal] = useState(false)
    const [exportData, setExportData] = useState([])

    return (
        <Grid style={{ padding: '10px 10px 100px 10px' }}>
            <Grid container justifyContent='space-between' spacing={2}>
                <Grid item xs={6}>
                    <TerritoryTableChartsMap
                        title={i18n.distributionEstablishmentsActivitySection}
                        tableData={distributionEstablishmentsActivitySection}
                        chartData={getDataEchartsDistribution()}
                        headers={headerDistributionEtablishments}
                        displayType={1}
                        idDisplay='repartEtab'
                        exportData={exportDistribution()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <WhiteCard
                        title={i18n.territoryDiagnosis}
                        actions={[{
                            iconName: 'file_download',
                            tooltip: i18n.export,
                            color: 'black',
                            onClick: () => getDiagnosisExport(),
                        }]}
                    >
                        <Grid
                            container
                            direction='column'
                            spacing={2}
                            p={2}
                        >
                            {
                                territoryDiagnosis.map(td => {
                                    return (
                                        <Grid container item style={{ fontSize: '14.3px' }} key={td.stat}>
                                            <Grid item xs={4} className='bold'>{td.stat}</Grid>
                                            <Grid item >{formatMilliers(td.value)}</Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </WhiteCard>
                </Grid>
            </Grid>
            <Grid style={{ marginTop: 10 }}>
                <Table
                    title={i18n.activities}
                    data={territoryActivities}
                    type={{ headers: headerActivity }}
                    actions={[{
                        iconName: 'file_download',
                        tooltip: i18n.export,
                        color: 'black',
                        onClick: () => {
                            setExportData(exportActivities())
                            setOpenModal(true)
                        },
                    }]}
                    condensed
                    sortable
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    invertedHeaderStyle
                />
            </Grid>
            <Grid style={{ marginTop: 10 }}>
                <Table
                    title={i18n.territoryPollutants}
                    data={pollutants}
                    type={{ headers: headerPollutants }}
                    customHeaders={{
                        name: i18n.parameter,
                    }}
                    actions={[{
                        iconName: 'file_download',
                        tooltip: i18n.export,
                        color: 'black',
                        onClick: () => {
                            setExportData(exportTerritoryPolutants())
                            setOpenModal(true)
                        },
                    }]}
                    condensed
                    sortable
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    invertedHeaderStyle
                />
            </Grid>
            <Grid style={{ marginTop: 10 }}>
                <Table
                    title={i18n.territoryPollutantsEmissions}
                    data={pollutantsEmissions}
                    type={{ headers: headerPollutantsEmissions }}
                    customHeaders={{
                        parameterCode: (<span>Code<br />paramètre</span>),
                        nbMeasurementsCouple: (<span>Nb mesures<br />sur le couple étudié</span>),
                        medianDailyFlows: (<span>Médiane des flux journaliers<br />observés en kg/i/entreprise</span>),
                        standardDeviationDailyFlows: (<span>Écart type des flux journaliers<br />observés en kg/i/entreprise</span>),
                        nbMeasurementsCoupleWithNbEmployees: (<span>Nb mesures sur le couple étudié<br />avec données sur nb employes</span>),
                        slopeRegression: (<span>Constante de la<br />régression linéaire</span>),
                        constantRegression: (<span>pente de la régression<br />kg/i/employes</span>),
                        rSquare: (<span>Constante de la régression<br />linéaire R²</span>),
                    }}
                    actions={[{
                        iconName: 'file_download',
                        tooltip: i18n.export,
                        color: 'black',
                        onClick: () => {
                            setExportData(exportPollutantsEmissions())
                            setOpenModal(true)
                        },
                    }]}
                    condensed
                    sortable
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    invertedHeaderStyle
                />
            </Grid>
            <Grid style={{ marginTop: 10 }}>
                <Table
                    title={i18n.agreements}
                    data={conventions}
                    type={{ headers: headerConventions }}
                    customHeaders={{
                        analysisDate: i18n.date,
                    }}
                    actions={[{
                        iconName: 'file_download',
                        tooltip: i18n.export,
                        color: 'black',
                        onClick: () => {
                            setExportData(exportConventionsAnalysis())
                            setOpenModal(true)
                        },
                    }]}
                    condensed
                    sortable
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    invertedHeaderStyle
                />
            </Grid>
            <Grid style={{ marginTop: 10 }}>
                <Table
                    title={i18n.establishmentsTerritoryConnectionWastewaterNetworks}
                    data={establishmentDischarges}
                    type={{ headers: headerEstablishmentDischarges }}
                    actions={[{
                        iconName: 'file_download',
                        tooltip: i18n.export,
                        color: 'black',
                        onClick: () => {
                            setExportData(exportEstablishmentDischarges())
                            setOpenModal(true)
                        },
                    }]}
                    condensed
                    sortable
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    invertedHeaderStyle
                />
            </Grid>
            <ExportFileModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                data={exportData}
            />
        </Grid>
    )
}

TerritoryActivities.propTypes = {
}

export default TerritoryActivities
