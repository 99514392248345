import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { H_PLUVIO_OPTIONS } from '../../../account/constants/AccessRulesConstants'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import HomeAction from '../../../home/actions/HomeAction'
import StationAction from '../../../station/actions/StationAction'
import DtoDataType from '../../../station/dto/DtoDataType'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getSetting, getSettingInt } from '../../../utils/SettingUtils'
import AdministrationAction from '../../actions/AdministrationAction'
import SieauParameterDto from '../../dto/SieauParameterDto'
import DataTypesTable from '../dataTypes/DataTypesTable'
import ShowAssociatedDataRuleTable from '../dataTypes/ShowAssociatedDataRuleTable'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import { arrayOf } from '../../../utils/StoreUtils'
import Select from '../../../components/forms/Select'

class AdminPluviometerApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            readMode: true,
            dataLoaded: false,
            project: 'SIP',
            applicationSettings: props.applicationSettings,
            validationDefaultProducerPluvio: getSettingInt(props.applicationSettings, 'validationDefaultProducerPluvio'),
            validationDefaultManagerPluvio: getSettingInt(props.applicationSettings, 'validationDefaultManagerPluvio'),
            validationDefaultValidatorPluvio: getSettingInt(props.applicationSettings, 'validationDefaultValidatorPluvio'),
            showDataTypesRules: getSetting(props.applicationSettings, 'showDataTypesRules'),
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_PLUVIO_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        this.setReadMode()
        this.props.setTitle([{
            title: i18n.pluviometry,
            href: 'pluviometry',
        }, {
            title: i18n.pluviometryOptions,
            href: 'pluviometry/pluviometryOptions',
        }])
        this.setDataTypes()
    }

    setDataTypes = () => {
        const { project } = this.state
        this.props.fetchDataTypesByProject(project).then(() => {
            const { filteredDataTypes } = this.props
            this.setState({ dataLoaded: true, dataTypes: filteredDataTypes })
        })
    }

    setReadMode = () => {
        this.setState({ readMode: true })
        this.setActions({
            edit: () => this.setEditMode(),
        })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            save: () => this.onSave(),
            cancel: () => this.onCancel(),
        })
    }

    onCancel = () => {
        const { filteredDataTypes, applicationSettings } = this.props
        this.setState({ dataTypes: filteredDataTypes, applicationSettings })
        this.setReadMode()
    }

    onSave = () => {
        const { dataTypes, project } = this.state
        this.setState({ dataLoaded: false })
        this.props.updateDataTypesByProject(project, dataTypes).then(() => {
            this.setDataTypes()
        })

        const { applicationSettings, validationDefaultProducerPluvio, validationDefaultManagerPluvio, validationDefaultValidatorPluvio, showDataTypesRules } = this.state

        const newSetting = [
            { parameter: 'validationDefaultProducerPluvio', value: validationDefaultProducerPluvio ? validationDefaultProducerPluvio.toString() : validationDefaultProducerPluvio },
            { parameter: 'validationDefaultManagerPluvio', value: validationDefaultManagerPluvio ? validationDefaultManagerPluvio.toString() : validationDefaultManagerPluvio },
            { parameter: 'validationDefaultValidatorPluvio', value: validationDefaultValidatorPluvio ? validationDefaultValidatorPluvio.toString() : validationDefaultValidatorPluvio },
            { parameter: 'showDataTypesRules', value: showDataTypesRules }
        ].filter(p => getSetting(applicationSettings, p.parameter) !== p.value)

        if (applicationSettings.length) {
            this.props.updateSieauParameters(newSetting)
        }
        this.setReadMode()
    }

    onChangeSetting = (setting, value) => {
        const stateObj = this.state.applicationSettings.filter(e => e.parameter !== setting)
        this.setState({ applicationSettings: [...stateObj, { parameter: setting, value: value ? value.toString() : value }] })
    }

    render() {
        const { readMode, dataLoaded, dataTypes, project } = this.state

        return (
            <div className='padding-1'>
                <Card title={ i18n.pluviometryOptions }>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        <h5>{ i18n.validationScreenDefaultValues }</h5>
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <Select
                            disabled={readMode}
                            col={ 4 }
                            label={ i18n.producer }
                            options={ this.props.contributors }
                            value={ this.state.validationDefaultProducerPluvio }
                            onChange={ v => this.setState({ validationDefaultProducerPluvio: v }) }
                            keyValue='id'
                            keyLabel='mnemonique'
                        />
                        <Select
                            disabled={readMode}
                            col={ 4 }
                            label={ i18n.administrator }
                            options={ this.props.contributors }
                            value={ this.state.validationDefaultManagerPluvio }
                            onChange={ v => this.setState({ validationDefaultManagerPluvio: v }) }
                            keyValue='id'
                            keyLabel='mnemonique'
                        />
                        <Select
                            disabled={readMode}
                            col={ 4 }
                            label={ i18n.validator }
                            options={ this.props.contributors }
                            value={ this.state.validationDefaultValidatorPluvio }
                            onChange={ v => this.setState({ validationDefaultValidatorPluvio: v }) }
                            keyValue='id'
                            keyLabel='mnemonique'
                        />
                    </div>
                </Card>
                {dataLoaded && (
                    <div className='padding-top-1'>
                        <DataTypesTable
                            project={project}
                            dataTypes={dataTypes}
                            readMode={readMode}
                            onChange={(data) => this.setState({ dataTypes: data })}
                        />
                    </div>
                )}
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={[{ parameter: 'showDataTypesRules', value: this.state.showDataTypesRules }]}
                        fromStationType='PLUVIO'
                        toStationType='PIEZO'
                        onChangeRules={ v => this.setState({ showDataTypesRules: v }) }
                        readMode={readMode}
                    />
                )}
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={[{ parameter: 'showDataTypesRules', value: this.state.showDataTypesRules }]}
                        fromStationType='PLUVIO'
                        toStationType='HYDRO'
                        onChangeRules={ v => this.setState({ showDataTypesRules: v }) }
                        readMode={readMode}
                    />
                )}
                <div className='padding-top-9' />
            </div>
        )
    }
}

AdminPluviometerApp.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    filteredDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDataType)),
    setTitle: PropTypes.func,
    contributors: arrayOf(ContributorDto),
    updateDataTypesByProject: PropTypes.func,
    fetchDataTypesByProject: PropTypes.func,
    push: PropTypes.func,
    updateSieauParameters: PropTypes.func,
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    contributors: store.ContributorReducer.contributors,
    filteredDataTypes: store.StationReducer.filteredDataTypes,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchContributors: ContributorAction.fetchContributors,
    updateDataTypesByProject: StationAction.updateDataTypesByProject,
    fetchDataTypesByProject: StationAction.fetchDataTypesByProject,
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPluviometerApp)
