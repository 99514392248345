import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import Table from 'components/datatable/Table'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import { hasValue } from 'utils/NumberUtil'
import { getLabel, getSandreList } from 'utils/StoreUtils'
import { getSandreLabel, substringText } from 'utils/StringUtil'

const CatchmentSDAGETable = ({
    sdages = [],
    setCatchment = () => { },
    readMode = true,
}) => {
    const {
        contributors,
        catchment,
        sandreCodes,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        catchment: store.CatchmentReducer.catchment,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [codeSDAGE, setCodeSDAGE] = useState(0)
    const [comment, setComment] = useState()
    const [contributor, setContributor] = useState()
    const [index, setIndex] = useState()

    const sdageList = getSandreList(sandreCodes, 'CAPTAGES.SDAGE')

    const sdagesFormated = orderBy(sdages.map((sdage, i) => {
        const sdageLabel = getSandreLabel(sandreCodes, 'CAPTAGES.SDAGE', sdage.codeSDAGE)
        return {
            sdage: sdageLabel,
            startDate: getDate(sdage.startDate),
            endDate: getDate(sdage.endDate),
            contributor: getLabel(contributors, sdage.contributor),
            comment: substringText(sdage.comment, 50),
            startDateTimestamp: sdage.startDate,
            endDateTimestamp: sdage.endDate,
            codeSDAGE: sdage.codeSDAGE,
            commentFull: sdage.comment,
            contributorCode: sdage.contributor,
            index: i,
        }
    }), 'startDateTimestamp', 'desc')

    const exportAction = {
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: () => {
            const exportDataFormated = orderBy(sdages, 'startDate', 'desc').map(sdage => {
                const sdageLabel = getSandreLabel(sandreCodes, 'CAPTAGES.SDAGE', sdage.codeSDAGE)
                return {
                    sdage: sdageLabel,
                    startDate: { value: getDate(sdage.startDate), format: 'dd/MM/yyyy', cellType: 'date' },
                    endDate: { value: getDate(sdage.endDate), format: 'dd/MM/yyyy', cellType: 'date' },
                    contributor: getLabel(contributors, sdage.contributor),
                    comment: sdage.comment,
                }
            })
            const exportData = exportDataFormated.length ? [{ ...exportDataFormated[0], headers: ['sdage', 'startDate', 'endDate', 'contributor', 'comment'] }, ...exportDataFormated.slice(1)] : []
            exportFile({
                data: exportData,
                exportType: 'xlsx',
                titleFile: `${catchment.code || catchment.name || catchment.aacNum || ''}_${i18n.sdage}`,
            })
        },
    }

    const actions = !readMode ? [exportAction, {
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setIsOpen(true),
        'data-cy': 'new_sdage',
    }] : [exportAction]

    const onClose = () => {
        setIsOpen(false)
        setStartDate(undefined)
        setEndDate(undefined)
        setCodeSDAGE(0)
        setComment(undefined)
        setContributor(undefined)
        setIndex(undefined)
    }

    const onValidate = () => {
        const newSdage = {
            startDate,
            endDate,
            codeSDAGE,
            comment,
            contributor,
        }
        if (hasValue(index)) {
            setCatchment(prev => ({ ...prev, sdages: [...sdages.slice(0, index), newSdage, ...sdages.slice(index + 1)] }))
        } else {
            setCatchment(prev => ({ ...prev, sdages: [...sdages, newSdage] }))
        }
        onClose()
    }

    return (
        <>
            <Table
                title={i18n.sdage}
                data={sdagesFormated}
                paging
                actions={actions}
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers: ['sdage', 'startDate', 'endDate', 'contributor', 'comment'] }}
                alterable={!readMode}
                onAlter={s => {
                    setStartDate(s.startDateTimestamp)
                    setEndDate(s.endDateTimestamp)
                    setCodeSDAGE(s.codeSDAGE)
                    setComment(s.commentFull)
                    setContributor(s.contributorCode)
                    setIndex(s.index)
                    setIsOpen(true)
                }}
                deletable={!readMode}
                onDelete={s => setCatchment(prev => ({ ...prev, sdages: [...sdages.slice(0, s.index), ...sdages.slice(s.index + 1)] }))}
                condensed
                sortable
                id='catchment_sdages_table'
            />
            <Dialog
                fullWidth
                maxWidth='lg'
                onClose={onClose}
                open={isOpen}
            >
                <DialogTitle>{i18n.sdage}</DialogTitle>
                <DialogContent>
                    <div className='row no-margin padding-top-1' style={{ height: '25vh' }}>
                        <div className='col s12'>
                            <Select
                                col={3}
                                options={sdageList}
                                label={i18n.sdage}
                                value={codeSDAGE}
                                noSort
                                onChange={setCodeSDAGE}
                                noNullValue
                                data-cy='codeSDAGE'
                            />
                            <SimpleDatePicker
                                value={startDate}
                                id='startDate'
                                label={i18n.beginDate}
                                onChange={setStartDate}
                                col={3}
                                data-cy='startDate'
                            />
                            <SimpleDatePicker
                                value={endDate}
                                id='endDate'
                                label={i18n.endDate}
                                onChange={setEndDate}
                                col={3}
                                data-cy='endDate'
                            />
                            <Select
                                col={3}
                                options={contributors}
                                label={i18n.contributor}
                                nullLabel='&nbsp;'
                                onChange={setContributor}
                                value={contributor}
                                data-cy='contributor'
                            />
                        </div>
                        <div className='col s12 padding-top-1'>
                            <Textarea
                                col={12}
                                title={i18n.comment}
                                value={comment}
                                onChange={setComment}
                                data-cy='sdage_comment'
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant='outlined' >
                        {i18n.cancel}
                    </Button>
                    <Button onClick={onValidate} variant='contained' data-cy='validate_sdage'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

CatchmentSDAGETable.propTypes = {
    sdages: PropTypes.arrayOf(PropTypes.shape({})),
    setCatchment: PropTypes.func,
    readMode: PropTypes.bool,
}

export default CatchmentSDAGETable