import AEPOverview from 'quality/components/AEPoverview/AEPOverview'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'

const DistributionUnitAEPApp = ({
    id,
}) => {
    const {
        distributionUnit,
        associatedSites,
    } = useSelector(store => ({
        distributionUnit: store.DistributionUnitReducer.distributionUnit,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.distributionUnit,
        href: 'distributionUnit',
    }, {
        title: getStationTitle(distributionUnit),
        href: `station/distributionUnit/${id}`,
    }, {
        title: i18n.AEPoverview,
        href: `station/distributionUnit/${id}/conformityOverview`,
    }], [])

    const ids = useMemo(() => associatedSites.filter(site => site.stationLinkedType === 3).map(site => site.stationLinkedId), [associatedSites])

    return (
        <AEPOverview
            ids={ids}
        />
    )
}

DistributionUnitAEPApp.propTypes = {
    id: PropTypes.string,
}

export default DistributionUnitAEPApp