import { push } from 'connected-react-router'
import DtoInstallationLight from 'installation/dto/installation/DtoInstallationLight'
import { groupBy, orderBy, take } from 'lodash'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import CentralTypeDto from '../../../materiel/components/central/dto/CentralTypeDto'
import EquipmentTypeDto from '../../../materiel/components/equipment/dto/EquipmentTypeDto'
import PowerSupplyTypeDto from '../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import DtoSensorType from '../../../materiel/components/sensor/dto/DtoSensorType'
import DtoSimType from '../../../materiel/components/sim/dto/DtoSimType'
import TelecomTypeDto from '../../../materiel/components/telecom/dto/TelecomTypeDto'
import DtoVariousMaterielType from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from '../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import DtoPiezometerContributorLink from '../../../piezometry/dto/DtoPiezometerContributorLink'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import TaxonDto from '../../../referencial/components/taxon/dto/TaxonDto'
import { REFERENCIAL_LABELS } from '../../../referencial/constants/ReferencialConstants'
import { STATIONS_TYPE_NAME, STATION_TYPE, STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import DtoCentral from '../../../station/dto/materiel/DtoCentral'
import DtoEquipment from '../../../station/dto/materiel/DtoEquipment'
import DtoPowerSupply from '../../../station/dto/materiel/DtoPowerSupply'
import DtoSensor from '../../../station/dto/materiel/DtoSensor'
import DtoSim from '../../../station/dto/materiel/DtoSim'
import DtoTelecom from '../../../station/dto/materiel/DtoTelecom'
import { getUser } from '../../../utils/SettingUtils'
import { findStationType } from '../../../utils/StationUtils'
import {
    arrayOf,
    getLabel,
    getMapStateToProps,
    getObjectLabel,
    getPropTypes,
} from '../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../utils/StringUtil'
import HomeAction from '../../actions/HomeAction'

const storeProps = {
    qualitometers: false,
    hydrometricStations: false,
    installations: false,
    productionUnits: false,
    distributionUnits: false,
    cmsEvents: false,
    cmsCategories: false,
    contacts: false,
    networks: false,
    fractions: false,
    contributors: false,
    watermasses: false,
    methods: false,
    parameters: false,
    supports: false,
    units: false,
    hydrogeologicalEntities: false,
    watersheds: false,
    users: false,
}

class NotifsGlobalResearchComponent extends Component {
    constructor(props) {
        super(props)
        this.state = { searchValue: null, dataLoaded: false, results: [], clicked: false, result: [] }
    }

    loadData = () => {
        AppStore.dispatch(HomeAction.loadGlobalResearchStations(() => {
            this.setState({ dataLoaded: true, results: [ ...this.state.results, ...this.getStationsHash()] })
        }))
        AppStore.dispatch(HomeAction.loadGlobalResearchReferentials(() => {
            this.setState({ dataLoaded: true, results: [ ...this.state.results, ...this.getReferentialsHash()] })
        }))
        AppStore.dispatch(HomeAction.loadGlobalResearchOther(() => {
            this.setState({ dataLoaded: true, results: [ ...this.state.results, ...this.getOthersHash()] })
        }))
    }

    onEnterSearchValue = (v) => {
        this.setState({ searchValue: v })
        if (v.length >= 3) {
            if (v) {
                $('.global-search-field').dropdown('open')
            }
        } else {
            $('.global-search-field').dropdown('close')
        }
    }

    getStationLabel = (station, town, internCodes = []) => {
        if (station.typeName === STATION_TYPE_NAME.installation) {
            return `[${station.id}] ${station.code ? `[${station.code}]` : ''} ${station.name || ''} - ${town || ''}`
        }
        return `[${station.code}${station.designation && station.code.includes('X') && station.stationType == STATION_TYPE.POINT_EAU_SOUTERRAINE ? `/${station.designation}` : ''}] ${internCodes.map(i => `[${i}] `).join()}${station.name ? ` - ${station.name}` : ''}${town ? ` - ${town}` : ''}`
    }

    getStationsHash = () => {
        const piezoContributors = this.props.piezometersContributors.filter(c =>
            c.internalReference && (c.internalReference.toLowerCase().startsWith('bss') || c.internalReference.toLowerCase()[5] === 'x' || c.internalReference.toLowerCase()[5] === 'z')
        )
        return Object.keys(STATIONS_TYPE_NAME).map(key => STATIONS_TYPE_NAME[key]).reduce((acc, stationType) => {
            const stations = this.props[stationType] || []
            const stationsLight = this.props[`${stationType}Light`] || []
            const allStations = [ ...stations, ...stationsLight ]
            allStations.map(station => {
                const town = getObjectLabel(this.props.citiesIndex[station.townCode], 'labelWithCode')
                const internCodes = stationType !== STATIONS_TYPE_NAME.piezometers ? [] : piezoContributors.filter(c => c.idStation === station.id).map(c => c.internalReference)
                const stationId = stationType === 'installations' ? [station.id] : []
                acc.push({
                    hash: searchAllCharacters([...stationId, station.code, station.name || '', station.townCode || '', town, ...internCodes].join('   ')),
                    type: 'station',
                    subtype: stationType,
                    subTypeLabel: i18n[stationType],
                    url: `/station/${station.typeName}/${station.id}`,
                    urlList: `/${station.typeName}`,
                    label: this.getStationLabel(station, town, internCodes),
                })
            })
            return acc
        }, [])
    }

    getReferentialsHash = () => {
        const referencialItems = ['parameters', 'watermasses', 'watersheds']
        return ['cities', 'parameters', 'contacts', 'contributors', 'units', 'watersheds', 'hydrogeologicalEntities', 'watermasses', 'networks', 'fractions', 'methods', 'supports', 'taxons'].reduce((acc, referencial) => {
            if (getUser().metadata === '1' || getUser().isAdmin === '1') {
                this.props[referencial].map(elem => {
                    acc.push({
                        hash: searchAllCharacters([elem.code, elem.latinName || elem.name, elem.internalCode].join('   ')),
                        type: 'referencial',
                        subtype: referencial,
                        subTypeLabel: i18n[referencial],
                        url: `${(referencialItems.includes(referencial) ? '/referencialItem/' : '/referencial/') + getLabel(REFERENCIAL_LABELS, referencial, 'single', 'plural')}/${elem.code}`,
                        urlList: `/referencial/${getLabel(REFERENCIAL_LABELS, referencial, 'single', 'plural')}`,
                        label: `${elem.latinName || elem.name} [${elem.code}]${elem.internalCode ? ` [${elem.internalCode}]` : ''}`,
                    })
                })
            }
            return acc
        }, [])
    }

    getOthersHash = () => {
        // CAMPAIGNS
        const hash1 = ['piezometryCampaigns', 'qualityCampaigns', 'pluviometryCampaigns', 'hydrometryCampaigns'].reduce((acc, campaignType) => {
            this.props[campaignType].map(campaign => {
                acc.push({
                    hash: searchAllCharacters(campaign.name || ''),
                    type: 'campaign',
                    subtype: campaignType,
                    subTypeLabel: i18n[campaignType],
                    url: `/${findStationType(campaign.stationType).type}/campaign/${campaign.id}`,
                    urlList: `/${findStationType(campaign.stationType).type}/campaign`,
                    label: campaign.name,
                })
            })
            return acc
        }, [])
        // CMS
        const hash2 = this.props.cmsEvents.reduce((acc, event) => {
            acc.push({
                hash: searchAllCharacters([event.title || '', event.comment || ''].join('   ')),
                type: 'content',
                subtype: getLabel(this.props.cmsCategories, event.idCategory, null, 'id'),
                subTypeLabel: getLabel(this.props.cmsCategories, event.idCategory, null, 'id'),
                url: `/contents/${event.idCategory}/${event.id}`,
                urlList: `/contents/${event.idCategory}`,
                label: event.title || '',
            })
            return acc
        }, hash1)
        // USERS
        const hash3 = this.props.users.reduce((acc, user) => {
            if (getUser().isAdmin === '1') {
                acc.push({
                    hash: searchAllCharacters([user.login, user.name || '', user.firstname || ''].join('   ')),
                    type: 'user',
                    subtype: 'user',
                    subTypeLabel: i18n.user,
                    url: `/administration/user/${user.login}`,
                    urlList: '/administration/user/',
                    label: `${user.firstname || ''} ${user.name || ''} [${user.login}]`,
                })
            }
            return acc
        }, hash2)
        // MATERIEL
        const materiels = [
            { mats: 'centrals', types: 'centralTypes', single: 'central' },
            { mats: 'powerSupplies', types: 'powerSupplyTypes', single: 'powerSupply' },
            { mats: 'sensors', types: 'sensorTypes', single: 'sensor' },
            { mats: 'sims', types: 'simTypes', single: 'sim' },
            { mats: 'telecoms', types: 'telecomTypes', single: 'telecom' },
            { mats: 'variousMateriels', types: 'variousMaterielTypes', single: 'variousMateriel' },
        ]
        return materiels.reduce((acc, materielObj) => {
            this.props[materielObj.mats].map(mat => {
                const type = this.props[materielObj.types].find(t => t.materielType == mat.materielType) || {}
                acc.push({
                    hash: searchAllCharacters([type.name || '', mat.serialNumber || mat.imei || ''].join('   ')),
                    type: 'materiel',
                    subtype: materielObj.single,
                    subTypeLabel: i18n[materielObj.single],
                    url: `/materiel/${materielObj.single}/${mat.id}`,
                    urlList: `/materiel/${materielObj.single}`,
                    label: `${type.name || ''} ${mat.serialNumber || mat.imei || ''}`,
                })
            })
            return acc
        }, hash3)
    }

    getDropDown = () => {
        if (this.state.dataLoaded && this.state.searchValue) {
            const searchValue = searchAllCharacters(this.state.searchValue)
            const results = this.state.results.filter(r => r.hash.includes(searchValue))
            const groupedResults = groupBy(results, r => `${r.type}:${r.subtype}`)
            this.state.result = results.length === 1 ? results : ''
            const content = Object.keys(groupedResults).map(key => {
                const elements = groupedResults[key]
                const orderedElements = orderBy(elements,
                    [
                        elem => elem.hash.indexOf(searchValue) === 0,
                        elem => [' ', '(', '\''].includes(elem.hash[elem.hash.indexOf(searchValue) - 1]),
                        elem => elem.label.length,
                    ], ['desc', 'desc', 'asc'])
                const rows = take(orderedElements, 5).map(e => (
                    <div className='row no-margin padding-left-2 globalSearchLineHeight truncate clickable hoverHighlight' onClick={ () => {
                        this.setState({ searchValue: '' })
                        this.props.push(e.url)
                    } }
                    >
                        { e.label }
                    </div>
                ))
                return (
                    <div>
                        <div className='row no-margin padding-left-1 globalSearchLineHeight'>
                            <h6 className='bold'>{ elements[0].subTypeLabel }</h6>
                        </div>
                        { rows }
                        { elements.length > 5 ? <div className='row no-margin padding-left-2 globalSearchLineHeight'><a className={ 'black-text' } onClick={ () => {
                            this.props.push(elements[0].urlList)
                            AppStore.dispatch(HomeAction.updateGlobalResearch(this.state.searchValue))
                            this.setState({ searchValue: '' })
                        } }
                        > { i18n.viewAll } </a></div> : null }
                    </div>
                )
            })
            return (
                <div className='sieau-dropdown dropdown-content blue-arrow row no-margin' id='globalSearchDropdown'>
                    <Card noMargin={ false } className='margin-top-1'>
                        <div className='row no-margin'>
                            <div className='col s12 no-padding title'>
                                <h6 className='center-align' id='result_length'>
                                    { content ? <b>{ results.length } { results.length > 1 ? i18n.results : i18n.result }</b> : <b>{ i18n.progressLoading }</b>}
                                </h6>
                                <p className='no-margin no-padding divider'/>
                            </div>
                        </div>
                        { content }
                    </Card>
                </div>
            )
        }
        return <div className='sieau-dropdown dropdown-content blue-arrow row no-margin inherited-position' id='globalSearchDropdown'/>
    }

    onKeyPressed = () => {
        if (this.state.result.length === 1) {
            this.setState({ searchValue: '' })
            this.props.push(this.state.result[0].url)
        }
    }

    render() {
        return (
            <div className='row valign-wrapper'>
                <div className='col s12 padding-top-1 global-search-div' onClick={ () => !this.state.clicked ? this.setState({ clicked: true }, () => this.loadData()) : {} }>
                    <Input noInputFieldClass className='no-margin black-text global-search-field dropdown-button' id='global_searchbar'placeholder={ i18n.search }
                        value={this.state.searchValue} onChange={(value) => this.onEnterSearchValue(value)} dropDownId='globalSearchDropdown' onEnterKeyPress={this.onKeyPressed}
                        otherInputProps={ {
                            onFocus: () => $('.global-search-icon').addClass('active'),
                            onBlur: () => $('.global-search-icon').removeClass('active'),
                        } }
                    />
                    <i className='material-icons clickable relative global-search-icon padding-right-3' >search</i>
                </div>
                { this.getDropDown() }
            </div>
        )
    }

    componentDidUpdate() {
        $('.tooltipped').tooltip('remove')
        $('.material-tooltip').remove()
        $('.tooltipped').tooltip({ delay: 50, html: true })
        $('.dropdown-button').dropdown()
    }
}

NotifsGlobalResearchComponent.propTypes = getPropTypes(storeProps, {
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    hydrometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    pluviometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    piezometersLight: arrayOf(DtoPiezometerLight),
    piezometersContributors: arrayOf(DtoPiezometerContributorLink),
    centrals: arrayOf(DtoCentral),
    powerSupplies: arrayOf(DtoPowerSupply),
    sensors: arrayOf(DtoSensor),
    sims: arrayOf(DtoSim),
    telecoms: arrayOf(DtoTelecom),
    variousMateriels: arrayOf(VariousMaterielDto),
    equipments: arrayOf(DtoEquipment),
    centralTypes: arrayOf(CentralTypeDto),
    powerSupplyTypes: arrayOf(PowerSupplyTypeDto),
    sensorTypes: arrayOf(DtoSensorType),
    telecomTypes: arrayOf(TelecomTypeDto),
    simTypes: arrayOf(DtoSimType),
    variousMaterielTypes: arrayOf(DtoVariousMaterielType),
    equipmentTypes: arrayOf(EquipmentTypeDto),
    citiesIndex: PropTypes.objectOf(PropTypes.instanceOf(CityDto)),
    cities: arrayOf(CityDto),
    taxons: PropTypes.arrayOf(PropTypes.instanceOf(TaxonDto)),
    installationsLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
})

const mapStateToProps = store => getMapStateToProps(storeProps, {
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    piezometersLight: store.PiezometryReducer.piezometersLight,
    piezometersContributors: store.PiezometryReducer.piezometersContributors,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
    pluviometryCampaigns: store.PluviometryReducer.pluviometryCampaigns,
    hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
    centrals: store.CentralReducer.centrals,
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    sensors: store.SensorReducer.sensors,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    sims: store.SimReducer.sims,
    telecoms: store.TelecomReducer.telecoms,
    equipments: store.EquipmentReducer.equipmentTypes,
    centralTypes: store.CentralReducer.centralTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sensorTypes: store.SensorReducer.sensorTypes,
    telecomTypes: store.TelecomReducer.telecomTypes,
    simTypes: store.SimReducer.simTypes,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    citiesIndex: store.CityReducer.citiesIndex,
    cities: store.CityReducer.cities,
    taxons: store.TaxonReducer.taxons,
    installationsLight: store.InstallationReducer.installationsLight,
    pluviometers: store.PluviometryReducer.pluviometers,
})

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifsGlobalResearchComponent)
