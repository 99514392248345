import PropTypes from 'prop-types'
import { instanceOf } from '../../../utils/StoreUtils'
import Job from '../../dto/DtoJob'
import i18n from 'simple-react-i18n'
import { hasValue } from '../../../utils/NumberUtil'
import React, { useEffect, useState } from 'react'
import StepperDialog from '../../../components/modal/StepperDialog'
import { Grid } from '@mui/material'
import PresentationCard from '../../../components/card/PresentationCard'
import CenterIcon from '../../../components/icon/CenterIcon'
import Select from '../../../components/forms/Select'
import JobAction from '../../actions/JobAction'
import Icon from '../../../components/icon/Icon'
import JobChainGraph from './JobChainGraph'
import { push } from 'connected-react-router'
import AppStore from '../../../store/AppStore'

const STEP = {
    ACTION: 0,
    CONFIRM: 1,
    SUCCESS: 2,
}

const stepAction = (chain, selectedJob, nextStep, setAction, currentJob, setIsOpen) => {
    const centerStyle = {
        'align-items': 'center',
        display: 'flex',
        justifyContent: 'center',
    }
    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h5>Vous avez sélectionné le traitement <b className='bold'>{selectedJob?.name}</b></h5>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4} >
                    <PresentationCard
                        iconComponent={<CenterIcon
                            icon={'arrow_back'}
                            color='#4f88b5'
                            additionalStyle={{ paddingRight: 5 }}
                        />}
                        iconContainerStyle={centerStyle}
                        title='Ajouter un traitement avant.'
                        onClick={() => {
                            setAction('BEFORE')
                            nextStep()
                        }}
                        description={`Lorsqu il sera terminé sans erreurs, le traitement ${selectedJob?.name} sera lancé ensuite.`}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PresentationCard
                        iconComponent={<CenterIcon
                            icon={'clear'}
                            color='#4f88b5'
                            additionalStyle={{ paddingRight: 5 }}
                        />}
                        iconContainerStyle={centerStyle}
                        title='Retirer ce traitement de la chaine'
                        onClick={() => {
                            setAction('REMOVE')
                            nextStep()
                        }}
                        description={`La chaine de traitement existera toujours mais le traitement ${selectedJob?.name} n\'en fera plus partie.`}
                        disabled={chain.length < 3}
                    />
                </Grid>
                <Grid item xs={4} >
                    <PresentationCard
                        iconComponent={<CenterIcon
                            icon={'arrow_forward'}
                            color='#4f88b5'
                            additionalStyle={{ paddingRight: 5 }}
                        />}
                        iconContainerStyle={centerStyle}
                        title='Ajouter un traitement après.'
                        onClick={() => {
                            setAction('AFTER')
                            nextStep()
                        }}
                        description={`Lorsque le traitement ${selectedJob?.name} sera terminé sans erreurs, ce traitement sera lancé ensuite.`}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={2} />
                <Grid item xs={4} >
                    <PresentationCard
                        iconComponent={<CenterIcon
                            icon={'delete'}
                            color='#4f88b5'
                            additionalStyle={{ paddingRight: 5 }}
                        />}
                        iconContainerStyle={centerStyle}
                        title='Supprimer toute la chaine de traitements.'
                        onClick={() => {
                            setAction('DELETE')
                            nextStep()
                        }}
                        disabled={chain.length === 1}
                        description={'Les traitements existeront toujours mais ils ne seront plus lancés les uns à la suite des autres.'}
                    />
                </Grid>
                <Grid item xs={4} >
                    <PresentationCard
                        iconComponent={<CenterIcon
                            icon={'edit'}
                            color='#4f88b5'
                            additionalStyle={{ paddingRight: 5 }}
                        />}
                        iconContainerStyle={centerStyle}
                        title='Accéder au traitement'
                        onClick={() => {
                            AppStore.dispatch(push(`/import/${selectedJob.id}`))
                            setIsOpen(false)
                        }}
                        disabled={selectedJob.id === currentJob.id}
                    />
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </div>
    )
}

const stepConfirm = (chain, selectedJob, action, jobs, otherJob, setOtherJob, currentJob) => {
    const title = (() => {
        switch (action) {
            case 'BEFORE':
                return 'Vous souhaitez ajouter un traitement avant'
            case 'AFTER':
                return 'Vous souhaitez ajouter un traitement après.'
            case 'REMOVE':
                return 'Vous souhaitez retirer ce traitement de la chaine de traitements'
            default:
                return 'Vous souhaitez supprimer toute la chaine de traitements'
        }
    })()
    const chainLoop = chain.includes(otherJob?.id)
    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h5>{title}</h5>
            </Grid>
            {
                ['REMOVE', 'DELETE'].includes(action) ? (<Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'><h6>Veuillez confirmer.</h6></Grid>) : (
                    <>
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                            <h6>Choisissez le traitement à ajouter dans la chaine.</h6>
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                            <Select col={ 8 } options={jobs} value={otherJob?.id} onChange={id => setOtherJob(jobs.find(j => j.id === id))}/>
                        </Grid>
                    </>
                )
            }
            {
                !chainLoop ? null : (
                    <>
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                            <h4>Ce traitement est déjà présent dans la chaine</h4>
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                            <Icon icon='error' style={{ color: 'red' }} size='large' clickable={false} />
                        </Grid>
                    </>
                )
            }
            {
                action === 'DELETE' || chainLoop || (['BEFORE', 'AFTER'].includes(action) && !otherJob) ? null : (
                    <>
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                            <h5>Voici la nouvelle chaine de traitement.</h5>
                        </Grid>
                        <JobChainGraph jobs={jobs} chain={getNewChain(chain, selectedJob, action, otherJob, currentJob)} onClick={() => {}} selectedJob={selectedJob}/>
                    </>
                )
            }
        </div>
    )
}

const stepSuccess = () => {
    return (
        <>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>La chaine de traitement à été mise à jour avec succès.</h4>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                <Icon icon='check_circle' style={{ color: 'green' }} size='large'/>
            </Grid>
        </>
    )
}

const getNewChain = (chain, selectedJob, action, otherJob, currentJob) => {
    switch (action) {
        case 'BEFORE':
            const chain2 = chain.slice()
            chain2.splice(chain.indexOf(selectedJob.id), 0, otherJob.id)
            return chain2
        case 'AFTER':
            const chain3 = chain.slice()
            chain3.splice(chain.indexOf(selectedJob.id) + 1, 0, otherJob.id)
            return chain3
        case 'REMOVE':
            return chain.filter(id => id !== selectedJob.id)
        case 'DELETE':
            return [currentJob.id]
    }
}

const applyChanges = (chain, selectedJob, action, otherJob, changeChain, nextStep, currentJob) => {
    switch (action) {
        case 'DELETE':
            JobAction.clearJobChain(selectedJob.id).then(() => {
                changeChain([currentJob.id])
                nextStep()
            })
            break
        default:
            const newChain = getNewChain(chain, selectedJob, action, otherJob, currentJob)
            JobAction.updateJobChain(selectedJob.id, newChain).then(() => {
                changeChain(newChain)
                nextStep()
            })
            break
    }
}

const JobChainStepper = ({ chain, changeChain, jobs, selectedJob, isOpen, setIsOpen, currentJob }) => {
    const [action, setAction] = useState(null)
    const [otherJob, setOtherJob] = useState(null)

    return (
        <StepperDialog
            steps={[
                {
                    label: 'Action',
                    constant: STEP.ACTION,
                    nextAvailable: !!action,
                },
                {
                    label: 'Confirmation',
                    constant: STEP.CONFIRM,
                    nextAvailable: (['REMOVE', 'DELETE'].includes(action) ? true : hasValue(otherJob)) && !chain.includes(otherJob?.id),
                    onNext: (nextStep) => applyChanges(chain, selectedJob, action, otherJob, changeChain, nextStep, currentJob),
                    nextLabel: i18n.confirm,
                },
                {
                    label: 'Résultat',
                    constant: STEP.SUCCESS,
                    canGoBack: false,
                },
            ]}
            open={isOpen}
            title={'Modifier la chaine de traitements'}
            closeDialog={() => {
                setAction(null)
                setOtherJob(null)
                setIsOpen(false)
            }}
        >
            {(step, nextStep, resetStep) => {
                switch (step) {
                    case STEP.ACTION:
                        return stepAction(chain, selectedJob, nextStep, setAction, currentJob, setIsOpen)
                    case STEP.CONFIRM:
                        return stepConfirm(chain, selectedJob, action, jobs, otherJob, setOtherJob, currentJob)
                    case STEP.SUCCESS:
                        return stepSuccess()
                }
            }}
        </StepperDialog>
    )
}

JobChainStepper.propTypes = {
    chain: PropTypes.arrayOf(PropTypes.number),
    selectedJob: instanceOf(Job),
    currentJob: instanceOf(Job),
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default JobChainStepper