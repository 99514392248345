import { push } from 'connected-react-router'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getStationArrowNav } from 'utils/ActionUtils'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import CatchmentPointsMap from '../CatchmentPointsMap'

const CatchmentMapApp = () => {
    const {
        catchment,
        catchments,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        catchments: store.CatchmentReducer.catchments,
    }), shallowEqual)
    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.catchments,
        href: 'catchment',
    }, {
        title: catchment.code || i18n.unknown,
        href: `station/catchment/${catchment.id}`,
    }, {
        title: i18n.dashboard,
        href: `station/catchment/${catchment.id}`,
    }], [catchment])

    useActions(() => {
        return {
            arrowNav: getStationArrowNav('catchment', catchments, catchment.id, s => dispatch(push(`/station/catchment/${s.id}`))),
            links: [{
                href: `http://www.deb.developpement-durable.gouv.fr/telechargements/fiche.php?IG=RMC_${catchment.code}`,
                label: 'MTES',
            }],
        }
    }, [catchments, catchment])

    return (
        <div className='row no-margin padding-right-1'>
            <div className='col s12 no-padding'>
                <CatchmentPointsMap catchmentPoints={catchment.catchmentPoints} />
            </div>
        </div>
    )
}

export default CatchmentMapApp