import React from 'react'
import PropTypes from 'prop-types'
import DtoOperation from 'station/components/suivipc/qualitometer/dto/DtoOperation'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { maxBy, orderBy, uniq } from 'lodash'
import { getLabel } from 'utils/StoreUtils'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { exportExcelIcon, exportPictureIcon } from 'components/echart/EChartUtils'
import { exportFile } from 'utils/ExportDataUtil'

const HydrobioMonitoringOperationGraph = ({
    hydrobioLists = [],
    hydrobioOperations = [],
}) => {
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const lastOperation = maxBy(hydrobioOperations, 'date')

    const listsFiltered = hydrobioLists.filter(l => l.idOperation === lastOperation?.id)

    const data = listsFiltered.map(l => [l.taxonCode, l.total])

    const listTaxons = uniq(listsFiltered.filter(l => hasValue(l.total)).map(l => l.taxonCode))

    const listParamOrdered = orderBy(listTaxons, p => p)

    const options = {
        title: {
            text: i18n.resultLastOperation,
            x: 'center',
            top: '1%',
        },
        series: [{
            type: 'bar',
            data,
            name: `${i18n.operation} ${i18n.fromDate} ${getDate(lastOperation?.date)}`,
            barMaxWidth: 30,
            itemStyle: {
                color: 'blue',
            },
        }],
        xAxis: [{
            type: 'category',
            data: listParamOrdered,
            boundaryGap: true,
            axisLabel: {
                formatter: v => {
                    const label = getLabel(taxons, v, 'labelWithCode')
                    return label.length > 33 ? `${label.substring(0, 30)}...` : label
                },
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
            name: i18n.enumerations,
            position: 'left',
            nameLocation: 'center',
            nameGap: 50,
            boundaryGap: true,
        }],
        legend: {
            top: '5%',
            left: '10%',
            right: '20%',
            type: 'scroll',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                const paramName = getLabel(taxons, params[0].axisValue, 'labelWithCode')
                return params.map(p => `${paramName}: ${p.data[1]}`).join('<br/>')
            },
        },
        grid: {
            top: '15%',
            left: '6%',
            right: '3%',
            bottom: '80',
            containLabel: true,
            height: 500,
        },
        toolbox: {
            right: '5%',
            top: '5%',
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: i18n.resultLastOperation,
                },
                myToolExport: {
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        const exportData = listsFiltered.map(l => {
                            return {
                                taxonCode: { value: l.taxonCode, cellType: 'right' },
                                taxon: getLabel(taxons, l.taxonCode, 'latinName'),
                                phaseA: { value: l.enumerationA, cellType: 'right' },
                                phaseB: { value: l.enumerationB, cellType: 'right' },
                                phaseC: { value: l.enumerationC, cellType: 'right' },
                                phaceCbis: { value: l.enumerationC2, cellType: 'right' },
                                total: { value: l.total, cellType: 'right' },
                            }
                        })
                        const sortedData = orderBy(exportData, 'taxonCode.value')
                        exportFile({
                            data: sortedData.length ? [
                                {
                                    ...sortedData[0],
                                    headers: Object.keys(sortedData[0]),
                                },
                                ...sortedData.slice(1),
                            ] : [],
                            exportType: 'xlsx',
                            titleFile: i18n.resultLastOperation,
                        }, true)
                    },
                },
            },
        },
    }
    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 700 }}
        />
    )
}

HydrobioMonitoringOperationGraph.propTypes = {
    hydrobioLists: PropTypes.arrayOf(PropTypes.shape({
        // DtoHydrobioList
        date: PropTypes.number,
        support: PropTypes.string,
        total: PropTypes.number,
    })),
    hydrobioOperations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
}

export default HydrobioMonitoringOperationGraph