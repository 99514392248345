import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import SettingDto from '../../../administration/dto/SettingDto'
import Icon from '../../../components/icon/Icon'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoEvent from '../../../events/dto/DtoEvent'
import HomeAction from '../../../home/actions/HomeAction'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import ContactItem from '../../../referencial/components/contact/dto/ContactDto'
import AppStore from '../../../store/AppStore'
import { getDate, getFullDate, getHour } from '../../../utils/DateUtil'
import { round } from '../../../utils/NumberUtil'
import { getSetting } from '../../../utils/SettingUtils'

const $ = window.$

class SmallEventCard extends Component {
    constructor(props) {
        super(props)
        this.state = { key: uuidv4() }
        this.onPublish = this.onPublish.bind(this)
    }

    componentDidMount() {
        $('.event-tooltip').tooltip()
    }

    componentDidUpdate() {
        $('.event-tooltip').tooltip()
    }

    getEventTypeBalise(icon, label) {
        if (this.props.small) {
            return <h6 className='valign-wrapper'><Icon size='small' icon={ icon } /> {label}</h6>
        }
        return <h5 className='valign-wrapper center-align'><Icon size='small' icon={ icon } /> {label}</h5>
    }

    getContact(code) {
        if (code) {
            return this.props.contacts.find(o => o.code == code)
        }
        return null
    }

    getUpdateInfo = (event) => {
        return event.updateDate && event.updateLogin ? (
            <div className='row no-margin valign-wrapper padding-left-2 font-size-10'>
                { this.props.shortDate ?
                    `${i18n.atDate}${getFullDate(event.updateDate)} ${i18n.by} ${event.updateLogin}` :
                    `${i18n.lastUpdateOn} ${getDate(event.updateDate)} ${i18n.atHour} ${getHour(event.updateDate)} ${i18n.by} ${event.updateLogin}` }
            </div>
        ) : null
    }

    getEventType(event) {
        switch (event.eventType) {
            case 'M':
                return this.getEventTypeBalise('build', i18n.curative)
            case 'G':
                return this.getEventTypeBalise('directions_run', i18n.preventive)
            case 'P':
                return this.getEventTypeBalise('event_note', i18n.toPlan)
            case 'S':
                return this.getEventTypeBalise('visibility', i18n.toMonitor)
            case 'C':
                return this.getEventTypeBalise('comment', i18n.comment)
            case 'T':
                return this.getEventTypeBalise('build', i18n.technique)
            default:
                return this.getEventTypeBalise('more_horiz', i18n.undefinedIntervention)
        }
    }

    getInfos = (event) => {
        if (!this.props.small) {
            const infos = []
            if (event.staticLevel) {
                infos.push(`${i18n.staticLevel}: ${event.staticLevel}`)
            }
            if (event.centralLevel) {
                infos.push(`${i18n.centralLevel}: ${event.centralLevel}`)
            }
            if (event.volt) {
                infos.push(`${i18n.volt}: ${event.volt}V`)
            }
            return infos.join(' - ')
        }
        return ''
    }

    getEventTitle = (event) => {
        return event.problem || event.solution || event.comment
    }

    getContent = (event) => {
        if (event.problem && event.solution) {
            return (
                <div>
                    <p>{event.solution}</p>
                    <p>{event.comment}</p>
                </div>
            )
        } else if (!event.problem && !event.solution) {
            return null
        }
        return (<p>{event.comment}</p>)
    }

    onPublish() {
        const token = getSetting(this.props.settings, 'FACEBOOK')
        if (token && token.value) {
            const message = []
            message.push(this.getEventTitle(this.props.event))
            if (this.props.event.problem || this.props.event.solution) {
                message.push(this.props.event.comment)
            }
            message.push(getDate(this.props.event.date))
            AppStore.dispatch(HomeAction.fbPublisher(message.join(' \n'), token.value))
        }
    }

    openPopup = () => {
        const popup = {
            id: uuidv4(),
            header: i18n.shareTheEvent,
            actions: (<div><a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a></div>),
            content: (
                <div>
                    <h3>{ i18n.inDeveloppmentFunctionnality }</h3>
                    {
                        getSetting(this.props.settings, 'FACEBOOK') && (
                            <div className='col s2'>
                                <div className='row no-margin center-align'>
                                    <a className='btn' onClick={ this.onPublish }>Publier sur Facebook</a>
                                </div>
                            </div>
                        )
                    }
                </div>
            ),
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    render() {
        const event = this.props.event
        const contact = this.getContact(event.contactCode)
        const obj = Object.assign({}, event, {
            date: getDate(event.date),
            hour: getHour(event.eventHour),
        })

        const datetime = `${obj.date} - ${obj.hour}`
        const title = this.props.title ? (<h4>{this.props.title}</h4>) : null
        const ns = event.ns ? <h6>{ `${i18n.staticLevel} : ${round(event.ns)}` }</h6> : null

        const elements = this.props.small ? {
            eventTitle: <h6 className='event-title'>{ this.getEventTitle(event) }</h6>,
            leftType: this.getEventType(event),
            rightType: null,
        } : {
            eventTitle: <h5 className='event-title'>{ this.getEventTitle(event) }</h5>,
            leftType: null,
            rightType: this.getEventType(event),
        }
        return (
            <div className='event-card'>
                <div className='row no-margin event-content valign-wrapper'>
                    <div className='col s9 clickable' onClick={ () => AppStore.dispatch(push(this.props.link + event.number)) } >
                        <div>
                            { title }
                            { elements.eventTitle }
                            <p><i>{datetime}</i></p>
                            { this.getContent(event) }
                            { elements.leftType }
                            { ns }
                        </div>
                    </div>
                    <div className='col s3'>
                        { elements.rightType }
                        { event.contactCode && contact && contact.code && (
                            <div className='row no-margin'>
                                <div className='clickable event-img-max-size tooltipped event-tooltip' id={ this.state.key }
                                    data-position='bottom' data-delay='20' data-tooltip={ contact.name }
                                    onClick={ () => AppStore.dispatch(push(`/referencial/contact/${event.contactCode}`)) }
                                >
                                    <Icon size='medium' icon='account_circle' />
                                </div>
                            </div>
                        ) }
                    </div>
                </div>
                <div className='row no-margin event-footer'>
                    <div className='no-padding col s8'>
                        <div className='row no-margin'>
                            <div className='col s9 no-padding'>
                                { this.getUpdateInfo(event) }
                            </div>
                            <div className='col s3'>
                                { this.getInfos(event) }
                            </div>
                        </div>
                    </div>
                    <div className='no-padding col s3 center-align'>
                        { event.chgtpiles && event.chgtpiles !== '0' && (<Icon size='small' icon='battery_std' tooltip={ i18n.changeBatteries } />) }
                        { event.razstation && event.razstation !== '0' && (<Icon size='small' icon='settings_backup_restore' tooltip={ i18n.rebootStation } />) }
                        { event.graph && event.graph !== '0' && (<Icon size='small' icon='insert_chart' tooltip={ i18n.displayOnCharts } />) }
                        { event.bankExport && event.bankExport !== '0' && (<Icon size='small' icon='launch' tooltip={ i18n.eventBankExport } />) }
                        { event.diagnostic && (<Icon size='small' icon='assignment_turned_in' tooltip={ i18n.diagnosticDone } />) }
                        { event.downloadData && (<Icon size='small' icon='cloud_download' tooltip={ i18n.downloadData }/>) }
                        { event.transmissionTest && (<Icon size='small' icon='network_check' tooltip={ i18n.transmissionTest }/>) }
                        { event.replaceDesiccant && (<Icon size='small' icon='invert_colors' tooltip={ i18n.replaceDesiccant }/>) }
                        { event.firmwareUpdate && (<Icon size='small' icon='system_update_alt' tooltip={ i18n.firmwareUpdate }/>) }
                        { event.replaceORings && (<Icon size='small' icon='settings_input_component' tooltip={ i18n.replaceORings }/>) }
                        { event.calibrationUpdate && (<Icon size='small' icon='timer' tooltip={ i18n.calibrateDateAndHour }/>) }
                    </div>
                    <div className='no-padding col s1'>
                        {
                            getSetting(this.props.settings, 'FACEBOOK') && (
                                <a className='tooltipped event-tooltip clickable' data-position='top'
                                    data-delay='20' data-tooltip={ i18n.toShare } onClick={ this.openPopup }
                                >
                                    <Icon size='small' icon='share' className='event-share'/>
                                </a>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        $('.event-tooltip').tooltip('remove')
    }
}

SmallEventCard.propTypes = {
    event: PropTypes.instanceOf(DtoEvent),
    title: PropTypes.string,
    small: PropTypes.boolean,
    shortDate: PropTypes.boolean,
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    link: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactItem)),
    showStationInfo: PropTypes.bool,
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
}

SmallEventCard.defaultProps = {
    small: true,
}

const mapStateToProps = store => ({
    settings: store.AdministrationReducer.settings,
    contacts: store.ContactReducer.contacts,
    installations: store.InstallationReducer.installations,
    qualitometers: store.QualityReducer.qualitometersLight,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
})

export default connect(mapStateToProps)(SmallEventCard)
