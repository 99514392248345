import { push } from 'connected-react-router'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import { H_ADMINISTRATION } from '../../account/constants/AccessRulesConstants'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import CartographyApp from './cartography/CartographyApp'
import ThemeApp from './cartography/ThemeApp'
import AdministrationContentsApp from './contents/AdministrationContentsApp'
import AdministrationHomePageApp from './homepage/AdministrationHomePageApp'
import ModelApp from './models/ModelApp'
import ParameterApp from './parameter/ParameterApp'
import ProblemSolutionApp from './problemSolution/ProblemSolutionApp'
import SettingApp from './setting/SettingApp'
import AdminStatsApp from './stats/components/AdminStatsApp'
import SuperAdminPanel from './superAdmin/components/SuperAdminPanel'
import UsersApp from './user/components/UsersApp'
import CorrectV6ParentApp from './superAdmin/components/correctV6/CorrectV6ParentApp'

class AdministrationApp extends Component {
    componentWillMount() {
        if (!componentHasHabilitations(H_ADMINISTRATION)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
    }

    render() {
        const base = '/administration/'
        const links = [
            {
                href: `${base}settings`,
                icons: 'build',
                name: i18n.settings,
            },
            {
                href: `${base}cartography`,
                icons: 'map',
                name: i18n.cartography,
            },
            {
                href: `${base}stats`,
                icons: 'data_usage',
                name: i18n.statistics,
            },
            {
                href: `${base}user`,
                icons: 'person_outline',
                name: i18n.users,
            },
            {
                href: `${base}homepage`,
                icons: 'dashboard',
                name: i18n.adminHomepage,
            },
            {
                href: `${base}contents`,
                icons: 'event_note',
                name: i18n.contentManagement,
            },
            {
                href: `${base}models`,
                icons: 'folder_open',
                name: i18n.models,
            },
            {
                href: `${base}parameters`,
                icons: 'settings',
                name: i18n.parameters,
            },
            // {
            //     href: base + 'problemSolution',
            //     icons: 'report_problem',
            //     name: i18n.problemSolution
            // },
        ]

        return (
            <div>
                <FixedSlideNav links={ links } />
                <div className='content-page no-padding fullscreen'>
                    <div className='row no-margin'>
                        <div className='col s12 no-padding'>
                            <BoundaryError>
                                <Switch>
                                    <Redirect exact from='/administration' to='/administration/user' />
                                    <Route path='/administration/settings' render={(props) => <SettingApp {...props} />} />
                                    <Route path='/administration/stats' render={(props) => <AdminStatsApp {...props} />} />
                                    <Route path='/administration/user' render={(props) => <UsersApp {...props} />} />
                                    <Route path='/administration/cartography/:id' render={(props) => <ThemeApp {...props} />} />
                                    <Route path='/administration/cartography' render={(props) => <CartographyApp {...props} />} />
                                    <Route path='/administration/homepage' render={(props) => <AdministrationHomePageApp {...props} />} />
                                    <Route path='/administration/contents' render={(props) => <AdministrationContentsApp {...props} />} />
                                    <Route path='/administration/problemSolution' render={(props) => <ProblemSolutionApp {...props} />} />
                                    <Route path='/administration/models' render={(props) => <ModelApp {...props} />} />
                                    <Route path='/administration/parameters' render={(props) => <ParameterApp {...props} />} />
                                    <Route path='/administration/superAdmin/SuperAdminPanel' render={(props) => <SuperAdminPanel {...props} />} />
                                    <Route path='/administration/correctV6' render={(props) => <CorrectV6ParentApp {...props} />} />
                                </Switch>
                                {/* { this.props.children && React.cloneElement(this.props.children, {
                                    getLink: this.props.getLink
                                }) } */}
                            </BoundaryError>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AdministrationApp.propTypes = {
    popup: PropTypes.element,
    children: PropTypes.element,
    getLink: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    popup: store.StationReducer.popup,
})

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationApp)
