import fetch from 'isomorphic-fetch'
import { RECEIVE_ALL_FRACTIONS, RECEIVE_FRACTION, RESET_FRACTION } from '../constants/FractionConstants'
import ApplicationConf from 'conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { PATH_REFERENCIAL_FRACTION, PATH_REFERENCIAL_FRACTION_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'

const FractionAction = {
    receiveFractions(fractions) {
        return { type: RECEIVE_ALL_FRACTIONS, fractions }
    },

    receiveFraction(fraction) {
        return { type: RECEIVE_FRACTION, fraction }
    },

    fetchSynchroSandreFraction() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateFraction(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        dispatch(FractionAction.fetchFractions())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdateOrInsertion))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.fraction + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.fraction))
                })
        }
    },

    fetchFraction(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.fraction(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(FractionAction.receiveFraction(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.fraction} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.fraction))
                })
        }
    },

    promiseFractions() {
        return fetch(ApplicationConf.referencial.fractions(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    // eslint-disable-next-line consistent-return
    fetchFractions() {
        return (dispatch) => {
            return FractionAction.promiseFractions()
                .then((json = []) => {
                    dispatch(FractionAction.receiveFractions(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.fractions} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.fractions))
                })
        }
    },

    updateFraction(fraction, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveFraction(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(fraction),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(FractionAction.fetchFraction(code))
                        dispatch(ToastrAction.success(i18n.fractionSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.fraction} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.fraction))
                })
        }
    },

    createFraction(fraction) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.fractions(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(fraction),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_FRACTION_LINK + fraction.code))
                        dispatch(FractionAction.fetchFractions())
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.fraction} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.fraction))
                })
        }
    },

    deleteFraction(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteFraction(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(FractionAction.fetchFractions())
                        dispatch(push(PATH_REFERENCIAL_FRACTION))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.fraction} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.fraction))
                })
        }
    },

    purgeFraction() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeFraction(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(FractionAction.fetchFractions())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.fractions} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.fractions))
                })
        }
    },

    replaceFraction(fraction, fractionReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceFraction(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: fraction.toString(),
                    replaceCode: fractionReplace.toString(),
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.fractionSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.fraction} : ${err}`))
                })
        }
    },

    reset() {
        return { type: RESET_FRACTION }
    },
}

export default FractionAction
