import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Table from '../../../../../components/datatable/Table'
import { template } from 'lodash'
import { nbPerPageLabel, SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend, StyledSpan } from '../../../../../components/StyledElements'
import { getSandreLabel } from '../../../../../utils/StringUtil'
import ReferencialAction from '../../../../../referencial/action/ReferencialAction'
import { getObjectLabel } from '../../../../../utils/StoreUtils'
import JobAction from '../../../../../import/actions/JobAction'
import { JOB_TYPES } from '../AddPiezoConstants'
import { getJobList } from '../../../../../utils/JobUtils'
import Input from '../../../../../components/forms/Input'
import Select from '../../../../../components/forms/Select'
import { hasValue } from '../../../../../utils/NumberUtil'
import Button from '../../../../../components/forms/Button'
import ToastrAction from '../../../../../toastr/actions/ToastrAction'
import { push } from 'connected-react-router'
import PresentationCard from '../../../../../components/card/PresentationCard'
import CenterIcon from '../../../../../components/icon/CenterIcon'


const getNumberList = max => [...Array(max)].map((_, i) => ({ value: `${i}`, label: i > 9 ? i : `0${i}` }))
const getHours = () => [ { value: '*', label: i18n.everyHours }, ...getNumberList(24) ]
const getMinutes = () => [
    { value: '*/5', label: template(i18n.everyCustomMinutes)({ minute: 5 }) },
    { value: '*/10', label: template(i18n.everyCustomMinutes)({ minute: 10 }) },
    { value: '*/15', label: template(i18n.everyCustomMinutes)({ minute: 15 }) },
    { value: '*/20', label: template(i18n.everyCustomMinutes)({ minute: 20 }) },
    { value: '*/30', label: template(i18n.everyCustomMinutes)({ minute: 30 }) },
    ...getNumberList(60),
]
const getDays = () => [
    { value: '*', label: i18n.allDays },
    { value: '1', label: i18n.monday },
    { value: '2', label: i18n.tuesday },
    { value: '3', label: i18n.wednesday, disabled: true },
    { value: '4', label: i18n.thursday },
    { value: '5', label: i18n.friday },
    { value: '6', label: i18n.saturday },
    { value: '0', label: i18n.sunday },
]

const handleChangeTime = (k, v, job, changeJob) => {
    if (k === 'minutes' && v.includes('/')) {
        changePlanification({ ...job, minutes: v, hours: '*', days: '*' }, changeJob)
    } else if (k === 'hours' && v.includes('/')) {
        changePlanification({ ...job, hours: v, days: '*' }, changeJob)
    } else {
        changePlanification({ ...job, [k]: v }, changeJob)
    }
}

const handleChangeDays = (selected, job, changeJob) => {
    changePlanification({ ...job, days: hasValue(selected) && selected != -1 ? selected.toString() : '*' }, changeJob)
}

const changePlanification = ({ minutes, hours, days }, changeJob) => {
    const cron = ['0', minutes, hours, '*', '*', days, '*'].join(' ')
    changeJob({ cron, minutes, hours, days })
}

const addJob = (newJob, setNewJob, initialJob, dispatch, jobTableCriterias, setJobsWithStation, code) => {
    if (!hasValue(newJob.name)) {
        dispatch(ToastrAction.warning(i18n.pleaseCompleteAllField))
    } else {
        dispatch(JobAction.addJob(newJob, false)).then(() => {
            setNewJob(initialJob)
            dispatch(JobAction.fetchJobTable(jobTableCriterias))
            JobAction.jobHasStation(code).then(json => setJobsWithStation(json))
        })
    }
}


const AddPiezoStepJob = ({
    station,
    changeStation,
    nextStep,
    fullReset,
    setIsOpen,
    setFilters,
    setJobDataTypes,
}) => {
    const dispatch = useDispatch()

    const {
        piezometers,
        sandreCodes,
        citiesIndex,
        jobTable,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometerListSpecific,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        citiesIndex: store.CityReducer.citiesIndex,
        jobTable: store.JobReducer.jobTable,
    }), shallowEqual)

    const jobTableCriterias = {
        jobType: JOB_TYPES[station.stationType],
        forceLoad: true,
        scheduled: true,
        displayMode: 'job',
    }
    const initialJob = { ...station.job, cron: '0 0 0 * * * *', days: '*', hours: '0', minutes: '0', id: null, name: null }

    const [newJob, setNewJob] = useState(initialJob)
    const [jobsWithStation, setJobsWithStation] = useState([])
    const changeJob = (changes) => setNewJob({ ...newJob, ...changes })

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        dispatch(JobAction.fetchJobTable(jobTableCriterias))
        JobAction.jobHasStation(station.code).then(json => setJobsWithStation(json))
    }, [])

    const stations = station.ids.map(i => piezometers.find(q => q.id === i)).map(s => ({
        code: { value: s.code },
        name: { value: s.name },
        nature: { value: getSandreLabel(sandreCodes, SANDRE.PIEZOMETER_NATURE, station.nature) },
        city: { value: getObjectLabel(citiesIndex[s.townCode], 'labelWithCode') },
        nullValue: {
            value: null,
            rightIcon: 'arrow_forward',
            rightIconColor: '#2196F3',
            rightIconTip: i18n.accessToTheFile,
            rightIconClick: () => dispatch(push(`/station/piezometry/${s.id}/dashboard`)),
            leftIcon: 'border_color',
            leftIconColor: '#2196F3',
            leftIconTip: i18n.accessDescription,
            leftIconClick: () => dispatch(push(`/station/piezometry/${s.id}/description`)),
        },
    }))
    const jobList = getJobList(jobTable.data).jobs.map(j => {
        const jobHasStation = jobsWithStation.some(job => j.id === job.id)
        return {
            ...j,
            lastExecution: { value: j.date.value },
            nullValue2: {
                value: null,
                leftIcon: jobHasStation ? 'location_on' : 'location_off',
                leftIconColor: 'rgba(0, 0, 0, 0.87)',
                leftIconTip: () => jobHasStation ? i18n.stationIsIn : i18n.stationIsOut,
                rightIcon: jobHasStation ? 'clear' : 'note_add',
                rightIconColor: '#2196F3',
                rightIconTip: () => jobHasStation ? i18n.removeStationFromJob : i18n.addStationToJob,
                rightIconClick: jobHasStation ?
                    () => JobAction.removeStationFromJob(station.code, j.id).then(() => JobAction.jobHasStation(station.code).then(json => {
                        setJobsWithStation(json)
                        dispatch(ToastrAction.success(i18n.stationHasBeenRemoved))
                    })) :
                    () => JobAction.addStationToJob(station.code, j.id).then(() => JobAction.jobHasStation(station.code).then(json => {
                        setJobsWithStation(json)
                        dispatch(ToastrAction.success(i18n.stationHasBeenAdd))
                    })),
            },
        }
    })

    return (
        <div>
            <Table
                condensed
                sortable
                color
                data={stations}
                type={{ headers: ['code', 'name', 'city', 'nature', 'nullValue'] }}
                maxHeight='62vh'
                overflow='auto'
                title={i18n.importedData}
            />
            <div className='padding-top-2' />
            <Table
                title={i18n.jobs}
                data={jobList}
                color
                paging
                type={{ headers: ['jobName', 'status', 'lastExecution', 'programmation', 'nullValue', 'nullValue2'] }}
                nbPerPageLabel={nbPerPageLabel}
                sortable
                condensed
            />
            <StyledFieldSet>
                <StyledLegend>
                    <StyledSpan>{i18n.addJob}</StyledSpan>
                </StyledLegend>
                <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-1'>
                    <Grid item xs={5}>
                        <Input title={i18n.name} value={newJob.name} onChange={v => changeJob({ name: v })} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-1'>
                    <Grid item xs={3}>
                        <Select
                            value={ newJob.hours || 0 }
                            options={ getHours() }
                            label={ i18n.jobHour }
                            onChange={ v => handleChangeTime('hours', v, newJob, changeJob) }
                            noNullValue
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={ newJob.minutes || 0 }
                            options={ getMinutes() }
                            label={ i18n.jobMinute }
                            onChange={ v => handleChangeTime('minutes', v, newJob, changeJob) }
                            noNullValue
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={ newJob.days }
                            options={ getDays() }
                            label={ i18n.jobDay }
                            onChange={ v => handleChangeDays(v, newJob, changeJob) }
                            noNullValue
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button title={i18n.add} icon='note_add' onClick={() => addJob(newJob, setNewJob, initialJob, dispatch, jobTableCriterias, setJobsWithStation, station.code)}/>
                    </Grid>
                </Grid>
            </StyledFieldSet>
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                <h4>{ i18n.andNow }</h4>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <PresentationCard
                        iconComponent={
                            <CenterIcon
                                icon={'note_add'}
                                color='#4f88b5'
                                additionalStyle={{ paddingRight: 5 }}
                            />
                        }
                        title={i18n.addAnotherStation}
                        onClick={fullReset}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PresentationCard
                        iconComponent={
                            <CenterIcon
                                icon={'clear'}
                                color='#4f88b5'
                                additionalStyle={{ paddingRight: 5 }}
                            />
                        }
                        title={i18n.close}
                        onClick={() => setIsOpen(false)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PresentationCard
                        iconComponent={
                            <CenterIcon
                                icon={'location_on'}
                                color='#4f88b5'
                                additionalStyle={{ paddingRight: 5 }}
                            />
                        }
                        title={i18n.accessToTheFile}
                        onClick={() => dispatch(push(`/station/piezometry/${station.ids[0]}/dashboard`))}
                    />
                </Grid>
            </Grid>

        </div>
    )
}

AddPiezoStepJob.propTypes = {
    nextStep: PropTypes.func,
    changeStation: PropTypes.func,
}

export default AddPiezoStepJob