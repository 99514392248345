import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { ceil, chunk, groupBy, last, range } from 'lodash'
import Checkbox from '../../../../../components/forms/Checkbox'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import Line from '../../../../../components/echart/series/Line'
import { getColorFromPalette } from '../../../../../utils/ColorUtil'



const PiezoSuiviPastYearsTab2 = ({
    id,
    displayCote,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    measures,
    tab, // tab sélectionné en haut à gauche
    minDate,
    maxDate,
}) => {
    const dispatch = useDispatch()

    const [activeYears, setActiveYears] = useState([])
    const [groupedCivil, setGroupedCivil] = useState({})
    const [groupedHydro, setGroupedHydro] = useState({})

    const toEchartMeasure = (m) => ({ value: [m.date, displayCote === MEASURE_COTE.NGF ? m.NGF : landmarkValue - m.NGF, { NGF: m.NGF, depth: landmarkValue - m.NGF }], isPiezo: true })

    useEffect(() => {
        setGroupedCivil(groupBy(measures, m => moment(m.date).year()))
        setGroupedHydro(groupBy(measures, m => moment(m.date).add(3, 'month').year()))
    }, [])

    useEffect(() => {
        const group = tab === 'CIVIL_YEAR' || tab === 'OTHER' ? groupedCivil : (tab === 'HYDRO_YEAR' ? groupedHydro : null)
        if (!group) {
            changeParent({ yearSeries: [] })
        } else {
            const yearSeries = activeYears.flatMap((y, idx) => {
                const yearMeasures = (() => {
                    if (tab === 'CIVIL_YEAR' || tab === 'OTHER') {
                        const nowYear = tab === 'OTHER' && (minDate ?? maxDate) ? moment(minDate ?? maxDate).year() : moment().year()
                        return (group[y] || []).map(m => toEchartMeasure({ ...m, date: moment(m.date).year(nowYear).valueOf() }))
                    }
                    return (group[y] || []).map(m => toEchartMeasure({ ...m, date: moment(m.date).add(3, 'month').year(moment().year()).subtract(3, 'month').valueOf() }))
                })()
                if (!yearMeasures.length) {
                    return []
                }
                return [Line({
                    data: yearMeasures,
                    name: y.toString(),
                    isPiezo: true,
                    connectNulls: false,
                    showSymbol: false,
                    color: getColorFromPalette(idx),
                })]
            })
            changeParent({ yearSeries })
        }
    }, [activeYears, tab, minDate, maxDate]) // recalculé lorsqu'on change les checkbox, ou la tab en haut à gauche

    const changeYear = (y, v) => setActiveYears(v ? [...activeYears, y] : activeYears.filter(y2 => y2 !== y))

    if (!measures.length) {
        return null
    }
    const yearRange = range(moment(measures[0].date).year(), moment(last(measures).date).year() + 1)
    const yearCheckboxes = yearRange.map(y => (
        <div className='row no-margin'>
            <Checkbox checked={ activeYears.includes(y) } label={ y } onChange={ v => changeYear(y, v) } />
        </div>
    ))
    const groups = chunk(yearCheckboxes, ceil(yearCheckboxes.length / 4) || 1)
    return (
        <div>
            <StyledFieldSet>
                <StyledLegend>{ i18n.previousYears }</StyledLegend>
                <div className='padding-left-2 row no-margin' >
                    <div className='col s3'>
                        { groups[0] || [] }
                    </div>
                    <div className='col s3'>
                        { groups[1] || [] }
                    </div>
                    <div className='col s3'>
                        { groups[2] || [] }
                    </div>
                    <div className='col s3'>
                        { groups[3] || [] }
                    </div>
                </div>
            </StyledFieldSet>
        </div>
    )
}

PiezoSuiviPastYearsTab2.propTypes = {
    displayCote: PropTypes.number,
    id: PropTypes.number,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
}

export default PiezoSuiviPastYearsTab2