import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { push } from 'connected-react-router'
import { groupBy, orderBy, pick, template } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import { setActions } from '../../../components/ActionUtil'
import Card from '../../../components/card/Card'
import MessageCard from '../../../components/card/MessageCard'
import Table from '../../../components/datatable/Table'
import { getSortedTableData } from '../../../components/datatable/TableUtils'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import FilterSelect from '../../../components/forms/specific/FilterSelect'
import JobAction from '../../../import/actions/JobAction'
import NetworkAction from '../../../referencial/components/network/actions/NetworkAction'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { catchError } from '../../../utils/ActionUtils'
import { getDate, getFullDate } from '../../../utils/DateUtil'
import { onChangeDate, sieauTooltip } from '../../../utils/FormUtils'
import { round } from '../../../utils/NumberUtil'
import WaitAction from '../../../wait/WaitAction'
import PiezometryAction from '../../actions/PiezometryAction'
import DtoExportOverview from './DtoExportOverview'

const ExporOverviewList = ({}) => {
    const {
        networkPiezoLink,
        networks,
        filterResults,
    } = useSelector(store => ({
        networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
        networks: store.NetworkReducer.networks,
        filterResults: store.StationReducer.filterResults,
    }))

    const statusList = [
        { code: 1, name: i18n.success },
        { code: 2, name: i18n.successWithWarnings },
        { code: 3, name: i18n.error },
    ]
    const [dataLoaded, setDataLoaded] = useState(false)

    const [networkList, setNetworkList] = useState([])
    const [exportData, setExportData] = useState([])
    const [redCount, setRedCount] = useState(null)
    const [orangeCount, setorangeCount] = useState(null)
    const [greenCount, setGreenCount] = useState(null)
    const [logDialogIsOpen, setLogDialogIsOpen] = useState(false)
    const [selectedStationCode, setSelectedStationCode] = useState(null)
    const [selectedExecutionId, setSelectedExecutionId] = useState(null)
    const [selectedStatusLabel, setSelectedStatusLabel] = useState(null)
    const [jobLogs, setJobLogs] = useState([])
    const [date, setDate] = useState(moment().valueOf())
    const [searchValue, setSearchValue] = useState(null)
    const [filter, setFilter] = useState(() => pick(AdministrationAction.getCache('piezometry'), ['filter']).filter)
    const [stationsFilter, setStationsFilter] = useState(filter && filterResults ? filterResults : null)
    const [network, setNetwork] = useState(null)
    const [color, setColor] = useState('all')
    const [exportStatus, setExportStatus] = useState(null)
    const [ackStatus, setAckStatus] = useState(null)
    const [lastExecStatus, setLastExecStatus] = useState(null)
    const [nbDaysWarning, setNbDaysWarning] = useState(3)
    const [nbDaysError, setNbDaysError] = useState(5)
    const [usedSort, setUsedSort] = useState({})
    const dispatch = useDispatch()

    const onValidate = () => {
        setDataLoaded(false)
        PiezometryAction.promisePiezoExportOverview({
            searchValue,
            filter,
            network,
            color,
            exportStatus,
            ackStatus,
            lastExecStatus,
            nbDaysWarning,
            nbDaysError,
            date,
            stationsFilter: [-1, null, undefined].includes(filter) ? null : (stationsFilter || []).map(s => s.id || s),
        }).then(json => {
            setDataLoaded(true)
            setExportData(json.lines.map(d => new DtoExportOverview(d)))
            setRedCount(json.red)
            setorangeCount(json.orange)
            setGreenCount(json.green)
        }).catch(err => catchError(err, i18n.exportOverview))
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return i18n.success
            case 2:
                return i18n.successWithWarnings
            case 3:
                return i18n.error
            default:
                return null
        }
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 'success':
                return { className: 'green-200' }
            case 'warning':
                return { className: 'yellow-200' }
            case 'error':
                return { className: 'red-200' }
            default:
                return null
        }
    }

    const getStatusIcon = (status) => {
        const size = 25
        const [icon, color, label] = (() => {
            switch (status) {
                case 1:
                    return ['done', 'green', i18n.success]
                case 2:
                    return ['done', 'green', i18n.successWithWarnings]
                case 3:
                    return ['error', 'red', i18n.error]
                default:
                    return [null, null, null]
            }
        })()
        if (icon) {
            const tooltip = sieauTooltip(() => label)
            if (status === 2) {
                return (
                    <div style={{ position: 'relative', width: `${size}px` }} {...tooltip} >
                        <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color, fontSize: `${size}px`, width: `${size}px`, height: `${size}px`, position: 'relative' }}>{ icon }</i>
                        <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color: 'orange', fontSize: `${round(size/2, 0)}px`, width: `${round(size/2, 0)}px`, height: `${round(size/2, 0)}px`, position: 'absolute', right: 0, bottom: 0, zIndex: 10 }}>warning</i>
                    </div>
                )
            }
            return <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color, fontSize: `${size}px`, width: `${size}px`, height: `${size}px` }} {...tooltip} >{ icon }</i>
        }
    }

    const values = useMemo(() => {
        const rawData = exportData.map(d => ({
            ...d,
            nullValue: { leftIcon: 'border_color', leftIconColor: 'dodgerblue', leftIconClick: () => redirectStation(d.stationId, true), leftIconTip: () => i18n.goToValidation },
            code: { value: d.code, onClick: () => redirectStation(d.stationId) },
            name: { value: d.name, onClick: () => redirectStation(d.stationId) },
            city: { value: d.city },
            startDate: { value: d.startDate, format: 'dd/MM/yyyy', cellType: 'date' },
            endDate: { value: d.endDate, format: 'dd/MM/yyyy', cellType: 'date' },
            numNum: { value: d.num, cellType: 'number' },

            nullValue2: { value: getStatusIcon(d.exportStatus), onClick: () => openLog(d.code, d.exportExecId, i18n.exportStatus) },
            exportName: { value: d.exportDate, color: d.exportColor, format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date', onClick: () => openLog(d.code, d.exportExecId, i18n.exportStatus) },
            exportStatus: { value: getStatusLabel(d.exportStatus) },

            nullValue3: { value: getStatusIcon(d.ackStatus), onClick: () => openLog(d.code, d.ackExecId, i18n.ackStatus) },
            acknoledgment: { value: d.ackDate, color: d.ackColor, format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date', onClick: () => openLog(d.code, d.ackExecId, i18n.ackStatus) },
            ackStatus: { value: getStatusLabel(d.ackStatus) },

            nullValue4: { value: getStatusIcon(d.lastExecStatus), onClick: () => openLog(d.code, d.lastExecId, i18n.lastExecStatus) },
            lastExecution: { value: d.lastExecDate, color: d.lastExecColor, format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date', onClick: () => openLog(d.code, d.lastExecId, i18n.lastExecStatus) },
            lastExecStatus: { value: getStatusLabel(d.lastExecStatus) },
            station: { id: d.stationId, code: d.code, name: d.name, city: d.city },
        }))
        const sortedData = getSortedTableData(rawData, usedSort, true)
        const stations = sortedData.map(s => s.station)
        dispatch(AdministrationAction.setSelectedSearchValues('piezometry', { stations }))
        return sortedData
    }, [exportData, usedSort])

    useEffect(() => {
        dispatch(PiezometryAction.fetchNetworkPiezoLink()).then(() => {
            const networkGroup = groupBy(networkPiezoLink, 'idNetwork')
            setNetworkList(Object.keys(networkGroup).map(nId => networks.find(n => n.id == nId)).filter(n => !!n))
        })
        if (!networks.length) {
            dispatch(NetworkAction.fetchNetworks())
        }
        onValidate()
    }, [])

    useEffect(() => setActions({
        export: () => {
            const data = values
            if (data.length) {
                data[0].headers = ['code', 'name', 'city', 'startDate', 'endDate', 'numNum', 'exportStatus', 'exportName', 'ackStatus', 'acknoledgment', 'lastExecStatus', 'lastExecution']
            }
            return {
                data,
                exportType: 'xlsx',
                titleFile: i18n.exportOverview,
            }
        },
    }), [values])

    const redirectStation = (id, validation) => dispatch(push(`/station/piezometry/${id}${validation ? '/validation' : ''}`))
    const redirectExecution = (jobId, jobExecutionId) => dispatch(push(`/import/${jobId}?jobExecutionId=${jobExecutionId}`))


    const openLog = (selectedStationCode, selectedExecutionId, selectedStatusLabel) => {
        dispatch(WaitAction.waitStart())
        JobAction.promiseFindLog(selectedExecutionId, selectedStationCode).then(json => {
            dispatch(WaitAction.waitStop())
            setLogDialogIsOpen(true)
            setSelectedExecutionId(selectedExecutionId)
            setSelectedStationCode(selectedStationCode)
            setJobLogs(json)
            setSelectedStatusLabel(selectedStatusLabel)
        }).catch(err => catchError(err, i18n.exportOverview))
    }

    const getLogDialog = () => {
        const data = orderBy(jobLogs, 'date').map(log => ({
            date: { value: getFullDate(log.date), ...getStatusColor(log.status) },
            comment: { value: log.comment, ...getStatusColor(log.status) },
        }))
        const redirect = !jobLogs.length ? () => {} : () => redirectExecution(jobLogs[0].jobId, selectedExecutionId)

        return (
            <Dialog
                onClose={ () => setLogDialogIsOpen(false) }
                fullWidth
                maxWidth='lg'
                open={logDialogIsOpen}
                classes='no-padding'
                scroll='body'
            >
                <DialogTitle>{`${selectedStatusLabel} - ${selectedStationCode}`}</DialogTitle>
                <DialogContent>
                    <div>
                        <Table
                            data={data}
                            paging
                            color
                            nbPerPageLabel={nbPerPageLabel}
                            showTitle={false}
                            type={{ headers: ['date', 'comment'] }}
                            condensed
                            noHightlight
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () =>setLogDialogIsOpen(false) } variant='outlined'>
                        {i18n.close}
                    </Button>
                    <Button onClick={redirect} variant='contained' color='primary'>
                        {i18n.viewJob}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <div>
            <Card className='padding-top-1'>
                <div className='row no-margin'>
                    <Input col={ 2 } title={ i18n.endDate } value={getDate(date)} onChange={ v => onChangeDate(v, setDate) }/>
                    <Input col={3} title={i18n.search} value={searchValue} onChange={setSearchValue} />
                    <FilterSelect col={3} onChange={(v, filter) => {
                        setFilter(filter)
                        setStationsFilter(v)
                    }} stationType='piezometry'
                    />
                    <Select options={networkList}
                        keyLabel='mnemonic'
                        col={4}
                        label={i18n.network}
                        nullLabel='&nbsp;'
                        onChange={(value = {}) => setNetwork(value)}
                        value={network}
                    />
                </div>
                <div className='row no-margin'>
                    <NumberField col={3} title={i18n.nbDaysUndetected} onChange={setNbDaysWarning} value={nbDaysWarning} max={ nbDaysError } min={ 0 }/>
                    <NumberField col={3} title={i18n.nbDaysAlert} onChange={setNbDaysError} value={nbDaysError} min={nbDaysWarning} />
                    <Select col={2} options={statusList} label={ i18n.exportStatus } value={ exportStatus } onChange={setExportStatus} nullLabel='&nbsp;'/>
                    <Select col={2} options={statusList} label={ i18n.ackStatus } value={ ackStatus } onChange={setAckStatus} nullLabel='&nbsp;'/>
                    <Select col={2} options={statusList} label={ i18n.lastExecStatus } value={ lastExecStatus } onChange={setLastExecStatus} nullLabel='&nbsp;'/>
                </div>
                <div className='row no-margin'>
                    <Checkbox
                        col={1}
                        checked={color === 'all'}
                        label={i18n.all}
                        onChange={() => setColor('all')}
                    />
                    <Checkbox
                        col={1}
                        checked={color === 'green'}
                        label={<span><span className={'green arrests-level-panel green-text'} {...sieauTooltip(template(i18n.infDaysData)({ days: nbDaysWarning }))}>__</span>{`(${greenCount || '0'})`}</span>}
                        onChange={v => setColor(v ? 'green' : 'all')}
                        tooltip={template(i18n.infDaysData)({ days: nbDaysWarning })}
                    />
                    <Checkbox
                        col={1}
                        checked={color === 'orange'}
                        label={<span><span className={'yellow darken-1 arrests-level-panel yellow-text'} {...sieauTooltip(template(i18n.infBetweenDaysData)({ days1: nbDaysWarning, days2: nbDaysError }))}>__</span>{`(${orangeCount || '0'})`}</span>}
                        onChange={v => setColor(v ? 'orange' : 'all')}
                        tooltip={template(i18n.infBetweenDaysData)({ days1: nbDaysWarning, days2: nbDaysError })}
                    />
                    <Checkbox
                        col={1}
                        checked={color === 'red'}
                        label={<span><span className={'red arrests-level-panel red-text'} {...sieauTooltip(template(i18n.supDaysData)({ days: nbDaysError }))}>__</span>{`(${redCount || '0'})`}</span>}
                        onChange={v => setColor(v ? 'red' : 'all')}
                        tooltip={template(i18n.supDaysData)({ days: nbDaysError })}
                    />
                    <div className='col s5' />
                    <div className='col s3 padding-bottom-1'>
                        <a className='btn col s12' onClick={() => onValidate()}>
                            {i18n.search}
                        </a>
                    </div>
                </div>
            </Card>
            {
                !dataLoaded ? <div className='padding-top-1 padding-left-1 padding-right-1'><MessageCard >{ i18n.loadingInProgress }</MessageCard></div> : (
                    !exportData.length ? <div className='padding-top-1 padding-left-1 padding-right-1'><MessageCard >{ i18n.noDataToDisplay }</MessageCard></div> : (
                        <Table
                            data={values}
                            sortable
                            paging
                            color
                            nbPerPageLabel={nbPerPageLabel}
                            title={i18n.piezometers}
                            type={{ headers: ['nullValue', 'code', 'name', 'city', 'startDate', 'endDate', 'numNum', 'nullValue2', 'exportName', 'nullValue3', 'acknoledgment', 'nullValue4', 'lastExecution'] }}
                            condensed
                            noHightlight
                            onSort={ (column, sort) => setUsedSort({ column, sort }) }
                        />
                    )
                )
            }
            { getLogDialog() }
        </div>
    )
}

export default ExporOverviewList
