'use strict'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import CityDto from 'referencial/components/city/dto/CityDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import {
    H_AGRI_WATER_TURNS,
    H_TERRITORY_DASHBOARD,
    H_TERRITORY_OPTIONS, H_TERRITORY_RSEAU, H_TERRITORY_USAGE,
} from '../../account/constants/AccessRulesConstants'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import TerritoryDashboardApp from './TerritoryDashboardApp'
import TerritorySettingsApp from './TerritorySettingsApp'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import AgriAction from 'agriAdministration/actions/AgriAction'
import WaterTurnsManagerApp from 'exploitations/components/WaterTurnsManagerApp'
import TerritoryRestrictionsApp from './TerritoryRestrictionsApp'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'

class TerritoryApp extends Component {
    componentDidMount() {
        if (!componentHasHabilitations(H_TERRITORY_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const { sandreCodes, installationsWithGeo, cities, managementUnits } = this.props
        this.props.setFormationPath('module-territoire')
        this.props.setHelpLink('', '')
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!installationsWithGeo.length) {
            this.props.fetchInstallationsWithGeo()
        }
        if (!cities.length) {
            this.props.fetchCities()
        }
        if (!managementUnits.length) {
            this.props.fetchManagementUnits()
        }
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()
        this.props.fetchExploitationsExportFullData()
    }

    componentWillUnmount() {
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
    }

    getLink = () => {
        if (this.props.location.pathname.includes('/territory/settings')) {
            // Il faudra potentiellement modifier la condition à l'avenir
            const links = [
                {
                    href: '/territory/settings/parameters',
                    icons: 'build',
                    name: i18n.parameters,
                    habilitation: H_TERRITORY_DASHBOARD,
                },
                {
                    href: '/territory/settings/cultures',
                    icons: 'local_florist',
                    name: i18n.cultures,
                    habilitation: H_TERRITORY_DASHBOARD,
                },
                {
                    href: '/territory/settings/usages',
                    icons: 'art_track',
                    name: i18n.usages,
                    habilitation: H_TERRITORY_USAGE,
                },
                {
                    href: '/territory/settings/tanks',
                    icons: 'local_drink',
                    name: i18n.detentions,
                    habilitation: H_TERRITORY_OPTIONS,
                },
                {
                    href: '/territory/settings/rseau',
                    icons: 'settings_applications',
                    name: 'RSEau',
                    habilitation: H_TERRITORY_RSEAU,
                },
                {
                    className: 'bottom-slide-nav-link',
                    href: '/territory/settings',
                    icons: 'settings',
                    name: i18n.settings,
                    habilitation: H_TERRITORY_OPTIONS,
                },
            ]
            return links.filter(l => !!l && componentHasHabilitations(l.habilitation))
        }
        return [{
            href: '/territory/dashboard',
            icons: 'map',
            name: i18n.dashboard,
            habilitation: H_TERRITORY_DASHBOARD,
        }, {
            href: '/territory/restrictions',
            icons: 'format_color_reset',
            name: i18n.restrictionsManagement,
            habilitation: H_TERRITORY_OPTIONS,
        }, {
            href: '/territory/waterTurns',
            icons: 'invert_colors',
            name: i18n.waterTurnsManagement,
            habilitation: H_AGRI_WATER_TURNS,
        }, {
            className: 'bottom-slide-nav-link',
            href: '/territory/settings',
            icons: 'settings',
            name: i18n.settings,
            habilitation: H_TERRITORY_OPTIONS,
        }].filter(l => componentHasHabilitations(l.habilitation))
    }

    render() {
        const navBarLinks = this.getLink()
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Switch>
                                    <Redirect exact from='/territory' to='/territory/dashboard' />
                                    <Route path='/territory/dashboard' render={(props) => <TerritoryDashboardApp {...props} />} />
                                    <Route path='/territory/waterTurns' render={(props) => <WaterTurnsManagerApp {...props} />} />
                                    <Route path='/territory/restrictions' render={(props) => <TerritoryRestrictionsApp {...props} />} />
                                    <Route path='/territory/settings' render={(props) => <TerritorySettingsApp {...props} />} />
                                </Switch>
                            </BoundaryError>
                            {/* {this.props.children && React.cloneElement(this.props.children, {
                                getLink: this.props.getLink
                            })} */}
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

TerritoryApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    children: PropTypes.element,
    getLink: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
    fetchInstallationsWithGeo: PropTypes.func,
    fetchExploitationsExportFullData: PropTypes.func,
    fetchCities: PropTypes.func,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    fetchManagementUnits: PropTypes.func,
    setFormationPath: PropTypes.func,
    setHelpLink: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationsWithGeo: store.InstallationReducer.installationsWithGeo,
    cities: store.CityReducer.cities,
    managementUnits: store.ManagementUnitReducer.managementUnits,
})

const mapDispatchToProps = {
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchManagementUnitsRestrictions: ManagementUnitAction.fetchManagementUnitsRestrictions,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    fetchExploitationsExportFullData: AgriAction.fetchExploitationsExportFullData,
    fetchInstallationsWithGeo: InstallationAction.fetchInstallationsWithGeo,
    fetchCities: CityAction.fetchCities,
    setFormationPath: HomeAction.setFormationPath,
    setHelpLink: HomeAction.setHelpLink,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryApp)
