import { Step, StepLabel, Stepper } from '@mui/material'
import { push } from 'connected-react-router'
import { deburr, flatten, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import DtoAccountHabilitation from '../../../account/dto/DtoAccountHabilitation'
import Button from '../../../components/forms/Button'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import RadioButtons from '../../../components/forms/RadioButtons'
import Textarea from '../../../components/forms/Textarea'
import CartographyPanel from '../../../components/map/CartographyPanel'
import SelectEmailCms from '../../../components/modal/SelectEmailCms'
import Row from '../../../components/react/Row'
import DistributionUnitAction from '../../../distributionUnit/actions/DistributionUnitAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import CmsAction from '../../../events/actions/CmsAction'
import {
    DESCRIPTION,
    LOCATION_SELECT,
    MAIL_SEND,
    MANDATORY_FIELDS,
    TYPE_SELECT,
} from '../../../events/constants/CMSEventConstants'
import CMSCategoryDto from '../../../events/dto/CMSCategoryDto'
import HomeAction from '../../../home/actions/HomeAction'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import DtoHydrologicalContributorLink from '../../../hydrometry/dto/DtoHydrologicalContributorLink'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import InstallationAction from '../../../installation/actions/InstallationAction'
import DtoCaptureDownstreamDistribUnit from '../../../installation/components/capture/dto/DtoCaptureDownstreamDistribUnit'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoPiezometerContributorLink from '../../../piezometry/dto/DtoPiezometerContributorLink'
import DtoPiezometerWithCampaignsAndEvents from '../../../piezometry/dto/DtoPiezometerWithCampaignsAndEvents'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import DtoPluviometerContributorLink from '../../../pluviometry/dto/DtoPluviometerContributorLink'
import DtoPluviometerWithCampaignsAndEvents from '../../../pluviometry/dto/DtoPluviometerWithCampaignsAndEvents'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import ContactGroupDto from '../../../referencial/components/contact/dto/ContactGroupDto'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import StationAction from '../../../station/actions/StationAction'
import { getCmsIcon, getCmsMarker } from '../../../utils/CmsUtils'
import { getDate, getDateWithHour, getHour } from '../../../utils/DateUtil'
import { checkMandatoryFields, onChangeDate, onChangeHour } from '../../../utils/FormUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getLogin } from '../../../utils/SettingUtils'
import { execByType } from '../../../utils/StationUtils'
import { removeNullKeys } from '../../../utils/StoreUtils'


class AlertNewCmsModal extends Component {
    state = {
        step: TYPE_SELECT,
        cms: {
            dateDebut: moment().valueOf(),
            dateFin: null,
            withLocation: 'yes',
            projection: 16,
            author: getLogin(),
            status: 2,
            cmsDocument: [],
            login: getLogin(),
            sendMail: true,
            mailReceivers: [],
            mailContacts: [],
            idContactsGroups: [],
            mailContributors: [],
            mailCities: [],
            mailUdi: [],
        },
        station: undefined,
        town: undefined,
        installations: [],
        udi: [],
        searchValue: '',
    }

    componentDidMount = () => {
        this.props.setTitle([
            {
                title: i18n.situation,
                href: '/alert',
            },
            {
                title: i18n.new,
            },
        ])
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        if (!this.props.distributionUnits.length) {
            this.props.fetchDistributionUnits()
        }
        if (!this.props.piezometersWithCampaignsAndEvents.length) {
            this.props.fetchPiezometersWithCampaignsAndEvents()
        }
        if (!this.props.hydrometricStations.length) {
            this.props.fetchPluviometersWithCampaignsAndEvents()
        }
        if (!this.props.pluviometersWithCampaignsAndEvents.length) {
            this.props.fetchHydrometricStations()
        }
        if (!this.props.captureDownstreamDistribUnits.length) {
            this.props.fetchCaptureDownstreamDistribUnits()
        }
        if (!this.props.installations.length) {
            this.props.fetchInstallations()
        }
        this.props.fetchContactsGroups()
        this.props.fetchPluviometersContributors()
        this.props.fetchPiezometersContributors()
        // problème sur la requête ci-dessous. à décommenter lorsqu'elle fonctionnnera
        // this.props.fetchHydrologicalContributors()
        this.props.fetchAllContributorLink()
    }

    checkUserRight = typeCategory => {
        switch (typeCategory) {
            case 1: return !!this.props.accountHabilitations.find(hab => hab.habilitation === 'SITUATION_FLOOD')
            case 2: return !!this.props.accountHabilitations.find(hab => hab.habilitation === 'SITUATION_POLLUTION')
            // add AEP
            default: return true
        }
    }

    onChangeCms = (changes) => this.setState({ cms: removeNullKeys({ ...this.state.cms, ...changes }) })

    stepCmsType = () => {
        const nextStep = () => {
            if (!hasValue(this.state.cms.idCategory)) {
                this.props.toastrError(i18n.selectEventType)
            } else {
                this.setState({ step: DESCRIPTION })
            }
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row className='padding-bottom-3 padding-top-3'>
                    <h5>{i18n.SelectEventTypeToCreate}</h5>
                </Row>
                <Row>
                    {
                        this.props.cmsCategories.filter(({ typeCategory }) => hasValue(typeCategory) && this.checkUserRight(typeCategory)).map(category => (
                            <Row className='padding-bottom-2'>
                                <Checkbox label={<Row className='valign-wrapper'>
                                    {getCmsIcon(category.typeCategory)}
                                    <h6 className='padding-left-1'>{category.title}</h6></Row>}
                                checked={category.id === this.state.cms.idCategory} onChange={v => this.onChangeCms({ idCategory: v ? category.id : null })}
                                />
                            </Row>
                        ))
                    }
                </Row>
                <div style={{ right: '10px', bottom: '0', width: '98%' }}>
                    <Row>
                        <Button className='right' title={i18n.next} onClick={nextStep} />
                        <Button className='right red modal-close' title={i18n.cancel} onClick={() => this.props.navigateTo('/alert')} />
                    </Row>
                </div>
            </div>
        )
    }

    stepDescription = () => {
        const { cms } = this.state
        const nextStep = () => {
            checkMandatoryFields(MANDATORY_FIELDS, cms, () => this.setState({ step: LOCATION_SELECT }))
        }
        const levelOptions = ['blue', 'green', 'orange', 'red'].map((color, index) => ({
            label: <span className={`${color} arrests-level-panel ${color}-text`}>O</span>,
            value: index + 1,
        }))
        const allLevelOptions = [
            {
                label: i18n.none,
                value: -1,
            },
            ...levelOptions,
        ]
        const category = this.props.cmsCategories.find(c => this.state.cms.idCategory === c.id) || {}
        return (
            <div style={{ height: '70%' }}>
                <Row>
                    <div className='col s11'>
                        <h5>{i18n.defineEvent}</h5>
                    </div>
                    <div className='col s1'>
                        {getCmsIcon(category.typeCategory)}
                    </div>
                </Row>
                <Row>
                    <Input col={6} value={getDate(cms.dateDebut)} title={i18n.startDate} onChange={v => onChangeDate(v, v2 => this.onChangeCms({ dateDebut: getDateWithHour(v2, cms.dateDebut).valueOf() }))} />
                    <Input col={6} value={getHour(cms.dateDebut)} title={i18n.startHour} onChange={v => onChangeHour(v, v2 => this.onChangeCms({ dateDebut: getDateWithHour(cms.dateDebut, v2).valueOf() }))} />
                </Row>
                <Row>
                    <Input col={6} value={getDate(cms.dateFin)} title={i18n.endDate} onChange={v => onChangeDate(v, v2 => this.onChangeCms({ dateFin: getDateWithHour(v2, cms.dateFin).valueOf() }))} />
                    <Input col={6} value={getHour(cms.dateFin)} title={i18n.endHour} onChange={v => onChangeHour(v, v2 => this.onChangeCms({ dateFin: getDateWithHour(cms.dateFin, v2).valueOf() }))} />
                </Row>
                <Row>
                    <Input col={12} value={cms.title} title={i18n.title} onChange={v => this.onChangeCms({ title: v })} />
                </Row>
                <Row>
                    <Input col={12} value={cms.subtitle} title={i18n.subTitle} onChange={v => this.onChangeCms({ subtitle: v })} />
                </Row>
                <Row>
                    <RadioButtons
                        col={12}
                        elements={allLevelOptions}
                        selected={hasValue(cms.levelContent) ? cms.levelContent : -1}
                        title={i18n.level}
                        onChange={v => this.onChangeCms({ levelContent: v == -1 ? null : parseInt(v) })}
                    />
                </Row>
                <Row className='padding-bottom-1 height-45p'>
                    <Textarea col={12} value={cms.comment} title={i18n.description} onChange={v => this.onChangeCms({ comment: v })} />
                </Row>
                <div style={{ right: '10px', bottom: '0', width: '98%' }}>
                    <Row>
                        <Button className='right' title={i18n.next} onClick={nextStep} />
                        <Button className='left' title={i18n.previous} onClick={() => this.setState({ step: TYPE_SELECT })} />
                        <Button className='right red modal-close' title={i18n.cancel} onClick={() => this.props.navigateTo('/alert')} />
                    </Row>
                </div>
            </div>
        )
    }

    onChangeTown = town => {
        const udi = town ? this.props.distributionUnits.filter(({ associatedTowns }) => (associatedTowns || []).find(townCode => town.code === townCode)) : []
        const downstreamLink = flatten(udi.map(({ id }) => this.props.captureDownstreamDistribUnits.filter(({ downstreamIdStation }) => id === downstreamIdStation))).map(({ idStation }) => idStation)
        const installations = uniq(downstreamLink).map(id => this.props.installations.find(inst => inst.id === id)).filter(inst => !!inst)
        const cities = [...udi, ...installations].map(({ townCode }) => this.props.cities.find(({ code }) => code === townCode)).filter(c => !!c)
        const mailCities = uniq(cities.map(({ email }) => email))
        this.setState(({ cms }) => ({ town, udi, installations, cms: { ...cms, mailCities } }))
    }

    updateMailContact = (linkContacts) => {
        const mailContacts = uniqBy(linkContacts.map(lc => lc.contactCode).map(code => {
            return this.props.contacts.find(c => c.code === code)
        }).filter(c => c?.email).map(c => c.email))
        this.setState({ cms: removeNullKeys({ ...this.state.cms, mailContacts }) })
    }

    onGetStation = station => {
        this.setState({ station })
        const emailContributors = this.props.contributors.filter(c => c.email)
        if (station) {
            const contributors = execByType(station.typeName, {
                // hydrometry: () => this.props.hydrologicalContributors.filter(c => c.idStation === station.id).map(c => emailContributors.find(a => c.idContributor === a.id)),
                pluviometry: () => this.props.pluviometersContributors.filter(c => c.idStation === station.id).map(c => emailContributors.find(a => c.idContributor === a.id)),
                piezometry: () => this.props.piezometersContributors.filter(c => c.idStation === station.id).map(c => emailContributors.find(a => c.idContributor === a.id)),
                default: () => [],
            })
            execByType(station.typeName, {
                hydrometry: () => this.props.fetchHydrometricStation(station.id).then(() => this.updateMailContact(this.props.hydrometricStation.link_contacts)),
                pluviometry: () => this.props.fetchPluviometer(station.id).then(() => this.updateMailContact(this.props.pluviometer.link_contacts)),
                piezometry: () => this.props.fetchPiezometer(station.id).then(() => this.updateMailContact(this.props.piezometer.link_contacts)),
            })
            this.setState({ cms: removeNullKeys({ ...this.state.cms, mailContributors: uniqBy(contributors.filter(c => c && c.email).map(c => c.email)) }) })
        } else {
            this.setState({ cms: { ...this.state.cms, mailContacts: [] } })
        }
    }

    stepLocation = () => {
        const {
            cms,
            town,
            udi,
            station,
        } = this.state
        const category = this.props.cmsCategories.find(c => cms.idCategory === c.id) || {}
        return (
            <div>
                <div className='row'>
                    <CartographyPanel
                        layers={['STATIONS_POINTS']}
                        componentType='all'
                        fullScreenable={false}
                        stationsPoints={[...this.props.sites, { ...cms, typeName: 'cms', markerIcon: getCmsMarker(cms, category) }]}
                        height={window.innerHeight * 0.55}
                        onClickMap={coords => this.onChangeCms({ x: coords[0], y: coords[1] })}
                        getTownObjects={this.onChangeTown}
                        getStation={this.onGetStation}
                    />
                </div>
                {
                    town && (
                        <Row>
                            {`${i18n.city}: [${town.code}] ${town.name}`}
                        </Row>
                    )
                }
                {
                    station && station.code && (
                        <Row>
                            {`${i18n.station}: [${station.code}] ${station.name || ''}`}
                        </Row>
                    )
                }
                {
                    !station || !station.code && (
                        <Row>
                            {`${i18n.station}: ${i18n.none}`}
                        </Row>
                    )
                }
                {
                    udi.length !== 0 && (
                        <Row>
                            {`UDI: ${udi.map(({ code, name }) => `[${code}] ${name}`).join(', ')}`}
                        </Row>
                    )
                }
                <div style={{ right: '10px', bottom: '0', width: '98%' }}>
                    <Row className='padding-bottom-1 padding-top-5'>
                        <Button className='right' title={i18n.next} onClick={() => this.setState({ step: MAIL_SEND })} />
                        <Button className='left' title={i18n.previous} onClick={() => this.setState({ step: DESCRIPTION })} />
                        <Button className='right red modal-close' title={i18n.cancel} onClick={() => this.props.navigateTo('/alert')} />
                    </Row>
                </div>
            </div>
        )
    }

    filterValidSearch = (list) => {
        const search = deburr(this.state.searchValue.toLowerCase())
        return uniqBy(list
            .filter(c => hasValue(c.name) && hasValue(c.email))
            .filter(c => !c.noNewsLetterDate && !c.noNewLetterLogin)
            .filter(c => deburr(`${c.name} (${c.email})`.toLowerCase()).includes(search)),
        'email')
    }

    sendMail = () => {
        const { cms } = this.state
        this.props.fetchContactsGroupsAlert(cms.idContactsGroups)
            .then(groups => {
                const mailContactsGroups = uniq(flatten(groups.map(g => g.contacts)).map(contact => contact.email).filter(mail => mail))
                const { mailReceivers, mailContacts, mailContributors, mailUdi, mailCities, ...dataCms } = cms
                const newCms = {
                    ...dataCms,
                    mailReceivers: uniq([
                        ...mailContactsGroups,
                        ...mailReceivers,
                        ...mailContacts,
                        ...mailContributors,
                        ...mailUdi,
                        ...mailCities,
                    ]),
                }
                this.props.updateCMSEvent(newCms, true, false).then(() => $('.modal').modal('close'))
                this.props.navigateTo('/alert')
            })
    }

    stepSendMail = () => {
        const { cms, udi, installations, searchValue } = this.state
        const udiContributors = flatten(udi.map(({ contributors }) => contributors)).map(({ idContributor }) => idContributor)
        const installationContributors = flatten(installations.map(({ id }) => this.props.contributorLinks.filter(({ idStation }) => idStation === id))).map(({ idContributor }) => idContributor)
        const contributors = uniq([...udiContributors, ...installationContributors]).map(id => this.props.contributors.find(({ code }) => code === id)).filter(c => !!c)
        return (
            <div>
                <Input
                    style={{ width: '40%' }}
                    title={ i18n.search }
                    value={ searchValue }
                    onChange={ v => this.setState({ searchValue: v })}
                />
                <div className='scrollable-card'>
                    <SelectEmailCms
                        mailContacts={cms.mailContacts}
                        mailContributors={cms.mailContributors}
                        mailCities={cms.mailCities}
                        mailReceivers={cms.mailReceivers}
                        idContactsGroups={cms.idContactsGroups}
                        mailUdi={cms.mailUdi}
                        onChangeEmails={mails => this.onChangeCms(mails)}
                        contacts={this.filterValidSearch(this.props.contacts)}
                        contactsGroups={this.props.contactsGroups}
                        contributors={this.filterValidSearch(this.props.contributors)}
                        udiContributors={this.filterValidSearch(contributors)}
                        cities={this.filterValidSearch(this.props.cities)}
                    />
                </div>
                <div className='' style={{ right: '10px', bottom: '0', width: '98%' }}>
                    <Row className='padding-bottom-1'>
                        <Button className='right' title={i18n.register} onClick={() => this.sendMail() } />
                        <Button className='left' title={i18n.previous} onClick={() => this.setState({ step: LOCATION_SELECT })} />
                        <Button className='right red modal-close' title={i18n.cancel} onClick={() => this.props.navigateTo('/alert')} />
                    </Row>
                </div>
            </div>
        )
    }

    render = () => {
        const {
            step,
        } = this.state
        return (
            <div className='content-page fullscreen' style={{ paddingBottom: '100px' }}>
                <div className='card padding-top-1 padding-bottom-1'>
                    <Stepper
                        activeStep={step}
                        style={{ zoom: '1.5' }}
                    >
                        <Step
                            className='clickable zoom'
                            onClick={() => {
                                this.setState({ step: TYPE_SELECT })
                            }}
                        >
                            <StepLabel>{i18n.type}</StepLabel>
                        </Step>
                        <Step
                            className='clickable'
                            onClick={() => {
                                if (!hasValue(this.state.cms.idCategory)) {
                                    this.props.toastrError(i18n.selectEventType)
                                } else {
                                    this.setState({ step: DESCRIPTION })
                                }
                            }}
                        >
                            <StepLabel>{i18n.description}</StepLabel>
                        </Step>
                        <Step
                            className='clickable'
                            onClick={() => {
                                if (!hasValue(this.state.cms.idCategory)) {
                                    this.props.toastrError(i18n.selectEventType)
                                } else {
                                    checkMandatoryFields(MANDATORY_FIELDS, this.state.cms, () => this.setState({ step: LOCATION_SELECT }))
                                }
                            }}
                        >
                            <StepLabel>{i18n.location}</StepLabel>
                        </Step>
                        <Step
                            className='clickable'
                            onClick={() => {
                                if (!hasValue(this.state.cms.idCategory)) {
                                    this.props.toastrError(i18n.selectEventType)
                                } else {
                                    checkMandatoryFields(MANDATORY_FIELDS, this.state.cms, () => this.setState({ step: MAIL_SEND }))
                                }
                            }}
                        >
                            <StepLabel>{i18n.email}</StepLabel>
                        </Step>
                    </Stepper>
                    <div className='row no-margin'>
                        <div className='col s12' style={{ position: 'relative' }}>
                            <div>
                                {step === TYPE_SELECT && this.stepCmsType()}
                                {step === DESCRIPTION && this.stepDescription()}
                                {step === LOCATION_SELECT && this.stepLocation()}
                                {step === MAIL_SEND && this.stepSendMail()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AlertNewCmsModal.propTypes = {
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    distributionUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoDistributionUnit)),
    piezometersWithCampaignsAndEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerWithCampaignsAndEvents)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    pluviometersWithCampaignsAndEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerWithCampaignsAndEvents)),
    captureDownstreamDistribUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoCaptureDownstreamDistribUnit)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contributorLinks: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerContributorLink)),
    pluviometersContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerContributorLink)),
    piezometersContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerContributorLink)),
    hydrologicalContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrologicalContributorLink)),
    updateCMSEvent: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchDistributionUnits: PropTypes.func,
    toastrError: PropTypes.func,
    sites: PropTypes.arrayOf(PropTypes.object),
    navigateTo: PropTypes.func,
    fetchPiezometersWithCampaignsAndEvents: PropTypes.func,
    fetchPluviometersWithCampaignsAndEvents: PropTypes.func,
    fetchHydrometricStations: PropTypes.func,
    setTitle: PropTypes.func,
    fetchCaptureDownstreamDistribUnits: PropTypes.func,
    fetchInstallations: PropTypes.func,
    fetchAllContributorLink: PropTypes.func,
    fetchPluviometersContributors: PropTypes.func,
    fetchPiezometersContributors: PropTypes.func,
    fetchHydrologicalContributors: PropTypes.func,
    fetchContactsGroups: PropTypes.func,
    fetchContactsGroupsAlert: PropTypes.func,
    contactsGroups: PropTypes.arrayOf(PropTypes.instanceOf(ContactGroupDto)),
    fetchHydrometricStation: PropTypes.func,
    fetchPluviometer: PropTypes.func,
    fetchPiezometer: PropTypes.func,
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
}

const mapStateToProps = store => ({
    cmsCategories: store.EventsReducer.cmsCategories,
    contacts: store.ContactReducer.contacts,
    contactsGroups: store.ContactReducer.contactsGroups,
    contributors: store.ContributorReducer.contributors,
    cities: store.CityReducer.cities,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    sites: [
        ...store.PiezometryReducer.piezometersWithCampaignsAndEvents,
        ...store.HydrometryReducer.hydrometricStations,
        ...store.PluviometryReducer.pluviometersWithCampaignsAndEvents,
    ],
    piezometersWithCampaignsAndEvents: store.PiezometryReducer.piezometersWithCampaignsAndEvents,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    pluviometersWithCampaignsAndEvents: store.PluviometryReducer.pluviometersWithCampaignsAndEvents,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    captureDownstreamDistribUnits: store.DistributionUnitReducer.captureDownstreamDistribUnits,
    installations: store.InstallationReducer.installations,
    contributorLinks: store.InstallationReducer.contributorLinks,
    pluviometersContributors: store.PluviometryReducer.pluviometersContributors,
    piezometersContributors: store.PiezometryReducer.piezometersContributors,
    hydrologicalContributors: store.HydrometryReducer.hydrologicalContributors,
    hydrometricStation: store.HydrometryReducer.hydrometricStation,
    pluviometer: store.PluviometryReducer.pluviometer,
    piezometer: store.StationReducer.piezometer,
})

const mapDispatchToProps = {
    updateCMSEvent: CmsAction.updateCMSEvent,
    fetchContributors: ContributorAction.fetchContributors,
    fetchDistributionUnits: DistributionUnitAction.fetchDistributionUnits,
    toastrError: ToastrAction.error,
    navigateTo: push,
    fetchPiezometersWithCampaignsAndEvents: PiezometryAction.fetchPiezometersWithCampaignsAndEvents,
    fetchPluviometersWithCampaignsAndEvents: PluviometryAction.fetchPluviometersWithCampaignsAndEvents,
    fetchHydrometricStations: HydrometryAction.fetchHydrometricStations,
    setTitle: HomeAction.setTitle,
    fetchCaptureDownstreamDistribUnits: DistributionUnitAction.fetchCaptureDownstreamDistribUnits,
    fetchInstallations: InstallationAction.fetchInstallations,
    fetchAllContributorLink: InstallationAction.fetchAllContributorLink,
    fetchPluviometersContributors: PluviometryAction.fetchPluviometersContributors,
    fetchPiezometersContributors: PiezometryAction.fetchPiezometersContributors,
    fetchHydrologicalContributors: HydrometryAction.fetchHydrologicalContributors,
    fetchContactsGroups: ContactAction.fetchContactsGroups,
    fetchContactsGroupsAlert: ContactAction.fetchContactsGroupsAlert,
    fetchHydrometricStation: HydrometryAction.fetchHydrometricStation,
    fetchPluviometer: PluviometryAction.fetchPluviometer,
    fetchPiezometer: StationAction.fetchPiezometer,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertNewCmsModal)
