import Icon from 'components/icon/Icon'
import Row from 'components/react/Row'
import { ButtonMUI } from 'components/styled/Buttons'
import { push } from 'connected-react-router'
import { differenceBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { createRef, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { getReferencialData } from 'referencial/util/ReferencialUtils'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { searchAllCharacters } from 'utils/StringUtil'
import Card from '../../../../components/card/Card'
import SelectionTable from '../../../../components/datatable/SelectionTable'
import Table from '../../../../components/datatable/Table'
import Input from '../../../../components/forms/Input'
import ContributorAction from '../actions/ContributorAction'
import ContributorDto from '../dto/ContributorDto'
import ContributorGroupDto from '../dto/ContributorGroupDto'

const headers = ['name', 'email', 'phoneTel', 'siret', 'status']
const truncateNb = 40

const FilterPanel = ({
    code,
    group,
    setGroup,
    isEdit,
    setSelectedData,
    setFilter,
}) => {
    const {
        contributors,
        contributorsGroup,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        contributorsGroup: store.ContributorReducer.contributorsGroup,
    }), shallowEqual)

    const [tmpFilter, setTmpFilter] = useState({ searchValue: '' })

    const myRef = createRef()

    const dispatch = useDispatch()

    const handleChangeSiren = (value) => {
        const siren = value?.replace(/\D/g, '')
        if (siren?.length > 0) {
            setGroup({ ...group, siren })
        } else {
            myRef.current.value = ''
        }
    }

    const synchronizeContributors = () => {
        if (!group.siren || group.siren.length !== 9) {
            dispatch(ToastrAction.warning(i18n.youNeedFillSirenToSync))
        } else {
            const contributorsFiltered = contributors.filter(c => c.siret?.substring(0, 9) === group.siren)
            setSelectedData(getReferencialData(contributorsFiltered))
        }
    }

    return (
        <Card title={code === 'new' ? i18n.newGroup : contributorsGroup.name} noMargin={false} className='margin-1' >
            <div className='row padding-1 no-margin'>
                <Input
                    col={3}
                    title={i18n.nameGroup}
                    value={group.name}
                    disabled={!isEdit}
                    onChange={value => setGroup({ ...group, name: value })}
                    obligatory
                />
                <Input
                    col={3}
                    title={i18n.siren}
                    value={group.siren}
                    maxLength={9}
                    disabled={!isEdit}
                    onChange={handleChangeSiren}
                    otherInputProps={{ ref: myRef }}
                />
                <Icon
                    size='small'
                    icon='sync'
                    onClick={synchronizeContributors}
                    style={{ marginTop: '1rem' }}
                    tooltip={i18n.automaticContributorsSync}
                />
                {isEdit && (
                    <div className='margin-top-1'>
                        <Input
                            col={8}
                            title={ i18n.search }
                            value={ tmpFilter.searchValue }
                            onChange={(v) => setTmpFilter({ ...tmpFilter, searchValue: v })}
                            onEnterKeyPress={() => setFilter(tmpFilter)}
                        />
                        <Row>
                            <ButtonMUI
                                variant='contained'
                                className='right'
                                style={{
                                    margin: 10,
                                }}
                                onClick={() => setFilter(tmpFilter)}
                            >
                                {i18n.search}
                            </ButtonMUI>
                            <ButtonMUI
                                variant='outlined'
                                className='right'
                                style={{
                                    margin: 10,
                                }}
                                onClick={() => {
                                    setFilter({ searchValue: '' })
                                    setTmpFilter({ searchValue: '' })
                                }}
                            >
                                {i18n.reinit}
                            </ButtonMUI>
                        </Row>
                    </div>
                )}
            </div>
        </Card>
    )
}

FilterPanel.propTypes = {
    code: PropTypes.string,
    group: PropTypes.shape({
        name: PropTypes.string,
        siren: PropTypes.string,
    }),
    setGroup: PropTypes.func,
    isEdit: PropTypes.bool,
    setSelectedData: PropTypes.func,
    setFilter: PropTypes.func,
}

const ContributorGroupApp = ({
    match: { params: { code } },
}) => {
    const {
        contributors,
        contributorsGroup,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        contributorsGroup: store.ContributorReducer.contributorsGroup,
    }), shallowEqual)

    const [group, setGroup] = useState({})
    const [selectedData, setSelectedData] = useState([])
    const [dataContributors, setDataContributors] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [filter, setFilter] = useState({ searchValue: '' })

    const dispatch = useDispatch()

    const getHash = (contributor) => searchAllCharacters(headers.map(key => contributor[key]))

    const getFilteredData = (data) => {
        const includesValue = searchAllCharacters(filter.searchValue || '')
        return data.filter(i => getHash(i).includes(includesValue))
    }

    const onAdd = (contributor) => {
        if (!selectedData.includes(contributor)) {
            setSelectedData([...selectedData, contributor])
        }
    }

    const addAll = () => {
        setSelectedData(uniqBy([...selectedData, ...getFilteredData(contributors)], 'id'))
    }

    const onDelete = (contributor) => {
        if (selectedData.map(s => s.id).includes(contributor.id)) {
            setSelectedData(selectedData.filter(e => e.id !== contributor.id))
        }
    }

    const deleteAll = () => {
        if (filter.searchValue) {
            setSelectedData(differenceBy(selectedData, getFilteredData(selectedData), 'id'))
        } else {
            setSelectedData([])
        }
    }

    useActions(() => {
        if (isEdit) {
            return {
                save: () => {
                    const groupFormatted = {
                        name: group.name,
                        siren: group.siren,
                        contributors: selectedData.map(contributor => new ContributorDto(contributor)),
                    }
                    if (code === 'new') {
                        dispatch(ContributorAction.createContributorsGroup(groupFormatted))
                    } else {
                        dispatch(ContributorAction.setContributorsGroup({ ...groupFormatted, groupCode: parseInt(code) })).then(() => {
                            setIsEdit(false)
                            setGroup(groupFormatted)
                            setDataContributors(selectedData)
                        })
                    }
                },
                cancel: () => {
                    if (code !== 'new') {
                        deleteAll()
                        setIsEdit(false)
                    } else {
                        dispatch(push('/referencial/contributor'))
                    }
                },
            }
        }
        return {
            edit: () => {
                setIsEdit(true)
                setSelectedData(dataContributors)
            },
            delete: () => {
                dispatch(ContributorAction.deleteContributorsGroup(code))
                dispatch(push('/referencial/contributor'))
            },
        }
    }, [code, isEdit, selectedData])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: 'referencial',
                },
                {
                    title: i18n.contributorsGroups,
                    href: 'referencial/contributor',
                },
                {
                    title: i18n.newGroup,
                    href: 'referencial/contributorsgroups',
                },
            ]
        }
        return [
            {
                title: i18n.referencial,
                href: 'referencial',
            },
            {
                title: i18n.contributorsGroups,
                href: 'referencial/contributor',
            },
            {
                title: contributorsGroup.name,
                href: 'referencial/contributorsgroups',
            },
        ]
    }, [contributorsGroup.name])

    useEffect(() => {
        if (code === 'new') {
            setGroup({ name: i18n.newGroup })
            setIsEdit(true)
        } else {
            dispatch(ContributorAction.fetchContributorsGroup(code)).then(returnedGroup => {
                const groupContributorsFormatted = getReferencialData(returnedGroup.contributors)
                setGroup(returnedGroup)
                setDataContributors(groupContributorsFormatted)
                setSelectedData(groupContributorsFormatted)
            })
        }

        if (!contributors.length && isEdit) {
            dispatch(ContributorAction.fetchContributors())
        }
    }, [])

    const getTruncateDatas = (datas) => datas.map(d => ({
        ...d,
        name: d?.name?.length > truncateNb ? `${d?.name?.substring(0, truncateNb)}...` : d?.name,
        email: d?.email?.length > truncateNb ? `${d?.email?.substring(0, truncateNb)}...` : d?.email,
    }))

    const getDataSelectionTable = () => {
        const contribFormatted = getReferencialData(contributors)
        const contribDifferentiated = differenceBy(contribFormatted, selectedData, 'id')
        const contribFiltered = getFilteredData(contribDifferentiated)
        return getTruncateDatas(contribFiltered)
    }

    const data = useMemo(() => dataContributors.map((value) => ({
        ...value,
        headers,
    })), [dataContributors])

    return (
        <div className='no-margin'>
            <FilterPanel
                code={code}
                group={group}
                setGroup={setGroup}
                isEdit={isEdit}
                setSelectedData={setSelectedData}
                setFilter={setFilter}
            />
            {isEdit ? (
                <div className='row margin-1 padding-top-1'>
                    <SelectionTable
                        listTitle={i18n.contributors}
                        listData={getDataSelectionTable() }
                        listHeaders={headers}
                        selectedHeaders={headers}
                        selectedData={getTruncateDatas(getFilteredData(selectedData))}
                        selectedTitle={i18n.groupMembers}
                        onAdd={(contributor) => onAdd(contributor)}
                        addAll={() => addAll()}
                        deleteAll={() => deleteAll()}
                        onDelete={contributor => onDelete(contributor)}
                        nbPerPage={nbPerPageLabelShort}
                    />
                </div>
            ) : (
                <div className='margin-top-1 margin-right-1 margin-left-1'>
                    <Table
                        title={i18n.groupMembers}
                        data={data}
                        exportData={data.map(d => ({
                            ...d,
                            siret: { value: d.siret, cellType: 'string' },
                        }))}
                        exportName={group.name}
                        type={{ headers }}
                        padding
                        sortable
                        initialSort={{ column: 'name', sort: 'asc' }}
                        link='referencial/contributor'
                        exportButtonOnHeader
                    />
                </div>
            )}
        </div>
    )
}

ContributorGroupApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    contributorsGroup: PropTypes.instanceOf(ContributorGroupDto),
    fetchContributors: PropTypes.func,
    fetchContributorsGroup: PropTypes.func,
    createContributorsGroup: PropTypes.func,
    setContributorsGroup: PropTypes.func,
    deleteContributorsGroup: PropTypes.func,
    setTitle: PropTypes.func,
    warning: PropTypes.func,
    push: PropTypes.func,
}

export default ContributorGroupApp