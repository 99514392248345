import { Button, Dialog, DialogContent, Step, StepLabel, Stepper } from '@mui/material'
import SettingDto from 'administration/dto/SettingDto'
import NumberField from 'components/forms/NumberField'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { push } from 'connected-react-router'
import DtoExploitationMatRep from 'exploitations/dto/DtoExploitationMatRep'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import { getSetting, getSettingInt } from 'utils/SettingUtils'
import WaitAction from 'wait/WaitAction'
import Card from '../../../components/card/Card'
import Select from '../../../components/forms/Select'
import Row from '../../../components/react/Row'
import DtoInstallationWithGeoItem from '../../../installation/components/installations/dto/DtoInstallationWithGeoItem'
import VariousMaterielAction from '../../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoVariousMaterielSituation from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import VariousMaterielDto from '../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoExploitation from '../../dto/DtoExploitation'
import CounterPanel from '../panels/CounterPanel'
import PumpPanel from '../panels/PumpPanel'

const STEP_POINT = 1
const STEP_PUMP = 2
const STEP_COUNTER = 3

class MaterielStepperModal extends Component {
    constructor(props) {
        super(props)
        const { point, pump, counter, situationPump, situationCounter, linkMaterielsRep, exploitation } = props
        const repPump = point && pump ? linkMaterielsRep.find((l) => l.linkType === 1 && l.idElement1 === point.id && l.idElement2 === pump.id) : null
        const repCounter = pump && counter ? linkMaterielsRep.find((l) => l.linkType === 2 && l.idElement1 === pump.id && l.idElement2 === counter.id) : null
        this.state = {
            situationPump: new DtoVariousMaterielSituation({ ...situationPump, siteType: 7, siteCode: point ? point.id : null, idVarious: pump ? pump.id : null, statusCode: 1 }),
            situationCounter: new DtoVariousMaterielSituation({ ...situationCounter, siteType: 8, siteCode: pump ? pump.id : null, idVarious: counter ? counter.id : null, statusCode: 1 }),
            repPump: repPump || new DtoExploitationMatRep({ idExploitation: exploitation.idExploitation, linkType: 1, idElement1: point ? point.id : null, idElement2: pump ? pump.id : null, repartition: null }),
            repCounter: repCounter || new DtoExploitationMatRep({ idExploitation: exploitation.idExploitation, linkType: 2, idElement1: pump ? pump.id : null, idElement2: counter ? counter.id : null, repartition: null }),
            point: point || {},
            initialPump: pump || {},
            pump: pump || {},
            initialCounter: counter || {},
            counter: counter || {},
            step: props.step || STEP_POINT,
            linkMaterielsRep,
        }
    }

    componentDidMount() {
        const { applicationSettings, variousMateriels, disabled, pump, counter } = this.props
        const pumps = variousMateriels.filter((m) => m.pump)
        const counters = variousMateriels.filter((m) => m.counter)
        if (['True', '1', 'OUI', true].includes(getSetting(applicationSettings, 'useAutomaticCodificationPump') || false)) {
            const prefixCodificationPump = getSetting(applicationSettings, 'prefixCodificationPump') || ''
            const suffixCodificationPump = getSetting(applicationSettings, 'suffixCodificationPump') || ''
            const minLengthCodificationPump = getSettingInt(applicationSettings, 'minLengthCodificationPump') || 0
            const maxRefPump = Math.max(...pumps.map((p) => {
                if (p?.reference?.startsWith(prefixCodificationPump) && p?.reference?.endsWith(suffixCodificationPump)) {
                    const str = p?.reference?.replace(prefixCodificationPump, '') || ''
                    return parseInt(str.slice(0, str.length - suffixCodificationPump.length)) || 0
                }
                return 0
            })) + 1
            const newId = maxRefPump.toString().length >= minLengthCodificationPump ? maxRefPump : `${'0'.repeat(minLengthCodificationPump - maxRefPump.toString().length)}${maxRefPump}`
            const newRefPump = disabled ? '' : `${prefixCodificationPump}${newId}${suffixCodificationPump}`
            this.setState({
                initialPump: pump || { reference: newRefPump },
                pump: pump || { reference: newRefPump },
            })
        }
        if (['True', '1', 'OUI', true].includes(getSetting(applicationSettings, 'useAutomaticCodificationCounter') || false)) {
            const prefixCodificationCounter = getSetting(applicationSettings, 'prefixCodificationCounter') || ''
            const suffixCodificationCounter = getSetting(applicationSettings, 'suffixCodificationCounter') || ''
            const minLengthCodificationCounter = getSettingInt(applicationSettings, 'minLengthCodificationCounter') || 0
            const maxRefCounter = Math.max(...counters.map((c) => {
                if (c?.reference?.startsWith(prefixCodificationCounter) && c?.reference?.endsWith(suffixCodificationCounter)) {
                    const str = c?.reference?.replace(prefixCodificationCounter, '') || ''
                    return parseInt(str.slice(0, str.length - suffixCodificationCounter.length)) || 0
                }
                return 0
            })) + 1
            const newId = maxRefCounter.toString().length >= minLengthCodificationCounter ? maxRefCounter : `${'0'.repeat(minLengthCodificationCounter - maxRefCounter.toString().length)}${maxRefCounter}`
            const newRefCounter = disabled ? '' : `${prefixCodificationCounter}${newId}${suffixCodificationCounter}`
            this.setState({
                initialCounter: counter || { reference: newRefCounter },
                counter: counter || { reference: newRefCounter },
            })
        }
    }

    onCancel = () => {
        this.resetState()
        this.props.onCancel()
    }

    onValidate = () => {
        const { pump, initialPump } = this.state
        const { exploitation } = this.props
        if (!pump.id && pump.reference && Object.keys(pump).length > 1) {
            this.props.createVariousMateriel({ ...pump, administrator: exploitation.operatorCode, pump: pump.pump || {}, isShared: !!pump.isShared }, 'noRedirection').then((pumpId) => {
                this.validateCounter(pumpId, true)
            })
        } else if (!isEqual(pump, initialPump)) {
            this.props.updateVariousMateriel(pump).then(() => {
                this.validateCounter(pump.id, true)
            })
        } else {
            this.validateCounter(pump.id)
        }
    }

    validateCounter = (pumpId, updatePump = false) => {
        const { counter, initialCounter } = this.state
        const { exploitation } = this.props
        if (counter.reference && Object.keys(counter).length > 1) {
            if (!counter.id) {
                this.props.createVariousMateriel({ ...counter, administrator: exploitation.operatorCode, counter: counter.counter || {}, isShared: !!counter.isShared }, 'noRedirection').then((counterId) => {
                    this.saveSituation(true, pumpId, counterId)
                })
            } else if (!isEqual(counter, initialCounter)) {
                this.props.updateVariousMateriel(counter).then(() => {
                    this.saveSituation(true, pumpId, counter.id)
                })
            } else {
                this.saveSituation(updatePump, pumpId, counter.id)
            }
        } else {
            this.saveSituation(updatePump, pumpId)
        }
    }

    saveSituation = (fetchMateriels, pumpId, counterId) => {
        const { point, pump, situationPump, situationCounter, linkMaterielsRep, repPump, repCounter } = this.state
        const updatedPumpSitu = {
            ...situationPump,
            siteName: point.code,
            idVarious: pumpId,
        }
        const updatedCounterSitu = counterId ? {
            ...situationCounter,
            siteCode: pumpId,
            siteName: pump.reference,
            idVarious: counterId,
        } : {}
        const newLinkPumpRep = [
            ...linkMaterielsRep.filter((l) => !(l.linkType === 1 && l.idElement1 === situationPump.siteCode && l.idElement2 === pumpId)),
            {
                ...repPump,
                idElement1: situationPump.siteCode,
                idElement2: pumpId,
            },
        ]
        const newLinkMatRep = (counterId ? [
            ...newLinkPumpRep.filter((l) => !(l.linkType === 2 && l.idElement1 === pumpId && l.idElement2 === counterId)),
            {
                ...repCounter,
                idElement1: pumpId,
                idElement2: counterId,
            },
        ] : newLinkPumpRep).filter((r) => r.idElement1 && r.idElement2)
        if (fetchMateriels) {
            this.props.waitStart()
            this.props.fetchVariousMateriels().then(() => {
                this.props.waitStop()
                this.props.onValidate(updatedPumpSitu, updatedCounterSitu, newLinkMatRep)
            })
        } else {
            this.props.onValidate(updatedPumpSitu, updatedCounterSitu, newLinkMatRep)
        }
    }

    getHeader = () => {
        const { step } = this.state
        return (
            <Stepper
                activeStep={step - 1}
                style={{ zoom: '1.5', height: '30px' }}
            >
                <Step
                    className='clickable'
                    onClick={() => {
                        this.setState({ step: STEP_POINT })
                    }}
                >
                    <StepLabel>{i18n.pointPrelevement}</StepLabel>
                </Step>
                <Step
                    className='clickable'
                    onClick={() => {
                        this.setState({ step: STEP_PUMP })
                    }}
                >
                    <StepLabel>{i18n.pumpingEquipment}</StepLabel>
                </Step>
                <Step
                    className='clickable'
                    onClick={() => {
                        this.setState({ step: STEP_COUNTER })
                    }}
                >
                    <StepLabel>{i18n.countingDevice}</StepLabel>
                </Step>
            </Stepper>
        )
    }

    onSelectPoint = (point) => {
        const { cities } = this.props
        const city = cities.find((c) => c.code === point.townCode) || {}
        this.setState(({ situationPump }) => ({
            point: { ...point, city: `${city.code || ''} ${city.name || ''}` },
            situationPump: { ...situationPump, siteCode: point.id },
        }))
    }

    onSelectPump = (pump) => {
        this.setState(({ situationPump, situationCounter }) => ({
            pump,
            initialPump: pump,
            situationPump: { ...situationPump, idVarious: pump.id },
            situationCounter: { ...situationCounter, siteCode: pump.id },
        }))
    }

    onSelectCounter = (counter) => {
        this.setState(({ situationCounter }) => ({
            counter,
            initialCounter: counter,
            situationCounter: { ...situationCounter, idVarious: counter.id },
        }))
    }

    getPointStep = () => {
        const { point } = this.state
        const { disabled, points } = this.props
        return (
            <div className='padding-1'>
                <Row>
                    <Select
                        col={4}
                        id='point'
                        label={i18n.pointPrelevement}
                        value={point.id}
                        options={points}
                        keyValue='id'
                        keyLabel='code'
                        displayWithCode
                        onChange={(_, v) => this.onSelectPoint(v || {})}
                        disabled={disabled}
                    />
                </Row>
                <Card title={`${i18n.pointPrelevement} ${point.code || ''}`}>
                    <div className='padding-1' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                        <Row className='bold'>{point.city || ''}</Row>
                        <Row>{`${point.code ? `${point.code} - ` : ''}${`${point.parcel || ''} ${point.section || ''}${point.location ? ` - ${point.location}` : ''}`}`}</Row>
                    </div>
                </Card>
            </div>
        )
    }

    getPumpStep = () => {
        const { pump, situationPump, repPump } = this.state
        const { disabled, variousMateriels, matsRepartitions } = this.props
        const othersRep = matsRepartitions.filter((r) => r.idElement2 === pump.id && r.linkType === 1 && !isEqual({ ...r, repartition: undefined }, { ...repPump, repartition: undefined }))
        const totalRep = othersRep.reduce((acc, rep) => acc + rep.repartition, 0) + repPump.repartition
        return (
            <div className='padding-1'>
                <Row>
                    <Select
                        col={3}
                        id='pump'
                        label={i18n.pumpingEquipments}
                        value={pump.id}
                        options={variousMateriels.filter((m) => m.pump)}
                        keyValue='id'
                        keyLabel='reference'
                        displayWithCode
                        onChange={(_, v) => this.onSelectPump(v || {})}
                        disabled={disabled}
                    />
                    <SimpleDatePicker
                        col={3}
                        id='pumpSituationDate'
                        label={i18n.pumpCommissioningDate}
                        value={situationPump.situationDate}
                        onChange={date => this.setState({ situationPump: { ...situationPump, situationDate: date } })}
                        max={situationPump.situationEndDate}
                        disabled={disabled}
                    />
                    <SimpleDatePicker
                        col={3}
                        id='pumpSituationEndDate'
                        label={i18n.pumpDecommissioningDate}
                        value={situationPump.situationEndDate}
                        onChange={date => this.setState({ situationPump: { ...situationPump, situationEndDate: date } })}
                        min={situationPump.situationDate}
                        disabled={disabled}
                    />
                    <NumberField
                        col={3}
                        id='distributionPump'
                        title={`${i18n.division} (%)`}
                        value={repPump.repartition}
                        onChange={v => this.setState({ repPump: { ...repPump, repartition: v } })}
                        min={0}
                        max={100}
                        disabled={disabled}
                        className={totalRep > 100 ? 'red' : ''}
                        tooltip={totalRep > 100 ? `${i18n.totalOver100Percent} (${totalRep}%)` : null}
                    />
                </Row>
                <Card title={pump.id ? i18n.change : i18n.create}>
                    <PumpPanel pump={pump} onChange={(obj) => this.setState(() => ({ pump: { ...pump, ...obj } }))} disabled={disabled}/>
                </Card>
            </div>
        )
    }

    getCounterStep = () => {
        const { counter, situationCounter, repCounter } = this.state
        const { disabled, variousMateriels, matsRepartitions } = this.props
        const othersRep = matsRepartitions.filter((r) => r.idElement2 === counter.id && r.linkType === 2 && !isEqual({ ...r, repartition: undefined }, { ...repCounter, repartition: undefined }))
        const totalRep = othersRep.reduce((acc, rep) => acc + rep.repartition, 0) + repCounter.repartition
        return (
            <div className='padding-1'>
                <Row>
                    <Select
                        col={3}
                        id='counter'
                        label={i18n.countingDevice}
                        value={counter.id}
                        options={variousMateriels.filter((m) => m.counter)}
                        keyValue='id'
                        keyLabel='reference'
                        displayWithCode
                        onChange={(_, v) => this.onSelectCounter(v || {})}
                        disabled={disabled}
                    />
                    <SimpleDatePicker
                        col={3}
                        id='counterSituationDate'
                        label={i18n.counterCommissioningDate}
                        value={situationCounter.situationDate}
                        onChange={date => this.setState({ situationCounter: { ...situationCounter, situationDate: date } })}
                        max={situationCounter.situationEndDate}
                        disabled={disabled}
                    />
                    <SimpleDatePicker
                        col={3}
                        id='counterSituationEndDate'
                        label={i18n.counterDecommissioningDate}
                        value={situationCounter.situationEndDate}
                        onChange={date => this.setState({ situationCounter: { ...situationCounter, situationEndDate: date } })}
                        min={situationCounter.situationDate}
                        disabled={disabled}
                    />
                    <NumberField
                        col={3}
                        id='distributionCounter'
                        title={`${i18n.division} (%)`}
                        value={repCounter.repartition}
                        onChange={v => this.setState({ repCounter: { ...repCounter, repartition: v } })}
                        min={0}
                        max={100}
                        disabled={disabled}
                        className={totalRep > 100 ? 'red' : ''}
                        tooltip={totalRep > 100 ? `${i18n.totalOver100Percent} (${totalRep}%)` : null}
                    />
                </Row>
                <Card title={counter.id ? i18n.change : i18n.create}>
                    <CounterPanel counter={counter} onChange={(obj) => this.setState(() => ({ counter: { ...counter, ...obj } }))} disabled={disabled}/>
                </Card>
            </div>
        )
    }

    getStep = () => {
        const { step } = this.state
        switch (step) {
            case STEP_POINT:
                return this.getPointStep()
            case STEP_PUMP:
                return this.getPumpStep()
            case STEP_COUNTER:
                return this.getCounterStep()
            default:
                return null
        }
    }

    onPrevStep = () => {
        this.setState(({ step }) => ({ step: step - 1 }))
    }

    onNextStep = () => {
        this.setState(({ step }) => ({ step: step + 1 }))
    }

    resetState = () => {
        this.setState({ point: {}, pump: {}, counter: {}, situationPump: new DtoVariousMaterielSituation({ siteType: 7 }), situationCounter: new DtoVariousMaterielSituation({ siteType: 8 }) })
    }

    onConsult = () => {
        const { point, step, pump, counter } = this.state
        this.resetState()
        if (step === STEP_POINT && hasValue(point.id)) {
            this.props.push(`/station/installation/${point.id}/dashboard`)
        } else {
            const matId = step === STEP_PUMP ? pump.id : counter.id
            if (hasValue(matId)) {
                this.props.push(`/materiel/variousMateriel/${matId}`)
            }
        }
    }

    render = () => {
        const { open, title, disabled } = this.props
        const { step, point, pump, counter } = this.state
        const valid = pump.reference || counter.reference

        const canConsult = (step === STEP_POINT && hasValue(point.id)) || (step === STEP_PUMP && hasValue(pump.id)) || (step === STEP_COUNTER && hasValue(counter.id))

        return (
            <Dialog
                onClose={this.onCancel}
                fullWidth
                maxWidth='lg'
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title || i18n.updatePointPrel}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCancel} />
                </DialogTitleMUI>
                <DialogContent>
                    <div className='row no-margin' style={{ height: '65vh', overflowY: 'auto' }}>
                        {this.getHeader()}
                        {this.getStep()}
                    </div>
                </DialogContent>
                <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.onCancel} variant='outlined' color='primary'>
                        {disabled ? i18n.close : i18n.cancel}
                    </Button>
                    <Button onClick={this.onPrevStep} variant='outlined' color='primary' disabled={step === STEP_POINT}>
                        {i18n.previous}
                    </Button>
                    <Button onClick={this.onNextStep} variant='outlined' color='primary' disabled={step === STEP_COUNTER}>
                        {i18n.next}
                    </Button>
                    {disabled ? (
                        <Button onClick={this.onConsult} variant='contained' color='primary' disabled={!canConsult}>
                            {i18n.consult}
                        </Button>
                    ) : (
                        <Button onClick={this.onValidate} variant='contained' color='primary' disabled={!valid || disabled}>
                            {i18n.validate}
                        </Button>
                    )}
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

MaterielStepperModal.propTypes = {
    open: PropTypes.bool,
    disabled: PropTypes.bool,
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    point: PropTypes.instanceOf(DtoInstallationWithGeoItem),
    pump: PropTypes.instanceOf(VariousMaterielDto),
    situationPump: PropTypes.instanceOf(DtoVariousMaterielSituation),
    counter: PropTypes.instanceOf(VariousMaterielDto),
    situationCounter: PropTypes.instanceOf(DtoVariousMaterielSituation),
    step: PropTypes.number,
    points: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    linkMaterielsRep: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitationMatRep)),
    matsRepartitions: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitationMatRep)),
    title: PropTypes.string,
    fetchVariousMateriels: PropTypes.func,
    createVariousMateriel: PropTypes.func,
    updateVariousMateriel: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    matsRepartitions: store.AgriReducer.matsRepartitions,
})

const mapDispatchToProps = {
    fetchVariousMateriels: VariousMaterielAction.fetchVariousMateriels,
    createVariousMateriel: VariousMaterielAction.createVariousMateriel,
    updateVariousMateriel: VariousMaterielAction.updateVariousMateriel,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterielStepperModal)
