import React, { useMemo, useState } from 'react'
import logoSandre from 'assets/pictures/logo_sandre.png'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import { v4 as uuidv4 } from 'uuid'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import SieauAction from '../../../../components/sieau/SieauAction'
import { sandreSupportDefaultLink } from '../../../../conf/SieauConstants'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_SUPPORT,
    PATH_REFERENCIAL_SUPPORT_LINK,
    PATH_REFERENCIAL_SUPPORT_NEW,
} from '../../../../home/constants/RouteConstants'
import { getUser } from '../../../../utils/SettingUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import DeleteModal from '../../DeleteModal'
import ReplaceModal from '../../ReplaceModal'
import SupportAction from '../actions/SupportAction'
import SupportDto from '../dto/SupportDto'
import useTitle from 'utils/customHook/useTitle'

const SupportApp = ({ match: { params: { code } } }) => {
    const {
        supportProps,
        supports,
        referencialStatus,
    } = useSelector(store => ({
        supportProps: store.SupportReducer.support,
        supports: store.SupportReducer.supports,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const [support, setSupport] = useState(new SupportDto({}))
    const [isEditMode, setIsEditMode] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => () => dispatch(SupportAction.reset()), [])

    useEffect(() => setSupport(supportProps), [supportProps])

    useEffect(() => {
        if (code !== 'new') {
            dispatch(SupportAction.fetchSupport(code))
            dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.supportCountDelete(code)))
            setIsEditMode(false)
        } else {
            setIsEditMode(true)
        }
        dispatch(ReferencialAction.fetchReferencialStatus())
        dispatch(HomeAction.setHelpLink('', ''))
    }, [code])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.supports,
                    href: PATH_REFERENCIAL_SUPPORT,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_SUPPORT_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.supports,
                href: PATH_REFERENCIAL_SUPPORT,
            },
            {
                title: code + (supportProps.name ? ` - ${supportProps.name}` : ''),
                href: PATH_REFERENCIAL_SUPPORT_LINK + code,
            },
        ]
    }, [code, supportProps])

    const getSupportLinks = () => {
        return [{
            href: sandreSupportDefaultLink + support.code,
            img: logoSandre,
            label: i18n.sandre.toUpperCase(),
        }]
    }

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        if (code === 'new' && (!supportProps || !supportProps.code)) {
            return {
                save: () => {
                    const existCode = supports.find(u => u.code === support.code)
                    if (existCode) {
                        dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                    } else if (!support.code) {
                        dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                    } else {
                        dispatch(SupportAction.createSupport(support))
                        setIsEditMode(false)
                    }
                },
                cancel: () => {
                    dispatch(push(PATH_REFERENCIAL_SUPPORT))
                    setIsEditMode(false)
                },
            }
        }
        if (isEditMode) {
            return {
                save: () => {
                    dispatch(SupportAction.updateSupport(support, support.code))
                    setIsEditMode(false)
                },
                cancel: () => {
                    setSupport(supportProps)
                    setIsEditMode(false)
                },
            }
        }
        return {
            edit: () => {
                setIsEditMode(true)
            },
            deleteCheck: () => {
                dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.supportCountDelete(code)))
                const id = uuidv4()
                const popup = {
                    id,
                    content: <DeleteModal
                        onDelete={ () => dispatch(SupportAction.deleteSupport(support.code)) }
                    />,
                }
                dispatch(SieauAction.setPopup(popup))
            },
            replace: () => {
                const id = uuidv4()
                const popup = {
                    id,
                    content: <ReplaceModal
                        elements={ supports }
                        title={ `${i18n.support} [${support.code}]` }
                        label={ i18n.support }
                        onReplace={ newCode => dispatch(SupportAction.replaceSupport(support.code, newCode.code)) }
                    />,
                }
                dispatch(SieauAction.setPopup(popup))
            },
            links: getSupportLinks(),
        }
    }, [isConsultant, code, supportProps, support, isEditMode])

    const disabled = useMemo(() => ({
        active: isEditMode,
        disabled: !isEditMode,
    }), [isEditMode])

    const activeIcon = useMemo(() => support.status === '3' ? <i className='material-icons'>lock</i> : null, [support.status])

    return (
        <div className='row no-margin'>
            <div id='file' className='col s12'>
                <div className='card margin-top-0-75-rem'>
                    <div className='card-content'>
                        <div className='row no-margin'>
                            <div className='col s12 m2 l2' />
                            <div className='col s12 m8 l8'>
                                <div className='row no-margin padding-top-3-px'>
                                    <Input
                                        col={ 3 }
                                        title={ i18n.sandreCode }
                                        value={ support.code }
                                        onChange={ newCode => setSupport({ ...support, code: newCode }) }
                                        maxLength={ 5 }
                                        disabled={ code !== 'new' }
                                        obligatory
                                    />
                                    <Select
                                        options={ referencialStatus }
                                        label={ i18n.status }
                                        col={ 2 }
                                        onChange={ status => setSupport({ ...support, status: status?.toString() }) }
                                        value={ parseInt(support.status) }
                                        nullLabel='&nbsp;'
                                        { ...disabled }
                                    />
                                    <Checkbox
                                        col={ 1 }
                                        label={ i18n.active }
                                        checked={ support.actif === '1' }
                                        onChange={ e => setSupport({ ...support, actif: e ? '1' : '0' }) }
                                        { ...disabled }
                                    />
                                    <div className='col s1 padding-top-10-px'>
                                        { activeIcon }
                                    </div>
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Input
                                        col={ 6 }
                                        id='support_name'
                                        title={ i18n.name }
                                        value={ support.name }
                                        onChange={ name => setSupport({ ...support, name }) }
                                        maxLength={ 255 }
                                        { ...disabled }
                                    />
                                    <SimpleDatePicker
                                        onChange={ creationDate => setSupport({ ...support, creationDate }) }
                                        id='creationDate'
                                        label={ i18n.creationDate }
                                        value={ support.creationDate }
                                        col={ 3 }
                                        { ...disabled }
                                        endDate={ support.updateDate }
                                    />
                                    <SimpleDatePicker
                                        onChange={ updateDate => setSupport({ ...support, updateDate }) }
                                        id='updateDate'
                                        label={ i18n.modification }
                                        value={ support.updateDate }
                                        col={ 3 }
                                        { ...disabled }
                                        startDate={ support.creationDate }
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Input
                                        col={ 6 }
                                        title={ i18n.internationalName }
                                        value={ support.internationalName }
                                        onChange={ internationalName => setSupport({ ...support, internationalName }) }
                                        maxLength={ 255 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 6 }
                                        title={ i18n.author }
                                        value={ support.author }
                                        onChange={ author => setSupport({ ...support, author }) }
                                        maxLength={ 50 }
                                        { ...disabled }
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Textarea
                                        col={ 12 }
                                        title={ i18n.comment }
                                        value={ support.comment }
                                        onChange={ comment => setSupport({ ...support, comment }) }
                                        { ...disabled }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SupportApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }),
}

export default SupportApp
