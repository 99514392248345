import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { H_CAMPAIGN_PIEZO_TRACKING } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CampaignTracking from '../../../campaign/components/campaignTracking/CampaignTracking'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import DtoPiezometerLight from '../../dto/DtoPiezometerLight'


class PiezometryCampaignTrackingApp extends Component {
    componentDidMount = () => {
        if (!componentHasHabilitations(H_CAMPAIGN_PIEZO_TRACKING)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.fetchPiezometryCampaignEvent(this.props.match.params.id)
    }

    componentDidUpdate = prevProps => {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.fetchPiezometryCampaignEvent(this.props.match.params.id)
        }
    }

    render = () => (
        <CampaignTracking
            params={this.props.match.params}
            stations={this.props.piezometers}
            stationType='piezometry'
        />
    )
}

PiezometryCampaignTrackingApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    campaign: PropTypes.instanceOf(CampaignDto),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    push: PropTypes.func,
    fetchPiezometryCampaignEvent: PropTypes.func,
}

const mapStateToProps = store => ({
    campaign: store.CampaignReducer.campaign,
    piezometers: store.PiezometryReducer.piezometersLight,
})

const mapDispatchToProps = {
    fetchPiezometryCampaignEvent: CampaignAction.fetchPiezometryCampaignEvent,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryCampaignTrackingApp)
