import { push } from 'connected-react-router'
import { chunk, groupBy, maxBy, minBy, orderBy, range, take } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_STATION_QUALITO_STATES } from '../../../account/constants/AccessRulesConstants'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import MessageCard from '../../../components/card/MessageCard'
import Table from '../../../components/datatable/Table'
import NumberField from '../../../components/forms/NumberField'
import RadioButtons from '../../../components/forms/RadioButtons'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import Row from '../../../components/react/Row'
import SieauAction from '../../../components/sieau/SieauAction'
import ExportAction from '../../../export/actions/ExportAction'
import HomeAction from '../../../home/actions/HomeAction'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../store/AppStore'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { exportModelFile, formatData, getModelFileType } from '../../../utils/ExportDataUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getStateLabels } from '../../../utils/QualityUtils'
import { getUser } from '../../../utils/SettingUtils'
import { getLinks, getStationTitle } from '../../../utils/StationUtils'
import QualityAction from '../../actions/QualityAction'
import DtoQualitometer from '../../dto/DtoQualitometer'
import DtoQualitometerState from '../../dto/states/DtoQualitometerState'
import DtoQualityState from '../../dto/states/DtoQualityState'
import QualitometerStateModal from './QualitometerStateModal'

class QualitometerStatesApp extends ActionComponent {
    constructor(props) {
        super(props)
        const startYear= minBy(props.qualitometerStates, 'startDate')
        this.state = {
            dataLoaded: false,
            startYear: moment(startYear?.startDate).year(),
            endYear: moment().year(),
            openExportModal: false,
            yearChoice: 1,
        }
    }
    componentDidMount() {
        if (!componentHasHabilitations(H_STATION_QUALITO_STATES)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const { id, designation, name, code } = this.props.qualitometer
        this.props.fetchQualitometerStates(id).then(() => {
            const startYear = minBy(this.props.qualitometerStates, 'startDate')
            this.setState({ startYear: moment(startYear?.startDate).year(), dataLoaded: true })
        })
        this.props.setTitle([{
            title: i18n.quality,
            href: 'quality',
        }, {
            title: getStationTitle({ id, code, name, designation }),
            href: `station/quality/${id}`,
        }, {
            title: i18n.states,
            href: `station/quality/${id}'/qualityStates'`,
        }])
        if (!this.props.qualityStates.length) {
            this.props.fetchQualityStates()
        }
        this.setReadMode()
    }

    setReadMode = () => {
        this.setState({ editMode: false })
        if (getUser().admin === '1') {
            this.setActions({
                edit: this.setEditMode,
                exportList: [{
                    onClick: () => this.setState({ openExportModal: true }),
                    label: i18n.excel,
                }],
                links: getLinks(this.props.qualitometer, this.props),
                arrowNav: getStationArrowNav('quality', this.props.qualitometers, this.props.qualitometer.id, s => this.props.push(`/station/quality/${s.id}/qualityStates`)),

            })
        } else {
            this.setActions({
                exportList: [{
                    onClick: () => this.setState({ openExportModal: true }),
                    label: i18n.excel,
                }],
                links: getLinks(this.props.qualitometer, this.props),
                arrowNav: getStationArrowNav('quality', this.props.qualitometers, this.props.qualitometer.id, s => this.props.push(`/station/quality/${s.id}/qualityStates`)),
            })
        }
    }

    setEditMode = () => {
        this.setState({ editMode: true })
        this.setActions({
            cancel: this.setReadMode,
            exportList: [{
                onClick: () => this.setState({ openExportModal: true }),
                label: i18n.excel,
            }],
            new: () => this.setStateModal(true),
            links: getLinks(this.props.qualitometer, this.props),
            arrowNav: getStationArrowNav('quality', this.props.qualitometers, this.props.qualitometer.id, s => this.props.push(`/station/quality/${s.id}/qualityStates`)),
        })
    }

    setStateModal = (isNew, qualitometerState = {}) => {
        const buttons = (
            <div>
                <a id='deleteStates' className='waves-effect waves-teal btn-flat modal-close'>{i18n.delete}</a>
                <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.cancel}</a>
                <a id='saveStates' className='waves-effect waves-teal btn-flat'>{i18n.register}</a>
            </div>
        )
        const popup = {
            id: 'statePopup',
            header: i18n.editState,
            actions: buttons,
            fixedFooter: true,
            dismissible: false,
            content: <QualitometerStateModal isNew={isNew} qualitometerState={qualitometerState} />,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    getExport = () => {
        const data = this.getData()
        const tableData = data[0]
        if (tableData.length) {
            tableData[0].headers = data[1]
        }
        return {
            data: tableData,
            exportType: 'xlsx',
            titleFile: `${this.props.qualitometer.code} - ${i18n.states}`,
        }
    }

    getYearRange = () => {
        const years = take(range(this.state.endYear, this.state.startYear -1), 100)
        if (this.state.yearChoice == 1) {
            return years
        }
        return chunk(years, this.state.yearChoice).map(group => group.map(y => y.toString().slice(2)).join('-'))
    }

    getStateYear = (s, allYears) => {
        if (this.state.yearChoice == 1) {
            return moment(s.startDate).year()
        }
        return allYears.find(y => y.includes(moment(s.startDate).year().toString().slice(2)))
    }


    getData = () => {
        const filterStates = this.props.qualitometerStates.filter(s => hasValue(s.startDate) && hasValue(s.value)).filter(s => {
            const year = moment(s.startDate).year()
            return year >= this.state.startYear && year <= this.state.endYear
        })

        const allYears = this.getYearRange()
        const stateGroup = groupBy(filterStates, 'stateId')
        const stateLabels = getStateLabels()

        const data = orderBy(Object.keys(stateGroup).map(key => {
            const states = stateGroup[key]
            const stateByYearGroups = groupBy(states, s => this.getStateYear(s, allYears))
            const years = Object.keys(stateByYearGroups).reduce((acc, val) => {
                const yearStates = stateByYearGroups[val]
                const worst = maxBy(yearStates, 'value')
                return ({
                    ...acc,
                    [val]: {
                        sortValue: worst.value,
                        ...(stateLabels[worst.value] || { color: 'white' }),
                        tooltip: worst.comment,
                        className: worst.comment && 'tooltipped clickable',
                        onClick: this.state.editMode ? () => this.setStateModal(false, worst) : null,
                    },
                })
            }, {})
            return {
                state: { value: states[0].stateName },
                order: { value: states[0].order },
                ...years,

            }
        }), 'order')
        const headers = ['state', ...allYears]
        return [data, headers]
    }
    exportData = (data, type, title) => {
        if (!data[0].length) {
            return AppStore.dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const dataWithHeaders = data[0].map(line =>{
            return {
                ...line,
                headers: data[1],
            }
        })
        const formattedData = formatData(dataWithHeaders)
        return this.props.standardExport(formattedData, type, title)
    }
    getExportModal = (events) => {
        const { openExportModal } = this.state
        const { typeEnvironmentModels: models } = this.props
        const tableExport = [{
            name: i18n.resultsTable,
            formats: [{
                type: i18n.excelFile,
                callback: () => this.exportData(events, 'xlsx', `${this.props.qualitometer.code} - ${i18n.states}`),
            }, {
                type: i18n.csvFile,
                callback: () => this.exportData(events, 'csv', `${this.props.qualitometer.code} - ${i18n.states}`),
            }],
        }]
        const exportModel = models.map((model) => {
            const fileNameSplit = model.split('.')
            const name = fileNameSplit.slice(0, -1).join('')
            const ext = fileNameSplit[fileNameSplit.length - 1]
            return {
                name,
                formats: [{
                    type: getModelFileType(ext),
                    callback: () => exportModelFile({
                        stationId: this.props.qualitometer.id.toString(),
                        stationCode: this.props.qualitometer.code,
                        stationType: this.props.qualitometer.typeName,
                        environmentModels: models,
                        filenameModelExport: model,
                    }),
                }],
            }
        })
        return openExportModal && (
            <ExportFileModal
                open={openExportModal}
                onClose={() => this.setState({ openExportModal: false })}
                data={[ ...tableExport, ...exportModel ]}
            />
        )
    }

    render() {
        const yearChoices = [
            { code: 1, name: i18n.year },
            { code: 2, name: `${2} ${i18n.nbYears}` },
            { code: 3, name: `${3} ${i18n.nbYears}` },
        ]
        const data = this.getData()
        const tableData = data[0]
        const headers = data[1]

        return (
            <div className='padding-top-1 margin-left-25 margin-right-2'>
                <Card>
                    <Row className='padding-top-1'>
                        <NumberField col={2} title={i18n.startYear} value={this.state.startYear} onChange={v => this.setState({ startYear: v ? v : moment().year() - 3 })} />
                        <NumberField col={2} title={i18n.endYear} value={this.state.endYear} onChange={v => this.setState({ endYear: v ? v : moment().year() })} />
                        <RadioButtons col={5} elements={yearChoices} title={i18n.regrouping} selected={this.state.yearChoice} onChange={v => this.setState({ yearChoice: v })} />
                    </Row>
                </Card>
                <Card>
                    <div className='col s12 no-padding'>
                        {tableData.length ? (
                            <Table
                                color
                                sortable condensed type={{ headers }} showTitle={false} data={tableData} nbPerPageLabel={nbPerPageLabel} paging
                            />
                        ) : <MessageCard>{i18n.noStates}</MessageCard>}

                    </div>
                </Card>
                {this.state.openExportModal && this.getExportModal(data)}
            </div>
        )
    }
}

QualitometerStatesApp.propTypes = {
    qualitometer: PropTypes.instanceOf(DtoQualitometer),
    fetchQualitometerStates: PropTypes.func,
    qualitometerStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerState)),
    qualityStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityState)),
    standardExport: PropTypes.func,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    qualitometer: store.QualityReducer.qualitometer,
    qualitometers: store.QualityReducer.qualitometersLight,
    citiesIndex: store.CityReducer.citiesIndex,
    qualitometerStates: store.QualityReducer.qualitometerStates,
    qualityStates: store.QualityReducer.qualityStates,
    stationWatermass: store.StationReducer.stationWatermass,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const dispatchToProps = {
    push,
    fetchQualitometerStates: QualityAction.fetchQualitometerStates,
    setTitle: HomeAction.setTitle,
    fetchQualityStates: QualityAction.fetchQualityStates,
    standardExport: ExportAction.export,
}

export default connect(mapStateToProps, dispatchToProps)(QualitometerStatesApp)