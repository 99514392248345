'use strict'
import React, { Component } from 'react'

class DevelopmentApp extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className='row '>
                <div className='col s12 '>
                    <div className='card '>
                        <div className='card-title activator active'>
                            <i className='material-icons no-display'>sort</i>
                            En developpement
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DevelopmentApp
