import {
    OPEN_POPUP,
    POPUP,
    POPUP_CLOSED,
    RECEIVE_PROPS,
    RECEIVE_SIEAU_HYPE_TRENDS,
    RESET_ABORT_SIGNAL,
    RESET_POPUP,
    RESET_PROPS,
    RESET_SIEAU_HYPE_TRENDS,
    SET_LOADING_DATA,
    WAIT_PROPS,
} from './SieauConstants'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { every, some } from 'lodash'
import {
    getAddFunctions,
    getDeleteFunctions,
    getFetchFunctions,
    getResetFunctions,
    getUpdateFunctions,
} from '../../utils/StoreUtils'
import { hasValue } from '../../utils/NumberUtil'
import IAEauAction from '../../iaeau/IAEauAction'
import QualityAction from '../../quality/actions/QualityAction'
import { promiseAllProgress } from '../../utils/ActionUtils'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import HomeAction from '../../home/actions/HomeAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import i18n from 'simple-react-i18n'
import LogAction from '../../log/actions/LogAction'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'


const getPropsToFetch = (tabProps) => {
    if ((typeof tabProps) === 'string') {
        return [tabProps]
    }
    if (tabProps.length) {
        return tabProps
    }
    return Object.keys(tabProps).filter(prop => tabProps[prop])
}

const propIsEmpty = (propName, prop) => {
    if (AppStore.getState().SieauReducer.receivedProps.includes(propName)) {
        return false
    }
    if (!hasValue(prop)) {
        return false
    }
    if (hasValue(prop.length)) {
        return prop.length === 0
    }
    if (Object.keys(prop).length !== 0) {
        return false
    }
    return true
}

const SieauAction = {
    fetch: (tabProps, param1, param2, param3, param4) => {
        return (dispatch) => {
            const propsToFetch = getPropsToFetch(tabProps)
            const fetchFunctions = getFetchFunctions(propsToFetch)
            propsToFetch.map(prop => {
                dispatch(fetchFunctions[prop](param1, param2, param3, param4))
            })
        }
    },
    forceFetch: (tabProps, param1, param2, param3, param4) => {
        return (dispatch) => {
            const propsToFetch = getPropsToFetch(tabProps)
            const fetchFunctions = getFetchFunctions(propsToFetch)
            propsToFetch.map(prop => {
                return dispatch(fetchFunctions[prop](param1, param2, param3, param4))
            })
        }
    },
    loadSieauApp: (progressCallback = () => { }) => dispatch => (promiseAllProgress([
        QualityAction.promiseQualityIndicators(),
        AdministrationAction.promisePictos(),
        HomeAction.promiseThemeLayers(),
        AdministrationAction.promiseSettings(),
        AdministrationAction.promiseCartographyThemes(),
        ReferencialAction.promiseStationTypes(),
    ], progressCallback).then(jsonTab => {
        dispatch(QualityActionConstant.receiveQualityIndicators(jsonTab[0]))
        dispatch(AdministrationAction.receivePictos(jsonTab[1]))
        dispatch(HomeAction.receiveThemeLayers(jsonTab[2]))
        dispatch(AdministrationAction.receiveSettings(jsonTab[3]))
        dispatch(AdministrationAction.receiveCartographyThemes(jsonTab[4]))
        dispatch(ReferencialAction.receiveStationTypes(jsonTab[5]))
    }).catch((err) => {
        dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
        dispatch(ToastrAction.error(i18n.loadError))
    })),
    waitProps: (tabProps, props) => {
        return (dispatch) => {
            if (!tabProps.length) {
                dispatch(WaitAction.waitStop())
                return dispatch({ type: WAIT_PROPS, waitProps: [] })
            }
            const storeWaitProps = AppStore.getState().SieauReducer.waitProps
            if (storeWaitProps.length !== tabProps.length && (!props ||
                some(tabProps, propName => propIsEmpty(propName, props[propName])))) {
                dispatch(WaitAction.waitStart())
                return dispatch({ type: WAIT_PROPS, waitProps: tabProps })
            }
            return { type: '' }
        }
    },
    checkWaitingProps: (nextProps) => {
        return (dispatch) => {
            const waitProps = AppStore.getState().SieauReducer.waitProps
            if (waitProps.length !== 0) {
                if (some(waitProps, propName => !nextProps[propName])) {
                    // possible error when changing pages
                    dispatch(SieauAction.waitProps([]))
                    return dispatch(WaitAction.waitStop())
                }
                if (every(waitProps, propName => !propIsEmpty(propName, nextProps[propName]))) {
                    dispatch(SieauAction.waitProps([]))
                    return dispatch(WaitAction.waitStop())
                }
                if (!AppStore.getState().WaitReducer.isWait) {
                    return dispatch(WaitAction.waitStart())
                }
            }
            return { type: '' }
        }
    },
    receiveAction(type, data) {
        return { type, data }
    },
    add: (tabProps, param1, param2, param3, param4) => {
        return (dispatch) => {
            const propsToFetch = getPropsToFetch(tabProps)
            const addFunctions = getAddFunctions(propsToFetch)
            propsToFetch.map(prop => {
                return dispatch(addFunctions[prop](param1, param2, param3, param4))
            })
        }
    },
    update: (tabProps, param1, param2, param3, param4) => {
        return (dispatch) => {
            const propsToFetch = getPropsToFetch(tabProps)
            const updateFunctions = getUpdateFunctions(propsToFetch)
            propsToFetch.map(prop => {
                return dispatch(updateFunctions[prop](param1, param2, param3, param4))
            })
        }
    },
    delete: (tabProps, param1, param2, param3) => {
        return (dispatch) => {
            const propsToFetch = getPropsToFetch(tabProps)
            const deleteFunctions = getDeleteFunctions(propsToFetch)
            propsToFetch.map(prop => {
                return dispatch(deleteFunctions[prop](param1, param2, param3))
            })
        }
    },
    reset: (tabProps) => {
        return (dispatch) => {
            const propsToFetch = getPropsToFetch(tabProps)
            const resetFunctions = getResetFunctions(propsToFetch)
            propsToFetch.map(prop => {
                return dispatch(resetFunctions[prop]())
            })
        }
    },
    receiveProps(propName) {
        if (AppStore.getState().SieauReducer.receivedProps.includes(propName)) {
            return { type: '' }
        }
        return { type: RECEIVE_PROPS, propName }
    },
    resetProps(resetProps) {
        return { type: RESET_PROPS, resetProps }
    },
    receiveHypeTrends(json) {
        return { type: RECEIVE_SIEAU_HYPE_TRENDS, trends: json.trends, ruptures: json.breaks }
    },
    fetchHypeTrends(measures, callback = () => { }) {
        return (dispatch) => {
            dispatch(IAEauAction.fetchHypeTrendsFromIAEau(measures, callback))
        }
    },
    resetHypeTrends() {
        return { type: RESET_SIEAU_HYPE_TRENDS }
    },
    setPopup(popup) {
        return { type: POPUP, popup }
    },
    setPopupIsClosed() {
        return { type: POPUP_CLOSED }
    },
    openPopup() {
        return { type: OPEN_POPUP }
    },
    resetPopup() {
        return { type: RESET_POPUP }
    },
    setLoadingData(loadingData) { // true or false, false to interrupt loading
        return { type: SET_LOADING_DATA, loadingData }
    },
    resetAbortSignal() {
        return { type: RESET_ABORT_SIGNAL }
    },
}

export default SieauAction
