'use strict'
import createClass from 'create-react-class'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AccountUserApp from './AccountUserApp'


export default createClass({

    propTypes: {
        children: PropTypes.element,
    },

    render () {
        return (
            <main>
                <div className='row no-margin'>
                    <BoundaryError>
                        <Switch>
                            <Route exact path='/account' component={() => <AccountUserApp {...this.props} />} />
                            <Route exact path='/account/update' component={() => <AccountUserApp {...this.props} />} />
                        </Switch>
                    </BoundaryError>
                </div>
            </main>
        )
    },
})
