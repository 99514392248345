export default class DtoCampaignProgress {
    constructor(obj) {
        this.id = obj.id
        this.nbStation = obj.nbStation
        this.nbStationValidated = obj.nbStationValidated
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.progress = obj.progress
        this.progressTotal = obj.progressTotal
    }
}