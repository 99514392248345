import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from 'utils/FormUtils'
import useBoolean from 'utils/customHook/useBoolean'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { useDispatch } from 'react-redux'
import OperationAction from 'quality/components/operation/actions/OperationAction'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { isNil, isUndefined, keys, omitBy, values } from 'lodash'
import PropTypes from 'prop-types'
import Checkbox from 'components/forms/Checkbox'
import MessageCard from 'components/card/MessageCard'

const CalculateIndexes = ({
    qualitometer,
    operations = [],
    support,
}) => {
    const dispatch = useDispatch()

    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)
    const [options, setOptions] = useState({})

    const onCalculate = () => {
        if (operations.length === 0 || isNil(qualitometer)) return
        dispatch(OperationAction.calculateSEEE(qualitometer, operations, keys(omitBy(options, o => !o))))
    }

    return (
        <div className='action-wrapper'>
            <a
                className='right waves-effect nav-actions blue-arrow'
                { ...sieauTooltip(i18n.calculSeeeDesc, 'update_action_navbar', 'bottom') }
                id='calculate_indice_action_navbar'
                onClick={() => {
                    open()
                    setOptions({})
                }}
            >
                <i className='material-icons left no-margin'>sync</i>
            </a>
            <DialogMUI
                maxWidth='md'
                fullWidth
                open={isOpen}
                PaperProps={{
                    sx: {
                        minHeight: undefined,
                        maxHeight: undefined,
                    },
                }}
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item >
                            {i18n.calculSeeeDesc}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={close} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI sx={{ padding: '5px' }}>
                    {
                        (isUndefined(support) || support === 13) && (
                            <StyledFieldSet>
                                <StyledLegend>{i18n.macroInvertebres}</StyledLegend>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.ibgdce}
                                            checked={options.ibgdce}
                                            onChange={v => setOptions(p => ({ ...p, ibgdce: v }))}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.mgce}
                                            checked={options.mgce}
                                            onChange={v => setOptions(p => ({ ...p, mgce: v }))}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.i2m2}
                                            checked={options.i2m2}
                                            onChange={v => setOptions(p => ({ ...p, i2m2: v }))}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        )
                    }
                    {
                        (isUndefined(support) || support === 4) && (
                            <StyledFieldSet>
                                <StyledLegend>{i18n.fish}</StyledLegend>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.ipr}
                                            checked={options.ipr}
                                            onChange={v => setOptions(p => ({ ...p, ipr: v }))}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.iprPlus}
                                            checked={options.iprPlus}
                                            onChange={v => setOptions(p => ({ ...p, iprPlus: v }))}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.iil}
                                            checked={options.iil}
                                            onChange={v => setOptions(p => ({ ...p, iil: v }))}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        )
                    }
                    {
                        (isUndefined(support) || support === 10 || support === 27) && (
                            <StyledFieldSet>
                                <StyledLegend>{i18n.diatomees} et {i18n.macrophyte}</StyledLegend>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.ibd}
                                            checked={options.ibd}
                                            onChange={v => setOptions(p => ({ ...p, ibd: v }))}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.ibmr}
                                            checked={options.ibmr}
                                            onChange={v => setOptions(p => ({ ...p, ibmr: v }))}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        )
                    }
                    {
                        (isUndefined(support) || support === 11) && (
                            <StyledFieldSet>
                                <StyledLegend>{i18n.phytoplanctons }</StyledLegend>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.iplac}
                                            checked={options.iplac}
                                            onChange={v => setOptions(p => ({ ...p, iplac: v }))}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Checkbox
                                            label={i18n.iphyge}
                                            checked={options.iphyge}
                                            onChange={v => setOptions(p => ({ ...p, iphyge: v }))}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        )
                    }
                    {![4, 10, 11, 13, 27, undefined].includes(support) && (
                        <MessageCard>{i18n.noCalculAvailable}</MessageCard>
                    )}
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button
                        onClick={onCalculate}
                        variant='contained'
                        color='primary'
                        disabled={!values(options).some(v => v)}
                    >
                        {i18n.calculate}
                    </Button>
                </DialogActionsMUI>
            </DialogMUI>
        </div>
    )
}

CalculateIndexes.propTypes = {
    qualitometer: PropTypes.number,
    operations: PropTypes.arrayOf(PropTypes.number),
    support: PropTypes.string,
}

export default CalculateIndexes