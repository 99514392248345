import { push } from 'connected-react-router'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getArrestColor } from '../../../utils/ColorUtil'
import { getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'


const propsToFetch = {
    arrests: false,
}

const NotifsArrestPanel = ({
    arrests = [],
    goTo,
}) => {
    if (arrests.length !== 0) {
        return (
            <div className='col s12 no-padding notif-arrest'>
                <div className='collection row no-margin'>
                    {
                        arrests.slice(0, 5).map(arrest => (
                            <div className={ `collection-item row no-padding valign-wrapper ${getArrestColor(arrest.typeArrest)}` }>
                                <div className='no-margin no-padding col s2 center-align'>
                                    <p className='center-align no-margin bold'>
                                        { [
                                            arrest.typeArrest.split(' ')[0],
                                            <br/>,
                                            arrest.typeArrest.split(' ')[1] || '',
                                        ] }
                                    </p>
                                </div>
                                <div className='no-padding col s10 white'>
                                    <h6 className='truncate title padding-left-1'>
                                        <b className='bold'>
                                            { arrest.title }
                                        </b>
                                    </h6>
                                    <div className='truncate padding-left-1'>
                                        { arrest.comment }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className='collection-item clickable center-align'>
                        <p
                            className='no-margin'
                            onClick={ () => goTo('/arrests') }
                        >
                            { i18n.viewMore }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    return <div className='col s12'>{ i18n.noArrestsToDisplay }</div>
}

NotifsArrestPanel.propTypes = getPropTypes(propsToFetch)

const mapStateToProps = () => getMapStateToProps(propsToFetch)

const mapDispatchToProps = {
    goTo: push,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotifsArrestPanel)
