/* eslint-disable camelcase */
import { Grid } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { createIndex } from 'utils/StoreUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import AgriAction from '../../agriAdministration/actions/AgriAction'
import ProgressBar from 'components/progress/ProgressBar'
import { groupBy, omit, partition, uniqBy } from 'lodash'
import WaterTurnsFilters from 'exploitations/components/waterTurns/WaterTurnsFilters'
import WaterTurnsAccordion from 'exploitations/components/waterTurns/WaterTurnsAccordion'
import UpdateWaterTurnsDialog from 'exploitations/components/waterTurns/UpdateWaterTurnsDialog'
import AddWaterTurnsDialog from 'exploitations/components/waterTurns/AddWaterTurnsDialog'
import { getYear } from 'utils/DateUtil'
import { getApplyDays, setTableIndex } from 'exploitations/utils/AgriUtils'
import DtoWaterTurnsSlot from 'exploitations/dto/DtoWaterTurnsSlot'
import { hasValue } from 'utils/NumberUtil'
import DtoWaterTurns from 'exploitations/dto/DtoWaterTurns'

const hashHeaders = ['samplerName', 'pumpType', 'material', 'serialNumber', 'nature', 'identifier']

const WaterTurnsManagerApp = ({
}) => {
    const dispatch = useDispatch()

    const {
        restrictions,
        variousMateriels,
        exploitationsExportFullData,
        waterTurnsSlots,
        waterTurns,
    } = useSelector(store => ({
        restrictions: store.AgriReducer.restrictions,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        waterTurns: store.AgriReducer.waterTurns,
    }), shallowEqual)

    const equipments = useMemo(() => variousMateriels.filter(vm => vm.pump), [variousMateriels])

    const [readMode, setReadMode] = useState(true)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [filters, setFilters] = useState({})

    const [selectedWaterTurn, setSelectedWaterTurn] = useState()
    const [selectedSlot, setSelectedSlot] = useState(new DtoWaterTurnsSlot({}))
    const [title, setTitle] = useState('')

    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)

    const [addedWaterTurns, setAddedWaterTurns] = useState([])
    const [updatedWaterTurns, setUpdatedWaterTurns] = useState([])
    const [deletedWaterTurns, setDeletedWaterTurns] = useState([])
    const [expanded, setExpanded] = useState(`panel_${getYear(moment.now())}`)

    useEffect(() => {
        if (!exploitationsExportFullData.length) {
            dispatch(AgriAction.fetchExploitationsExportFullData())
        }
        if (!restrictions.length) {
            dispatch(AgriAction.fetchRestrictions()).then(json => {
                if (!json.length) {
                    dispatch(ToastrAction.warning(i18n.noRestrictionsInBase))
                }
            })
        }
        if (!waterTurnsSlots.length) {
            dispatch(AgriAction.fetchSlots())
        }
        dispatch(AgriAction.fetchWaterTurns({})).then(() => {
            setDataLoaded(true)
        })
    }, [])

    useTitle(() => [{
        title: i18n.steering,
        href: 'territory',
    }, {
        title: i18n.waterTurnsManagement,
        href: 'territory/waterTurns',
    }], [])

    const colors = useMemo(() => {
        return createIndex([
            ...restrictions,
            {
                id: 'X',
                color: '#52D100',
                level: 'X',
            },
        ], 'level')
    }, [restrictions])

    const getHash = (user) => {
        return searchAllCharacters(hashHeaders.map((key) => user[key]))
    }

    const getFilteredData = (data) => {
        const toSearch = searchAllCharacters(filters.search || '')
        return data.filter((i) => getHash(i).includes(toSearch))
    }

    const data = useMemo(() => {
        const { search, equipmentsFilter } = filters

        const deletedData = waterTurns.filter(d => !deletedWaterTurns.includes(d.id))
        const updatedData = deletedData.map(dd => {
            const updatedDatum = updatedWaterTurns.find(d => d.id === dd.id)
            return hasValue(updatedDatum?.id) ? updatedDatum : dd
        })
        const addedData = [...updatedData, ...addedWaterTurns]
        const equipmentsData = equipmentsFilter?.length ? addedData.filter(swt => equipmentsFilter.includes(swt.idMat)) : addedData

        return search ? getFilteredData(equipmentsData) : equipmentsData
    }, [waterTurns, filters, colors, addedWaterTurns, updatedWaterTurns, deletedWaterTurns])

    const groupedYearData = groupBy(data, 'year')

    useActions(() => {
        const actions = readMode ? {
            edit: () => setReadMode(false),
        } : {
            save: () => {
                const p = Promise.resolve()
                p.then(() => {
                    if (deletedWaterTurns.length) {
                        dispatch(AgriAction.deleteWaterTurn(deletedWaterTurns))
                    }
                }).then(() => {
                    const updated = [...updatedWaterTurns, ...addedWaterTurns].map(d => new DtoWaterTurns(d))
                    if (updated.length) {
                        dispatch(AgriAction.updateWaterTurns(updated))
                    }
                }).then(() => {
                    setReadMode(true)
                    setAddedWaterTurns([])
                    setUpdatedWaterTurns([])
                    setDeletedWaterTurns([])
                    setDataLoaded(false)
                    dispatch(AgriAction.fetchWaterTurns({})).then(() => {
                        setDataLoaded(true)
                    })
                })
            },
            new: () => setOpenAdd(true),
            cancel: () => {
                setReadMode(true)
                setAddedWaterTurns([])
                setUpdatedWaterTurns([])
                setDeletedWaterTurns([])
            },
        }
        return restrictions.length ? actions : omit(actions, ['new', 'edit'])
    }, [readMode, waterTurns, restrictions, data])

    const addWaterTurns = (exploitationsToAdd, idSlot) => {
        AgriAction.addWaterTurns({ idExploitations: exploitationsToAdd, idSlot }).then(json => {
            setAddedWaterTurns(setTableIndex([...addedWaterTurns, ...json]))
            setOpenAdd(false)
        })
    }

    const onOpenUpdate = (waterTurn, slot, formattedTitle) => {
        setSelectedWaterTurn(waterTurn)
        setSelectedSlot(slot)
        setTitle(formattedTitle)
        setOpen(true)
    }

    const updateWaterTurns = (waterTurn) => {
        if (hasValue(waterTurn.id)) {
            setUpdatedWaterTurns([
                ...updatedWaterTurns.filter(wt => wt.id !== waterTurn.id),
                waterTurn,
            ])
        } else {
            setAddedWaterTurns(setTableIndex([
                ...addedWaterTurns.filter(wt => wt.index !== waterTurn.index),
                waterTurn,
            ]))
        }
        setOpen(false)
    }

    const deleteWaterTurns = (waterTurn) => {
        if (hasValue(waterTurn.id)) {
            setDeletedWaterTurns([
                ...deletedWaterTurns,
                waterTurn.id,
            ])
            setUpdatedWaterTurns(updatedWaterTurns.filter(uwt => uwt.id !== waterTurn.id))
        } else {
            setAddedWaterTurns(setTableIndex(addedWaterTurns.filter(wt => wt.index !== waterTurn.index)))
        }
    }

    const onApply = (slot, selectionTool, colorInput) => {
        const days = getApplyDays(selectionTool, colorInput)
        const [updatedData, addedData] = partition(data.filter(d => d.idSlot === slot.id && d.year === moment().year()).map(swt => ({ ...swt, ...days })), d => hasValue(d.id))
        setAddedWaterTurns(setTableIndex(uniqBy([
            ...addedData,
            ...addedWaterTurns,
        ], d => d.index)))
        setUpdatedWaterTurns(uniqBy([
            ...updatedData,
            ...updatedWaterTurns,
        ], d => d.id))
    }

    return (
        <Grid container justifyContent='center' className='padding-1' style={{ paddingBottom: '100px' }}>
            <WaterTurnsFilters
                restrictions={restrictions}
                equipments={equipments}
                data={data}
                onFilter={setFilters}
                readMode={readMode}
            />
            <Grid item xs={12} className='padding-top-1'>
                {dataLoaded && !data.length && (
                    <div style={{ background: 'white', border: '1px solid #35609F', borderRadius: 5 }}>
                        <div style={{ padding: '50px' }}>
                            <div className='col s12 text-align-center'>
                                <i className='material-icons medium'>nature_people</i>
                            </div>
                            <div className='center font-size-20'>{i18n.NoneResultForThisResearch}</div>
                        </div>
                    </div>
                )}
                {dataLoaded && Object.keys(groupedYearData).sort().reverse().map(yearKey => (
                    <Grid key={`accordion_${yearKey}`}>
                        <WaterTurnsAccordion
                            data={groupedYearData[yearKey]}
                            readMode={readMode}
                            colors={colors}
                            deleteWaterTurns={deleteWaterTurns}
                            onOpenUpdate={onOpenUpdate}
                            yearKey={yearKey}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            onApply={onApply}
                        />
                    </Grid>
                )) || <ProgressBar indeterminate />}
            </Grid>
            <UpdateWaterTurnsDialog
                selectedWaterTurn={selectedWaterTurn}
                updateWaterTurns={updateWaterTurns}
                slot={selectedSlot}
                colors={colors}
                title={title}
                open={open}
                setOpen={setOpen}
            />
            <AddWaterTurnsDialog openAdd={openAdd} setOpenAdd={setOpenAdd} addWaterTurns={addWaterTurns} />
        </Grid >
    )
}

WaterTurnsManagerApp.propTypes = {
}

export default WaterTurnsManagerApp
