import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoCasingDeclaration from 'survey/dto/DtoCasingDeclaration'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { hasValue } from 'utils/NumberUtil'

class PumpCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pump: props.pump,
        }
    }

    render() {
        const { title, actions, sandreCodes, variousMaterielTypes } = this.props
        const { pump } = this.state
        const pumpNature = variousMaterielTypes.find((t) => t.materielType === pump.materielType && t.category === 1) || {}
        const pumpCategory = sandreCodes.find((c) => c.field === 'MAT.MOBILITE' && c.code === pump.mobilityCode) || {}
        const pumpType = sandreCodes.find((c) => c.field === 'POMPES.TYPE' && c.code === pump.pumpType) || {}

        return (
            <Card
                title={title}
                headerStyle={{ backgroundColor: secondaryBlue }}
                actions={actions}
                className='row no-margin padding-bottom-1'
                cardStyle={{
                    border: `1px solid ${secondaryBlue}`,
                    borderWidth: '0 2 2 2',
                }}
            >
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.name}
                    value={pump.name}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.nature}
                    value={pumpNature.name}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.category}
                    value={pumpCategory.name}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.type}
                    value={pumpType.name}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.maxFlow}
                    value={pump.maxFlow}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.inUseFlow}
                    value={pump.operatingFlow}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.shared}
                    value={pump.isShared ? i18n.yes : i18n.no}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.forRent}
                    value={pump.rent ? i18n.yes : i18n.no}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.assignmentRate}
                    value={hasValue(pump.assignmentRate) ? `${pump.assignmentRate} %` : ''}
                    readMode
                />
                <Textarea
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.comment}
                    value={pump.comment}
                    readMode
                />
            </Card>
        )
    }
}

PumpCard.propTypes = {
    title: PropTypes.string.isRequired,
    pump: PropTypes.instanceOf(DtoCasingDeclaration).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        iconName: PropTypes.string,
    })),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
}

PumpCard.defaultProps = {
    editable: false,
    pump: {},
}

const mapStateToProps = (store) => {
    return {
        sandreCodes: store.ReferencialReducer.sandreCodes,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    }
}

export default connect(mapStateToProps)(PumpCard)
