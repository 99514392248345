import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { PATH_REFERENCIAL_CONTACT, PATH_REFERENCIAL_CONTACT_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import {
    checkAuth,
    checkError,
    genericFetch,
    genericPromise,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import {
    CREATE_CONTACTS_GROUPS,
    DELETE_CONTACTS_GROUP,
    UPDATE_CONTACTS_GROUP,
} from '../constants/ContactConstants'
import { ContactActionConstant } from '../reducers/ContactReducer'


const ContactAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().ContactReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    fetchContactsTable(filter = {}) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.referencial.contactsTable(), 'POST', filter)
                .then((json = []) => {
                    dispatch(ContactActionConstant.receiveContactsTable(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contacts} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contacts))
                })
        }
    },

    updateContact(contact) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveContact(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(contact),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(ContactAction.fetchContact(contact.code))
                        dispatch(ToastrAction.success(i18n.contactSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contact} : ${err}`))
                })
        }
    },

    createContact(contact, noRedirection) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contacts(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(contact),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                        if (noRedirection) {
                            return json.id
                        }
                        dispatch(push(PATH_REFERENCIAL_CONTACT_LINK + json.id))
                        return json.id
                    }
                    return null
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.contact} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.contact))
                })
        }
    },

    fetchContact(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contact(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(ContactActionConstant.receiveContact(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contact} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contact))
                })
        }
    },


    promiseContacts() {
        return fetch(ApplicationConf.referencial.contacts(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchContacts() {
        return (dispatch) => {
            return ContactAction.promiseContacts()
                .then((json = []) => {
                    dispatch(ContactActionConstant.receiveAllContacts(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contacts} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contacts))
                })
        }
    },

    deleteContact(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteContact(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_CONTACT))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.contact} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.contact))
                })
        }
    },

    purgeContact() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeContact(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(ContactAction.fetchContacts())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.contacts} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.contacts))
                })
        }
    },

    replaceContact(contact, contactReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceContact(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    id: contact,
                    replaceId: contactReplace,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.contactSuccessUpdated))
                    } else if (json.update === 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.contact} : ${err}`))
                })
        }
    },

    promiseContactsGroups() {
        return genericPromise(ApplicationConf.referencial.contactsGroups())
    },

    fetchContactsGroups() {
        return (dispatch) => {
            return ContactAction.promiseContactsGroups()
                .then((json = []) => {
                    dispatch(ContactActionConstant.receiveAllContactsGroups(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseContactsGroup(id) {
        return genericPromise(ApplicationConf.referencial.contactsGroup(id))
    },

    fetchContactsGroup(id) {
        return (dispatch) => {
            return ContactAction.promiseContactsGroup(id)
                .then((json = []) => {
                    dispatch(ContactActionConstant.receiveContactsGroup(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseDelelteContactsGroup(id) {
        return genericPromise(ApplicationConf.referencial.contactsGroup(id), 'DELETE')
    },
    deleteContactsGroup(id) {
        return genericFetch(ContactAction.promiseDelelteContactsGroup(id), DELETE_CONTACTS_GROUP)
    },
    promiseCreateContactsGroup(body) {
        return genericPromise(ApplicationConf.referencial.contactsGroups(), 'POST', body)
    },
    createContactsGroup(body) {
        return genericFetch(ContactAction.promiseCreateContactsGroup(body), CREATE_CONTACTS_GROUPS)
    },
    promiseSetContactsGroup(body) {
        return genericPromise(ApplicationConf.referencial.contactsGroups(), 'PUT', body)
    },
    setContactsGroup(body) {
        return genericFetch(ContactAction.promiseSetContactsGroup(body), UPDATE_CONTACTS_GROUP)
    },
    fetchContactsGroupsAlert: tableId => dispatch => Promise.all(tableId.map(code =>
        ContactAction.promiseContactsGroup(code)
    ))
        .then((json=[]) => {
            return json
        })
        .catch(err => {
            dispatch(WaitAction.waitStop())
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),
}

export default ContactAction
