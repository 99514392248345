import React, { useMemo } from 'react'
import logoSandre from 'assets/pictures/logo_sandre.png'
import { push } from 'connected-react-router'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { sandreWatershedDefaultLink } from '../../../../conf/SieauConstants'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_WATERSHED,
    PATH_REFERENCIAL_WATERSHED_ITEM_NEW,
} from '../../../../home/constants/RouteConstants'
import ReferentialAction from '../../../action/ReferencialAction'
import WatershedAction from '../actions/WatershedAction'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import SearchTable from 'referencial/components/SearchTable'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import ImportFileContent from 'import/components/content/ImportFileContent'

const headers = ['code', 'name', 'area', 'parent', 'comment', 'startMonthLowWater', 'endMonthLowWater']
const headersCsv = ['Id*', 'Nom*', 'Code', 'Superficie', 'Commentaire', 'Parent', 'Mois début étiage', 'Mois fin étiage']

const WatershedsApp = () => {
    const {
        watersheds,
        referencialSandreCodes,
    } = useSelector(store => ({
        watersheds: store.WatershedReducer.watersheds,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    }), shallowEqual)

    const [fileContent, setFileContent] = useState('')
    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(WatershedAction.fetchWatersheds())
        dispatch(ReferentialAction.fetchReferencialSandreCodes())
        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.watersheds,
        href: PATH_REFERENCIAL_WATERSHED,
    }], [])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'BASSINS_VERSANTS'), [referencialSandreCodes])

    const months = useMemo(() => [
        { value: 1, name: i18n.january },
        { value: 2, name: i18n.february },
        { value: 3, name: i18n.march },
        { value: 4, name: i18n.april },
        { value: 5, name: i18n.may },
        { value: 6, name: i18n.june },
        { value: 7, name: i18n.july },
        { value: 8, name: i18n.august },
        { value: 9, name: i18n.september },
        { value: 10, name: i18n.october },
        { value: 11, name: i18n.november },
        { value: 12, name: i18n.december },
    ], [])

    const watershedsFormatted = useMemo(() => watersheds.map((w) => ({
        ...w,
        startMonthLowWater: (months.find(m => m.value === w.startMonthLowWater) || {}).name,
        endMonthLowWater: (months.find(m => m.value === w.endMonthLowWater) || {}).name,
    })), [months, watersheds])

    const data = useMemo(() => getExportReferencialData(watershedsFormatted), [watershedsFormatted])

    const onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => setFileContent(upload.target.result)
        reader.readAsDataURL(file)
    }

    const getFileContent = (title, subtitle, extension) => (
        <ImportFileContent
            title={title}
            subtitle={subtitle}
            extension={extension}
            headersCsv={headersCsv}
            onImportFile={f => onImportFile(f)}
        />
    )

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_WATERSHED_ITEM_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.watershedsList,
                    },
                    sync: {
                        updateAction: () => dispatch(WatershedAction.fetchSynchroSandreWatershed()),
                        onImportXml: () => dispatch(WatershedAction.fetchImportWatershedXML(fileContent)),
                        onImportCsv: () => dispatch(WatershedAction.fetchImportWatershedCSV(fileContent)),
                        xmlContent: getFileContent(i18n.xmlSyncChoice, i18n.importXmlFileToZanderReference, '.xml'),
                        csvContent: getFileContent(i18n.csvSyncChoice, i18n.importCsvZanderReference, '.csv'),
                        fileContentIsValid: !!fileContent,
                    },
                    purge: {
                        onPurge: () => dispatch(WatershedAction.purgeWatershed()),
                    },
                },
            },
            links: [
                {
                    href: sandreWatershedDefaultLink,
                    img: logoSandre,
                    label: i18n.sandre.toUpperCase(),
                },
            ],
        }
    }, [exportData, fileContent])

    return (
        <SearchTable
            lastUpdate={lastUpdate}
            sessionKey={REFERENCIAL_TYPE_NAME.watershed}
            title={i18n.watershedsList}
            data={data}
            setExportData={setExportData}
            headers={headers}
            onClick={watershed => dispatch(push(`/referencial/watershed/${watershed.id}/dashboard`))}
        />
    )
}

WatershedsApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }).isRequired,
}

export default WatershedsApp