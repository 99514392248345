import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Checkbox from '../../../components/forms/Checkbox'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getLogin, getSetting, getUser } from '../../../utils/SettingUtils'
import moment from 'moment'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import InstallationInfosPanel from '../installations/components/InstallationInfosPanel'
import InstallationComElecPanel from '../installations/components/InstallationComElecPanel'
import InstallationAccessSitePanel from '../installations/components/InstallationAccessSitePanel'
import InstallationAccessCasingPanel from '../installations/components/InstallationAccessCasingPanel'
import StationLocationInfoPanel from '../../../station/components/location/StationLocationInfoPanel'
import Row from '../../../components/react/Row'
import InstallationStaffSafetyPanel from '../installations/components/InstallationStaffSafetyPanel'
import PumpingEquipmentsPanel from './components/PumpingEquipmentsPanel'
import NumberField from '../../../components/forms/NumberField'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoPumpingStation from './dto/DtoPumpingStation'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import { getVisitCheckBoxChange, getVisitDatePickerChange, getVisitSandreSelectChange, getVisitSelectChange } from '../../../utils/VisitUtils'
import Select from '../../../components/forms/Select'
import { getDate } from '../../../utils/DateUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import LocalisationMapModal from '../installations/components/modal/LocalisationMapModal'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { arrayOf, getSandreList } from 'utils/StoreUtils'
import StationSamplersPanel from 'station/components/link/StationSamplersPanel'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { greyBlue } from 'utils/constants/ColorTheme'
import ElecmecEquipmentPanel from '../installations/components/ElecmecEquipmentPanel'

class PumpingStationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            usedWater: {},
            readMode: true,
            pumping: new DtoPumpingStation({}),
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationPumping(this.props.installation.id).then(() => {
                this.setState({
                    pumping: {
                        ...this.props.installationPumping,
                        idStation: this.props.installation.id,
                    },
                })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () =>
            this.props.push('/installation'),
        )
    }

    onSave = (visit) => {
        const { installation, pumping } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    pumping,
                }
            ).then(() => this.setReadOnlyMode())
        }
        return this.props.updateInstallationPumping(
            installation,
            pumping
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, pumping: this.props.installationPumping })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
        }

        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`)
            ),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) =>
        this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangePumping = (newObject) =>
        this.setState({ pumping: { ...this.state.pumping, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return (
            getSetting(
                this.props.applicationSettings,
                `PANEL_${this.state.installation.installationType}_${panel}`,
            ) !== 'hidden'
        )
    }

    render() {
        const { installation, readMode, pumping, localizeMap } = this.state
        const { sandreCodes, accountHabilitations } = this.props
        const mode = { readMode, editMode: !readMode }
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_ST_POMPAGES_AEP,
        }

        const paramsPumping = {
            station: pumping,
            pumping,
            onChange: this.onChangePumping,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <div className='row padding-top-1 margin-left-2 padding-bottom-5' id='PumpingStationDescriptionPanel'>
                <div className='col s9' style={ { marginBottom: '100px' } }>
                    <Card title={ i18n.description }>
                        <InstallationInfosPanel { ...params } />
                        <Row>
                            <NumberField
                                col={ 6 }
                                value={ pumping.nominalCapacity }
                                title={ i18n.nominalCapacity }
                                onChange={ (v) => {
                                    this.onChangePumping({ nominalCapacity: v })
                                    this.onChangeVisit({ previousValue: pumping.nominalCapacity, newValue: v, field: i18n.nominalCapacity })
                                } }
                                readMode={ readMode }
                            />
                            <NumberField
                                col={ 6 }
                                value={ pumping.nominalHourlyCapacity }
                                title={ i18n.nominalHourlyCapacity }
                                onChange={ (v) => {
                                    this.onChangePumping({ nominalHourlyCapacity: v })
                                    this.onChangeVisit({ previousValue: pumping.nominalHourlyCapacity, newValue: v, field: i18n.nominalHourlyCapacity })
                                } }
                                readMode={ readMode }
                            />
                        </Row>
                        {/* <Row>
                            <Input
                                col={6}
                                value={pumping.treatedParams}
                                title={i18n.treatedParams}
                                onChange={(v) => this.onChangePumping({ treatedParams: v })}
                                readMode={readMode}
                            />
                            <Select
                                col={6}
                                value={pumping.processingSector}
                                label={i18n.processingSector}
                                options={getSandreList(
                                    sandreCodes,
                                    SANDRE.INSTALLATIONS_FILIERETRAITEMENT,
                                )}
                                onChange={(v) => this.onChangePumping({ processingSector: v })}
                                integerValue
                                readMode={readMode}
                            />
                        </Row> */}
                        <Row>
                            <Checkbox
                                col={ 6 }
                                checked={ pumping.autorisationDecree }
                                label={ i18n.autorisationDecree }
                                onChange={ (v) => {
                                    this.onChangePumping({ autorisationDecree: v })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.autorisationDecree, pumping.autorisationDecree, v))
                                } }
                                disabled={ readMode }
                            />
                            <Input
                                col={ 6 }
                                value={ pumping.numDecree }
                                title={ i18n.numDecree }
                                onChange={ (v) => {
                                    this.onChangePumping({ numDecree: v })
                                    this.onChangeVisit({ previousValue: pumping.numDecree, newValue: v, field: i18n.numDecree })
                                } }
                                readMode={ readMode }
                            />
                        </Row>
                        {/* <Row>
                            <DatePicker
                                col={6}
                                value={pumping.autorisationDecreeDate}
                                id='creationDate'
                                title={i18n.autorisationDecreeDate}
                                onChange={(_, v) =>
                                    this.onChangePumping({ autorisationDecreeDate: v })
                                }
                                readMode={readMode}
                            />
                            <NumberField
                                col={6}
                                value={pumping.decreeDuration}
                                title={i18n.decreeDuration}
                                onChange={(v) => this.onChangePumping({ decreeDuration: v })}
                                readMode={readMode}
                            />
                        </Row>
                        <Row>
                            <Checkbox
                                col={6}
                                checked={pumping.rejectAutorisation}
                                label={i18n.rejectAutorisation}
                                onChange={(v) => this.onChangePumping({ rejectAutorisation: v })}
                                disabled={readMode}
                            />
                            <DatePicker
                                col={6}
                                value={pumping.rejectAutorisationDate}
                                title={i18n.rejectAutorisationDate}
                                onChange={(_, v) =>
                                    this.onChangePumping({ rejectAutorisationDate: v })
                                }
                                readMode={readMode}
                            />
                        </Row> */}
                        <Row>
                            <NumberField
                                col={ 6 }
                                value={ pumping.TNLevel }
                                title={ i18n.TNLevel }
                                onChange={ (v) => {
                                    this.onChangePumping({ TNLevel: v })
                                    this.onChangeVisit({ previousValue: pumping.TNLevel, newValue: v, field: i18n.TNLevel })
                                } }
                                readMode={ readMode }
                            />
                            <Select
                                col={6}
                                value={pumping.casingType}
                                label={i18n.casingType}
                                options={getSandreList(sandreCodes, SANDRE.STATION_POMPAGE_TYPE)}
                                onChange={(v) => {
                                    this.onChangePumping({ casingType: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.STATION_POMPAGE_TYPE, i18n.casingType, pumping.casingType, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Row>
                        <Row>
                            <NumberField
                                col={6}
                                value={pumping.nominalFlow}
                                title={i18n.nominalFlow}
                                onChange={(v) => {
                                    this.onChangePumping({ nominalFlow: v })
                                    this.onChangeVisit({ previousValue: pumping.nominalFlow, newValue: v, field: i18n.nominalFlow })
                                }}
                                readMode={readMode}
                            />
                            <NumberField
                                col={6}
                                value={pumping.potentialVolume}
                                title={i18n.potentialVolume}
                                onChange={(v) => {
                                    this.onChangePumping({ potentialVolume: v })
                                    this.onChangeVisit({ previousValue: pumping.potentialVolume, newValue: v, field: i18n.potentialVolume })
                                }}
                                readMode={readMode}
                            />
                        </Row>
                        <Row>
                            <NumberField
                                col={6}
                                value={pumping.sampledVolume}
                                title={i18n.sampledVolume}
                                onChange={(v) => {
                                    this.onChangePumping({ sampledVolume: v })
                                    this.onChangeVisit({ previousValue: pumping.sampledVolume, newValue: v, field: i18n.sampledVolume })
                                }}
                                readMode={readMode}
                            />
                        </Row>
                        <Row className='padding-left-1 padding-right-1'>
                            <BuildingsPanel {...params} />
                        </Row>
                        <Row
                            className='no-margin-bottom padding-left-1 padding-right-1 margin-top-1'
                            noMargin={ false }
                            displayIf={ this.isDisplayed('ELECMEC') }
                        >
                            <ElecmecEquipmentPanel { ...paramsPumping } />
                        </Row>
                        <Card
                            title={ i18n.equipments }
                            headerStyle={{ backgroundColor: greyBlue }}
                            className='padding-1'
                            displayIf={ this.isDisplayed('PUMPING_EQUIPMENTS') }
                        >
                            <PumpingEquipmentsPanel { ...paramsPumping } />
                            <Row>
                                <InstallationEquipmentsPanel className='padding-left-1 padding-right-1' { ...params } />
                            </Row>
                        </Card>
                    </Card>
                    <Card
                        title={ i18n.communicationElectricity }
                        className='margin-top-1'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('ELEC_COM') }
                    >
                        <InstallationComElecPanel { ...params } />
                    </Card>
                    <Card
                        title={ i18n.accessSite }
                        className='margin-top-1'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('ACCESS_SITE') }
                    >
                        <InstallationAccessSitePanel { ...params } />
                    </Card>
                    <Card
                        title={ i18n.accessCasing }
                        className='margin-top-1'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('ACCESS_CASING') }
                    >
                        <InstallationAccessCasingPanel { ...params } />
                    </Card>
                    <Row
                        className='margin-top-1 no-margin-bottom'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('STAFF_SAFETY') }
                    >
                        <InstallationStaffSafetyPanel { ...params } />
                    </Row>
                    <Row
                        className='margin-top-1 no-margin-bottom'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('CONTRIBUTORS') }
                    >
                        <StationPiezoContributorPanel { ...params } />
                    </Row>
                    <Row
                        className='margin-top-1 no-margin-bottom'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('USAGES') }
                    >
                        <StationUsagePanel { ...params } />
                    </Row>
                    <Row
                        className='margin-top-1 no-margin-bottom'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('ARRANGEMENTS') }
                    >
                        <StationArrangementPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={accountHabilitations.some((h) => h.habilitation === AGRI) && this.isDisplayed('SAMPLERS')}>
                        <StationSamplersPanel {...params}/>
                    </Row>
                </div>
                <div className='col s3' style={ { marginBottom: '100px' } }>
                    <StationUpdatePanel station={ installation } />
                    <Card noMargin={ false } className='margin-top-1 padding-bottom-1'>
                        <Row className={ `${(!readMode && 'padding-top-1') || ''}` }>
                            <Input
                                col={ 12 }
                                title={ i18n.dataOrigin }
                                value={ installation.dataOrigin }
                                onChange={ v => {
                                    this.onChangeInstallation({ dataOrigin: v })
                                    this.onChangeVisit({ previousValue: installation.dataOrigin, newValue: v, field: i18n.dataOrigin })
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.status }
                                value={ installation.status }
                                onChange={ v => {
                                    this.onChangeInstallation({
                                        status: v,
                                        statusLogin: getLogin(),
                                        statusDate: moment().valueOf(),
                                    })
                                    this.onChangeVisit(getVisitSelectChange(this.props.status, 'code', i18n.status, installation.status, v))
                                } }
                                options={ this.props.status }
                                keyValue='code'
                                integerValue
                                { ...mode }
                            />
                        </Row>
                        <Row className={ `${(!readMode && 'padding-bottom-1') || ''}` }>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.statusDate) }
                                title={ i18n.controlDoneAt }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ statusDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.controlDoneAt, installation.statusDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.controlDoneBy }
                                value={ installation.statusLogin }
                                keyValue='name'
                                onChange={ v => {
                                    this.onChangeInstallation({ statusLogin: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.users.map(u => ({ code: u.login, name: u.name || u.login })), 'name', i18n.controlDoneBy, installation.statusLogin, v))
                                } }
                                options={ this.props.users.map(u => ({ code: u.login, name: u.name || u.login })) } { ...mode }
                            />
                        </Row>
                        <Row className={ `${(!readMode && 'padding-bottom-1') || ''}` }>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.endDate) }
                                title={ i18n.deliberationDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ endDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.deliberationDate, installation.endDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } value={ installation.closeComment } title={ i18n.become }
                                onChange={ v => {
                                    this.onChangeInstallation({ closeComment: v })
                                    this.onChangeVisit({ previousValue: installation.closeComment, newValue: v, field: i18n.become })
                                } } { ...mode }
                            />
                        </Row>
                        <Row>
                            <Checkbox col={ 12 } label={ i18n.confidential } checked={ installation.confidential === '1' }
                                onChange={ v => {
                                    this.onChangeInstallation({ confidential: v ? '1' : '0' })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.confidential, installation.confidential === '1', v))
                                } } disabled={ this.state.readMode }
                            />
                        </Row>
                    </Card>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={ i18n.contact }
                            contactCode={ installation.ownerCode }
                            onChange={ v => {
                                this.onChangeInstallation({ ownerCode: v })
                                this.onChangeVisit(getVisitSelectChange(this.props.contacts, 'code', i18n.contact, installation.ownerCode, v))
                            } }
                            readMode={ readMode }
                        />
                    </Row>
                    <LastEventPanel
                        id={ installation.id }
                        events={ this.props.installationEvents }
                        stationId={ installation.id }
                        stationType='installation'
                    />
                    <Card
                        title={ i18n.map }
                        noMargin={ false }
                        className='margin-top-1'
                        actions={
                            !this.state.readMode ?
                                [{
                                    iconName: 'my_location',
                                    onClick: () => this.setState({ localizeMap: true }),
                                }] : []
                        }
                    >
                        <StationMapDashboardPanel noMarkerTooltip station={this.props.installation} type={'installation'} />
                        <LocalisationMapModal
                            onChangeInstallation={this.onChangeInstallation}
                            installation={installation}
                            readMode={readMode}
                            open={localizeMap}
                            onClose={ () => this.setState({ localizeMap: false }) }
                        />
                    </Card>
                    <Card title={ i18n.localisation } noMargin={ false } className='margin-top-1'>
                        <StationLocationInfoPanel
                            onChange={ this.onChangeInstallation }
                            onChangeVisit={ this.onChangeVisit }
                            station={ installation }
                            readMode={ readMode }
                        />
                    </Card>
                    <LinkedStationsPanel
                        noMargin={ false }
                        className='margin-top-1'
                        onReMount={ this.props.onReMount }
                        station={ installation }
                        { ...mode }
                    />
                </div>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM &&
            nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)
        ) {
            return false
        }
        return true
    }
}

PumpingStationDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationPumping: store.InstallationReducer.installationPumping,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationPumping: InstallationAction.updateInstallationPumping,
    fetchInstallationPumping: InstallationAction.fetchInstallationPumping,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(PumpingStationDescriptionPanel)
