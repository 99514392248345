import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getPayload } from 'utils/ActionUtils'
import moment from 'moment'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { URL_WANTED } from 'home/constants/HomeConstants'

const setUrlWanted = () => {
    const wantedUrl = window.location.href || ''
    const pathToPush = wantedUrl.substring(wantedUrl.indexOf('/#/') + 3)
    if (pathToPush && pathToPush !== 'login' && pathToPush !== 'home') {
        localStorage.setItem(URL_WANTED, pathToPush)
    }
}

const AuthenticatedRoute = (props) => {
    const dispatch = useDispatch()
    const payload = getPayload()
    if (!payload) {
        setUrlWanted()
        return <Redirect to='/login' />
    }
    const exp = moment(JSON.parse(payload).exp)
    if (moment().isAfter(exp)) {
        setUrlWanted()
        dispatch(HomeAction.logout())
        return <Redirect to='/login' />
    }
    return <Route {...props} />
}

AuthenticatedRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.func,
}

export default AuthenticatedRoute