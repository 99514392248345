import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { isEqual, maxBy, omit } from 'lodash'
import WaitAction from 'wait/WaitAction'
import { getSensorSetting } from '../../../../../materiel/utils/MaterielUtils'
import DtoStationSensorAssignment from '../../../../dto/materiel/DtoStationSensorAssignment'
import DtoMaterielState from '../../../../../materiel/dto/DtoMaterielState'
import MaterielAction from '../../../../../materiel/actions/MaterielAction'
import SensorDto from '../../../../../materiel/components/sensor/dto/SensorDto'
import SensorAction from '../../../../../materiel/components/sensor/actions/SensorAction'
import DtoSensorType from '../../../../../materiel/components/sensor/dto/DtoSensorType'
import StationAction from '../../../../actions/StationAction'
import SensorPanel from '../../../../../materiel/components/sensor/components/SensorPanel'
import SituationDetailPanel from '../SituationDetailPanel'
import DtoStation from '../../../../dto/DtoStation'
import DtoSensorSituation from '../../../../../materiel/components/sensor/dto/DtoSensorSituation'

class SensorSituationDetail extends Component {
    state = {
        sensor: {},
        sensorDefaultParam: {},
        previousSensorSetting: {},
    }

    componentDidMount() {
        const {
            stationSensorAssignments,
            materielStates,
            sensors,
            sensorTypes,
        } = this.props
        if (!materielStates.length) {
            this.props.fetchMaterielStates()
        }
        if (!sensors.length) {
            this.props.fetchSensors()
        }
        if (!sensorTypes.length) {
            this.props.fetchSensorTypes()
        }
        this.fetchPreviousSensorSetting(stationSensorAssignments)
    }

    componentDidUpdate(prevProps) {
        const { stationSensorAssignments } = this.props
        if (stationSensorAssignments.length !== prevProps.stationSensorAssignments.length) {
            this.fetchPreviousSensorSetting(stationSensorAssignments)
        }
    }

    fetchPreviousSensorSetting = stationSensorAssignments => {
        const lastSituation = maxBy(stationSensorAssignments, 'situationDate')
        if (lastSituation) {
            const lastMaterielId = lastSituation.idSensor
            this.props.fetchSensor(lastMaterielId).then(json =>
                this.setState({ previousSensorSetting: getSensorSetting(json) }))
        }
    }

    getSensorTitle = ({ serialNumber, reference }, { label }) => {
        const code = serialNumber || reference || ''
        return label ? `${label} - ${code}` : code || i18n.unknownMaterial
    }

    setSensorTypeParam = () => {
        const { sensorType } = this.state.sensor
        const type = this.props.sensorTypes.find(t => t.id === sensorType) || {}
        this.setState({
            sensor: {
                ...this.state.sensor,
                ...omit(type, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers']),
            },
        })
    }

    getSensorPanel = isEditMode => {
        const { sensor } = this.state
        if (sensor.id) {
            const sensorType = this.props.sensorTypes.find(t => t.id === sensor.sensorType) || {}
            return (
                <div>
                    <div className='col s8 offset-s2'>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={() => this.setState({
                                sensor: {
                                    ...this.state.sensorDefaultParam,
                                },
                            })}
                            >
                                {i18n.defaultSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={this.setSensorTypeParam}>
                                {i18n.typeSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className={`col s12 btn ${this.props.stationSensorAssignments.length ? '' : 'disabled'}`} onClick={() => this.setState({
                                sensor: {
                                    ...this.state.sensor,
                                    ...this.state.previousSensorSetting,
                                },
                            })}
                            >
                                {i18n.previousSettings}
                            </a>
                        </div>
                    </div>
                    <div className='row col s10 offset-s1'>
                        <div className='card no-padding'>
                            <div className='col s12 card-title active'>
                                {this.getSensorTitle(sensor, sensorType)}
                            </div>
                            <div className={'card-content margin-top-1'}>
                                <div className='row no-margin'>
                                    <SensorPanel id={sensor.id}
                                        sensor={sensor}
                                        onChange={changes => this.setState({ sensor: { ...this.state.sensor, ...changes } })}
                                        disabled={!isEditMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    fetchSensor = id => {
        this.props.waitStart()
        this.props.fetchSensor(id).then(json => {
            this.setState({ sensor: json, sensorDefaultParam: json })
            this.props.waitStop()
        })
    }

    updateSensor = () => {
        if (!isEqual(this.state.sensor, this.state.sensorDefaultParam)) {
            this.props.updateSensor(this.state.sensor)
        }
    }

    resetSensor = () => {
        this.props.resetSensor()
        this.setState({ sensor: {}, sensorDefaultParam: {} })
    }

    render = () => {
        const {
            station,
            sensors,
            sensorsLastSituations,
            sensorTypes,
            stationSensorAssignments,
            addSensorSituation,
            saveSensorSituation,
            deleteSensorSituation,
            params,
        } = this.props
        return (
            <SituationDetailPanel
                params={params}
                getMaterielPanel={isEditMode => this.getSensorPanel(isEditMode)}
                station={station}
                materiels={sensors}
                lastSituations={sensorsLastSituations}
                materielTypes={sensorTypes}
                stationMaterielAssignments={stationSensorAssignments}
                addSituation={addSensorSituation}
                saveSituation={saveSensorSituation}
                deleteSituation={deleteSensorSituation}
                fetchMateriel={this.fetchSensor}
                updateMateriel={this.updateSensor}
                resetMateriel={this.resetSensor}
                keyMaterielType='sensorType'
                keyMaterielId='idSensor'
            />
        )
    }
}

SensorSituationDetail.propTypes = {
    params: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    station: PropTypes.instanceOf(DtoStation),

    stationSensorAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSensorAssignment)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    sensor: PropTypes.instanceOf(SensorDto),
    sensors: PropTypes.arrayOf(PropTypes.instanceOf(SensorDto)),
    sensorsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorSituation)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorType)),

    resetSensor: PropTypes.func,
    updateSensor: PropTypes.func,
    fetchSensor: PropTypes.func,
    fetchSensors: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchSensorTypes: PropTypes.func,
    saveSensorSituation: PropTypes.func,
    addSensorSituation: PropTypes.func,
    deleteSensorSituation: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
}

const mapStateToProps = store => ({
    stationSensorAssignments: store.StationReducer.stationSensorAssignments,
    materielStates: store.MaterielReducer.materielStates,
    sensor: store.SensorReducer.sensor,
    sensors: store.SensorReducer.sensors,
    sensorsLastSituations: store.SensorReducer.sensorsLastSituations,
    sensorTypes: store.SensorReducer.sensorTypes,
})

const mapDispatchToProps = {
    resetSensor: SensorAction.resetSensor,
    updateSensor: SensorAction.updateSensor,
    fetchSensor: SensorAction.fetchSensor,
    fetchSensors: SensorAction.fetchSensors,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchSensorTypes: SensorAction.fetchSensorTypes,
    saveSensorSituation: StationAction.saveSensorSituation,
    addSensorSituation: StationAction.addSensorSituation,
    deleteSensorSituation: StationAction.deleteSensorSituation,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
}

export default connect(mapStateToProps, mapDispatchToProps)(SensorSituationDetail)