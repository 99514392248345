import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import SimpleTabList from 'components/list/SimpleTabList'
import React, { useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { groupBy, keys, maxBy, minBy, range, uniq, uniqBy } from 'lodash'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import useTitle from 'utils/customHook/useTitle'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { i18nize } from 'utils/StringUtil'
import { EVENT_TYPES } from 'events/constants/EventsConstants'
import { EventsGroupByCauseIntervention, EventsGroupByDate, EventsGroupByEventType, EventsGroupByReferent, EventsGroupByStation } from './EventsGroup'
import EventsAction from 'events/actions/EventsAction'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import { getDate, getYear } from 'utils/DateUtil'
import useSandreList from 'utils/customHook/useSandreList'
import moment from 'moment'
import { generateRandomColor, getColor } from 'utils/ColorUtil'
import LoadingButton from 'components/buttons/LoadingButton'
import useBoolean from 'utils/customHook/useBoolean'
import CampaignAction from 'campaign/actions/CampaignAction'
import Select from 'components/forms/Select'
import CentralAction from 'materiel/components/central/actions/CentralAction'
import PowerSupplyAction from 'materiel/components/powerSupply/actions/PowerSupplyAction'
import SensorAction from 'materiel/components/sensor/actions/SensorAction'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import EquipmentAction from 'materiel/components/equipment/actions/EquipmentAction'
import SimAction from 'materiel/components/sim/actions/SimAction'
import TelecomAction from 'materiel/components/telecom/actions/TelecomAction'
import SubscriptionAction from 'materiel/components/subscription/actions/SubscriptionAction'
import DtoInterventionEvent from 'piezometry/dto/DtoInterventionEvent'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import MessageCard from 'components/card/MessageCard'
import PropTypes from 'prop-types'
import useActions from 'utils/customHook/useActions'
import ExportFileModal from 'components/modal/ExportFileModal'
import { formatData } from 'utils/ExportDataUtil'
import ExportAction from 'export/actions/ExportAction'
import { getEventTypeInfo } from 'events/utils/EventUtils'
import useListIndexed from 'utils/customHook/useListIndexed'
import CityAction from 'referencial/components/city/actions/CityAction'

const DATE = 'DATE'
const REFERENT = 'REFERENT'
const EVENT_TYPE = 'EVENT_TYPE'
const STATION = 'STATION'
const CAUSE_INTERVENTION = 'CAUSE_INTERVENTION'

const GraphHistoric = ({
    events = [],
    colors = {},
}) => {
    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    const firstYear = getYear(minBy(events, 'date')?.date)
    const LastYear = getYear(maxBy(events, 'date')?.date)
    const listYear = range(firstYear, LastYear + 1, 1).map(y => `${y}`)

    const formattedEvents = events.flatMap(e => {
        if (!e.interventions) {
            return [e]
        }
        return e.interventions.map(i => ({ ...e, intervention: i }))
    })
    const groupByIntervention = groupBy(formattedEvents, 'intervention')

    const generateSerie = (name = i18n.unknown, list, color) => {
        const groupByYear = groupBy(list, e => getYear(e.date))
        return {
            type: 'bar',
            name,
            data: listYear.map(year => [year, groupByYear[year]?.length ?? 0]),
            stack: true,
            barMaxWidth: 30,
            itemStyle: {
                color,
            },
        }
    }

    const series = keys(groupByIntervention).map(key => {
        const [type, id] = key.split(':')
        const parseId = parseInt(id)
        const intervention = interventions[type]?.find(i => i.code === parseId)
        return generateSerie(intervention?.name, groupByIntervention[key], colors[key])
    })

    const options = {
        series,
        xAxis: [{
            type: 'category',
            data: listYear,
            boundaryGap: true,
            axisLabel: {
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
            position: 'left',
            splitLine: {
                show: false,
            },
        }],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            position: pos => [pos[0] + 20, pos[1] + 20],
        },
        grid: {
            top: '20',
            left: '20',
            right: '20',
            bottom: '0',
            containLabel: true,
            height: 180,
        },
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 200 }}
        />
    )
}

GraphHistoric.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
    colors: PropTypes.objectOf(PropTypes.string),
}

const FilterField = ({
    onValidate = () => {},
    isLoaded = false,
}) => {
    const {
        piezometers,
        piezometersReferents,
        accountHabilitations,
        networkPiezoLink,
        networksProps,
        eventDiagnostics,
        contributors,
        campaignsProps,

        powerSupplyTypes,
        sensorTypes,
        variousMaterielTypes,
        equipmentTypes,
        simTypes,
        telecomTypes,
        subscriptionTypes,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometersReferents: store.PiezometryReducer.piezometersReferents,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
        networksProps: store.NetworkReducer.networks,
        eventDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
        contributors: store.ContributorReducer.contributors,
        campaignsProps: store.PiezometryReducer.piezometryCampaigns,

        powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
        sensorTypes: store.SensorReducer.sensorTypes,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        equipmentTypes: store.EquipmentReducer.equipmentTypes,
        simTypes: store.SimReducer.simTypes,
        telecomTypes: store.TelecomReducer.telecomTypes,
        subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    }))
    const uniqReferents = uniqBy(piezometersReferents, 'code')

    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const [eventTypes, setEventTypes] = useState([])
    const [causeInterventions, setCauseInterventions] = useState([])
    const [diagnostics, setDiagnostics] = useState([])
    const [networks, setNetworks] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [administrators, setAdministrators] = useState([])
    const [stations, setStations] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const [provider, setProvider] = useState()
    const [manufacturer, setManufacturer] = useState()
    const [materialType, setMaterialType] = useState()

    const formattedInterventions = useMemo(() => {
        const formatIntervention = (list, type) => list.map(i => ({ id: `${type}:${i.code}`, name: i.name }))
        return [
            ...formatIntervention(interventionPreventive, 'PREVENTIF'),
            ...formatIntervention(interventionCurative, 'CURATIF'),
            ...formatIntervention(interventionOther, 'AUTRE'),
        ]
    }, [interventionCurative, interventionOther, interventionPreventive])

    const networksHabilitation = accountHabilitations.filter(({ habilitation }) => habilitation.match('^PIEZO_NETWORK_*')).map(({ habilitation }) => parseInt(habilitation.substr(14)))
    const contributorsHabilitation = accountHabilitations.filter(({ habilitation }) => habilitation.match('^PIEZO_CONTRIB_*')).map(({ habilitation }) => parseInt(habilitation.substr(14)))
    const networksContributors = networksProps.filter(({ contributorCode }) => contributorsHabilitation.includes(contributorCode))
    const networksId = [...networkPiezoLink.map(({ idNetwork }) => idNetwork), ...networksHabilitation]
    const networklistFound = uniq(networksId).map(id => networksProps.find(n => n.id === id)).filter(n => !!n)

    const networklist = uniqBy([...networksContributors, ...networklistFound], 'id')

    const formattedPiezometers = useMemo(() => {
        return piezometers.map(p => ({
            ...p,
            nameWithCode: p.code ? `[${p.code}] ${p.name || ''}` : (p.name || ''),
        }))
    }, [piezometers])

    const formattedEventTypes = useMemo(() => EVENT_TYPES.map(t => ({ id: t.code, label: i18n[t.label] })), [])

    const formattedMaterialTypes = useMemo(() => {
        const formatType = (list, type) => list.map(t => ({ id: `${type}:${t.materielType}`, name: `[${t.materielLabel}] ${t.name}` }))
        return [
            ...formatType(powerSupplyTypes, 'POWER_SUPPLY'),
            ...formatType(sensorTypes, 'SENSOR'),
            ...formatType(variousMaterielTypes, 'VARIOUS'),
            ...formatType(equipmentTypes, 'EQUIPMENT'),
            ...formatType(simTypes, 'SIM'),
            ...formatType(telecomTypes, 'TELECOM'),
            ...formatType(subscriptionTypes, 'SUBSCRIPTION'),
        ]
    }, [equipmentTypes, powerSupplyTypes, sensorTypes, simTypes, subscriptionTypes, telecomTypes, variousMaterielTypes])

    return (
        <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ padding: '5px' }} alignItems='center'>
            <Grid item xs={4}>
                <SuperMultiAutocomplete
                    label={i18n.eventType}
                    options={formattedEventTypes}
                    values={eventTypes}
                    onChange={setEventTypes}
                    multiple
                    limit={1}
                />
            </Grid>
            <Grid item xs={4}>
                <SuperMultiAutocomplete
                    label={i18n.causeIntervention}
                    options={formattedInterventions}
                    values={causeInterventions}
                    onChange={setCauseInterventions}
                    multiple
                    limit={1}
                />
            </Grid>
            <Grid item xs={4}>
                <SuperMultiAutocomplete
                    label={i18n.diagnostic}
                    options={eventDiagnostics}
                    keyValue='codeDiagnostic'
                    keyLabel='description'
                    values={diagnostics}
                    onChange={setDiagnostics}
                    multiple
                    limit={1}
                />
            </Grid>
            <Grid item xs={4}>
                <SuperMultiAutocomplete
                    label={i18n.network}
                    options={networklist}
                    keyLabel='labelWithSandre'
                    additionalKeysFilter={['sandreCode', 'name', 'mnemonic']}
                    values={networks}
                    onChange={setNetworks}
                    multiple
                    limit={1}
                />
            </Grid>
            <Grid item xs={4}>
                <SuperMultiAutocomplete
                    label={i18n.campaign}
                    options={campaignsProps}
                    values={campaigns}
                    onChange={setCampaigns}
                    multiple
                    limit={1}
                />
            </Grid>
            <Grid item xs={4}>
                <SuperMultiAutocomplete
                    label={i18n.administrator}
                    options={uniqReferents}
                    values={administrators}
                    onChange={setAdministrators}
                    multiple
                    limit={1}
                />
            </Grid>

            <Grid item xs={4}>
                <Select
                    options={contributors}
                    label={i18n.provider}
                    nullLabel='&nbsp;'
                    onChange={setProvider}
                    value={provider}
                />
            </Grid>
            <Grid item xs={4}>
                <Select
                    options={contributors}
                    label={i18n.manufacturer}
                    nullLabel='&nbsp;'
                    onChange={setManufacturer}
                    value={manufacturer}
                />
            </Grid>
            <Grid item xs={4}>
                <Select
                    options={formattedMaterialTypes}
                    label={i18n.materielType}
                    nullLabel='&nbsp;'
                    onChange={setMaterialType}
                    value={materialType}
                />
            </Grid>

            <Grid item xs={4}>
                <SuperMultiAutocomplete
                    label={i18n.station}
                    options={formattedPiezometers}
                    values={stations}
                    keyLabel='nameWithCode'
                    additionalKeysFilter={['code', 'name']}
                    onChange={setStations}
                    multiple
                    limit={1}
                />
            </Grid>
            <Grid item xs={2}>
                <SimpleDatePicker
                    label={i18n.startDate}
                    value={startDate}
                    onChange={setStartDate}
                />
            </Grid>
            <Grid item xs={2}>
                <SimpleDatePicker
                    label={i18n.endDate}
                    value={endDate}
                    onChange={setEndDate}
                />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
                <LoadingButton
                    loading={!isLoaded}
                    variant='contained'
                    fullWidth
                    onClick={() => {
                        const formatSplit = (elem = '') => {
                            const [type, code] = elem.split(':')
                            return { type, code: parseInt(code) ?? undefined }
                        }
                        onValidate({
                            eventTypes,
                            causeInterventions: causeInterventions?.map(formatSplit),
                            diagnostics,
                            networks,
                            campaigns,
                            administrators,
                            stations,
                            startDate,
                            endDate,
                            provider,
                            manufacturer,
                            materialType: materialType && formatSplit(materialType),
                        })
                    }}
                >
                    {i18n.search}
                </LoadingButton>
            </Grid>
        </Grid>
    )
}

FilterField.propTypes = {
    onValidate: PropTypes.func,
    isLoaded: PropTypes.bool,
}

const Legend = ({
    colors = {},
}) => {
    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    return (
        <Grid container spacing={1} sx={{ padding: '5px 10px' }}>
            {keys(colors).map(key => {
                const [type, id] = key.split(':')
                const parseId = parseInt(id)
                const intervention = interventions[type]?.find(i => i.code === parseId)
                return (
                    <Grid container item xs='auto' alignItems='center' justifyContent='flex-start'>
                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', color: colors[key], backgroundColor: colors[key], marginRight: '5px' }} />
                        {intervention?.name ?? i18n.unknown}
                    </Grid>
                )
            })}
        </Grid>
    )
}

Legend.propTypes = {
    colors: PropTypes.objectOf(PropTypes.string),
}

const PieChart = ({
    events = [],
    title = '',
    colors = {},
}) => {
    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    const formattedEvents = events.flatMap(e => {
        if (!e.interventions) {
            return [e]
        }
        return e.interventions.map(i => ({ ...e, intervention: i }))
    })
    const groupByIntervention = groupBy(formattedEvents, 'intervention')

    const options = {
        title: {
            text: title,
            left: 'center',
        },
        series: [{
            name: i18n.history,
            type: 'pie',
            radius: ['60%', '85%'],
            hoverOffset: 3,
            top: '30px',
            bottom: '0px',
            labelLine: {
                show: false,
            },
            label: {
                show: false,
            },
            data: keys(groupByIntervention).map(key => {
                const [type, id] = key.split(':')
                const parseId = parseInt(id)
                const intervention = interventions[type]?.find(i => i.code === parseId)
                return {
                    value: groupByIntervention[key].length,
                    name: intervention?.name ?? i18n.unknown,
                    itemStyle: {
                        color: colors[key],
                    },
                }
            }),
        }],
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 200 }}
        />
    )
}

PieChart.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
    title: PropTypes.string,
    colors: PropTypes.objectOf(PropTypes.string),
}

const GraphEventType = ({
    events = [],
}) => {
    const groupByType = groupBy(events, 'eventType')

    const formattedEventTypes = i18nize(EVENT_TYPES).filter(t => t.code !== 'T')

    const generateSerie = (name = i18n.unknown, list, color) => {
        return {
            type: 'bar',
            name,
            data: [[list?.length ?? 0, name]],
            stack: true,
            barMaxWidth: 30,
            itemStyle: {
                color,
            },
        }
    }

    const series = formattedEventTypes.map(eventType => {
        return generateSerie(eventType.label, groupByType[eventType.code], getColor(eventType.color))
    })

    const options = {
        series,
        xAxis: [{
            type: 'value',
            position: 'left',
            splitLine: {
                show: false,
            },
        }],
        yAxis: [{
            type: 'category',
            data: formattedEventTypes.map(t => t.label),
            boundaryGap: true,
        }],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            position: pos => [pos[0] + 20, pos[1] + 20],
        },
        grid: {
            top: '20',
            left: '20',
            right: '20',
            bottom: '0',
            containLabel: true,
            height: 180,
        },
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 200 }}
        />
    )
}

GraphEventType.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
}

const ExportModal = ({
    isOpen = false,
    close = () => {},

    events = [],
}) => {
    const dispatch = useDispatch()

    const {
        eventDiagnostics,
        eventSolutions,
        piezometers,
        contacts,
        campaigns,
        cities,

        networkPiezoLink,
        networks,
        piezometersReferents,
    } = useSelector(store => ({
        eventDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
        eventSolutions: store.EventsReducer.eventPiezometerSolutions,
        piezometers: store.PiezometryReducer.piezometersLight,
        contacts: store.ContactReducer.contacts,
        campaigns: store.PiezometryReducer.piezometryCampaigns,
        cities: store.CityReducer.cities,

        networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
        networks: store.NetworkReducer.networks,
        piezometersReferents: store.PiezometryReducer.piezometersReferents,
    }), shallowEqual)

    const piezometersIndexed = useListIndexed(piezometers)
    const contactsIndexed = useListIndexed(contacts)
    const campaignsIndexed = useListIndexed(campaigns)
    const networksIndexed = useListIndexed(networks)
    const citiesIndexed = useListIndexed(cities)

    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    const eventTypeInfo = useMemo(() => getEventTypeInfo(), [])

    const formatEvents = exportFormat => {
        const data = events.flatMap(e => {
            const piezo = piezometersIndexed[e.piezometer]
            const referentLink = piezometersReferents.find(link => link.idStation === e.piezometer)
            const networkLinks = networkPiezoLink.filter(link => link.idPiezometer === e.piezometer)
            const baseEvent = {
                stationCode: piezo?.code,
                stationName: piezo?.name,
                city: citiesIndexed[piezo?.townCode]?.name ?? '',
                referentCode: e.referent,
                referent: contactsIndexed[e.referent]?.name,
                administratorCode: referentLink?.code,
                administrator: referentLink?.name,
                network: networkLinks.map(n => networksIndexed[n.idNetwork]?.name).filter(n => !!n).join(', '),
                campaign: campaignsIndexed[e.campaign]?.name,
                date: getDate(e.date),
                eventType: eventTypeInfo[e.eventType]?.label,
                cause: e.interventions?.map(intervention => {
                    const [type, id] = intervention.split(':')
                    const parseId = parseInt(id)
                    return interventions[type]?.find(i => i.code === parseId)?.name ?? ''
                }).join(', '),
                comment: e.comment,
            }
            if (!e.diagnostics) {
                return [{
                    ...baseEvent,
                    diagnostic: '',
                    action: '',
                }]
            }
            return e.diagnostics.flatMap(d => {
                const [diagId, solutions] = d.split(':')
                const listSolutions = solutions.split(',').map(s => parseInt(s)).filter(s => !!s)
                const parseDiagId = parseInt(diagId)
                const diagnostic = eventDiagnostics.find(ed => ed.codeDiagnostic === parseDiagId)?.description
                return listSolutions.map(s => {
                    const solution = eventSolutions.find(es => es.codeSolution === s)?.description
                    return {
                        ...baseEvent,
                        diagnostic,
                        action: solution,
                    }
                })
            })
        })
        const dataWithHeaders = data.length ? [
            { ...data[0], headers: Object.keys(data[0]) },
            ...data.slice(1),
        ] : []
        dispatch(ExportAction.export(formatData(dataWithHeaders), exportFormat, i18n.events))
    }
    return (
        <ExportFileModal
            open={isOpen}
            onClose={close}
            data={[{
                name: i18n.events,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => formatEvents('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => formatEvents('csv'),
                }],
            }]}
        />
    )
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
}

const PiezometryDiagnosticsApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    // not opti but will do the job until refacto
    const [events, setEvents] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])

    const [filters, setFilters] = useState({})
    /*
    eventTypes: array of string
    causeInterventions: array of string // type:code
    diagnostics: array of number
    networks: array of number
    campaigns: array of number
    administrators: array of number
    stations: array of number
    startDate: number
    endDate: number
    */

    const {
        isLoaded,
        progress,
    } = useProgressDispatch(() => [
        dispatch(PiezometryAction.fetchPiezometersLight()),
        dispatch(NetworkAction.fetchNetworks()),
        dispatch(PiezometryAction.fetchNetworkPiezoLink()),
        dispatch(PiezometryAction.fetchPiezometersReferents()),
        dispatch(ReferencialAction.fetchSandreCodes()),
        dispatch(CityAction.fetchCities()),
        dispatch(EventsAction.fetchPiezometerDiagnostics()),
        dispatch(EventsAction.fetchPiezometerSolutions()),
        dispatch(CampaignAction.fetchPiezometryCampaigns()),
        dispatch(CentralAction.fetchCentralTypes()),
        dispatch(PowerSupplyAction.fetchPowerSupplyTypes()),
        dispatch(SensorAction.fetchSensorTypes()),
        dispatch(VariousMaterielAction.fetchVariousMaterielTypes()),
        dispatch(EquipmentAction.fetchEquipmentTypes()),
        dispatch(SimAction.fetchSimTypes()),
        dispatch(TelecomAction.fetchTelecomTypes()),
        dispatch(SubscriptionAction.fetchSubscriptionTypes()),
        EventsAction.getPiezometryInterventionEvents().then(allEvents => {
            setEvents(allEvents)
            setFilteredEvents(allEvents)
        }),
    ], [dispatch])

    const { value: isEventLoaded, setTrue: eventLoaded, setFalse: eventNotLoaded } = useBoolean(true)
    useUpdateEffect(() => {
        eventNotLoaded()
        EventsAction.getPiezometryInterventionEvents(filters)
            .then(setFilteredEvents).finally(eventLoaded)
    }, [filters])

    useTitle(() => [{
        title: i18n.piezometry,
        href: 'piezometry',
    }, {
        title: i18n.diagnosticsTable,
        href: 'disgnostics',
    }], [])

    const currentYear = moment().year()
    const lastYearEvents = events.filter(e => getYear(e.date) === currentYear)

    const colors = useMemo(() => {
        const causes = events.filter(e => e.interventions).flatMap(e => e.interventions)
        const generatedColors = generateRandomColor(causes.length)
        return causes.reduce((acc, cur, i) => ({ ...acc, [cur]: generatedColors[i] }), { undefined: '#999999' })
    }, [events])

    useActions(() => ({
        exportList: [{
            onClick: openExportModal,
            label: i18n.excel,
        }],
    }), [])

    if (!isLoaded) {
        return (
            <ProgressCard progress={progress} />
        )
    }

    return (
        <Grid container spacing={2} sx={{ width: 1400, marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid container item xs={9} direction='column' justifyContent='flex-start'>
                <Grid item>
                    <Card>
                        <GraphHistoric events={events} colors={colors} />
                        <FilterField onValidate={setFilters} isLoaded={isEventLoaded} />
                    </Card>
                </Grid>
                <Grid item>
                    <SimpleTabList
                        defaultTab={DATE}
                        tabs={[
                            {
                                constant: DATE,
                                label: i18n.byDate,
                                icon: 'insert_invitation',
                            },
                            {
                                constant: STATION,
                                label: i18n.byStation,
                                icon: 'location_on',
                            },
                            {
                                constant: EVENT_TYPE,
                                label: i18n.byEventType,
                                icon: 'donut_small',
                            },
                            {
                                constant: CAUSE_INTERVENTION,
                                label: i18n.byCauseIntervention,
                                icon: 'build',
                            },
                            {
                                constant: REFERENT,
                                label: i18n.byReferent,
                                icon: 'person',
                            },
                        ]}
                        style={{ padding: 10 }}
                    >
                        {tab => {
                            if (!filteredEvents.length) {
                                return (
                                    <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                                )
                            }
                            return (
                                <>
                                    {tab === DATE && <EventsGroupByDate events={filteredEvents} />}
                                    {tab === REFERENT && <EventsGroupByReferent events={filteredEvents} />}
                                    {tab === EVENT_TYPE && <EventsGroupByEventType events={filteredEvents} />}
                                    {tab === STATION && <EventsGroupByStation events={filteredEvents} />}
                                    {tab === CAUSE_INTERVENTION && <EventsGroupByCauseIntervention events={filteredEvents} />}
                                </>
                            )
                        }}
                    </SimpleTabList>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Card>
                    <Grid container spacing={2} sx={{ padding: '10px 0' }} direction='column' justifyContent='flex-start'>
                        <Grid item>
                            <Legend colors={colors}/>
                        </Grid>
                        <Grid item>
                            <PieChart events={events} title={i18n.history} colors={colors} />
                        </Grid>
                        <Grid item>
                            <GraphEventType events={events} />
                        </Grid>
                        <Grid item>
                            <PieChart events={lastYearEvents} title={`${currentYear}`} colors={colors} />
                        </Grid>
                        <Grid item>
                            <GraphEventType events={lastYearEvents} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <ExportModal
                isOpen={isExportModalOpen}
                close={closeExportModal}

                events={events}
            />
        </Grid>
    )
}

export default PiezometryDiagnosticsApp