import { H_REFERENCIAL_TAXON } from 'account/constants/AccessRulesConstants'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import TaxonAction from '../actions/TaxonAction'
import TaxonApp from './TaxonApp'
import TaxonCarto from './TaxonCarto'

const TaxonRouterApp = ({ match: { params: { code } } }) => {
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const dispatch = useDispatch()

    const navLinks = useMemo(() => {
        return [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: `/referencial/${REFERENCIAL_TYPE_NAME.taxon}/${code}/dashboard`,
            habilitation: H_REFERENCIAL_TAXON,
        }, {
            icons: 'map',
            name: i18n.cartography,
            href: `/referencial/${REFERENCIAL_TYPE_NAME.taxon}/${code}/map`,
            habilitation: H_REFERENCIAL_TAXON,
        }].filter(l => componentHasHabilitations(l.habilitation))
    }, [code])

    useEffect(() => {
        if (!taxons.length) {
            dispatch(TaxonAction.fetchTaxons())
        }
        if (code !== 'new') {
            dispatch(TaxonAction.fetchTaxon(code))
        }
    }, [dispatch, code])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/referencial/taxon/:code' to='/referencial/taxon/:code/dashboard' />
                                <Route path='/referencial/taxon/:code/dashboard' render={(props) => <TaxonApp {...props} />} />
                                <Route path='/referencial/taxon/:code/map' render={(props) => <TaxonCarto {...props} />} />
                            </Switch>
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

TaxonRouterApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }),
}

export default TaxonRouterApp