import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
// import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import ActionComponent from '../../../components/ActionComponent'
import { arrayOf } from '../../../utils/StoreUtils'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import DtoSurvey from '../../dto/DtoSurvey'
import FormFilterSurveys from '../panels/FormFilterSurveys'
import SurveyCard from '../panels/SurveyCard'
import { groupBy, orderBy, reverse } from 'lodash'
import { getSandreLabel } from 'utils/StringUtil'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { Button, Dialog, Grid } from '@mui/material'
import NumberField from 'components/forms/NumberField'
import Row from 'components/react/Row'
import DtoSurveyWithStats from '../../dto/DtoSurveyWithStats'
import { getUser } from '../../../utils/SettingUtils'
import ProgressCard from 'components/card/ProgressCard'
import Input from 'components/forms/Input'
import ToastrAction from 'toastr/actions/ToastrAction'
import TabList from 'components/list/TabList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import Select from 'components/forms/Select'
import EvolVolumesChartPanel from '../panels/EvolVolumesChartPanel'
import HomeAction from 'home/actions/HomeAction'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'

class SurveysDashboardApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            filter: {},
            sortBy: 'statut',
            newSurvey: new DtoSurvey({ surveyType: 1, contributorId: props.accountUser.contributorCode }),
            dataLoaded: false,
        }
    }

    componentDidMount = () => {
        this.props.fetchSurveysWithStats().then(() => {
            this.setState({ dataLoaded: true })
        })
        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        this.getActions()
    }

    componentDidUpdate = () => {
        this.setTitle()
        this.getActions()
    }

    setTitle = () => {
        this.props.setTitle([{
            title: i18n.planning,
            href: 'planning/dashboard',
        }, {
            title: i18n.surveys,
            href: 'planning/surveys',
        }])
    }

    getActions = () => {
        const actions = getUser().isAdmin === '1' || getUser().metadata === '1' ? {
            new: () => this.setState({ open: true }),
        } : {}
        this.setActions(actions)
    }

    getSurveysByStatus = surveys => {
        const groupedSurveys = groupBy(surveys, 'statusCode')

        return Object.keys(groupedSurveys).map(key => {
            return this.getPanelList(groupedSurveys[key], getSandreLabel(this.props.sandreCodes, SANDRE.CMS_STATUT, key))
        })
    }

    getSurveysByDate = surveys => {
        const groupedSurveys = groupBy(surveys, 'year')

        return reverse(Object.keys(groupedSurveys).map(key => {
            return this.getPanelList(groupedSurveys[key], key)
        }))
    }

    getSurveysByOrganism = surveys => {
        const groupedSurveys = groupBy(surveys, 'organism')

        return Object.keys(groupedSurveys).map(key => {
            return this.getPanelList(groupedSurveys[key], key === '' ? i18n.none : key)
        })
    }

    getPanelList = (groups, title) => (
        <div className='padding-1'>
            <AccordionMUI defaultExpanded round>
                <AccordionSummaryMUI round>
                    {title}
                </AccordionSummaryMUI>
                <AccordionDetailsMUI nopadding>
                    {groups.map(survey => (
                        <SurveyCard
                            survey={survey}
                            progress={{
                                nbDeclarations: survey.nbDeclarations,
                                nbDeclarationsValidated: survey.nbDeclarationsValidated,
                            }}
                        />
                    ))}
                </AccordionDetailsMUI>
            </AccordionMUI>
        </div>
    )

    getFilteredSurveys = () => {
        const { filter } = this.state
        const { surveysWithStats } = this.props

        const startDateFilter = filter.startDate ? surveysWithStats.filter(survey => survey.startDate >= filter.startDate) : surveysWithStats
        const endDateFilter = filter.endDate ? startDateFilter.filter(survey => survey.endDate <= filter.endDate) : startDateFilter
        const statusFilter = filter.status ? endDateFilter.filter(survey => survey.statusCode === filter.status) : endDateFilter

        return orderBy(statusFilter, 'year', 'desc')
    }

    getSurveys = () => {
        const { sortBy } = this.state
        const { contributors } = this.props

        const filteredSurveys = this.getFilteredSurveys().map((s) => ({
            ...s,
            organism: s.contributorId ? contributors.find((c) => c.id === s.contributorId)?.name : '',
        }))

        switch (sortBy) {
            case 'statut':
                return this.getSurveysByStatus(filteredSurveys)
            case 'date':
                return this.getSurveysByDate(filteredSurveys)
            case 'organism':
                return this.getSurveysByOrganism(filteredSurveys)
            default:
                return []
        }
    }

    handleOnSave = () => {
        const { newSurvey } = this.state
        if (newSurvey.year && newSurvey.name && newSurvey.surveyType) {
            this.props.createSurvey({
                ...newSurvey,
                idSurvey: -1,
                statusCode: 2,
            }).then((json) => {
                this.props.fetchSurveysWithStats()
                this.props.push(`/survey/${json.id}`)
            })
        } else {
            this.props.error(i18n.pleaseCompleteAllRequiredField)
        }
    }

    render = () => {
        const { open, newSurvey, dataLoaded } = this.state
        const { contributors } = this.props

        return (
            <div className='row no-margin' style={{ padding: 10 }}>
                <Grid container spacing={2} alignItems='flex-start'>
                    <Grid item xs={8}>
                        <FormFilterSurveys
                            onChange={obj => this.setState({ filter: obj })}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <EvolVolumesChartPanel
                            statistics={this.props.surveysWithStats}
                            keyVolume='requestedVolume'
                            select
                            selectOptions={[{
                                value: 'requestedVolume',
                                label: i18n.requestedVolume,
                            }, {
                                value: 'allocatedVolume',
                                label: i18n.allocatedVolume,
                            }, {
                                value: 'allowedVolume',
                                label: i18n.allowedVolume,
                            }]}
                        />
                    </Grid>
                </Grid>
                <TabList
                    onChangeTab={(v) => this.setState({ sortBy: v })}
                    tabs={[{
                        value: 'statut',
                        label: i18n.byStatus,
                        icon: 'edit',
                    },
                    {
                        value: 'date',
                        label: i18n.perYear,
                        icon: 'insert_invitation',
                    },
                    {
                        value: 'organism',
                        label: i18n.byOrganism,
                        icon: 'person',
                    }]}
                >
                    { dataLoaded ? this.getSurveys() : <ProgressCard indeterminate round /> }
                </TabList>
                <Dialog
                    fullWidth
                    maxWidth='sm'
                    open={open}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.createSurvey}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ open: false })} />
                    </DialogTitleMUI>
                    <DialogContentMUI className='padding-top-1'>
                        <Row>
                            <Input
                                col={6}
                                title={i18n.name}
                                value={newSurvey.name}
                                onChange={value => this.setState({ newSurvey: { ...newSurvey, name: value } })}
                                obligatory
                            />
                            <NumberField
                                col={6}
                                title={i18n.year}
                                value={newSurvey.year}
                                onChange={value => this.setState({ newSurvey: { ...newSurvey, year: value } })}
                                obligatory
                            />
                            <Select
                                col={6}
                                label={i18n.type}
                                options={[{
                                    value: 1,
                                    label: 'Annuelle',
                                }, {
                                    value: 2,
                                    label: 'Intermédiaire',
                                }]}
                                value={newSurvey.surveyType}
                                integerValue
                                onChange={value => this.setState({ newSurvey: { ...newSurvey, surveyType: value } })}
                                noNullValue
                                obligatory
                            />
                            <Select
                                col={6}
                                label={i18n.organism}
                                options={contributors}
                                value={newSurvey.contributorId}
                                keyValue='id'
                                onChange={value => this.setState({ newSurvey: { ...newSurvey, contributorId: value } })}
                            />
                        </Row>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button onClick={this.handleOnSave} variant='contained' color='primary'>
                            {i18n.save}
                        </Button>
                    </DialogActionsMUI>
                </Dialog>
            </div>
        )
    }
}

SurveysDashboardApp.propTypes = {
    surveysWithStats: arrayOf(DtoSurveyWithStats),
}

const mapStateToProps = store => ({
    surveysWithStats: store.AgriReducer.surveysWithStats,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    accountUser: store.AccountReducer.accountUser,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    push,
    fetchSurveysWithStats: AgriAction.fetchSurveysWithStats,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    setTitle: HomeAction.setTitle,
    createSurvey: AgriAction.createSurvey,
    error: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveysDashboardApp)
