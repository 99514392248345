import { PICTURE } from 'components/file/constant/FileConstants'
import ApplicationConf from 'conf/ApplicationConf'
import moment from 'moment'
import { hasValue } from 'utils/NumberUtil'

export default class DtoPicture {
    constructor(obj) {
        const pointIndex = obj.name.lastIndexOf('.')
        this.code = obj.code
        this.name = obj.name
        const sliced = obj.name.slice(obj?.stationCode?.length + 1, pointIndex).split('_')
        const [,, shortNameOld, dateOld] = sliced
        const [type, active, date, stationTypeOrShortName, shortName] = sliced
        const [stationCode] = obj.name.slice(0, pointIndex).split('_')
        this.isAllDataName = sliced.length === 4 || sliced.length === 5
        this.url = ApplicationConf.picture.pictureCompressPath(obj.name)
        this.stationCode = obj.stationCode || stationCode
        this.type = type
        this.active = active || '0'
        this.shortName = new RegExp('^[0-9]+-[0-9]+$').test(date) ? (shortName || stationTypeOrShortName) : shortNameOld
        this.date = new RegExp('^[0-9]+-[0-9]+$').test(date) ? moment(date, 'YYYYMMDD-HHmmss').valueOf() : parseInt(dateOld)
        this.fileType = PICTURE
        this.stationType = shortName ? stationTypeOrShortName : null
        this.shared = !hasValue(shortName)
    }
}