import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import { useDispatch, useSelector } from 'react-redux'
import DistributionUnitAction from 'distributionUnit/actions/DistributionUnitAction'
import { useParams } from 'react-router-dom'
import DistributionUnitCounterTable from './DistributionUnitCounterTable'
import { Grid } from '@mui/material'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'

const DistributionUnitCounterManager = ({
}) => {
    const dispatch = useDispatch()
    const {
        distributionCounterTypes,
        distributionCounters,
        distributionUnit,
    } = useSelector(store => ({
        distributionCounterTypes: store.DistributionUnitReducer.distributionCounterTypes,
        distributionCounters: store.DistributionUnitReducer.distributionCounters,
        distributionUnit: store.DistributionUnitReducer.distributionUnit,
    }))

    const { id } = useParams()

    useEffect(() => {
        if (!distributionCounterTypes.length) {
            dispatch(DistributionUnitAction.fetchDistributionCounterTypes())
        }
    }, [])

    useEffect(() => {
        dispatch(DistributionUnitAction.fetchCounters({ ids: [parseInt(id)] }))
    }, [id])

    useTitle(() => {
        return [{
            title: i18n.distributionUnit,
            href: 'distributionUnit',
        }, {
            title: getStationTitle(distributionUnit),
            href: `station/distributionUnit/${id}`,
        }, {
            title: i18n.counters,
            href: `station/distributionUnit/${id}/counters`,
        }]
    }, [distributionCounters, id])

    return (
        <Grid style={{ padding: '10 10 100 20' }}>
            <DistributionUnitCounterTable data={distributionCounters} />
        </Grid>
    )
}

DistributionUnitCounterManager.propTypes = {
}

export default DistributionUnitCounterManager