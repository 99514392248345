/* eslint-disable camelcase */
import { Grid } from '@mui/material'
import AdministrationAction from 'administration/actions/AdministrationAction'
import ActionComponent from 'components/ActionComponent'
import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import NumberField from 'components/forms/NumberField'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ProgressBar from 'components/progress/ProgressBar'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import HomeAction from 'home/actions/HomeAction'
import { isEmpty, orderBy, xor } from 'lodash'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import UsageAction from 'referencial/components/usage/actions/UsageAction'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import { getSetting, getSettingInt } from 'utils/SettingUtils'

class TerritorySettingsParamsApp extends ActionComponent {
    state = {
        dataLoaded: false,
        matTypesLoaded: false,
        readMode: true,
        usagesInTerritoryCalcul: [],
        nbRotationsCultMax: 6,
        lowWaterStartDate: null,
        lowWaterEndDate: null,
        materielsTypeForIndex: [],
    }

    componentDidMount() {
        const { applicationSettings } = this.props
        if (!applicationSettings.length) {
            this.props.fetchApplicationSettings().then(() => {
                this.initState()
            })
        } else {
            this.initState()
        }
        this.props.setTitle([{
            title: i18n.steering,
            href: 'territory',
        }, {
            title: i18n.settings,
            href: 'territory/settings',
        }, {
            title: i18n.parameters,
            href: 'territory/settings/parameters',
        }])
    }

    setReadMode = () => {
        this.setState({ readMode: true })
        this.setActions({ edit: () => this.setEditMode() })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            save: () => this.onSave(),
            cancel: () => this.onCancel(),
        })
    }

    initState = () => {
        const { applicationSettings } = this.props
        this.setReadMode()
        this.props.fetchVariousMaterielTypes().then(() => this.setState({ matTypesLoaded: true }))
        this.props.fetchUsages().then(() => {
            const usagesInTerritoryCalcul = (getSetting(applicationSettings, 'usagesInTerritoryCalcul') || '').split(' ').filter((uId) => uId !== '')
            const nbRotationsCultMax = getSettingInt(applicationSettings, 'nbRotationsCultMax') || 6
            const lowWaterStartDate = getSettingInt(applicationSettings, 'lowWaterStartDate')
            const lowWaterEndDate = getSettingInt(applicationSettings, 'lowWaterEndDate')
            const materielsTypeForIndex = (getSetting(applicationSettings, 'materielsTypeForIndex') || '').split(',').map((id) => Number(id))
            this.setState({
                dataLoaded: true,
                usagesInTerritoryCalcul,
                nbRotationsCultMax,
                lowWaterStartDate,
                lowWaterEndDate,
                materielsTypeForIndex,
            })
        })
    }

    onSave = () => {
        const { usagesInTerritoryCalcul, nbRotationsCultMax, lowWaterStartDate, lowWaterEndDate, materielsTypeForIndex } = this.state
        const settings = [
            { parameter: 'usagesInTerritoryCalcul', value: usagesInTerritoryCalcul.reduce((acc, i) => `${acc} ${i}`, '') },
            { parameter: 'nbRotationsCultMax', value: hasValue(nbRotationsCultMax) ? String(nbRotationsCultMax) : '6' },
            { parameter: 'lowWaterStartDate', value: hasValue(lowWaterStartDate) ? String(lowWaterStartDate) : null },
            { parameter: 'lowWaterEndDate', value: hasValue(lowWaterEndDate) ? String(lowWaterEndDate) : null },
            { parameter: 'materielsTypeForIndex', value: `${materielsTypeForIndex}` },
        ]
        this.setState({ dataLoaded: false })
        this.props.updateSieauParameters(settings).then(() => {
            this.props.fetchApplicationSettings().then(() => {
                this.initState()
            })
        })
    }

    onCancel = () => {
        this.initState()
    }

    onChangeUsages = (id) => {
        const { usagesInTerritoryCalcul } = this.state
        const { usages } = this.props

        if (!usagesInTerritoryCalcul.includes('all')) {
            if (id === 'all') {
                this.setState({ usagesInTerritoryCalcul: ['all'] })
            } else if (usagesInTerritoryCalcul.includes(id)) {
                this.setState({ usagesInTerritoryCalcul: usagesInTerritoryCalcul.filter((uId) => uId !== id) })
            } else if (isEmpty(xor(usages.map((u) => `${u.id}`), [...usagesInTerritoryCalcul, id]))) {
                this.setState({ usagesInTerritoryCalcul: ['all'] })
            } else {
                this.setState({ usagesInTerritoryCalcul: [...usagesInTerritoryCalcul, id] })
            }
        } else {
            this.setState({ usagesInTerritoryCalcul: [...usages.map((u) => `${u.id}`), ...usagesInTerritoryCalcul].filter((uId) => uId !== id).filter((uId) => uId !== 'all') })
        }
    }

    render() {
        const { usages, variousMaterielTypes } = this.props
        const { readMode, dataLoaded, matTypesLoaded, usagesInTerritoryCalcul, nbRotationsCultMax, lowWaterStartDate, lowWaterEndDate, materielsTypeForIndex } = this.state

        return (
            <div className='padding-1'>
                {dataLoaded && matTypesLoaded ? (
                    <Card title={i18n.parameters} round>
                        <div className='padding-bottom-1 padding-left-1 padding-right-1'>
                            <StyledFieldSet className='margin-1'>
                                <StyledLegend>{i18n.usagesForTotalVolumesRequested}</StyledLegend>
                                <Grid container spacing={1} className='margin-top-1 padding-bottom-1'>
                                    <Checkbox
                                        col
                                        label={i18n.everybody}
                                        checked={ (usagesInTerritoryCalcul || []).includes('all') }
                                        onChange={() => this.onChangeUsages('all')}
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid container spacing={1}>
                                    {orderBy(usages, 'description').map((u) => (
                                        <Checkbox
                                            col
                                            label={u.description}
                                            checked={(usagesInTerritoryCalcul || []).some(r=> [`${u.id}`, 'all'].includes(r))}
                                            onChange={() => this.onChangeUsages(`${u.id}`)}
                                            disabled={readMode}
                                        />
                                    ))}
                                </Grid>
                            </StyledFieldSet>
                            <StyledFieldSet className='margin-1'>
                                <StyledLegend>{i18n.usages}</StyledLegend>
                                <Grid container spacing={1} className='padding-top-1'>
                                    <Grid item xs={3}>
                                        <NumberField
                                            col={12}
                                            title={i18n.nbRotationsCultMax}
                                            value={nbRotationsCultMax}
                                            onChange={(v) => this.setState({ nbRotationsCultMax: v })}
                                            disabled={readMode}
                                            min={1}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                            <StyledFieldSet className='margin-1'>
                                <StyledLegend>{i18n.lowWater}</StyledLegend>
                                <Grid container spacing={1} className='padding-top-1'>
                                    <Grid item xs={3}>
                                        <SimpleDatePicker
                                            col={12}
                                            label={i18n.startDate}
                                            value={lowWaterStartDate}
                                            onChange={(v) => this.setState({ lowWaterStartDate: v })}
                                            disabled={readMode}
                                            max={lowWaterEndDate}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleDatePicker
                                            col={12}
                                            label={i18n.endDate}
                                            value={lowWaterEndDate}
                                            onChange={(v) => this.setState({ lowWaterEndDate: v })}
                                            disabled={readMode}
                                            min={lowWaterStartDate}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                            <StyledFieldSet className='margin-1'>
                                <StyledLegend>{i18n.materiels}</StyledLegend>
                                <Grid container spacing={1} className='padding-top-1'>
                                    <Grid item xs={6}>
                                        <MultiAutocomplete
                                            col={12}
                                            label={i18n.materielsForIndex}
                                            values={materielsTypeForIndex}
                                            onChange={values => this.setState({ materielsTypeForIndex: values })}
                                            clearFunction
                                            integerValue
                                            elements={orderBy(variousMaterielTypes, ['category', 'name'])}
                                            disabled={readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        </div>
                    </Card>
                ) : <ProgressBar indeterminate />}
            </div>
        )
    }
}

TerritorySettingsParamsApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    fetchVariousMaterielTypes: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        applicationSettings: store.AdministrationReducer.applicationSettings,
        usages: store.UsageReducer.usages,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    }
}

const mapDispatchToProps = {
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    fetchApplicationSettings: AdministrationAction.fetchApplicationSettings,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    setTitle: HomeAction.setTitle,
    fetchUsages: UsageAction.fetchUsages,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritorySettingsParamsApp)