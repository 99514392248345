import React, { useMemo, useState } from 'react'
import { inseeActivitiesDefaultLink } from 'conf/SieauConstants'
import { push } from 'connected-react-router'
import { sortBy } from 'lodash'
import { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import logoInsee from '../../../../assets/pictures/logo-insee.png'
import HomeAction from '../../../../home/actions/HomeAction'
import { PATH_REFERENCIAL } from '../../../../home/constants/RouteConstants'
import { getSandreLabel } from '../../../../utils/StringUtil'
import ReferencialAction from '../../../action/ReferencialAction'
import { REFERENCIAL_TYPE_NAME, SANDRE } from '../../../constants/ReferencialConstants'
import ActivityAction from '../actions/ActivityAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import useActions from 'utils/customHook/useActions'
import SearchTable from 'referencial/components/SearchTable'

const headers = ['codeactivite', 'codeactivitev2', 'libelle', 'categorieactivite', 'section']

const ActivitiesApp = () => {
    const {
        activities,
        sandreCodes,
    } = useSelector(store => ({
        activities: store.ActivityReducer.activities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])
    const [activitiesFormatted, setActivitiesFormatted] = useState([])

    const dispatch = useDispatch()

    useEffect(() => setActivitiesFormatted(sortBy(activities, 'codeactivite')), [activities])

    useEffect(() => {
        dispatch(ActivityAction.fetchActivities(), [])
        dispatch(HomeAction.setHelpLink('', ''))
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.activities,
        href: '/referencial/activity',
    }], [])

    const activitiesList = useMemo(() => activitiesFormatted.map(a => ({
        ...a,
        categorieactivite: getSandreLabel(sandreCodes, SANDRE.ACTIVITES_CATEGORIE, a.categorieactivite),
    })), [activitiesFormatted, sandreCodes])

    const data = useMemo(() => getExportReferencialData(activitiesList), [activitiesList])

    useActions(() => {
        return {
            new: () => dispatch(push('/referencial/activity/new')),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.activities,
                    },
                },
            },
            links: [
                {
                    href: inseeActivitiesDefaultLink,
                    img: logoInsee,
                    label: i18n.insee.toUpperCase(),
                },
            ],
        }
    }, [exportData])

    return (
        <SearchTable
            sessionKey={REFERENCIAL_TYPE_NAME.activity}
            title={i18n.activities}
            data={data}
            setExportData={setExportData}
            headers={headers}
            customHeaders={{
                codeactivite: i18n.code,
                codeactivitev2: 'Code V2',
                categorieactivite: i18n.categoryActivity,
            }}
            onClick={activity => dispatch(push(`/referencial/activity/${activity.id}/dashboard`))}
        />
    )
}

export default ActivitiesApp
