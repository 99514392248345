import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { H_MAT_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CampaignCard from '../../../campaign/components/CampaignCard'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import Card from '../../../components/card/Card'
import PanelList from '../../../components/list/panelList/PanelList'
import SieauAction from '../../../components/sieau/SieauAction'
import EventsAction from '../../../events/actions/EventsAction'
import MonitoredStationCard from '../../../events/components/MonitoredStationCard'
import MonitoredStationDto from '../../../events/dto/MonitoredStationEventDto'
import MaterielAction from '../../../materiel/actions/MaterielAction'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import QualityAction from '../../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import AppStore from '../../../store/AppStore'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getUser } from '../../../utils/SettingUtils'
import MonitoredMaterielDto from '../../dto/MonitoredMaterielDto'
import MonitoredMaterielPortletPanel from './MonitoredMaterielPortletPanel'


class MaterielDashboardApp extends Component {
    getTitle = label => <h5>{ label }</h5>

    getPanelList = (title, list) => list && list.length !== 0 && <PanelList title={ title } collapsible dontOpenFirst>{ list }</PanelList>

    getCurrentCampaigns = () => this.props.piezometryCampaigns.reduce((acc, c) => {
        const { nbStation, nbStationValidated } = this.props.piezometryCampaignsProgress.find(cp => cp.id === c.id) || {}
        if (nbStation !== 0 && nbStationValidated < nbStation) {
            return [
                ...acc,
                c,
            ]
        }
        return acc
    }, [])

    render() {
        const piezometryCampaignCards = this.getCurrentCampaigns().filter(c => c.statut === 2).map(c => (
            <CampaignCard
                campaign={c}
                progress={this.props.piezometryCampaignsProgress.find(cp => cp.id === c.id)}
                stationType={'piezometry'}
            />
        ))
        const currentPiezometryCampaigns = this.getPanelList(this.getTitle(i18n.currentPiezometryCampaigns), piezometryCampaignCards)

        const stationCards = this.props.monitoredStations.map(s => <MonitoredStationCard station={s} onClickEnable={!(getUser().consultant === '1')} />)
        const stations = this.getPanelList(this.getTitle(i18n.monitoredStations), stationCards)

        const monitoredMateriels = this.props.monitoredMateriels.filter(m => this.props.piezometers.find(p => p.id === m.idSite)
            || this.props.qualitometers.find(p => p.id === m.idSite))
        const materielCards = monitoredMateriels.length ? <MonitoredMaterielPortletPanel allStationType showTitle={false}/> : null
        const materiels = this.getPanelList(this.getTitle(i18n.monitoredMateriels), materielCards)

        return (
            <Card maxWidth={ 1200 } className='no-box-shadow transparent padding-top-1' contentClassName='transparent'>
                <ul className='sieau-collapsible popout' data-collapsible='expandable'>
                    { [currentPiezometryCampaigns, stations, materiels] }
                </ul>
            </Card>
        )
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_MAT_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        AppStore.dispatch(EventsAction.fetchMonitoredStations())
        AppStore.dispatch(MaterielAction.fetchMonitoredMateriels())
        // AppStore.dispatch(PiezometryAction.fetchPiezometersLight())
        AppStore.dispatch(CampaignAction.fetchPiezometryCampaigns())
        AppStore.dispatch(CampaignAction.fetchPiezometryCampaignsProgress())
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.materiel,
            href: 'materiel',
        }, {
            title: i18n.dashboard,
            href: 'materiel',
        }]))
        this.expandAll()
        if (!this.props.piezometers.length) {
            AppStore.dispatch(PiezometryAction.fetchAllPiezometers())
        }
        if (!this.props.qualitometers.length) {
            AppStore.dispatch(QualityAction.fetchQualitometersLight())
        }
    }

    componentDidUpdate() {
        this.expandAll()
    }

    expandAll = () => {
        $('.collapsible-header').map(index => {
            const elem = $($('.collapsible-header')[index])
            if (!elem.hasClass('active')) {
                $('.sieau-collapsible').collapsible('open', index)
            }
        })
    }
}

MaterielDashboardApp.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    getLink: PropTypes.func,
    monitoredStations: PropTypes.arrayOf(PropTypes.instanceOf(MonitoredStationDto)),
    monitoredMateriels: PropTypes.arrayOf(PropTypes.instanceOf(MonitoredMaterielDto)),
    piezometers: PropTypes.instanceOf(DtoPiezometerLight),
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    piezometryCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    qualitometers: PropTypes.instanceOf(DtoQualitometerLight),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    monitoredStations: store.EventsReducer.monitoredStations,
    monitoredMateriels: store.MaterielReducer.monitoredMateriels,

    piezometers: store.PiezometryReducer.piezometersLight,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    piezometryCampaignsProgress: store.PiezometryReducer.piezometryCampaignsProgress,

    qualitometers: store.QualityReducer.qualitometersLight,
    push,
})

export default connect(mapStateToProps)(MaterielDashboardApp)
