import { orderBy, round } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import ListComponent from '../../../components/list/tableList/ListComponent'
import SieauAction from '../../../components/sieau/SieauAction'
import { contentsPath } from '../../../conf/basepath'
import AppStore from '../../../store/AppStore'
import { getDate } from '../../../utils/DateUtil'
import { getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'

const propsToFetch = {
    lastDocuments: true,
}

class DocumentsApp extends ActionComponent {
    componentWillMount() {
        AppStore.dispatch(SieauAction.fetch(propsToFetch))
    }

    getSize = (size) => {
        const sizedValue = ['ko', 'Mo', 'Go', 'To'].reduce((acc, value) => {
            if (acc.size <= 800) {
                return acc
            }
            return {
                size: round(acc.size / 1000, 2),
                category: value,
            }
        }, { size, category: 'o' })
        return `${sizedValue.size} ${sizedValue.category}`
    }

    getTreeObject = () => {
        const files = []
        const documentsUrl = `${contentsPath}DOCUMENTS/GENERAL/`
        this.props.lastDocuments.map(file => {
            if (file.url.includes(documentsUrl)) {
                const relativePath = file.url.replace(documentsUrl, '')
                const route = relativePath.split('/')
                if (route.length === 1) {
                    files.push(Object.assign({}, { type: 'file' }, file))
                } else {
                    let currentDir = files
                    route.map((element, index) => {
                        if (index + 1 === route.length) {
                            currentDir.push(Object.assign({}, { type: 'file' }, file))
                        } else if (currentDir.find(dir => dir.name === element)) {
                            currentDir = currentDir.find(dir => dir.name === element).files
                        } else {
                            const newDir = {
                                type: 'dir',
                                name: element,
                                files: [],
                            }
                            currentDir.push(newDir)
                            currentDir = newDir.files
                        }
                    })
                }
            }
        })
        return files
    }

    getTreeLine = (file, deep) => {
        if (file.type === 'file') {
            return (
                <div className='padding-left-1' >
                    <a href={ file.url } target='_blank'>
                        <div className={ `row no-margin valign-wrapper padding-top-1 padding-bottom-1 padding-left-${deep}` }>
                            <div className='col s1 no-margin center-align'>
                                <i className='material-icons'>insert_drive_file</i>
                            </div>
                            <div className='col s7 no-padding no-margin'>
                                <h6>
                                    <b className='bold font-size-16'>{ file.name }</b>
                                </h6>
                            </div>
                            <div className='col s2 no-padding no-margin'>
                                { getDate(file.updateDate, 'DD/MM/YYYY  HH:mm') }
                            </div>
                            <div className='col s2 no-padding no-margin'>
                                { this.getSize(file.size) }
                            </div>
                        </div>
                    </a>
                </div>
            )
        }
        const dirObj = {
            title: (
                <div className='col s12' >
                    <a href={ file.url } target='_blank'>
                        <div className={ `row no-margin valign-wrapper padding-top-1 padding-bottom-1 padding-left-${deep}` }>
                            <div className='col s1 no-margin center-align'>
                                <i className='material-icons no-margin'>folder</i>
                            </div>
                            <div className='col s7 no-padding no-margin'>
                                <h6>
                                    <b className='bold font-size-16'>{ file.name }</b>
                                </h6>
                            </div>
                        </div>
                    </a>
                </div>
            ),
            component: orderBy(file.files, 'type').map(dirContent => this.getTreeLine(dirContent, deep + 2)),
        }
        return <ListComponent tables={ [dirObj] } displayBlock />
    }

    render() {
        return <Card>{ orderBy(this.getTreeObject(), 'type').map(content => this.getTreeLine(content, 0)) }</Card>
    }
}

DocumentsApp.propTypes = getPropTypes(propsToFetch)

const mapStateToProps = () => getMapStateToProps(propsToFetch)

export default connect(mapStateToProps)(DocumentsApp)
