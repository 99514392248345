import MultiGridTableV2 from 'components/datatable/virtualizedTable/MultiGridTableV2'
import PropTypes from 'prop-types'
import { groupBy, maxBy, meanBy, minBy, orderBy, round, uniqBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { calculateGeometricAverage, calculatePercentile90 } from 'utils/AnalyseUtils'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { getLabel } from 'utils/StoreUtils'
import { statusIcon } from 'utils/StatusUtil'
import Icon from 'components/icon/Icon'
import useBoolean from 'utils/customHook/useBoolean'
import { IndiceGraphModal } from 'quality/components/qualityComponents/IndiceGraph'
import DtoSearchIndices from 'quality/dto/DtoSearchIndices'
import DtoOperation from 'quality/components/operation/dto/DtoOperation'
import MessageCard from 'components/card/MessageCard'

const STATS_HEADERS = ['min', 'averageShort', 'max', 'percentile90', 'geometricAverageShort', 'nbResults']

const HeaderCellTootip = ({
    operation = {},
}) => {
    const {
        contributorsIndex,
        status,
        qualifications,
    } = useSelector(store => ({
        contributorsIndex: store.ContributorReducer.contributorsIndex,
        status: store.QualityReducer.status,
        qualifications: store.QualityReducer.qualifications,
    }), shallowEqual)

    const producer = contributorsIndex[operation.producer]
    const producerLabel = producer && (producer.mnemonique || producer.name) || ''

    // const determiner = contributorsIndex[operation.determiner]
    // const determinerLabel = determiner && (determiner.mnemonique || determiner.name) || ''

    return (
        <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
            <Grid item>
                {`${i18n.date}: ${getDate(operation.date)}`}
            </Grid>
            <Grid item>
                {`${i18n.status}: ${getLabel(status, operation.status)}`}
            </Grid>
            <Grid item>
                {`${i18n.qualification}: ${getLabel(qualifications, operation.qualification)}`}
            </Grid>
            <Grid item>
                {`${i18n.producer}: ${producerLabel}`}
            </Grid>
            {/* <Grid item>
                {`${i18n.determiner}: ${determinerLabel}`}
            </Grid> */}
        </Grid>
    )
}

HeaderCellTootip.propTypes = {
    operation: PropTypes.shape({}),
}

const HeaderCell = ({
    operation = {},
}) => {
    const {
        contributorsIndex,
    } = useSelector(store => ({
        contributorsIndex: store.ContributorReducer.contributorsIndex,
    }), shallowEqual)

    const producer = contributorsIndex[operation.producer]
    const producerLabel = producer && (producer.mnemonique || producer.name) || ''

    // const determiner = contributorsIndex[operation.determiner]
    // const determinerLabel = determiner && (determiner.mnemonique || determiner.name) || ''

    return (
        <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
            <Grid item>
                <div className='valign-wrapper'>
                    {statusIcon(operation, 15, false)}
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}> {getDate(operation.date)}</span>
                </div>
            </Grid>
            <Grid item>
                <div className='valign-wrapper'>
                    <Icon icon='widgets' style={{ fontSize: '15px' }} />
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}>{producerLabel}</span>
                </div>
            </Grid>
            {/* <Grid item>
                <div className='valign-wrapper'>
                    <Icon icon='face' style={{ fontSize: '15px' }} />
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}>{determinerLabel}</span>
                </div>
            </Grid> */}
        </Grid>
    )
}

HeaderCell.propTypes = {
    operation: PropTypes.shape({}),
}

const IndiceMonitoringTable = ({
    indices = [],
    operations = [],
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const {
        value: isGraphOpen,
        setTrue: openGraph,
        setFalse: closeGraph,
    } = useBoolean(false)
    const [selectedParam, setSelectedParam] = useState()

    const uniqOperations = useMemo(() => {
        return uniqBy(indices, ({ idOperation, stationId }) => `${idOperation}:${stationId}`)
    }, [indices])

    const operationsFound = useMemo(() => {
        return uniqOperations.map(({ idOperation, stationId }) => operations.find(op => op.id === idOperation && op.qualitometer === stationId)).filter(o => !!o)
    }, [uniqOperations, operations])

    const operationsDatesSort = useMemo(() => {
        return orderBy(operationsFound, ['date', 'qualitometer'], ['desc', 'asc']).map(op => `${op.date}#:#${op.qualitometer}#:#${op.id}`)
    }, [operationsFound])

    const data = useMemo(() => {
        const indicesGroupByParam = groupBy(indices, ({ parameter }) => parameter)
        return Object.keys(indicesGroupByParam).map(paramCode => {
            const { [paramCode]: indicesGroup = [] } = indicesGroupByParam

            const valuesObj = indicesGroup.reduce((acc, indice) => {
                const key = `${indice.sampleDate}#:#${indice.stationId}#:#${indice.idOperation}`
                acc[key] = {
                    justifyContent: 'right',
                    value: indice.result,
                    sortValue: indice.result,
                }
                return acc
            }, {})

            const parameter = parameters.find(t => t.code === paramCode)

            const minValue = minBy(indicesGroup, 'result')
            const maxValue = maxBy(indicesGroup, 'result')
            const averageValue = meanBy(indicesGroup, 'result')
            const sortIndicesGroup = orderBy(indicesGroup, 'result')
            const percentileValue = calculatePercentile90(sortIndicesGroup)
            const geoAverage = calculateGeometricAverage(indicesGroup)
            const nbResults = indicesGroup.length

            return {
                ...valuesObj,
                parameter: {
                    value: parameter?.labelWithCode,
                    tooltip: parameter?.labelWithCode || undefined,
                },
                min: {
                    value: minValue?.result,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                max: {
                    value: maxValue?.result,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                averageShort: {
                    value: round(averageValue, 3),
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                geometricAverageShort: {
                    value: round(geoAverage, 3),
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                percentile90: {
                    value: percentileValue?.result,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                nbResults: {
                    value: nbResults,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                paramCode,
            }
        })
    }, [indices, parameters])

    const customHeaders = useMemo(() => {
        const operationHeaders = operationsDatesSort.reduce((acc, key) => {
            const operation = operations.find(op => `${op.date}#:#${op.qualitometer}#:#${op.id}` === key)
            acc[key] = {
                value: (<HeaderCell operation={operation} />),
                tooltip: (<HeaderCellTootip operation={operation} />),
            }
            return acc
        }, {})

        const statHeaders = {
            nbResults: {
                style: {
                    whiteSpace: 'pre-wrap',
                },
            },
        }
        return {
            ...operationHeaders,
            ...statHeaders,
        }
    }, [operationsDatesSort])

    const filteredIndices = indices.filter(i => i.parameter === selectedParam)

    if (data.length === 0) {
        return (
            <MessageCard>{i18n.noDataToDisplay}</MessageCard>
        )
    }

    return (
        <>
            <MultiGridTableV2
                data={data}
                customHeaders={customHeaders}
                headers={['parameter', ...operationsDatesSort, ...STATS_HEADERS]}
                fixedColumnCount={1}
                headerHeight={45}
                columnWidth={200}
                onClick={({ paramCode }) => {
                    setSelectedParam(paramCode)
                    openGraph()
                }}
            />
            <IndiceGraphModal
                isOpen={isGraphOpen}
                indices={filteredIndices}
                closeGraph={closeGraph}
            />
        </>
    )
}

IndiceMonitoringTable.propTypes = {
    indices: PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchIndices)),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
}

export default IndiceMonitoringTable