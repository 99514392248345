import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import { getSandreList } from 'utils/StoreUtils'
import Table from '../../../../components/datatable/Table'
import Select from '../../../../components/forms/Select'
import { placeOptions } from '../../../../quality/components/operation/constants/OperationDescriptionConstants'
import FractionDto from '../../../../referencial/components/fraction/dto/FractionDto'
import ParameterDto from '../../../../referencial/components/parameter/dto/ParameterDto'
import SupportDto from '../../../../referencial/components/support/dto/SupportDto'
import UnitDto from '../../../../referencial/components/unit/dto/UnitDto'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import { getParametersNature, getParametersType } from '../../../../referencial/util/ReferencialUtils'
import { getStatusInformation } from '../../../../referencial/util/StatusUtils'
import { getLocalizationPicto } from '../../../../utils/AnalyseUtils'
import { i18nize } from '../../../../utils/StringUtil'


class ParametersPanel extends Component {
    state = {
        isOpen: false,
        unitCode: undefined,
        fractionCode: undefined,
        supportCode: undefined,
        placeCode: undefined,
        paramCode: undefined,
    }

    onClose = () => {
        this.setState({
            isOpen: false,
            unitCode: undefined,
            fractionCode: undefined,
            supportCode: undefined,
            placeCode: undefined,
            paramCode: undefined,
        })
    }

    onChange = () => {
        const {
            unitCode,
            fractionCode,
            supportCode,
            placeCode,
            paramCode,
        } = this.state

        const param = this.props.selectedParameters.find(({ parameterCode }) => parameterCode === paramCode) || {}
        this.props.onUpdateParameter({ ...param, unitCode, fractionCode, supportCode, placeCode })
        this.onClose()
    }

    render = () => {
        const {
            selectedParameters = [],
            onDeleteParameter = () => { },
            editMode = false,

            parameters = [],
            fractions = [],
            supports = [],
            units = [],
            sandreCodes,
        } = this.props

        const {
            isOpen,
            unitCode,
            fractionCode,
            supportCode,
            placeCode,
            paramCode,
        } = this.state

        const activeParameters = parameters.filter(p => p.active)
        const types = getParametersType()
        const natures = getParametersNature()
        const parametersFormat = selectedParameters.reduce((acc, param) => {
            const parameter = activeParameters.find(p => p.code === param.parameterCode)
            if (parameter) {
                acc.push({
                    code: parameter.code,
                    name: parameter.name,
                    type: types[parameter.typeParam],
                    nature: natures[parameter.nature],
                    status: parameter.status ? getStatusInformation(parameter.status) : i18n.validated,
                    unit: (units.find(f => f.id === param.unitCode) || {}).labelWithCode,
                    fraction: (fractions.find(f => f.id === param.fractionCode) || {}).labelWithCode,
                    support: (supports.find(s => s.id === param.supportCode) || {}).labelWithCode,
                    place: getLocalizationPicto(param.placeCode),
                    data: {
                        unitCode: param.unitCode,
                        fractionCode: param.fractionCode,
                        placeCode: param.placeCode,
                        supportCode: param.supportCode,
                    },
                })
            }
            return acc
        }, [])

        const title = paramCode ? (parameters.find(({ code }) => code === paramCode) || {}).labelWithCode : ''

        return (
            <div>
                <Table
                    showTitle={false}
                    data={parametersFormat}
                    type={{ headers: ['code', 'name', 'type', 'nature', 'status', 'unit', 'fraction', 'support', 'place'] }}
                    sortable
                    deletable={editMode}
                    onDelete={onDeleteParameter}
                    alterable={editMode}
                    onAlter={({ code, data }) => this.setState({ isOpen: true, paramCode: code, ...data })}
                    condensed
                    paging
                    overflow='hidden'
                    nbPerPageLabel={nbPerPageLabel}
                />
                <Dialog
                    onClose={this.onClose}
                    fullWidth
                    maxWidth='lg'
                    open={isOpen}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <div className='row no-margin padding-top-1' style={{ height: '30vh' }}>
                            <Select
                                col={6}
                                label={i18n.unit}
                                value={unitCode}
                                options={units}
                                onChange={v => this.setState({ unitCode: v })}
                                keyLabel='labelWithCode'
                            />
                            <Select
                                col={6}
                                label={i18n.fraction}
                                value={fractionCode}
                                options={fractions}
                                onChange={v => this.setState({ fractionCode: v })}
                                keyLabel='labelWithCode'
                                keyValue='id'
                            />
                            <Select
                                col={6}
                                label={i18n.support}
                                value={supportCode}
                                options={supports}
                                onChange={v => this.setState({ supportCode: v })}
                                keyLabel='labelWithCode'
                                keyValue='id'
                            />
                            <Select
                                col={6}
                                label={i18n.place}
                                options={getSandreList(sandreCodes, 'ANALYSES.LIEU')}
                                onChange={v => this.setState({ placeCode: hasValue(v) && `${v}` || '' })}
                                value={placeCode}
                                nullLabel='&nbsp;'
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} variant='outlined' >
                            {i18n.cancel}
                        </Button>
                        <Button onClick={this.onChange} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

ParametersPanel.propTypes = {
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    onDeleteParameter: PropTypes.func,
    onUpdateParameter: PropTypes.func,
    editMode: PropTypes.bool,

    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
    fractions: store.FractionReducer.fractions,
    supports: store.SupportReducer.supports,
    units: store.UnitReducer.units,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(ParametersPanel)