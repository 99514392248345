import { template } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IconCard from './IconCard'

const CardToMuchData = ({
    nbElements = 0,
    maxNbElements = 10000,
    forceDisplay = () => { },
}) => {
    return (
        <IconCard icon='warning' color='orange'>
            <div className='row no-margin valign-wrapper'>
                <div className='col s12'>
                    <h6 className='bold no-margin-bottom'>
                        {i18n.tooMuchDataToShow}
                    </h6>
                    <div className='flex-row'>
                        <h6>
                            {template(i18n.performanceDisplayMessage)({ nbElements, maxNbElements })}
                        </h6>
                        <div className='padding-left-1' />
                        <h6 className='textLink' onClick={forceDisplay}>
                            {i18n.displayEverything}
                        </h6>
                    </div>
                </div>
            </div>
        </IconCard>
    )
}

CardToMuchData.propTypes = {
    nbElements: PropTypes.number,
    maxNbElements: PropTypes.number,
    forceDisplay: PropTypes.func,
}

export default CardToMuchData