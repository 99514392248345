import { push } from 'connected-react-router'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from '../../../store/AppStore'
import { getNewsColor } from '../../../utils/ColorUtil'
import { getArticleFormat } from '../../../utils/DateUtil'
// import SieauAction from '../../../components/sieau/SieauAction'
import { getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'

const propsToFetch = {
    cmsEvents: false,
}

class NotifNewsComponent extends Component {
    componentWillMount() {
        // AppStore.dispatch(SieauAction.fetch(propsToFetch))
    }

    redirect = (link) => {
        AppStore.dispatch(push(link))
    }

    getFormattedTitle = (title) => {
        const index = title.substring(0, 45).lastIndexOf(' ')
        return [ title.substring(0, index), <br/>, title.substring(index)]
    }

    getNewsRow(news) {
        const formattedTitle = news.title.length >= 45 ? this.getFormattedTitle(news.title) : news.title
        const linkPart = news.link ? (
            <div className='no-margin col s2 center-align'>
                <a href={ news.link } target='_blank' className='transparent tooltipped' data-tooltip={ news.link } ><i className='material-icons clickable blue-text rem-3-size'>link</i></a>
            </div>
        ) : null
        return (
            <div className={ `${news.status != 1 ? 'grey lighten-2' : ''} collection-item row no-margin valign-wrapper clickable colorful-card card-${getNewsColor(news.level).toLowerCase()}` }>
                <div className='no-margin col s2 center-align'>
                    <h6 className='bold' onClick={ () => this.redirect(`/contents/${news.idCategory}/${news.id}`)}>{ getArticleFormat(news.updateDate) }</h6>
                </div>
                <div className={ `no-padding no-margin col clickable ${news.link ? 's8' : 's10'}` } onClick={ () => this.redirect(`/contents/${news.idCategory}/${news.id}`)}>
                    <h6 className='tilte no-margin truncate'><b className='bold'>{ formattedTitle }</b></h6>
                </div>
                { linkPart }
            </div>
        )
    }

    render() {
        const publishedNews = this.props.cmsEvents.filter(news => news.idCategory === this.props.categoryId && news.status === 1)
        if (!publishedNews.length) {
            return (
                <div className='col s12'>{ i18n.noNewsToDisplay }</div>
            )
        }
        const maxNewsInNotifs = 5
        const ViewMore = (
            <div className='collection-item clickable center-align'>
                <p className='no-margin' onClick={ () => this.redirect(`/contents/${this.props.categoryId}`) }>{ publishedNews.length > maxNewsInNotifs ? `${i18n.viewMore}...` : i18n.viewAll}</p>
            </div>
        )
        const content = !publishedNews.length ? (
            <div className='col s12'>{ i18n.noNewsToDisplay }</div>
        ) : orderBy(publishedNews, 'updateDate', 'desc').slice(0, maxNewsInNotifs).map(n => this.getNewsRow(n))
        return (
            <div className='collection'>
                { content }
                { publishedNews.length ? ViewMore : null }
            </div>
        )
    }
}

NotifNewsComponent.propTypes = getPropTypes(propsToFetch, {
    categoryId: PropTypes.number,
})

const mapStateToProps = () => getMapStateToProps(propsToFetch)

export default connect(mapStateToProps)(NotifNewsComponent)
