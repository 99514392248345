import { orderBy } from 'lodash'
import { IPSColorIndex } from './MapSituationUtils'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import React from 'react'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoMapSituationStat from './dto/DtoMapSituationStat'
import { getI18nOrLabel } from '../../../utils/StringUtil'
import AppStore from '../../../store/AppStore'
import { push } from 'connected-react-router'
import { Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend } from '../../../components/StyledElements'
import { getDate } from '../../../utils/DateUtil'


const MapSituationPie = ({ stats, st }) => {
    const chartData = orderBy(stats.map(s => {
        return {
            value: s.nb,
            name: `(${s.nb}) ${getI18nOrLabel(s.threshold)}`,
            color: s.color,
        }
    }), d => IPSColorIndex(d.color))

    const options = {
        color: chartData.map(o => o.color),
        tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)',
        },
        series: [
            {
                type: 'pie',
                data: chartData,
                avoidLabelOverlap: false,
                radius: ['30%', '70%'],
                label: {
                    normal: {
                        show: false,
                        position: 'center',
                    },
                },
                labelLine: {
                    normal: {
                        show: false,
                    },
                },
                left: '55%',
            },
        ],
        legend: {
            orient: 'vertical',
            left: 10,
            itemWidth: 28,
            itemHeight: 17,
            textStyle: {
                width: 300,
                overflow: 'break',
                fontSize: 13,
                padding: [0, 0, 0, 10],
            },
        },
    }

    return (
        <Grid item xs={4}>
            <StyledFieldSet>
                <StyledLegend onClick={() => AppStore.dispatch(push(`/${st}/mapSituations?mapId=${stats[0].mapId}`))} className='clickable'>{ `${stats[0].mapName} - ${getDate(stats[0].date)}` }</StyledLegend>
                <ReactEchartsCore
                    echarts={echarts}
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    className={'row no-margin'}
                    style={{ height: 200 }}
                    onEvents={ { click: () => AppStore.dispatch(push(`/${st}/mapSituations?mapId=${stats[0].mapId}`)) } }
                />
            </StyledFieldSet>
        </Grid>
    )
}

MapSituationPie.propTypes = {
    stats: arrayOf(DtoMapSituationStat),
}

export default MapSituationPie