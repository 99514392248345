import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import LegendsOverlay from 'carto/Components/LegendsOverlay'
import { find, maxBy, minBy } from 'lodash'
// import { Map as MapOL } from 'ol/Map'
import * as ol from 'ol'
import { defaults } from 'ol/control'
import Attribution from 'ol/control/Attribution'
import FullScreen from 'ol/control/FullScreen'
import Rotate from 'ol/control/Rotate'
import ScaleLine from 'ol/control/ScaleLine'
import Zoom from 'ol/control/Zoom'
import * as interaction from 'ol/interaction'
import Draw from 'ol/interaction/Draw'
import VectorLayer from 'ol/layer/Vector'
import Overlay from 'ol/Overlay'
import * as olProj from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import View from 'ol/View'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Popup from 'utils/mapUtils/Popup'
import WaitAction from 'wait/WaitAction'
import CartographyExportPanel from '../../components/map/CartographyExportPanel'
import TabsSideBar from '../../components/navbar/TabsSideBar'
import GeoApiAction from '../../geoApi/action/GeoApiAction'
import DtoGeoApiTown from '../../geoApi/DtoGeoApiTown'
import DtoQualitometer from '../../quality/dto/DtoQualitometer'
import DtoQualitometerLight from '../../quality/dto/DtoQualitometerLight'
import DtoSearchAnalysis from '../../quality/dto/DtoSearchAnalysis'
import CityDto from '../../referencial/components/city/dto/CityDto'
import DtoStation from '../../station/dto/DtoStation'
import { sieauTooltip } from '../FormUtils'
import { arrayOf, instanceOf } from '../StoreUtils'
import { EPSG3857, EPSG4326, getWGS84KML, loadWMSProjections } from './CoordinateUtils'
import SearchBarMap from './SearchBarMap'

// import LegendControl from 'carto/Components/LegendControl'

class Map extends Component {
    constructor(props) {
        super(props)
        this.state = {
            event: {},
            popup: [],
            fixed: false,
            result: [],
            townMessage: '',
            isOpen: false,
        }
        this.overlayRef = React.createRef()
        this.overlay = null
    }

    componentWillUnmount() {
        window.removeEventListener('fullscreenchange', () => this.props.onToggleFullScreen())
    }

    componentWillMount() {
        window.addEventListener('fullscreenchange', () => this.props.onToggleFullScreen())
        loadWMSProjections()
        let layersTab = this.props.layers.map(el => el.getLayer())
        if (this.props.drawFunction) {
            const source = new VectorSource({ wrapX: false })
            const vector = new VectorLayer({
                source,
            })
            layersTab = [].concat(layersTab, vector)
            this.source = source
        }
        const map = new ol.Map({
            layers: layersTab,
            interactions: interaction.defaults(
                { mouseWheelZoom: this.props.mouseWheelZoom }
            ),
            view: new View({
                projection: 'EPSG:3857',
                center: olProj.fromLonLat(this.props.mapConf.center),
                zoom: this.props.mapConf.zoom,
            }),
            controls: defaults({
                zoom: false,
                attribution: false,
            }).extend([
                new ScaleLine({
                    units: 'metric',
                }),
                new Rotate({
                //    className: 'ol-rotate ol-custom-rotate',
                    className: 'ol-rotate',
                    tipLabel: 'Rotation vers le nord',
                }),
                new Zoom({
                //    className: 'ol-zoom ol-custom-zoom',
                    className: 'ol-zoom',
                    zoomInTipLabel: 'Zoom +',
                    zoomOutTipLabel: 'Zoom -',
                }),
                new Attribution({
                    // className: 'ol-attribution ol-custom-attribution',
                    className: 'ol-attribution',
                    tipLabel: 'Contributeurs',
                    collapseLabel: 'x',
                }),
                new FullScreen({
                    className: 'ol-custom-full-screen',
                    tipLabel: 'Plein écran',
                    source: document.getElementById('fullScreenSource'),
                }),
            ]),
        })
        let ghostZoom = map.getView().getZoom()
        const self = this
        map.on('moveend', (() => {
            if (ghostZoom != map.getView().getZoom()) {
                self.setState({ zoom: map.getView().getZoom() })
            }
            self.props.onMoveEnd(map)
        }))

        map.on('pointermove', (e) => {
            const pixel = map.getEventPixel(e.originalEvent)
            const hit = map.hasFeatureAtPixel(pixel)
            map.getTarget().style.cursor = hit ? 'pointer' : ''
        })

        map.on(['pointerdrag', 'click'], () => {
            if (this.overlay.getPosition()) {
                this.overlay.setPosition(undefined)
            }
        })

        map.on(['singleclick'], (e) => {
            if (e?.originalEvent) {
                this.clickMap(e.originalEvent)
            }
        })

        this.olMap = map
        if (this.props.drawFunction) {
            const draw = new Draw({
                source: this.source,
                type: 'Circle',
            })
            map.addInteraction(draw)
            this.listener = ''
            this.coord = ''
            this.radius = ''
            this.measureTooltipElement = ''
            this.measureTooltip = ''
            this.createMeasureTooltip()
            draw.on('drawstart',
                (evt) => {
                    evt.feature.getGeometry().on('change', (event) => {
                        let units = map.getView().getProjection().getUnits()
                        self.listener = event.target
                        self.measureTooltipElement.innerHTML = ((self.listener.getRadius() * olProj.METERS_PER_UNIT[units]) / 1000).toFixed(2)
                        self.measureTooltip.setPosition(self.listener.getLastCoordinate())
                    })
                }, this)
            draw.on('drawend',
                function (evt) {
                    self.coord = olProj.transform(evt.feature.getGeometry().getFirstCoordinate(), EPSG3857, EPSG4326)
                    self.radius = self.listener.getRadius() / 1000
                    self.props.radius(this.coord, this.radius)
                }, this)
        }
    }

    createMeasureTooltip = () => {
        if (this.measureTooltipElement) {
            this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement)
        }
        this.measureTooltipElement = document.createElement('div')
        this.measureTooltipElement.className = 'tooltip tooltip-measure'
        this.measureTooltip = new Overlay({
            element: this.measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        })
        this.olMap.addOverlay(this.measureTooltip)
    }

    componentDidUpdate(prevProps) {
        if (this.props.fullScreen !== prevProps.fullScreen) {
            this.olMap.updateSize()
        }
    }

    componentDidMount() {
        const map = ReactDOM.findDOMNode(this.refs.map)
        this.olMap.setTarget(map)

        if (this.props.mapConf.extent && this.props.mapConf.extent.length == 4) {
            this.olMap.getView().fit(olProj.transformExtent(this.props.mapConf.extent, EPSG4326, EPSG3857), this.olMap.getSize())
        }
        if (this.props.fixed) {
            this.setState({ fixed: true })
        }

        const fullScreenControlIndex = this.olMap.getControls().getLength() - 1
        this.olMap.getControls().setAt(fullScreenControlIndex, new FullScreen({
            className: 'ol-custom-full-screen',
            tipLabel: 'Plein écran',
            source: document.getElementById('fullScreenSource'),
        }))

        const container = ReactDOM.findDOMNode(this.overlayRef.current)
        this.overlay = new Overlay({ element: container })
        this.overlay.setMap(this.olMap)
        this.olMap.addOverlay(this.overlay)
        // removing legendControl for the moment until we define what is in popup
        // this.olMap.addControl(new LegendControl({ overlay: this.overlay, map: this.olMap }))
    }

    flatDeep = arr => {
        return arr.reduce((acc, val) => {
            return val[0] != undefined && Array.isArray(val[0]) ? acc.concat(this.flatDeep(val)) : acc.concat([val])
        }, [])
    }

    componentWillReceiveProps(nextProps) {
        const added = nextProps.layers.filter(next => {
            return !this.props.layers.find((prec) => prec === next)
        })

        added.forEach((element) => {
            this.olMap.addLayer(element.getLayer())
        }, this)

        const removed = this.props.layers.filter(prec => {
            return !nextProps.layers.find((next) => prec === next)
        })

        removed.forEach((element) => {
            this.olMap.removeLayer(element.getLayer())
        }, this)


        if (nextProps.mapConf.center[0] !== this.props.mapConf.center[0] || nextProps.mapConf.center[1] !== this.props.mapConf.center[1]) {
            this.olMap.getView().setCenter(olProj.fromLonLat(nextProps.mapConf.center))
        }
        if (nextProps.mapConf.zoom !== this.props.mapConf.zoom) {
            this.olMap.getView().setZoom(nextProps.mapConf.zoom)
        }
        if (nextProps.mapConf.extent && nextProps.mapConf.extent !== this.props.mapConf.extent && nextProps.mapConf.extent.length === 4) {
            this.olMap.getView().fit(olProj.transformExtent(nextProps.mapConf.extent, EPSG4326, EPSG3857), this.olMap.getSize())
        }
        if (nextProps.mapConf.wfscenter) {
            const findWFS = find(nextProps.layers, (o) => {
                return o.layer.type == 'VECTOR'
            })
            if (findWFS) {
                if (findWFS.layer.getSource().getFeatures().length) {
                    const tmp = (() => {
                        const tmpGeometry = findWFS.layer.getSource().getFeatures()[0].getGeometry().getCoordinates()
                        return this.flatDeep(tmpGeometry)
                    })()
                    const minX = minBy(tmp, (o) => o[0])
                    const maxX = maxBy(tmp, (o) => o[0])
                    const minY = minBy(tmp, (o) => o[1])
                    const maxY = maxBy(tmp, (o) => o[1])
                    const minCoordinate = getWGS84KML({ x: minX[0], y: minY[1] })
                    const maxCoordinate = getWGS84KML({ x: maxX[0], y: maxY[1] })
                    const extent = [minCoordinate[0], minCoordinate[1], maxCoordinate[0], maxCoordinate[1]]
                    this.olMap.getView().fit(olProj.transformExtent(extent, EPSG4326, EPSG3857), this.olMap.getSize())
                }
            }
        }
    }

    fetchTown = (x, y) => {
        this.setState({ townMessage: i18n.progressLoading })
        this.props.fetchTownsByCoordinate(x, y).then(() => {
            this.setState({ townMessage: this.props.towns[0] ? this.props.towns[0].name : '' })
            if (this.props.getTownObjects) {
                this.props.getTownObjects(this.props.towns[0])
            }
        })
    }

    clickMap = e => {
        e.preventDefault()
        if (!this.props.drawFunction) {
            this.setState({ event: { clientX: e.clientX, clientY: e.clientY } })
            if (this.props.event) {
                const coordinate = this.olMap.getCoordinateFromPixel(this.olMap.getEventPixel({
                    clientX: e.clientX,
                    clientY: e.clientY,
                }))
                const pointTransform = olProj.transform(coordinate, EPSG3857, EPSG4326)
                if (!this.props.noTownsOnClick) {
                    this.fetchTown(pointTransform[0], pointTransform[1])
                }
                if (this.props.onClickMap) {
                    this.props.onClickMap(pointTransform)
                }
                this.props.event(pointTransform, this.state.zoom)
            }
        }
    }

    getActionsButtons = () => {
        const actionButtons = []
        // export button
        const exportTooltip = sieauTooltip(i18n.export, null, 'bottom')
        actionButtons.push(
            <a className='waves-effect waves-light ol-control btn ol-control-style dropdown-button'
                data-position='bottom' onClick={() => this.setState({ isOpen: true })} {...exportTooltip}
            >
                <i className='material-icons left no-margin ol-button-style'>file_download</i>
            </a>
        )
        actionButtons.push(
            !this.props.noSearchBar && this.props.stationsPoints && this.props.stationsPoints.length > 1 && (
                <SearchBarMap olMap={this.olMap} stationsPoints={this.props.stationsPoints} />)
        )
        if (actionButtons.length) {
            return (
                <div className='absolute padding-top-1 padding-left-4'>
                    { actionButtons }
                </div>
            )
        }
        return null
    }

    onClose = () => {
        this.setState({ isOpen: false })
    }

    getExportModal() {
        return (
            <Dialog
                onClose={this.onClose}
                open={this.state.isOpen}
            >
                <DialogTitle>{i18n.chooseExportFormat}</DialogTitle>
                <DialogContent>
                    <CartographyExportPanel
                        stationsPoints={this.props.stationsPointsExport}
                        layers={this.props.layersExport}
                        station={this.props.stationExport}
                        analysis={this.props.analysisExport}
                        map={this.olMap}
                        visible={this.props.visible}
                        onClose={this.onClose}
                    />
                </DialogContent>
            </Dialog>
        )
    }

    render() {
        return (
            <div className='row no-margin map-wrapper' style={this.props.styleContainer}>
                <div id='fullScreenSource' style={{ height: `${this.props.mapConf.size}px`, width: `${this.props.mapConf.width}px` }}>
                    <div id='map' data-cy='sieau-map'
                        className={ `sieau-ol-map ${this.props.className || ''} ${this.state.fixed && 'fixed'}` }
                        ref='map' onClick={(e) => {
                            if (e.target.id !== 'fancy') {
                                e.preventDefault()
                            }
                        }}
                    >
                        { !this.props.noTownsOnClick && this.props.towns && this.props.towns.length > 0 && this.state.townMessage !== '' &&
                        <div ref='popup' className='ol-control ol-region-marker right hide-on-med-and-down'>
                            <b className='no-margin region-marker-field dropdown-button btn'>{ this.state.townMessage }</b>
                        </div>
                        }
                        {this.getActionsButtons()}
                        {this.getExportModal()}
                        <TabsSideBar
                            tabs={ this.props.tabs }
                            openByDefault={ this.props.openByDefault }
                            onChangeTab={ this.props.onChangeTab }
                            loadOnClick={ this.props.tabsLoadOnClick }
                        />
                    </div>
                </div>
                {!this.props.noMarkerTooltip ? (
                    <Popup
                        olMap={this.olMap}
                        layers={this.props.layers}
                        event={this.state.event}
                        popupProps={this.props.popupProps}
                        popupStyle={this.props.popupStyle}
                        currentStation={this.props.station}
                        getStation={this.props.getStation}
                    />
                ) : ''}
                <div ref={this.overlayRef} >
                    <LegendsOverlay visible={this.props.visible} overlay={this.overlay} map={this.olMap} />
                </div>
            </div>
        )
    }
}

Map.propTypes = {
    layers: PropTypes.arrayOf(PropTypes.object),
    popupProps: PropTypes.shape({ filters: PropTypes.arrayOf(PropTypes.string) }),
    popupStyle: PropTypes.shape({}),
    styleContainer: PropTypes.shape({}),
    mapConf: PropTypes.shape({
        size: PropTypes.number,
        center: PropTypes.array,
        extent: PropTypes.array,
        zoom: PropTypes.number,
    }),
    event: PropTypes.func,
    drawFunction: PropTypes.bool,
    className: PropTypes.bool,
    fixed: PropTypes.bool,
    radius: PropTypes.func,
    mouseWheelZoom: PropTypes.bool,
    onMoveEnd: PropTypes.func,
    fullScreen: PropTypes.bool,
    tabs: PropTypes.arrayOf(PropTypes.object),
    onChangeTab: PropTypes.func,
    openByDefault: PropTypes.bool,
    stationsPoints: PropTypes.arrayOf(PropTypes.object),
    stationsPointsExport: arrayOf(DtoQualitometerLight),
    layersExport: arrayOf(PropTypes.string),
    stationExport: instanceOf(DtoQualitometer),
    towns: arrayOf(DtoGeoApiTown),
    analysisExport: arrayOf(DtoSearchAnalysis),
    onClickMap: PropTypes.func,
    tabsLoadOnClick: PropTypes.bool,
    noMarkerTooltip: PropTypes.bool,
    noSearchBar: PropTypes.bool,
    fetchTownsByCoordinate: PropTypes.func,
    noTownsOnClick: PropTypes.bool,
    waitStart: PropTypes.func,
    getTownObjects: PropTypes.func,
    getStation: PropTypes.func,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    station: PropTypes.instanceOf(DtoStation),
    onToggleFullScreen: PropTypes.func,
    visible: PropTypes.shape({
        DISTRIBUTION_UNIT: PropTypes.bool,
        HYDROMETRIC_STATION: PropTypes.bool,
        INSTALLATION: PropTypes.bool,
        PIEZOMETER: PropTypes.bool,
        PLUVIOMETER: PropTypes.bool,
        PRODUCTION_UNIT: PropTypes.bool,
        QUALITOMETER: PropTypes.bool,
        installationType0: PropTypes.bool,
        installationType1: PropTypes.bool,
        installationType2: PropTypes.bool,
        installationType3: PropTypes.bool,
        installationType4: PropTypes.bool,
        installationType5: PropTypes.bool,
        installationType6: PropTypes.bool,
        installationType7: PropTypes.bool,
        installationType8: PropTypes.bool,
        installationType9: PropTypes.bool,
        installationType10: PropTypes.bool,
        installationType11: PropTypes.bool,
        installationType12: PropTypes.bool,
        installationType13: PropTypes.bool,
        installationType14: PropTypes.bool,
        installationType16: PropTypes.bool,
        installationType17: PropTypes.bool,
        installationType18: PropTypes.bool,
        installationType20: PropTypes.bool,
        'installationType-1': PropTypes.bool,
        qualitometerType0: PropTypes.bool,
        qualitometerType1: PropTypes.bool,
        qualitometerType2: PropTypes.bool,
        qualitometerType3: PropTypes.bool,
        qualitometerType4: PropTypes.bool,
        qualitometerType5: PropTypes.bool,
        qualitometerType6: PropTypes.bool,
        qualitometerType7: PropTypes.bool,
        qualitometerType8: PropTypes.bool,
    }),
}

Map.defaultProps = {
    mapConf: {
        size: 300,
        center: [1.75, 47.5],
        zoom: 6,
    },
    layers: [],
    popupProps: { filters: [] },
    drawFunction: false,
    event () {
    },
    radius () {
    },
    zoom: 10,
    mouseWheelZoom: true,
    onMoveEnd: () => {},
    tabs: [],
    onChangeTab: () => {},
    onToggleFullScreen: () => {},
    getTownObjects: undefined,
    getStation: undefined,
}

const mapStateToProps = store => ({
    towns: store.GeoApiReducer.towns,
    cities: store.CityReducer.cities,
})


const mapDispatchToProps = {
    fetchTownsByCoordinate: GeoApiAction.fetchTownsByCoordinate,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
