import React, { useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Table from 'components/datatable/Table'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { getDayDisplay, getTextSlot } from 'exploitations/utils/AgriUtils'
import { sieauTooltip } from 'utils/FormUtils'
import { getSandreLabel } from 'utils/StringUtil'
import EditToolDisplay from './EditToolDisplay'
import { Grid } from '@mui/material'
import { range, sortBy } from 'lodash'
import { useParams } from 'react-router-dom'
import { exportFile } from 'utils/ExportDataUtil'

const headers = ['samplerNameDisplay', 'pointAndPumpDisplay', 'maxFlow', 'mondayDisplay', 'tuesdayDisplay',
    'wednesdayDisplay', 'thursdayDisplay', 'fridayDisplay', 'saturdayDisplay', 'sundayDisplay', 'commentDisplay']

const exportHeaders = ['codification', 'siret', 'pacage', 'samplerName', 'procedure', 'mail', 'mobile', 'exportLabelNameUG', 'exportLabelNameSUG', 'resourceType',
    'exportLabelIdPump', 'exportLabelIdPoint', 'city', 'lieuDit', 'cadastralParcel', 'x', 'y', 'inUseFlow', 'maxFlow', 'surface', 'authorizedVolume', 'detention', 'droughtSector',
    'waterTurns', 'comment', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const WaterTurnsTable = ({
    slot = {},
    data = [],
    readMode = false,
    colors = [],
    openUpdate = () => { },
    onDelete = () => { },
    currentYear = false,
    onApply = () => { },
}) => {
    const dispatch = useDispatch()
    const [colorInput, setColorInput] = useState('X')
    const [selectionTool, setSelectionTool] = useState(0)
    const { id } = useParams()

    const {
        sandreCodes,
        exploitationsExportFullData,
        installationsWithGeo,
        managementUnits,
        citiesIndex,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const { textTimeSlots, nbSlots } = useMemo(() => ({
        textTimeSlots: getTextSlot(slot),
        nbSlots: slot.nbSlots || 1,
    }), [slot])

    const formattedData = sortBy(data.map(d => {
        const pointAndPump = `${d.identifier}\n${d.material}`
        const slotsDisplay = range(nbSlots)
        return {
            ...d,
            monday: { value: d.day1, cellType: 'string' },
            mondayDisplay: getDayDisplay(d.day1, slotsDisplay, colors),
            tuesday: { value: d.day2, cellType: 'string' },
            tuesdayDisplay: getDayDisplay(d.day2, slotsDisplay, colors),
            wednesday: { value: d.day3, cellType: 'string' },
            wednesdayDisplay: getDayDisplay(d.day3, slotsDisplay, colors),
            thursday: { value: d.day4, cellType: 'string' },
            thursdayDisplay: getDayDisplay(d.day4, slotsDisplay, colors),
            friday: { value: d.day5, cellType: 'string' },
            fridayDisplay: getDayDisplay(d.day5, slotsDisplay, colors),
            saturday: { value: d.day6, cellType: 'string' },
            saturdayDisplay: getDayDisplay(d.day6, slotsDisplay, colors),
            sunday: { value: d.day7, cellType: 'string' },
            sundayDisplay: getDayDisplay(d.day7, slotsDisplay, colors),
            category: d.mobility,
            pointAndPump,
            pointAndPumpDisplay: (
                <span
                    style={{ whiteSpace: 'pre-wrap' }}
                    {...sieauTooltip(
                        `${d.pumpType ? `${i18n.pumpType} : ${d.pumpType}\n` : ''}${d.serialNumber ? `${i18n.serialNumber} : ${d.serialNumber}\n` : ''}${d.nature ? `${i18n.nature} : ${d.nature}\n` : ''}${d.mobility ? `${i18n.category} : ${d.mobility}\n` : ''}`
                        , null, 'right')}
                >
                    {pointAndPump}
                </span>
            ),
            commentDisplay: d.comment ? <i className='material-icons' {...sieauTooltip(d.comment)}>info_outline</i> : '',
            sampleType: d.samplingTypeLabel,
            samplerNameDisplay: `${d.samplerName} [${d.codification}]`,
        }
    }), ['samplerName', 'identifier'])

    const onExport = () => {
        const exportData = formattedData.length ? [
            { ...formattedData[0], headers: exportHeaders },
            ...formattedData.slice(1),
        ].map((d) => {
            const exploit = exploitationsExportFullData.find((e) => e.idExploitation === d.idExploitation) || {}
            const inst = installationsWithGeo.find((i) => i.id === d.idInstallation) || {}
            const uge = managementUnits.find((u) => u.managementCode === inst.managementCode) || {}
            const suge = managementUnits.find((u) => u.managementCode === inst.subManagementCode) || {}
            const city = citiesIndex[inst.townCode] || {}
            return {
                ...d,
                siret: { value: exploit.siret, cellType: 'string' },
                pacage: { value: exploit.pacage, cellType: 'string' },
                procedure: { value: getSandreLabel(sandreCodes, 'DOSSIERS.TYPE_PROCEDURE', exploit.procedureType) },
                mail: { value: exploit.email },
                mobile: { value: exploit.mobile, cellType: 'string' },
                exportLabelNameUG: { value: uge.labelWithCode },
                exportLabelNameSUG: { value: suge.labelWithCode },
                resourceType: { value: d.sampleType, cellType: 'string' },
                exportLabelIdPump: { value: d.material, cellType: 'string' },
                exportLabelIdPoint: { value: d.identifier, cellType: 'string' },
                city: { value: city.name },
                lieuDit: { value: inst.location, cellType: 'string' },
                cadastralParcel: { value: `${inst.parcel || ''} ${inst.section || ''}`, cellType: 'string' },
                x: { value: inst.x, cellType: 'string' },
                y: { value: inst.y, cellType: 'string' },
                maxFlow: { value: d.maxFlow },
                droughtSector: d.droughtSector,
                waterTurns: d.watershed,
            }
        }) : []

        exportFile({
            data: exportData,
            titleFile: i18n.waterTurns,
        })
    }

    return (
        <>
            {!readMode && currentYear && slot.id !== -1 && (
                <Grid className='padding-bottom-1'>
                    <EditToolDisplay
                        colorInput={colorInput}
                        setColorInput={setColorInput}
                        slot={slot}
                        colors={colors}
                        selectionTool={selectionTool}
                        setSelectionTool={setSelectionTool}
                        onApply={() => onApply(slot, selectionTool, colorInput)}
                    />
                </Grid>
            )}
            <Table
                title={`${i18n.waterTurns} - ${i18n.ranges} : ${slot.id !== -1 && textTimeSlots || i18n.notDefined}`}
                actions={[{ iconName: 'file_download', tooltip: i18n.export, onClick: onExport }]}
                data={formattedData}
                nbPerPageLabel={nbPerPageLabelShort}
                type={{ headers }}
                paging
                condensed
                sortable
                alterable={!readMode}
                onAlter={waterTurn => openUpdate(waterTurn, slot, `${waterTurn.samplerName || ''} - ${waterTurn.identifier || ''}[${waterTurn.material || ''}]`)}
                customHeaders={{
                    pointAndPumpDisplay: i18n.pointAndPump,
                    commentDisplay: i18n.nullValue,
                    mondayDisplay: i18n.monday,
                    tuesdayDisplay: i18n.tuesday,
                    wednesdayDisplay: i18n.wednesday,
                    thursdayDisplay: i18n.thursday,
                    fridayDisplay: i18n.friday,
                    saturdayDisplay: i18n.saturday,
                    sundayDisplay: i18n.sunday,
                    samplerNameDisplay: i18n.samplerName,
                }}
                deletable={!readMode}
                onDelete={onDelete}
                onClick={waterTurn => {
                    if (!id) {
                        dispatch(push(`/dossiers/${waterTurn.idExploitation}/dashboard`))
                    }
                }}
                customWidthHeaders={{
                    samplerName: '18%',
                    pointAndPumpDisplay: '10%',
                    maxFlow: '8%',
                    mondayDisplay: '8%',
                    tuesdayDisplay: '8%',
                    wednesdayDisplay: '8%',
                    thursdayDisplay: '8%',
                    fridayDisplay: '8%',
                    saturdayDisplay: '8%',
                    sundayDisplay: '8%',
                }}
                round
            />
        </>
    )
}

WaterTurnsTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    slot: PropTypes.shape({}),
    colors: PropTypes.shape({}),
    readMode: PropTypes.bool,
    currentYear: PropTypes.bool,
    openUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onApply: PropTypes.func,
}

export default WaterTurnsTable
