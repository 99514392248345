import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { setConfirmationModal } from '../../utils/FormUtils'
import IAction from './IAction'

class Delete extends IAction {
    fn = () => {
        return () => setConfirmationModal(this.props.onDelete)
    }
    icon = () => {
        return 'delete'
    }
    label = () => {
        return i18n.delete
    }
    id = () => {
        return 'delete_action_navbar'
    }
}

Delete.propTypes = {
    onDelete: PropTypes.func.isRequired,
}

export default Delete