/* eslint-disable camelcase */
import { Button, Grid, Icon } from '@mui/material'
import { styled } from '@mui/styles'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import IntervenantCard from 'exploitations/components/cards/IntervenantCard'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import { compact, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import ContactDto from 'referencial/components/contact/dto/ContactDto'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoIntervenantDeclaration from 'survey/dto/DtoIntervenantDeclaration'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getLogin } from 'utils/SettingUtils'
import SelectContactModal from '../modals/SelectContactModal'

const ButtonStep = styled(Button)({
    width: '100%',
})

class DeclarationContactsStep extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            contributors: [],
            contributorsLoaded: false,
            openModal: false,
            contributorToEdit: false,
            contributorTypeEdit: null,
        }
    }

    componentDidMount() {
        this.getContributors()
    }

    getContributors = () => {
        const { declaration, contacts } = this.props
        this.setState({ contributorsLoaded: false })
        this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
            if (!contacts.length) {
                this.props.fetchContacts(declaration).then(() => {
                    this.setContacts(declaration)
                })
            } else {
                this.setContacts(declaration)
            }
        })
    }

    formatContact = (contact, updatedContact) => {
        if (updatedContact) {
            return {
                ...contact,
                ...updatedContact,
                address: updatedContact.road,
                additionalAddress: updatedContact.addressComplement,
                desktopTel: updatedContact.fax,
                postalBox: updatedContact.postalCode,
            }
        }
        return contact
    }

    setContacts = (declaration) => {
        const { exploitation, contributors, contacts } = this.props
        const contactsExploitation = compact(exploitation.link_contributors.map((c) => {
            const linkUpdated = declaration.link_contributorTypes.find((cT) => !cT.isTemp && cT.idContributor === c.contactCode && cT.contributorType === c.contributorType)
            if (!linkUpdated) {
                return {
                    idContributor: c.contactCode,
                    isTemp: false,
                    contributorType: c.contributorType,
                    surveyId: declaration.idSurvey,
                    idExploitation: exploitation.idExploitation,
                    updateLogin: getLogin(),
                    mode: 'u',
                }
            }
            return null
        }))
        const contactsExploitationAndDecla = [...contactsExploitation, ...declaration.link_contributorTypes.filter((cT) => cT.mode !== 'd')]

        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode) || {}
        const contactsWithRep = (() => {
            if (!contactsExploitationAndDecla.find((cT) => cT.contributorType === -1 && cT.mode !== 'd') && preleveur.structureType !== 1) {
                const legalRepresentative = contacts.find((c) => c.id === preleveur.legalRepresentative)
                if (legalRepresentative) {
                    return [
                        ...contactsExploitationAndDecla,
                        {
                            idContributor: legalRepresentative.id,
                            isTemp: false,
                            contributorType: -1,
                            surveyId: declaration.idSurvey,
                            idExploitation: exploitation.idExploitation,
                            updateLogin: getLogin(),
                            mode: 'u',
                        },
                    ]
                }
                return contactsExploitationAndDecla
            }
            return contactsExploitationAndDecla
        })()

        const sortedContacts = orderBy(contactsWithRep, 'contributorType')
        this.props.setContributors(sortedContacts)
        this.setState({
            contributors: sortedContacts,
            contributorsLoaded: true,
        })
    }

    editContributor = (contributor = false, contributorTypeEdit) => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal, contributorToEdit: contributor, contributorTypeEdit })
    }

    onSave = (contributor, type) => {
        const { declaration, exploitation } = this.props
        const { contributors, contributorTypeEdit } = this.state
        const id = contributor.idContributorTemp || contributor.idContributor || contributor.id
        const newLink = {
            contributorType: type,
            surveyId: declaration.idSurvey,
            idExploitation: exploitation.idExploitation,
            updateLogin: getLogin(),
        }
        const contribFormatted = {
            ...contributor,
            road: contributor.address,
            addressComplement: contributor.additionalAddress,
            fax: contributor.desktopTel,
            postalCode: contributor.postalBox,
            idContributor: id,
            surveyId: declaration.idSurvey,
            contactType: 2,
        }
        // si le contact est déjà lié
        if (id && contributors.find((cT) => cT.idContributor === id && !!cT.isTemp === !!contributor.idContributorTemp && cT.contributorType === type)) {
            // si c'est un ajout du même type de lien
            if (!contributorTypeEdit) {
                this.props.warning('Ce lien existe déjà')
            // sinon, c'est une modification des infos du contact
            } else {
                this.setState({ contributorsLoaded: false })
                this.props.updateDeclarationContributor(declaration.idSurvey, contribFormatted).then(() => {
                    this.getContributors()
                })
                this.editContributor()
            }
        // si c'est une création de nouveau contact
        } else if (!id) {
            this.setState({ contributorsLoaded: false })
            this.props.updateDeclarationContributor(declaration.idSurvey, contribFormatted).then((idReturned) => {
                this.props.onChangeDeclaration('link_contributorTypes', [
                    ...declaration.link_contributorTypes,
                    {
                        ...newLink,
                        idContributor: idReturned,
                        isTemp: true,
                        mode: 'c',
                    },
                ])
                this.props.fetchDeclaration(declaration.idDeclaration).then(() => {
                    this.getContributors()
                })
            })
            this.editContributor()
        // si c'est un rajout de lien non existant
        } else {
            this.setState({ contributorsLoaded: false })
            this.props.updateDeclarationContributor(declaration.idSurvey, contribFormatted).then(() => {
                this.props.onChangeDeclaration('link_contributorTypes', [
                    ...(contributorTypeEdit ?
                        declaration.link_contributorTypes.filter((cT) => !(cT.idContributor === id && !!cT.isTemp === !!contributor.idContributorTemp && cT.contributorType === contributorTypeEdit)) :
                        declaration.link_contributorTypes),
                    {
                        ...newLink,
                        idContributor: id,
                        isTemp: !!contributor.idContributorTemp,
                        mode: contributorTypeEdit ? 'u' : 'c',
                    },
                ])
                this.props.fetchDeclaration(declaration.idDeclaration).then(() => {
                    this.getContributors()
                })
            })
            this.editContributor()
        }
    }

    onDelete = () => {
        const { declaration, exploitation } = this.props
        const { contributorToEdit, contributorTypeEdit } = this.state
        const id = contributorToEdit.idContributorTemp || contributorToEdit.idContributor || contributorToEdit.id
        this.setState({ contributorsLoaded: false })
        this.props.onChangeDeclaration('link_contributorTypes', [
            ...declaration.link_contributorTypes.filter((cT) => !(cT.idContributor === id && !!cT.isTemp === !!contributorToEdit.idContributorTemp && cT.contributorType === contributorTypeEdit)),
            {
                contributorType: contributorTypeEdit,
                surveyId: declaration.idSurvey,
                idExploitation: exploitation.idExploitation,
                updateLogin: getLogin(),
                idContributor: id,
                isTemp: !!contributorToEdit.idContributorTemp,
                mode: 'd',
            },
        ])
        this.props.fetchDeclaration(declaration.idDeclaration).then(() => {
            this.getContributors()
        })
        this.editContributor()
    }

    onCancel = () => {
        this.getContributors()
        this.editContributor()
    }

    getListContributors = () => {
        const { contributors } = this.state
        const { sandreCodes, readMode, declarationContributors, contacts } = this.props
        const typesContributors = [{ code: -1, name: i18n.legalRepresentative }, ...sandreCodes.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')]
        return contributors.map((cT) => {
            const contact = cT.isTemp ? {} : (contacts.find((c) => c.id === cT.idContributor) || {})
            const contactUpdated = declarationContributors.find((c) => (cT.isTemp ? c.idContributorTemp : c.idContributor) === cT.idContributor && c.contactType === 2)
            const formattedContact = this.formatContact(contact, contactUpdated)
            const type = typesContributors.find((t) => t.code === cT.contributorType)
            return (
                <Grid key={cT.idContributor} item xs={4} className='padding-1'>
                    <IntervenantCard
                        intervenant={formattedContact}
                        title={type ? type.name : i18n.unknown}
                        actions={readMode ? [] : [{ onClick: () => this.editContributor(formattedContact, cT.contributorType), iconName: 'edit' }]}
                        round
                    />
                </Grid>
            )
        })
    }

    showLegalRepresentative = () => {
        const { contributors } = this.state
        return !contributors.find((c) => c.mode !== 'd' && c.contributorType === -1)
    }

    render() {
        const { contributorsLoaded, openModal, contributorToEdit, contributorTypeEdit } = this.state
        const { dataLoaded, readMode } = this.props
        return (
            <Grid
                item
                container
                direction='column'
                justifyContent='space-between'
                alignItems='stretch'
                style={{ paddingBottom: 60, backgroundColor: 'white' }}
            >
                <Grid container justifyContent='center' className='padding-top-1' style={{ overflowY: 'auto' }}>
                    {contributorsLoaded && dataLoaded ? (
                        this.getListContributors()
                    ) : (
                        <Grid item xs={12}>
                            <ProgressBar indeterminate />
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        height: 60,
                        backgroundColor: 'white',
                        borderTop: '1px solid grey',
                        width: 'calc(100% - 60px)',
                    }}
                >
                    <Grid item xs={2}>
                        <ButtonStep variant='contained' color='primary' onClick={this.props.previousStep}>
                            <Icon>keyboard_arrow_left</Icon>
                        </ButtonStep>
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonStep
                            variant='contained'
                            color='primary'
                            onClick={() => this.editContributor(new DtoIntervenantDeclaration({}))}
                            disabled={readMode}
                        >
                            {i18n.addContributor}
                        </ButtonStep>
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonStep variant='contained' color='primary' onClick={this.props.nextStep}>
                            <Icon>keyboard_arrow_right</Icon>
                        </ButtonStep>
                    </Grid>
                </Grid>
                {openModal && (
                    <SelectContactModal
                        showLegalRepresentative={this.showLegalRepresentative()}
                        contributor={contributorToEdit}
                        contributorType={contributorTypeEdit}
                        open={openModal}
                        onSave={this.onSave}
                        onDelete={this.onDelete}
                        onCancel={this.onCancel}
                    />
                )}
            </Grid>
        )
    }
}

DeclarationContactsStep.propTypes = {
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    onChangeDeclaration: PropTypes.func.isRequired,
    fetchContacts: PropTypes.func,
    setContributors: PropTypes.func.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    updateDeclarationContributor: PropTypes.func,
    fetchDeclarationContributors: PropTypes.func,
    fetchDeclaration: PropTypes.func,
    updateDeclaration: PropTypes.func,
    warning: PropTypes.func,
    dataLoaded: PropTypes.bool,
    readMode: PropTypes.bool,
    previousStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    operator: PropTypes.instanceOf(ContributorDto),
}

const mapStateToProps = (store) => {
    return {
        contacts: store.ContactReducer.contacts,
        exploitation: store.AgriReducer.exploitation,
        declarationContributors: store.AgriReducer.declarationContributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        operator: store.AgriReducer.operator,
        contributors: store.ContributorReducer.contributors,
    }
}

const mapDispatchToProps = {
    fetchContacts: ContactAction.fetchContacts,
    updateDeclarationContributor: AgriAction.updateDeclarationContributor,
    fetchDeclarationContributors: AgriAction.fetchDeclarationContributors,
    fetchDeclaration: AgriAction.fetchDeclaration,
    updateDeclaration: AgriAction.updateDeclaration,
    warning: ToastrAction.warning,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationContactsStep)
