import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Checkbox from '../../../components/forms/Checkbox'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getLogin, getSetting, getUser } from '../../../utils/SettingUtils'
import moment from 'moment'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import InstallationInfosPanel from '../installations/components/InstallationInfosPanel'
import InstallationComElecPanel from '../installations/components/InstallationComElecPanel'
import InstallationAccessSitePanel from '../installations/components/InstallationAccessSitePanel'
import InstallationAccessCasingPanel from '../installations/components/InstallationAccessCasingPanel'
import StationLocationInfoPanel from '../../../station/components/location/StationLocationInfoPanel'
import Row from '../../../components/react/Row'
import InstallationStaffSafetyPanel from '../installations/components/InstallationStaffSafetyPanel'
import STEPCivilEngPanel from './components/STEPCivilEngPanel'
import STEPEquipmentsPanel from './components/STEPEquipmentsPanel'
import STEPAssociatedTownsPanel from './components/STEPAssociatedTownsPanel'
import STEPAssociatedIndustriesPanel from './components/STEPAssociatedIndustriesPanel'
import STEPCollectionBasinsPanel from './components/STEPCollectionBasinsPanel'
import NumberField from '../../../components/forms/NumberField'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSTEP from './dto/DtoSTEP'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import {
    getVisitCheckBoxChange,
    getVisitDatePickerChange,
    getVisitSandreSelectChange,
    getVisitSelectChange,
} from '../../../utils/VisitUtils'
import { getDate } from '../../../utils/DateUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import Select from '../../../components/forms/Select'
import LocalisationMapModal from '../installations/components/modal/LocalisationMapModal'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import StationSamplersPanel from 'station/components/link/StationSamplersPanel'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import { greyBlue } from 'utils/constants/ColorTheme'
import WatermassAction from 'referencial/components/watermass/actions/WatermassAction'
import WatershedAction from 'referencial/components/watershed/actions/WatershedAction'
import STEPRejectPointsPanel from './components/STEPRejectPointsPanel'
import STEPFollowPointsPanel from './components/STEPFollowPointsPanel'

class STEPDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            usedWater: {},
            readMode: true,
            STEP: new DtoSTEP({}),
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationSTEP(this.props.installation.id).then(() => {
                this.setState({
                    STEP: { ...this.props.installationSTEP, idStation: this.props.installation.id },
                })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationsLight.length) {
            this.props.fetchInstallationsLight()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }

        if (!this.props.watermasses.length) {
            this.props.fetchWatermasses()
        }

        if (!this.props.watersheds.length) {
            this.props.fetchWatersheds()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () =>
            this.props.push('/installation'),
        )
    }

    onSave = (visit) => {
        const { installation, STEP: step } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    step,
                }
            ).then(() => this.setReadOnlyMode())
        }
        return this.props.updateInstallationSTEP(
            installation,
            step
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, STEP: this.props.installationSTEP })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) =>
        this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeSTEP = (newObject) => this.setState({ STEP: { ...this.state.STEP, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return (
            getSetting(
                this.props.applicationSettings,
                `PANEL_${this.state.installation.installationType}_${panel}`,
            ) !== 'hidden'
        )
    }

    render() {
        const { installation, readMode, STEP, localizeMap } = this.state
        const { sandreCodes, accountHabilitations, watermasses, watersheds } = this.props
        const mode = { readMode, editMode: !readMode }
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_TYPE_ASST,
        }

        const paramsSTEP = {
            STEP,
            onChange: this.onChangeSTEP,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <div className='row padding-top-1 margin-left-2 padding-bottom-5' id='STEPDescriptionPanel'>
                <div className='col s9' style={ { marginBottom: '100px' } }>
                    <Card title={ i18n.description } className='padding-bottom-1'>
                        <InstallationInfosPanel {...params} />
                        <Row>
                            <Checkbox
                                col={ 6 }
                                checked={ STEP.autorisationDecree }
                                label={ i18n.autorisationDecree }
                                onChange={ (v) => {
                                    this.onChangeSTEP({ autorisationDecree: v })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.autorisationDecree, STEP.autorisationDecree, v))
                                } }
                                disabled={ readMode }
                            />
                            <Input
                                col={ 6 }
                                value={ STEP.numDecree }
                                title={ i18n.numDecree }
                                onChange={ (v) => {
                                    this.onChangeSTEP({ numDecree: v })
                                    this.onChangeVisit({ previousValue: STEP.numDecree, newValue: v, field: i18n.numDecree })
                                } }
                                readMode={ readMode }
                            />
                        </Row>
                        <Row>
                            <Input
                                col={ 6 }
                                value={ getDate(STEP.autorisationDecreeDate) }
                                title={ i18n.autorisationDecreeDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeSTEP({ autorisationDecreeDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.autorisationDecreeDate, STEP.autorisationDecreeDate, v))
                                } }
                                readMode={ readMode }
                            />
                            <NumberField
                                col={ 6 }
                                value={ STEP.decreeDuration }
                                title={ i18n.decreeDuration }
                                onChange={ (v) => {
                                    this.onChangeSTEP({ decreeDuration: v })
                                    this.onChangeVisit({ previousValue: STEP.decreeDuration, newValue: v, field: i18n.decreeDuration })
                                } }
                                readMode={ readMode }
                            />
                        </Row>
                        <Row>
                            <NumberField
                                col={ 6 }
                                value={ STEP.treatmentCapacity }
                                title={ i18n.treatmentCapacity }
                                onChange={ (v) => {
                                    this.onChangeSTEP({ treatmentCapacity: v })
                                    this.onChangeVisit({ previousValue: STEP.treatmentCapacity, newValue: v, field: i18n.treatmentCapacity })
                                } }
                                readMode={ readMode }
                            />
                            <NumberField
                                col={6}
                                value={STEP.incomingFlow}
                                title={i18n.incomingFlow}
                                onChange={(v) => {
                                    this.onChangeSTEP({ incomingFlow: v })
                                    this.onChangeVisit({ previousValue: STEP.incomingFlow, newValue: v, field: i18n.incomingFlow })
                                }}
                                floatValue
                                readMode={readMode}
                            />
                        </Row>
                        <Row>
                            <NumberField
                                col={6}
                                value={STEP.nominalCapacityDB05}
                                title={i18n.nominalCapacityDB05}
                                onChange={(v) => {
                                    this.onChangeSTEP({ nominalCapacityDB05: v })
                                    this.onChangeVisit({ previousValue: STEP.nominalCapacityDB05, newValue: v, field: i18n.nominalCapacityDB05 })
                                }}
                                floatValue
                                readMode={readMode}
                            />
                            <NumberField
                                col={6}
                                value={STEP.maxCharge}
                                title={i18n.maxCharge}
                                onChange={(v) => {
                                    this.onChangeSTEP({ maxCharge: v })
                                    this.onChangeVisit({ previousValue: STEP.maxCharge, newValue: v, field: i18n.maxCharge })
                                }}
                                floatValue
                                readMode={readMode}
                            />
                        </Row>
                        <Row>
                            <Input
                                col={ 6 }
                                value={ STEP.receivingMedium }
                                title={ i18n.receivingMedium }
                                onChange={ (v) => {
                                    this.onChangeSTEP({ receivingMedium: v })
                                    this.onChangeVisit({ previousValue: STEP.receivingMedium, newValue: v, field: i18n.receivingMedium })
                                } }
                                readMode={ readMode }
                            />
                            <NumberField
                                col={ 6 }
                                value={ STEP.populationEquivalent }
                                title={ i18n.populationEquivalent }
                                onChange={ (v) => {
                                    this.onChangeSTEP({ populationEquivalent: v })
                                    this.onChangeVisit({ previousValue: STEP.populationEquivalent, newValue: v, field: i18n.populationEquivalent })
                                } }
                                readMode={ readMode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 6 }
                                value={ STEP.sludgeTreatmentSector }
                                label={ i18n.sludgeTreatmentSector }
                                options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_FILIERETRAITEMENTEBOUES) }
                                keyvalue='code'
                                onChange={ (v) => {
                                    this.onChangeSTEP({ sludgeTreatmentSector: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_FILIERETRAITEMENTEBOUES, i18n.sludgeTreatmentSector, STEP.sludgeTreatmentSector, v))
                                } }
                                integerValue
                                readMode={ readMode }
                            />
                            <Select
                                col={ 6 }
                                value={ STEP.sludgeDisposalSystem }
                                label={ i18n.sludgeDisposalSystem }
                                options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_FILIEREELIMINATIONBOUES) }
                                keyvalue='code'
                                onChange={ (v) => {
                                    this.onChangeSTEP({ sludgeDisposalSystem: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_FILIEREELIMINATIONBOUES, i18n.sludgeDisposalSystem, STEP.sludgeDisposalSystem, v))
                                } }
                                integerValue
                                readMode={ readMode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 6 }
                                value={ STEP.rejectType }
                                label={ i18n.rejectType }
                                options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEREJET) }
                                onChange={ (v) => {
                                    this.onChangeSTEP({ rejectType: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEREJET, i18n.rejectType, STEP.rejectType, v))
                                } }
                                integerValue
                                keyvalue='code'
                                readMode={ readMode }
                            />
                            <Select
                                col={ 6 }
                                value={ STEP.wastewaterTreatmentSector }
                                label={ i18n.wastewaterTreatmentSector }
                                keyvalue='code'
                                options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_FILIERETRAITEMENTEAUXUSEES) }
                                onChange={ (v) => {
                                    this.onChangeSTEP({ wastewaterTreatmentSector: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_FILIERETRAITEMENTEAUXUSEES, i18n.wastewaterTreatmentSector, STEP.wastewaterTreatmentSector, v))
                                } }
                                integerValue
                                readMode={ readMode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={6}
                                label={i18n.watermass}
                                value={STEP.watermassCode}
                                onChange={v => {
                                    this.onChangeSTEP({ watermassCode: v })
                                    this.onChangeVisit(getVisitSelectChange(watermasses, 'id', i18n.watermass, STEP.watermassCode, v))
                                }}
                                options={watersheds}
                                keyValue='id'
                                stringValue
                                readMode={readMode}
                            />
                            <Select
                                col={6}
                                label={i18n.watershed}
                                value={STEP.watershed}
                                onChange={v => {
                                    this.onChangeSTEP({ watershed: v })
                                    this.onChangeVisit(getVisitSelectChange(watersheds, 'id', i18n.watershed, STEP.watershed, v))
                                }}
                                options={watersheds}
                                keyValue='id'
                                readMode={readMode}
                            />
                        </Row>
                        <Row>
                            <Select
                                col={6}
                                value={STEP.networkType}
                                label={i18n.networkType}
                                keyvalue='code'
                                options={getSandreList(sandreCodes, SANDRE.STEP_TYPE_RESEAU)}
                                onChange={(v) => {
                                    this.onChangeSTEP({ networkType: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.STEP_TYPE_RESEAU, i18n.networkType, STEP.networkType, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                            <NumberField
                                col={6}
                                value={STEP.agriSpreadingQuantity}
                                title={i18n.agriSpreadingQuantity}
                                onChange={(v) => {
                                    this.onChangeSTEP({ agriSpreadingQuantity: v })
                                    this.onChangeVisit({ previousValue: STEP.agriSpreadingQuantity, newValue: v, field: i18n.agriSpreadingQuantity })
                                }}
                                floatValue
                                readMode={readMode}
                            />
                        </Row>
                        <Row className='padding-1'>
                            <STEPRejectPointsPanel {...paramsSTEP} />
                        </Row>
                        <Row className='padding-1'>
                            <STEPFollowPointsPanel {...paramsSTEP} />
                        </Row>
                        <Row
                            className='padding-1'
                            displayIf={this.isDisplayed('BASINS')}
                        >
                            <STEPCollectionBasinsPanel {...paramsSTEP} />
                        </Row>
                        <Card
                            title={ i18n.complEquipements }
                            headerStyle={{ backgroundColor: greyBlue }}
                            noBoxShadow
                            className='no-margin-top padding-bottom-1 padding-left-1 padding-right-1'
                        >
                            <Row className='padding-top-1'>
                                <Checkbox
                                    col={ 6 }
                                    checked={ STEP.finishingLagoon }
                                    label={ i18n.finishingLagoon }
                                    onChange={ (v) => {
                                        this.onChangeSTEP({ finishingLagoon: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.finishingLagoon, STEP.finishingLagoon, v))
                                    } }
                                    disabled={ readMode }
                                />
                                <Checkbox
                                    col={ 6 }
                                    checked={ STEP.reuseTreatedWastedwater }
                                    label={ i18n.reuseTreatedWastedwater }
                                    onChange={ (v) => {
                                        this.onChangeSTEP({ reuseTreatedWastedwater: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.reuseTreatedWastedwater, STEP.reuseTreatedWastedwater, v))
                                    } }
                                    disabled={ readMode }
                                />
                            </Row>
                            <Row>
                                <Checkbox
                                    col={ 6 }
                                    checked={ STEP.sandCleaningMaterialsIUnit }
                                    label={ i18n.sandCleaningMaterialsIUnit }
                                    onChange={ (v) => {
                                        this.onChangeSTEP({ sandCleaningMaterialsIUnit: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.sandCleaningMaterialsIUnit, STEP.sandCleaningMaterialsIUnit, v))
                                    } }
                                    disabled={ readMode }
                                />
                                <Checkbox
                                    col={ 6 }
                                    checked={ STEP.fatsUnit }
                                    label={ i18n.fatsUnit }
                                    onChange={ (v) => {
                                        this.onChangeSTEP({ fatsUnit: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.fatsUnit, STEP.fatsUnit, v))
                                    } }
                                    disabled={ readMode }
                                />
                            </Row>
                            <Row>
                                <Checkbox
                                    col={ 6 }
                                    checked={ STEP.drainMaterialUnit }
                                    label={ i18n.drainMaterialUnit }
                                    onChange={ (v) => {
                                        this.onChangeSTEP({ drainMaterialUnit: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.drainMaterialUnit, STEP.drainMaterialUnit, v))
                                    } }
                                    disabled={ readMode }
                                />
                                <Checkbox
                                    col={6}
                                    checked={STEP.azoteTreatment}
                                    label={i18n.azoteTreatment}
                                    onChange={(v) => {
                                        this.onChangeSTEP({ azoteTreatment: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.azoteTreatment, STEP.azoteTreatment, v))
                                    }}
                                    disabled={readMode}
                                />
                            </Row>
                            <Row>
                                <Checkbox
                                    col={6}
                                    checked={STEP.phosphorusTreatment}
                                    label={i18n.phosphorusTreatment}
                                    onChange={(v) => {
                                        this.onChangeSTEP({ phosphorusTreatment: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.phosphorusTreatment, STEP.phosphorusTreatment, v))
                                    }}
                                    disabled={readMode}
                                />
                                <Checkbox
                                    col={6}
                                    checked={STEP.disinfection}
                                    label={i18n.disinfection}
                                    onChange={(v) => {
                                        this.onChangeSTEP({ disinfection: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.disinfection, STEP.disinfection, v))
                                    }}
                                    disabled={readMode}
                                />
                            </Row>
                            <Row>
                                <Checkbox
                                    col={6}
                                    checked={STEP.spreadingPlan}
                                    label={i18n.spreadingPlan}
                                    onChange={(v) => {
                                        this.onChangeSTEP({ spreadingPlan: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.spreadingPlan, STEP.spreadingPlan, v))
                                    }}
                                    disabled={readMode}
                                />
                            </Row>
                        </Card>
                        <Card
                            title={ i18n.civilEngineering }
                            headerStyle={{ backgroundColor: greyBlue }}
                            noBoxShadow
                            className='padding-left-1 padding-right-1 padding-bottom-1'
                            displayIf={ this.isDisplayed('LIFTING_EQUIPMENTS') }
                        >
                            <STEPCivilEngPanel {...paramsSTEP} />
                            <Row className='padding-left-1 padding-right-1'>
                                <BuildingsPanel {...params} />
                            </Row>
                        </Card>
                        <Card
                            title={ i18n.equipments }
                            headerStyle={{ backgroundColor: greyBlue }}
                            noBoxShadow
                            className='no-margin-top padding-left-1 padding-right-1 padding-bottom-1'
                            displayIf={ this.isDisplayed('LIFTING_EQUIPMENTS') }
                        >
                            <STEPEquipmentsPanel { ...paramsSTEP } />
                            <Row className='padding-left-1 padding-right-1'>
                                <InstallationEquipmentsPanel className='padding-left-1 padding-right-1' { ...params } />
                            </Row>
                        </Card>
                        <Row
                            className='no-margin-bottom padding-left-1 padding-right-1 margin-top-1'
                            noMargin={ false }
                            displayIf={ this.isDisplayed('STAFF_SAFETY') }
                        >
                            <STEPAssociatedTownsPanel { ...paramsSTEP } />
                        </Row>
                        <Row
                            className='no-margin-bottom padding-left-1 padding-right-1 margin-top-1'
                            noMargin={ false }
                            displayIf={ this.isDisplayed('STAFF_SAFETY') }
                        >
                            <STEPAssociatedIndustriesPanel { ...paramsSTEP } />
                        </Row>
                    </Card>
                    <Card
                        title={ i18n.communicationElectricity }
                        className='margin-top-1'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('ELEC_COM') }
                    >
                        <InstallationComElecPanel { ...params } />
                    </Card>
                    <Card
                        title={ i18n.accessSite }
                        className='margin-top-1'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('ACCESS_SITE') }
                    >
                        <InstallationAccessSitePanel { ...params } />
                    </Card>
                    <Card
                        title={ i18n.accessCasing }
                        className='margin-top-1'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('ACCESS_CASING') }
                    >
                        <InstallationAccessCasingPanel { ...params } />
                    </Card>
                    <Row
                        className='margin-top-1 no-margin-bottom'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('STAFF_SAFETY') }
                    >
                        <InstallationStaffSafetyPanel { ...params } />
                    </Row>
                    <Row
                        className='margin-top-1 no-margin-bottom'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('CONTRIBUTORS') }
                    >
                        <StationPiezoContributorPanel { ...params } />
                    </Row>
                    <Row
                        className='margin-top-1 no-margin-bottom'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('USAGES') }
                    >
                        <StationUsagePanel { ...params } />
                    </Row>
                    <Row
                        className='margin-top-1 no-margin-bottom'
                        noMargin={ false }
                        displayIf={ this.isDisplayed('ARRANGEMENTS') }
                    >
                        <StationArrangementPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={accountHabilitations.some((h) => h.habilitation === AGRI) && this.isDisplayed('SAMPLERS')}>
                        <StationSamplersPanel {...params}/>
                    </Row>
                </div>
                <div className='col s3' style={ { marginBottom: '100px' } }>
                    <StationUpdatePanel station={ installation } />
                    <Card noMargin={ false } className='margin-top-1 padding-bottom-1'>
                        <Row className={`${(!readMode && 'padding-top-1') || ''}`}>
                            <Input
                                col={ 12 }
                                title={ i18n.dataOrigin }
                                value={ installation.dataOrigin }
                                onChange={ v => {
                                    this.onChangeInstallation({ dataOrigin: v })
                                    this.onChangeVisit({ previousValue: installation.dataOrigin, newValue: v, field: i18n.dataOrigin })
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.status }
                                value={ installation.status }
                                onChange={ v => {
                                    this.onChangeInstallation({
                                        status: v,
                                        statusLogin: getLogin(),
                                        statusDate: moment().valueOf(),
                                    })
                                    this.onChangeVisit(getVisitSelectChange(this.props.status, 'code', i18n.status, installation.status, v))
                                } }
                                options={ this.props.status }
                                keyvalue='code'
                                integerValue
                                { ...mode }
                            />
                        </Row>
                        <Row className={`${(!readMode && 'padding-bottom-1') || ''}`}>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.statusDate) }
                                title={ i18n.controlDoneAt }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ statusDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.controlDoneAt, installation.statusDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.controlDoneBy }
                                value={ installation.statusLogin }
                                keyvalue='name'
                                onChange={ v => {
                                    this.onChangeInstallation({ statusLogin: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.users.map(u => ({ code: u.login, name: u.name || u.login })), 'name', i18n.controlDoneBy, installation.statusLogin, v))
                                } }
                                options={ this.props.users.map(u => ({ code: u.login, name: u.name || u.login })) } { ...mode }
                            />
                        </Row>
                        <Row className={`${(!readMode && 'padding-bottom-1') || ''}`}>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.endDate) }
                                title={ i18n.deliberationDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ endDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.deliberationDate, installation.endDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } value={ installation.closeComment } title={ i18n.become }
                                onChange={ v => {
                                    this.onChangeInstallation({ closeComment: v })
                                    this.onChangeVisit({ previousValue: installation.closeComment, newValue: v, field: i18n.become })
                                } } { ...mode }
                            />
                        </Row>
                        <Row>
                            <Checkbox col={ 12 } label={ i18n.confidential } checked={ installation.confidential === '1' }
                                onChange={ v => {
                                    this.onChangeInstallation({ confidential: v ? '1' : '0' })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.confidential, installation.confidential === '1', v))
                                } } disabled={ readMode }
                            />
                        </Row>
                    </Card>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={ i18n.contact }
                            contactCode={ installation.ownerCode }
                            onChange={ v => {
                                this.onChangeInstallation({ ownerCode: v })
                                this.onChangeVisit(getVisitSelectChange(this.props.contacts, 'code', i18n.contact, installation.ownerCode, v))
                            } }
                            readMode={ readMode }
                        />
                    </Row>
                    <LastEventPanel
                        id={ installation.id }
                        events={ this.props.installationEvents }
                        stationId={ installation.id }
                        stationType='installation'
                    />
                    <Card
                        title={ i18n.map }
                        noMargin={ false }
                        className='margin-top-1'
                        actions={
                            !this.state.readMode ?
                                [{
                                    iconName: 'my_location',
                                    onClick: () => this.setState({ localizeMap: true }),
                                }] : []
                        }
                    >
                        <StationMapDashboardPanel noMarkerTooltip station={this.props.installation} type={'installation'} />
                        <LocalisationMapModal
                            onChangeInstallation={this.onChangeInstallation}
                            installation={installation}
                            readMode={readMode}
                            open={localizeMap}
                            onClose={ () => this.setState({ localizeMap: false }) }
                        />
                    </Card>
                    <Card noMargin={ false } title={ i18n.localisation } className='margin-top-1'>
                        <StationLocationInfoPanel
                            onChange={ this.onChangeInstallation }
                            onChangeVisit={ this.onChangeVisit }
                            station={ installation }
                            readMode={ readMode }
                        />
                    </Card>
                    <LinkedStationsPanel
                        noMargin={ false }
                        className='margin-top-1'
                        onReMount={ this.props.onReMount }
                        station={ installation }
                        { ...mode }
                    />
                </div>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM &&
            nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)
        ) {
            return false
        }
        return true
    }
}

STEPDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationSTEP: store.InstallationReducer.installationSTEP,
    installationVisits: store.InstallationReducer.installationVisits,
    installationsLight: store.InstallationReducer.installationsLight,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    watermasses: store.WatermassReducer.watermasses,
    watersheds: store.WatershedReducer.watersheds,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationSTEP: InstallationAction.updateInstallationSTEP,
    fetchInstallationSTEP: InstallationAction.fetchInstallationSTEP,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    fetchInstallationsLight: InstallationAction.fetchInstallationsLight,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
    fetchWatermasses: WatermassAction.fetchWatermasses,
    fetchWatersheds: WatershedAction.fetchWatersheds,
}

export default connect(mapStateToProps, mapDispatchToProps)(STEPDescriptionPanel)
