import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { ceil, chunk } from 'lodash'
import { hasValue, nbElements } from '../../utils/NumberUtil'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import ContributorDto from '../../referencial/components/contributor/dto/ContributorDto'
import CityDto from '../../referencial/components/city/dto/CityDto'
import PanelList from '../list/panelList/PanelList'
import Icon from '../icon/Icon'
import Input from '../forms/Input'

const SelectEmail = ({
    mailContacts = [],
    mailContributors = [],
    mailCities = [],
    mailReceivers = [],
    onChangeEmails = () => { },

    contacts = [],
    contributors = [],
    cities = [],
}) => {
    const getEmailList = (list, emailSelected, key) => {
        const listEmail = chunk(list.map(c => {
            const isSelected = emailSelected.includes(c.email)
            return (
                <div className='valign-wrapper' onClick={() => onChangeEmails({ [key]: isSelected ? emailSelected.filter(email => email !== c.email) : [...emailSelected, c.email] })}>
                    <div className='col s1 no-margin'>
                        <i className='material-icons'>{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>
                    </div>
                    <div className='col s11 no-margin'>
                        <label style={{ fontSize: '1.3rem' }}>{`${c.name} (${c.email})`}</label>
                    </div>
                </div>
            )
        }), ceil(list.length / 2))
        return (
            <div className='row no-margin'>
                <div className='col s6'>
                    {listEmail[0]}
                </div>
                <div className='col s6'>
                    {listEmail[1]}
                </div>
            </div>
        )
    }

    return (
        <div>
            <ul className='sieau-collapsible' data-collapsible='expandable'>
                <PanelList collapsible title={i18n.personalizedEmail} actions={[{ icon: 'note_add', label: i18n.add, onClick: () => onChangeEmails({ mailReceivers: [...mailReceivers, ''] }) }]}>
                    {mailReceivers.map((email, index) => (
                        <div className='row no-margin valign-wrapper padding-top-1'>
                            <div className='col s1'>
                                <Icon icon='clear' onClick={() => onChangeEmails({ mailReceivers: mailReceivers.filter((_, i) => i !== index) })} />
                            </div>
                            <div className='col s11'>
                                <Input title={i18n.email} value={email} onChange={v => {
                                    const newMail = [...mailReceivers]
                                    newMail[index] = v
                                    onChangeEmails({ mailReceivers: newMail })
                                }}
                                />
                            </div>
                        </div>
                    ))}
                </PanelList>
                <PanelList
                    collapsible
                    title={i18n.contacts + nbElements(mailContacts.length)}
                    actions={[
                        { icon: 'check_box', label: i18n.addAll, onClick: () => onChangeEmails({ mailContacts: contacts.map(c => c.email) }) },
                        { icon: 'check_box_outline_blank', label: i18n.deleteAll, onClick: () => onChangeEmails({ mailContacts: [] }) },
                    ]}
                >
                    {getEmailList(contacts, mailContacts, 'mailContacts')}
                </PanelList>
                <PanelList
                    collapsible
                    title={i18n.contributors + nbElements(mailContributors.length)}
                    actions={[
                        { icon: 'check_box', label: i18n.addAll, onClick: () => onChangeEmails({ mailContributors: contributors.map(c => c.email) }) },
                        { icon: 'check_box_outline_blank', label: i18n.deleteAll, onClick: () => onChangeEmails({ mailContributors: [] }) },
                    ]}
                >
                    {getEmailList(contributors, mailContributors, 'mailContributors')}
                </PanelList>
                <PanelList
                    collapsible
                    title={i18n.cities + nbElements(mailCities.length)}
                    actions={[
                        { icon: 'check_box', label: i18n.addAll, onClick: () => onChangeEmails({ mailCities: cities.map(c => c.email) }) },
                        { icon: 'check_box_outline_blank', label: i18n.deleteAll, onClick: () => onChangeEmails({ mailCities: [] }) },
                    ]}
                >
                    {getEmailList(cities, mailCities, 'mailCities')}
                </PanelList>
            </ul>
        </div>
    )
}

SelectEmail.propTypes = {
    mailContacts: PropTypes.arrayOf(PropTypes.string),
    mailContributors: PropTypes.arrayOf(PropTypes.string),
    mailCities: PropTypes.arrayOf(PropTypes.string),
    mailReceivers: PropTypes.arrayOf(PropTypes.string),
    onChangeEmails: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts.filter(c => hasValue(c.email) && !c.noNewsLetterDate && !c.noNewLetterLogin),
    contributors: store.ContributorReducer.contributors.filter(c => hasValue(c.email) && !c.noNewsLetterDate && !c.noNewLetterLogin),
    cities: store.CityReducer.cities.filter(c => hasValue(c.email)),
})

export default connect(mapStateToProps)(SelectEmail)