import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'
import AppStore from 'store/AppStore'
import HomeAction from '../../home/actions/HomeAction'
import StationAction from '../actions/StationAction'
import OperationAction from '../../quality/components/operation/actions/OperationAction'
import { links } from '../constants/LinksConstants'
import DtoStation from '../dto/DtoStation'
import DtoPiezometer from '../../piezometry/dto/DtoPiezometer'
import DtoHydrometricStation from '../../hydrometry/dto/DtoHydrometricStation'
import PiezometerStationAction from '../actions/PiezometerStationAction'
import HydrometerStationAction from '../actions/HydrometerStationAction'
import ProductionUnitAction from '../../productionUnit/actions/ProductionUnitAction'
import DistributionUnitAction from '../../distributionUnit/actions/DistributionUnitAction'
import { execByType, getStation } from '../../utils/StationUtils'
import DtoAssociatedStation from '../dto/DtoAssociatedStation'
import SieauAction from '../../components/sieau/SieauAction'
import { getMapStateToProps, getPropTypes } from '../../utils/StoreUtils'
import { NEW, STATION_NAME_ASSOCIATION } from '../constants/StationConstants'
import WatershedAction from '../../referencial/components/watershed/actions/WatershedAction'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import i18n from 'simple-react-i18n'
import DtoAccountHabilitation from '../../account/dto/DtoAccountHabilitation'
import PluviometerDto from '../../pluviometry/dto/PluviometerDto'
import PluviometryAction from '../../pluviometry/actions/PluviometryAction'
import DtoProductionUnit from '../../productionUnit/dto/DtoProductionUnit'
import DtoDistributionUnit from '../../distributionUnit/dto/DtoDistributionUnit'
import DtoInstallation from '../../installation/dto/installation/DtoInstallation'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import DtoPiezometerLight from '../../piezometry/dto/DtoPiezometerLight'
import ActionComponent from '../../components/ActionComponent'
import MaterielAction from '../../materiel/actions/MaterielAction'
import CampaignAction from '../../campaign/actions/CampaignAction'
import EventsAction from '../../events/actions/EventsAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import QualityAction from '../../quality/actions/QualityAction'
import ExportAction from '../../export/actions/ExportAction'
import { INSTALLATION_TYPE } from '../../installation/constants/InstallationConstants'
import { Redirect, Route, Switch } from 'react-router-dom'
import StationDashboardApp from './dashboard/StationDashboardApp'
import StationDescriptionApp from './description/StationDescriptionApp'
import StationOperationApp from 'quality/components/operation/components/StationOperationApp'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import PcMonitoringApp from './suivipc/suiviPC2/PcMonitoringApp'
import QualitometerStatesApp from 'quality/components/states/QualitometerStatesApp'
import InstallationFollowUpApp from 'installation/components/installationDefault/InstallationFollowUpApp'
// import InstallationAEPIndicatorsApp from 'installation/components/installationDefault/InstallationAEPIndicatorsApp'
import PictureStationApp from './picture/PictureStationApp'
import StationAssociatedStationApp from './associatedStation/StationAssociatedStationApp'
import StationEventsApp from './event/StationEventsApp'
import StationEventTypeDispatcher from './event/StationEventTypeDispatcher'
import STEPTerritoryApp from 'installation/components/step/components/STEPTerritoryApp'
import PiezometryValidationApp from 'piezometry/components/validation/PiezometryValidationApp'
import SituationDetailApp from './materiel/situationDetail/SituationDetailApp'
import MaterielStationApp from './materiel/MaterielStationApp'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_MAT_MODULE,
    H_STATION_HYDRO_DASHBOARD,
    H_STATION_HYDRO_DESCRIPTION,
    H_STATION_HYDRO_DOCUMENTS,
    H_STATION_HYDRO_EVENTS,
    H_STATION_HYDRO_FOLLOW_UP,
    H_STATION_HYDRO_MAT,
    H_STATION_HYDRO_VALIDATION,
    H_STATION_HYDRO_MODELS,
    // H_STATION_INSTALLATION_AEP_INDICATOR,
    H_STATION_INSTALLATION_CONFORMITY,
    H_STATION_INSTALLATION_DASHBOARD,
    H_STATION_INSTALLATION_DESCRIPTION,
    H_STATION_INSTALLATION_DOCUMENTS,
    H_STATION_INSTALLATION_EVENTS,
    H_STATION_INSTALLATION_FOLLOW_UP,
    H_STATION_INSTALLATION_MAT,
    H_STATION_INSTALLATION_STEP,
    H_STATION_INSTALLATION_SUIVIPC,
    H_STATION_PIEZO_DASHBOARD,
    H_STATION_PIEZO_DESCRIPTION,
    H_STATION_PIEZO_DOCUMENTS,
    H_STATION_PIEZO_EVENTS,
    H_STATION_PIEZO_FOLLOW_UP,
    H_STATION_PIEZO_MAT,
    H_STATION_PIEZO_VALIDATION,
    H_STATION_PIEZO_MODELS,
    H_STATION_PLUVIO_DASHBOARD,
    H_STATION_PLUVIO_DESCRIPTION,
    H_STATION_PLUVIO_DOCUMENTS,
    H_STATION_PLUVIO_EVENTS,
    H_STATION_PLUVIO_MAT, H_STATION_PLUVIO_VALIDATION,
    H_STATION_QUALITO_DASHBOARD,
    H_STATION_QUALITO_DESCRIPTION,
    H_STATION_QUALITO_DOCUMENTS,
    H_STATION_QUALITO_EVENTS,
    H_STATION_QUALITO_HYDROBIO_MONITORING,
    H_STATION_QUALITO_HYDROBIO_OPERATIONS,
    H_STATION_QUALITO_MAT,
    H_STATION_QUALITO_OPERATIONS,
    H_STATION_QUALITO_STATES,
    H_STATION_QUALITO_SUIVI_PC,
    H_UNITS_CONFORMITY,
    H_UNITS_COUNTERS,
    H_UNITS_DASHBOARD,
    H_UNITS_DESCRIPTION,
    H_UNITS_DOCUMENTS,
    H_UNITS_EVENTS,
    H_UNITS_GRAPHIC,
    H_UNITS_SUIVIPC,
} from '../../account/constants/AccessRulesConstants'
import BoundaryError from 'log/components/BoundaryError'
import STEPPollutionSearchApp from 'installation/components/step/components/STEPPollutionSearchApp'
import AgriAction from 'agriAdministration/actions/AgriAction'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import DistributionUnitPcMonitoringApp from 'distributionUnit/components/DistributionUnitPcMonitoringApp'
import ProductionUnitPcMonitoringApp from 'productionUnit/components/ProductionUnitPcMonitoringApp'
import InstallationPcMonitoringApp from 'installation/components/InstallationPcMonitoringApp'
import IAEauModelsApp from './iaeauModels/IAEauModelsApp'
import DistributionUnitAEPApp from 'distributionUnit/components/DistributionUnitAEPApp'
import ProductionUnitAEPApp from 'productionUnit/components/ProductionUnitAEPApp'
import InstallationAEPApp from 'installation/components/InstallationAEPApp'
import InstallationsExport from '../../installation/components/step/InstallationsExport'
import DistributionUnitAssociatedGraphic from 'distributionUnit/components/DistributionUnitAssociatedGraphic'
import ProductionUnitAssociatedGraphic from 'productionUnit/components/ProductionUnitAssociatedGraphic'
import StationMapApp from './dashboard/StationMapApp'
import HydrometerObservationsApp from 'hydrometry/components/observation/HydrometerObservationsApp'
import PiezometerSuiviTableApp from '../../piezometry/components/suiviTable/PiezometerSuiviTableApp'
import HydroSuiviTableApp from '../../hydrometry/components/followUpTable/HydroSuiviTableApp'
import HydrometryValidationApp from '../../hydrometry/components/validation2/HydrometryValidationApp'
import HydrobioOperationsApp from 'quality/components/hydrobio/operation/HydrobioOperationsApp'
import HydrobioOperationApp from 'quality/components/hydrobio/operation/HydrobioOperationApp'
import HydrobioMonitoringApp from 'quality/components/hydrobio/monitoring/HydrobioMonitoringApp'
import { ProductionUnitActionConstant } from 'productionUnit/reducers/ProductionUnitReducer'
import { PluviometryActionConstant } from 'pluviometry/reducers/PluviometryReducer'
import DistributionUnitCounterManager from 'distributionUnit/components/DistributionUnitCounterManager'
import PluviometryValidationApp from '../../pluviometry/components/validation/PluviometryValidationApp'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import HydrometerSuiviApp from '../../hydrometry/components/followUp/components/HydrometerSuiviApp'
import OperationsListApp from 'quality/components/operation/components/list/OperationsListApp'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { DistributionUnitActionConstant } from 'distributionUnit/reducers/DistributionUnitReducer'
import DtoQualityIndicators from 'quality/dto/QualityIndicator/DtoQualityIndicators'
import PluvioSuiviTableApp from '../../pluviometry/components/followUpTable/PluvioSuiviTableApp'
import IAEauModelApp from './iaeauModels/IAEauModelApp'
import PiezoSuiviChart2 from '../../piezometry/components/suivi/components/PiezoSuiviChart2'
import PluvioSuiviGraphApp from '../../pluviometry/components/followUp/PluvioSuiviGraphApp'
import InstallationAction from 'installation/actions/InstallationAction'

const propsToFetch = {
    qualitometers: true,
    settings: false,
    hydrometricStation: true,
    cities: false,
    sandreCodes: true,
    productionUnit: false,
    distributionUnit: false,
    installation: false,
    stationTypes: false,
}

class StationApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            stationTypes: props.stationTypes,
            associatedSitesFetched: false,
            filesFetched: false,
            picturesFetched: false,
        }
    }

    onRemount = () => {
        this.setState({ stationTypes: [], associatedSitesFetched: false, filesFetched: false, picturesFetched: false }, this.onResetStationTypes)
    }

    onResetStationTypes = () => {
        this.resetData()
        this.fetchData()
    }

    componentDidMount() {
        this.fetchData()
        this.checkStationTypes(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.type !== nextProps.match.params.type || this.props.match.params.id !== nextProps.match.params.id) {
            this.onRemount()
        }

        ['quality', 'piezometry', 'pluviometry', 'hydrometry', 'productionUnit', 'distributionUnit', 'installation'].map(type => {
            const station = getStation(this.props, type)
            const nextStation = getStation(nextProps, type)
            if (!station.id && nextStation.id) {
                this.onReceiveStation(nextProps, type)
            }
        })
    }

    onReceiveStation = (props, type) => {
        execByType(type, {
            quality: () => {
                if (props.qualitometer.id) {
                    AppStore.dispatch(HomeAction.setHelpLink('qualite', '5'))
                    AppStore.dispatch(SieauAction.fetch('stationEvents', type, props.qualitometer.id))
                    AppStore.dispatch(StationAction.fetchQualitomerWaterMass(props.qualitometer.id))
                    this.props.fetchQualitometerPoints(props.qualitometer.id)
                    if (!this.props.qualityIndicators.length) {
                        this.props.fetchQualityIndicators()
                    }
                }
            },
            piezometry: () => {
                if (props.piezometer.id) {
                    AppStore.dispatch(HomeAction.setHelpLink('piezometrie', ''))
                    AppStore.dispatch(SieauAction.fetch('stationEvents', type, props.piezometer.id))
                    this.props.fetchAllPiezometerActions(props.piezometer.id)
                }
            },
            pluviometry: () => {
                if (props.pluviometer.id) {
                    AppStore.dispatch(HomeAction.setHelpLink('pluviometrie', ''))
                    AppStore.dispatch(SieauAction.fetch('stationEvents', type, props.pluviometer.id))
                    this.props.fetchPluviometerCentralAssignment(props.pluviometer.id)
                    this.props.fetchPluviometerPowerSupplyAssignment(props.pluviometer.id)
                    this.props.fetchPluviometerSensorAssignment(props.pluviometer.id)
                    this.props.fetchPluviometerSimAssignment(props.pluviometer.id)
                    this.props.fetchPluviometerTelecomAssignment(props.pluviometer.id)
                    this.props.fetchPluviometerVariousMaterielAssignment(props.pluviometer.id)
                    this.props.fetchPluviometerEquipmentAssignment(props.pluviometer.id)
                    this.props.fetchPluviometerSubscriptionAssignment(props.pluviometer.id)
                }
            },
            hydrometry: () => {
                if (props.hydrometricStation.id) {
                    AppStore.dispatch(HomeAction.setHelpLink('hydrometrie', ''))
                    AppStore.dispatch(SieauAction.fetch('stationEvents', type, props.hydrometricStation.id))
                    this.props.fetchHydrometryCentralAssignment(props.hydrometricStation.id)
                    this.props.fetchHydrometryPowerSupplyAssignment(props.hydrometricStation.id)
                    this.props.fetchHydrometrySensorAssignment(props.hydrometricStation.id)
                    this.props.fetchHydrometrySimAssignment(props.hydrometricStation.id)
                    this.props.fetchHydrometryTelecomAssignment(props.hydrometricStation.id)
                    this.props.fetchHydrometryVariousMaterielAssignment(props.hydrometricStation.id)
                    this.props.fetchHydrometryEquipmentAssignment(props.hydrometricStation.id)
                    this.props.fetchHydrometrySubscriptionAssignment(props.hydrometricStation.id)
                }
            },
            productionUnit: () => {
                AppStore.dispatch(HomeAction.setHelpLink('', ''))
                AppStore.dispatch(SieauAction.fetch('productionUnitAssociations', props.productionUnit.id))
            },
            distributionUnit: () => {
                AppStore.dispatch(HomeAction.setHelpLink('', ''))
                AppStore.dispatch(SieauAction.fetch('stationEvents', type, props.distributionUnit.id))
                this.props.fetchDistributionUnitLink(props.distributionUnit.id)
            },
            installation: () => {
                AppStore.dispatch(HomeAction.setHelpLink('', ''))
                this.props.fetchInstallationCentralAssignment(props.installation.id)
                this.props.fetchInstallationPowerSupplyAssignment(props.installation.id)
                this.props.fetchInstallationSensorAssignment(props.installation.id)
                this.props.fetchInstallationSimAssignment(props.installation.id)
                this.props.fetchInstallationTelecomAssignment(props.installation.id)
                this.props.fetchInstallationVariousMaterielAssignment(props.installation.id)
                this.props.fetchInstallationEquipmentAssignment(props.installation.id)
                AppStore.dispatch(SieauAction.fetch('installationAssociations', props.installation.id))
            },
        })
        const station = getStation(props, type)
        const stationTypeName = STATION_NAME_ASSOCIATION[this.props.match.params.type]
        if (stationTypeName) {
            const found = this.props.stationTypes.find(t => t.name === stationTypeName)
            if (station && found) {
                this.props.fetchAssociatedSites(station.code, found.code).then(() => this.setState({ associatedSitesFetched: true }))
            }
        }
    }

    componentWillUnmount() {
        this.resetData()
    }

    fetchData = () => {
        const { match, accountHabilitations } = this.props
        AppStore.dispatch(HomeAction.setBackPath(`/${match.params.type}`))
        AppStore.dispatch(SieauAction.fetch(['parameters', 'units', 'contributors'], match.params.id))
        this.props.fetchMaterielTypes()
        if (accountHabilitations.some((h) => h.habilitation === AGRI)) {
            this.props.fetchExploitationsLight()
        }

        this.fetchStation(match.params.type, match.params.id)
        AppStore.dispatch(PiezometryAction.fetchPiezometersLight())
        AppStore.dispatch(PluviometryAction.fetchPluviometers())
        AppStore.dispatch(SieauAction.fetch(propsToFetch))
        AppStore.dispatch(CityAction.fetchCities())
        AppStore.dispatch(HomeAction.setHelpLink('qualite'))

        this.props.fetchApplicationSettings()
    }

    resetData = () => {
        AppStore.dispatch(SieauAction.update('selectedSearchValues', 'quality', { displayInListMode: false }))
        AppStore.dispatch(StationAction.resetStation())
        AppStore.dispatch(SieauAction.reset('suiviPC'))
        AppStore.dispatch(OperationAction.fetchReset())
        AppStore.dispatch(PiezometerStationAction.reset())
        AppStore.dispatch(HydrometerStationAction.reset())
        AppStore.dispatch(PluviometryActionConstant.resetPluviometerStation())
        AppStore.dispatch(ProductionUnitActionConstant.resetProductionUnit())
        AppStore.dispatch(SieauAction.reset(['installation', 'productionUnit', 'distributionUnit', 'stationEvents']))
        AppStore.dispatch(WatershedAction.resetWatershed())
        AppStore.dispatch(EventsAction.resetStationEvents())
        this.props.resetDistributionUnitLink()
    }

    checkStationTypes() {
        // this.setState({ stationTypes: [this.props.match.params.type] })
        // if (nextProps.qualitometers.length && nextProps.piezometers.length && nextProps.pluviometers.length && nextProps.hydrometricStations.length
        //     && this.state.stationTypes.length === 0 && station.code && nextProps.associatedSites.length) {

        // ['quality', 'pluviometry', 'piezometry', 'hydrometry'].map(type => {
        //     if (!stationTypes.find(elem => elem === type)) {
        //         execByType(type, {
        //             quality: () => {
        //                 const foundQualitometer = nextProps.qualitometers.find(elem => elem.code === station.code)
        //                 if (foundQualitometer && foundQualitometer.id) {
        //                     stationTypes.push(type)
        //                     this.fetchStation(type, foundQualitometer.id)
        //                 }
        //             },
        //             piezometry: () => {
        //                 const foundPiezometer = nextProps.piezometers.find(elem => elem.code === station.code)
        //                 if (foundPiezometer && foundPiezometer.id) {
        //                     stationTypes.push(type)
        //                     this.fetchStation(type, foundPiezometer.id)
        //                 }
        //             },
        //             pluviometry: () => {
        //                 const foundPluviometer = nextProps.pluviometers.find(elem => elem.code === station.code)
        //                 if (foundPluviometer && foundPluviometer.id) {
        //                     stationTypes.push(type)
        //                     this.fetchStation(type, foundPluviometer.id)
        //                 }
        //             },
        //             hydrometry: () => {
        //                 const foundHydrometricStation = nextProps.hydrometricStations.find(elem => elem.code === station.code)
        //                 if (foundHydrometricStation && foundHydrometricStation.id) {
        //                     stationTypes.push(type)
        //                     this.fetchStation(type, foundHydrometricStation.id)
        //                 }
        //             }
        //         })
        //     }
        // })

        // }
    }

    fetchDocumentsAssociated = (code, type) => {
        if (code?.replace(' ', '') !== '') {
            this.props.fetchPictures(code, type).then(() => this.setState({ picturesFetched: true }))
            this.props.fetchFiles(code, type).then(() => this.setState({ filesFetched: true }))
        }
    }

    fetchStation = (type, id) => {
        if (id && id !== NEW) {
            execByType(type, {
                quality: () => {
                    this.props.fetchQualitometer(id).then(() => {
                        this.fetchDocumentsAssociated(this.props.qualitometer.code, STATION_TYPE_CONSTANT.QUALITY)
                    })
                    this.props.fetchQualityCampaigns()
                    this.props.fetchQualityStationCampaigns()
                    this.props.fetchEnvironmentModelsByType(type)
                },
                piezometry: () => {
                    this.props.fetchPiezometer(id).then(() => {
                        this.fetchDocumentsAssociated(this.props.piezometer.code, STATION_TYPE_CONSTANT.PIEZOMETRY)
                    })
                    this.props.fetchPiezometryCampaigns()
                    this.props.fetchPiezometryStationCampaigns()
                    this.props.fetchEnvironmentModelsByType(type)
                },
                pluviometry: () => {
                    this.props.fetchPluviometer(id).then(() => {
                        this.fetchDocumentsAssociated(this.props.pluviometer.code, STATION_TYPE_CONSTANT.PLUVIOMETRY)
                    })
                    this.props.fetchPluviometryCampaigns()
                    this.props.fetchPluviometryStationCampaigns()
                    this.props.fetchEnvironmentModelsByType(type)
                },
                hydrometry: () => {
                    this.props.fetchHydrometricStation(id).then(() => {
                        this.fetchDocumentsAssociated(this.props.hydrometricStation.code, STATION_TYPE_CONSTANT.HYDROMETRY)
                    })
                    this.props.fetchHydrometryCampaigns()
                    this.props.fetchHydrometryStationCampaigns()
                    this.props.fetchEnvironmentModelsByType(type)
                },
                productionUnit: () => {
                    this.props.fetchProductionUnit(id).then(() => {
                        this.fetchDocumentsAssociated(this.props.productionUnit.code, STATION_TYPE_CONSTANT.PRODUCTION)
                    })
                    this.props.fetchProductionUnitEvents(id)
                },
                distributionUnit: () => {
                    this.props.fetchDistributionUnit(id).then(() => {
                        this.fetchDocumentsAssociated(this.props.distributionUnit.code, STATION_TYPE_CONSTANT.DISTRIBUTION)
                    })
                },
                installation: () => {
                    this.props.fetchInstallation(id).then(() => {
                        this.fetchDocumentsAssociated(this.props.installation.code, STATION_TYPE_CONSTANT.INSTALLATION)
                    })
                    this.props.fetch('installationEvents', id)
                    this.props.fetchInstallationCampaigns()
                    this.props.fetchInstallationStationCampaigns()
                },
            })
        }
    }

    getLink = (linkName) => {
        if (linkName !== 'materiel' || componentHasHabilitations(H_MAT_MODULE)) {
            return {
                ...links[linkName],
                href: `/station/${this.props.match.params.type}/${this.props.match.params.id}/${linkName}`,
                name: i18n[links[linkName].i18n],
            }
        }
        return null
    }

    getInstallationsLinks = () => {
        const isQualitoAssociated = this.props.associatedSites.some(site => site.stationLinkedType === 3)
        // const aepLinks = this.props.associatedSites.length > 0 && this.props.installation.installationType === INSTALLATION_TYPE.BOREHOLE ? [
        //     { habilitation: H_STATION_INSTALLATION_AEP_INDICATOR, link: 'installationAEPIndicators' },
        // ] : []
        const stepLinks = this.props.installation.installationType === INSTALLATION_TYPE.STEP ? [
            { habilitation: H_STATION_INSTALLATION_STEP, link: 'STEPTerritory' },
            { habilitation: H_STATION_INSTALLATION_STEP, link: 'STEPPollutionSearch' },
            { habilitation: H_STATION_INSTALLATION_STEP, link: 'export' },
        ] : []
        const qualityFeatures = isQualitoAssociated ? [
            { habilitation: H_STATION_INSTALLATION_SUIVIPC, link: 'pcMonitoring' },
            { habilitation: H_STATION_INSTALLATION_CONFORMITY, link: 'conformityOverview' },
        ] : []
        return [
            { habilitation: H_STATION_INSTALLATION_DASHBOARD, link: 'dashboard' },
            { habilitation: H_STATION_INSTALLATION_DESCRIPTION, link: 'description' },
            { habilitation: H_STATION_INSTALLATION_DASHBOARD, link: 'map' },
            { habilitation: H_STATION_INSTALLATION_EVENTS, link: 'event' },
            { habilitation: H_STATION_INSTALLATION_DOCUMENTS, link: 'picture' },
            { habilitation: H_STATION_INSTALLATION_FOLLOW_UP, link: 'installationFollowUp' },
            // ...aepLinks,
            ...qualityFeatures,
            { habilitation: H_STATION_INSTALLATION_MAT, link: 'materiel' },
            ...stepLinks,
        ]
    }

    render() {
        const navBarLinks = execByType(this.props.match.params.type, {
            quality: () => [
                { habilitation: H_STATION_QUALITO_DASHBOARD, link: 'dashboard' },
                { habilitation: H_STATION_QUALITO_DESCRIPTION, link: 'description' },
                { habilitation: H_STATION_QUALITO_DASHBOARD, link: 'map' },
                { habilitation: H_STATION_QUALITO_EVENTS, link: 'event' },
                { habilitation: H_STATION_QUALITO_DOCUMENTS, link: 'picture' },
                { habilitation: H_STATION_QUALITO_OPERATIONS, link: 'operation' },
                { habilitation: H_STATION_QUALITO_SUIVI_PC, link: 'suiviPC' },
                { habilitation: H_STATION_QUALITO_STATES, link: 'qualityStates' },
                { habilitation: H_STATION_QUALITO_HYDROBIO_OPERATIONS, link: 'hydrobioOperation' },
                { habilitation: H_STATION_QUALITO_HYDROBIO_MONITORING, link: 'hydrobioMonitoring' },
                { habilitation: H_STATION_QUALITO_MAT, link: 'materiel' },
            ],
            piezometry: () => [
                { habilitation: H_STATION_PIEZO_DASHBOARD, link: 'dashboard' },
                { habilitation: H_STATION_PIEZO_DESCRIPTION, link: 'description' },
                { habilitation: H_STATION_PIEZO_DASHBOARD, link: 'map' },
                { habilitation: H_STATION_PIEZO_EVENTS, link: 'event' },
                { habilitation: H_STATION_PIEZO_DOCUMENTS, link: 'picture' },
                { habilitation: H_STATION_PIEZO_FOLLOW_UP, link: 'piezometricFollowUp' },
                { habilitation: H_STATION_PIEZO_FOLLOW_UP, link: 'piezoSuiviTable' },
                { habilitation: H_STATION_PIEZO_VALIDATION, link: 'validation' },
                { habilitation: H_STATION_PIEZO_MAT, link: 'materiel' },
                { habilitation: H_STATION_PIEZO_MODELS, link: 'iaeauModels' },
            ],
            pluviometry: () => [
                { habilitation: H_STATION_PLUVIO_DASHBOARD, link: 'dashboard' },
                { habilitation: H_STATION_PLUVIO_DESCRIPTION, link: 'description' },
                { habilitation: H_STATION_PLUVIO_DASHBOARD, link: 'map' },
                { habilitation: H_STATION_PLUVIO_EVENTS, link: 'event' },
                { habilitation: H_STATION_PLUVIO_DOCUMENTS, link: 'picture' },
                { habilitation: H_STATION_PLUVIO_DASHBOARD, link: 'pluviometricFollowUp' },
                { habilitation: H_STATION_PLUVIO_DASHBOARD, link: 'pluviometricSuiviTable' },
                // { habilitation: H_STATION_PLUVIO_VALIDATION, link: 'validationData' },
                { habilitation: H_STATION_PLUVIO_VALIDATION, link: 'validationPluvio' },
                { habilitation: H_STATION_PLUVIO_MAT, link: 'materiel' },
            ],
            hydrometry: () => [
                { habilitation: H_STATION_HYDRO_DASHBOARD, link: 'dashboard' },
                { habilitation: H_STATION_HYDRO_DESCRIPTION, link: 'description' },
                { habilitation: H_STATION_HYDRO_DASHBOARD, link: 'map' },
                { habilitation: H_STATION_HYDRO_EVENTS, link: 'event' },
                { habilitation: H_STATION_HYDRO_DOCUMENTS, link: 'picture' },
                { habilitation: H_STATION_HYDRO_FOLLOW_UP, link: 'hydrometricFollowUp' },
                { habilitation: H_STATION_HYDRO_FOLLOW_UP, link: 'hydroSuiviTable' },
                { habilitation: H_STATION_HYDRO_FOLLOW_UP, link: 'observations' },
                { habilitation: H_STATION_HYDRO_MAT, link: 'materiel' },
                { habilitation: H_STATION_HYDRO_VALIDATION, link: 'validationHydro' },
                { habilitation: H_STATION_HYDRO_MODELS, link: 'iaeauModels' },
            ],
            installation: () => this.getInstallationsLinks(),
            units: () => {
                const isQualitoAssociated = this.props.associatedSites.some(site => site.stationLinkedType === 3)
                const qualityFeatures = isQualitoAssociated ? [
                    { habilitation: H_UNITS_GRAPHIC, link: 'graphic' },
                    { habilitation: H_UNITS_SUIVIPC, link: 'pcMonitoring' },
                    { habilitation: H_UNITS_CONFORMITY, link: 'conformityOverview' },
                ] : []
                const counters = this.props.match.params.type === 'distributionUnit' ? [
                    { habilitation: H_UNITS_COUNTERS, link: 'counters' },
                ] : []
                return [
                    { habilitation: H_UNITS_DASHBOARD, link: 'dashboard' },
                    { habilitation: H_UNITS_DESCRIPTION, link: 'description' },
                    { habilitation: H_UNITS_DASHBOARD, link: 'map' },
                    { habilitation: H_UNITS_EVENTS, link: 'event' },
                    { habilitation: H_UNITS_DOCUMENTS, link: 'picture' },
                    ...counters,
                    ...qualityFeatures,
                ]
            },
        })
            .filter(l => componentHasHabilitations(l.habilitation))
            .map(l => this.getLink(l.link))
            .filter(l => !!l)
        const types = this.state.stationTypes.length ? this.state.stationTypes : [this.props.match.params.type]
        const propsToPass = {
            id: this.props.match.params.id,
            key: this.props.match.params.id,
            stationTypes: [this.props.match.params.type],
            stationType: this.props.match.params.type,
            urlStationTypes: types,
            getLink: this.props.getLink,
            location: this.props.location,
            associatedSitesFetched: this.state.associatedSitesFetched,
            filesFetched: this.state.filesFetched,
            picturesFetched: this.state.picturesFetched,
            onReMount: this.onRemount,
        }

        const main = (getStation(this.props, this.props.match.params.type) || {}).id ? (
            <main>
                <div className='row no-margin'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/station/:type/:id' to='/station/:type/:id/dashboard' />
                                <Route path='/station/:type/:id/dashboard' render={(props) => <StationDashboardApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/map' render={(props) => <StationMapApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/description' render={(props) => <StationDescriptionApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/operation/:code' render={(props) => <StationOperationApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/iaeauModels/:modelId' render={(props) => <IAEauModelApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/iaeauModels' render={(props) => <IAEauModelsApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/operation' render={(props) => <OperationsListApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/develop' render={(props) => <DevelopmentApp {...props} {...propsToPass} />} />
                                <Route path='/station/quality/:id/suiviPC' render={(props) => <PcMonitoringApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/qualityStates' render={(props) => <QualitometerStatesApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/piezoSuiviTable' render={(props) => <PiezometerSuiviTableApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/piezometricFollowUp' render={(props) => <PiezoSuiviChart2 {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/pluviometricFollowUp' render={() => <PluvioSuiviGraphApp/>} />
                                <Route path='/station/:type/:id/pluviometricSuiviTable' render={(props) => <PluvioSuiviTableApp {...props} {...propsToPass} />} />
                                <Route path='/station/pluviometry/:id/validationPluvio' render={(props) => <PluviometryValidationApp {...props} {...propsToPass} />} />


                                <Route path='/station/:type/:id/hydrometricFollowUp' render={(props) => <HydrometerSuiviApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/hydroSuiviTable' render={(props) => <HydroSuiviTableApp {...props} {...propsToPass} />} />
                                <Route path='/station/hydrometry/:id/validationHydro' render={(props) => <HydrometryValidationApp {...props} {...propsToPass} />} />


                                <Route path='/station/:type/:id/observations' render={(props) => <HydrometerObservationsApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/installationFollowUp' render={(props) => <InstallationFollowUpApp {...props} {...propsToPass} />} />
                                {/* <Route path='/station/:type/:id/installationAEPIndicators' render={(props) => <InstallationAEPIndicatorsApp {...props} {...propsToPass} />} /> */}
                                <Route path='/station/:type/:id/picture' render={(props) => <PictureStationApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/associated' render={(props) => <StationAssociatedStationApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/event/:code' render={(props) => <StationEventTypeDispatcher {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/event' render={(props) => <StationEventsApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/materiel/:materialType' render={(props) => <SituationDetailApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/materiel' render={(props) => <MaterielStationApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/validation' render={(props) => <PiezometryValidationApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/STEPTerritory' render={(props) => <STEPTerritoryApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/STEPPollutionSearch' render={(props) => <STEPPollutionSearchApp {...props} {...propsToPass} />} />
                                <Route path='/station/:type/:id/export' render={(props) => <InstallationsExport {...props} {...propsToPass} />} />

                                <Route path='/station/quality/:idStation/hydrobioOperation/:idOperation' render={(props) => <HydrobioOperationApp {...props} {...propsToPass} />} />
                                <Route path='/station/quality/:idStation/hydrobioOperation' render={(props) => <HydrobioOperationsApp {...props} {...propsToPass} />} />
                                <Route path='/station/quality/:idStation/hydrobioMonitoring' render={(props) => <HydrobioMonitoringApp {...props} {...propsToPass} />} />

                                <Route path='/station/distributionUnit/:id/pcMonitoring' render={(props) => <DistributionUnitPcMonitoringApp {...props} {...propsToPass} />} />
                                <Route path='/station/distributionUnit/:id/conformityOverview' render={(props) => <DistributionUnitAEPApp {...props} {...propsToPass} />} />
                                <Route path='/station/distributionUnit/:id/graphic' render={(props) => <DistributionUnitAssociatedGraphic {...props} {...propsToPass} />} />
                                <Route path='/station/distributionUnit/:id/counters' render={(props) => <DistributionUnitCounterManager {...props} {...propsToPass} />} />

                                <Route path='/station/productionUnit/:id/pcMonitoring' render={(props) => <ProductionUnitPcMonitoringApp {...props} {...propsToPass} />} />
                                <Route path='/station/productionUnit/:id/conformityOverview' render={(props) => <ProductionUnitAEPApp {...props} {...propsToPass} />} />
                                <Route path='/station/productionUnit/:id/graphic' render={(props) => <ProductionUnitAssociatedGraphic {...props} {...propsToPass} />} />

                                <Route path='/station/installation/:id/pcMonitoring' render={(props) => <InstallationPcMonitoringApp {...props} {...propsToPass} />} />
                                <Route path='/station/installation/:id/conformityOverview' render={(props) => <InstallationAEPApp {...props} {...propsToPass} />} />
                            </Switch>
                        </BoundaryError>
                        {/* {this.props.children && React.cloneElement(this.props.children, {
                            id: this.props.match.params.id,
                            code: this.props.match.params.code,
                            materialType: this.props.match.params.materialType,
                            stationTypes: types,
                            urlStationTypes: types,
                            getLink: this.props.getLink,
                            key: this.props.match.params.id,
                            onReMount: this.onRemount,
                            location: this.props.location,
                            associatedSitesFetched: this.state.associatedSitesFetched
                        })} */}
                    </div>
                </div>
            </main>
        ) : null
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks} />
                {main}
            </div>
        )
    }
}

StationApp.propTypes = getPropTypes(propsToFetch, {
    qualitometer: PropTypes.instanceOf(DtoStation),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    productionUnit: PropTypes.instanceOf(DtoProductionUnit),
    distributionUnit: PropTypes.instanceOf(DtoDistributionUnit),
    installation: PropTypes.instanceOf(DtoInstallation),
    children: PropTypes.element,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        code: PropTypes.string,
        materialType: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.object,
    }),
    getLink: PropTypes.func,
    popup: PropTypes.element,
    associatedSites: PropTypes.instanceOf(DtoAssociatedStation),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    fetchAssociatedSites: PropTypes.func,
    fetchQualitometerPoints: PropTypes.func,
    fetchApplicationSettings: PropTypes.func,
    fetchDistributionUnitLink: PropTypes.func,
    resetDistributionUnitLink: PropTypes.func,
    fetchQualityIndicators: PropTypes.func,
    qualityIndicators: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityIndicators)),
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    qualitometer: store.QualityReducer.qualitometer,
    piezometer: store.StationReducer.piezometer,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometer: store.PluviometryReducer.pluviometer,
    hydrometricStation: store.HydrometryReducer.hydrometricStation,
    productionUnit: store.ProductionUnitReducer.productionUnit,
    distributionUnit: store.DistributionUnitReducer.distributionUnit,
    installation: store.InstallationReducer.installation,
    popup: store.StationReducer.popup,
    associatedSites: store.StationReducer.associatedSites,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    qualityIndicators: store.QualityReducer.qualityIndicators,
})

const mapDispatchToProps = {
    fetchMaterielTypes: MaterielAction.fetchMaterielTypes,
    fetchInstallationCentralAssignment: StationAction.fetchInstallationCentralAssignment,
    fetchInstallationPowerSupplyAssignment: StationAction.fetchInstallationPowerSupplyAssignment,
    fetchInstallationSensorAssignment: StationAction.fetchInstallationSensorAssignment,
    fetchInstallationSimAssignment: StationAction.fetchInstallationSimAssignment,
    fetchInstallationTelecomAssignment: StationAction.fetchInstallationTelecomAssignment,
    fetchInstallationVariousMaterielAssignment: StationAction.fetchInstallationVariousMaterielAssignment,
    fetchInstallationEquipmentAssignment: StationAction.fetchInstallationEquipmentAssignment,
    fetchQualityCampaigns: CampaignAction.fetchQualityCampaigns,
    fetchQualityStationCampaigns: CampaignAction.fetchQualityCampaignStations,
    fetchPiezometryCampaigns: CampaignAction.fetchPiezometryCampaigns,
    fetchPiezometryStationCampaigns: CampaignAction.fetchPiezometryCampaignStations,
    fetchPluviometryCampaigns: CampaignAction.fetchPluviometryCampaigns,
    fetchPluviometryStationCampaigns: CampaignAction.fetchPluviometryCampaignStations,
    fetchHydrometryCampaigns: CampaignAction.fetchHydrometryCampaigns,
    fetchHydrometryStationCampaigns: CampaignAction.fetchHydrometryCampaignStations,
    fetchInstallationCampaigns: CampaignAction.fetchInstallationCampaigns,
    fetchInstallationStationCampaigns: CampaignAction.fetchInstallationCampaignStations,
    fetchAllPiezometerActions: EventsAction.fetchAllPiezometerActions,
    fetchAssociatedSites: StationAction.fetchAssociatedSites,
    fetchQualitometerPoints: QualityAction.fetchQualitometerPoints,
    fetchPictures: StationAction.fetchPictures,
    fetchFiles: StationAction.fetchFiles,
    fetchEnvironmentModelsByType: ExportAction.fetchEnvironmentModelsByType,
    fetchExploitationsLight: AgriAction.fetchExploitationsLight,
    fetchPluviometerCentralAssignment: StationAction.fetchPluviometerCentralAssignment,
    fetchPluviometerPowerSupplyAssignment: StationAction.fetchPluviometerPowerSupplyAssignment,
    fetchPluviometerSensorAssignment: StationAction.fetchPluviometerSensorAssignment,
    fetchPluviometerSimAssignment: StationAction.fetchPluviometerSimAssignment,
    fetchPluviometerTelecomAssignment: StationAction.fetchPluviometerTelecomAssignment,
    fetchPluviometerVariousMaterielAssignment: StationAction.fetchPluviometerVariousMaterielAssignment,
    fetchPluviometerEquipmentAssignment: StationAction.fetchPluviometerEquipmentAssignment,
    fetchPluviometerSubscriptionAssignment: StationAction.fetchPluviometerSubscriptionAssignment,
    fetchHydrometryCentralAssignment: StationAction.fetchHydrometryCentralAssignment,
    fetchHydrometryPowerSupplyAssignment: StationAction.fetchHydrometryPowerSupplyAssignment,
    fetchHydrometrySensorAssignment: StationAction.fetchHydrometrySensorAssignment,
    fetchHydrometrySimAssignment: StationAction.fetchHydrometrySimAssignment,
    fetchHydrometryTelecomAssignment: StationAction.fetchHydrometryTelecomAssignment,
    fetchHydrometryVariousMaterielAssignment: StationAction.fetchHydrometryVariousMaterielAssignment,
    fetchHydrometryEquipmentAssignment: StationAction.fetchHydrometryEquipmentAssignment,
    fetchHydrometrySubscriptionAssignment: StationAction.fetchHydrometrySubscriptionAssignment,
    fetchApplicationSettings: AdministrationAction.fetchApplicationSettings,
    fetchDistributionUnitLink: DistributionUnitAction.fetchDistributionUnitLink,
    resetDistributionUnitLink: DistributionUnitActionConstant.resetDistributionUnitLink,
    fetchQualityIndicators: QualityAction.fetchQualityIndicators,
    fetchPiezometer: StationAction.fetchPiezometer,
    fetchPluviometer: PluviometryAction.fetchPluviometer,
    fetchHydrometricStation: HydrometryAction.fetchHydrometricStation,
    fetchProductionUnit: ProductionUnitAction.fetchProductionUnit,
    fetchProductionUnitEvents: ProductionUnitAction.fetchProductionUnitEvents,
    fetchDistributionUnit: DistributionUnitAction.fetchDistributionUnit,
    fetch: SieauAction.fetch,
    fetchQualitometer: QualityAction.fetchQualitometer,
    fetchInstallation: InstallationAction.fetchInstallation,

}

export default connect(mapStateToProps, mapDispatchToProps)(StationApp)
