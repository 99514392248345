import Card from 'components/card/Card'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import CultureAction from 'referencial/components/cultures/actions/CultureAction'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import DtoCultureVentilation from 'referencial/components/cultures/dto/DtoCultureVentilation'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import { getUser } from 'utils/SettingUtils'
import { getSandreLabel, searchAllCharacters } from 'utils/StringUtil'
import ActionComponent from '../../components/ActionComponent'
import Icon from '../../components/icon/Icon'
import HomeAction from '../../home/actions/HomeAction'
import { sieauTooltip } from '../../utils/FormUtils'
import CultureVentilationModal from './modal/CultureVentilationModal'
import { Button, Grid } from '@mui/material'
import { orderBy } from 'lodash'

const headers = ['culturesFamily', 'culture', 'nameUge', 'climateSector', 'year', 'groundType', 'mainIrrigationType', 'requirement',
    'lowWaterNeed', 'januaryShort', 'februaryShort', 'marchShort', 'aprilShort', 'mayShort', 'juneShort', 'julyShort',
    'augustShort', 'septemberShort', 'octoberShort', 'novemberShort', 'decemberShort', 'total', 'status']

const headersSearch = ['culturesFamily', 'culture', 'nameUge', 'climateSector', 'mainIrrigationType']

const emptyFilter = {
    searchValue: '',
    year: null,
}

class TerritorySettingsCulturesApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            readMode: true,
            openModal: false,
            cultureVentilationModal: null,
            culturesVentilations: [],
            newCulturesVentilations: [],
            culturesVentilationsToUpdate: [],
            culturesVentilationsToDelete: [],
            tmpFilter: emptyFilter,
            filter: emptyFilter,
            tempId: 1,
        }
    }

    componentDidMount() {
        this.props.setTitle([{
            title: i18n.steering,
            href: 'territory',
        }, {
            title: i18n.settings,
            href: 'territory/settings',
        }, {
            title: i18n.cultures,
            href: 'territory/settings/cultures',
        }])
        this.props.fetchCulturesVentilations().then(() => {
            const { culturesVentilations } = this.props
            this.setState({ dataLoaded: true, culturesVentilations })
            this.setReadOnlyMode()
        })
    }

    setReadOnlyMode = () => {
        this.setState({ readMode: true })
        if (!(getUser().consultant === '1')) {
            this.setActions({
                edit: () => this.setEditMode(),
            })
        } else {
            this.setActions({})
        }
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            cancel: () => this.onCancelAction(),
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => this.onSave()
            actions.new = () => this.setState({ openModal: true })
        }
        this.setActions(actions)
    }

    onSave = () => {
        const { newCulturesVentilations, culturesVentilationsToUpdate, culturesVentilationsToDelete } = this.state
        this.setState({ dataLoaded: false })
        this.props.deleteCulturesVentilations(culturesVentilationsToDelete).then(() => {
            this.props.updateCulturesVentilations(culturesVentilationsToUpdate).then(() => {
                this.props.createCulturesVentilations(newCulturesVentilations).then(() => {
                    this.props.fetchCulturesVentilations().then(() => {
                        const { culturesVentilations } = this.props
                        this.setState({
                            dataLoaded: true,
                            culturesVentilations,
                            culturesVentilationsToDelete: [],
                            culturesVentilationsToUpdate: [],
                            newCulturesVentilations: [],
                            tempId: 1,
                        })
                        this.props.info('Les demandes de volumes pour les années modifiées sont à recalculer depuis le tableau de bord de l\'enquête.')
                        this.setReadOnlyMode()
                    })
                })
            })
        })
    }

    onCancelAction = () => {
        const { culturesVentilations } = this.props
        this.setState({ culturesVentilations, newCulturesVentilations: [], culturesVentilationsToUpdate: [], culturesVentilationsToDelete: [], tempId: 1 })
        this.setReadOnlyMode()
    }

    getValue = (value) => {
        return hasValue(value) ? value : 0
    }

    formatPourcent = (value) => {
        return value ? `${value} %` : null
    }

    onDelete = (cV) => {
        const { culturesVentilations } = this.state
        if (cV.tempId) {
            this.setState(({ newCulturesVentilations }) => ({
                newCulturesVentilations: newCulturesVentilations.filter((c) => c.tempId !== cV.tempId),
            }))
        } else {
            this.setState(({ culturesVentilationsToDelete }) => ({
                culturesVentilations: culturesVentilations.filter((c) => c.id !== cV.id),
                culturesVentilationsToDelete: [ cV, ...culturesVentilationsToDelete],
            }))
        }
    }

    getTableData = () => {
        const { cultures, culturesFamilies, managementUnits, sandreCodes } = this.props
        const { culturesVentilations, newCulturesVentilations, culturesVentilationsToUpdate } = this.state
        const data = [...newCulturesVentilations, ...culturesVentilationsToUpdate, ...culturesVentilations].map((cV) => {
            const mainIrrigationType = getSandreLabel(sandreCodes, 'IRRIGATION.CATEGORIES', cV.mainIrrigationMode)
            const groundType = getSandreLabel(sandreCodes, 'USAGES.TYPES_SOLS', cV.groundType)
            const status = getSandreLabel(sandreCodes, 'USAGES.STATUT', cV.status)
            const culture = cultures.find((c) => c.id === cV.cultureId) || {}
            const cultureFamily = culturesFamilies.find((cF) => cF.id === culture.idFamille) || {}
            const uge = managementUnits.find((m) => m.managementCode === cV.managementCode) || {}
            const climateSector = getSandreLabel(sandreCodes, 'PREL_AGRI.SECTEUR_CLIMATIQUE', cV.climateSector)
            const total = this.getValue(cV.month01) + this.getValue(cV.month02) + this.getValue(cV.month03) + this.getValue(cV.month04) +
                        this.getValue(cV.month05) + this.getValue(cV.month06) + this.getValue(cV.month07) + this.getValue(cV.month08) +
                        this.getValue(cV.month09) + this.getValue(cV.month10) + this.getValue(cV.month11) + this.getValue(cV.month12)
            return {
                ...cV,
                culturesFamily: cultureFamily.name,
                culture: culture.name,
                nameUge: uge.name,
                climateSector,
                groundCriterias: cV.groundCriterias ? i18n.yes : i18n.no,
                mainIrrigationType,
                groundType,
                lowWaterNeed: cV.irrigationNeed,
                januaryShort: this.formatPourcent(cV.month01),
                februaryShort: this.formatPourcent(cV.month02),
                marchShort: this.formatPourcent(cV.month03),
                aprilShort: this.formatPourcent(cV.month04),
                mayShort: this.formatPourcent(cV.month05),
                juneShort: this.formatPourcent(cV.month06),
                julyShort: this.formatPourcent(cV.month07),
                augustShort: this.formatPourcent(cV.month08),
                septemberShort: this.formatPourcent(cV.month09),
                octoberShort: this.formatPourcent(cV.month10),
                novemberShort: this.formatPourcent(cV.month11),
                decemberShort: this.formatPourcent(cV.month12),
                total: this.formatPourcent(total),
                status,
                nullValue: (
                    <div {...sieauTooltip(i18n.delete, null, 'bottom')}>
                        <Icon
                            onClick={() => this.onDelete(cV)}
                        >
                            close
                        </Icon>
                    </div>
                ),
                nullValue2: (
                    <div {...sieauTooltip(i18n.edit, null, 'bottom')}>
                        <Icon
                            onClick={() => this.setState({
                                openModal: true,
                                cultureVentilationModal: cV,
                            })}
                        >
                            edit
                        </Icon>
                    </div>
                ),
                nullValue3: (
                    <div {...sieauTooltip(i18n.duplicate, null, 'bottom')}>
                        <Icon
                            onClick={() => this.setState(({ tempId }) => ({
                                openModal: true,
                                cultureVentilationModal: { ...cV, dateMaj: null, userMaj: null, year: null, id: null, tempId },
                                tempId: tempId + 1,
                            }))}
                        >
                            control_point_duplicate
                        </Icon>
                    </div>
                ),
            }
        })

        return orderBy(this.getFilteredData(data), ['year', 'culturesFamily', 'culture', 'nameUge', 'climateSector'], ['desc', 'asc'])
    }

    onValidate = (cultureVentilation) => {
        const { newCulturesVentilations, culturesVentilations, culturesVentilationsToUpdate, tempId } = this.state
        if (cultureVentilation.id) {
            this.setState({
                openModal: false,
                cultureVentilationModal: null,
                culturesVentilations: culturesVentilations.filter((c) => c.id !== cultureVentilation.id),
                culturesVentilationsToUpdate: [ cultureVentilation, ...culturesVentilationsToUpdate],
            })
        } else if (cultureVentilation.tempId) {
            this.setState({
                openModal: false,
                cultureVentilationModal: null,
                newCulturesVentilations: [ cultureVentilation, ...newCulturesVentilations.filter((c) => c.tempId !== cultureVentilation.tempId)],
            })
        } else {
            this.setState({
                openModal: false,
                cultureVentilationModal: null,
                newCulturesVentilations: [ { ...cultureVentilation, tempId }, ...newCulturesVentilations],
                tempId: tempId + 1,
            })
        }
    }

    getFilteredData = (data) => {
        const { filter } = this.state
        const { searchValue, year } = filter
        const yearFilteredData = year ? data.filter((cV) => cV.year === year) : data
        const includesValue = searchAllCharacters(searchValue || '')
        return yearFilteredData.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (cultureVentilation) => {
        return searchAllCharacters(headersSearch.map(key => cultureVentilation[key]))
    }

    resetFilters = () => {
        this.setState({ tmpFilter: emptyFilter, filter: emptyFilter })
        this.onValidateFilter(emptyFilter)
    }

    onValidateFilter = (filter) => {
        this.setState({ filter })
    }

    render() {
        const { dataLoaded, openModal, cultureVentilationModal, tmpFilter, readMode } = this.state
        const { searchValue, year } = tmpFilter

        return (
            <div className='row no-margin padding-1 padding-left-0'>
                <Card round>
                    <div className='row padding-top-1 no-margin'>
                        <Input
                            col={2}
                            title={ i18n.search }
                            value={ searchValue }
                            onChange={(v) => this.setState({ tmpFilter: { ...tmpFilter, searchValue: v } })}
                            onEnterKeyPress={() => this.onValidateFilter(tmpFilter)}
                        />
                        <NumberField
                            col={2}
                            title={ i18n.year }
                            value={ year }
                            onChange={(v) => this.setState({ tmpFilter: { ...tmpFilter, year: v } })}
                            onEnterKeyPress={() => this.onValidateFilter(tmpFilter)}
                        />
                        <Grid className='padding-bottom-1 padding-right-1' container justifyContent='flex-end' alignItems='flex-end'>
                            <Grid item>
                                <Button variant='outlined' onClick={this.resetFilters}>
                                    {i18n.reinit}
                                </Button>
                            </Grid>
                            <Grid item className='padding-left-1'>
                                <Button variant='contained' onClick={() => this.onValidateFilter(tmpFilter)}>
                                    {i18n.search}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
                {dataLoaded ? (
                    <div className='margin-top-1'>
                        <Table
                            title={i18n.culturesSettings}
                            data={this.getTableData()}
                            nbPerPageLabel={nbPerPageLabel}
                            type={{ headers: readMode ? headers : ['nullValue', 'nullValue2', 'nullValue3', ...headers] }}
                            condensed
                            sortable
                            paging
                            customHeaders={{
                                culturesFamily: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.culturesFamilyWrap}</span>),
                                climateSector: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.climateSectorWrap}</span>),
                                mainIrrigationType: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.mainIrrigationTypeWrap}</span>),
                                groundType: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.groundTypeWrap}</span>),
                                lowWaterNeed: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.lowWaterNeedWrap}</span>),
                                requirement: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.maxGlobalNeedWrap}</span>),
                            }}
                            round
                        />
                    </div>
                ) : <ProgressBar indeterminate />}
                {openModal && (
                    <CultureVentilationModal
                        open={openModal}
                        cultureVentilation={cultureVentilationModal || new DtoCultureVentilation({ status: 1 })}
                        onCancel={() => this.setState({ openModal: false, cultureVentilationModal: null })}
                        onValidate={this.onValidate}
                    />
                )}
            </div>
        )
    }
}

TerritorySettingsCulturesApp.propTypes = {
    culturesVentilations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCultureVentilation)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    push: PropTypes.func,
    info: PropTypes.func,
    setTitle: PropTypes.func,
    fetchCulturesVentilations: PropTypes.func,
    createCulturesVentilations: PropTypes.func,
}

const mapStateToProps = store => ({
    culturesVentilations: store.CultureReducer.culturesVentilations,
    cultures: store.CultureReducer.cultures,
    culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    push,
    setTitle: HomeAction.setTitle,
    info: ToastrAction.info,
    fetchCulturesVentilations: CultureAction.fetchCulturesVentilations,
    createCulturesVentilations: CultureAction.createCulturesVentilations,
    updateCulturesVentilations: CultureAction.updateCulturesVentilations,
    deleteCulturesVentilations: CultureAction.deleteCulturesVentilations,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritorySettingsCulturesApp)
