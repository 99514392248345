import React from 'react'
import PropTypes from 'prop-types'
import ActionComponent from '../../../../components/ActionComponent'
import { connect } from 'react-redux'
import AppStore from 'store/AppStore'
import ApplicationConf from 'conf/ApplicationConf'
import FractionAction from '../actions/FractionAction'
import ReferencialAction from '../../../action/ReferencialAction'
import DtoReferencial from '../../../dto/DtoReferencial'
import DtoReferencialItem from '../../../dto/DtoReferencialItem'
import i18n from 'simple-react-i18n'
import FractionDto from '../dto/FractionDto'
import Checkbox from '../../../../components/forms/Checkbox'
import moment from 'moment'
import 'moment/locale/fr'
import Select from '../../../../components/forms/Select'
import SieauAction from '../../../../components/sieau/SieauAction'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import { getReferencialComponent } from '../../../util/ReferencialUtils'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import ReplaceModal from '../../ReplaceModal'
import { isEqual, omit } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_FRACTION,
    PATH_REFERENCIAL_FRACTION_LINK,
    PATH_REFERENCIAL_FRACTION_NEW,
} from '../../../../home/constants/RouteConstants'
import { push } from 'connected-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import DeleteModal from '../../DeleteModal'
import { sandreFractionLink } from '../../../../conf/SieauConstants'
import { getUser } from '../../../../utils/SettingUtils'
import HomeAction from 'home/actions/HomeAction'
import logoSandre from 'assets/pictures/logo_sandre.png'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { hasValue } from 'utils/NumberUtil'

moment.locale('fr')

class FractionApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            fraction: new FractionDto({}),
        }
    }

    setReadMode = () => {
        this.setState({ readMode: true })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        AppStore.dispatch(FractionAction.reset())
    }

    componentWillMount() {
        if (this.props.match.params.code !== 'new') {
            AppStore.dispatch(FractionAction.fetchFraction(this.props.match.params.code))
            this.setReadMode()
        } else {
            this.setEditMode()
        }
        AppStore.dispatch(ReferencialAction.fetchReferencialStatus())
        AppStore.dispatch(ReferencialAction.fetchReferencialSupport())
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarFraction(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.code !== this.props.match.params.code) {
            AppStore.dispatch(FractionAction.fetchFraction(nextProps.match.params.code))
        } else if (nextProps.match.params.code === 'new') {
            AppStore.dispatch(SieauAction.forceFetch('title', [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.fractions,
                    href: PATH_REFERENCIAL_FRACTION,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_FRACTION_NEW,
                },
            ]))
        } else {
            if (!isEqual(nextProps.fraction, this.props.fraction)) {
                AppStore.dispatch(SieauAction.forceFetch('title', [
                    {
                        title: i18n.referencials,
                        href: PATH_REFERENCIAL,
                    },
                    {
                        title: i18n.fractions,
                        href: PATH_REFERENCIAL_FRACTION,
                    },
                    {
                        title: this.props.match.params.code + (nextProps.fraction.name ? ` - ${nextProps.fraction.name}` : ''),
                        href: PATH_REFERENCIAL_FRACTION_LINK + this.props.match.params.code,
                    },
                ]))
                this.setState({ fraction: nextProps.fraction })
            }
            if (!isEqual(this.props.isEditMode, nextProps.isEditMode) || !isEqual(this.props.fraction.code, nextProps.fraction.code) || !isEqual(this.props.fractions, nextProps.fractions)) {
                this.toNavbarFraction(nextProps)
            }
        }
    }

    onReplace = newCode => {
        AppStore.dispatch(FractionAction.replaceFraction(this.state.fraction.code, newCode.code))
    }

    getRequiredFunction = (actions) => {
        AppStore.dispatch(actions)
        this.setReadMode()
    }

    getFractionLinks = props => {
        return [
            {
                href: sandreFractionLink + props.fraction.code,
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            },
        ]
    }

    toNavbarFraction(props = this.props) {
        const { fraction } = this.state
        const state = this.props.referencialStatus.find(status => status.code === parseInt(fraction.state))?.name
        const actions = (() => {
            if (props.match.params.code === 'new' && (!props.fraction || !props.fraction.code)) {
                return {
                    save: () => {
                        const existCode = this.props.fractions.find(f => f.id === fraction.code)
                        if (existCode) {
                            AppStore.dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                        } else if (!fraction.code) {
                            AppStore.dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                        } else {
                            this.getRequiredFunction(FractionAction.createFraction({
                                ...fraction,
                                state,
                            }), props)
                        }
                    },
                    cancel: () => {
                        AppStore.dispatch(push(PATH_REFERENCIAL_FRACTION))
                        this.setReadMode()
                    },
                }
            }
            if (!this.state.readMode) {
                return {
                    save: () => {
                        this.getRequiredFunction(FractionAction.updateFraction({
                            ...fraction,
                            state,
                        }, fraction.code), props)
                    },
                    cancel: () => {
                        this.setState({ fraction: props.fraction })
                        this.setReadMode()
                    },
                }
            }
            return {
                edit: () => {
                    this.setEditMode()
                },
                deleteCheck: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.fractionCountDelete(this.props.match.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <DeleteModal
                            onDelete={ () => AppStore.dispatch(FractionAction.deleteFraction(fraction.code)) }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                replace: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.fractionCountDelete(this.props.match.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <ReplaceModal
                            elements={ props.fractions }
                            title={ `${i18n.fraction} [${fraction.code}]` }
                            label={ i18n.fraction }
                            onReplace={ this.onReplace }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                links: this.getFractionLinks(props),
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidUpdate() {
        this.toNavbarFraction()
    }

    componentDidMount() {
        this.toNavbarFraction()
    }

    render() {
        const { readMode, fraction } = this.state
        const { match, referencialStatus, referencialSupport } = this.props

        const disabled = {
            active: !readMode,
            disabled: readMode,
        }

        const nature = [
            { id: '0', name: i18n.unknownNature },
            { id: '1', name: i18n.liquidFraction },
            { id: '2', name: i18n.solidFraction },
            { id: '3', name: i18n.gaseousFraction },
        ]

        const activeIcon = fraction.state === '3' ? <i className='material-icons'>lock</i> : null

        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s12 m3 l3' />
                                <div className='col s12 m6 l6'>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 3 }
                                            title={ i18n.code }
                                            value={ fraction.code }
                                            onChange={ code => this.setState({ fraction: { ...fraction, code } }) }
                                            maxLength={ 5 }
                                            disabled={match.params.code !== 'new'}
                                        />
                                        <Checkbox
                                            col={ 3 }
                                            label={ i18n.active }
                                            checked={ fraction.actif === '1' }
                                            onChange={ (e) => this.setState({ fraction: { ...fraction, actif: e ? '1' : '0' } }) }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            id='fraction_name'
                                            col={ 6 }
                                            title={ i18n.name }
                                            value={ fraction.name }
                                            onChange={ name => this.setState({ fraction: { ...fraction, name } }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 6 }
                                            title={ i18n.internationalName }
                                            value={ fraction.internationalName }
                                            maxLength={ 255 }
                                            onChange={ internationalName => this.setState({ fraction: { ...fraction, internationalName } }) }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.comment }
                                            value={ fraction.comment }
                                            onChange={ comment => this.setState({ fraction: { ...fraction, comment } }) }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-7-px'>
                                        <Select
                                            options={ referencialStatus }
                                            label={ i18n.status }
                                            col={ 3 }
                                            onChange={ state => this.setState({ fraction: { ...fraction, state: hasValue(state) ? state.toString() : '' } }) }
                                            value={ parseInt(fraction.state) }
                                            nullLabel='&nbsp;'
                                            { ...disabled }
                                        />
                                        <div className='col s1 padding-top-10-px'>
                                            { activeIcon }
                                        </div>
                                        <Input
                                            col={ 8 }
                                            title={ i18n.author }
                                            value={ fraction.author }
                                            onChange={ author => this.setState({ fraction: { ...fraction, author } }) }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <SimpleDatePicker
                                            col={6}
                                            id='creationDate'
                                            label={i18n.creationDate}
                                            value={fraction.creationDate}
                                            max={fraction.updateDate}
                                            onChange={creationDate => this.setState({ fraction: { ...fraction, creationDate } })}
                                            { ...disabled }
                                        />
                                        <SimpleDatePicker
                                            col={6}
                                            id='updateDate'
                                            label={i18n.modification}
                                            value={fraction.updateDate}
                                            onChange={updateDate => this.setState({ fraction: { ...fraction, updateDate } })}
                                            min={fraction.creationDate}
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-7-px'>
                                        <Select
                                            options={ getReferencialComponent(nature) }
                                            label={ i18n.nature }
                                            col={ 6 }
                                            nullLabel='&nbsp;'
                                            onChange={ v => this.setState({ fraction: { ...fraction, nature: v } }) }
                                            value={ fraction.nature }
                                            { ...disabled }
                                        />
                                        <Select
                                            options={ getReferencialComponent(referencialSupport) }
                                            label={ i18n.support }
                                            col={ 6 }
                                            nullLabel='&nbsp;'
                                            onChange={ supportCode => this.setState({ fraction: { ...fraction, supportCode } }) }
                                            value={ fraction.supportCode }
                                            { ...disabled }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FractionApp.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    fraction: PropTypes.instanceOf(FractionDto),
    referencialStatus: PropTypes.instanceOf(DtoReferencial),
    referencialSupport: PropTypes.instanceOf(DtoReferencialItem),
    getLink: PropTypes.func,
    isEnableEdit: PropTypes.bool,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => {
    return {
        fraction: store.FractionReducer.fraction,
        fractions: store.FractionReducer.fractions,
        referencialStatus: store.ReferencialReducer.referencialStatus,
        referencialSupport: store.ReferencialReducer.referencialSupport,
    }
}

export default connect(mapStateToProps)(FractionApp)
