/* eslint-disable camelcase */
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { getFullDate } from '../../../utils/DateUtil'
import { getExport, setModal } from '../../../utils/linkUtils'
import { getLabel } from '../../../utils/StoreUtils'
import NetworkModal from './modals/NetworkModal'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import SieauAction from 'components/sieau/SieauAction'

const StationNetworkPanel = ({
    station = {},
    readMode = false,
    onChange = () => {},
}) => {
    const dispatch = useDispatch()
    const {
        networks,
    } = useSelector(store => ({
        networks: store.NetworkReducer.networks,
    }))

    useEffect(() => {
        if (!networks.length) {
            dispatch(NetworkAction.fetchNetworks())
        }
    }, [])

    const stationNetworks = station.link_networks.map((n, index) => ({
        ...n,
        index,
    }))

    const tableData = stationNetworks.map(n => {
        return {
            ...n,
            sandreCode: (networks.find(ns => ns.code === n.idNetwork) || {}).sandreCode,
            startDate: getFullDate(n.startDate),
            endDate: getFullDate(n.endDate),
            network: getLabel(networks, n.idNetwork),
        }
    })

    const headers = ['startDate', 'endDate', 'network', 'sandreCode']
    const exportAction = getExport(tableData, i18n.networks, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newNetwork, <NetworkModal id={station.id} saveResult={r => onChange({ link_networks: [...stationNetworks, r] })} />, v => dispatch(SieauAction.setPopup(v))),
    }, exportAction] : [exportAction]

    return (
        <div id = 'networks'>
            <Table
                title={ i18n.networks }
                condensed
                data={ tableData }
                type={{ headers }}
                sortable={ !!tableData.length }
                actions={ actions }
                alterable={ !readMode }
                onAlter={
                    element => setModal(i18n.networks,
                        <NetworkModal
                            id={ station.id }
                            network={ stationNetworks.find(sn => element.index === sn.index) }
                            saveResult={ r => onChange({
                                link_networks: orderBy([
                                    ...stationNetworks.filter(sn => sn.index !== element.index),
                                    { ...r, index: element.index },
                                ], 'index'),
                            })}
                        />
                        , v => dispatch(SieauAction.setPopup(v)))
                }
                deletable={ !readMode }
                onDelete={ (element) => onChange({ link_networks: stationNetworks.filter(sn => sn.index !== element.index) }) }
            />
        </div>
    )
}

StationNetworkPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

export default StationNetworkPanel