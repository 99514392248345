import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Card from './Card'

class MessageCard extends Component {
    render() {
        return (
            <Card className={ this.props.className ? this.props.className : 'padding-top-1 padding-bottom-1 padding-left-1' }>
                <h5>{ this.props.children }</h5>
            </Card>
        )
    }
}

MessageCard.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
}

export default MessageCard

