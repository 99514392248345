/* eslint-disable consistent-return */
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { isUndefined, max, maxBy, min, minBy, round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import { getDate, getFullDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import {
    exportExcelIcon,
    exportPictureIcon, getAxisLabelInterval, logIcon, setLogOptions, setYOptions, toEchartsData, yAutomaticScaleValues,
} from './EChartUtils'

const DEFAULT_GRAPH_HEIGHT = 150
const DEFAULT_GAP = 50
const DEFAULT_HEADER_HEIGHT = 40
const DEFAULT_FOOTER_HEIGHT = 75

const HydroChart = ({
    data = [],
    grids = [],

    headerHeight = DEFAULT_HEADER_HEIGHT,
    footerHeight = DEFAULT_FOOTER_HEIGHT,
}) => {
    // use on mouse event
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)

    const [displayLog, setDisplayLog] = useState(false)

    const minDate = min(data.map(d => minBy(d.measures, 'date')?.date).filter(d => !isUndefined(d)))
    const maxDate = max(data.map(d => maxBy(d.measures, 'date')?.date).filter(d => !isUndefined(d)))


    const {
        formatter,
        interval,
    } = getAxisLabelInterval(moment(maxDate), moment(minDate))

    let tmpGap = headerHeight
    const grid = grids.map(({ gridOptions: { top = DEFAULT_GAP, height = DEFAULT_GRAPH_HEIGHT, right = '40px', left = '70px' } = {} }) => {
        const tmpTop = tmpGap + top
        const tmpGrid = {
            top: tmpTop,
            height,
            left,
            right,
        }
        tmpGap = tmpTop + height
        return tmpGrid
    })

    const xAxis = grids.map(({ xOptions = {} }, index) => {
        const {
            type = 'time',
            boundaryGap = true,
            axisLabel = {},
        } = xOptions
        return {
            type,
            boundaryGap,
            axisLabel: {
                rotate: axisLabel.rotate || 0,
                formatter,
                show: true,
            },
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: { show: true },
            interval,
            gridIndex: index,
            min: minDate,
            max: maxDate,
        }
    })


    const yAxis = grids.map(({ name/*, yOptions = {}*/ }, index) => {
        // const {
        //     type,
        //     nameLocation,
        //     nameGap,
        //     boundaryGap,
        // } = yOptions
        const filteredData = data.filter(d => d.gridIndex === index)
        const allMeasures = filteredData.flatMap(d => d.measures?.map(m => m.value) ?? [])
        const allThresholds = filteredData.flatMap(d => d.thresholds?.map(t => t.value) ?? [])
        const yScale = yAutomaticScaleValues([...allMeasures, ...allThresholds])
        return {
            type: displayLog ? 'log' : 'value',
            nameLocation: 'middle',
            name,
            gridIndex: index,
            nameGap: 40,
            showSplitLine: true,
            ...setYOptions(null, yScale),
            ...setLogOptions(displayLog),
        }
    })


    const series = data.map(({ id, name = '', color, measures = [], thresholds = [], gridIndex = 0, lineStyle = {} }, i) => {
        const filteredMeasures = displayLog ? measures.filter(d => d.value > 0) : measures
        return {
            type: 'line',
            data: toEchartsData(filteredMeasures),
            name,
            connectNulls: false,
            color,
            yAxisIndex: gridIndex,
            xAxisIndex: gridIndex,
            serieId: id ?? i,
            markLine: {
                silent: false,
                data: thresholds.map(t => ({
                    yAxis: t.value,
                    symbol: 'none',
                    label: {
                        show: true,
                        position: 'middle',
                        formatter: () => t.title ? `${t.title} : ${round(t.value, 5)}` : '',
                    },
                    lineStyle: {
                        normal: {
                            color: t.color,
                            type: 'dashed',
                        },
                    },
                })),
            },
            lineStyle: {
                type: lineStyle.type ?? 'solid',
                opacity: lineStyle.opacity ?? 1,
                width: lineStyle.width ?? 2,
            },
        }
    })

    const getLegend = () => ({
        top: 10,
        left: 70,
        right: displayToolbox.current ? `${30 * 4 + 35}px` : 40,
        type: 'scroll',
        show: true,
    })

    const getToolbox = () => ({
        show: displayToolbox.current,
        top: 5,
        right: 40,
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            myLog: {
                show: true,
                title: i18n.logarithm,
                icon: logIcon,
                onclick: () => setDisplayLog(p => !p),
            },
            saveAsImage: {
                show: true,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: i18n.overview,
            },
            myToolExport: {
                show: true,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    const exportData = data.flatMap(({ name, measures, unit }) => {
                        return measures.map(d => ({
                            date: { value: getDate(d.date), format: 'dd/MM/yyyy', cellType: 'date' },
                            value: { value: d.value, format: '0.00000', cellType: 'number' },
                            name,
                            unit,
                        }))
                    })
                    exportFile({
                        data: exportData.length ? [
                            {
                                ...exportData[0],
                                headers: ['name', 'date', 'value', 'unit'],
                            },
                            ...exportData.slice(1),
                        ] : [],
                        exportType: 'xlsx',
                        titleFile: i18n.overview,
                    }, true)
                },
            },
            restore: { title: i18n.restore },
        },
    })

    const options = {
        series,
        legend: getLegend(),
        xAxis,
        yAxis,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = getFullDate(params[0].value[0])
                const result = params.map(o => `${o.marker} ${o.seriesName} : ${round(o.value[1], 2)} ${o.unit ?? ''}`).join('<br />')
                return `${date} <br /> ${result}`
            },
        },
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        grid,
        dataZoom: [{
            type: 'slider',
            xAxisIndex: grids.map((_, index) => index),
            filterMode: 'none',
        }, {
            bottom: '10px',
            type: 'inside',
            filterMode: 'filter',
            xAxisIndex: grids.map((_, index) => index),
            handleStyle: {
                color: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
            },
        }],
        toolbox: getToolbox(),
        backgroundColor: '#fff',
    }

    const componentHeight = grids.reduce((acc, { gridOptions: { height = DEFAULT_GRAPH_HEIGHT, top = DEFAULT_GAP } = {} }) => acc + height + top, headerHeight + footerHeight)

    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                className={'row no-margin'}
                style={{ height: componentHeight }}
                ref={e => {
                    echartRef.current = e
                }}
            />
        </div>
    )
}

HydroChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        color: PropTypes.string,
        measures: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number,
            date: PropTypes.number,
        })),
        thresholds: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.number,
            color: PropTypes.string,
        })),
        gridIndex: PropTypes.number,
        lineStyle: PropTypes.shape({

        }),
    })).isRequired,
    grids: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        gridOptions: PropTypes.shape({
            top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        xOptions: PropTypes.shape({
            type: PropTypes.string,
            boundaryGap: PropTypes.bool,
            showSplitLine: PropTypes.bool,
        }),
        yOptions: PropTypes.shape({
            type: PropTypes.string,
            nameLocation: PropTypes.string,
            nameGap: PropTypes.number,
            boundaryGap: PropTypes.bool,
        }),
    })).isRequired,

    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
}

export default HydroChart