import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import QualityGraphicApp from 'quality/components/graphic/QualityGraphicApp'

const DistributionUnitAssociatedGraphic = ({
    id,
}) => {
    const {
        distributionUnit,
        associatedSites,
    } = useSelector(store => ({
        distributionUnit: store.DistributionUnitReducer.distributionUnit,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.distributionUnit,
        href: 'distributionUnit',
    }, {
        title: getStationTitle(distributionUnit),
        href: `station/distributionUnit/${id}`,
    }, {
        title: i18n.graphics,
        href: `station/distributionUnit/${id}/graphic`,
    }], [distributionUnit])

    const defaultStations = useMemo(() => {
        return associatedSites.filter(as => as.stationLinkedType === 3).map(as => as.stationLinkedId)
    }, [associatedSites])

    return (
        <QualityGraphicApp
            defaultStations={defaultStations}
            hideStationsCriteria
        />
    )
}

DistributionUnitAssociatedGraphic.propTypes = {
    id: PropTypes.string,
}

export default DistributionUnitAssociatedGraphic