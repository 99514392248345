import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import UnitDto from '../../../../../referencial/components/unit/dto/UnitDto'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import AppStore from '../../../../../store/AppStore'
import { checkMandatoryFields } from '../../../../../utils/FormUtils'
import { hasValue } from '../../../../../utils/NumberUtil'
import { removeNullKeys } from '../../../../../utils/StoreUtils'
import OperationAction from '../../actions/OperationAction'
import DtoIndice from '../../dto/DtoIndice'
import { orderBy } from 'lodash'
import DtoRemark from '../../dto/DtoRemark'

class OperationIndexModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: {},
        }
    }

    componentWillMount() {
        this.setState({
            index: this.props.index,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.popupIsOpen && nextProps.popupIsOpen) {
            $('#saveIndex').on('click', this.onSave)
        }
    }

    onSave = () => checkMandatoryFields([{ field: 'parameter', i18n: 'parameter' }, { field: 'result', i18n: 'result' }], this.state.index, () => {
        AppStore.dispatch(OperationAction.updateOperationIndex(
            { ...this.state.index, parameter: parseInt(this.state.index.parameter) },
            this.props.isNew ? 'POST' : 'PUT',
            () => $('.modal').modal('close')
        ))
    })

    onChangeIndex = (changes) => this.setState({ index: removeNullKeys({ ...this.state.index, ...changes }) })

    render() {
        const {
            index,
        } = this.state
        const {
            remarks,
            popupIsOpen,
            parameters,
            units,
            isNew,
        } = this.props
        return !!popupIsOpen && (
            <div>
                <div className='row no-margin padding-top-1'>
                    <NumberField
                        col={4}
                        value={index.result}
                        title={i18n.result}
                        onChange={v => this.onChangeIndex({ result: v })}
                        floatValue
                    />
                    <Select
                        col={4}
                        value={index.parameter}
                        options={parameters}
                        label={i18n.parameter}
                        onChange={v => this.onChangeIndex({ parameter: v })}
                        disabled={!isNew}
                        keyValue='code'
                        keyLabel='labelWithCode'
                    />
                    <Select
                        col={4}
                        value={index.unit}
                        options={units}
                        label={i18n.unit}
                        onChange={v => this.onChangeIndex({ unit: v })}
                        disabled={!isNew}
                        keyLabel='symbolWithName'
                    />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <Select
                        col={4}
                        value={index.remarkCode}
                        label={i18n.remarkCode}
                        options={orderBy(remarks, 'code')}
                        onChange={v => this.onChangeIndex({ remarkCode: hasValue(v) ? v : null })}
                        keyValue='id'
                        floatValue
                        noSort
                    />
                    <Textarea
                        col={6}
                        value={index.comment}
                        title={i18n.comment}
                        onChange={v => this.onChangeIndex({ comment: v })}
                    />
                    <Checkbox
                        col={2}
                        checked={index.accreditation === 1}
                        label={i18n.accreditation}
                        onChange={v => this.onChangeIndex({ accreditation: v ? 1 : 0 })}
                    />
                </div>
            </div>
        )
    }
}

OperationIndexModal.propTypes = {
    popupIsOpen: PropTypes.bool,
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    index: PropTypes.instanceOf(DtoIndice),
    remarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoRemark)),
    isNew: PropTypes.bool,
}

const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    parameters: store.ParameterReducer.parameters,
    units: store.UnitReducer.units,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    remarks: store.OperationReducer.remarks,
})

export default connect(mapStateToProps)(OperationIndexModal)