import { push } from 'connected-react-router'
import { isEqual, minBy, orderBy, take, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import FormQualityCampaign from '../../../campaign/components/campaignDashboard/quality/FormQualityCampaign'
import SteperPopupEDILABO from '../../../campaign/components/campaignDashboard/quality/stepper/SteperPopupEDILABO'
import TabPanel from '../../../campaign/components/campaignDashboard/quality/TabPanel'
import { STATION_PANEL } from '../../../campaign/constants/CampaignConstants'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import CampaignPlanningDto from '../../../campaign/dto/CampaignPlanningDto'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import DtoParameterCampaign from '../../../campaign/dto/DtoParameterCampaign'
import DtoStationCampaign from '../../../campaign/dto/DtoStationCampaign'
import EDILABOFileDto from '../../../campaign/dto/EDILABOFileDto'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import LastUpdateCard from '../../../components/card/LastUpdateCard'
import ProgressCircleChart from '../../../components/progress/ProgressCircleChart'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoEvent from '../../../events/dto/DtoEvent'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import FractionDto from '../../../referencial/components/fraction/dto/FractionDto'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import SupportDto from '../../../referencial/components/support/dto/SupportDto'
import UnitDto from '../../../referencial/components/unit/dto/UnitDto'
import { getParametersNature, getParametersType } from '../../../referencial/util/ReferencialUtils'
import { getStatusInformationExport } from '../../../referencial/util/StatusUtils'
import { getDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { getPercentage, hasValue } from '../../../utils/NumberUtil'
import { getUser } from '../../../utils/SettingUtils'
import { getLabel } from '../../../utils/StoreUtils'
import DtoQualitometerLight from '../../dto/DtoQualitometerLight'
import { placeOptions } from '../operation/constants/OperationDescriptionConstants'
import PopupEdilaboRAI from '../qualityComponents/PopupEdilaboRAI'
import ImportCampagnePopup from './ImportCampagnePopup'
import { isAuthorized } from 'utils/HabilitationUtil'
import { H_QUALITY_EDILABO } from 'account/constants/AccessRulesConstants'


class QualityCampaign extends ActionComponent {
    state = {
        view: STATION_PANEL,
        campaign: {},
        selectedStations: [],
        selectedParameters: [],
        plannings: [],
        editMode: false,
        isOpenPopupEdilaboDAI: false,
        isOpenPopupEdilaboRAI: false,
        isPopupImportOpen: false,
    }

    componentDidMount = () => {
        if (!(this.props.params.id === 'new') && !(this.props.params.id === 'duplicate')) {
            this.setReadMode()
        } else {
            this.setEditMode()
        }
        this.setTitle()
    }

    setTitle = () => {
        const { campaign } = this.props
        const { params } = this.props
        this.props.forceFetch('title', [
            {
                title: i18n.quality,
                href: '/quality',
            },
            {
                title: i18n.campaigns,
                href: '/quality/campaign',
            },
            (() => {
                switch (params.id) {
                    case 'new':
                        return {
                            title: i18n.new,
                            href: '/quality/campaign/new/dashboard',
                        }
                    case 'duplicate':
                        return {
                            title: i18n.duplicate,
                            href: '/quality/campaign/duplicate/dashboard',
                        }
                    default:
                        return {
                            title: campaign.name || params.id,
                            href: `/quality/campaign/${params.id}/dashboard`,
                        }
                }
            })(),
        ])
    }

    onExportStations = () => {
        const stations = this.getStationsToMonitor(this.props.stations)
        const data = this.state.selectedStations.map((s, i) => {
            const station = stations.find(p => p.id === s.id) || {}
            const accessibilities = this.props.accessibilities.filter(access => access.idPiezo === station.id)
            const access = accessibilities.length && accessibilities.find(a => hasValue(a.order)) ? minBy(accessibilities, 'order') : (accessibilities.length ? accessibilities[0] : null)
            const contact = this.props.contacts.find(c => c.id === (access && access.contactCode ? access.contactCode : station.contactCode))
            const obj = {
                ...s,
                code: station.code,
                name: station.name,
                cityCode: { value: station.townCode, cellType: 'right' },
                city: getLabel(this.props.cities, station.townCode),
                monitoredStation: station.monitoringEvents.map(e => e.comment || e.problem || '').join(', '),
                access: orderBy(accessibilities, 'order').map(a => a.description).join(', '),
                contact: contact ? [contact.name || '', contact.address, contact.postalCode, getLabel(this.props.cities, contact.cityCode), contact.phoneTel || contact.mobile].filter(str => !!str).join(', ') : '',
                toPlan: station.toPlanEvents.map(e => e.comment || e.problem || '').join(', '),
            }
            if (i === 0) {
                return {
                    ...obj,
                    headers: ['code', 'cityCode', 'city', 'name', 'contact', 'access', 'monitoredStation', 'toPlan'],
                }
            }
            return obj
        })
        exportFile({
            data,
            exportType: 'xlsx',
            titleFile: `${i18n.campaigns}_${i18n.quality}_${i18n.stations}_${this.state.campaign.name || ''}`,
        })
    }

    onExportParameters = () => {
        const {
            units,
            fractions,
            supports,
            parameters,
        } = this.props

        const types = getParametersType()
        const natures = getParametersNature()

        const data = this.state.selectedParameters.map(({ parameterCode, unitCode, fractionCode, supportCode, placeCode }, index) => {
            const {
                name,
                typeParam,
                nature,
            } = parameters.find(p => p.code === parameterCode) || {}
            const place = placeOptions.find(p => p.value === placeCode)
            const unit = units.find(u => u.id === unitCode)
            const obj = {
                name: name && `[${parameterCode}] ${name}` || '',
                type: types[typeParam] || '',
                nature: natures[nature] || '',
                status: status ? getStatusInformationExport(status) : i18n.validated,
                unit: unit ? `[${unit.code}] ${unit.symbol}` : '',
                fraction: (fractions.find(f => f.id === fractionCode) || {}).labelWithCode,
                support: (supports.find(s => s.id === supportCode) || {}).labelWithCode,
                place: place ? i18n[place.label] : '',
            }
            if (index === 0) {
                return {
                    headers: ['name', 'type', 'nature', 'status', 'unit', 'fraction', 'support', 'place'],
                    ...obj,
                }
            }
            return obj
        })
        exportFile({
            data,
            exportType: 'xlsx',
            titleFile: `${i18n.campaigns}_${i18n.quality}_${i18n.parameters}_${this.state.campaign.name || ''}`,
        })
    }

    getExportAction = () => {
        if (!isAuthorized(H_QUALITY_EDILABO, this.props)) {
            return [
                {
                    onClick: () => this.state.view === STATION_PANEL ? this.onExportStations() : this.onExportParameters(),
                    label: i18n.excel,
                },
            ]
        }
        return [
            {
                onClick: () => this.state.view === STATION_PANEL ? this.onExportStations() : this.onExportParameters(),
                label: i18n.excel,
            },
            {
                onClick: () => this.setState({ isOpenPopupEdilaboDAI: true }),
                label: i18n.EDILABODAI,
            },
            {
                onClick: () => this.setState({ isOpenPopupEdilaboRAI: true }),
                label: i18n.EDILABORAI,
            },
        ]
    }

    getLinkAction = () => take(orderBy(this.props.edilaboFiles, 'updateDate', 'desc'), 5)

    setActionEditMode = () => {
        if (this.props.params.id === 'duplicate') {
            this.setActions({
                save: this.onSaveDuplicate,
                cancel: () => this.props.push('/quality/campaign'),
            })
        } else if (this.props.params.id === 'new') {
            this.setActions({
                save: () => {
                    const date = moment().valueOf()
                    const campaign = {
                        ...this.state.campaign,
                        updateLogin: getUser().login,
                        updateDate: date,
                        beginningApplication: this.state.campaign.beginningApplication || getDate(date),
                        stationType: 3, //
                        contactCode: this.props.accountUser?.contactCode,
                    }
                    if (campaign.name) {
                        this.props.createCampaign(campaign, 'quality')
                            .then((json = {}) => {
                                if (json.insert === 1 && json.id) {
                                    this.props.push(`/quality/campaign/${json.id}/dashboard`)
                                    this.setReadMode()
                                    this.props.toastrSuccess(i18n.successfulCreation)
                                }
                            })
                    } else {
                        this.props.toastrError(i18n.thanksToEnterName)
                    }
                },
                import: () => this.setState({ isPopupImportOpen: true }),
                cancel: () => this.props.push('/quality/campaign'),
            })
        } else {
            this.setActions({
                save: this.onSaveWithStations,
                cancel: this.setReadMode,
                import: () => this.setState({ isPopupImportOpen: true }),
                links: this.getLinkAction(),
                exportList: this.getExportAction(),
            })
        }
    }

    setActionReadMode = () => {
        const action = getUser().consultant === '1' ? {
            exportList: this.getExportAction(),
            links: this.getLinkAction(),
        } : {
            edit: this.setEditMode,
            delete: () => this.props.deleteCampaign('quality', this.props.params.id),
            duplicate: () => {
                this.setState({
                    campaign: {
                        ...this.state.campaign,
                        name: '',
                        beginningApplication: undefined,
                        endingApplication: undefined,
                    },
                })
                this.props.push('/quality/campaign/duplicate/dashboard')
            },
            exportList: this.getExportAction(),
            links: this.getLinkAction(),
        }
        this.setActions(action)
    }

    setReadMode = () => {
        this.setActionReadMode()
        this.setState({
            campaign: this.props.campaign,
            editMode: false,
            selectedStations: this.props.campaignStations.map(s => ({ id: s.stationId })),
            selectedParameters: [...this.props.campaignParameters],
            plannings: [...this.props.campaignPlannings],
        })
    }

    setEditMode = () => {
        this.setActionEditMode()
        this.setState({ editMode: true })
    }

    onSaveDuplicate = () => {
        const date = moment().valueOf()
        const campaign = {
            ...this.state.campaign,
            beginningApplication: this.state.campaign.beginningApplication || getDate(date),
            updateLogin: getUser().login,
            updateDate: date,
            stationType: 3, // quality
        }
        if (campaign.name) {
            const stations = this.state.selectedStations.map(s => new DtoStationCampaign({ stationId: s.id }))
            const parameters = this.state.selectedParameters.map(p => new DtoParameterCampaign(p))
            this.props.createCampaign(campaign, 'quality').then((json = {}) => {
                if (json.id) {
                    this.props.updateCampaignParameters(json.id, parameters)
                    this.props.updateCampaignPlannings(json.id, this.state.plannings)
                    this.props.updateCampaignStations(stations, 'quality', json.id).then(() => {
                        this.props.push(`/quality/campaign/${json.id}/dashboard`)
                        this.setReadMode()
                        this.props.toastrSuccess(i18n.successfulCreation)
                    })
                }
            })
        } else {
            this.props.toastrError(i18n.thanksToEnterName)
        }
    }

    onSaveWithStations = () => {
        const {
            id,
        } = this.props.params
        const {
            selectedStations,
            selectedParameters,
            plannings,
            campaign,
        } = this.state
        const stations = selectedStations.map(s => new DtoStationCampaign({ stationId: s.id }))
        const parameters = selectedParameters.map(p => new DtoParameterCampaign(p))
        const planningFiltered = plannings.map(planning => ({
            ...planning,
            parametersPlan: planning.parametersPlan.filter(({ parameterCode }) => parameters.find(param => param.parameterCode === parameterCode)),
            stationsPlan: planning.stationsPlan.filter(idStation => stations.find(s => s.stationId === idStation)),
        }))
        this.props.updateCampaign(campaign, id, 'quality').then(() => {
            this.props.updateCampaignStations(stations, 'quality', id)
            this.props.updateCampaignParameters(id, parameters)
            this.props.updateCampaignPlannings(id, planningFiltered)
            this.setReadMode()
        })
    }

    componentDidUpdate = prevProps => {
        if (this.props.params.id !== prevProps.params.id) {
            this.setTitle()
            if (this.props.params.id === 'new' || this.props.params.id === 'duplicate') {
                this.setEditMode()
            } else {
                this.setReadMode()
            }
        }
        if (!isEqual(this.props.edilaboFiles, prevProps.edilaboFiles)) {
            if (this.state.editMode) {
                this.setActionEditMode()
            } else {
                this.setActionReadMode()
            }
        }
        if (!isEqual(this.props.campaignStations, prevProps.campaignStations)) {
            this.setState({
                selectedStations: this.props.campaignStations.map(s => ({ id: s.stationId })),
            })
        }
        if (!isEqual(this.props.campaignParameters, prevProps.campaignParameters)) {
            this.setState({
                selectedParameters: [...this.props.campaignParameters],
            })
        }
        if (!isEqual(this.props.campaignPlannings, prevProps.campaignPlannings)) {
            this.setState({
                plannings: [...this.props.campaignPlannings],
            })
        }
        if (!isEqual(this.props.campaign, prevProps.campaign)) {
            this.setTitle()
            this.setState({ campaign: this.props.campaign })
        }
    }

    onDeleteStation = station => this.setState({ selectedStations: this.state.selectedStations.filter(s => s.id !== station.id) })

    onDeleteParameter = param => this.setState({ selectedParameters: this.state.selectedParameters.filter(p => p.parameterCode !== param.code) })

    onDeletePlanning = index => this.setState({ plannings: this.state.plannings.filter((_, i) => i !== index) })

    onUpdateParameter = param => this.setState(({ selectedParameters }) => ({ selectedParameters: selectedParameters.map(p => p.parameterCode === param.parameterCode ? param : p) }))

    onUpdatePlanning = (planning, index) => {
        const plannings = hasValue(index) ? this.state.plannings.map((p, i) => i === index ? planning : p) : [...this.state.plannings, planning]
        this.setState({ plannings: orderBy(plannings, ['startDate', 'endDate']) })
    }

    getStationsToMonitor = stations => {
        const date = moment().valueOf()
        const {
            monitoringCampaignsEvents,
            toPlanEvents,
        } = this.props.campaignEvents.reduce((acc, event) => {
            if (!event.closeDate || event.closeDate > date) {
                if (event.eventType === 'S') {
                    acc.monitoringCampaignsEvents.push(event)
                }
                if (event.eventType === 'P') {
                    acc.toPlanEvents.push(event)
                }
            }
            return acc
        }, { monitoringCampaignsEvents: [], toPlanEvents: [] })
        return stations.map(s => {
            const monitoringEvents = monitoringCampaignsEvents.filter(e => e.stationId === s.id)
            const toPlanEvent = toPlanEvents.filter(e => e.stationId === s.id)
            return {
                ...s,
                monitoring: monitoringEvents.length,
                monitoringEvents,
                toPlanEventLength: toPlanEvent.length,
                toPlanEvents: toPlanEvent,
            }
        })
    }

    onClosePopupEDILABO = () => this.setState({
        isOpenPopupEdilaboDAI: false,
        paramEDILABO: {},
    })

    render = () => {
        const {
            campaign,
            editMode,
            selectedStations,
            selectedParameters,
            plannings,
            isOpenPopupEdilaboDAI,
            isOpenPopupEdilaboRAI,
            view,
            isPopupImportOpen,
        } = this.state
        const {
            campaignProgress,
            stations,
            params,
        } = this.props
        return (
            <div id='campaignApp' className='row no-margin'>
                <div className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <FormQualityCampaign
                                    campaign={campaign}
                                    disabled={{ active: editMode, disabled: !editMode }}
                                    size={params.id === 'new' || params.id === 'duplicate' ? 'col s12' : 'col s12 m9'}
                                    onChangeCampaign={obj => this.setState({ campaign: { ...campaign, ...obj } })}
                                />
                                {
                                    params.id !== 'new' && params.id !== 'duplicate' && (
                                        <div className='col s12 m3'>
                                            <div className='row no-margin'>
                                                <div className='col s12'>
                                                    <LastUpdateCard
                                                        element={campaign}
                                                        withHour
                                                    />
                                                </div>
                                                <div className='col s12'>
                                                    <Card title={i18n.analysisProgress}>
                                                        <ProgressCircleChart
                                                            total={campaignProgress.progressTotal}
                                                            complete={campaignProgress.progress}
                                                            title={`${getPercentage(campaignProgress.progress, campaignProgress.progressTotal)} %`}
                                                        />
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {
                        params.id !== 'new' && (
                            <TabPanel
                                selectedStations={selectedStations}
                                selectedParameters={selectedParameters}
                                selectedSelections={campaign.selections}
                                plannings={plannings}
                                onChangeView={newView => this.setState({ view: newView })}
                                view={view}
                                editMode={editMode}
                                stations={this.getStationsToMonitor(stations)}
                                parameterListCode={campaign.parameterListCode}
                                onChangeStation={tmpStations => this.setState({ selectedStations: tmpStations })}
                                onChangeParameter={(tmpParameters, tmpSelections) => this.setState({ selectedParameters: tmpParameters, campaign: { ...campaign, selections: [...tmpSelections] } })}
                                onUpdateParameter={this.onUpdateParameter}
                                onUpdatePlanning={this.onUpdatePlanning}
                                onDeleteStation={this.onDeleteStation}
                                onDeleteParameter={this.onDeleteParameter}
                                onDeletePlanning={this.onDeletePlanning}
                            />
                        )
                    }
                </div>
                <SteperPopupEDILABO
                    isOpen={isOpenPopupEdilaboDAI}
                    onClose={() => this.setState({ isOpenPopupEdilaboDAI: false })}
                    campaign={campaign}
                    stations={stations}
                    selectedStations={selectedStations}
                    selectedParameters={selectedParameters}
                    plannings={plannings}
                    onChange={obj => this.setState(obj)}
                    setEditMode={this.setEditMode}
                />
                <ImportCampagnePopup
                    isOpen={isPopupImportOpen}
                    onClose={() => this.setState({ isPopupImportOpen: false })}
                    onChangeStation={tmpStations => this.setState({ selectedStations: uniqBy([...selectedStations, ...tmpStations], 'id') })}
                    onChangeParameter={tmpParameters => this.setState({ selectedParameters: uniqBy([...selectedParameters, ...tmpParameters], 'parameterCode') })}
                />
                <PopupEdilaboRAI
                    isOpen={isOpenPopupEdilaboRAI}
                    campaign={campaign}
                    stations={stations}
                    selectedStations={selectedStations.map(({ id }) => id)}
                    selectedParameters={selectedParameters}
                    onClose={() => this.setState({ isOpenPopupEdilaboRAI: false })}
                />
            </div >
        )
    }
}

QualityCampaign.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    accessibilities: PropTypes.arrayOf(PropTypes.shape({})),
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),

    campaign: PropTypes.instanceOf(CampaignDto),
    campaignStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    campaignParameters: PropTypes.arrayOf(PropTypes.instanceOf(DtoParameterCampaign)),
    campaignPlannings: PropTypes.arrayOf(PropTypes.instanceOf(CampaignPlanningDto)),
    campaignEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    campaignProgress: PropTypes.instanceOf(DtoCampaignProgress),
    edilaboFiles: PropTypes.arrayOf(PropTypes.instanceOf(EDILABOFileDto)),

    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),

    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
}

QualityCampaign.defaultProps = {
    accessibilities: [],
}

const mapStateToProps = store => ({
    campaign: store.CampaignReducer.campaign,
    campaignStations: store.CampaignReducer.campaignStations,
    campaignParameters: store.CampaignReducer.campaignParameters,
    campaignPlannings: store.CampaignReducer.campaignPlannings,
    campaignEvents: store.CampaignReducer.campaignEvents,
    campaignProgress: store.CampaignReducer.campaignProgress,
    edilaboFiles: store.CampaignReducer.edilaboFiles,
    stations: store.QualityReducer.qualitometersLight,

    parameters: store.ParameterReducer.parameters,
    fractions: store.FractionReducer.fractions,
    supports: store.SupportReducer.supports,
    units: store.UnitReducer.units,

    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,

    accountUser: store.AccountReducer.accountUser,
    accountHabilitations: store.AccountReducer.accountHabilitations,

})

const mapDispatchToProps = {
    push,
    createCampaign: CampaignAction.createCampaign,
    updateCampaign: CampaignAction.updateCampaign,
    deleteCampaign: CampaignAction.deleteCampaign,
    updateCampaignStations: CampaignAction.updateCampaignStations,
    updateCampaignParameters: CampaignAction.updateCampaignParameters,
    updateCampaignPlannings: CampaignAction.updateCampaignPlannings,
    forceFetch: SieauAction.forceFetch,
    toastrSuccess: ToastrAction.success,
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityCampaign)
