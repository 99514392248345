import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { H_CAMPAIGN_HYDRO_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import DashboardCampaign from '../../../campaign/components/campaignDashboard/DashboardCampaign'
import ProgressCard from '../../../components/card/ProgressCard'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'


class HydrometryCampaignDashboard extends Component {
    state = {
        dataLoaded: false,
        progress: 0,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_CAMPAIGN_HYDRO_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.loadCampaignDashboard(p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))
    }

    componentDidUpdate = prevProps => {
        // const {
        //     id,
        // } = this.props.match.params
        // if (id !== prevProps.match.params.id && id !== 'new' && id !== 'duplicate') {
        //     this.props.fetchCampaign('hydrometry', id)
        //     this.props.fetchCampaignStation('hydrometry', id)
        //     this.props.fetchCampaignProgress('hydrometry', id)
        // }
    }

    render = () => {
        const {
            dataLoaded,
            progress,
        } = this.state
        if (dataLoaded) {
            const { hydrometricStations } = this.props
            const { params } = this.props.match
            return (
                <DashboardCampaign
                    params={params}
                    stations={hydrometricStations}
                    stationType={'hydrometry'}
                />
            )
        }
        return <ProgressCard progress={progress} />
    }
}

HydrometryCampaignDashboard.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),

    push: PropTypes.func,
    loadCampaignDashboard: PropTypes.func,
    fetchCampaign: PropTypes.func,
    fetchCampaignStation: PropTypes.func,
    fetchCampaignProgress: PropTypes.func,
}

const mapStateToProps = store => ({
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
})

const mapDispatchToProps = {
    loadCampaignDashboard: CampaignAction.loadCampaignDashboard,
    fetchCampaign: CampaignAction.fetchCampaign,
    fetchCampaignStation: CampaignAction.fetchCampaignStation,
    fetchCampaignProgress: CampaignAction.fetchCampaignProgress,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometryCampaignDashboard)
