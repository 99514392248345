import { Button, Grid, MobileStepper } from '@mui/material'
import Icon from 'components/icon/Icon'
import ConfirmModal from 'components/modal/ConfirmModal'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { floor, maxBy, minBy, orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import ExportAction from '../../../../../export/actions/ExportAction'
import DtoQualitometerLight from '../../../../../quality/dto/DtoQualitometerLight'
import { getDate } from '../../../../../utils/DateUtil'
import { hasValue } from '../../../../../utils/NumberUtil'
import { searchAllCharacters } from '../../../../../utils/StringUtil'
import CampaignAction from '../../../../actions/CampaignAction'
import CampaignPlanningDto from '../../../../dto/CampaignPlanningDto'
import FormPopupExportEDILABO from '../stepExportEDILABO/FormPopupExportEDILABO'
import PlanningPopupExportEDILABO from '../stepExportEDILABO/PlanningPopupExportEDILABO'
import ValidateParameterTab from '../stepExportEDILABO/ValidateParameterTab'
import ValidateStationTab from '../stepExportEDILABO/ValidateStationTab'

const FORM = -2
const PLANNING = -1
const STATION = 0
const PARAM = 1

const getTimestamp = date => date && moment(date, 'DD/MM/YYYY').valueOf()

const PopupPlanningEDILABO = ({
    onClose = () => {},
    isOpen,
    stations,
    campaign,
    plannings: planningsProps = [],
    selectedStations,
    selectedParameters,
    onChange = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        parameters,
        edilaboFiles,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        edilaboFiles: store.CampaignReducer.edilaboFiles,
    }), shallowEqual)

    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)

    const [step, setStep] = useState(FORM)
    const [plannings, setPlannings] = useState(planningsProps)
    const [paramEDILABO, setParamEDILABO] = useState(() => {
        const lastFile = maxBy(edilaboFiles, 'updateDate')
        const exportPath = lastFile ? lastFile.href.split('EXPORT/EDILABO/')[1].replace(lastFile.label, '') : ''
        return {
            campaignId: campaign.id,
            exportPath,
            requestName: '',
            startDate: planningsProps.length ? minBy(planningsProps, 'startDate').startDate : getTimestamp(campaign.beginningApplication),
            endDate: planningsProps.length ? maxBy(planningsProps, 'endDate').endDate : getTimestamp(campaign.endingApplication),
            codifications: [], // [{ stationId: Int, codification: Int }]
            sender: hasValue(campaign.contributorCode) ? parseInt(campaign.contributorCode) : undefined,
            receiver: (() => {
                const samplerCode = hasValue(campaign.samplerCode) ? parseInt(campaign.samplerCode) : undefined
                const recipientCode = hasValue(campaign.recipientCode) ? parseInt(campaign.recipientCode) : undefined
                switch (campaign.requestType) {
                    case '1':
                        return samplerCode
                    case '2':
                        return recipientCode
                    case '3':
                        return recipientCode || samplerCode
                    default:
                        return undefined
                }
            })(),
        }
    })

    const onCloseDialog = () => {
        onClose()
        dispatch(ToastrAction.info(i18n.RememberToBackUpYourData))
    }

    const indexCurrentPlanning = floor(step / 2)

    const {
        stationsPlan = [],
        parametersPlan = [],
        selection,
        startDate,
        endDate,
    } = indexCurrentPlanning >= 0 ? plannings[indexCurrentPlanning] : {}

    const nbSteps = 2 + plannings.length * 2

    const isNextDisabled = useMemo(() => {
        const {
            sender,
            receiver,
            requestName,
            startDate: startDateParam,
            endDate: endDateParam,
        } = paramEDILABO
        return (step >= 0 && step % 2 === STATION && !stationsPlan.length)
            || (step >= 0 && step % 2 === PARAM && !parametersPlan.length)
            || (step === PLANNING && !plannings.length)
            || (step === FORM && (!hasValue(sender) || !hasValue(receiver) || !requestName || !startDateParam || !endDateParam))
            || step === nbSteps - 3
    }, [nbSteps, paramEDILABO, parametersPlan.length, plannings.length, stationsPlan.length, step])

    const isExportDataComplete = useMemo(() => {
        return plannings.every(planning => {
            return planning.parametersPlan.length
                && planning.stationsPlan.length
                && planning.parametersPlan.every(p => hasValue(p.fractionCode) && hasValue(p.unitCode) && hasValue(p.supportCode))
        })
    }, [plannings])

    const onExport = () => {
        dispatch(CampaignAction.updateCampaignPlannings(campaign.id, plannings)).then(shouldClose => {
            if (shouldClose) {
                dispatch(ExportAction.exportEDILABOPlanning(paramEDILABO))
                onClose()
            }
        })
    }

    return (
        <>
            <DialogMUI
                open={isOpen}
                fullWidth
                maxWidth='xl'
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                        <Grid item >
                            {`${i18n.step} ${step + 3}/${nbSteps}: ${i18n.EDILABODAI}`}
                            {step >= 0 ? `: ${i18n.sample} ${i18n.fromDate} ${getDate(startDate)} - ${getDate(endDate)}` : ''}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onCloseDialog} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI>
                    {
                        step === FORM && (
                            <FormPopupExportEDILABO
                                paramEDILABO={paramEDILABO}
                                onChange={obj => setParamEDILABO(prevParam => ({ ...prevParam, ...obj }))}
                            />
                        )
                    }
                    {
                        step === PLANNING && (
                            <PlanningPopupExportEDILABO
                                plannings={plannings}
                                onChange={(planning, index) => {
                                    const newPlannings = hasValue(index) ? plannings.map((p, i) => i === index ? { ...p, ...planning } : p) : [...plannings, { ...planning, stationsPlan: [], parametersPlan: [], selection: campaign.parameterListCode }]
                                    setPlannings(orderBy(newPlannings, 'startDate'))
                                }}
                                onDeletePlanning={index => setPlannings(plannings.filter((_, i) => index !== i))}
                            />
                        )
                    }
                    {
                        step >= 0 && step % 2 === STATION && (
                            <ValidateStationTab
                                stations={stations.filter(s => !!selectedStations.find(stationId => stationId === s.id))}
                                selectedStations={stationsPlan}
                                codifications={paramEDILABO.codifications}
                                onChangeCodifications={newCodi => setParamEDILABO(p => ({ ...p, codifications: [...p.codifications.filter(c => c.stationId !== newCodi.stationId), newCodi] }))}
                                onChangeSelectedStation={tmpStations => setPlannings(plannings.map((p, i) => i === indexCurrentPlanning ? { ...p, stationsPlan: tmpStations } : p))}
                            />
                        )
                    }
                    {
                        step >= 0 && step % 2 === PARAM && (
                            <ValidateParameterTab
                                parameters={parameters.filter(p => p.active).reduce((acc, p) => {
                                    const param = selectedParameters.find(({ parameterCode }) => parameterCode === p.code)
                                    if (param) {
                                        acc.push({
                                            ...p,
                                            ...param,
                                            labelSearch: searchAllCharacters(p.code + p.name),
                                        })
                                    }
                                    return acc
                                }, [])}
                                canEdit
                                selectedParameters={parametersPlan}
                                parameterListCode={selection}
                                selections={campaign.selections}
                                onChangeSelection={(tmpSelection, tmpParameters) => setPlannings(plannings.map((p, i) => i === indexCurrentPlanning ? { ...p, parametersPlan: tmpParameters, selection: tmpSelection } : p))}
                                onChangeSelectedParameter={tmpParameters => setPlannings(plannings.map((p, i) => i === indexCurrentPlanning ? { ...p, parametersPlan: tmpParameters } : p))}
                            />
                        )
                    }
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item xs={1} />
                        <Grid item xs={6}>
                            <MobileStepper
                                variant='progress'
                                steps={nbSteps}
                                position='static'
                                activeStep={step + 2}
                                sx={{ height: 10 }}
                                nextButton={
                                    <Button
                                        size='small'
                                        onClick={() => {
                                            onChange({ plannings })
                                            setStep(s => s + 1)
                                        }}
                                        disabled={isNextDisabled}
                                        variant='outlined'
                                        sx={{
                                            '&:focus': {
                                                outline: 'none',
                                                backgroundColor: 'white',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgb(53, 96, 159, 0.04)',
                                            },
                                        }}
                                    >
                                        {i18n.next}
                                        <Icon size='small' icon='keyboard_arrow_right' />
                                    </Button>
                                }
                                backButton={
                                    <Button
                                        size='small'
                                        onClick={() => setStep(s => s - 1)}
                                        disabled={step === -2}
                                        variant='outlined'
                                        sx={{
                                            '&:focus': {
                                                outline: 'none',
                                                backgroundColor: 'white',
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgb(53, 96, 159, 0.04)',
                                            },
                                        }}
                                    >
                                        <Icon size='small' icon='keyboard_arrow_left' />
                                        {i18n.previous}
                                    </Button>
                                }
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                onClick={() => {
                                    if (isExportDataComplete) {
                                        onExport()
                                    }
                                    setConfirmModalOpen(true)
                                }}
                                disabled={step <= PLANNING}
                                variant='contained'
                            >
                                {i18n.export}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActionsMUI>
            </DialogMUI>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                title={i18n.someDatasAreIncompleteAndWillNotBeTakenIntoAccountInTheExport}
                onValidate={onExport}
                onClose={() => setConfirmModalOpen(false)}
            />
        </>
    )
}

PopupPlanningEDILABO.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    campaign: PropTypes.shape({
        id: PropTypes.number,
        recipientCode: PropTypes.string,
        contributorCode: PropTypes.string,
        samplerCode: PropTypes.string,
        requestType: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.string),
        parameterListCode: PropTypes.string,
        beginningApplication: PropTypes.string,
        endingApplication: PropTypes.string,
    }),
    plannings: PropTypes.arrayOf(PropTypes.instanceOf(CampaignPlanningDto)),
    selectedStations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    onChange: PropTypes.func,
}

export default PopupPlanningEDILABO
