import HomeAction from 'home/actions/HomeAction'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import SieauAction from '../../../components/sieau/SieauAction'
import AppStore from '../../../store/AppStore'
import { getUser } from '../../../utils/SettingUtils'
import UserAction from '../user/actions/UserAction'
import { BASIC_HOMEPAGE_VIEWS } from '../user/constants/UserConstants'
import UserViewDto from '../user/dto/UserViewDto'

class AdministrationHomePageApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            homepageViews: props.genericUserView,
        }
    }

    componentDidMount() {
        AppStore.dispatch(HomeAction.setHelpLink('administration', '91'))
        AppStore.dispatch(SieauAction.forceFetch('title', [
            {
                title: i18n.administration,
                href: 'administration',
            }, {
                title: i18n.adminHomepage,
                href: 'administration/homepage',
            },
        ]))
        if (!(getUser().consultant === '1')) {
            this.setActions({
                edit: () => this.setEditMode(),
            })
        } else {
            this.setActions({})
        }
        AppStore.dispatch(UserAction.fetchGenericUserView())
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.genericUserView, this.props.genericUserView)) {
            this.setState({ homepageViews: nextProps.genericUserView })
        }
    }

    setEditMode = () => {
        this.setState({ editMode: true })
        const actions = {
            cancel: () => this.setReadOnlyMode(),
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => this.saveView()
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ editMode: false, homepageViews: this.props.genericUserView })
        if (!(getUser().consultant === '1')) {
            this.setActions({
                edit: () => this.setEditMode(),
            })
        } else {
            this.setActions({})
        }
    }

    getCard = (panel, size) => {
        const { homepageViews, editMode } = this.state
        const view = homepageViews.find(v => v.panel === panel) || { ...BASIC_HOMEPAGE_VIEWS.find(v => v.panel === panel), visible: false }
        const actions = []
        if (view && view.visible) {
            if (['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit'].includes(panel)) {
                actions.push({
                    iconName: view.graphicMode ? 'list' : 'insert_chart',
                    onClick: () => {},
                })
            }
            actions.push({
                iconName: 'visibility',
                onClick: () => editMode && this.onChangeView(view, { visible: false }),
            })
        } else {
            actions.push({
                iconName: 'visibility_off',
                onClick: () => editMode && this.onChangeView(view, { visible: true }),
            })
        }
        return (
            <Card height={ size } title={ i18n[panel] } noMargin={ false } className='margin-bottom-1' contentClassName='valign-wrapper' actions={ actions } active={ editMode } cardStyle={view && view.visible ? {} : { opacity: 0.5 }}>
                { ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit'].includes(panel) ? <i className={ `material-icons large absolute left-45 ${!editMode ? 'grey-text' : ''}` } >{ view.graphicMode ? 'insert_chart' : 'list' }</i> : null }
            </Card>
        )
    }

    onChangeView = (view, changes) => {
        const newView = new UserViewDto({ ...view, ...changes })
        const userView = this.state.homepageViews.filter(v => v.panel != newView.panel)
        this.setState({ homepageViews: [...userView, newView] })
    }

    resetView = () => {
        this.setState({ homepageViews: BASIC_HOMEPAGE_VIEWS })
    }

    saveView = () => {
        if (!isEqual(this.props.genericUserView, this.state.homepageViews)) {
            AppStore.dispatch(UserAction.updateGenericUserView(this.state.homepageViews, () => this.setReadOnlyMode()))
        } else {
            this.setReadOnlyMode()
        }
    }

    render() {
        const bookmarks = ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit', 'agriculture'].map(type => this.getCard(type, 70))
        return (
            <div>
                <div className='col s12 row no-margin padding-top-2 padding-bottom-1'>
                    <div className='col s3 offset-s9 padding-left-2'>
                        <a className={ `col s12 btn ${this.state.editMode ? '' : 'disabled'}` } onClick={ this.resetView }>
                            { i18n.reinit }
                        </a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col s9 padding-top-1 padding-left-2'>
                        { bookmarks }
                        { this.getCard('map', 400) }
                    </div>
                    <div className='col s3 padding-top-1'>
                        { this.getCard('keyFigures', 100) }
                        { this.getCard('arrests', 150) }
                        { this.getCard('vigilances', 150) }
                        { this.getCard('tweets', 400) }
                    </div>
                </div>
            </div>
        )
    }
}

AdministrationHomePageApp.propTypes = {
    genericUserView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
}

const mapStateToProps = (store) => ({
    genericUserView: store.UserReducer.genericUserView,
})

export default connect(mapStateToProps)(AdministrationHomePageApp)
