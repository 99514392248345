import { Button, Grid } from '@mui/material'
import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import RestictionsDisplay from 'exploitations/components/waterTurns/RestrictionsDisplay'

const WaterTurnsFilters = ({
    restrictions = [],
    equipments = [],
    data = [],
    onFilter = () => { },
}) => {
    const [search, setSearch] = useState()
    const [equipmentsFilter, setEquipmentsFilter] = useState()

    return (
        <Grid item xs={12}>
            <Card className='padding-1' round>
                <Grid container>
                    <Grid container item xs={12}>
                        <Grid item xs={6} className='padding-right-1'>
                            <Input
                                title={i18n.search}
                                value={search}
                                onChange={setSearch}
                                onEnterKeyPress={() => onFilter({ search, equipmentsFilter })}
                                data-cy='search_water_turns'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MultiAutocomplete
                                col={12}
                                displayWithCode
                                label={i18n.pumps}
                                elements={equipments.filter(e => data.map(d => d.material).includes(e.reference))}
                                clearFunction
                                keyName='reference'
                                values={equipmentsFilter}
                                onChange={setEquipmentsFilter}
                            />
                        </Grid>
                        <Grid item xs={8} className='padding-top-1'>
                            <RestictionsDisplay restrictions={restrictions} />
                        </Grid>
                        <Grid container item xs={4} justifyContent='flex-end'>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        setSearch()
                                        setEquipmentsFilter()
                                        onFilter({ search: null, equipmentsFilter: null })
                                    }}
                                >
                                    {i18n.reinit}
                                </Button>
                            </Grid>
                            <Grid item className='padding-left-1'>
                                <Button
                                    variant='contained'
                                    onClick={() => onFilter({ search, equipmentsFilter })}
                                >
                                    {i18n.search}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
            </Card >
        </Grid >
    )
}

WaterTurnsFilters.propTypes = {
    restrictions: PropTypes.arrayOf(PropTypes.shape({})),
    equipments: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    onFilter: PropTypes.func,
}

export default WaterTurnsFilters