/* eslint-disable consistent-return */
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { groupBy, isUndefined, keyBy, keys, max, maxBy, min, minBy, orderBy, round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { AREA, AUTO, MONTH, NOTHING, THRESHOLD, WEEK, YEAR, getIntervalFormatter, getYScale } from 'quality/constants/ChartConstant'
import React, { useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import { getDate, getFullDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import { hasValue } from 'utils/NumberUtil'
import {
    chartLine,
    chartSymbol,
    exportExcelIcon,
    exportPictureIcon,
    fullScreenIcon,
    histogramIcon,
    legendSymbol,
    lineIcon,
    logIcon,
    stackIcon,
    thresholdIcon,
} from './EChartUtils'

const REGEX_BACKGROUND_COLOR = /background-color:[#\w]+;/

const DEFAULT_GRAPH_HEIGHT = 400
const DEFAULT_GAP = 50
const DEFAULT_HEADER_HEIGHT = 60
const DEFAULT_FOOTER_HEIGHT = 135
const LINE = 'line'
const BAR = 'bar'
const ALL = 'all'

const MultiChart = ({
    data = [],
    grids = [],
    thresholds = [],
    title = '',
    exportName = '',
    componentHeight,
    headerHeight = DEFAULT_HEADER_HEIGHT,
    footerHeight = DEFAULT_FOOTER_HEIGHT,
    roundValue = 3,
    areaColor = [],

    xAxisSpace,
    maxXAxisSpace = AUTO,
    defaultMinDate,
    defaultMaxDate,

    defaultDisplayMarker = true,
    defaultDisplayLine = true,

    defaultStateThreshold = THRESHOLD,

    withToolTypeLine = false,
    withToolTypeBar = false,
    withToolTypeStack = false, // currently bug
    withToolLog = false,
    withToolThreshold = false,
    withToolLegend = false,
    withToolMarker = false,
    withToolLine = false,
    withFullScreen = false,
    withArea = false,
    withDataZoom = false,

    dataZoomPosition = { },
    toolboxPosition = { },
    legendPosition = { },

    tooltipFormatter,
    onFullScreen = () => {},

    onChangeLegend = () => {},
    defaultSelectedLegend = {},
}) => {
    // use on mouse event
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)
    let selectedLegend = useRef(defaultSelectedLegend)

    const isGridDisplayed = (gridIndex) => {
        if (!selectedLegend.current || !keys(selectedLegend.current).length) {
            return true
        }
        return data.some(d => d.gridIndex === gridIndex && (isUndefined(selectedLegend.current[d.name]) || selectedLegend.current[d.name]))
    }

    const [displayLog, setDisplayLog] = useState(false)
    const [displayMarker, setDisplayMarker] = useState(defaultDisplayMarker)
    const [displayLegend, setDisplayLegend] = useState(true)
    const [displayLine, setDisplayLine] = useState(defaultDisplayLine)
    const [chartType, setChartType] = useState(LINE)
    const [stack, setStack] = useState()
    const [stateThreshold, setStateThreshold] = useState(defaultStateThreshold)

    const defaultTimestamp = moment().valueOf()

    const thresholdsWithValue = thresholds.filter(({ value }) => hasValue(value))
    const groupAreaColor = keyBy(areaColor, 'gridIndex')

    const allData = data.flatMap(d => d.dataList)

    const minDate = defaultMinDate ?? (minBy(allData, 'date')?.date ?? defaultTimestamp)
    const maxDate = defaultMaxDate ?? (maxBy(allData, 'date')?.date ?? defaultTimestamp)
    const chartMinDate = moment(minDate).startOf(xAxisSpace ?? YEAR)
    const chartMaxDate = moment(maxDate).endOf(xAxisSpace ?? YEAR)

    const {
        formatter,
        interval,
    } = getIntervalFormatter(chartMinDate, chartMaxDate, maxXAxisSpace)
    // } = getAxisIntervalFormatter(chartMaxDate, chartMinDate)

    const getGridList = () => {
        let tmpGap = headerHeight
        return grids.map(({ gridOptions: { top = DEFAULT_GAP, height = DEFAULT_GRAPH_HEIGHT, right = '40px', left = '60px' }, gridIndex }) => {
            const display = isGridDisplayed(gridIndex)
            const tmpTop = tmpGap + top
            const grid = {
                top: tmpTop,
                height: display ? height : 0,
                left,
                right,
            }
            if (display) {
                tmpGap = tmpTop + height
            }
            return grid
        })
    }

    const getXAxisList = () => grids.map(({ xOptions = {}, gridIndex }, index = 0) => {
        const display = isGridDisplayed(gridIndex)
        const {
            type = 'time',
            boundaryGap = true,
            showSplitLine = true,
            axisLabel = {},
            axisLine = {},
            axisTick = {},
        } = xOptions
        return {
            type,
            boundaryGap,
            axisLabel: {
                rotate: axisLabel.rotate || 0,
                formatter,
                show: display ? (axisLabel.show ?? true) : display,
            },
            axisLine: {
                show: display ? (axisLine.show ?? true) : display,
            },
            axisTick: {
                show: display ? (axisTick.show ?? true) : display,
            },
            splitLine: {
                show: display ? (showSplitLine ?? true) : display,
            },
            interval,
            gridIndex: index,
            min: chartMinDate.valueOf(),
            max: chartMaxDate.valueOf(),
        }
    })

    const thresholdsSeries = stateThreshold === THRESHOLD ? thresholdsWithValue.map(({ value, gridIndex, name = i18n.threshold, color = 'black', labelColor = color }) => ({
        type: LINE,
        color,
        yAxisIndex: gridIndex,
        xAxisIndex: gridIndex,
        markLine: {
            symbol: 'none',
            // symbol: ['none', 'arrow'],
            data: [{
                yAxis: value,
                symbol: 'none',
                // gridName,
                label: {
                    show: true,
                    position: 'middle',
                    color: labelColor,
                    formatter: () => name ? `${name} : ${round(value, 2)}` : '',
                },
                lineStyle: {
                    normal: {
                        color,
                        type: 'dashed',
                    },
                },
            }],
            silent: true,
        },
    })) : []

    const groupThresholds = groupBy(thresholdsWithValue, 'gridIndex')
    const markArea = stateThreshold === AREA && thresholdsWithValue.length ? keys(groupThresholds).flatMap(key => [{
        type: LINE,
        yAxisIndex: parseInt(key),
        xAxisIndex: parseInt(key),
        markArea: {
            data: [
                ...groupThresholds[key].map(({ value }, i) => ({
                    0: {
                        yAxis: value,
                        itemStyle: {
                            color: groupAreaColor[key]?.color[i] || 'white',
                            opacity: 0.2,
                        },
                    },
                    1: { yAxis: i === 0 ? 0 : groupThresholds[key][i - 1].value },
                })),
                {
                    0: {
                        yAxis: groupThresholds[key][groupThresholds[key].length - 1].value,
                        itemStyle: {
                            color: groupAreaColor[key]?.color[groupThresholds[key].length] || 'white',
                            opacity: 0.2,
                        },
                    },
                    // 1: { yAxis: max(Object.keys(groupMax).map(k => groupMax[k])) },
                    1: { yAxis: 'min' }, // max value :shrug:
                },
            ],
        },
    }]) : []

    const series = data.map(({ name, unit, dataList, color, type, lineStyle, showSymbol = true, connectNulls = true, barWidth, gridIndex = 0, overrideSerie = {}, areaStyle, sampling, z = 2 }) => {
        const dataListFiltered = displayLog ? dataList.filter(d => d.value > 0) : dataList
        const dataOrdered = orderBy(dataListFiltered, 'date').map(dataObj => ({
            value: [dataObj.date, dataObj.value, dataObj],
            symbol: displayMarker ? (dataObj.marker || 'circle') : 'none',
            itemStyle: {
                color: dataObj.markerColor || dataObj.color,
            },
            unit: dataObj.unit ?? unit,
        }))
        return {
            type: type || chartType,
            data: dataOrdered,
            name,
            connectNulls,
            showSymbol,
            color,
            barWidth,
            symbolSize: 6,
            lineStyle: {
                opacity: displayLine ? undefined : 0,
                type: lineStyle,
            },
            stack,
            yAxisIndex: gridIndex,
            xAxisIndex: gridIndex,
            ...overrideSerie,
            markLine: { silent: false, data: thresholdsSeries.filter(threshold => threshold.gridIndex === gridIndex) },
            areaStyle,
            sampling,
            z,
        }
    })

    const getSeriesValues = (index, keysDisplayed) => {
        if (keys(defaultSelectedLegend).length) {
            return series.filter(({ xAxisIndex, name: serieName }) => xAxisIndex === index && keysDisplayed.includes(serieName)).flatMap(s => s.data).map(({ value }) => value[1])
        }
        return series.filter(({ xAxisIndex }) => xAxisIndex === index).flatMap(s => s.data).map(({ value }) => value[1])
    }

    // const isGridDisplayed = (gridIndex) => {
    //     if (!selectedLegend.current || !keys(selectedLegend.current).length) {
    //         return true
    //     }
    //     return data.some(d => d.gridIndex === gridIndex && (isUndefined(selectedLegend.current[d.name]) || selectedLegend.current[d.name]))
    // }

    const getYAxisList = () => grids.map(({ name, yOptions = {}, gridIndex }, index = 0) => {
        const display = isGridDisplayed(gridIndex)
        const keysDisplayed = keys(selectedLegend.current).filter(legend => selectedLegend.current[legend])
        const allSeriesValues = getSeriesValues(index, keysDisplayed)
        const allDatas = data.filter(d => d.gridIndex === index)
        const allThresholdsValues = thresholdsSeries.filter(threshold => allDatas.includes(({ gridName }) => threshold.gridName === gridName)).map(({ yAxis }) => yAxis)

        const minValue = min(allSeriesValues)
        const maxValue = max(allSeriesValues)
        const usedMax = orderBy(allThresholdsValues, v => v).find(t => t.value > maxValue)?.value ?? maxValue
        // orderBy(thresholdGroupByYAxis[idYAxis], 'value', 'desc').find(t => t.value < value.min)?.value || value.min
        const scale = getYScale(minValue, usedMax)

        return {
            type: yOptions.type ?? (displayLog ? 'log' : 'value'),
            nameLocation: yOptions.nameLocation ?? 'center',
            nameGap: yOptions.nameGap ?? 40,
            boundaryGap: yOptions.boundaryGap ?? true,
            showSplitLine: yOptions.showSplitLine,
            name: display ? name : undefined,
            gridIndex: index,
            axisLabel: { formatter: v => v > 10 ? round(v) : round(v, 4) },
            interval: scale.interval,
            max: () => {
                if (displayLog) {
                    return
                }
                return yOptions.max ?? scale.max
            },
            min: () => {
                if (displayLog) {
                    return
                }
                if (chartType === BAR || stack) {
                    return 0
                }
                return yOptions.min ?? scale.min
            },
        }
    })

    // these functions are called in the option object as well as in the setOption in the mouseEvent
    const getLegend = () => ({
        top: 55,
        left: '5%',
        // adding boolan give number
        right: displayToolbox.current ? `${30 * (4 + withToolTypeLine + withToolTypeBar + withToolTypeStack + withToolLog + withToolThreshold + withToolLegend + withToolMarker + withToolLine) + (parseInt(toolboxPosition.right?.replace('px', '')) || 35)}px` : '2%',
        ...legendPosition,
        type: 'scroll',
        show: displayLegend,
        selected: selectedLegend.current,
    })

    const formatTooltip = params => {
        const date = getDate(params[0].axisValue)
        const listLine = params.map(({ marker, seriesIndex, seriesName, value: [, result], data: { unit = '', lineColor } }) => {
            const color = lineColor ?? echartRef.current?.getEchartsInstance().getVisual({
                seriesIndex,
            }, 'color')
            const newMarker = marker.replace(REGEX_BACKGROUND_COLOR, `background-color:${color};`)
            return `<br/>${newMarker} ${seriesName}: ${result && round(result, roundValue)} ${unit}`
        })
        return `${date}${listLine.join('')}`
    }

    const getToolbox = () => ({
        top: '50px',
        right: '35px',
        ...toolboxPosition,
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            myToolLine: {
                show: displayToolbox.current && withToolTypeLine,
                title: i18n.lines,
                icon: lineIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(LINE)
                },
                iconStyle: {
                    borderColor: chartType === LINE ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolBar: {
                show: displayToolbox.current && withToolTypeBar,
                title: i18n.histogram,
                icon: histogramIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(BAR)
                    setDisplayLog(false)
                },
                iconStyle: {
                    borderColor: chartType === BAR && !stack ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolStack: {
                show: false, // currently bug
                // show: displayToolbox.current && withToolTypeStack,
                title: i18n.stack,
                icon: stackIcon,
                onclick: () => {
                    setStack(ALL)
                    setChartType(BAR)
                    setDisplayLog(false)
                },
                iconStyle: {
                    borderColor: chartType === BAR && stack === ALL ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolLog: {
                show: displayToolbox.current && withToolLog,
                title: i18n.logarithm,
                icon: logIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(LINE)
                    setDisplayLog(prevDisplayLog => !prevDisplayLog)
                },
                iconStyle: {
                    borderColor: displayLog ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolThreshold: {
                show: displayToolbox.current && withToolThreshold && !!thresholdsWithValue.length,
                title: withArea ? i18n.thresholdAreaNothing : i18n.toggleThreshold,
                icon: thresholdIcon,
                onclick: () => setStateThreshold(prevStateThreshold => {
                    if (withArea) {
                        return (prevStateThreshold + 1) % 3
                    }
                    return prevStateThreshold === THRESHOLD ? NOTHING : THRESHOLD
                }),
                iconStyle: {
                    borderColor: stateThreshold !== NOTHING ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleLegend: {
                show: displayToolbox.current && withToolLegend,
                title: i18n.toggleLegend,
                icon: legendSymbol,
                onclick: () => setDisplayLegend(prevHiddenLegend => !prevHiddenLegend),
                iconStyle: {
                    borderColor: displayLegend ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleMarker: {
                show: displayToolbox.current && withToolMarker,
                title: i18n.toggleMarker,
                icon: chartSymbol,
                onclick: () => setDisplayMarker(prevHiddenMarker => !prevHiddenMarker),
                iconStyle: {
                    borderColor: displayMarker ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleLine: {
                show: displayToolbox.current && withToolLine,
                title: i18n.toggleLine,
                icon: chartLine,
                onclick: () => setDisplayLine(prevHiddenLine => !prevHiddenLine),
                iconStyle: {
                    borderColor: displayLine ? '#4d93c9' : '#5c5c5c',
                },
            },
            saveAsImage: {
                show: displayToolbox.current,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: exportName || title,
            },
            myToolExport: {
                show: displayToolbox.current,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    const exportData = data.flatMap(({ name, dataList, unit }) => {
                        return dataList.map(d => ({
                            date: { value: getFullDate(d.date), format: 'dd/MM/yyyy hh:mm:ss', cellType: 'date' },
                            value: { value: d.value, format: '0.00000', cellType: 'number' },
                            name,
                            unit: d.unit ?? unit,
                        }))
                    })
                    exportFile({
                        data: exportData.length ? [
                            {
                                ...exportData[0],
                                headers: ['name', 'date', 'value', 'unit'],
                            },
                            ...exportData.slice(1),
                        ] : [],
                        exportType: 'xlsx',
                        titleFile: exportName || title,
                    }, true)
                },
            },
            myToolFullScreen: {
                show: displayToolbox.current && withFullScreen,
                title: i18n.fullScreen,
                icon: fullScreenIcon,
                onclick: onFullScreen,
            },
            restore: {
                show: displayToolbox.current,
                title: i18n.restore,
            },
        },
    })

    const getComponentHeightCalculated = () => grids.reduce((acc, { gridOptions: { height = DEFAULT_GRAPH_HEIGHT, top = DEFAULT_GAP }, gridIndex }) => {
        if (isGridDisplayed(gridIndex)) {
            return acc + height + top
        }
        return acc
    }, headerHeight + footerHeight)

    const options = {
        title: {
            text: title,
            x: 'center',
            top: '1%',
        },
        series: [...thresholdsSeries, ...markArea, ...series],
        legend: getLegend(),
        xAxis: getXAxisList(),
        yAxis: getYAxisList(),
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: tooltipFormatter ?? formatTooltip,
        },
        grid: getGridList(),
        dataZoom: withDataZoom ? [{
            type: 'slider',
            xAxisIndex: getGridList().map((_, index) => index),
            filterMode: 'none',
        }, {
            bottom: '10px',
            ...dataZoomPosition,
            type: 'inside',
            filterMode: 'filter',
            // handleSize: '80%',
            xAxisIndex: getGridList().map((_, index) => index),
            handleStyle: {
                color: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
            },
        }] : [],
        toolbox: getToolbox(),
        backgroundColor: '#fff',
    }

    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                className={'row no-margin'}
                style={{ height: componentHeight || getComponentHeightCalculated() }}
                ref={e => {
                    echartRef.current = e
                }}
                onEvents={{
                    legendselectchanged: (legend) => {
                        selectedLegend.current = legend.selected
                        echartRef.current?.getEchartsInstance().setOption({
                            legend: getLegend(),
                            xAxis: getXAxisList(),
                            yAxis: getYAxisList(),
                            grid: getGridList(),
                        })
                        echartRef.current.getEchartsInstance().getDom().style.height = componentHeight || getComponentHeightCalculated()
                        onChangeLegend(legend.selected)
                    },
                }}
            />
        </div>
    )
}

MultiChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        gridIndex: PropTypes.number.isRequired,
        unit: PropTypes.string,
        color: PropTypes.string,
        gridName: PropTypes.string.isRequired,
        type: PropTypes.string,
        lineStyle: PropTypes.string,
        showSymbol: PropTypes.bool,
        connectNulls: PropTypes.bool,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.number,
            value: PropTypes.number,
            marker: PropTypes.string,
            color: PropTypes.string,
            unit: PropTypes.string,
        })).isRequired,
    })).isRequired,
    grids: PropTypes.arrayOf(PropTypes.shape({
        gridIndex: PropTypes.number.isRequired,
        type: PropTypes.string,
        barWidth: PropTypes.string,
        yOptions: PropTypes.shape({
            type: PropTypes.string,
            nameLocation: PropTypes.string,
            nameGap: PropTypes.number,
            boundaryGap: PropTypes.bool,
            showSplitLine: PropTypes.bool,
        }),
        xOptions: PropTypes.shape({
            type: PropTypes.string,
            boundaryGap: PropTypes.bool,
            showSplitLine: PropTypes.bool,
            axisLabel: PropTypes.shape({
                show: PropTypes.bool,
            }),
            axisLine: PropTypes.shape({
                show: PropTypes.bool,
            }),
            axisTick: PropTypes.shape({
                show: PropTypes.bool,
            }),
        }),
        gridOptions: PropTypes.shape({
            top: PropTypes.number.isRequired,
            left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            height: PropTypes.number.isRequired,
        }).isRequired,
    })).isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        color: PropTypes.string,
        labelColor: PropTypes.string,
        param: PropTypes.string,
    })),
    title: PropTypes.string,
    exportName: PropTypes.string,
    componentHeight: PropTypes.number,
    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
    roundValue: PropTypes.number,
    areaColor: PropTypes.arrayOf(PropTypes.string), // 1 more than thresholds
    markLines: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),

    xAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
    maxXAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
    defaultDisplayMarker: PropTypes.bool,
    defaultDisplayLine: PropTypes.bool,
    defaultStateThreshold: PropTypes.oneOf([THRESHOLD, AREA, NOTHING]),

    withToolTypeLine: PropTypes.bool,
    withToolTypeBar: PropTypes.bool,
    withToolTypeStack: PropTypes.bool,
    withToolLog: PropTypes.bool,
    withToolThreshold: PropTypes.bool,
    withToolLegend: PropTypes.bool,
    withToolMarker: PropTypes.bool,
    withToolLine: PropTypes.bool,
    withFullScreen: PropTypes.bool,
    withArea: PropTypes.bool,
    withDataZoom: PropTypes.bool,

    dataZoomPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
    }),
    toolboxPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
        right: PropTypes.string,
    }),
    legendPosition: PropTypes.shape({
        bottom: PropTypes.string,
        top: PropTypes.string,
    }),
    tooltipFormatter: PropTypes.func,
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
    onChangeLegend: PropTypes.func,
    defaultSelectedLegend: PropTypes.object,
}

export default MultiChart