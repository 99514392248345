import AEPOverview from 'quality/components/AEPoverview/AEPOverview'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'

const InstallationAEPApp = ({
    id,
}) => {
    const {
        installation,
        associatedSites,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.installation,
        href: 'installation',
    }, {
        title: getStationTitle(installation),
        href: `station/installation/${id}`,
    }, {
        title: i18n.AEPoverview,
        href: `station/installation/${id}/conformityOverview`,
    }], [])

    const ids = useMemo(() => associatedSites.filter(site => site.stationLinkedType === 3).map(site => site.stationLinkedId), [associatedSites])

    return (
        <AEPOverview
            ids={ids}
        />
    )
}

InstallationAEPApp.propTypes = {
    id: PropTypes.string,
}

export default InstallationAEPApp