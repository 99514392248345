import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import { H_PGSSE_MODULE } from '../account/constants/AccessRulesConstants'
import DtoAccountHabilitation from '../account/dto/DtoAccountHabilitation'
import FixedSlideNav from '../components/navbar/FixedSlideNav'
import { PATH_DASHBOARD, PATH_PGSSE, PATH_PGSSE_DANGERS } from '../home/constants/RouteConstants'
import ReferencialAction from '../referencial/action/ReferencialAction'
import { componentHasHabilitations } from '../utils/HabilitationUtil'
import DangersManager from './components/Dangers/DangersManager'
import PGSSEDashboard from './components/dashboard/PGSSEDashboard'

const MaterielApp = ({
}) => {
    const dispatch = useDispatch()
    const {
        accountUser = [],
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const checkAbillitation = () => {
        if (hasValue(accountUser.login)) {
            if (!componentHasHabilitations(H_PGSSE_MODULE)) {
                dispatch(ToastrAction.error(i18n.AccessRightDeny))
                dispatch(push('/'))
            }
        }
    }

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: 'PGSSE',
            href: 'pgsse/dashboard',
        }, {
            title: i18n.dashboard,
            href: 'pgsse/dangers',
        }]))
        dispatch(ReferencialAction.fetchSandreCodes())
    }, [])

    useEffect(() => {
        checkAbillitation()
    }, [accountUser])

    const getLinks = () => {
        return [
            {
                href: `${PATH_PGSSE}/${PATH_DASHBOARD}`,
                icons: 'dashboard',
                name: i18n.dashboard,
                habilitation: H_PGSSE_MODULE,
            },
            {
                href: PATH_PGSSE_DANGERS,
                icons: 'manage_search',
                name: i18n.dangers,
                habilitation: H_PGSSE_MODULE,
            },
        ]
    }

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={getLinks()} />
            <main>
                <div className='row no-margin'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/pgsse' to='/pgsse/dashboard' />
                                <Route path='/pgsse/dashboard' render={(props) => <PGSSEDashboard {...props} />} />
                                <Route path='/pgsse/dangers' render={(props) => <DangersManager {...props} />} />
                            </Switch>
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

MaterielApp.propTypes = {
    habilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
}

export default MaterielApp
