/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogTitle, Grid } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ActionComponent from 'components/ActionComponent'
import Checkbox from 'components/forms/Checkbox'
import Icon from 'components/icon/Icon'
import KeyFigurePanel from 'components/keyFigures/KeyFigurePanel'
import ProgressCard from 'components/card/ProgressCard'
import Row from 'components/react/Row'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import InstallationAction from 'installation/actions/InstallationAction'
import { compact, groupBy, isEqual, orderBy, partition, sumBy, uniq, uniqBy } from 'lodash'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getDate } from 'utils/DateUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { getLogin, getUser } from 'utils/SettingUtils'
import WaitAction from 'wait/WaitAction'
import Table from '../../components/datatable/Table'
import { nbPerPageLabel } from '../../referencial/constants/ReferencialConstants'
import { getExport } from '../../utils/linkUtils'
import { hasValue } from '../../utils/NumberUtil'
import { formatMilliers, getI18nTitleData, getSandreLabel } from '../../utils/StringUtil'
import MaterielStepperModal from './modal/MaterielStepperModal'

const headerPumps = ['nullValue', 'pointPrelevement', 'name', 'codeParcelle', 'pumpValue', 'nature', 'category', 'type', 'sharedCheckbox', 'maxFlow', 'inUseFlow', 'situationDate', 'situationEndDate']
const headerPumpsExport = ['nullValue', 'pointPrelevement', 'name', 'codeParcelle', 'pump', 'nature', 'category', 'type', 'shared', 'maxFlow', 'inUseFlow', 'situationDate', 'situationEndDate']
const headerCounters = ['nullValue', 'pump', 'counterValue', 'type', 'installationDate', 'brand', 'reference', 'readingCoefficient', 'situationDate', 'situationEndDate']
const headerCountersExport = ['nullValue', 'pump', 'counter', 'type', 'installationDate', 'brand', 'reference', 'readingCoefficient', 'situationDate', 'situationEndDate']
const headerPoints = ['nullValue', 'code', 'location', 'codeParcelle']
const headerMats = ['nullValue', 'nullValue4', 'nullValue2', 'reference', 'type', 'nullValue3']

const styleCell = {
    width: '60%',
    textAlign: 'right',
}

const STEP_POINT = 1
const STEP_PUMP = 2
const STEP_COUNTER = 3

const TYPE_LINK_REP_PUMP = 1
const TYPE_LINK_REP_COUNTER = 2

class ExploitationMaterielsApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            idExploitation: parseInt(props.match.params.id),
            dataLoaded: false,
            pointsLoaded: false,
            readMode: true,
            modal: {
                openModalMateriel: false,
                selectedPoint: null,
                selectedPump: null,
                selectedCounter: null,
                previousSituationPump: null,
                previousSituationCounter: null,
                step: 1,
            },
            exploitationPumpsSitu: [],
            exploitationPumpsIds: [],
            exploitationCountersSitu: [],
            exploitationCountersIds: [],
            exploitationPoints: [],
            allExploitationMateriels: [],
            newIdSituation: -1,
            newSituationsPumps: [],
            newSituationsCounters: [],
            situationsToDelete: [],
            materielsToDelete: [],
            openWarningDeleteMatModal: false,
            exploitationPumpsSituHisto: [],
            exploitationCountersSituHisto: [],
            linkMaterielsRep: [],
            showPumpsHisto: false,
            showCountersHisto: false,
        }
    }

    componentDidMount() {
        this.setReadOnlyMode()
        this.props.fetchVariousMaterielTypes().then(() => this.setAllDatas())
    }

    setAllDatas = (forceFetch) => {
        const { variousMateriels } = this.props
        this.props.waitStart()
        if (forceFetch || !variousMateriels.length) {
            this.props.fetchAllMatsRepartitions()
            this.props.fetchVariousMateriels().then(() => {
                this.getExploitation(forceFetch)
            })
        } else {
            this.getExploitation(forceFetch)
        }
    }

    getExploitation = (forceFetch) => {
        const { idExploitation } = this.state
        const { exploitation } = this.props
        if (forceFetch || (exploitation && exploitation.idExploitation !== idExploitation)) {
            this.props.fetchExploitation(idExploitation).then(() => {
                this.setMateriels(this.props.exploitation)
            })
        } else {
            this.setMateriels(exploitation)
        }
    }

    setEditMode = () => {
        const { exploitation } = this.props
        this.setState({ readMode: false })
        const actions = {
            cancel: () => {
                this.setState({ dataLoaded: false, newSituationsPumps: [], newSituationsCounters: [], situationsToDelete: [], materielsToDelete: [] })
                this.setMateriels(exploitation)
                this.setReadOnlyMode()
            },
            save: () => this.onSave(),
            new: () => this.setState(({ modal }) => ({ modal: { ...modal, openModalMateriel: true } })),
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ readMode: true })
        if (!(getUser().consultant === '1')) {
            this.setActions({
                edit: () => this.setEditMode(),
            })
        } else {
            this.setActions({})
        }
    }

    onSave = () => {
        const { materielsToDelete } = this.state
        if (materielsToDelete.length) {
            this.setState({ openWarningDeleteMatModal: true })
        } else {
            this.onSaveConfirm()
        }
    }

    onSaveConfirm = () => {
        const { newSituationsPumps, newSituationsCounters, situationsToDelete, materielsToDelete, linkMaterielsRep } = this.state
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, newSituationsPumps: [], newSituationsCounters: [], situationsToDelete: [], materielsToDelete: [], openWarningDeleteMatModal: false })
        this.setReadOnlyMode()
        this.props.waitStart()
        const eventModel = {
            idExploitation: exploitation.idExploitation,
            associateType: 'materiel',
            eventDate: Date.now(),
            updateLogin: getLogin(),
        }
        const eventsSituationsDelete = situationsToDelete.map((s) => {
            this.props.deleteVariousMaterielSituation(s.idSituation)
            return {
                ...eventModel,
                associateId: s.idMateriel,
                eventType: 'd',
                comment: `${i18n.link} ${s.siteType === 7 ? i18n.point : i18n.pump} ${s.siteName}`,
            }
        })
        const situationsToUpdate = [...newSituationsPumps, ...newSituationsCounters].filter(({ idVariousSituation }) => idVariousSituation >= 0)
        const eventsSituationsUpdate = situationsToUpdate.map((situ) => {
            this.props.updateVariousMaterielSituation(situ.idVariousSituation, situ)
            return {
                ...eventModel,
                associateId: situ.idVarious,
                eventType: 'u',
                comment: `${i18n.link} ${situ.siteType === 7 ? i18n.point : i18n.pump} ${situ.siteName}`,
            }
        })
        this.props.updateExploitation({ ...exploitation, link_repartition_materiels: linkMaterielsRep })
        const situationsToCreate = [...newSituationsPumps, ...newSituationsCounters].filter(({ idVariousSituation }) => idVariousSituation < 0)
        const createGroupByMat = groupBy(situationsToCreate, 'idVarious')
        const filteredSituationToCreate = []
        const eventsSituationsCreate = []
        Object.keys(createGroupByMat).flatMap((idVarious) => {
            this.props.fetchVariousMaterielSituations(idVarious).then(() => {
                const { variousMaterielSituations } = this.props
                const newMatSitus = createGroupByMat[idVarious]
                newMatSitus.map((newS) => {
                    // eslint-disable-next-line max-nested-callbacks
                    if (!variousMaterielSituations.find((s) => s.siteType === newS.siteType &&
                                                            s.siteCode === newS.siteCode &&
                                                            s.situationDate === newS.situationDate &&
                                                            s.situationEndDate === newS.situationEndDate &&
                                                            s.idVarious === newS.idVarious)) {
                        filteredSituationToCreate.push(newS)
                        eventsSituationsCreate.push({
                            ...eventModel,
                            associateId: newS.idVarious,
                            eventType: 'c',
                            comment: `${i18n.link} ${newS.siteType === 7 ? i18n.point : i18n.pump} ${newS.siteName}`,
                        })
                    }
                })
            })
        })
        materielsToDelete.forEach((idMat) => {
            this.props.deleteVariousMateriel(idMat, () => {})
        })
        setTimeout(() => {
            const allEvents = compact([
                ...eventsSituationsDelete,
                ...eventsSituationsUpdate,
                ...eventsSituationsCreate,
            ])
            if (allEvents.length) {
                this.props.createExploitationsEvents(allEvents)
            }
            if (filteredSituationToCreate.length) {
                this.props.createAllVariousMaterielSituation(filteredSituationToCreate).then(() => {
                    this.setAllDatas({ forceFetch: true })
                })
            } else {
                this.setAllDatas({ forceFetch: true })
            }
        }, 5000)
    }


    setMateriels = (exploitation) => {
        const { installationsWithGeo, variousMateriels } = this.props
        // points
        const allExploitationMateriels = variousMateriels.filter(({ administrator }) => administrator === exploitation.operatorCode)
        const linkPointsFiltered = exploitation.link_samplings.filter((p) => !p.endDate || p.endDate > moment().valueOf())
        const linkPointsFilteredHisto = exploitation.link_samplings.filter((p) => p.endDate && p.endDate < moment().valueOf())
        // pumps
        const exploitationPumpsSitu = exploitation.link_materiel.filter((s) =>
            s.siteType === 7 &&
            linkPointsFiltered.find(({ idInstallation }) => idInstallation === s.siteCode) &&
            (!s.situationEndDate || s.situationEndDate >= moment().valueOf()) &&
            !linkPointsFilteredHisto.find((p) => p.idInstallation === s.siteCode)
        )
        const exploitationPumpsSituHisto = exploitation.link_materiel.filter((s) =>
            s.siteType === 7 &&
            ((s.situationEndDate && s.situationEndDate < moment().valueOf()) ||
            linkPointsFilteredHisto.find((p) => p.idInstallation === s.siteCode))
        )
        const exploitationPumpsIds = uniq(exploitationPumpsSitu.map(({ idVarious }) => idVarious))

        const exploitationPumpsIdsHisto = uniq(exploitationPumpsSituHisto.map(({ idVarious }) => idVarious))
        // counters
        const exploitationCountersSitu = exploitation.link_materiel.filter((s) =>
            s.siteType === 8 &&
            (allExploitationMateriels.find(({ id }) => id === s.siteCode) || exploitationPumpsIds.includes(s.siteCode)) &&
            (!s.situationEndDate || s.situationEndDate > moment().valueOf())
        )
        const exploitationCountersSituHisto = exploitation.link_materiel.filter((s) =>
            s.siteType === 8 &&
            (exploitationPumpsIdsHisto.includes(s.siteCode) ||
            (s.situationEndDate && s.situationEndDate < moment().valueOf()))
        )
        const exploitationCountersIds = uniq(exploitationCountersSitu.map(({ idVarious }) => idVarious))

        if (!installationsWithGeo.length) {
            this.props.fetchInstallationsWithGeo().then(() => {
                this.setState({ pointsLoaded: true })
            })
        } else {
            this.setState({ pointsLoaded: true })
        }
        const exploitationPoints = compact(linkPointsFiltered.map((linkPoint) => {
            return installationsWithGeo.find((i) => i.id === linkPoint.idInstallation)
        }))
        this.props.fetchContributor(exploitation.operatorCode).then(() => {
            const { contributor } = this.props
            this.props.setTitle([
                {
                    title: i18n.folders,
                    href: 'dossiers',
                },
                {
                    title: `${contributor.name} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`,
                    href: `dossiers/${exploitation.idExploitation}/dashboard`,
                },
                {
                    title: i18n.materiels,
                    href: `dossiers/${exploitation.idExploitation}/materiels`,
                },
            ])
            this.props.waitStop()
            this.setState({
                dataLoaded: true,
                exploitationPumpsSitu,
                exploitationCountersSitu,
                exploitationPumpsIds,
                exploitationCountersIds,
                exploitationPoints,
                allExploitationMateriels,
                exploitationPumpsSituHisto,
                exploitationCountersSituHisto,
                linkMaterielsRep: exploitation.link_repartition_materiels,
                showPumpsHisto: false,
                showCountersHisto: false,
            })
        })
    }

    getMaterielTypeLabel = (materielType) => {
        const { variousMaterielTypes } = this.props
        const typeFound = variousMaterielTypes.find(({ id }) => id === materielType) || {}
        return typeFound.name || i18n.unknown
    }

    onDeletePumpSitu = (idSituation, idMateriel, siteType, siteName, siteCode) => {
        this.setState(({ situationsToDelete, exploitationPumpsSitu, exploitationPumpsSituHisto, newSituationsPumps, linkMaterielsRep }) => ({
            newSituationsPumps: [ ...newSituationsPumps.filter((s) => s.idVariousSituation !== idSituation) ],
            situationsToDelete: [ ...situationsToDelete, { idSituation, idMateriel, siteName, siteType } ],
            exploitationPumpsSitu: [ ...exploitationPumpsSitu.filter((s) => s.idVariousSituation !== idSituation) ],
            exploitationPumpsSituHisto: [ ...exploitationPumpsSituHisto.filter((s) => s.idVariousSituation !== idSituation) ],
            linkMaterielsRep: linkMaterielsRep.filter((l) => !(l.linkType === TYPE_LINK_REP_PUMP && l.idElement1 === siteCode && l.idElement2 === idMateriel)),
        }))
    }

    getContentPumps = (histo) => {
        const { variousMateriels, sandreCodes, exploitation, installationsWithGeo, citiesIndex, matsRepartitions } = this.props
        const { exploitationPumpsSitu, exploitationPumpsSituHisto, newSituationsPumps, readMode, showPumpsHisto, linkMaterielsRep } = this.state
        const datasPumps = histo ? exploitationPumpsSituHisto : [...exploitationPumpsSitu, ...newSituationsPumps]
        const formattedData = compact(datasPumps.map((situ) => {
            const matFound = variousMateriels.find(({ id }) => id === situ.idVarious) || {}
            if (!histo && hasValue(matFound.administrator) && matFound.administrator !== exploitation.operatorCode) {
                return null
            }
            const pumpInfos = matFound.pump || {}
            const pointFound = installationsWithGeo.find((p) => p.id === situ.siteCode) || {}
            const pumpRep = linkMaterielsRep.find((l) => l.linkType === 1 && l.idElement1 === situ.siteCode && l.idElement2 === situ.idVarious) || {}
            const city = citiesIndex[pointFound.townCode] || {}
            const point = { ...pointFound, city: `${city.code || ''} ${city.name || ''}` }
            const actualRep = sumBy(matsRepartitions.filter((r) => r.idElement1 === situ.siteCode && r.idElement2 === situ.idVarious && r.linkType === 1 && !linkMaterielsRep.some((rTmp) => isEqual({ ...r, repartition: undefined }, { ...rTmp, repartition: undefined }))), 'repartition') || 0
            const tmpRep = sumBy(linkMaterielsRep.filter((r) => r.idElement1 === situ.siteCode && r.idElement2 === situ.idVarious && r.linkType === 1), 'repartition') || 0
            const totalRep = actualRep + tmpRep
            return {
                idVariousSituation: situ.idVariousSituation,
                idVarious: situ.idVarious,
                siteName: situ.siteName,
                siteType: situ.siteType,
                siteCode: situ.siteCode,
                pumpRef: matFound.reference,
                modalData: {
                    point,
                    matFound,
                    situ,
                },
                nullValue: { value: readMode ? null : (<i className='material-icons clickable' onClick={() => this.setupModal(true, point, matFound, null, situ)}>edit</i>) },
                pointPrelevement: { value: situ.siteName },
                name: { value: pointFound.name },
                codeParcelle: { value: `${pointFound.parcel || ''} ${pointFound.section || ''}` },
                pumpValue: { value: (<span {...sieauTooltip(matFound.name, null, 'bottom')}>{matFound.reference}</span>) },
                pump: { value: matFound.reference, tooltip: matFound.name },
                nature: { value: this.getMaterielTypeLabel(matFound.materielType) },
                category: { value: getSandreLabel(sandreCodes, 'MAT.MOBILITE', matFound.mobilityCode) },
                type: { value: getSandreLabel(sandreCodes, 'POMPES.TYPE', pumpInfos.pumpType) },
                sharedCheckbox: { value: hasValue(matFound.isShared) ? (<Checkbox col={ 12 } checked={matFound.isShared} disabled />) : i18n.unknown },
                shared: { value: (!hasValue(matFound.isShared) && i18n.unknown) || (matFound.isShared && i18n.yes) || i18n.no },
                maxFlow: {
                    value: hasValue(pumpInfos.maxFlow) ? `${formatMilliers(pumpInfos.maxFlow) || 0} m3/h` : i18n.unknown,
                    style: styleCell,
                },
                inUseFlow: {
                    value: hasValue(pumpInfos.operatingFlow) ? `${formatMilliers(pumpInfos.operatingFlow) || 0} m3/h` : i18n.unknown,
                    style: styleCell,
                },
                situationDate: { value: getDate(situ.situationDate) },
                situationEndDate: {
                    value: getDate(situ.situationEndDate),
                    style: styleCell,
                },
                division: { value: hasValue(pumpRep.repartition) ? `${pumpRep.repartition} %` : null, color: totalRep > 100 ? 'red' : null, tooltip: totalRep > 100 ? `${i18n.totalOver100Percent} (${totalRep}%)` : null },
            }
        }))
        const actions = readMode ? [getExport(formattedData, `${exploitation.codification} - ${i18n.pumpingEquipmentsUse}`, headerPumpsExport)] : []
        return (
            <Row className='padding-bottom-1'>
                <Table
                    title={histo ? i18n.historisedPumpingEquipments : i18n.pumpingEquipmentsAssignments}
                    data={orderBy(formattedData, ['siteName', 'pumpRef'])}
                    actions={actions}
                    nbPerPageLabel={ nbPerPageLabel }
                    type={{ headers: [...headerPumps, 'division'] }}
                    customHeaders={{
                        pumpValue: i18n.pump,
                        pointPrelevement: i18n.point,
                        sharedCheckbox: i18n.shared,
                        name: (<span style={{ whiteSpace: 'pre-wrap' }}>{`${i18n.name}\n${i18n.point}`}</span>),
                        inUseFlow: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.inUseFlowWrap}</span>),
                        codeParcelle: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.codeParcelleWrap}</span>),
                    }}
                    onClick={(s) => readMode && this.setupModal(true, s.modalData.point, s.modalData.matFound, null, s.modalData.situ)}
                    paging
                    sortable
                    initialSort
                    color
                    deleteTooltip={i18n.deleteAffectation}
                    deletable={!readMode}
                    onDelete={(s) => this.onDeletePumpSitu(s.idVariousSituation, s.idVarious, s.siteType, s.siteName, s.siteCode)}
                    round
                />
                {!histo && (
                    <Button
                        className='margin-top-1 right'
                        variant={showPumpsHisto ? 'outlined' : 'contained'}
                        color='primary'
                        onClick={() => this.setState({ showPumpsHisto: !showPumpsHisto })}
                        {...sieauTooltip(showPumpsHisto ? i18n.clickToHide : i18n.clickToDisplay, null, 'bottom')}
                        disabled={!exploitationPumpsSituHisto.length}
                    >
                        {`${exploitationPumpsSituHisto.length} ${getI18nTitleData(i18n.historisedPumpingEquipment, i18n.historisedPumpingEquipments, exploitationPumpsSituHisto)}`}
                    </Button>
                )}
            </Row>
        )
    }

    onDeleteCounterSitu = (idSituation, idMateriel, siteType, siteName, siteCode) => {
        this.setState(({ exploitationCountersSitu, exploitationCountersSituHisto, situationsToDelete, newSituationsCounters, linkMaterielsRep }) => ({
            newSituationsCounters: [ ...newSituationsCounters.filter((s) => s.idVariousSituation !== idSituation) ],
            situationsToDelete: [ ...situationsToDelete, { idSituation, idMateriel, siteName, siteType } ],
            exploitationCountersSitu: [ ...exploitationCountersSitu.filter((s) => s.idVariousSituation !== idSituation) ],
            exploitationCountersSituHisto: [ ...exploitationCountersSituHisto.filter((s) => s.idVariousSituation !== idSituation) ],
            linkMaterielsRep: linkMaterielsRep.filter((l) => !(l.linkType === TYPE_LINK_REP_COUNTER && l.idElement1 === siteCode && l.idElement2 === idMateriel)),
        }))
    }

    getContentCounters = (histo) => {
        const { variousMateriels, exploitation, matsRepartitions } = this.props
        const { exploitationCountersSitu, exploitationCountersSituHisto, newSituationsCounters, readMode, showCountersHisto, linkMaterielsRep } = this.state
        const datasCounters = histo ? exploitationCountersSituHisto : [...exploitationCountersSitu, ...newSituationsCounters]
        const formattedData = compact(datasCounters.map((situ) => {
            const counterFound = variousMateriels.find(({ id }) => id === situ.idVarious) || {}
            if (!histo && hasValue(counterFound.administrator) && counterFound.administrator !== exploitation.operatorCode) {
                return null
            }
            const pumpFound = variousMateriels.find(({ id }) => id === situ.siteCode) || {}
            const counterRep = linkMaterielsRep.find((l) => l.linkType === 2 && l.idElement1 === situ.siteCode && l.idElement2 === situ.idVarious) || {}
            const counterInfos = counterFound.counter || {}
            const actualRep = sumBy(matsRepartitions.filter((r) => r.idElement1 === situ.siteCode && r.idElement2 === situ.idVarious && r.linkType === 2 && !linkMaterielsRep.some((rTmp) => isEqual({ ...r, repartition: undefined }, { ...rTmp, repartition: undefined }))), 'repartition') || 0
            const tmpRep = sumBy(linkMaterielsRep.filter((r) => r.idElement1 === situ.siteCode && r.idElement2 === situ.idVarious && r.linkType === 2), 'repartition') || 0
            const totalRep = actualRep + tmpRep

            return {
                idVariousSituation: situ.idVariousSituation,
                idVarious: situ.idVarious,
                nullValue: readMode ? null : (<i className='material-icons clickable' onClick={() => this.setupModal(true, null, pumpFound, counterFound, null, situ)}>edit</i>),
                pump: situ.siteName,
                siteType: situ.siteType,
                siteCode: situ.siteCode,
                counterValue: (<span {...sieauTooltip(counterFound.name, null, 'bottom')}>{counterFound.reference}</span>),
                counter: counterFound.reference,
                type: this.getMaterielTypeLabel(counterFound.materielType) || '',
                installationDate: getDate(counterInfos.installationDate),
                brand: counterInfos.brand || '',
                reference: counterFound.serialNumber,
                readingCoefficient: counterInfos.readingCoefficient || '',
                situationDate: getDate(situ.situationDate),
                situationEndDate: getDate(situ.situationEndDate),
                division: totalRep <= 100 ? (hasValue(counterRep.repartition) ? `${counterRep.repartition} %` : null) : { value: hasValue(counterRep.repartition) ? `${counterRep.repartition} %` : null, color: 'red', tooltip: `${i18n.totalOver100Percent} (${totalRep}%)` },
                modalData: {
                    pumpFound,
                    counterFound,
                    situ,
                },
            }
        }))
        const actions = readMode ? [getExport(formattedData, `${exploitation.codification} - ${i18n.countingDevicesUse}`, headerCountersExport)] : []
        return (
            <Row className='padding-bottom-1'>
                <Table
                    title={histo ? i18n.historisedCountingDevices : i18n.countingDevicesAssignments}
                    data={orderBy(formattedData, ['counter', 'pump'])}
                    actions={actions}
                    nbPerPageLabel={ nbPerPageLabel }
                    type={{ headers: [ ...headerCounters, 'division'] }}
                    onClick={(s) => readMode && this.setupModal(true, null, s.modalData.pumpFound, s.modalData.counterFound, null, s.modalData.situ)}
                    customHeaders={{
                        counterValue: i18n.counter,
                        readingCoefficient: i18n.coeff,
                        installationDate: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.installationDateWrap}</span>),
                    }}
                    paging
                    sortable
                    initialSort
                    deletable={!readMode}
                    deleteTooltip={i18n.deleteAffectation}
                    onDelete={(s) => this.onDeleteCounterSitu(s.idVariousSituation, s.idVarious, s.siteType, s.pump, s.siteCode)}
                    round
                />
                {!histo && (
                    <Button
                        className='margin-top-1 right'
                        variant={showCountersHisto ? 'outlined' : 'contained'}
                        color='primary'
                        onClick={() => this.setState({ showCountersHisto: !showCountersHisto })}
                        {...sieauTooltip(showCountersHisto ? i18n.clickToHide : i18n.clickToDisplay, null, 'bottom')}
                        disabled={!exploitationCountersSituHisto.length}
                    >
                        {`${exploitationCountersSituHisto.length} ${getI18nTitleData(i18n.historisedCountingDevice, i18n.historisedCountingDevices, exploitationCountersSituHisto)}`}
                    </Button>
                )}
            </Row>
        )
    }

    setupModal = (openModalMateriel, selectedPoint, selectedPump, selectedCounter, previousSituationPump, previousSituationCounter) => {
        const { exploitationCountersSitu, exploitationPumpsSitu } = this.state
        const { variousMateriels, installationsWithGeo, citiesIndex } = this.props
        const modal = {
            openModalMateriel,
            selectedPump,
            step: (selectedCounter && STEP_COUNTER) || (selectedPump && STEP_PUMP) || STEP_POINT,
        }
        if (selectedPoint && selectedPump && exploitationCountersSitu.filter((s) => s.siteCode === selectedPump.id).length === 1) {
            // récupération du compteur si la pompe récupérée n'est liée qu'à un compteur
            const counterSitu = exploitationCountersSitu.find((s) => s.siteCode === selectedPump.id)
            const counterLinked = variousMateriels.find((c) => c.id === counterSitu.idVarious)
            this.setState({
                modal: {
                    ...modal,
                    selectedPoint,
                    previousSituationPump,
                    selectedCounter: counterLinked,
                    previousSituationCounter: counterSitu,
                },
            })
        } else if (selectedPump && selectedCounter && exploitationPumpsSitu.filter((s) => s.idVarious === selectedPump.id).length === 1) {
            // récupération du point si la pompe récupérée n'est liée qu'à un point
            const pointSitu = exploitationPumpsSitu.find((s) => s.idVarious === selectedPump.id)
            const pointLinked = installationsWithGeo.find((p) => p.id === pointSitu.siteCode) || {}
            const city = citiesIndex[pointLinked.townCode] || {}
            const point = { ...pointLinked, city: `${city.code || ''} ${city.name || ''}` }
            this.setState({
                modal: {
                    ...modal,
                    selectedPoint: point,
                    previousSituationPump: pointSitu,
                    selectedCounter,
                    previousSituationCounter,
                },
            })
        } else {
            this.setState({
                modal: {
                    ...modal,
                    selectedPoint,
                    selectedCounter,
                    previousSituationPump,
                    previousSituationCounter,
                },
            })
        }
    }

    getPointsWithoutMatTable = () => {
        const { exploitation, installationsWithGeo, citiesIndex } = this.props
        const { exploitationPumpsSitu, exploitationPoints, readMode } = this.state
        const idsPointsWithMat = uniqBy(exploitationPumpsSitu.map(({ siteCode }) => siteCode))
        const pointsWithoutMat = exploitationPoints.filter(({ id }) => !idsPointsWithMat.includes(id))
        const points = compact(pointsWithoutMat.map(({ id }) => {
            const pointFound = installationsWithGeo.find((p) => p.id === id) || {}
            const city = citiesIndex[pointFound.townCode] || {}
            const point = { ...pointFound, city: `${city.code || ''} ${city.name || ''}` }
            return {
                nullValue: readMode ? null : (<i className='material-icons clickable' onClick={() => this.setupModal(true, point)}>edit</i>),
                ...point,
                codeParcelle: `${point.parcel || ''} ${point.section || ''}`,
            }
        }))
        const actions = readMode ? [getExport(points, `${exploitation.codification} - ${i18n.pointsWithoutMat}`, headerPoints)] : []
        return (
            <Row noMargin={false}>
                <Table
                    title={i18n.pointsWithoutMat}
                    data={orderBy(points, 'code')}
                    actions={actions}
                    nbPerPageLabel={ nbPerPageLabel }
                    type={{ headers: headerPoints }}
                    onClick={(s) => readMode && this.setupModal(true, s)}
                    paging
                    condensed
                    sortable
                    initialSort
                    round
                />
            </Row>
        )
    }

    onDeleteMateriel = (id) => {
        this.setState(({ materielsToDelete, exploitationPumpsIds, exploitationCountersIds, allExploitationMateriels }) => ({
            exploitationPumpsIds: exploitationPumpsIds.filter((idPump) => idPump !== id),
            exploitationCountersIds: exploitationCountersIds.filter((idCounter) => idCounter !== id),
            allExploitationMateriels: allExploitationMateriels.filter((m) => m.id !== id),
            materielsToDelete: [...materielsToDelete, id],
        }))
    }

    getMatInStock = () => {
        const { exploitation } = this.props
        const { exploitationPumpsIds, exploitationCountersIds, readMode, allExploitationMateriels } = this.state
        const exploitationMaterielsInStock = allExploitationMateriels.filter(({ id }) => ![ ...exploitationPumpsIds, ...exploitationCountersIds ].includes(id))
        const materielsFormatted = compact(exploitationMaterielsInStock.map((m) => {
            if (hasValue(m.administrator) && m.administrator !== exploitation.operatorCode) {
                return null
            }
            const type = (m.pump && i18n.pumpingEquipment) || (m.counter && i18n.countingDevice) || i18n.unknown
            return {
                nullValue4: readMode ? null : (<i className='material-icons clickable' {...sieauTooltip(i18n.deleteMaterial, null, 'left')} onClick={() => this.onDeleteMateriel(m.id)}>delete</i>),
                nullValue2: readMode ? null : (<i className='material-icons clickable' onClick={() => this.setupModal(true, null, m.pump ? m : null, m.counter ? m : null)}>edit</i>),
                ...m,
                type,
            }
        }))
        const actions = readMode ? [getExport(materielsFormatted, `${exploitation.codification} - ${i18n.materielsWithoutAssignment}`, headerMats)] : []
        return (
            <Row noMargin={false}>
                <Table
                    title={i18n.materielsWithoutAssignment}
                    data={orderBy(materielsFormatted, 'reference')}
                    actions={actions}
                    nbPerPageLabel={ nbPerPageLabel }
                    type={{ headers: readMode ? headerMats.filter((h) => !['nullValue', 'nullValue4', 'nullValue2'].includes(h)) : headerMats }}
                    onClick={(m) => readMode && this.setupModal(true, null, m.pump ? m : null, m.counter ? m : null)}
                    paging
                    condensed
                    sortable
                    initialSort
                    round
                />
            </Row>
        )
    }

    getMatShared = () => {
        const { exploitation, variousMateriels, contributors } = this.props
        const { exploitationPumpsIds, exploitationCountersIds, readMode, linkMaterielsRep, exploitationPumpsSitu, exploitationCountersSitu, situationsToDelete } = this.state
        const exploitationMaterielsShared = compact([...exploitationPumpsIds, ...exploitationCountersIds].map((mId) => variousMateriels.find((m) => m.id === mId)))
        const materielsFormatted = compact(exploitationMaterielsShared.map((m) => {
            if (hasValue(m.administrator) && m.administrator !== exploitation.operatorCode) {
                const type = (m.pump && i18n.pumpingEquipment) || (m.counter && i18n.countingDevice) || i18n.unknown
                const contributor = contributors.find((c) => c.id === m.administrator) || {}
                return {
                    nullValue: readMode ? null : (
                        <i
                            className='material-icons clickable'
                            {...sieauTooltip(i18n.deleteAffectation, null, 'left')}
                            onClick={() => {
                                if (m.pump) {
                                    const [situToKeep, situToDelete] = partition(exploitationPumpsSitu, (s) => s.idVarious !== m.id)
                                    this.setState(({ newSituationsPumps }) => ({
                                        newSituationsPumps: [ ...newSituationsPumps.filter((s) => s.idVarious !== m.id) ],
                                        situationsToDelete: [ ...situationsToDelete, ...situToDelete.map((s) => ({ ...s, idSituation: s.idVariousSituation, idMateriel: s.idVarious })) ],
                                        exploitationPumpsIds: exploitationPumpsIds.filter((id) => id !== m.id),
                                        exploitationPumpsSitu: situToKeep,
                                        linkMaterielsRep: linkMaterielsRep.filter((l) => !(l.linkType === TYPE_LINK_REP_PUMP && l.idElement2 === m.id)),
                                    }))
                                } else {
                                    const [situToKeep, situToDelete] = partition(exploitationCountersSitu, (s) => s.idVarious !== m.id)
                                    this.setState(({ newSituationsCounters }) => ({
                                        newSituationsCounters: [ ...newSituationsCounters.filter((s) => s.idVarious !== m.id) ],
                                        situationsToDelete: [ ...situationsToDelete, ...situToDelete.map((s) => ({ ...s, idSituation: s.idVariousSituation, idMateriel: s.idVarious })) ],
                                        exploitationCountersIds: exploitationCountersIds.filter((id) => id !== m.id),
                                        exploitationCountersSitu: situToKeep,
                                        linkMaterielsRep: linkMaterielsRep.filter((l) => !(l.linkType === TYPE_LINK_REP_COUNTER && l.idElement2 === m.id)),
                                    }))
                                }
                            }}
                        >
                            close
                        </i>
                    ),
                    nullValue4: readMode ? null : (<i className='material-icons clickable' {...sieauTooltip(i18n.deleteMaterial, null, 'left')} onClick={() => this.onDeleteMateriel(m.id)}>delete</i>),
                    nullValue2: readMode ? null : (<i className='material-icons clickable' onClick={() => this.setupModal(true, null, m.pump ? m : null, m.counter ? m : null)}>edit</i>),
                    ...m,
                    type,
                    nullValue3: <i className='material-icons clickable' {...sieauTooltip(contributor.mnemonique || contributor.name, null, 'left')}>person</i>,
                }
            }
            return null
        }))
        const actions = readMode ? [getExport(materielsFormatted, `${exploitation.codification} - ${i18n.materielsWithoutAssignment}`, headerMats)] : []
        return (
            <Row noMargin={false}>
                <Table
                    title={i18n.sharedMaterials}
                    data={orderBy(materielsFormatted, 'reference')}
                    actions={actions}
                    nbPerPageLabel={ nbPerPageLabel }
                    type={{ headers: readMode ? headerMats.filter((h) => !['nullValue', 'nullValue4', 'nullValue2'].includes(h)) : headerMats }}
                    onClick={(m) => readMode && this.setupModal(true, null, m.pump ? m : null, m.counter ? m : null)}
                    paging
                    condensed
                    sortable
                    initialSort
                    round
                />
            </Row>
        )
    }

    getModalMateriel = () => {
        const { exploitation } = this.props
        const { modal, readMode, exploitationPoints, allExploitationMateriels, linkMaterielsRep } = this.state
        const { openModalMateriel, selectedPoint, selectedPump, selectedCounter, previousSituationPump, previousSituationCounter, step } = modal
        return (
            <MaterielStepperModal
                title={readMode ? `${i18n.pointPrelevement} ${selectedPoint ? selectedPoint.code : ''}` : ''}
                point={selectedPoint}
                pump={selectedPump}
                counter={selectedCounter}
                step={step}
                points={exploitationPoints}
                situationPump={previousSituationPump}
                situationCounter={previousSituationCounter}
                open={openModalMateriel}
                variousMateriels={allExploitationMateriels}
                exploitation={exploitation}
                linkMaterielsRep={linkMaterielsRep}
                onCancel={() => this.setupModal(false)}
                onValidate={this.onValidateSituation}
                disabled={readMode}
            />
        )
    }

    onValidateSituation = (pumpSituation, counterSituation, linksMatRepUpdated) => {
        const { newIdSituation, newSituationsPumps, newSituationsCounters, exploitationPumpsSitu, exploitationCountersSitu, modal } = this.state
        const { previousSituationPump, previousSituationCounter } = modal
        const { exploitation, variousMateriels } = this.props
        this.setState({ dataLoaded: false, linkMaterielsRep: linksMatRepUpdated })
        const allExploitationMateriels = variousMateriels.filter(({ administrator }) => administrator === exploitation.operatorCode)
        // const pumpSituExist = exploitationPumpsSitu.find((s) => s.siteCode === pumpSituation.siteCode &&
        //     s.siteType === pumpSituation.siteType &&
        //     s.idVarious === pumpSituation.idVarious &&
        //     s.situationDate === pumpSituation.situationDate &&
        //     s.situationEndDate === pumpSituation.situationEndDate)
        // const counterSituExist = exploitationCountersSitu.find((s) => s.siteCode === counterSituation.siteCode &&
        //     s.siteType === counterSituation.siteType &&
        //     s.idVarious === counterSituation.idVarious &&
        //     s.situationDate === counterSituation.situationDate &&
        //     s.situationEndDate === counterSituation.situationEndDate)
        // if just update a pump or a counter
        if (!pumpSituation.idVarious && !counterSituation.idVarious) {
            this.setState({
                dataLoaded: true,
                allExploitationMateriels,
            })
        // if link point -> pump
        } else if (pumpSituation.siteCode && pumpSituation.idVarious) {
            const pumpSituationFormatted = {
                ...previousSituationPump,
                ...pumpSituation,
                idVariousSituation: previousSituationPump?.idVariousSituation || newIdSituation,
            }
            // if link pump -> counter
            if (counterSituation.siteCode && counterSituation.idVarious) {
                const counterSituationFormatted = {
                    ...previousSituationCounter,
                    ...counterSituation,
                    idVariousSituation: previousSituationCounter?.idVariousSituation || newIdSituation - 1,
                }
                this.setState({
                    exploitationCountersSitu: [...exploitationCountersSitu.filter(({ idVariousSituation }) => idVariousSituation !== counterSituationFormatted.idVariousSituation)],
                    newSituationsCounters: [
                        ...newSituationsCounters.filter(({ idVariousSituation }) => idVariousSituation !== counterSituationFormatted.idVariousSituation),
                        counterSituationFormatted,
                    ],
                    newIdSituation: newIdSituation - 2,
                })
            // if no link pump -> counter
            } else {
                this.setState({ newIdSituation: newIdSituation - 1 })
            }
            this.setState({
                dataLoaded: true,
                allExploitationMateriels,
                exploitationPumpsSitu: [...exploitationPumpsSitu.filter(({ idVariousSituation }) => idVariousSituation !== pumpSituationFormatted.idVariousSituation)],
                newSituationsPumps: [
                    ...newSituationsPumps.filter(({ idVariousSituation }) => idVariousSituation !== pumpSituationFormatted.idVariousSituation),
                    pumpSituationFormatted,
                ],
            })
        // if no point, just link pump -> counter
        } else if (counterSituation.siteCode && counterSituation.idVarious) {
            const counterSituationFormatted = {
                ...previousSituationCounter,
                ...counterSituation,
                idVariousSituation: previousSituationCounter?.idVariousSituation || newIdSituation,
            }
            this.setState({
                exploitationCountersSitu: [...exploitationCountersSitu.filter(({ idVariousSituation }) => idVariousSituation !== counterSituationFormatted.idVariousSituation)],
                newSituationsCounters: [
                    ...newSituationsCounters.filter(({ idVariousSituation }) => idVariousSituation !== counterSituationFormatted.idVariousSituation),
                    counterSituationFormatted,
                ],
                newIdSituation: newIdSituation - 1,
                dataLoaded: true,
                allExploitationMateriels,
            })
        // if just update pump or counter
        } else {
            this.setState({
                dataLoaded: true,
                allExploitationMateriels,
            })
        }
        this.setupModal(false)
    }

    getKeyFigureDatas = () => {
        const { exploitationPumpsIds, exploitationCountersIds } = this.state
        return [{
            title: i18n.pumpingEquipmentsUse,
            tooltip: i18n.pumpingEquipmentsUse,
            value: exploitationPumpsIds.length,
        }, {
            title: i18n.countingDevicesUse,
            tooltip: i18n.countingDevicesUse,
            value: exploitationCountersIds.length,
        }]
    }

    getWarningDeleteMatModal = () => (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Icon style={{ fontSize: '2.5rem', marginRight: 8 }}>warning</Icon>
                    <Grid item>{i18n.deleteMaterial}</Grid>
                </Grid>
            </DialogTitle>
            <div className='padding-1'>
                <p id='modal-text' style={{ whiteSpace: 'pre-wrap' }}>
                    {i18n.deleteMaterialInfo}
                </p>
            </div>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button variant='contained' color='primary' onClick={() => this.setState({ openWarningDeleteMatModal: false })}>
                    {i18n.cancel}
                </Button>
                <h5 id='modal-description' className='bold' style={{ color: 'red' }}>{i18n.irreversibleAction}</h5>
                <Button variant='contained' className='red' style={{ color: 'white' }} onClick={this.onSaveConfirm}>
                    {i18n.confirm}
                </Button>
            </DialogActions>
        </Dialog>

    )

    render() {
        const { dataLoaded, pointsLoaded, modal, openWarningDeleteMatModal, showPumpsHisto, showCountersHisto } = this.state
        if (dataLoaded && pointsLoaded) {
            return (
                <div className='padding-1'>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            {this.getContentPumps()}
                            {showPumpsHisto && this.getContentPumps(true)}
                            {this.getContentCounters()}
                            {showCountersHisto && this.getContentCounters(true)}
                        </Grid>
                        <Grid item xs={3}>
                            <Row noMargin={false}>
                                <KeyFigurePanel data={this.getKeyFigureDatas()} showAllValues singleLine noMargin round/>
                            </Row>
                            {this.getPointsWithoutMatTable()}
                            {this.getMatInStock()}
                            {this.getMatShared()}
                        </Grid>
                        {modal.openModalMateriel && this.getModalMateriel()}
                        {openWarningDeleteMatModal && this.getWarningDeleteMatModal()}
                    </Grid>
                </div>
            )
        }
        return (
            <div className='padding-1'>
                <ProgressCard indeterminate round />
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        contributor: store.ContributorReducer.contributor,
        contributors: store.ContributorReducer.contributors,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        surveys: store.AgriReducer.surveys,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        variousMaterielSituations: store.VariousMaterielReducer.variousMaterielSituations,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        matsRepartitions: store.AgriReducer.matsRepartitions,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    updateExploitation: AgriAction.updateExploitation,
    fetchContributor: ContributorAction.fetchContributor,
    fetchInstallationsWithGeo: InstallationAction.fetchInstallationsWithGeo,
    fetchVariousMateriels: VariousMaterielAction.fetchVariousMateriels,
    fetchVariousMaterielSituations: VariousMaterielAction.fetchVariousMaterielSituations,
    createAllVariousMaterielSituation: VariousMaterielAction.createAllVariousMaterielSituation,
    updateVariousMaterielSituation: VariousMaterielAction.updateVariousMaterielSituation,
    deleteVariousMaterielSituation: VariousMaterielAction.deleteVariousMaterielSituation,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    deleteVariousMateriel: VariousMaterielAction.deleteVariousMateriel,
    createExploitationsEvents: AgriAction.createExploitationsEvents,
    fetchAllMatsRepartitions: AgriAction.fetchAllMatsRepartitions,
    setTitle: HomeAction.setTitle,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    info: ToastrAction.info,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploitationMaterielsApp)
