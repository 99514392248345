import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Job from '../../../import/dto/DtoJob'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import JobAlert from './JobAlert'
import { MEASURE_COTE } from '../../../piezometry/constants/PiezometryConstants'


class AlertPiezo extends Component {
    componentDidMount() {
        this.props.fetchAllPiezometers()
        this.props.fetchAllPiezometerThreshold(MEASURE_COTE.DEPTH)
    }

    render() {
        return (
            <JobAlert
                job={this.props.job}
                isEditMode={this.props.isEditMode}
                stations={this.props.piezometers}
                thresholds={this.props.piezometerThresholds}
                onChangeEmails={this.props.onChangeEmails}
                onChangeContactsIds={this.props.onChangeContactsIds}
                onChangeStation={this.props.onChangeStation}
                onChangeAlertTypes={this.props.onChangeAlertTypes}
            />
        )
    }
}

AlertPiezo.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    piezometers: PropTypes.arrayOf(PropTypes.object),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.object),
    fetchAllPiezometers: PropTypes.func,
    fetchAllPiezometerThreshold: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometers: store.PiezometryReducer.piezometers.map(({ id, code, name }) => ({
        id,
        code,
        name: `[${code}] ${name}`,
    })),
    piezometerThresholds: store.PiezometerStationReducer.allPiezometerThresholds.map(({ id, code, value, title }) => ({
        id,
        code,
        title: `${title}: ${value}m NGF`,
    })),
})

const mapDispatchToProps = {
    fetchAllPiezometers: PiezometryAction.fetchAllPiezometers,
    fetchAllPiezometerThreshold: PiezometerStationAction.fetchAllPiezometerThreshold,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertPiezo)