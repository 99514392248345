import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoCasingDeclaration from 'survey/dto/DtoCasingDeclaration'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getSandreLabel } from 'utils/StringUtil'

class CounterCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            counter: props.counter,
        }
    }

    render() {
        const { title, actions, variousMaterielTypes, sandreCodes } = this.props
        const { counter } = this.state
        const counterType = variousMaterielTypes.find((t) => t.materielType === counter.materielType && t.category === 2) || {}

        return (
            <Card
                title={title}
                headerStyle={{ backgroundColor: '#c7c6c6' }}
                actions={actions}
                className='row no-margin padding-bottom-1'
                cardStyle={{
                    border: `1px solid ${secondaryBlue}`,
                    borderWidth: '0 2 2 2',
                }}
            >
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.name}
                    value={counter.name}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.type}
                    value={counterType.name}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.brand}
                    value={counter.brand}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.serialNumber}
                    value={counter.serialNumber}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.readingCoefficient}
                    value={counter.readingCoefficient}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.mobility}
                    value={getSandreLabel(sandreCodes, 'MAT.MOBILITE', counter.mobilityCode)}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.installationDate}
                    value={getDate(counter.installationDate)}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.revisionDate}
                    value={getDate(counter.revisionDate)}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.shared}
                    value={counter.isShared ? i18n.yes : i18n.no}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.forRent}
                    value={counter.rent ? i18n.yes : i18n.no}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.assignmentRate}
                    value={hasValue(counter.assignmentRate) ? `${counter.assignmentRate} %` : ''}
                    readMode
                />
                <Textarea
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.comment}
                    value={counter.comment}
                    readMode
                />
            </Card>
        )
    }
}

CounterCard.propTypes = {
    title: PropTypes.string.isRequired,
    counter: PropTypes.instanceOf(DtoCasingDeclaration).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        iconName: PropTypes.string,
    })),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

CounterCard.defaultProps = {
    editable: false,
    counter: {},
}

const mapStateToProps = (store) => {
    return {
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

export default connect(mapStateToProps)(CounterCard)
