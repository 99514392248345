import PropTypes from 'prop-types'
import React, { Component } from 'react'
import PiezometerEventForm from '../../../piezometry/components/event/PiezometerEventForm'
import StationEventApp from './StationEventApp'

class StationEventTypeDispatcher extends Component {
    render() {
        if (this.props.match.params.type === 'piezometry') {
            return <PiezometerEventForm params={this.props.match.params} location={this.props.location} />
        }
        return <StationEventApp params={this.props.match.params} location={this.props.location} />
    }
}

StationEventTypeDispatcher.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
        }).isRequired,
    }),
    location: PropTypes.shape({
        search: PropTypes.object,
    }).isRequired,
}

export default StationEventTypeDispatcher