import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import DisplayedValue from './DisplayedValue'
import { isEqualBy, sieauTooltip } from '../../utils/FormUtils'
import i18n from 'simple-react-i18n'
import { searchAllCharacters } from '../../utils/StringUtil'


class Input extends Component {
    constructor(props) {
        super(props)
        this.state = { id: props.id || searchAllCharacters(props.title + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'), editing: false }
    }

    onChangeValue = (event) => {
        if (this.props.onEnterKeyPress && (event.key === 'Enter')) {
            this.props.onEnterKeyPress(event.target.value)
            if (this.state.editing) {
                this.setState({ editing: false })
            }
        }
    }

    onBlur = () => {
        if (this.props.tableEditable && this.state.editing) {
            this.props.onEnterKeyPress($(`#${this.state.id}`).val())
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value && !nextProps.value) {
            $(`#${this.state.id}`).val('')
        }
    }

    onTableEditing = () => {
        this.setState({ editing: true }, () => {
            $(`#${this.state.id}`).focus()
            $(`#${this.state.id}`).blur(() => this.setState({ editing: false }))
        })
    }

    getClearFunction = () => {
        if (this.props.clearFunction) {
            return (
                <i data-clear-input
                    className={`material-icons clickable right ${this.props.disabled ? 'grey-text' : ''}`}
                    onClick={() => this.onClear()}
                >
                    clear
                </i>
            )
        }
        return null
    }

    onClear = () => {
        if (!this.props.disabled) {
            if (this.props.onEnterKeyPress) {
                this.props.onEnterKeyPress('')
            } else if (this.props.keyObj && this.props.changeObj) {
                this.props.changeObj({ [this.props.keyObj]: '', LAST_FORM: `INPUT-${uuidv4()}` })
            } else if (this.props.onChange) {
                this.props.onChange('')
            }
            $(`#${this.state.id}`).val('')
        }
    }

    callOnChange = (value) => {
        const val = this.props.noSpace ? value.replaceAll(' ', '') : value
        $(`#${this.state.id}`).val(val)

        if (this.props.keyObj && this.props.changeObj) {
            this.props.changeObj({ [this.props.keyObj]: val, LAST_FORM: `INPUT-${uuidv4()}` })
        }
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }

    getInput = () => {
        if (this.props.readMode || (this.props.tableEditable && !this.state.editing)) {
            return <DisplayedValue label={ this.props.title } obligatory={this.props.obligatory} value={ this.props.value } hideNull={ this.props.hideNull } onClick={ this.onTableEditing } tableEditable={ this.props.tableEditable }/>
        }
        const disabled = { readOnly: this.props.disabled }
        const dropDown = this.props.dropDownId ? { 'data-position': 'bottom', 'data-activates': this.props.dropDownId } : {}
        return (
            <div className={ `${(this.props.noInputFieldClass ? '' : 'input-field') + (this.props.clearFunction ? ' clearable-input' : '')} ${this.props.containerClassName}` } style={this.props.containerStyle} >
                { this.getClearFunction() }
                <input
                    style={ this.props.style }
                    type={ this.props.passwordType ? 'password' : 'text' }
                    className={ `sieau-input form-control input-sm ${this.props.className}` }
                    data-mode={this.state.id}
                    id={this.state.id}
                    onKeyPress={ e => this.onChangeValue(e) }
                    onChange={ e => this.callOnChange(e.target.value) }
                    placeholder={ this.props.placeholder }
                    { ...(this.props.tooltip ? sieauTooltip(this.props.tooltip, this.state.id, 'bottom') : null) }
                    maxLength={ this.props.maxLength }
                    onBlur={ this.onBlur }
                    {...this.props.otherInputProps}
                    {...dropDown}
                    {...disabled}
                    data-cy={this.props['data-cy']}
                />
                <label htmlFor={this.state.id} >
                    {this.props.title}
                    { this.props.obligatory && <span className='primary-color-text'>{ i18n.obligatoryField }</span> }
                </label>
            </div>
        )
    }

    render() {
        return this.props.col ? (
            <div className={ `col s${this.props.col} ${this.props.containerClassName}` } >
                { this.getInput() }
            </div>
        ) : this.getInput()
    }

    componentDidMount() {
        if (this.props.value) {
            $(`#${this.state.id}`).val(this.props.value)
        }
    }

    componentDidUpdate() {
        if (this.props.value) {
            $(`#${this.state.id}`).val(this.props.value)
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.freezeOpti && isEqualBy(this.props, nextProps, ['value', 'col', 'disabled', 'readMode'])) {
            return false
        }
        return true
    }
}

Input.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    value: PropTypes.string,
    col: PropTypes.number,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    passwordType: PropTypes.bool,
    noInputFieldClass: PropTypes.bool,
    dropDownId: PropTypes.string,
    placeholder: PropTypes.string,
    otherInputProps: PropTypes.object,
    style: PropTypes.object,
    containerStyle: PropTypes.object,
    readMode: PropTypes.bool,
    hideNull: PropTypes.bool,
    tableEditable: PropTypes.bool,
    maxLength: PropTypes.number,
    clearFunction: PropTypes.bool,
    id: PropTypes.string,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
    obligatory: PropTypes.bool,
    noSpace: PropTypes.bool,

    'data-cy': PropTypes.string,
}

Input.defaultProps = {
    onChange: () => {},
    style: {},
    otherInputProps: {},
    readMode: false,
    clearFunction: false,
    className: '',
    title: '',
}

export default Input
