import { groupBy, max, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import Table from '../../../../components/datatable/Table'
import Button from '../../../../components/forms/Button'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Icon from '../../../../components/icon/Icon'
import Row from '../../../../components/react/Row'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import DtoPiezoThreshold from '../../../../station/dto/piezometer/DtoPiezoThreshold'
import AppStore from '../../../../store/AppStore'
import {
    getThresholdColorCode,
    getThresholdColorCodes,
    getThresholdColorHtml,
    pickColorModal
} from '../../../../utils/ColorUtil'
import { hasValue, round } from '../../../../utils/NumberUtil'
import { getMeasureValue, getNGFValue } from '../../../../utils/PiezometryUtils'
import PiezometryAction from '../../../actions/PiezometryAction'
import DtoParametrageDataType from '../../../dto/DtoParametrageDataType'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import DtoPiezometerThresholdItem from '../../../dto/DtoPiezometerThresholdItem'
import { WhiteCard } from '../../../../components/styled/Card'
import { darkBlue } from '../../../../utils/constants/ColorTheme'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import ColorPicker from '../../../../components/forms/ColorPicker'
import { MEASURE_COTE } from '../../../constants/PiezometryConstants'

class ThresholdsToolPanel extends Component {
    state = {
        validateActive: false,
    }

    getColorItem = t => {
        const color = getThresholdColorCode(t.color)
        return <span className='arrests-level-panel' style={ { color, 'background-color': color } } onClick={ () => pickColorModal(getThresholdColorCodes(), c => this.onChangeThreshold(t, { color: c })) }>O</span>
    }

    setValidateActive = (value) => {
        const { validateActive } = this.state
        if (value && !validateActive) {
            this.setState({ validateActive: true })
            this.props.changeParent({ locked: true })
        } else if (!value) {
            this.setState({ validateActive: false })
            this.props.changeParent({ locked: false })
        }
    }

    onAddThreshold = (dataType) => {
        this.setValidateActive(true)
        const id = max(this.props.piezometerThresholds.map(t => t.id).concat([0])) + 1
        this.props.changeParent({ piezometerThresholds: orderBy([
            ...this.props.piezometerThresholds,
            { id, dataType: dataType.toString(), color: undefined, value: undefined, name: undefined, code: this.props.piezometer.code, isNew: true },
        ], 'id', ['desc']) })
    }

    onChangeThreshold = (threshold, changes) => {
        this.setValidateActive(true)
        const others = this.props.piezometerThresholds.filter(t => t.id !== threshold.id)
        const oldValue = threshold.oldValue ? threshold.oldValue : changes.value
        const updatedThresholds = [
            ...others,
            { ...threshold, ...changes, oldValue, isUpdated: !threshold.isNew },
        ]
        this.props.changeParent({ piezometerThresholds: orderBy(updatedThresholds, 'id', ['desc']) })
    }

    onDeleteThreshold = (threshold) => {
        this.setValidateActive(true)
        const piezometerThresholds = [
            ...this.props.piezometerThresholds.filter(d => d.id !== threshold.id),
            { ...this.props.piezometerThresholds.find(d => d.id === threshold.id), isDeleted: true },
        ]
        this.props.changeParent({
            piezometerThresholds: orderBy(piezometerThresholds, 'id', ['desc']),
        })
    }

    onSave = () => {
        if (this.props.piezometerThresholds.some(t => !hasValue(t.name) || !hasValue(t.value))) {
            AppStore.dispatch(ToastrAction.warning(i18n.pleaseChooseANameAndValue))
        } else {
            this.setValidateActive(false)
            const piezometerThresholds = this.props.piezometerThresholds.filter(d => !d.isDeleted)
            this.props.updatePiezometerThresholds(this.props.id, piezometerThresholds, () => {}, MEASURE_COTE.NGF).then(() => {
                this.props.createTechnicalEvent()
                AppStore.dispatch(PiezometryAction.refreshOnePiezometerSituation(this.props.piezometer.id, this.props.selectedDataType))
                this.props.fetchPiezometerThresholds(this.props.id, MEASURE_COTE.NGF)
            })
        }
    }

    getTable = (thresholds) => {
        const data = orderBy(thresholds, 'id').map(t => ({
            name: <Input value={ t.name } onChange={ v => this.onChangeThreshold(t, { name: v }) }/>,
            overrun: <Checkbox checked={ t.isOverrunThreshold === '1' } onChange={ v => this.onChangeThreshold(t, { isOverrunThreshold: v ? '1' : '0' }) } />,
            color: (
                <ColorPicker
                    value={getThresholdColorHtml(t)}
                    style={{
                        width: '2rem',
                        cursor: 'pointer',
                        backgroundColor: getThresholdColorHtml(t),
                    }}
                    onChange={(color) => this.onChangeThreshold(t, { htmlColor: color }) }
                    noLabel
                />
            ),
            level: <NumberField
                value={ this.props.piezoMode ? round(getMeasureValue(
                    { NGF: t.value, landmark: this.props.lastLandmark, refAlti: 0 },
                    this.props.displayCote,
                    this.props.lastLandmark,
                    this.props.groundRefAlti
                )) : t.value }
                floatValue
                onChange={ v =>
                    this.onChangeThreshold(t, { value: this.props.piezoMode ? getNGFValue({ value: v }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti) : v })
                }
            />,
            nullValue: <Icon icon='clear' tooltip={ i18n.delete } onClick={ () => this.onDeleteThreshold(t) } />,
        }))
        const content = data.length ? (
            <Table smallTitle showTitle={ false } data={ data } sortable paging type={ new DtoPiezometerThresholdItem({}) } condensed nbPerPageLabel={ nbPerPageLabelShort } />
        ) : (
            <Card><h5 className='padding-1 no-margin'>{ i18n.noThresholds }</h5></Card>
        )

        return content
    }

    onCancel = () => {
        this.setValidateActive(false)
        this.props.onCancel()
    }

    render() {
        const group = groupBy(this.props.piezometerThresholds.filter(t => !t.isDeleted), t => t.dataType || '-1')
        const actions = [{
            iconName: 'note_add',
            color: darkBlue,
            tooltip: i18n.new,
            onClick: () => this.onAddThreshold(this.props.selectedDataType),
        }]
        return (
            <WhiteCard title={ i18n.thresholds } actions={ actions } round>
                { this.getTable(group[this.props.selectedDataType]) }
                <ul className='sieau-collapsible popout' data-collapsible='expandable'>
                    <Row className='padding-bottom-1 padding-top-1 center-align'>
                        <Button tooltip={ i18n.cancel } onClick={ this.onCancel } icon='cancel' className='red btn-floating btn-large margin-left-2 margin-right-2' disabled={ !this.state.validateActive }/>
                        <Button tooltip={ i18n.register } onClick={ this.onSave } icon='save' disabled={ !this.state.validateActive } className={ `btn-floating btn-large ${this.state.validateActive ? 'pulse' : ''}` }/>
                    </Row>
                </ul>
            </WhiteCard>
        )
    }
}

ThresholdsToolPanel.propTypes = {
    id: PropTypes.string,
    displayCote: PropTypes.number,
    lastLandmark: PropTypes.number,
    groundRefAlti: PropTypes.number,
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    changeParent: PropTypes.func,
    onCancel: PropTypes.func,
    updatePiezometerThresholds: PropTypes.func,
    createTechnicalEvent: PropTypes.func,
    fetchPiezometerThresholds: PropTypes.func,
    piezoMode: PropTypes.bool,
    selectedDataType: PropTypes.number,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})

const mapDispatchToProps = {
    updatePiezometerThresholds: PiezometerStationAction.updatePiezometerThresholds,
    fetchPiezometerThresholds: PiezometerStationAction.fetchPiezometerThresholds,
}

export default connect(mapStateToProps, mapDispatchToProps)(ThresholdsToolPanel)