import React from 'react'
import georisques from 'assets/pictures/georisques.png'
import logoInsee from 'assets/pictures/logo-insee.png'
import eaufrance from 'assets/pictures/logos/eaufrance.jpg'
import googleMap from 'assets/pictures/logos/googleMap.png'
import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import InstallationAction from 'installation/actions/InstallationAction'
import { template } from 'lodash'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import PropTypes from 'prop-types'
import QualityAction from 'quality/actions/QualityAction'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getValidCodeInput, getValidNameInsee } from 'utils/StringUtil'
import { v4 as uuidv4 } from 'uuid'
import Card from '../../../../components/card/Card'
import Table from '../../../../components/datatable/Table'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import SieauAction from '../../../../components/sieau/SieauAction'
import Textarea from '../../../../components/forms/Textarea'
import {
    eaufranceCityLink,
    georisquesCityLink,
    googleMapsSearchPath,
    inseeCityLink,
} from '../../../../conf/SieauConstants'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CITY,
    PATH_REFERENCIAL_CITY_LINK,
    PATH_REFERENCIAL_CITY_NEW,
} from '../../../../home/constants/RouteConstants'
import { getUser } from '../../../../utils/SettingUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import { FR, SANDRE } from '../../../constants/ReferencialConstants'
import CountryAction from '../../country/actions/CountryAction'
import DeleteModal from '../../DeleteModal'
import ReplaceModal from '../../ReplaceModal'
import CityAction from '../actions/CityAction'
import CityDto from '../dto/CityDto'

const typeCedex = { headers: ['cedex'] }
const typeCityHisto = { headers: ['cityCode', 'name'] }

const CityApp = ({ match: { params: { code } } }) => {
    const {
        cityProps,
        cities,
        sandreCodes,
        cityCedex,
        countries,
        citiesHisto,
        qualitometersLight,
        piezometersLight,
        installationsLight,
        productionUnits,
        pluviometers,
        hydrometricStations,
        contacts,
        contributors,
    } = useSelector(store => ({
        cityProps: store.CityReducer.city,
        cities: store.CityReducer.cities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cityCedex: store.CityReducer.cityCedex,
        countries: store.CountryReducer.countries,
        citiesHisto: store.CityReducer.citiesHisto,
        qualitometersLight: store.QualityReducer.qualitometersLight,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        installationsLight: store.InstallationReducer.installationsLight,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const [city, setCity] = useState(new CityDto({}))
    const [isEditMode, setIsEditMode] = useState(false)

    const getCityLinks = () => {
        if ((!cityProps.countryCode || cityProps.countryCode === FR) && cityProps.code) {
            return [{
                href: template(inseeCityLink)({ code: cityProps.code, lowercaseName: getValidNameInsee(cityProps.name) || '' }),
                img: logoInsee,
                label: i18n.insee.toUpperCase(),
            }, {
                href: georisquesCityLink + cityProps.code,
                img: georisques,
                label: i18n.georisques.toUpperCase(),
            }, {
                href: eaufranceCityLink + cityProps.code,
                img: eaufrance,
                label: i18n.eaufrance.toUpperCase(),
            }, {
                href: googleMapsSearchPath + cityProps.code,
                img: googleMap,
                label: 'Google Maps',
            }]
        }
        return []
    }

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HomeAction.setHelpLink('', ''))

        if (code !== 'new') {
            dispatch(CityAction.fetchCity(code))
            setIsEditMode(false)
        } else {
            setIsEditMode(true)
        }

        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!cityCedex.length) {
            dispatch(CityAction.fetchCityCedex())
        }
        if (!countries.length) {
            dispatch(CountryAction.fetchCountries())
        }
        if (!citiesHisto.length) {
            dispatch(CityAction.fetchCitiesHisto())
        }
        if (!qualitometersLight.length) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
        if (!piezometersLight.length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!installationsLight.length) {
            dispatch(InstallationAction.fetchInstallationsLight())
        }
        if (!productionUnits.length) {
            dispatch(ProductionUnitAction.fetchProductionUnits())
        }
        if (!pluviometers.length) {
            dispatch(PluviometryAction.fetchPluviometers())
        }
        if (!hydrometricStations.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        if (!contacts.length) {
            dispatch(ContactAction.fetchContacts())
        }
        if (!contributors.length) {
            dispatch(ContributorAction.fetchContributors())
        }
    }, [code])

    useEffect(() => setCity(cityProps), [cityProps])

    useEffect(() => () => dispatch(CityAction.resetCity()), [])

    const checkCode = (callback = () => {}) => {
        const existCode = cities.find(c => c.code === city.code)
        if (existCode) {
            dispatch(ToastrAction.error(i18n.codeAlreadyExists))
        } else if (!city.code && !city.code.length <= 1) {
            dispatch(ToastrAction.error(i18n.thanksToEnterCode))
        } else {
            callback()
        }
    }

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        const links = getCityLinks()
        const isNewActions = code === 'new' && (!cityProps || !cityProps.code)
        const actions = (() => {
            if (isNewActions) {
                return {
                    save: () => checkCode(() => {
                        dispatch(CityAction.createCity(city))
                        dispatch(CityAction.fetchCities())
                        setIsEditMode(false)
                    }),
                    cancel: () => {
                        dispatch(push(PATH_REFERENCIAL_CITY))
                        setIsEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => {
                        dispatch(CityAction.updateCity({ ...city, code: city.code.toString() }, city.code))
                        dispatch(CityAction.fetchCities())
                        setIsEditMode(false)
                    },
                    cancel: () => {
                        setCity(cityProps)
                        setIsEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    setIsEditMode(true)
                },
                deleteCheck: () => {
                    dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.cityCountDelete(code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <DeleteModal
                            onDelete={() => dispatch(CityAction.deleteCity(city.code))}
                        />,
                        dismissible: false,
                    }
                    dispatch(SieauAction.setPopup(popup))
                },
                replace: () => {
                    dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.cityCountDelete(code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <ReplaceModal
                            elements={ cities }
                            title={`${i18n.city} [${city.code}]`}
                            label={i18n.city}
                            onReplace={ (newCode) => dispatch(CityAction.replaceCity(city.code, newCode.code)) }
                        />,
                        dismissible: false,
                    }
                    dispatch(SieauAction.setPopup(popup))
                },
            }
        })()
        return (!isNewActions && !isEditMode && links.length > 0) ? { ...actions, links } : actions
    }, [isConsultant, code, cityProps, city, isEditMode])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.towns,
                    href: PATH_REFERENCIAL_CITY,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_CITY_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.towns,
                href: PATH_REFERENCIAL_CITY,
            },
            {
                title: code + (cityProps.name ? ` - ${cityProps.name}` : ''),
                href: PATH_REFERENCIAL_CITY_LINK + code,
            },
        ]
    }, [code, cityProps])

    const disabled = useMemo(() => ({
        active: isEditMode,
        disabled: !isEditMode,
    }), [isEditMode])

    const dataCedex = useMemo(() => cityCedex.filter(cc => cc.cityCode === city.code), [city.code, cityCedex])
    const dataCityHisto = useMemo(() => citiesHisto.filter(ch => ch.cityAttachmentCode === city.code), [citiesHisto, city.code])

    return (
        <div className='row no-margin padding-left-1'>
            <div className='col s12 margin-top-1'>
                <div id='file' className='col s12 white-background'>
                    <Card>
                        <Row className='padding-right-1 col s6 '>
                            <Row>
                                <Card title={i18n.description} className='padding-bottom-1' noMargin={false}>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 3 }
                                            title={ i18n.code }
                                            value={ city.code }
                                            onChange={ newCode => setCity({ ...city, code: getValidCodeInput(newCode) }) }
                                            maxLength={ 12 }
                                            disabled={ code !== 'new' }
                                        />
                                        <Input
                                            col={ 9 }
                                            title={ i18n.name }
                                            value={ city.name }
                                            onChange={ name => setCity({ ...city, name }) }
                                            maxLength={ 35 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Select
                                            col={ 6 }
                                            label={ i18n.country }
                                            value={ city.countryCode }
                                            options={ countries }
                                            keyValue='countryCode'
                                            nullLabel='&nbsp;'
                                            onChange={ countryCode => setCity({ ...city, countryCode }) }
                                            { ...disabled }
                                        />
                                        <Select
                                            options={ getSandreList(sandreCodes, SANDRE.COMMUNES_SITUATION) }
                                            label={ i18n.situation }
                                            col={ 6 }
                                            onChange={ situation => setCity({ ...city, situation }) }
                                            value={ parseInt(city.situation) }
                                            nullLabel='&nbsp;'
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Select
                                            options={ getSandreList(sandreCodes, 'PREL_AGRI.SECTEUR_CLIMATIQUE') }
                                            label={ i18n.climateSector }
                                            col={ 6 }
                                            onChange={ climateSector => setCity({ ...city, climateSector }) }
                                            value={ parseInt(city.climateSector) }
                                            nullLabel='&nbsp;'
                                            keyLabel='referenceWithLabel'
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='margin-1' noMargin={false}>
                                        <Table
                                            title={ i18n.cedexList }
                                            className='blue'
                                            data={ dataCedex }
                                            type={ typeCedex }
                                            customHeaders={{ cedex: i18n.cedexCode }}
                                            condensed sortable
                                        />
                                    </Row>
                                </Card>
                            </Row>
                            <Row noMargin={false} className='margin-top-1'>
                                <Card title={i18n.contact} className='no-padding' noMargin={false}>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 6 }
                                            title={ i18n.email }
                                            value={ city.email }
                                            onChange={ email => setCity({ ...city, email }) }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 6 }
                                            title={ i18n.phone }
                                            value={ city.phone }
                                            onChange={ phone => setCity({ ...city, phone }) }
                                            { ...disabled }
                                        />
                                    </Row>
                                </Card>
                            </Row>
                        </Row>
                        <Row className='padding-left-1 col s6'>
                            <Row>
                                <Card title={i18n.location} className='no-padding' noMargin={false}>
                                    <Row className='padding-top-1'>
                                        <NumberField
                                            col={ 6 }
                                            title={ i18n.area }
                                            value={ city.area }
                                            onChange={ area => setCity({ ...city, area }) }
                                            { ...disabled }
                                            floatValue
                                        />
                                        <NumberField
                                            col={ 6 }
                                            title={ i18n.population }
                                            value={ city.population }
                                            onChange={ population => setCity({ ...city, population }) }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <NumberField
                                            floatValue
                                            col={ 6 }
                                            title={ i18n.x }
                                            value={ city.x }
                                            onChange={ x => setCity({ ...city, x }) }
                                            { ...disabled }
                                        />
                                        <NumberField
                                            floatValue
                                            col={ 6 }
                                            title={ i18n.y }
                                            value={ city.y }
                                            onChange={ y => setCity({ ...city, y }) }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <Select
                                            options={ getSandreList(sandreCodes, SANDRE.PROJECTION) }
                                            label={ i18n.projection }
                                            col={ 6 }
                                            onChange={ projection => setCity({ ...city, projection }) }
                                            value={ city.projection }
                                            nullLabel='&nbsp;'
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1 padding-bottom-1'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.km_polygon }
                                            value={ city.kml }
                                            onChange={kml => setCity({ ...city, kml }) }
                                            { ...disabled }
                                        />
                                    </Row>
                                </Card>
                            </Row>
                            <Row noMargin={false} className='margin-top-1'>
                                <Table
                                    title={ i18n.associatedCities }
                                    data={ dataCityHisto }
                                    type={ typeCityHisto }
                                    condensed sortable activeHeader
                                />
                            </Row>
                        </Row>
                    </Card>
                </div>
            </div>
        </div>
    )
}

CityApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }),
}

export default CityApp
