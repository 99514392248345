import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Icon from '../../../components/icon/Icon'
import DtoPowerSupplySituation from '../../../materiel/components/powerSupply/dto/DtoPowerSupplySituation'
import PowerSupplyDto from '../../../materiel/components/powerSupply/dto/PowerSupplyDto'
import PowerSupplyTypeDto from '../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import { getMaterialsInStock } from '../../../materiel/utils/MaterielUtils'
import { getDate } from '../../../utils/DateUtil'
import { getLabel } from '../../../utils/StoreUtils'

const MaterialEquivalentCard = ({
    iconName = '',
    type = '',
    tooltipIcon = '',
    keyType = '',
    keyToDisplay = '',
    keyMaterial = '',
    materials = [],
    campaignMaterials = [],
    lastSituations = [],
    materialsTypes = [],
    powerSupplies = [],
    powerSupplyTypes = [],
    powerSuppliesLastSituations = [],
    redirect = () => { },
}) => {
    return (
        <div>
            {
                campaignMaterials.map(mat => {
                    const nbMaterialInStock = getMaterialsInStock(materials, lastSituations, keyMaterial).filter(m => m[keyType] === mat[keyType]).length
                    const materialtooltip = nbMaterialInStock > 1 ? i18n.materialsStock : i18n.materialStock
                    const nbPowerSuppliesInStock = mat.pileNumber && mat.powerSupplyType && getMaterialsInStock(powerSupplies, powerSuppliesLastSituations, 'idPowerSupply').filter(m => m[keyType] === mat[keyType]).length
                    return (
                        <div className='row col s12 valign-wrapper'>
                            <div className='col s1'>
                                <Icon icon={iconName} size={'medium'} tooltip={tooltipIcon} />
                            </div>
                            <div className='col s11 valign-wrapper'>
                                <div className='col s5'>
                                    <div className='col s12'>
                                        {`${getLabel(materialsTypes, mat[keyType])} - ${mat[keyToDisplay] || ''}`}
                                    </div>
                                    <div className='col s12'>
                                        {`${i18n.onSiteSince} ${getDate(mat.situationDate)}`}
                                    </div>
                                    {
                                        mat.pileNumber && mat.powerSupplyType && (
                                            <div className='col s12'>
                                                {`${i18n.batteryCount}: ${mat.pileNumber} (${(powerSupplyTypes.find(pst => pst.id == mat.powerSupplyType) || {}).name})`}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='col s1'>
                                    <Icon icon='arrow_forward' size={'medium'} tooltip={i18n.equivalentMaterialInStock} />
                                </div>
                                <div className='col s6'>
                                    <div className='collection padding-left-1'>
                                        <a className='collection-item no-padding clickable' onClick={() => redirect(`/materiel/${type}${mat[keyType] ? `?idType=${mat[keyType]}` : ''}`)}>
                                            {`${getLabel(materialsTypes, mat[keyType])}`}
                                            <span className={`badge ${nbMaterialInStock > 1 ? '' : 'red'} tooltipped`} data-tooltip={`${nbMaterialInStock} ${materialtooltip}`}>
                                                {nbMaterialInStock}
                                            </span>
                                        </a>
                                        {
                                            mat.pileNumber && mat.powerSupplyType && (
                                                <a className='collection-item no-padding clickable' onClick={() => redirect(`/materiel/powerSupply?idType=${mat.powerSupplyType}`)}>
                                                    {`${getLabel(powerSupplyTypes, mat.powerSupplyType)}`}
                                                    <span className={`badge ${nbPowerSuppliesInStock > 1 ? '' : 'red'} tooltipped`} data-tooltip={`${nbPowerSuppliesInStock} ${nbPowerSuppliesInStock > 1 ? i18n.materialsStock : i18n.materialStock}`}>
                                                        {nbPowerSuppliesInStock}
                                                    </span>
                                                </a>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

MaterialEquivalentCard.propTypes = {
    iconName: PropTypes.string,
    type: PropTypes.string,
    tooltipIcon: PropTypes.string,
    keyType: PropTypes.string,
    keyToDisplay: PropTypes.string,
    keyMaterial: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.object),
    campaignMaterials: PropTypes.arrayOf(PropTypes.object),
    lastSituations: PropTypes.arrayOf(PropTypes.object),
    materialsTypes: PropTypes.arrayOf(PropTypes.object),
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    powerSuppliesLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupplySituation)),
    redirect: PropTypes.func,
}

const mapStateToProps = store => ({
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    powerSuppliesLastSituations: store.PowerSupplyReducer.powerSuppliesLastSituations,
})

const mapDispatchToProps = {
    redirect: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialEquivalentCard)
