'use strict'
import AdministrationAction from 'administration/actions/AdministrationAction'
import AdminPiezometerApp from 'administration/components/piezometer/AdminPiezometerApp'
import UserDto from 'administration/components/user/dto/UserDto'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import HomeAction from 'home/actions/HomeAction'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import {
    H_PIEZO_CAMPAIGN,
    H_PIEZO_DASHBOARD,
    H_PIEZO_DIAGNOSTICS,
    H_PIEZO_EXPORT,
    H_PIEZO_EXPORT_OVERVIEW,
    H_PIEZO_GRAPHIC,
    H_PIEZO_IMPORT,
    H_PIEZO_INTEGRATION,
    H_PIEZO_MAP_SITUATION,
    H_PIEZO_OPTIONS,
} from '../../../account/constants/AccessRulesConstants'
import User from '../../../account/dto/User'
import FixedSlideNav from '../../../components/navbar/FixedSlideNav'
import { links } from '../../../quality/constants/StationsConstants'
import MapSituationAdminApp from '../../../station/components/mapSituation/MapSituationAdminApp'
import MapSituationApp from '../../../station/components/mapSituation/MapSituationApp'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import PiezometryCampaigns from '../campaign/PiezometryCampaigns'
import PiezometersExportApp from '../export/PiezometersExportApp'
import PiezoExportOverviewApp from '../exportOverview/PiezoExportOverviewApp'
import PiezometryGraphicApp from '../graphic/PiezometryGraphicApp'
import PiezometryIntegrationApp from '../integration/components/PiezometryIntegrationApp'
import PiezometersDashboardApp from './PiezometersDashboardApp'
import PiezometryDiagnosticsApp from '../diagnostics/PiezometryDiagnosticsApp'

class PiezometersApp extends Component {
    componentWillMount() {
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
    }

    getLink = (linkName) => {
        const link = links[linkName]
        return {
            ...link,
            href: link.href || `/piezometry/${link.hrefName || linkName}`,
            name: i18n[link.i18n],
        }
    }

    render() {
        const navBarLinks = [
            ...[
                { habilitation: H_PIEZO_DASHBOARD, link: 'dashboard' },
                { habilitation: H_PIEZO_IMPORT, link: 'import' },
                { habilitation: H_PIEZO_EXPORT_OVERVIEW, link: 'exportOverview' },
                { habilitation: H_PIEZO_EXPORT, link: 'export' },
                { habilitation: H_PIEZO_INTEGRATION, link: 'integrationOverview' },
                { habilitation: H_PIEZO_CAMPAIGN, link: 'campaign' },
                { habilitation: H_PIEZO_GRAPHIC, link: 'graphic' },
                { habilitation: H_PIEZO_DIAGNOSTICS, link: 'diagnostics' },
                { habilitation: H_PIEZO_OPTIONS, link: 'piezometryOptions' },
            ].filter(l => componentHasHabilitations(l.habilitation)),
            { habilitation: H_PIEZO_MAP_SITUATION, link: 'mapSituations' },
        ].filter(l => l).map(l => this.getLink(l.link))

        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Switch>
                                    <Redirect exact path='/piezometry' to='/piezometry/dashboard' />
                                    <Route path='/piezometry/dashboard' component={PiezometersDashboardApp} />
                                    <Route path='/piezometry/export' component={PiezometersExportApp} />
                                    <Route path='/piezometry/develop' component={DevelopmentApp} />
                                    <Route path='/piezometry/integrationOverview' component={PiezometryIntegrationApp} />
                                    <Route path='/piezometry/exportOverview' component={PiezoExportOverviewApp} />
                                    <Route path='/piezometry/campaign' component={PiezometryCampaigns} />
                                    <Route path='/piezometry/piezometryOptions' component={AdminPiezometerApp} />
                                    <Route path='/piezometry/graphic' component={PiezometryGraphicApp} />
                                    <Route path='/piezometry/mapSituations/settings' component={MapSituationAdminApp} />
                                    <Route path='/piezometry/mapSituations' component={MapSituationApp} />
                                    <Route path='/piezometry/diagnostics' component={PiezometryDiagnosticsApp} />
                                </Switch>
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}


PiezometersApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
    globalParameters: PropTypes.arrayOf(PropTypes.instanceOf(GlobalParametersDto)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    fetchGlobalParameters: PropTypes.func,
}

const mapStateToProps = store => ({
    accountUser: store.AccountReducer.accountUser,
    globalParameters: store.AdministrationReducer.globalParameters,
    users: store.UserReducer.users,
})

const mapDispatchToProps = {
    fetchGlobalParameters: AdministrationAction.fetchGlobalParameters,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometersApp)
