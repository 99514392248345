import { push } from 'connected-react-router'
import { minBy, orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import NumberField from '../../../components/forms/NumberField'
import Icon from '../../../components/icon/Icon'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PiezometerAccessibilitieDto from '../../../piezometry/dto/PiezometerAccessibilitieDto'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { getEventsClosureModal } from '../../../utils/CampaignUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getLabel } from '../../../utils/StoreUtils'
import CampaignAction from '../../actions/CampaignAction'


const ListStationCampaign = ({
    campaignId = -1,
    editMode = false,
    onDeleteStation = () => { },
    accessibilities = [],
    stationType = '',
    stations = [],
    onChangeOrder = () => { },

    cities = [],
    contacts = [],

    redirect = () => { },
    fetchCampaign = () => { },
}) => {
    const getOrderItem = stationSelected => {
        const {
            id,
            order,
        } = stationSelected
        if (editMode) {
            return (
                <NumberField
                    value={order}
                    min={0}
                    onChange={v => onChangeOrder(id, v)}
                />
            )
        }
        return order
    }

    const eventsClosureModal = (events, label) => {
        const dateNow = moment().valueOf()
        getEventsClosureModal(events.filter(e =>
            !(e.closeDate && e.closeDate > dateNow)
        ), label, () => fetchCampaign(stationType, campaignId))
    }

    const getMonitoringIcon = ({ monitoring, monitoringEvents }) => monitoring !== 0 && (
        <Icon
            icon='notifications'
            className='blue-text'
            tooltip={i18n.monitoredStation}
            onClick={() => campaignId && stationType === 'piezometry' && eventsClosureModal(monitoringEvents, `${i18n.events} "${i18n.toMonitor}"`)}
        />
    )

    const getPlanIcon = ({ toPlanEvents, toPlanEventLength }) => toPlanEventLength !== 0 && (
        <Icon
            icon='assignment'
            className='blue-text'
            tooltip={i18n.toPlan}
            onClick={() => campaignId && stationType === 'piezometry' && eventsClosureModal(toPlanEvents, `${i18n.events} "${i18n.toPlan}"`)}
        />
    )

    const getContact = contact => {
        if (contact) {
            const postalCode = contact.postalCode ? `${contact.postalCode}, ` : ''
            const tooltip = ReactDOMServer.renderToString(
                <div className='row no-margin left-align'>
                    <h6>{contact.name}</h6>
                    <h6>{contact.address}</h6>
                    <h6>{postalCode + getLabel(cities, contact.cityCode)}</h6>
                    <h6>{contact.phoneTel || contact.mobile}</h6>
                </div>
            )
            return (
                <div className='row no-margin valign-wrapper tooltipped' data-tooltip={tooltip}>
                    <i className='material-icons'>account_circle</i>
                    {contact.phoneTel || contact.mobile ? <i className='material-icons'>call</i> : null}
                    {contact.name}
                </div>
            )
        }
        return null
    }

    const getAccessibilitieDescription = access => {
        const descriptions = access.filter(a => a.description).map(a => <h6>{a.description}</h6>)
        const foundDescription = access.find(a => a.description)
        const firstDescription = (foundDescription && foundDescription.description) || ''
        if (descriptions.length) {
            const tooltip = ReactDOMServer.renderToString(
                <div className='row no-margin left-align'>
                    {descriptions}
                </div>
            )
            return (
                <div className='row no-margin valign-wrapper tooltipped' data-tooltip={tooltip}>
                    <Icon icon='directions' />
                    {firstDescription.length > 80 ? `${firstDescription.slice(0, 77)}...` : firstDescription}
                </div>
            )
        }
        return null
    }

    const selectedStations = stations.map(station => {
        const stationAccess = accessibilities.filter(access => access.id === station.id)
        const access = stationAccess.length && stationAccess.find(a => hasValue(a.order)) ? minBy(stationAccess, 'order') : (stationAccess.length ? stationAccess[0] : null)
        const contact = contacts.find(c => c.id === (access && access.contactCode ? access.contactCode : station.contactCode))
        return {
            id: station.id,
            monitoringEvents: station.monitoringEvents,
            toPlanEvents: station.toPlanEvents,
            nullValue: [getMonitoringIcon(station), getPlanIcon(station)],
            code: {
                value: station.code,
                color: 'white',
                onClick: () => redirect(`/station/${stationType}/${station.id}`),
            },
            city: station.townCode ? `${getLabel(cities, station.townCode)} [${station.townCode}]` : null,
            name: station.name,
            contact: getContact(contact),
            exportContact: contact ? [contact.name || '', contact.address, contact.postalCode, getLabel(cities, contact.cityCode), contact.phoneTel || contact.mobile].filter(str => !!str).join(', ') : '',
            order: getOrderItem(station),
            visitOrder: station.order,
            estimated: station.previsionalVisitNumber,
            access: getAccessibilitieDescription(stationAccess),
            exportAccess: orderBy(stationAccess, 'order').map(a => a.description).join(', '),
        }
    })
    return (
        <Table
            showTitle={false}
            data={orderBy(selectedStations, ['order', 'code.value'])}
            type={{ headers: ['code', 'city', 'name', 'contact', 'access', 'order', 'nullValue'] }}
            sortable
            deletable={editMode}
            onDelete={onDeleteStation}
            condensed
            paging
            nbPerPageLabel={nbPerPageLabel}
        />
    )
}

ListStationCampaign.propTypes = {
    campaignId: PropTypes.number,
    editMode: PropTypes.bool,
    onDeleteStation: PropTypes.func,
    accessibilities: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(PiezometerAccessibilitieDto),
    ])),
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'installation']),
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    onChangeOrder: PropTypes.func,

    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),

    redirect: PropTypes.func,
    fetchCampaign: PropTypes.func,
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,
})

const mapDispatchToProps = {
    redirect: push,
    fetchCampaign: CampaignAction.fetchCampaign,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListStationCampaign)