import { push } from 'connected-react-router'
import { template } from 'lodash'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getFullDateMini } from 'utils/DateUtil'
import { getLogin } from 'utils/SettingUtils'
import HomeAction from '../actions/HomeAction'
import Dashboard from './Dashboard'

const DashboardApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        showWelcomePopup,
        accountStatistics,
        users,
    } = useSelector(store => ({
        showWelcomePopup: store.HomeReducer.showWelcomePopup,
        accountStatistics: store.AccountReducer.accountStatistics,
        users: store.UserReducer.users,
    }), shallowEqual)

    const login = getLogin()

    useEffect(() => {
        if (showWelcomePopup && accountStatistics.length !== 0 && users.length !== 0) {
            dispatch(HomeAction.showWelcomePopup(false))
            const accountStatsLogin = accountStatistics.filter(({ eventType }) => eventType === 'LOGIN')
            const stats = accountStatsLogin.reduce((acc, stat) => stat.value ? [...acc, stat.statisticDate] : acc, [])
            if (stats.length >= 2) {
                stats.sort((a, b) => a.statisticDate - b.statisticDate)
                const user = users.find(e => e.login === login)
                const lastConnection = getFullDateMini(stats[stats.length - 2]).split(' ')
                const messageToast = `${template(i18n.informationLastConnection)({ date: lastConnection[0], hours: lastConnection[1] })}</br>${i18n.seeMore}`
                dispatch(ToastrAction.success(messageToast, template(i18n.welcomeLogin)({ login: user.firstname && user.name ? `${user.firstname} ${user.name}` : user.login }), { positionClass: 'toast-flat-top-right', onclick: () => dispatch(push('/account')), preventDuplicates: true }))
            }
        }
    }, [accountStatistics, dispatch, login, showWelcomePopup, users])

    return (
        <Dashboard />
    )
}

export default DashboardApp
