import loading from 'assets/pictures/loading.gif'
import { push } from 'connected-react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { sortBy } from 'lodash'
import Card from '../../components/card/Card'
import Table from '../../components/datatable/Table'
import { getStationType } from '../../utils/StationUtils'
import { getDate, getFullDate } from '../../utils/DateUtil'
import CampaignPortletPanel from '../../campaign/components/CampaignPortletPanel'
import MonitoredMaterielPortletPanel from '../../materiel/components/dashboard/MonitoredMaterielPortletPanel'
import ProgressCard from '../../components/card/ProgressCard'
import { getUserBookmarksByStationType } from '../../utils/UserUtil'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_HYDRO_MODULE,
    H_MAT_MODULE,
} from '../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../utils/components/HabilitationRequired'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import UserThunk from 'administration/components/user/actions/UserThunk'
import useStateProgress from 'utils/customHook/useStateProgress'
import HydrometryThunk from 'hydrometry/actions/HydrometryThunk'
import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import StationAction from '../../station/actions/StationAction'
import useAccountSetting from '../../utils/customHook/useAccountSetting'
import MapSituationPie from '../../station/components/mapSituation/MapSituationPie'
import { Grid } from '@mui/material'

const panelTable = 'table'
const panelGraph = 'graph'
const hydroStationType = 4

const HydrometryCard = () => {
    const {
        userBookmarks,
        hydrometricStations,
        hydroStats,
        monitoredMateriels,
        citiesIndex,
        userBookmarksStatus,
        hydrometricStationsStatus,
        hydroBookmarksStatsStatus,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydroStats: store.HydrometryReducer.hydroBookmarksStats,
        monitoredMateriels: store.MaterielReducer.monitoredMateriels,
        citiesIndex: store.CityReducer.citiesIndex,
        userBookmarksStatus: store.DataManagerReducer.user.userBookmarksStatus,
        hydrometricStationsStatus: store.DataManagerReducer.hydrometry.hydrometricStationsStatus,
        hydroBookmarksStatsStatus: store.DataManagerReducer.hydrometry.hydroBookmarksStatsStatus,
    }), shallowEqual)

    const { progress: progressBar, isLoaded: dataLoaded } = useStateProgress([userBookmarksStatus, hydrometricStationsStatus, hydroBookmarksStatsStatus])

    const dispatch = useDispatch()

    const mapSituationWidgetObj = useAccountSetting('MAP_SITUATION_DASHBOARD_WIDGET', setting => JSON.parse(setting ?? '{}'))
    const [mapSituationStats, setMapSituationStats] = useState([])

    const bookmarks = useMemo(() => getUserBookmarksByStationType(userBookmarks, STATION_TYPE_NAME.hydrometry), [userBookmarks])

    const foundBookmarks = useMemo(() => bookmarks.reduce((acc, code) => {
        const station = hydrometricStations.find(s => s.code === code)
        if (station) {
            return [...acc, station]
        }
        return acc
    }, []), [hydrometricStations, bookmarks])

    useEffect(() => {
        dispatch(UserThunk.fetchBookmarks())
        dispatch(HydrometryThunk.fetchHydrometricStations())
    }, [])

    useEffect(() => {
        const hydroMapIds = Object.keys(mapSituationWidgetObj).filter(key => key.startsWith('hydrometry') && !!mapSituationWidgetObj[key]).map(k => parseInt(k.replace('hydrometry-', '')))
        if (hydroMapIds.length) {
            Promise.all(hydroMapIds.map(id => StationAction.promiseMapSituationStats('hydrometry', id))).then(jsonTab => setMapSituationStats(jsonTab.filter(t => t.length)))
        }
    }, [mapSituationWidgetObj])


    useEffect(() => {
        const ids = foundBookmarks.map(b => b.id)
        dispatch(HydrometryThunk.fetchHydroBookmarksStats(ids))
    }, [bookmarks, dispatch, foundBookmarks, hydrometricStations?.length])

    useEffect(() => () => dispatch(HydrometryActionConstant.resetHydroStats()), [])

    const [panelType, setPanelType] = useState(panelTable)

    const data = useMemo(() => ({
        stations: hydrometricStations,
        bookmarks,
        icon: 'multiline_chart',
        campaignPanel: null,
        campaigns: [],
        stationType: hydroStationType,
        habilitation: componentHasHabilitations(H_HYDRO_MODULE),
        monitoredMateriels: monitoredMateriels.filter(m => m.typeSite === hydroStationType),
    }), [bookmarks, hydrometricStations, monitoredMateriels])

    const bookmarksStationPanel = useMemo(() => {
        if (!foundBookmarks.length) {
            return null
        }
        const stationResult = sortBy(foundBookmarks.map(station => {
            const cityFound = citiesIndex[station.townCode]
            const stats = hydroStats.find(s => s.code === station.code)
            const startDate = stats?.startDate
            const endDate = stats?.endDate
            return {
                id: station.id,
                code: station.designation ? `${station.code}/${station.designation}` : station.code,
                name: station.name,
                altitude: station.altitude,
                stationType: station.stationType ? getStationType(parseInt(station.stationType)).libelle : '',
                headers: ['code', 'city', 'name', 'stationType', 'historic', 'lastValueHeight', 'lastValueFlow', 'lastObservationLabel'],
                city: cityFound ? `${cityFound.name} [${cityFound.code}]` : station.townCode,
                historic: `${startDate && getDate(startDate) || ''} ${i18n.to} ${endDate && getDate(endDate) || ''}`,
                lastValueHeight: {
                    className: stats?.lastValueHeight ? 'tooltipped' : '',
                    color: 'white',
                    value: stats?.lastValueHeight ?? '',
                    tooltip: getFullDate(stats?.lastDateHeight).toString() ?? '',
                },
                lastValueFlow: {
                    className: stats?.lastValueFlow ? 'tooltipped' : '',
                    color: 'white',
                    value: stats?.lastValueFlow ?? '',
                    tooltip: getFullDate(stats?.lastDateFlow).toString() ?? '',
                },
                lastObservationLabel: stats?.lastObservationLabel ?? '',
            }
        }), 'name')
        if (panelType === panelTable) {
            const mapSituationPies = mapSituationStats.map(tab => {
                return (
                    <MapSituationPie stats={tab} st='hydrometry' />
                )
            })
            const mapSituationCard = mapSituationPies.length ? (
                <Grid container justifyContent='center' alignItems='center' spacing={3}>
                    {mapSituationPies}
                </Grid>
            ) : undefined
            return (
                <>
                    {mapSituationCard}
                    <Table
                        showNbElements={false}
                        showTitle={false}
                        showIcon={true}
                        headerIcon={(
                            <a>
                                <i className='waves-effect material-icons'>grade</i>
                            </a>
                        )}
                        data={stationResult}
                        actions={[{
                            iconName: 'insert_chart',
                            onClick: () => setPanelType(panelGraph),
                        }]}
                        type={stationResult[0]}
                        link={`station/${STATION_TYPE_NAME.hydrometry}`}
                        condensed
                        sortable
                    />
                </>
            )
        }
        return (
            <Card
                title={(
                    <a>
                        <i className='waves-effect material-icons'>grade</i>
                        {i18n.myFavourites} ({foundBookmarks.length} {i18n.elements})
                    </a>
                )}
                actions={[{
                    iconName: 'list',
                    onClick: () => setPanelType(panelTable),
                }]}
            >
                {null}
            </Card>
        )
    }, [citiesIndex, foundBookmarks, hydroStats, panelType])

    const bookmarksPanel = useMemo(() => {
        if (data?.bookmarks?.length > 0 && data?.stations?.length > 0) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            {bookmarksStationPanel}
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [bookmarksStationPanel, data?.bookmarks?.length, data?.stations?.length])

    const campaignsPanel = useMemo(() => {
        if (data.campaigns && data.campaigns.length > 0 && data.campaignHeader) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            <CampaignPortletPanel
                                noHeader={true}
                                campaigns={data.campaigns}
                                stationType={STATION_TYPE_NAME.hydrometry}
                                tableHeader={data.campaignHeader}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [data.campaignHeader, data.campaigns])

    if (dataLoaded) {
        if (!(data.habilitation && ((data.bookmarks.length > 0 && data.stations.length > 0) || data.campaigns.length > 0 || data.monitoredMateriels.length > 0 || data?.exploitations?.length > 0)) || !(bookmarksPanel || campaignsPanel)) {
            return null
        }
        return (
            <div className='row no-margin padding-top-1'>
                <Card
                    title={(
                        <a className='waves-effect' onClick={() => dispatch(push(`/${data.redirect || STATION_TYPE_NAME.hydrometry}`))}>
                            <i className='material-icons '>{data.icon}</i>
                            {i18n[STATION_TYPE_NAME.hydrometry]}
                        </a>
                    )}
                >
                    <div className='padding-1 padding-bottom-0'>
                        {bookmarksPanel}
                        {campaignsPanel}
                        <HabilitationRequired habilitation={H_MAT_MODULE}>
                            <MonitoredMaterielPortletPanel noHeader={true} stationType={data.stationType} />
                        </HabilitationRequired>
                    </div>
                </Card>
            </div>
        )
    }
    return (
        <div className='padding-top-1'>
            <Card>
                <div className='row no-margin center-align padding-top-5'>
                    <img src={loading} />
                    <ProgressCard withoutCard message={i18n.hydrometryCardInProgress} progress={progressBar} />
                </div>
            </Card>
        </div>
    )
}

export default HydrometryCard