import AdminAlertApp from 'administration/components/alert/AdminAlertApp'
import AdminModelApp from 'administration/components/alert/AdminModelApp'
import { goBack } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_SITUATION_MODULE } from '../../account/constants/AccessRulesConstants'
import User from '../../account/dto/User'
import { SITUATION_MANAGEMENT } from '../../administration/components/user/constants/HabilitationConstants'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'
import { PATH_ALERT, PATH_ALERT_CONFIGURATION, PATH_MODEL_CONFIGURATION } from '../../home/constants/RouteConstants'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import { hasHabilitation } from '../../utils/SettingUtils'
import AlertMapApp from './dashboard/AlertMapApp'
import FollowApp from './follow/FollowApp'
import AlertNewCmsModal from './map/AlertNewCmsModal'


class AlertApp extends Component {
    componentDidMount() {
        this.checkAbillitation()
        AppStore.dispatch(HomeAction.setHelpLink('gemapi'))
    }

    componentDidUpdate() {
        this.checkAbillitation()
    }

    checkAbillitation = () => {
        const { user, toastError } = this.props
        if (user && user.login) {
            const isAuthorized = componentHasHabilitations(H_SITUATION_MODULE)
            if (!isAuthorized) {
                this.props.goBack()
                toastError(i18n.AccessRightDeny)
            }
        }
    }

    getLinks = () => {
        const alertOptions = hasHabilitation(SITUATION_MANAGEMENT) ? [{
            href: PATH_ALERT_CONFIGURATION,
            icons: 'settings',
            name: i18n.alertOptions,
            className: 'bottom-slide-nav-link',
        }] : []
        const links = [
            {
                href: PATH_ALERT,
                icons: 'map',
                name: i18n.map,
            },
            {
                href: PATH_MODEL_CONFIGURATION,
                icons: 'auto_awesome_mosaic',
                name: i18n.modelOptions,
            },
            ...alertOptions,
        ]
        return links
    }

    render() {
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={ this.getLinks() }/>
                <div className='content-page no-padding fullscreen'>
                    <div className='card padding-top-1 transparent no-box-shadow no-margin'>
                        <div className='row no-margin'>
                            <div className='col s12'>
                                <BoundaryError>
                                    <Switch>
                                        <Redirect exact path='/alert' to='/alert/dashboard' />
                                        <Route path='/alert/dashboard' render={(props) => <AlertMapApp {...props} />} />
                                        <Route path='/alert/follow/:id/:type' render={(props) => <FollowApp {...props} />} />
                                        <Route path='/alert/alertOptions' render={(props) => <AdminAlertApp {...props} />} />
                                        <Route path='/alert/modelOptions' render={(props) => <AdminModelApp {...props} />} />
                                        <Route path='/alert/newCms' render={(props) => <AlertNewCmsModal {...props} />} />
                                    </Switch>
                                </BoundaryError>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AlertApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    user: PropTypes.instanceOf(User),
    goBack: PropTypes.func,
    toastError: PropTypes.func,
}

const mapStateToProps = store => ({
    user: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    goBack,
    toastError: ToastrAction.error,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertApp)
