import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import i18n from 'simple-react-i18n'
import { PropTypes } from 'prop-types'
import Input from 'components/forms/Input'
import RadioButtons from 'components/forms/RadioButtons'
import { compact, orderBy, partition, toLower, toUpper, uniq, uniqBy } from 'lodash'
import { hasValue } from 'utils/NumberUtil'
import { darkBlue } from 'utils/constants/ColorTheme'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { getI18nTitleDataLength, searchAllCharacters } from 'utils/StringUtil'
import SelectionTable from 'components/datatable/SelectionTable'
import { getIconsUser, getSetting } from 'utils/SettingUtils'
import Icon from 'components/icon/Icon'
import Checkbox from 'components/forms/Checkbox'

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '15px',
        fontWeight: 'bold',
        minHeight: 40,
        maxHeight: 40,
        '&.Mui-expanded': {
            minHeight: 45,
            maxHeight: 45,
        },
    },
}))

const UsersStep = ({
    stateUsers = [],
    setStateUsers = () => { },
    stateAdministrators = [],
    setStateAdministrators = () => { },
    userOption = 0,
    setUserOption = () => { },
    children = null,
    isNew = false,
}) => {
    const classes = useStyles()

    const [expanded, setExpanded] = useState('')
    const [search, setSearch] = useState('')
    const [isAdmin, setIsAdmin] = useState(true)
    const [metadata, setMetadata] = useState(true)
    const [technicien, setTechnicien] = useState(true)
    const [consultant, setConsultant] = useState(true)
    const [blocked, setBlocked] = useState(false)
    const [appUsers, setAppUsers] = useState(false)

    const {
        contributors,
        applicationSettings,
        usersOfFilter,
        users,
        applicativeUsers,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        usersOfFilter: store.UserReducer.usersOfFilter,
        users: store.UserReducer.users,
        applicativeUsers: store.UserReducer.applicativeUsers,
    }))

    const maximumNumberTry = useMemo(() => getSetting(applicationSettings, 'maximumNumberTry') || 5, [applicationSettings])

    const getFilteredData = useCallback((data) => {
        const includesValue = searchAllCharacters(search || '')
        return data.filter(i => searchAllCharacters(i.login).includes(includesValue))
    }, [search])

    const filteredUsers = useMemo(() => {
        const blockedUsers = !blocked ? users.filter(u => (!u.connexionAttempts || u.connexionAttempts < maximumNumberTry)) : users
        const filter = blockedUsers.filter(u => {
            return (isAdmin && u.admin === '1')
                || (metadata && u.metadata === '1')
                || (technicien && u.labo === '1')
                || (consultant && u.consultant === '1')
                || (((!u.isAdmin && !u.admin) || (u.isAdmin === '0' && u.admin === '0')) && (!u.labo || u.labo === '0') && (!u.metadata || u.metadata === '0'))
        })
        const appUsersFiltered = appUsers ? [...filter, ...applicativeUsers.map(d => ({ ...d, contributorCode: -2 }))] : filter
        const searchUsers = search ? getFilteredData(appUsersFiltered) : appUsersFiltered
        return searchUsers.map(d => ({
            ...d,
            type: getIconsUser(d, maximumNumberTry),
        }))
    }, [blocked, users, appUsers, applicativeUsers, search, getFilteredData, maximumNumberTry, isAdmin, metadata, technicien, consultant])

    useEffect(() => {
        if (usersOfFilter.length === 0) {
            return
        }
        const uss = compact(usersOfFilter.filter(u => hasValue(u.user)).map(u => filteredUsers.find(user => user.login === u.user)))
        const adm = usersOfFilter.filter(user => hasValue(user.contributorCode)).map(user => user.contributorCode)
        if (stateUsers.length || stateAdministrators.length || userOption !== 0) {
            setUserOption(1)
        } else if ((usersOfFilter.length === 1 && !hasValue(usersOfFilter[0].user) && !hasValue(usersOfFilter[0].contributorCode)) || isNew) {
            setUserOption(0)
        } else {
            setUserOption(1)
            setStateUsers(uss)
            setStateAdministrators(adm)
        }
    }, [usersOfFilter])

    const getBuildingOwnerName = useCallback((contributorCode) => {
        if (!hasValue(contributorCode)) {
            return i18n.noAdministratorUsers
        }
        if (contributorCode === -2) {
            return i18n.usersApplication
        }
        return contributors.find(d => d.code === contributorCode)?.name || i18n.undefinedAdministrator
    }, [contributors])

    const administrators = useMemo(() => {
        return orderBy(uniq(filteredUsers.map(d => d.contributorCode)).map(d => ({
            code: d,
            name: getBuildingOwnerName(d),
        })), 'name').map(d => d.code)
    }, [filteredUsers, getBuildingOwnerName])

    useEffect(() => {
        setExpanded(`panel${administrators?.[0] ?? ''}`)
    }, [search, isAdmin, metadata, technicien, consultant, blocked, appUsers])

    const onChangeAdministrator = (checked, administrator) => {
        if (checked) {
            setStateAdministrators([...stateAdministrators, administrator])
            setStateUsers(stateUsers.filter(u => u.contributorCode !== administrator))
        } else {
            setStateAdministrators([...stateAdministrators.filter(ad => ad !== administrator)])
            setStateUsers(stateUsers.filter(u => u.contributorCode !== administrator))
        }
    }

    const onAddUser = user => setStateUsers([...stateUsers, user])
    const onDeleteUser = (user, administrator) => setStateUsers(stateUsers.filter(u => !(u.login === user.login && u.contributorCode === administrator)))

    const addAllUsers = administrator => setStateUsers(uniqBy([...stateUsers, ...filteredUsers.filter(u => u.contributorCode === administrator)], u => u.login + u.contributorCode))
    const deleteAllUsers = administrator => setStateUsers(stateUsers.filter(u => u.contributorCode !== administrator))

    const getSelectedUsers = (administrator, isAdmSelected) => {
        if (isAdmSelected) {
            return {
                selectedUsers: filteredUsers.filter(user => user.contributorCode === administrator),
                unSelectedUsers: [],
            }
        }
        const data = partition(filteredUsers.filter(user => user.contributorCode === administrator), user => stateUsers.some(su => su.login === user.login))
        return {
            selectedUsers: data[0],
            unSelectedUsers: data[1],
        }
    }

    const getGroupedUsers = () => {
        return administrators.map(administrator => {
            const isAdmSelected = userOption === 0 || stateAdministrators.some(a => a === administrator)
            const usersDisplay = filteredUsers.filter(user => user.contributorCode === administrator)
            const { unSelectedUsers, selectedUsers } = getSelectedUsers(administrator, isAdmSelected)
            const nbElements = isAdmSelected ? usersDisplay.length : stateUsers.filter(d => d.contributorCode === administrator).length
            return (
                <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === `panel${administrator ?? ''}`}
                    onChange={(_, isExpanded) => setExpanded(isExpanded ? `panel${administrator ?? ''}` : false)}
                    sx={{ border: 'solid 1px grey', marginBottom: '5px' }}
                    key={`panel${administrator ?? ''}`}
                >
                    <AccordionSummary
                        expandIcon={<Icon size='small' icon={'expand_more'} style={{ color: darkBlue, borderRadius: '50%', border: `solid ${darkBlue} 1px` }} />}
                        sx={{ color: darkBlue, fontSize: '18px', background: 'white', borderBottom: 'solid 1px grey' }}
                        classes={classes}
                    >
                        <Grid container alignItems='center'>
                            <Icon
                                size='small'
                                icon={`${hasValue(administrator) && administrator !== -2 ? 'domain' : 'person'}`}
                                style={{ marginRight: '10px' }}
                            />
                            {toUpper(getBuildingOwnerName(administrator))}&nbsp;<span style={{ fontSize: '12px', fontWeight: 'normal' }}>({nbElements} {toLower(getI18nTitleDataLength(i18n.user, i18n.users, nbElements))} sur {unSelectedUsers.length + selectedUsers.length})</span>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <>
                            {hasValue(administrator) && administrator !== -2 && (
                                <Grid>
                                    <Checkbox
                                        label={'Tout le gestionnaire'}
                                        checked={isAdmSelected}
                                        display
                                        onChange={v => onChangeAdministrator(v, administrator)}
                                    />
                                </Grid>
                            )}
                            {
                                (!isAdmSelected || !hasValue(administrator)) && (
                                    <Grid className='margin-top-1'>
                                        <SelectionTable
                                            maxHeight='45vh'
                                            listTitle={i18n.users}
                                            listData={unSelectedUsers}
                                            listHeaders={['login', 'type']}
                                            selectedHeaders={['login', 'type']}
                                            selectedData={selectedUsers}
                                            selectedTitle={i18n.selected}
                                            onAdd={user => onAddUser(user)}
                                            onDelete={user => onDeleteUser(user, administrator)}
                                            addAll={() => addAllUsers(administrator)}
                                            deleteAll={() => deleteAllUsers(administrator)}
                                        />
                                    </Grid>
                                )
                            }
                        </>
                    </AccordionDetails>
                </Accordion >
            )
        })
    }

    return (
        (
            <Grid container>
                <Grid item xs={12} className='padding-bottom-1'>
                    <StyledFieldSet>
                        <StyledLegend>
                            <StyledSpan>{i18n.options}</StyledSpan>
                        </StyledLegend>
                        <RadioButtons
                            elements={[
                                {
                                    code: 0,
                                    name: i18n.visibleByAllUsers,
                                }, {
                                    code: 1,
                                    name: i18n.restrictionsUserAdmin,
                                },
                            ]}
                            selected={userOption}
                            onChange={setUserOption}
                        />
                    </StyledFieldSet>
                    {userOption === 1 && (
                        <Grid style={{ position: 'sticky', top: '10px', zIndex: 1000 }} alignItems='stretch'>
                            <StyledFieldSet >
                                <StyledLegend>
                                    <StyledSpan>{i18n.researchCriterias}</StyledSpan>
                                </StyledLegend>
                                <Grid container item xs={12} justifyContent='space-around' alignItems='center'>
                                    <Grid item xs={3}>
                                        <Input
                                            title={i18n.search}
                                            value={search}
                                            onChange={setSearch}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <Checkbox
                                            label={i18n.admin}
                                            checked={isAdmin}
                                            onChange={setIsAdmin}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <Checkbox
                                            label={i18n.administrator}
                                            checked={metadata}
                                            onChange={setMetadata}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <Checkbox
                                            label={i18n.technicien}
                                            checked={technicien}
                                            onChange={setTechnicien}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <Checkbox
                                            label={i18n.consultant}
                                            checked={consultant}
                                            onChange={setConsultant}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            label={i18n.blockedUser}
                                            checked={blocked}
                                            onChange={setBlocked}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            label={i18n.usersApplication}
                                            checked={appUsers}
                                            onChange={setAppUsers}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        </Grid>
                    )}
                    <StyledFieldSet style={{ marginTop: '10px' }}>
                        <StyledLegend>
                            <StyledSpan>{i18n.results}</StyledSpan>
                        </StyledLegend>
                        <Grid container justifyContent='center' alignItems='center' >
                            {children}
                        </Grid>
                        {userOption === 1 && getGroupedUsers()}
                    </StyledFieldSet>
                </Grid>
            </Grid >
        )
    )
}

UsersStep.propTypes = {
    userOption: PropTypes.number,
    stateUsers: PropTypes.arrayOf(PropTypes.shape({})),
    stateAdministrators: PropTypes.arrayOf(PropTypes.number),
    setStateAdministrators: PropTypes.func,
    setUserOption: PropTypes.func,
    setStateUsers: PropTypes.func,
    children: PropTypes.element,
    isNew: PropTypes.bool,
}

export default UsersStep