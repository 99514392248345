import { push } from 'connected-react-router'
import { isEqual, omit } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import ActionComponent from '../../../../components/ActionComponent'
import Card from '../../../../components/card/Card'
import Input from '../../../../components/forms/Input'
import SieauAction from '../../../../components/sieau/SieauAction'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CULTURE,
    PATH_REFERENCIAL_CULTURES_RPG,
    PATH_REFERENCIAL_CULTURES_RPG_LINK,
    PATH_REFERENCIAL_CULTURES_RPG_NEW,
} from '../../../../home/constants/RouteConstants'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import CultureAction from '../actions/CultureAction'
import DtoCultureRPG from '../dto/DtoCultureRPG'

moment.locale('fr')

class CultureApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            isEditMode: props.isEditMode,
            cultureRPG: {},
        }
    }

    componentDidMount() {
        const { params } = this.props.match
        if (params.code !== 'new') {
            this.changeEditMode(false)
            this.props.fetchCultureRPG(params.code)
        } else {
            this.changeEditMode(true)
        }
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarCultureRPG()
    }

    changeStateAttribute = (attr, value) => {
        this.setState(({ cultureRPG }) => ({
            cultureRPG: {
                ...cultureRPG,
                [attr]: value,
            },
        }))
    }

    componentWillUnmount = () => this.props.resetCulture()

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    toNavbarCultureRPG() {
        const { params } = this.props.match
        const { isEditMode } = this.state
        const actions = (() => {
            if (params.code === 'new' && (!this.props.cultureRPG || !this.props.cultureRPG.code)) {
                return {
                    save: () => {
                        const { cultureRPG } = this.state
                        this.props.createCultureRPG(cultureRPG)
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.props.push(PATH_REFERENCIAL_CULTURES_RPG)
                        this.changeEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => {
                        const { cultureRPG } = this.state
                        this.props.updateCultureRPG(cultureRPG, cultureRPG.code)
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.setState({ cultureRPG: this.props.cultureRPG })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                delete: () => this.props.deleteCultureRPG(this.props.cultureRPG.code),
                edit: () => {
                    this.changeEditMode(true)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidUpdate = prevProps => {
        const { cultureRPG } = this.props
        const { params } = this.props.match
        const { isEditMode } = this.state
        if (!isEqual(prevProps.cultureRPG, cultureRPG)) {
            this.setState({ cultureRPG })
        }
        if (!isEqual(isEditMode, prevProps.isEditMode)) {
            this.toNavbarCultureRPG()
        }
        if (prevProps.match.params.code !== params.code) {
            this.props.fetchCulture(params.code)
        } else if (params.code === 'new') {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.culturesRPG,
                    href: PATH_REFERENCIAL_CULTURE,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_CULTURES_RPG_NEW,
                },
            ])
        } else if (!isEqual(prevProps.cultureRPG, cultureRPG)) {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.culturesRPG,
                    href: PATH_REFERENCIAL_CULTURE,
                },
                {
                    title: params.code + (cultureRPG.name ? ` - ${cultureRPG.name}` : ''),
                    href: PATH_REFERENCIAL_CULTURES_RPG_LINK + params.code,
                },
            ])
            this.setState({ cultureRPG })
            this.toNavbarCultureRPG()
        }
    }

    render() {
        const { isEditMode, cultureRPG } = this.state
        const disabled = {
            active: isEditMode,
            disabled: !isEditMode,
        }

        return (
            <div className='row no-margin padding-1'>
                <Card title={i18n.description} maxWidth={1000}>
                    <div className='padding-1'>
                        <div className='row no-margin padding-top-3-px'>
                            <Input
                                col={6}
                                title={i18n.code}
                                value={cultureRPG.code}
                                onChange={value => this.changeStateAttribute('code', value)}
                                maxLength={255}
                                {...disabled}
                            />
                            <Input
                                col={6}
                                title={i18n.name}
                                value={cultureRPG.name}
                                onChange={value => this.changeStateAttribute('name', value)}
                                maxLength={255}
                                {...disabled}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

CultureApp.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    cultureRPG: PropTypes.instanceOf(DtoCultureRPG),
    getLink: PropTypes.func,
    isEnableEdit: PropTypes.bool,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => ({
    cultureRPG: store.CultureReducer.cultureRPG,
    culturesRPG: store.CultureReducer.culturesRPG,
})

const mapDispatchToProps = {
    push,
    toastrError: ToastrAction.error,
    setPopup: SieauAction.setPopup,
    resetCulture: CultureAction.reset,
    deleteCultureRPG: CultureAction.deleteCultureRPG,
    createCultureRPG: CultureAction.createCultureRPG,
    updateCultureRPG: CultureAction.updateCultureRPG,
    fetchCultureRPG: CultureAction.fetchCultureRPG,
    setTitle: HomeAction.setTitle,
}


export default connect(mapStateToProps, mapDispatchToProps)(CultureApp)
