import SliderPanel from 'components/slider/SliderPanel'
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getLabel } from 'utils/StoreUtils'

const Label = ({
    title = '',
    label = '',
}) => !!label && (
    <span>
        {`${title}: ${label}`}
        <br />
    </span>
)

Label.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
}

const TooltipAnalyse = ({
    title = '',
    analyse = {},
    threshold = {},
    overrideColor,
}) => {
    const {
        contributors,
        supports,
        fractions,
        units,
        qualifications,
        remarks,
        status,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        supports: store.SupportReducer.supports,
        fractions: store.FractionReducer.fractions,
        units: store.UnitReducer.units,
        qualifications: store.QualityReducer.qualifications,
        remarks: store.OperationReducer.remarks,
        status: store.QualityReducer.status,
    }), shallowEqual)
    const producer = contributors.find(c => c.id === analyse.producer)
    const laboratory = contributors.find(c => c.id === analyse.labo)
    return (
        <div>
            <Label title={i18n.status} label={getLabel(status, analyse.status)} />
            <Label title={i18n.qualification} label={getLabel(qualifications, analyse.qualification)} />
            <Label title={i18n.remark} label={getLabel(remarks, analyse.remark)} />
            <Label title={i18n.unit} label={getLabel(units, analyse.unit, 'symbolWithCode')} />
            <Label title={i18n.support} label={getLabel(supports, analyse.support, 'labelWithCode')} />
            <Label title={i18n.fraction} label={getLabel(fractions, analyse.fraction, 'labelWithCode')} />
            <Label title={i18n.producer} label={producer && (producer.mnemonique || producer.name) || ''} />
            <Label title={i18n.laboratory} label={laboratory && (laboratory.mnemonique || laboratory.name) || ''} />
            <SliderPanel
                data={{
                    title,
                    subtitle: '',
                    threshold,
                    value: analyse.value,
                }}
                withThresholdLevels
                overrideColor={overrideColor}
            />
        </div>
    )
}

TooltipAnalyse.propTypes = {
    title: PropTypes.string.isRequired,
    analyse: PropTypes.shape({
        producer: PropTypes.string,
        labo: PropTypes.string,
        status: PropTypes.string,
        qualification: PropTypes.string,
        remark: PropTypes.string,
        unit: PropTypes.string,
        support: PropTypes.string,
        fraction: PropTypes.string,
        value: PropTypes.string,
        propTypes: PropTypes.string,
    }).isRequired,
    threshold: PropTypes.shape({
        parameterCode: PropTypes.string,
        threshold1: PropTypes.number,
        threshold2: PropTypes.number,
    }),
    overrideColor: PropTypes.arrayOf(PropTypes.string),
}

export default TooltipAnalyse