import { push } from 'connected-react-router'
import { template } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { PATH_CONTENTS } from '../../home/constants/RouteConstants'
import { getArticleFormat, getDate, getHour } from '../../utils/DateUtil'
import CMSCategoryDto from '../dto/CMSCategoryDto'
import CMSEventDto from '../dto/CMSEventDto'
import { getCmsLevelColor } from '../utils/CmsUtils'
import { sieauTooltip } from 'utils/FormUtils'


const CsmCard = ({
    cms = {},
    cmsCategories = [],
    redirect = () => { },
    sandreCodes,
}) => {
    const status = sandreCodes.find((c) => c.field === 'CMS.STATUS' && c.code === cms.status)
    const category = cmsCategories.find(cat => cat.id === cms.idCategory)
    const categoryIcon = category && category.icon || 'fiber_new'
    const categoryLabel = category && category.title || cms.categoryWPName || ''
    const levelColor = getCmsLevelColor(cms.levelContent)
    const fontColor = levelColor ? 'font-white' : ''
    return (
        <div key={cms.id} className={'collection-item clickable row no-margin'} style={{ display: 'flex' }}
            onClick={() => redirect(`${PATH_CONTENTS}/${cms.idCategory}/${cms.id}/${navigator.language || navigator.userLanguage}`)}
        >
            <div className={`col s3 no-margin ${levelColor} valign-wrapper`}>
                <div className={`valign-wrapper ${fontColor}`}>
                    <i className='material-icons margin-right-1' style={{ fontSize: '3rem' }}>{categoryIcon}</i>
                    <h5>{categoryLabel}</h5>
                </div>
            </div>
            <div className='col s9 no-margin no-padding'>
                <div className='col s12 valign-wrapper'>
                    <div className='col s10'>
                        <h5 className='thin'>{`${getArticleFormat(cms.dateDebut || cms.updateDate)} - ${cms.title}`}</h5>
                    </div>
                    {
                        status && (
                            <div className='col s2'>
                                <span
                                    className={`${levelColor} ${fontColor}`}
                                    style={{
                                        padding: '0 15px',
                                        borderRadius: '10px',
                                        border: 'solid',
                                        borderWidth: '1',
                                        borderColor: fontColor,
                                    }}
                                >
                                    {status.name}
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className='col s12 no-margin valign-wrapper' style={{ backgroundColor: '#eeeeee' }}>
                    <div className='col s9 font-size-10'>
                        {template(i18n.modifyAtBy)({ date: getDate(cms.updateDate), hour: getHour(cms.updateDate), name: cms.login || cms.author })}
                    </div>
                    <div className='col s3'>
                        {
                            !!cms.link && <i
                                className='material-icons right margin-left-1'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    window.open(cms.link, '_blank')
                                }}
                                {...sieauTooltip(cms.link, null, 'bottom')}
                                style={{ fontSize: '1.5rem' }}
                            >link</i>
                        }
                        {!!cms.cmsDocument.length && <i className='material-icons right margin-left-1' style={{ fontSize: '1.5rem' }}>attach_file</i> }
                        {!!(cms.x && cms.y) && <i className='material-icons right margin-left-1' style={{ fontSize: '1.5rem' }}>location_on</i>}
                        {cms.hasTrad && <i className='material-icons right margin-left-1' style={{ fontSize: '1.5rem' }}>translate</i>}
                    </div>
                </div>
            </div>
        </div>
    )
}

CsmCard.propTypes = {
    cms: PropTypes.instanceOf(CMSEventDto),
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),

    redirect: PropTypes.func,
}

const mapStateToProps = store => ({
    cmsCategories: store.EventsReducer.cmsCategories,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    redirect: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(CsmCard)