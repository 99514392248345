import React from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'

import { sieauTooltip } from '../../../utils/FormUtils'
import { useDispatch } from 'react-redux'

// const $ = window.$

const SlideNavLink = ({
    id = '',
    href = '',
    name = '',
    className = '',
    icon = '',
    tooltip = true,
    external = false,
    'data-cy': dataCy = '',
}) => {
    const dispatch = useDispatch()

    // useEffect(() => {
    //     $('.tooltipped').tooltip('remove')
    // })

    const tooltipObj = tooltip ? sieauTooltip(name, null, 'right') : null

    const linkProps = external ? {
        href,
        target: '_blank',
    } : {
        onClick: () => dispatch(push(href)),
    }

    return (
        <li className={className}>
            <a
                className={'waves-effect slide-nav-link'}
                {...tooltipObj}
                {...linkProps}
                data-cy={dataCy}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {!!icon && <i className='padding-glyph material-icons' id={id}>{icon}</i>}
                <span className='truncate'>
                    {name}
                </span>
            </a>
        </li>
    )
}

SlideNavLink.propTypes = {
    id: PropTypes.string,
    href: PropTypes.string.isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    external: PropTypes.bool,
    tooltip: PropTypes.bool,

    'data-cy': PropTypes.string,
}

export default SlideNavLink