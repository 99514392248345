/* eslint-disable consistent-return */
import { Button, Grid, MobileStepper } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import ValidateParameterTab from 'campaign/components/campaignDashboard/quality/stepExportEDILABO/ValidateParameterTab'
import ValidateStationTab from 'campaign/components/campaignDashboard/quality/stepExportEDILABO/ValidateStationTab'
import { hasValue } from 'utils/NumberUtil'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import FormPopupExportEDILABO from 'campaign/components/campaignDashboard/quality/stepExportEDILABO/FormPopupExportEDILABO'
import DtoQualitometerLight from 'quality/dto/DtoQualitometerLight'
import { searchAllCharacters } from 'utils/StringUtil'
import { maxBy } from 'lodash'
import { getDate, getTimestamp } from 'utils/DateUtil'
import JobAction from 'import/actions/JobAction'
import moment from 'moment'

const FORM = 0
const STATION = 1
const PARAM = 2

const NB_STEP = 3

const PopupEdilaboRAI = ({
    isOpen = false,
    onClose = () => {},

    campaign = {},
    selectedStations = [],
    stations = [],
    selectedParameters = [],
}) => {
    const dispatch = useDispatch()
    const {
        user,
        parameters,
        edilaboFiles,
        qualitometers,
    } = useSelector(store => ({
        user: store.UserReducer.user,
        parameters: store.ParameterReducer.parameters,
        edilaboFiles: store.CampaignReducer.edilaboFiles,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const [step, setStep] = useState(FORM)
    const [paramEDILABO, setParamEDILABO] = useState({})
    const [codifications, setCodifications] = useState([])

    const [tmpSelectedStations, setTmpSelectedStations] = useState([])
    const [tmpSelectedParameters, setTmpSelectedParameters] = useState([])
    const [selection, setSelection] = useState()

    useEffect(() => {
        if (isOpen) {
            setStep(FORM)
            setParamEDILABO(() => {
                const lastFile = maxBy(edilaboFiles, 'updateDate')
                const exportPath = lastFile ? lastFile.href.split('EXPORT/EDILABO/')[1].replace(lastFile.label, '') : ''
                const sender = (() => {
                    const samplerCode = hasValue(campaign.samplerCode) ? parseInt(campaign.samplerCode) : undefined
                    const recipientCode = hasValue(campaign.recipientCode) ? parseInt(campaign.recipientCode) : undefined
                    switch (campaign.requestType) {
                        case '1':
                            return samplerCode
                        case '2':
                            return recipientCode
                        case '3':
                            return recipientCode || samplerCode
                        default:
                            return undefined
                    }
                })()
                const receiver = hasValue(campaign.contributorCode) ? parseInt(campaign.contributorCode) : undefined
                return {
                    exportPath,
                    startDate: getTimestamp(campaign.beginningApplication),
                    endDate: getTimestamp(campaign.endingApplication),
                    receiver,
                    producer: receiver,
                    sender,
                    laboratory: sender,
                }
            })
            setTmpSelectedStations(selectedStations)
            setTmpSelectedParameters(selectedParameters)
        }
    }, [campaign.beginningApplication, campaign.contributorCode, campaign.endingApplication, campaign.recipientCode, campaign.requestType, campaign.samplerCode, edilaboFiles, isOpen, selectedParameters, selectedStations])

    const isNextDisabled = useMemo(() => {
        const {
            sender,
            receiver,
            requestName,
            startDate,
            endDate,
        } = paramEDILABO
        return (step === STATION && !tmpSelectedStations.length)
            || (step === FORM && (!hasValue(sender) || !hasValue(receiver) || !requestName || !startDate || !endDate))
            || step === PARAM
    }, [paramEDILABO, step, tmpSelectedStations.length])

    const onExport = () => {
        const {
            endDate,
            exportPath,
            receiver,
            producer,
            requestName,
            sender,
            laboratory,
            startDate,
        } = paramEDILABO

        const param = {
            campaign: campaign.id,
            labo: laboratory,
            producer,
            startDate,
            endDate,
            transmitter: sender,
            receiver,
            requestName,
            exportPath,
            selectedParameters: tmpSelectedParameters.map(sp => ({ parameterCode: sp.parameterCode, unitCode: sp.unitCpde })),
            listStation: tmpSelectedStations.map(id => {
                const qualitometer = qualitometers.find(q => q.id === id)
                if (!qualitometer) {
                    return
                }
                return {
                    id,
                    code: qualitometer.code,
                    name: qualitometer.name,
                    x: qualitometer.x,
                    y: qualitometer.y,
                    projection: qualitometer.projection,
                    codification: codifications.find(c => c.stationId === id)?.codification,
                }
            }).filter(q => !!q),
        }
        const newJob = {
            jobType: 'edilaboRai',
            login: user.login,
            name: `EDILABO_RAI_${campaign.id}_${getDate(moment().valueOf())}`,
            parameters: {
                parameters: [JSON.stringify(param)],
                filters: [],
                dataTypes: [],
                alertTypes: [],
                import: {},
            },
        }

        dispatch(JobAction.addJob(newJob, false)).then((json = { error: true }) => {
            if (!json.error) {
                dispatch(JobAction.executeJob(json.id))
                onClose()
            }
        })
    }


    return (
        <DialogMUI
            open={isOpen}
            fullWidth
            maxWidth='xl'
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {`${i18n.step} ${step + 1}/${NB_STEP}: ${i18n.EDILABORAI}`}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                {
                    step === FORM && (
                        <FormPopupExportEDILABO
                            paramEDILABO={paramEDILABO}
                            onChange={obj => setParamEDILABO(prevParam => ({ ...prevParam, ...obj }))}
                            fullForm
                        />
                    )
                }
                {
                    step === STATION && (
                        <ValidateStationTab
                            stationType={'quality'}
                            stations={stations.filter(s => !!selectedStations.find(stationId => stationId === s.id))}
                            selectedStations={tmpSelectedStations}
                            codifications={codifications}
                            onChangeCodifications={newCodi => setCodifications(prevCodifications => [...prevCodifications.filter(c => c.stationId !== newCodi.stationId), newCodi])}
                            onChangeSelectedStation={setTmpSelectedStations}
                        />
                    )
                }
                {
                    step === PARAM && (
                        <ValidateParameterTab
                            parameters={parameters.filter(p => p.active).reduce((acc, p) => {
                                const param = selectedParameters.find(({ parameterCode }) => parameterCode === p.code)
                                if (param) {
                                    acc.push({
                                        ...p,
                                        ...param,
                                        labelSearch: searchAllCharacters(p.code + p.name),
                                    })
                                }
                                return acc
                            }, [])}
                            selectedParameters={tmpSelectedParameters}
                            parameterListCode={selection}
                            selections={campaign.selections}
                            onChangeSelection={(tmpSelection, tmpParameters) => {
                                setTmpSelectedParameters(tmpParameters)
                                setSelection(tmpSelection)
                            }}
                            onChangeSelectedParameter={setTmpSelectedParameters}
                        />
                    )
                }
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item xs={1} />
                    <Grid item xs={6}>
                        <MobileStepper
                            variant='progress'
                            steps={NB_STEP}
                            position='static'
                            activeStep={step}
                            sx={{ height: 10 }}
                            nextButton={
                                <Button
                                    size='small'
                                    onClick={() => {
                                        setStep(s => s + 1)
                                    }}
                                    disabled={isNextDisabled}
                                    variant='outlined'
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                            backgroundColor: 'white',
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgb(53, 96, 159, 0.04)',
                                        },
                                    }}
                                >
                                    {i18n.next}
                                    <Icon size='small' icon='keyboard_arrow_right' />
                                </Button>
                            }
                            backButton={
                                <Button
                                    size='small'
                                    onClick={() => setStep(s => s - 1)}
                                    disabled={step === FORM}
                                    variant='outlined'
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                            backgroundColor: 'white',
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgb(53, 96, 159, 0.04)',
                                        },
                                    }}
                                >
                                    <Icon size='small' icon='keyboard_arrow_left' />
                                    {i18n.previous}
                                </Button>
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            onClick={onExport}
                            disabled={step !== PARAM || !tmpSelectedParameters.length}
                            variant='contained'
                        >
                            {i18n.export}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PopupEdilaboRAI.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,

    campaign: PropTypes.shape({
        id: PropTypes.number,
        recipientCode: PropTypes.string,
        contributorCode: PropTypes.string,
        samplerCode: PropTypes.string,
        requestType: PropTypes.string,
        parameterListCode: PropTypes.string,
    }),
    selectedStations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
}

export default PopupEdilaboRAI