import { differenceWith, flatten, groupBy, orderBy, uniqBy } from 'lodash'
import moment from 'moment'
import { enumerateBetweenDates, getDate, getMonthYear, getWeekYear } from 'utils/DateUtil'

const DATA_DISPLAY = {
    ANALYSIS: 'analysis',
    OPERATION: 'operation',
}

const USED_TIME = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
}

const formatHeaderDate = (date, usedTime) => {
    switch (usedTime) {
        case USED_TIME.DAY: return getDate(date)
        case USED_TIME.WEEK: return `S${getWeekYear(date)}`
        case USED_TIME.MONTH: return getMonthYear(date)
        default: return getDate(date)
    }
}

const formatCell = (campaignParameters, operations, analysis, usedData) => {
    switch (usedData) {
        case DATA_DISPLAY.OPERATION: return operations.length || ''
        case DATA_DISPLAY.ANALYSIS:
            if (!operations.length) {
                return ''
            }
            return `${analysis.length}/${campaignParameters.length}`
        default: return 0
    }
}

const getNbData = (operations, analysis, usedData) => {
    switch (usedData) {
        case DATA_DISPLAY.OPERATION: return operations.length
        case DATA_DISPLAY.ANALYSIS: return analysis.length
        default: return 0
    }
}

const getColor = (campaignParameters, analysis) => {
    const nbAnalysis = analysis.length
    if (nbAnalysis >= campaignParameters.length) {
        return 'green'
    } else if (nbAnalysis < campaignParameters.length && nbAnalysis !== 0) {
        return 'orange'
    } else if (nbAnalysis === 0) {
        return 'red'
    }
    return 'blue'
}

const getProgressPlanning = (campaignPlannings, campaignParameters, operations, analysis, id) => {
    const filteredPlanings = campaignPlannings.filter(p => p.stationsPlan.includes(id))
    const groupByOperation = groupBy(analysis, 'operation')

    const listPlanningAnalysis = filteredPlanings.map(p => {
        const planningOperations = operations.filter(o => p.startDate <= o.date && p.endDate > o.date)
        return planningOperations.flatMap(o => groupByOperation[o.id] ?? [])
    })

    // ?
    const progress = listPlanningAnalysis.filter(listAnalysis => {
        return uniqBy(listAnalysis, 'parameter').length === campaignParameters.length
    }).length

    const progressAnalysis = uniqBy(flatten(listPlanningAnalysis), 'parameter').length

    return {
        total: filteredPlanings.length,
        progress,
        progressAnalysis,
    }
}

const getProgressMonth = (campaignParameters, operations, analysis, startDate, endDate) => {
    const rangeDate = enumerateBetweenDates(startDate, endDate, 'month')
    const orderedDate = orderBy(rangeDate, date => date.valueOf())
    const formatedDate = orderedDate.map(date => getMonthYear(date))
    const groupOperations = groupBy(operations, o => getMonthYear(o.date))
    const groupByOperation = groupBy(analysis, 'operation')

    const listPlanningAnalysis = formatedDate.map(date => {
        return groupOperations[date]?.flatMap(o => groupByOperation[o.id] ?? []) || []
    })

    const progress = listPlanningAnalysis.filter(listAnalysis => {
        return uniqBy(listAnalysis, 'parameter').length === campaignParameters.length
    }).length

    const progressAnalysis = uniqBy(flatten(listPlanningAnalysis), 'parameter').length

    return {
        total: formatedDate.length,
        progress,
        progressAnalysis,
    }
}

const getProgressColor = (progress, total, progressAnalysis) => {
    if (total === 0 || (progress === 0 && progressAnalysis === 0)) {
        return 'red'
    }
    if (total === progress) {
        return 'green'
    }
    return 'orange'
}

const getMissingPlanningAnalysis = (qualitometer, plannings, operations, analysis) => {
    const filteredPlanings = plannings.filter(p => p.stationsPlan.includes(qualitometer.id))

    const groupByOperation = groupBy(analysis, 'operation')

    return filteredPlanings.flatMap(p => {
        const planningOperations = operations.filter(o => p.startDate <= o.date && p.endDate > o.date)
        const planningAnalysis = planningOperations.flatMap(o => groupByOperation[o.id])
        const diff = differenceWith(p.parametersPlan, planningAnalysis, (plan, ana) => plan.parameterCode === ana.parameter)
        return diff.map(d => ({
            startDate: p.startDate,
            endDate: p.endDate,
            ...d,
        }))
    })
}

const getMissingMonthAnalysis = (startDate, endDate, parameters, operations, analysis) => {
    const rangeDate = enumerateBetweenDates(startDate, endDate, 'month').map(d => d.valueOf())
    const orderedDate = orderBy(rangeDate, date => date)
    const groupByOperation = groupBy(analysis, 'operation')

    return orderedDate.flatMap(startMonth => {
        const endMonth = moment(startMonth).add(1, 'months').valueOf()
        const filteredOperations = operations.filter(o => startMonth <= o.date && endMonth > o.date)
        const filteredAnalysis = filteredOperations.flatMap(o => groupByOperation[o.id])
        const diff = differenceWith(parameters, filteredAnalysis, (cp, ana) => cp.parameterCode === ana.parameter)
        return diff.map(d => ({
            startDate: startMonth,
            endDate: endMonth,
            ...d,
        }))
    })
}

export {
    DATA_DISPLAY,
    USED_TIME,

    formatHeaderDate,
    formatCell,
    getNbData,
    getColor,
    getProgressPlanning,
    getProgressMonth,
    getProgressColor,

    getMissingPlanningAnalysis,
    getMissingMonthAnalysis,
}