import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import DtoInstallationTank from '../../../../installation/dto/installation/DtoInstallationTank'
import StationTankPanel from '../StationTankPanel'

class TankModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tank: props.tank,
        }
    }

    onChangeValue = (obj) => {
        this.setState(({ tank }) => ({
            tank: {
                ...tank,
                ...obj,
            },
        }))
    }

    onCancel = () => {
        this.setState({ tank: null })
        this.props.onCancel()
    }

    onValidate = () => {
        const { tank } = this.state
        this.props.onValidate(tank)
    }

    render = () => {
        const { open, title, lockId } = this.props
        const { tank } = this.state

        return (
            <Dialog
                open={open}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>{title || i18n.updateTank}</DialogTitle>
                <DialogContent>
                    <StationTankPanel
                        tank={tank}
                        onChange={this.onChangeValue}
                        lockId={lockId}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={this.onCancel}>
                        {i18n.cancel}
                    </Button>
                    <Button variant='contained' color='primary' onClick={this.onValidate}>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

TankModal.propTypes = {
    open: PropTypes.bool,
    lockId: PropTypes.bool,
    tank: PropTypes.instanceOf(DtoInstallationTank),
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
    title: PropTypes.string,
}

export default TankModal