import { Button, Grid, MobileStepper } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import DtoQualitometerLight from '../../../../../quality/dto/DtoQualitometerLight'
import { getDate } from '../../../../../utils/DateUtil'
import { searchAllCharacters } from '../../../../../utils/StringUtil'
import CampaignPlanningDto from '../../../../dto/CampaignPlanningDto'
import ValidateParameterTab from '../stepExportEDILABO/ValidateParameterTab'
import ValidateStationTab from '../stepExportEDILABO/ValidateStationTab'


const STATION = 0
const PARAM = 1

const PlanningStepper = ({
    isOpen = false,
    isNew,
    onChange,
    planning,
    stations,
    selectedStations,
    selectedParameters,
    selectedSelections,
    onClose = () => { },
    onValidate = () => { },
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const [step, setStep] = useState(STATION)

    const onCloseDialog = () => {
        setStep(STATION)
        onClose()
    }

    const {
        stationsPlan = [],
        parametersPlan = [],
        selection,
        startDate,
        endDate,
    } = planning

    return (
        <DialogMUI
            fullWidth
            maxWidth='xl'
            open={isOpen}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {isNew ? `${i18n.planning} ${i18n.fromDate} ${getDate(startDate)} - ${getDate(endDate)}` : i18n.newPlanning}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onCloseDialog} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <div style={{ height: '65vh' }}>
                    {
                        step === STATION && (
                            <>
                                <StyledFieldSet style={{ padding: '15px 10px' }}>
                                    <StyledLegend>{i18n.planning}</StyledLegend>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <SimpleDatePicker
                                                id='startDate'
                                                label={i18n.startDate}
                                                value={startDate}
                                                onChange={date => onChange({ startDate: date })}
                                                max={endDate}
                                                obligatory
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <SimpleDatePicker
                                                id='endDate'
                                                label={i18n.endDate}
                                                value={endDate}
                                                onChange={date => onChange({ endDate: date })}
                                                min={startDate}
                                                obligatory
                                            />
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                                <ValidateStationTab
                                    stations={stations.filter(({ id }) => !!selectedStations.find(({ id: stationId }) => stationId === id))}
                                    selectedStations={stationsPlan}
                                    stationType={'quality'}
                                    maxHeight={'52vh'}
                                    onChangeSelectedStation={tmpStations => onChange({ stationsPlan: tmpStations })}
                                />
                            </>
                        )
                    }
                    {
                        step === PARAM && (
                            <ValidateParameterTab
                                parameters={parameters.filter(p => p.active).reduce((acc, p) => {
                                    const param = selectedParameters.find(({ parameterCode }) => parameterCode === p.code)
                                    if (param) {
                                        acc.push({
                                            ...p,
                                            ...param,
                                            labelSearch: searchAllCharacters(p.code + p.name),
                                        })
                                    }
                                    return acc
                                }, [])}
                                canEdit
                                selections={selectedSelections}
                                selectedParameters={parametersPlan}
                                parameterListCode={selection}
                                onChangeSelection={(tmpSelection, tmpParameters) => onChange({ parametersPlan: tmpParameters, selection: tmpSelection })}
                                onChangeSelectedParameter={tmpParameters => onChange({ parametersPlan: tmpParameters })}
                            />
                        )
                    }
                </div>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid item xs={1} />
                    <Grid item xs={6}>
                        <MobileStepper
                            variant='progress'
                            steps={2}
                            position='static'
                            activeStep={step}
                            sx={{ height: 10 }}
                            nextButton={
                                <Button
                                    size='small'
                                    onClick={() => {
                                        setStep(s => s + 1)
                                    }}
                                    disabled={step === PARAM}
                                    variant='outlined'
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                            backgroundColor: 'white',
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgb(53, 96, 159, 0.04)',
                                        },
                                    }}
                                >
                                    {i18n.next}
                                    <Icon size='small' icon='keyboard_arrow_right' />
                                </Button>
                            }
                            backButton={
                                <Button
                                    size='small'
                                    onClick={() => setStep(s => s - 1)}
                                    disabled={step === STATION}
                                    variant='outlined'
                                    sx={{
                                        '&:focus': {
                                            outline: 'none',
                                            backgroundColor: 'white',
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgb(53, 96, 159, 0.04)',
                                        },
                                    }}
                                >
                                    <Icon size='small' icon='keyboard_arrow_left' />
                                    {i18n.previous}
                                </Button>
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            onClick={() => {
                                setStep(STATION)
                                onValidate()
                            }}
                            disabled={!stationsPlan.length || !parametersPlan.length || !hasValue(startDate) || !hasValue(endDate)}
                            variant='contained'
                        >
                            {i18n.validate}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PlanningStepper.propTypes = {
    isOpen: PropTypes.bool,
    isNew: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    onChange: PropTypes.func,
    planning: PropTypes.instanceOf(CampaignPlanningDto),
    selectedStations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    selectedSelections: PropTypes.arrayOf(PropTypes.string),
}

export default PlanningStepper