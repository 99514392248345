'use strict'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FixedSlideNav from '../../../../components/navbar/FixedSlideNav'
import { links } from '../../../../quality/constants/StationsConstants'
import { DASHBOARD } from '../../../constants/InstallationConstants'
import { Redirect, Route, Switch } from 'react-router-dom'
import InstallationsDashboardApp from './InstallationsDashboardApp'
import InstallationCounterApp from './InstallationCounterApp'
import InstallationCampaigns from 'installation/components/campaign/InstallationCampaigns'
import InstallationsOptions from 'installation/components/options/InstallationsOptions'
import TerritoryActivities from './TerritoryActivities'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import { connect } from 'react-redux'

class InstallationsApp extends Component {
    componentDidMount() {
        this.props.setHelpLink('', '')
    }

    getLink = (linkName) => {
        if (links[linkName].href) {
            return links[linkName]
        }
        return {
            ...links[linkName],
            href: `/installation/${linkName}`,
        }
    }

    render() {
        const navBarLinks = [DASHBOARD, 'territoryActivities', 'campaign', 'installationOptions', 'counter'].map(linkName => this.getLink(linkName))

        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Switch>
                                    <Redirect exact from='/installation' to='/installation/dashboard' />
                                    <Route path='/installation/dashboard' render={(props) => <InstallationsDashboardApp {...props} />} />
                                    <Route path='/installation/counter' render={(props) => <InstallationCounterApp {...props} />} />
                                    <Route path='/installation/campaign' render={(props) => <InstallationCampaigns {...props} />} />
                                    <Route path='/installation/installationOptions' render={(props) => <InstallationsOptions {...props} />} />
                                    <Route path='/installation/territoryActivities' render={(props) => <TerritoryActivities {...props} />} />
                                    <Route path='/installation/develop' render={(props) => <DevelopmentApp {...props} />} />
                                </Switch>
                                {/* {this.props.children && React.cloneElement(this.props.children, {
                                    getLink: this.props.getLink
                                })} */}
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

InstallationsApp.defaultProps = {
    getLink: ('', ''),
}


InstallationsApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    setHelpLink: PropTypes.func,
}

const mapDispatchToProps = {
    setHelpLink: HomeAction.setHelpLink,
}

export default connect(null, mapDispatchToProps)(InstallationsApp)
