import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import StationAction from 'station/actions/StationAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { PATH_REFERENCIAL_SECTOR, PATH_REFERENCIAL_SECTOR_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import { RESET_SECTOR, RECEIVE_SECTORS, RECEIVE_SECTOR_QUALITOMETERS, RECEIVE_SECTOR } from '../constants/SectorConstants'

const SectorAction = {
    receiveSector(sector) {
        return { type: RECEIVE_SECTOR, sector }
    },

    fetchSector(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.sector(code), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(SectorAction.receiveSector(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.sector} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.sector))
                })
        }
    },

    receiveSectors(sectors) {
        return { type: RECEIVE_SECTORS, sectors }
    },

    promiseSectors() {
        return fetch(ApplicationConf.referencial.sectors(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchSectors() {
        return (dispatch) => {
            return SectorAction.promiseSectors()
                .then((json = []) => {
                    dispatch(SectorAction.receiveSectors(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.sector} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.sector))
                })
        }
    },

    updateSector(sector, id) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.sector(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(sector),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(SectorAction.fetchSector(id))
                        dispatch(ToastrAction.success(i18n.updateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.sector} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.sector))
                })
        }
    },

    createSector(sector) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.sectorCreate(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(sector),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_SECTOR_LINK + json.id))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.sector} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.sector))
                })
        }
    },

    deleteSector(id) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.sector(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_SECTOR))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.sector} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.sector))
                })
        }
    },

    receiveQualitometersAssociations(qualitometers) {
        return { type: RECEIVE_SECTOR_QUALITOMETERS, qualitometers }
    },

    fetchQualitometersAssociations(id, callback = () => { }) {
        return (dispatch) => {
            return StationAction.promiseAssociatedSites(id, 3)
                .then((json = []) => {
                    dispatch(SectorAction.receiveQualitometersAssociations(json))
                    callback()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometer))
                })
        }
    },

    // purgeSector() {
    //     return (dispatch) => {
    //         dispatch(WaitAction.waitStart())
    //         return fetch(ApplicationConf.referencial.purgeSector(), {
    //             method: 'DELETE',
    //             headers: getAuthorization(),
    //         })
    //             .then(checkAuth)
    //             .then(getJson)
    //             .then(checkError)
    //             .then((json) => {
    //                 if (json.purge || json.purge === 0) {
    //                     dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
    //                     dispatch(SectorAction.fetchSectors())
    //                 }
    //                 dispatch(WaitAction.waitStop())
    //             })
    //             .catch(err => {
    //                 dispatch(LogAction.logError(`${i18n.deleteError + i18n.sectors} : ${err}`))
    //                 dispatch(ToastrAction.error(i18n.deleteError + i18n.sectors))
    //             })
    //     }
    // },

    // replaceSector(sector, sectorReplace) {
    //     return (dispatch) => {
    //         dispatch(WaitAction.waitStart())
    //         return fetch(ApplicationConf.referencial.replaceSector(), {
    //             method: 'PUT',
    //             headers: getAuthorization(),
    //             body: JSON.stringify({
    //                 code: sector.toString(),
    //                 replaceCode: sectorReplace.toString(),
    //             }),
    //         })
    //             .then(checkAuth)
    //             .then(getJson)
    //             .then(checkError)
    //             .then(json => {
    //                 if (json.update) {
    //                     dispatch(ToastrAction.success(i18n.sectorSuccessUpdated))
    //                 } else if (json.update == 0) {
    //                     dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
    //                 } else {
    //                     throw new Error()
    //                 }
    //                 dispatch(WaitAction.waitStop())
    //             })
    //             .catch(err => {
    //                 dispatch(WaitAction.waitStop())
    //                 dispatch(LogAction.logError(`${i18n.replace + i18n.sector} : ${err}`))
    //             })
    //     }
    // },

    reset() {
        return { type: RESET_SECTOR }
    },

}

export default SectorAction
