import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DtoStation from '../../../dto/DtoStation'
import { connect } from 'react-redux'
import {
    PATH_MATERIEL_CENTRAL,
    PATH_MATERIEL_EQUIPMENT,
    PATH_MATERIEL_POWER_SUPPLY,
    PATH_MATERIEL_SENSOR,
    PATH_MATERIEL_SIM,
    PATH_MATERIEL_SUBSCRIPTION,
    PATH_MATERIEL_TELECOM,
    PATH_MATERIEL_VARIOUS_MATERIEL,
} from '../../../../home/constants/RouteConstants'
import DtoMaterielState from '../../../../materiel/dto/DtoMaterielState'
import MaterielAction from '../../../../materiel/actions/MaterielAction'
import CentralSituationDetail from './situationMaterielDetail/CentralSituationDetail'
import EquipmentSituationDetail from './situationMaterielDetail/EquipmentSituationDetail'
import PowerSupplySituationDetail from './situationMaterielDetail/PowerSupplySituationDetail'
import SensorSituationDetail from './situationMaterielDetail/SensorSituationDetail'
import TelecomSituationDetail from './situationMaterielDetail/TelecomSituationDetail'
import SimSituationDetail from './situationMaterielDetail/SimSituationDetail'
import VariousMaterielSituationDetail from './situationMaterielDetail/VariousMaterielSituationDetail'
import NetworkAction from '../../../../referencial/components/network/actions/NetworkAction'
import { getStation } from '../../../../utils/StationUtils'
import SubscriptionSituationDetail from './situationMaterielDetail/SubscriptionSituationDetail'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'

class SituationDetailApp extends Component {
    componentDidMount() {
        if (!this.props.materielStates.length) {
            this.props.fetchMaterielStates()
        }
        this.props.fetchNetworks()
    }

    render() {
        const {
            materialType,
            type,
        } = this.props.match.params
        const station = getStation(this.props, type)
        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        {
                            materialType === PATH_MATERIEL_CENTRAL && (
                                <CentralSituationDetail
                                    station={ station }
                                    params={ this.props.match.params }
                                />
                            )
                        }
                        {
                            materialType === PATH_MATERIEL_EQUIPMENT && (
                                <EquipmentSituationDetail
                                    station={ station }
                                    params={ this.props.match.params }
                                />
                            )
                        }
                        {
                            materialType === PATH_MATERIEL_POWER_SUPPLY && (
                                <PowerSupplySituationDetail
                                    station={ station }
                                    params={ this.props.match.params }
                                />
                            )
                        }
                        {
                            materialType === PATH_MATERIEL_SENSOR && (
                                <SensorSituationDetail
                                    station={ station }
                                    params={ this.props.match.params }
                                />
                            )
                        }
                        {
                            materialType === PATH_MATERIEL_SIM && (
                                <SimSituationDetail
                                    station={ station }
                                    params={ this.props.match.params }
                                />
                            )
                        }
                        {
                            materialType === PATH_MATERIEL_TELECOM && (
                                <TelecomSituationDetail
                                    station={ station }
                                    params={ this.props.match.params }
                                />
                            )
                        }
                        {
                            materialType === PATH_MATERIEL_VARIOUS_MATERIEL && (
                                <VariousMaterielSituationDetail
                                    station={ station }
                                    params={ this.props.match.params }
                                />
                            )
                        }
                        {
                            materialType === PATH_MATERIEL_SUBSCRIPTION && (
                                <SubscriptionSituationDetail
                                    station={ station }
                                    params={ this.props.match.params }
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

SituationDetailApp.propTypes = {
    params: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),

    qualitometer: PropTypes.instanceOf(DtoStation),
    piezometer: PropTypes.instanceOf(DtoStation),
    pluviometer: PropTypes.instanceOf(DtoStation),
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    installation: PropTypes.instanceOf(DtoStation),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),

    fetchMaterielStates: PropTypes.func,
    fetchNetworks: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    qualitometer: store.QualityReducer.qualitometer,
    pluviometer: store.PluviometryReducer.pluviometer,
    hydrometricStation: store.HydrometryReducer.hydrometricStation,
    installation: store.InstallationReducer.installation,
    materielStates: store.MaterielReducer.materielStates,
})

const mapDispatchToProps = {
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchNetworks: NetworkAction.fetchNetworks,
}

export default connect(mapStateToProps, mapDispatchToProps)(SituationDetailApp)