import { H_CATCHMENT_DASHBOARD, H_CATCHMENT_PCMONITORING, H_CATCHMENT_CONFORMITY } from 'account/constants/AccessRulesConstants'
import CatchmentAction from 'catchment/actions/CatchmentAction'
import CatchmentThunk from 'catchment/actions/CatchmentThunk'
import { CatchmentActionConstant } from 'catchment/reducers/CatchmentReducer'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import InstallationAction from 'installation/actions/InstallationAction'
import BoundaryError from 'log/components/BoundaryError'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PropTypes from 'prop-types'
import QualityAction from 'quality/actions/QualityAction'
import OperationAction from 'quality/components/operation/actions/OperationAction'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import CatchmentAEPApp from './CatchmentAEPApp'
import CatchmentDashboardApp from './dashboard/CatchmentDashboardApp'
import CatchmentDescriptionApp from './CatchmentDescriptionApp'
import CatchmentMapApp from './CatchmentMapApp'
import CatchmentPcMonitoringApp from './CatchmentPcMonitoringApp'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'

const CatchmentApp = ({ match }) => {
    const dispatch = useDispatch()

    const { id } = match.params

    const {
        catchment,
        piezometers,
        qualitometers,
        installations,
        pluviometers,
        hydrometricStations,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        piezometers: store.PiezometryReducer.piezometersLight,
        qualitometers: store.QualityReducer.qualitometersLight,
        installations: store.InstallationReducer.installationsLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const navLinks = useMemo(() => {
        const hasQualito = catchment.catchmentPoints?.some(point => qualitometers.some(p => p.code === point.codeWithoutDesignation))
        const qualityFeatures = hasQualito ? [{
            icons: 'assignment_turned_in',
            name: i18n.followUpPC,
            href: `/station/catchment/${id}/pcMonitoring`,
            habilitation: H_CATCHMENT_PCMONITORING,
            'data-cy': 'nav_pcMonitoring',
        }, {
            icons: 'playlist_add_check',
            name: i18n.AEPoverview,
            href: `/station/catchment/${id}/conformityOverview`,
            habilitation: H_CATCHMENT_CONFORMITY,
            'data-cy': 'nav_AEPoverview',
        }] : []
        return [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: `/station/catchment/${id}/dashboard`,
            habilitation: H_CATCHMENT_DASHBOARD,
            'data-cy': 'nav_dashboard',
        }, {
            icons: 'compare_arrows',
            name: i18n.descriptive,
            href: `/station/catchment/${id}/description`,
            habilitation: H_CATCHMENT_DASHBOARD,
            'data-cy': 'nav_desciption',
        }, {
            icons: 'map',
            name: i18n.cartography,
            href: `/station/catchment/${id}/map`,
            habilitation: H_CATCHMENT_DASHBOARD,
            'data-cy': 'nav_cartography',
        },
        ...qualityFeatures].filter(l => componentHasHabilitations(l.habilitation))
    }, [catchment.catchmentPoints, id, qualitometers])

    useEffect(() => {
        dispatch(CatchmentThunk.fetchCatchments())
        dispatch(ReferencialAction.fetchSandreCodes())
        dispatch(ParameterAction.fetchParameters())
        dispatch(UnitAction.fetchUnits())
        dispatch(CityAction.fetchCities())
        dispatch(CityAction.fetchDepartment())

        dispatch(QualityAction.fetchQualifications())
        dispatch(QualityAction.fetchStatus())
        dispatch(OperationAction.fetchRemarks())
        dispatch(SupportAction.fetchSupports())
        dispatch(FractionAction.fetchFractions())

        if (!piezometers.length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!qualitometers.length) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
        if (!installations.length) {
            dispatch(InstallationAction.fetchInstallationsLight())
        }
        if (!pluviometers.length) {
            dispatch(PluviometryAction.fetchPluviometers())
        }
        if (!hydrometricStations.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        return () => {
            dispatch(CatchmentActionConstant.reset())
        }
    }, [])

    useEffect(() => {
        if (id !== 'new') {
            dispatch(CatchmentAction.fetchCatchment(id))
        }
    }, [id])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/station/catchment/:id' to='/station/catchment/:id/dashboard' />
                                <Route path='/station/catchment/:id/dashboard' render={(props) => <CatchmentDashboardApp {...props} />} />
                                <Route path='/station/catchment/:id/description' render={props => <CatchmentDescriptionApp {...props} />} />
                                <Route path='/station/catchment/:id/map' render={props => <CatchmentMapApp {...props} />} />
                                <Route path='/station/catchment/:id/pcMonitoring' render={props => <CatchmentPcMonitoringApp {...props} />} />
                                <Route path='/station/catchment/:id/conformityOverview' render={props => <CatchmentAEPApp {...props} />} />
                            </Switch>
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

CatchmentApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default CatchmentApp