import fetch from 'isomorphic-fetch'
import { RECEIVE_ALL_NETWORKS, RECEIVE_NETWORK, RESET_NETWORK } from '../constants/NetworkConstants'
import ApplicationConf from 'conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import AppStore from 'store/AppStore'
import i18n from 'simple-react-i18n'
import { push } from 'connected-react-router'
import { PATH_REFERENCIAL_NETWORK, PATH_REFERENCIAL_NETWORK_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'

const NetworkAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().NetworkReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    receiveNetworks(networks) {
        return { type: RECEIVE_ALL_NETWORKS, networks }
    },

    receiveNetwork(network) {
        return { type: RECEIVE_NETWORK, network }
    },

    fetchNetwork(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.network(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(NetworkAction.receiveNetwork(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.network} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.network))
                })
        }
    },

    promiseNetworks() {
        return fetch(ApplicationConf.referencial.networks(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchNetworks() {
        return (dispatch) => {
            return NetworkAction.promiseNetworks()
                .then((json = []) => {
                    dispatch(NetworkAction.receiveNetworks(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.networks} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.networks))
                })
        }
    },

    createNetwork(network) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.networks(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(network),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_NETWORK_LINK + network.code))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.network} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.network))
                })
        }
    },

    updateNetwork(network, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveNetwork(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(network),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(NetworkAction.fetchNetwork(code))
                        dispatch(ToastrAction.success(i18n.networkSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.network} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.network))
                })
        }
    },

    deleteNetwork(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteNetwork(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_NETWORK))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.network} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.network))
                })
        }
    },

    purgeNetwork() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeNetwork(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(NetworkAction.fetchNetworks())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.networks} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.networks))
                })
        }
    },

    replaceNetwork(network, networkReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceNetwork(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: network,
                    replaceCode: networkReplace,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.networkSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.network} : ${err}`))
                })
        }
    },

    fetchSynchroSandreNetwork() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateNetwork(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(NetworkAction.fetchNetworks())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.network + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.network))
                })
        }
    },

    resetNetwork() {
        return { type: RESET_NETWORK }
    },
}

export default NetworkAction
