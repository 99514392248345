import React from 'react'
import { MODEL_STATUS, HORIZON_TYPE } from '../constants/IAEauConstants'
import { round } from '../../utils/NumberUtil'
import i18n from 'simple-react-i18n'

const getModelStatusLabel = (status) => {
    switch (status) {
        case MODEL_STATUS.WARNING:
            return i18n.successWithWarnings
        case MODEL_STATUS.PROGRESS:
            return i18n.inProgress
        case MODEL_STATUS.SUCCESS:
            return i18n.success
        case MODEL_STATUS.DEMAND_SENT:
            return i18n.demandSent
        case MODEL_STATUS.DEMAND_PROGRESS:
            return i18n.demandProgress
        case MODEL_STATUS.WAITING:
            return i18n.waiting
        case MODEL_STATUS.LEARNING_PROGRESS:
            return i18n.learningProgress
        case MODEL_STATUS.LEARNING_FINISHED:
            return i18n.learningFinished
        default:
            return i18n.error
    }
}

const getStatusColor = (status) => {
    switch (status) {
        case MODEL_STATUS.WARNING:
            return 'green'
        case MODEL_STATUS.PROGRESS:
            return 'blue'
        case MODEL_STATUS.SUCCESS:
            return 'green'
        case MODEL_STATUS.DEMAND_SENT:
            return 'orange'
        case MODEL_STATUS.DEMAND_PROGRESS:
            return 'blue'
        case MODEL_STATUS.WAITING:
            return 'blue'
        case MODEL_STATUS.LEARNING_PROGRESS:
            return '#53A1FF'
        case MODEL_STATUS.LEARNING_FINISHED:
            return '#53A1FF'
        default:
            return 'red'
    }
}

const getModelStatusIcon = (status, size = 50) => {
    const icon = (() => {
        switch (status) {
            case MODEL_STATUS.WARNING:
                return 'done'
            case MODEL_STATUS.PROGRESS:
                return 'sync'
            case MODEL_STATUS.SUCCESS:
                return 'done'
            case MODEL_STATUS.DEMAND_SENT:
                return 'announcement'
            case MODEL_STATUS.DEMAND_PROGRESS:
                return 'rate_review'
            case MODEL_STATUS.WAITING:
                return 'hourglass_full'
            case MODEL_STATUS.LEARNING_PROGRESS:
                return 'adb'
            case MODEL_STATUS.LEARNING_FINISHED:
                return 'adb'
            default:
                return 'error'
        }
    })()
    const color = getStatusColor(status)

    if (status === MODEL_STATUS.WARNING) {
        return (
            <div style={{ position: 'relative', width: `${size}px` }}>
                <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color, fontSize: `${size}px`, width: `${size}px`, height: `${size}px`, position: 'relative' }}>{ icon }</i>
                <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color: 'orange', fontSize: `${round(size/2, 0)}px`, width: `${round(size/2, 0)}px`, height: `${round(size/2, 0)}px`, position: 'absolute', right: 0, bottom: 0, zIndex: 10 }}>warning</i>
            </div>
        )
    }
    if (icon === 'adb') {
        return (
            <div style={{ position: 'relative', width: `${size}px` }}>
                <i className={ 'material-icons' } style={{ color, fontSize: `${size}px`, width: `${size}px`, height: `${size}px`, position: 'relative' }}>{ icon }</i>
                <i className={ `material-icons ${status === MODEL_STATUS.LEARNING_PROGRESS ? 'rotate' : ''}` } style={{ color: 'blue', fontSize: `${round(size/2, 0)}px`, width: `${round(size/2, 0)}px`, height: `${round(size/2, 0)}px`, position: 'absolute', right: 0, bottom: 0, zIndex: 10 }}>{status === MODEL_STATUS.LEARNING_PROGRESS ? 'sync' : 'done'}</i>
            </div>
        )
    }

    return <i className={ `material-icons ${icon === 'sync' ? 'rotate' : ''}` } style={{ color, fontSize: `${size}px`, width: `${size}px`, height: `${size}px` }}>{ icon }</i>
}

const getMeasureJplus = (measure) => {
    return measure?.horizon ? `(${HORIZON_TYPE[measure?.horizonMode || 'days']}+${measure?.horizon})` : ''
}

export { getModelStatusIcon, getModelStatusLabel, getMeasureJplus }