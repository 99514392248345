import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import { push } from 'connected-react-router'
import { isEqual, omit } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import ActionComponent from '../../../../components/ActionComponent'
import Card from '../../../../components/card/Card'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import SieauAction from '../../../../components/sieau/SieauAction'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CULTURE,
    PATH_REFERENCIAL_CULTURE_LINK,
    PATH_REFERENCIAL_CULTURE_NEW,
} from '../../../../home/constants/RouteConstants'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import CulturesFamilyAction from '../../culturesFamilies/actions/CulturesFamilyAction'
import DtoCulturesFamily from '../../culturesFamilies/dto/DtoCulturesFamily'
import CultureAction from '../actions/CultureAction'
import { seasonList } from '../constants/CultureConstants'
import DtoCulture from '../dto/DtoCulture'

moment.locale('fr')

class CultureApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            isEditMode: props.isEditMode,
            culture: { limitCropRotations: true },
        }
    }

    componentDidMount() {
        const { params } = this.props.match
        if (params.id !== 'new') {
            this.changeEditMode(false)
            this.props.fetchCulture(params.id)
        } else {
            this.changeEditMode(true)
        }
        this.props.fetchCulturesFamilies()
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarCulture()
    }

    changeStateAttribute = (attr, value) => {
        this.setState(({ culture }) => ({
            culture: {
                ...culture,
                [attr]: value,
            },
        }))
    }

    componentWillUnmount = () => this.props.resetCulture()

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    toNavbarCulture() {
        const { params } = this.props.match
        const { isEditMode } = this.state
        const actions = (() => {
            if (params.id === 'new' && (!this.props.culture || !this.props.culture.id)) {
                return {
                    save: () => {
                        const { culture } = this.state
                        this.props.createCulture(culture)
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.props.push(PATH_REFERENCIAL_CULTURE)
                        this.changeEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => {
                        const { culture } = this.state
                        this.props.updateCulture({ ...culture, usermaj: getUser().login }, culture.id)
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.setState({ culture: this.props.culture })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidUpdate = prevProps => {
        const { culture } = this.props
        const { params } = this.props.match
        const { isEditMode } = this.state
        if (!isEqual(prevProps.culture, culture)) {
            this.setState({ culture })
        }
        if (!isEqual(isEditMode, prevProps.isEditMode)) {
            this.toNavbarCulture()
        }
        if (prevProps.match.params.id !== params.id) {
            this.props.fetchCulture(params.id)
        } else if (params.id === 'new') {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.cultures,
                    href: PATH_REFERENCIAL_CULTURE,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_CULTURE_NEW,
                },
            ])
        } else if (!isEqual(prevProps.culture, culture)) {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.cultures,
                    href: PATH_REFERENCIAL_CULTURE,
                },
                {
                    title: params.id + (culture.name ? ` - ${culture.name}` : ''),
                    href: PATH_REFERENCIAL_CULTURE_LINK + params.id,
                },
            ])
            this.setState({ culture })
            this.toNavbarCulture()
        }
    }

    render() {
        const { culturesFamilies, culturesRPG } = this.props
        const { isEditMode, culture } = this.state
        const disabled = {
            active: isEditMode,
            disabled: !isEditMode,
        }

        return (
            <div className='row no-margin padding-1'>
                <Card title={i18n.description} maxWidth={1000}>
                    <div className='padding-1'>
                        <div className='row no-margin padding-top-3-px'>
                            <Input
                                col={6}
                                title={i18n.name}
                                value={culture.name}
                                onChange={value => this.changeStateAttribute('name', value)}
                                maxLength={255}
                                {...disabled}
                            />
                            <Select
                                col={3}
                                label={i18n.status}
                                value={culture.status}
                                options={[{
                                    value: 1,
                                    label: i18n.activated,
                                }, {
                                    value: 2,
                                    label: i18n.disabled,
                                }]}
                                onChange={value => this.changeStateAttribute('status', value)}
                                integerValue
                                returnNull
                                {...disabled}
                            />
                            <NumberField
                                col={3}
                                title={i18n.id}
                                value={culture.id}
                                onChange={value => {
                                    this.changeStateAttribute('id', value)
                                }}
                                disabled
                            />
                        </div>
                        <div className='row no-margin padding-top-3-px'>
                            <Select
                                col={6}
                                label={i18n.family}
                                value={culture.idFamille}
                                options={culturesFamilies}
                                onChange={value => this.changeStateAttribute('idFamille', value)}
                                keyValue='id'
                                integerValue
                                returnNull
                                {...disabled}
                            />
                            <Select
                                col={6}
                                label={i18n.periodeSemence}
                                value={culture.periodeSemence}
                                options={seasonList}
                                onChange={value => this.changeStateAttribute('periodeSemence', value)}
                                keyLabel='label'
                                integerValue
                                returnNull
                                {...disabled}
                            />
                        </div>
                        <div className='row no-margin padding-top-3-px'>
                            <MultiAutocomplete
                                col={12}
                                label='RPG'
                                elements={culturesRPG}
                                clearFunction
                                keyName='codeWithName'
                                keyId='code'
                                values={culture.linkCulturesRPG}
                                onChange={values => this.changeStateAttribute('linkCulturesRPG', values)}
                                {...disabled}
                            />
                        </div>
                        <div className='row no-margin padding-top-3-px'>
                            <Checkbox
                                label={i18n.referencial}
                                checked={culture.referentiel}
                                onChange={v => {
                                    this.changeStateAttribute('referentiel', v)
                                }}
                                {...disabled}
                            />
                            <Checkbox
                                label={i18n.teteRotation}
                                checked={culture.teteRotation}
                                onChange={v => {
                                    this.changeStateAttribute('teteRotation', v)
                                }}
                                {...disabled}
                            />
                            <Checkbox
                                label={i18n.legumineuse}
                                checked={culture.legumineuse}
                                onChange={v => {
                                    this.changeStateAttribute('legumineuse', v)
                                }}
                                {...disabled}
                            />
                            <Checkbox
                                label={i18n.limitCropRotations}
                                checked={culture.limitCropRotations}
                                onChange={v => {
                                    this.changeStateAttribute('limitCropRotations', v)
                                }}
                                {...disabled}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

CultureApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    culture: PropTypes.instanceOf(DtoCulture),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    getLink: PropTypes.func,
    isEnableEdit: PropTypes.bool,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => ({
    culture: store.CultureReducer.culture,
    culturesRPG: store.CultureReducer.culturesRPG,
    culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
})

const mapDispatchToProps = {
    push,
    toastrError: ToastrAction.error,
    setPopup: SieauAction.setPopup,
    resetCulture: CultureAction.reset,
    deleteCulture: CultureAction.deleteCulture,
    createCulture: CultureAction.createCulture,
    updateCulture: CultureAction.updateCulture,
    fetchCulture: CultureAction.fetchCulture,
    fetchCulturesFamilies: CulturesFamilyAction.fetchCulturesFamilies,
    fetchCheckDelete: ReferencialAction.fetchCheckDelete,
    setTitle: HomeAction.setTitle,
}


export default connect(mapStateToProps, mapDispatchToProps)(CultureApp)
