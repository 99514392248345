import React from 'react'
import PropTypes from 'prop-types'

const Row = ({
    className = '',
    children = undefined,
    displayIf = true,
    noMargin = true,
    onClick = () => {},
    style = {},
}) => {
    if (displayIf) {
        return (
            <div className={`row ${noMargin && 'no-margin' || ''} ${className}`} onClick={ onClick } style={style}>
                {children}
            </div>
        )
    }
    return null
}

Row.propTypes = {
    className: PropTypes.string,
    style: PropTypes.instanceOf({}),
    children: PropTypes.element,
    displayIf: PropTypes.bool,
    noMargin: PropTypes.bool,
    onClick: PropTypes.func,
}

Row.defaultProps = {
    className: '',
    displayIf: true,
}

export default Row
