/* eslint-disable consistent-return */
import { Button, Grid } from '@mui/material'
import { styled } from '@mui/styles'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { groupBy, isNil, isUndefined, keyBy, maxBy, orderBy, sum, sumBy, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import DtoAnalysis from 'quality/dto/analyse/DtoAnalysis'
import DtoSearchIndices from 'quality/dto/DtoSearchIndices'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from 'utils/FormUtils'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import DtoQualityThreshold from '../../../../station/components/suivipc/qualitometer/dto/DtoQualityThreshold'
import { calculateAverage, calculateTaxonThresholdResult, calculateThresholdResult, calculateValue, filterQuantification, filterThresholdLevel, filterValid, searchMaxAnalysis, searchMinAnalysis, searchP90Analysis } from '../../../../utils/AnalyseUtils'
import { getDate, getYear } from '../../../../utils/DateUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { getStateLabels } from '../../../../utils/QualityUtils'
import { getObjectLabel } from '../../../../utils/StoreUtils'
import DtoQualitometerState from '../../../dto/states/DtoQualitometerState'
import DtoSearchHydrobio from 'quality/dto/taxons/DtoSearchHydrobio'
import { TAXON_MAP } from 'carto/constants/CartoConstants'
import { AbundanceLegend, OccurenceFrequencyLegend, PresenceMissingLegend } from 'components/map/utils/TaxonPredefinedMap'

moment.locale('fr')

const NO_PARAM = 'NO_PARAM'
const MOST_DOWNGRADING = 'MOST_DOWNGRADING'
const YEAR = 'YEAR'

const getTooltip = (station, date) => {
    if (!station) {
        return ''
    }
    return `${i18n.code}: ${station.code || ''}\n${i18n.date}: ${getDate(date)}\n${i18n.name}: ${station.name || ''}`
}

const Label = styled('label')({ fontSize: '1.5rem', color: 'black' })

const Stats = ({
    analysis,
}) => {
    const {
        settings,
        qualitometers,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const validAnalysis = filterValid(analysis)

    const p90 = searchP90Analysis(validAnalysis)
    const minValue = searchMinAnalysis(validAnalysis)
    const maxValue = searchMaxAnalysis(validAnalysis)
    const average = calculateAverage(validAnalysis, settings)

    const minValueStation = qualitometers.find(q => q.id === minValue?.qualitometer)
    const minValueTooltip = getTooltip(minValueStation, minValue?.analysisDate)

    const maxValueStation = qualitometers.find(q => q.id === maxValue?.qualitometer)
    const maxValueTooltip = getTooltip(maxValueStation, maxValue?.analysisDate)

    const p90Station = qualitometers.find(q => q.id === p90?.qualitometer)
    const p90Tooltip = getTooltip(p90Station, p90?.analysisDate)

    return (
        <>
            <Grid container item xs={6} justifyContent='flex-end'>
                <Label>{i18n.min}:</Label>
            </Grid>
            <Grid item xs={6}>
                <Label className='clickable blue-text' {...sieauTooltip(minValueTooltip, undefined, 'top')}>{calculateValue(minValue)}</Label>
            </Grid>
            <Grid container item xs={6} justifyContent='flex-end'>
                <Label>{i18n.average}:</Label>
            </Grid>
            <Grid item xs={6}>
                <Label>{average ?? ''}</Label>
            </Grid>
            <Grid container item xs={6} justifyContent='flex-end'>
                <Label>{i18n.max}:</Label>
            </Grid>
            <Grid item xs={6}>
                <Label className='clickable blue-text' {...sieauTooltip(maxValueTooltip, undefined, 'top')}>{calculateValue(maxValue)}</Label>
            </Grid>
            <Grid container item xs={6} justifyContent='flex-end'>
                <Label>{i18n.percentile90}:</Label>
            </Grid>
            <Grid item xs={6}>
                <Label className='clickable blue-text' {...sieauTooltip(p90Tooltip, undefined, 'top')}>{calculateValue(p90)}</Label>
            </Grid>
        </>
    )
}

Stats.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis ou DtoAnalysisLight
        // calculateThresholdResult
    })),
}

const ParameterSelectionTab = ({
    analysis = [],
    parameterAnalysis = [],
    listParameters,
    defaultParameter,
    defaultCriteriaValue,

    onChangeParameter = () => { },
    onChangeCriteriaValue = () => { },
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }))

    const parametersIndex = useMemo(() => keyBy(parameters, 'code'), [parameters])

    const [parameter, setParameter] = useState(defaultParameter)
    const [criteriaValue, setCriteriaValue] = useState(defaultCriteriaValue)

    const parameterOptions = useMemo(() => {
        const listParam = listParameters ?? uniqBy(analysis, 'parameter').map(a => a.parameter)
        const uniqParam = listParam.map(code => ({
            value: code,
            label: parametersIndex[code]?.name,
        })).filter(p => !isNil(p.value))
        return [
            { value: NO_PARAM, label: i18n.noParameter },
            ...orderBy(uniqParam, ['label']),
        ]
    }, [analysis, listParameters, parametersIndex])

    const valueOptions = useMemo(() => [
        { value: '0', label: i18n.lastData },
        { value: '1', label: i18n.max },
        { value: '2', label: i18n.min },
        { value: '3', label: i18n.average },
        { value: '4', label: i18n.percentile90 },
    ], [])

    const nbQuantifications = filterValid(filterQuantification(analysis)).length

    return (
        <>
            <div className='row card'>
                <div className='card-content'>
                    <Grid container rowSpacing={0} columnSpacing={2}>
                        <Grid item xs={12}>
                            <SuperMultiAutocomplete
                                label={i18n.parameter}
                                values={parameter}
                                options={parameterOptions}
                                keyValue='value'
                                onChange={c => setParameter(c ?? NO_PARAM)}
                            />
                        </Grid>
                        <Grid container item xs={6} justifyContent='flex-end'>
                            <Label>{i18n.nbQuantifications}:</Label>
                        </Grid>
                        <Grid item xs={6}>
                            <Label>{nbQuantifications}</Label>
                        </Grid>
                        {
                            defaultParameter !== NO_PARAM && (
                                <Stats
                                    analysis={parameterAnalysis}
                                />
                            )
                        }
                    </Grid>
                </div>
            </div>
            <div className='row card'>
                <div className='card-content'>
                    <Grid container>
                        <Grid item xs={12}>
                            <SuperMultiAutocomplete
                                label={i18n.criteriaValue}
                                values={criteriaValue}
                                options={valueOptions}
                                keyValue='value'
                                onChange={setCriteriaValue}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className='col s4 row no-margin right padding-top-1'>
                <Button
                    onClick={() => {
                        onChangeParameter?.(parameter)
                        onChangeCriteriaValue(criteriaValue)
                    }}
                    fullWidth
                    variant='contained'
                >
                    {i18n.apply}
                </Button>
            </div>
        </>
    )
}

ParameterSelectionTab.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis ou DtoAnalysisLight
        // calculateThresholdResult
    })),
    parameterAnalysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis ou DtoAnalysisLight
        // calculateThresholdResult
    })),
    listParameters: PropTypes.arrayOf(PropTypes.string),
    defaultParameter: PropTypes.string,
    defaultCriteriaValue: PropTypes.string,

    onChangeParameter: PropTypes.func,
    onChangeCriteriaValue: PropTypes.func,
}

const CartoAnalysisPanel = ({
    analysis = [],
    listParameters,
    thresholds = [],
    thresholdLevel,
    researchType = 31,
    filter = {},
}) => {
    const {
        selections,
        parameters,
    } = useSelector(store => ({
        selections: store.ParameterReducer.selections,
        parameters: store.ParameterReducer.parameters,
    }))

    const {
        selection = '-1',
        selectionResults = [],
        parameters: selectedParameters = [],
    } = filter

    const parametersIndex = useMemo(() => keyBy(parameters, 'code'), [parameters])

    const [parameter, setParameter] = useState(() => {
        if (selection !== '-1') {
            return selectionResults[0] || NO_PARAM
        }
        return selectedParameters[0] || NO_PARAM
    })
    const [criteriaValue, setCriteriaValue] = useState('0')

    const formattedAnalysis = useMemo(() => {
        const coloredAnalysis = analysis.map(a => ({
            ...a,
            ...calculateThresholdResult(a, thresholds),
        }))
        return hasValue(thresholdLevel) ? coloredAnalysis.filter(a => filterThresholdLevel(a, thresholdLevel)) : coloredAnalysis
    }, [analysis, thresholdLevel, thresholds])

    const filteredAnalysis = useMemo(() => {
        const filtered = parameter !== NO_PARAM ? formattedAnalysis.filter(a => a.parameter === parameter) : formattedAnalysis
        return orderBy(filtered, 'analysisDate', 'desc')
    }, [formattedAnalysis, parameter])

    const nbParameters = uniqBy(analysis, 'parameter').length

    const stationsPanelTitle = useMemo(() => {
        const param = parametersIndex[parameter]
        if (selection !== '-1') {
            const labelSelection = selections.find(s => s.id === selection)
            return `${labelSelection?.name || labelSelection?.code || selection} ${param?.name || ''}`
        }
        return param?.name || i18n.noParameter
    }, [parameter, parametersIndex, selection, selections])

    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            analysis={filteredAnalysis}
            stationsPanelTitle={stationsPanelTitle}
            researchType={researchType}
            qualityThresholds={thresholds}
            componentType={'quality'}
            colorMode
            sortingType={criteriaValue}
            panel={nbParameters > 0 ? (
                <ParameterSelectionTab
                    analysis={analysis}
                    parameterAnalysis={filteredAnalysis}
                    listParameters={listParameters}
                    defaultParameter={parameter}
                    onChangeParameter={setParameter}
                    defaultCriteriaValue={criteriaValue}
                    onChangeCriteriaValue={setCriteriaValue}
                />
            ) : undefined}
        />
    )
}

CartoAnalysisPanel.propTypes = {
    analysis: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
        PropTypes.shape({
            // like DtoAnalysis,
        }),
    ]),
    listParameters: PropTypes.arrayOf(PropTypes.string),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    thresholdLevel: PropTypes.string,
    researchType: PropTypes.number,
    filter: PropTypes.shape({
        parameters: PropTypes.arrayOf(PropTypes.string),
        selection: PropTypes.string,
        selectionResults: PropTypes.arrayOf(PropTypes.string),
    }),
}

const StateTab = ({
    states = [],
    state,
    setState = () => { },
    displayMode,
    setDisplayMode = () => { },
    year,
    setYear = () => { },
}) => {
    const formattedStates = uniqBy(states, 'stateId').map(s => ({ code: s.stateId, name: s.stateName }))
    const options = [
        { code: MOST_DOWNGRADING, name: i18n.chooseMostDowngrading },
        { code: YEAR, name: i18n.chooseYear },
    ]

    const years = orderBy(uniq(states.filter(s => s.stateId === state).map(s => getYear(s.startDate))), x => x)

    return (
        <Grid container rowSpacing={2} sx={{ paddingTop: '10px' }}>
            <Grid item xs={12}>
                <Select
                    options={formattedStates}
                    label={i18n.state}
                    value={state}
                    onChange={v => {
                        setState(v)

                        const newYearList = uniq(states.filter(s => s.stateId === v).map(s => getYear(s.startDate)))
                        if (!newYearList.includes(year)) {
                            setYear(newYearList[0])
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <RadioButtons
                    elements={options}
                    title={i18n.displayMode}
                    selected={displayMode}
                    onChange={setDisplayMode}
                />
            </Grid>
            <Grid item xs={4}>
                <Select
                    options={years.map(s => ({ code: s, name: s }))}
                    label={i18n.year}
                    disabled={displayMode !== YEAR}
                    integerValue
                    value={year}
                    onChange={setYear}
                />
            </Grid>
        </Grid>
    )
}

StateTab.propTypes = {
    states: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerState)),
    state: PropTypes.number,
    setState: PropTypes.func,
    displayMode: PropTypes.string,
    setDisplayMode: PropTypes.func,
    year: PropTypes.number,
    setYear: PropTypes.func,
}

const CartoStatesPanel = ({
    states = [],
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const qualitometersIndex = useMemo(() => keyBy(qualitometers, 'id'), [qualitometers])

    const [state, setState] = useState(states[0]?.stateId)
    const [displayMode, setDisplayMode] = useState(MOST_DOWNGRADING)
    const [year, setYear] = useState()

    const stateLabels = useMemo(getStateLabels, [])

    const pointStates = useMemo(() => {
        const filteredStates = states
            .filter(s => s.stateId === state)
            .filter(s => displayMode === MOST_DOWNGRADING || getYear(s.startDate) === year)
        const group = groupBy(filteredStates, 'qualitometer')
        return Object.keys(group).map(key => {
            const qualito = qualitometersIndex[key]
            if (!qualito) {
                return
            }
            const worst = maxBy(group[key], 'value')
            const stateLabel = stateLabels[worst.value] || { classNameColor: 'white' }
            return {
                ...qualito,
                markerIcon: `pictures/markers/map_qualite_${getObjectLabel(stateLabel, 'classNameColor')}.png`,
                state: stateLabel?.value,
            }
        }).filter(s => !!s)
    }, [displayMode, qualitometersIndex, state, stateLabels, states, year])

    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            stationsPoints={pointStates}
            componentType={'quality'}
            panel={<StateTab
                states={states}
                state={state}
                setState={setState}
                displayMode={displayMode}
                setDisplayMode={setDisplayMode}
                year={year}
                setYear={setYear}
            />}
            tableHeaders={['code', 'name', 'city', 'state']}
        />
    )
}

CartoStatesPanel.propTypes = {
    states: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerState)),
}

const CartoIndicesPanel = ({
    indices = [],
    thresholds,
    thresholdLevel,
    researchType,
    filter,
}) => {
    const indicesAnalysis = indices.map(i => ({
        accreditation: i.accreditation,
        operation: i.idOperation,
        parameter: i.parameter,
        qualification: i.qualification,
        remark: i.remarkCode,
        result: i.result,
        sampleDate: i.sampleDate,
        analysisDate: i.startDate || i.sampleDate,
        qualitometer: i.stationId,
        status: i.status,
        unit: i.unit,
    }))

    return (
        <CartoAnalysisPanel
            analysis={indicesAnalysis}
            thresholds={thresholds}
            thresholdLevel={thresholdLevel}
            researchType={researchType}
            filter={filter}
        />
    )
}

CartoIndicesPanel.propTypes = {
    indices: PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchIndices)),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    thresholdLevel: PropTypes.string,
    researchType: PropTypes.number,
    filter: PropTypes.shape({
        parameters: PropTypes.arrayOf(PropTypes.string),
        selection: PropTypes.string,
        selectionResults: PropTypes.arrayOf(PropTypes.string),
    }),
}

const HydrobioSelectionTab = ({
    formattedHydrobios = [],
    filteredHydrobios = [],
    defaultTaxon,
    defaultCriteriaValue,
    defaultMap,

    onChangeTaxon = () => { },
    onChangeCriteriaValue = () => { },
    onChangeMap = () => { },
}) => {
    const {
        taxonsList,
    } = useSelector(store => ({
        taxonsList: store.TaxonReducer.taxons,
    }))

    const taxonsIndex = useMemo(() => keyBy(taxonsList, 'code'), [taxonsList])

    const [taxon, setTaxon] = useState(defaultTaxon === NO_PARAM ? undefined : defaultTaxon)
    const [criteriaValue, setCriteriaValue] = useState(defaultCriteriaValue)
    const [map, setMap] = useState(defaultMap)

    const taxonOptions = useMemo(() => {
        const listTax = uniqBy(formattedHydrobios, 'taxon').map(t => t.taxon)
        const uniqTax = listTax.map(code => ({
            value: code,
            label: taxonsIndex[code]?.labelWithCode,
        })).filter(p => !isNil(p.value))
        return orderBy(uniqTax, ['label'])
    }, [formattedHydrobios, taxonsIndex])

    const valueOptions = useMemo(() => [
        { id: '0', label: i18n.lastData },
        { id: '1', label: i18n.max },
        { id: '2', label: i18n.min },
        { id: '3', label: i18n.average },
        { id: '4', label: i18n.percentile90 },
    ], [])

    const hydrobiosFiltered = !isUndefined(taxon) ? formattedHydrobios.filter(t => t.taxon === taxon) : formattedHydrobios
    const nbQuantifications = filterValid(filterQuantification(hydrobiosFiltered)).length

    const getSumEnumerationsHydrobio = () => {
        const hydrobioListFiltered = formattedHydrobios.filter(t => t.taxon === taxon)
        return sumBy(hydrobioListFiltered, 'result')
    }
    const nbEnumerations = !isUndefined(taxon) ? getSumEnumerationsHydrobio() : sum(formattedHydrobios.flatMap(t => [ t.enumerationA, t.enumerationB, t.enumerationC, t.enumerationC2 ]).filter(e => !!e))

    const predifineMaps = useMemo(() => {
        return [
            { id: '0', label: i18n.any },
            { id: TAXON_MAP.PRESENCE_MISSING, label: i18n.presenceMissing },
            { id: TAXON_MAP.ABUNDANCE, label: i18n.abundance },
            { id: TAXON_MAP.OCCURENCE_FREQUENCY, label: i18n.occurrenceFrequency },
        ]
    }, [])

    return (
        <>
            <div className='row card'>
                <div className='card-content'>
                    <Grid container rowSpacing={0} columnSpacing={2}>
                        <Grid item xs={12}>
                            <SuperMultiAutocomplete
                                label={i18n.taxons}
                                values={taxon}
                                options={taxonOptions}
                                keyValue='value'
                                onChange={v => {
                                    setTaxon(v)
                                    if (isUndefined(v)) {
                                        setMap('0')
                                    }
                                }}
                                placeholder={i18n.noTaxon}
                            />
                        </Grid>
                        <Grid container item xs={6} justifyContent='flex-end'>
                            <Label>{i18n.nbQuantifications}:</Label>
                        </Grid>
                        <Grid item xs={6}>
                            <Label>{nbQuantifications}</Label>
                        </Grid>
                        <Grid container item xs={6} justifyContent='flex-end'>
                            <Label>{i18n.countsSum}:</Label>
                        </Grid>
                        <Grid item xs={6}>
                            <Label>{nbEnumerations}</Label>
                        </Grid>
                        {
                            defaultTaxon !== NO_PARAM && (
                                <Stats
                                    analysis={filteredHydrobios}
                                />
                            )
                        }
                    </Grid>
                </div>
            </div>
            <div className='row card'>
                <div className='card-content'>
                    <Grid container>
                        <Grid item xs={12}>
                            <SuperMultiAutocomplete
                                label={i18n.criteriaValue}
                                values={criteriaValue}
                                options={valueOptions}
                                onChange={setCriteriaValue}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className='row card'>
                <div className='card-content'>
                    <Grid container>
                        <Grid item xs={12}>
                            <SuperMultiAutocomplete
                                label={i18n.predifineMaps}
                                values={map}
                                options={predifineMaps}
                                onChange={setMap}
                                disabled={isUndefined(taxon)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {map === TAXON_MAP.PRESENCE_MISSING && <PresenceMissingLegend />}
                            {map === TAXON_MAP.ABUNDANCE && <AbundanceLegend />}
                            {map === TAXON_MAP.OCCURENCE_FREQUENCY && <OccurenceFrequencyLegend />}
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className='col s4 row no-margin right padding-top-1'>
                <Button
                    onClick={() => {
                        onChangeTaxon(taxon ?? NO_PARAM)
                        onChangeCriteriaValue(criteriaValue)
                        onChangeMap(map)
                    }}
                    fullWidth
                    variant='contained'
                >
                    {i18n.apply}
                </Button>
            </div>
        </>
    )
}

HydrobioSelectionTab.propTypes = {
    formattedHydrobios: PropTypes.arrayOf(PropTypes.shape({
        // DtoSearchTaxon ou DtoSearchTaxonLight
        // calculateThresholdResult
    })),
    filteredHydrobios: PropTypes.arrayOf(PropTypes.shape({
        // DtoSearchTaxon ou DtoSearchTaxonLight
        // calculateThresholdResult
    })),
    defaultTaxon: PropTypes.string,
    defaultCriteriaValue: PropTypes.string,
    defaultMap: PropTypes.number,

    onChangeTaxon: PropTypes.func,
    onChangeCriteriaValue: PropTypes.func,
    onChangeMap: PropTypes.func,
}

const CartoHydrobioPanel = ({
    hydrobioList = [],
    thresholds = [],
    thresholdLevel,
    researchType = 31,
    filter = {},
}) => {
    const {
        taxonsList,
        selections,
    } = useSelector(store => ({
        taxonsList: store.TaxonReducer.taxons,
        selections: store.ParameterReducer.selections,
    }))

    const {
        selection = '-1',
        selectionResults = [],
        taxons: selectedTaxons = [],
    } = filter

    const taxonsIndex = useMemo(() => keyBy(taxonsList, 'code'), [taxonsList])

    const [taxon, setTaxon] = useState(() => {
        if (selection !== '-1') {
            return selectionResults[0] || NO_PARAM
        }
        return selectedTaxons[0] || NO_PARAM
    })
    const [criteriaValue, setCriteriaValue] = useState('0')
    const [map, setMap] = useState('0')

    const formattedHydrobios = useMemo(() => {
        const coloredHydrobios = hydrobioList.map(t => ({
            ...t,
            ...calculateTaxonThresholdResult(t, thresholds),
        }))
        return hasValue(thresholdLevel) ? coloredHydrobios.filter(t => filterThresholdLevel(t, thresholdLevel)) : coloredHydrobios
    }, [hydrobioList, thresholdLevel, thresholds])

    const filteredHydrobios = useMemo(() => {
        const filtered = taxon !== NO_PARAM && map === '0' ? formattedHydrobios.filter(t => t.taxon === taxon) : formattedHydrobios
        return orderBy(filtered, 'operationDate', 'desc')
    }, [formattedHydrobios, taxon, map])

    const nbHydrobios = uniqBy(hydrobioList, 'taxon').length

    const stationsPanelTitle = useMemo(() => {
        const tax = taxonsIndex[taxon]
        if (selection !== '-1') {
            const labelSelection = selections.find(s => s.id === selection)
            return `${labelSelection?.name || labelSelection?.code || selection} ${tax?.latinName || ''}`
        }
        return tax?.latinName || i18n.noTaxon
    }, [selection, selections, taxon, taxonsIndex])

    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            hydrobioList={filteredHydrobios}
            stationsPanelTitle={stationsPanelTitle}
            researchType={researchType}
            qualityThresholds={thresholds}
            componentType={'quality'}
            colorMode
            sortingType={criteriaValue}
            taxonMap={map}
            selectedTaxon={taxon}
            panel={nbHydrobios > 0 ? (
                <HydrobioSelectionTab
                    formattedHydrobios={hydrobioList}
                    filteredHydrobios={filteredHydrobios}
                    defaultTaxon={taxon}
                    onChangeTaxon={setTaxon}
                    defaultCriteriaValue={criteriaValue}
                    onChangeCriteriaValue={setCriteriaValue}
                    defaultMap={map}
                    onChangeMap={setMap}
                />
            ) : undefined}
        />
    )
}

CartoHydrobioPanel.propTypes = {
    hydrobioList: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchHydrobio)),
        PropTypes.shape({
            // like DtoSearchHydrobio,
        }),
    ]),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    thresholdLevel: PropTypes.string,
    researchType: PropTypes.number,
    filter: PropTypes.shape({
        taxons: PropTypes.arrayOf(PropTypes.string),
        selection: PropTypes.string,
        selectionResults: PropTypes.arrayOf(PropTypes.string),
    }),
}

export {
    CartoAnalysisPanel,
    CartoStatesPanel,
    CartoIndicesPanel,
    CartoHydrobioPanel,
    ParameterSelectionTab,
}