import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import { arrayOf } from '../../../utils/StoreUtils'
import Card from '../../../components/card/Card'
import DtoSandreCode from '../../dto/DtoSandreCode'
import ReferencialAction from '../../action/ReferencialAction'
import ReferencialSandreCodeDto from '../../dto/ReferencialSandreCodeDto'
import SieauAction from '../../../components/sieau/SieauAction'
import { push } from 'connected-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import logoSandre from 'assets/pictures/logo_sandre.png'
import { hasValue } from 'utils/NumberUtil'
import SandreCodePanel from './SandreCodePanel'

class SandreCodeApp extends ActionComponent {
    state = {
        sandreCode: new DtoSandreCode({}),
        readMode: true,
    }

    onChange = (value) => {
        this.setState({
            sandreCode: {
                ...this.state.sandreCode,
                ...value,
            },
        })
    }

    componentDidMount() {
        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes().then(() => this.setSandreCode())
        } else {
            this.setSandreCode()
        }

        if (!this.props.referencialSandreCodes.length) {
            this.props.fetchReferencialSandreCodes().then(() => this.setTitle())
        } else {
            this.setTitle()
        }

        if (this.props.match.params.field === 'new') {
            this.setEditMode()
        } else {
            this.setReadMode()
        }
    }

    componentDidUpdate(_, prevState) {
        const { sandreCode } = this.state
        if (prevState.sandreCode && sandreCode && prevState.sandreCode.field !== sandreCode.field) {
            if (this.props.match.params.field === 'new') {
                this.setEditMode()
            } else {
                this.setReadMode()
            }
        }
    }

    setReadMode = () => {
        this.setState({ readMode: true })
        this.setActions({
            edit: this.setEditMode,
            links: this.getLinks(),
        })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            save: this.onSave,
            cancel: this.onCancel,
            links: this.getLinks(),
        })
    }

    getLinks = () => {
        const { referencialSandreCodes } = this.props
        const { params } = this.props.match
        const sandre = referencialSandreCodes.find(rsc => rsc.field === params.field) || {}
        return params.field ? [{
            href: `http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:NSA:FRA:code:${sandre.code}:::referentiel:${sandre.referentialNumber}:html`,
            img: logoSandre,
            label: i18n.sandre.toUpperCase(),
        }] : {}
    }

    onCancel = () => {
        if (this.props.match.params.field === 'new') {
            this.props.push('/referencial/sandreCodes')
        } else {
            this.setSandreCode()
            this.setReadMode()
        }
    }

    onSave = () => {
        const { sandreCode } = this.state
        const { sandreCodes } = this.props
        const { params } = this.props.match

        if (hasValue(sandreCode.code) && sandreCode.field && sandreCode.field !== 'new' && sandreCode.name) {
            if (params.field === 'new') {
                if (sandreCodes.find(s => s.field === sandreCode.field && s.code === sandreCode.code)) {
                    this.props.error(i18n.undefinedOrTakenCodeAndName)
                    return
                }
                this.props.addReferencialSandreCode(sandreCode).then(() => {
                    this.props.fetchSandreCodes()
                    this.props.push('/referencial/sandreCodes')
                })
            } else {
                this.props.updateReferencialSandreCode(sandreCode).then(() => {
                    this.props.fetchSandreCodes()
                    this.setReadMode()
                })
            }
        } else {
            this.props.error(i18n.allFieldsAreRequired)
        }
    }

    setTitle = () => {
        const { referencialSandreCodes } = this.props
        const { params } = this.props.match
        const refSandreCode = referencialSandreCodes.find(rsc => rsc.field === params.field)?.code || ''

        this.props.forceFetch('title', [{
            title: i18n.referencials,
            href: '/referencial',
        }, {
            title: i18n.sandreNomenclature,
            href: '/referencial/sandreCodes',
        }, {
            title: `${params.field !== 'new' ? params.field : i18n.new} ${refSandreCode ? `\[${refSandreCode}\]` : ''}`,
            href: `/referencial/sandreCode/${params.field}/${params.code}/dashboard`,
        }])
    }

    setSandreCode = () => {
        const { sandreCodes } = this.props
        const { params } = this.props.match

        const sandreCode = sandreCodes.find(sc => sc.field === params.field && sc.code === parseInt(params.code)) || {}
        const newState = params.field !== 'new' ? { sandreCode } : { sandreCode: { field: params.field, code: Math.max(...sandreCodes.filter(s => s.field === params.field).map(s => s.code)) + 1 } }
        this.setState(newState)
    }

    render() {
        const { sandreCode, readMode } = this.state
        const { match: { params } } = this.props

        return (
            <div className='row margin-top-1'>
                <div className='col s10 offset-s1'>
                    <Card className='padding-1'>
                        <SandreCodePanel sandreCode={sandreCode} params={params} readMode={readMode} onChange={this.onChange}/>
                    </Card>
                </div>
            </div>
        )
    }
}

SandreCodeApp.propTypes = {
    params: PropTypes.shape({
        field: PropTypes.string,
        code: PropTypes.string,
    }),
    sandreCodes: arrayOf(DtoSandreCode),
    referencialSandreCodes: arrayOf(ReferencialSandreCodeDto),
    sandreCode: PropTypes.instanceOf(DtoSandreCode),
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
})

const mapDispatchToProps = {
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchReferencialSandreCodes: ReferencialAction.fetchReferencialSandreCodes,
    forceFetch: SieauAction.forceFetch,
    addReferencialSandreCode: ReferencialAction.addReferencialSandreCode,
    updateReferencialSandreCode: ReferencialAction.updateReferencialSandreCode,
    push,
    error: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(SandreCodeApp)
