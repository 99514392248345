import ReferencialDto from '../../../dto/ReferencialDto'

export default class DtoUsageAgri extends ReferencialDto {
    constructor(obj) {
        super(obj)
        this.id = obj.idUsage
        this.idUsage = obj.idUsage // Long,
        this.description = obj.description // Option[String],
        this.order = obj.order // Option[Int],
        this.eligibility = obj.eligibility // Option[Boolean],
        this.isCulture = obj.isCulture // Option[Boolean],
        this.status = obj.status // Option[Long],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.category = obj.category // Option[Int],
        this.comment = obj.comment // Option[String],
        this.multipleEntry = obj.multipleEntry // Option[Boolean],
        this.calculAuto = obj.calculAuto // Option[Boolean],
        this.volumeRealEntry = obj.volumeRealEntry // Option[Boolean],
        this.showVolumeIfCounter = obj.showVolumeIfCounter // Option[Boolean]
        this.simplifiedCulture = obj.simplifiedCulture // Option[Boolean]
    }
}