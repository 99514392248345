import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import Job from '../../../dto/DtoJob'
import HydroStationDto from '../../../dto/HydroStationDto'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { getIntegrationModes } from '../../../../utils/JobUtils'


class HydroPanel extends Component {
    state = {
    }

    onDeleteHydroCode = ({ hydroCode }) => {
        if (this.props.isEditMode) {
            const codes = this.props.job.parameters.parameters.filter(p => p != hydroCode)
            this.props.addHydroCode(codes)
        }
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    addHydroCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddHydroCode.value
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
            } else if (exist) {
                this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
            } else {
                this.props.addHydroCode([
                    code,
                    ...this.props.job.parameters.parameters,
                ])
                this.refs.txtAddHydroCode.value = ''
            }
        }
    }

    getStations = () => {
        return this.props.job.parameters.parameters.map(hydroCode => new HydroStationDto({ hydroCode }))
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('DATA'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })().filter(s => !s.includes('data'))
        this.props.onChangeDataTypes(newDataTypes)
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const dataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('station'))
            if (integration) {
                return [...data, 'stationIntegration']
            }
            return data
        })()
        this.props.onChangeDataTypes(dataTypes)
    }

    handleChangeHeightIntegration = integration => {
        this.changeDataTypes(integration, 'heightIntegration')
    }
    handleChangeDebitIntegration = integration => {
        this.changeDataTypes(integration, 'debitIntegration')
    }

    changeDataTypes = (integration, integrationValue) => {
        const { parameters } = this.props.job
        const dataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes(integrationValue))
            if (integration) {
                return [...data, integrationValue]
            }
            return data
        })()
        this.props.onChangeDataTypes(dataTypes)
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row no-margin valign-wrapper padding-bottom-2'>
                    <div className='col s3 no-margin'>
                        <Checkbox
                            checked={ !!this.getDataTypes('station') }
                            label={ i18n.integrateStationInfo }
                            onChange={ this.handleChangeStationIntegration }
                            { ...disabled }
                        />
                    </div>
                    <div className='col s4 no-margin'>
                        <Select
                            value={this.getDataTypes('DATA')}
                            options={getIntegrationModes()}
                            label={i18n.dataIntegration}
                            labelSpan={integrationModeHelpIcon()}
                            onChange={this.handleChangeDataIntegration}
                            {...disabled}
                        />
                    </div>
                    <Checkbox
                        checked={ !!this.getDataTypes('withPred') } col={ 4 }
                        label={ `${i18n.importPrevData} Vigicrues` }
                        onChange={ v => this.changeDataTypes(v, 'withPred') }
                        { ...disabled }
                    />
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.addCredential }</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddHydroCode' type='text' placeholder={ i18n.HYDROExample }
                                ref='txtAddHydroCode'
                            />
                            <label className='tinyLabel'>{ i18n.stationHYDROCode }</label>
                        </div>
                        <a className='waves-effect waves-light btn col offset-s1 s3'
                            onClick={ this.addHydroCode }
                        >
                            { i18n.add }
                        </a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <Checkbox
                        checked={ !!this.getDataTypes('heightIntegration') } col={ 3 }
                        label={ i18n.heightObs }
                        onChange={ this.handleChangeHeightIntegration }
                        { ...disabled }
                    />
                    <Checkbox
                        checked={ !!this.getDataTypes('debitIntegration') } col={ 3 }
                        label={ i18n.debitObs }
                        onChange={ this.handleChangeDebitIntegration }
                        { ...disabled }
                    />
                    <Checkbox
                        checked={ !!this.getDataTypes('withEvents') } col={ 3 }
                        label={ i18n.importEvents }
                        onChange={ v => this.changeDataTypes(v, 'withEvents') }
                        { ...disabled }
                    />
                    <Checkbox
                        checked={ !!this.getDataTypes('withThresholds') } col={ 3 }
                        label={ i18n.importThresholds }
                        onChange={ v => this.changeDataTypes(v, 'withThresholds') }
                        { ...disabled }
                    />
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <Table
                            type={ new HydroStationDto() }
                            data={ this.getStations() }
                            title={ i18n.credentials }
                            nbPerPageLabel={ nbPerPageLabelTiny }
                            onDelete={ this.onDeleteHydroCode }
                            deletable={ this.props.isEditMode }
                            showNbElements
                            paging
                            activeHeader
                            exportButtonOnHeader
                        />
                    </div>
                </div>
            </div>
        )
    }
}

HydroPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addHydroCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(
    null,
    mapDispatchToProps
)(HydroPanel)
