import { H_CATCHMENTS_DASHBOARD, H_CATCHMENTS_OVERVIEW, H_CATCHMENT_OPTIONS } from 'account/constants/AccessRulesConstants'
import CatchmentThunk from 'catchment/actions/CatchmentThunk'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import InstallationAction from 'installation/actions/InstallationAction'
import BoundaryError from 'log/components/BoundaryError'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import QualityAction from 'quality/actions/QualityAction'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import CatchmentsDashboardApp from './CatchmentsDashboardApp'
import CatchmentsOptionApp from './CatchmentsOptionApp'
import CatchmentsOverviewApp from '../catchmentsOverview/CatchmentsOverviewApp'
import CatchmentsCompareApp from '../CatchmentsCompare/CatchmentsCompareApp'

const CatchmentsApp = () => {
    const {
        piezometers,
        qualitometers,
        installations,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometers,
        qualitometers: store.QualityReducer.qualitometers,
        installations: store.InstallationReducer.installations,
    }), shallowEqual)

    const dispatch = useDispatch()

    const navLinks = useMemo(() => {
        return [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: '/catchment/dashboard',
            habilitation: H_CATCHMENTS_DASHBOARD,
        }, {
            icons: 'playlist_add_check',
            name: i18n.overview,
            href: '/catchment/overview',
            habilitation: H_CATCHMENTS_OVERVIEW,
        }, {
            icons: 'filter_b_and_w',
            name: i18n.comparing,
            href: '/catchment/compare',
            habilitation: H_CATCHMENTS_OVERVIEW,
        }, {
            icons: 'settings',
            name: i18n.settings,
            href: '/catchment/option',
            habilitation: H_CATCHMENT_OPTIONS,
            className: 'bottom-slide-nav-link',
        }].filter(l => componentHasHabilitations(l.habilitation))
    }, [])

    useEffect(() => {
        dispatch(CatchmentThunk.fetchCatchments())
        dispatch(CatchmentThunk.fetchCatchmentPoints())
        if (!piezometers.length) {
            dispatch(PiezometryAction.fetchPiezometers())
        }
        if (!qualitometers.length) {
            dispatch(QualityAction.fetchQualitometers())
        }
        if (!installations.length) {
            dispatch(InstallationAction.fetchInstallations())
        }
        dispatch(CityAction.fetchDepartment())
        dispatch(CityAction.fetchCities())
        dispatch(ParameterAction.fetchParameters())
        dispatch(ReferencialAction.fetchSandreCodes())
    }, [])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/catchment' to='/catchment/dashboard' />
                                <Route path='/catchment/dashboard' render={(props) => <CatchmentsDashboardApp {...props} />} />
                                <Route path='/catchment/overview' render={(props) => <CatchmentsOverviewApp {...props} />} />
                                <Route path='/catchment/compare' render={(props) => <CatchmentsCompareApp {...props} />} />
                                <Route path='/catchment/option' render={(props) => <CatchmentsOptionApp {...props} />} />
                            </Switch>
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default CatchmentsApp