import { push } from 'connected-react-router'
import { orderBy } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import EChart from '../../components/echart/EChart'
import Graph from '../../components/echart/series/Graph'
import Sunburst from '../../components/echart/series/Sunburst'
import AppStore from '../../store/AppStore'
import { addBlankTint } from '../../utils/ColorUtil'
import { getMapStateToProps, getPropTypes } from '../../utils/StoreUtils'

const propsToFetch = {
}

class SitePlanApp extends Component {
    constructor(props) {
        super(props)
    }

    onNodeClick = (params) => {
        if (params.data && params.data.length && params.data.length >= 4) {
            AppStore.dispatch(push(params.data[3]))
        }
    }

    searchNode = (tree, node) => {
        if (tree.node === node) {
            return tree
        }
        if (!tree.children) {
            return null
        }
        if (tree.children.length === 1) {
            return this.searchNode(tree.children[0], node)
        }
        return tree.children.map(child => this.searchNode(child, node)).filter(child => !!child)[0]
    }

    setLeafValues = (tree, first) => {
        if (!tree.children) {
            tree.value = first === 0 ? 3 : 1
        } else {
            tree.children.map(child => {
                this.setLeafValues(child, first-1)
            })
        }
    }

    setChildColors = (tree, color) => {
        if (tree.itemStyle) {
            if (tree.children) {
                tree.children.map(child => {
                    this.setChildColors(child, addBlankTint(tree.itemStyle.color))
                })
            }
        } else if (!color) {
            tree.children.map(this.setChildColors)
        } else {
            tree.itemStyle = {
                color,
            }
            if (tree.children) {
                tree.children.map(child => {
                    this.setChildColors(child, addBlankTint(tree.itemStyle.color))
                })
            }
        }
    }

    setGraphColors = (data, dataList, links, color) => {
        if (data.id === 0) {
            const childs = links.filter(link => link[0] === 0).map(link => dataList.find(d => d.id === link[1])).filter(d => !!d)
            childs.map(child => {
                this.setGraphColors(child, dataList, links)
            })
        } else if (data.color || color) {
            const parentColor = data.color || color
            data.itemStyle = {
                normal: {
                    color: parentColor,
                },
            }
            const childs = links.filter(link => link[0] === data.id).map(link => dataList.find(d => d.id === link[1])).filter(d => !!d)
            childs.map(child => {
                this.setGraphColors(child, dataList, links, addBlankTint(parentColor))
            })
        }
    }

    toSunburstData = (data, links) => {
        const firstNode = {
            name: i18n.homepage,
            node: 0,
            href: '/',
        }
        const sortedLinks = orderBy(links, link => link[1])
        const formattedData = sortedLinks.reduce((acc, link) => {
            const newNodeData = data[link[1]]
            const newNode = {
                name: newNodeData[2],
                node: link[1],
                href: newNodeData[3],
            }
            if (newNodeData.length === 5) {
                newNode.itemStyle = {
                    color: newNodeData[4],
                }
            }
            const foundNode = this.searchNode(acc, link[0])
            if (foundNode.children) {
                foundNode.children.push(newNode)
            } else {
                foundNode.children = [newNode]
            }
            return acc
        }, firstNode)
        this.setLeafValues(formattedData, 1)
        this.setChildColors(formattedData)
        const options = {
            series: [Sunburst({
                name: i18n.return,
                data: formattedData.children,
            })],
            height: 800,
            toolbox: {
                feature: {
                    saveAsImage: { title: i18n.export },
                },
                right: 50,
            },
            tooltip: { show: false },
        }
        return <EChart options={ options } onClick={this.onNodeClick }/>
    }

    toGraph = (data, curvedLinks, links) => {
        const formattedLinks = curvedLinks.map(link => ({
            source: link[0],
            target: link[1],
            lineStyle: {
                normal: {
                    curveness: 0.1,
                    color: '#161832',
                    opacity: 1,
                },
            },
        }))

        const otherLinks = links.map(link => ({
            source: link[0],
            target: link[1],
            lineStyle: {
                normal: {
                    color: '#161832',
                    opacity: 1,
                },
            },
        }))

        const formatData = data.map((d, i) => ({
            x: d[0],
            y: d[1],
            name: d[2],
            id: i,
            color: d.length === 5 ? d[4] : null,
            value: d[2],
            symbolSize: 30,
        }))
        this.setGraphColors(formatData[0], formatData, [].concat(curvedLinks, links))

        const options = {
            series: [Graph({
                data: formatData,
                symbol: 'roundRect',
                roam: true,
                links: [].concat(formattedLinks, otherLinks),
                label: {
                    normal: {
                        show: true,
                        formatter: (params) => params.data[2],
                    },
                },
            })],
            tooltip: { show: false },
            toolbox: {
                feature: {
                    saveAsImage: { title: i18n.export },
                },
                right: 50,
            },
            height: 1700,
        }
        return <EChart options={ options } />
    }

    render() {
        const data = [
            [0, -110, i18n.homepage, '/'],
            [30, -50, i18n.quality, 'quality', '#ff8400'],
            [30, -20, i18n.piezometry, 'piezometry', '#0a00ff'],
            [30, 10, i18n.hydrometry, 'hydrometry', '#2f4554'],
            [30, 40, i18n.pluviometry, '/', '#61a0a8'],
            [30, 70, i18n.installations, 'installation', '#4f7606'],
            [30, 100, i18n.productionUnit, 'productionUnit', '#478bff'],
            [30, 130, i18n.distributionUnit, 'distributionUnit', '#ff0003'],

            [50, -40, i18n.importName, 'import'],
            [65, -40, i18n.export, 'quality/develop'],
            [80, -40, i18n.situation, 'quality/situation'], // 10
            [95, -40, i18n.graphics, 'quality/graphic'],
            [110, -40, i18n.research, 'quality/research'],
            [125, -40, i18n.campaigns, 'quality/campaign'],
            [140, -40, i18n.AEPoverview, 'quality/conformityOverview'],

            [50, -10, i18n.importName, 'import'],
            [65, -10, i18n.export, 'piezometry/develop'],
            [80, -10, i18n.situation, 'piezometry/situation'],
            [95, -10, i18n.campaigns, 'piezometry/develop'],

            [30, -100, i18n.administration, 'administration/settings', '#c23531'],
            [40, -90, i18n.settings, 'administration/settings'], // 20
            [60, -90, i18n.statistics, 'administration/stats'],
            [80, -90, i18n.users, 'administration/user'],
            [100, -90, i18n.piezometryOptions.replace(' ', '\n'), 'administration/piezometer'],
            [120, -90, i18n.hydrometryOptions.replace(' ', '\n'), 'administration/hydrometricStation'],
            [140, -90, i18n.qualityOptions.replace(' ', '\n'), 'administration/quality'],
            [130, -80, i18n.thresholds, 'administration/quality/threshold'],
            [140, -80, i18n.qualityIndicators.replace(' ', '\n'), 'administration/quality/indicators'],
            [150, -80, i18n.selections, 'administration/quality/selection'],

            [30, -70, i18n.referencials, 'referencial', '#a49ea4'],
            [50, -60, i18n.cities, 'referencial/city'], // 30
            [65, -60, i18n.contacts, 'referencial/contact'],
            [80, -60, i18n.networks, 'referencial/network'],
            [95, -60, i18n.fractions, 'referencial/fraction'],
            [110, -60, i18n.contributors, 'referencial/contributor'],
            [125, -60, i18n.watermasses, 'referencial/watermass'],
            [140, -60, i18n.methods, 'referencial/method'],
            [155, -60, i18n.parameters, 'referencial/parameter'],
            [170, -60, i18n.supports, 'referencial/support'],
            [185, -60, i18n.units, 'referencial/unit'],
            [200, -60, i18n.hydrogeologicalEntities.replace(' ', '\n'), 'referencial/hydrogeologicalEntity'], // 40
            [215, -60, i18n.watersheds.replace(' ', '\n'), 'referencial/watershed'],

            [30, -130, i18n.jobs, 'import', '#430089'],
            [60, -130, i18n.news, 'events/news', '#cdc917'],
            [90, -130, i18n.about, '', '#000000'],
            [120, -130, i18n.confidentiality, '', '#000000'],
            [150, -130, i18n.myAccount, '', '#000000'],

            [155, -40, i18n.station],
            [130, -30, i18n.description],
            [140, -30, i18n.journal],
            [150, -30, i18n.picturesAndDocuments.replace(' ', '\n')], // 50
            [160, -30, i18n.associations],
            [170, -30, i18n.operations],
            [180, -30, i18n.followUpPC.replace(' ', '\n')],

            [110, -10, i18n.station],
            [90, 0, i18n.description],
            [100, 0, i18n.journal],
            [110, 0, i18n.picturesAndDocuments.replace(' ', '\n')],
            [120, 0, i18n.associations],
            [130, 0, i18n.piezometrySuivi.replace(' ', '\n')],

            [75, 20, i18n.station], // 60
            [60, 30, i18n.description],
            [70, 30, i18n.journal],
            [80, 30, i18n.picturesAndDocuments.replace(' ', '\n')],
            [90, 30, i18n.associations],

            [75, 80, i18n.station],
            [60, 90, i18n.description],
            [70, 90, i18n.journal],
            [80, 90, i18n.picturesAndDocuments.replace(' ', '\n')],
            [90, 90, i18n.associations],

            [75, 110, i18n.station], // 70
            [60, 120, i18n.description],
            [70, 120, i18n.journal],
            [80, 120, i18n.picturesAndDocuments.replace(' ', '\n')],
            [90, 120, i18n.associations],

            [75, 140, i18n.station],
            [60, 150, i18n.description],
            [70, 150, i18n.journal],
            [80, 150, i18n.picturesAndDocuments.replace(' ', '\n')],
            [90, 150, i18n.associations],
        ]

        const curvedLinks = [
            [1, 8],
            [1, 9],
            [1, 10],
            [1, 11],
            [1, 12],
            [1, 13],
            [1, 14],
            [1, 47],
            [2, 15],
            [2, 16],
            [2, 17],
            [2, 18],
            [2, 54],
            [19, 20],
            [19, 21],
            [19, 22],
            [19, 23],
            [19, 24],
            [19, 25],
            [29, 30],
            [29, 31],
            [29, 32],
            [29, 33],
            [29, 34],
            [29, 35],
            [29, 36],
            [29, 37],
            [29, 38],
            [29, 39],
            [29, 40],
            [29, 41],
            [3, 60],
            [5, 65],
            [6, 70],
            [7, 75],
        ]

        const links = [
            [0, 1],
            [0, 2],
            [0, 3],
            [0, 4],
            [0, 5],
            [0, 6],
            [0, 7],
            [0, 19],
            [25, 26],
            [25, 27],
            [25, 28],
            [0, 29],
            [0, 42],
            [0, 43],
            [0, 44],
            [0, 45],
            [0, 46],
            [47, 48],
            [47, 49],
            [47, 50],
            [47, 51],
            [47, 52],
            [47, 53],
            [54, 55],
            [54, 56],
            [54, 57],
            [54, 58],
            [54, 59],
            [60, 61],
            [60, 62],
            [60, 63],
            [60, 64],
            [65, 66],
            [65, 67],
            [65, 68],
            [65, 69],
            [70, 71],
            [70, 72],
            [70, 73],
            [70, 74],
            [75, 76],
            [75, 77],
            [75, 78],
            [75, 79],
        ]

        const chart = (() => {
            return this.toSunburstData(data, [].concat(curvedLinks, links))
        })()
        return (
            <div className='row no-margin'>
                { chart }
            </div>
        )
    }
}

SitePlanApp.propTypes = getPropTypes(propsToFetch)

const mapStateToProps = () => getMapStateToProps(propsToFetch)

export default connect(mapStateToProps)(SitePlanApp)
