'use strict'
import { H_TERRITORY_OPTIONS } from 'account/constants/AccessRulesConstants'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import CityDto from 'referencial/components/city/dto/CityDto'
import CultureAction from 'referencial/components/cultures/actions/CultureAction'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import CulturesFamilyAction from 'referencial/components/culturesFamilies/actions/CulturesFamilyAction'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import UsageApp from 'referencial/components/usage/components/UsageApp'
import UsagesApp from 'referencial/components/usage/components/UsagesApp'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import RSEauApplication from './RSEauApplication'
import TerritoryServicesApp from '../../exploitations/components/ExploitationsServicesSettingsApp'
import TerritorySettingsCulturesApp from './TerritorySettingsCulturesApp'
import TerritorySettingsParamsApp from './TerritorySettingsParamsApp'
import TerritoryTanksTypeApp from './TerritoryTanksTypeApp'
import TerritoryTanksTypesApp from './TerritoryTanksTypesApp'

class TerritorySettingsApp extends Component {
    componentDidMount() {
        if (!componentHasHabilitations(H_TERRITORY_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const { sandreCodes, cultures, culturesFamilies, managementUnits, cities } = this.props
        this.props.setHelpLink('', '')
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!cultures.length) {
            this.props.fetchCultures()
        }
        if (!culturesFamilies.length) {
            this.props.fetchCulturesFamilies()
        }
        if (!managementUnits.length) {
            this.props.fetchManagementUnits()
        }
        if (!cities.length) {
            this.props.fetchCities()
        }
    }

    render() {
        return (
            <BoundaryError>
                <Switch>
                    <Redirect exact from='/territory/settings' to='/territory/settings/parameters' />
                    <Route path='/territory/settings/parameters' render={(props) => <TerritorySettingsParamsApp {...props} />} />
                    <Route path='/territory/settings/cultures' render={(props) => <TerritorySettingsCulturesApp {...props} />} />
                    <Route path='/territory/settings/rseau' render={(props) => <RSEauApplication {...props} />} />
                    <Route path='/territory/settings/usage/:id' render={(props) => <UsageApp {...props} />} />
                    <Route path='/territory/settings/usages' render={(props) => <UsagesApp {...props} />} />
                    <Route path='/territory/settings/services' render={(props) => <TerritoryServicesApp {...props} />} />
                    <Route path='/territory/settings/tanks' render={(props) => <TerritoryTanksTypesApp {...props} />} />
                    <Route path='/territory/settings/tank/:id' render={(props) => <TerritoryTanksTypeApp {...props} />} />
                </Switch>
            </BoundaryError>
        )
    }
}

TerritorySettingsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    push: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchCultures: PropTypes.func,
    fetchCulturesFamilies: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchCities: PropTypes.func,
    setHelpLink: PropTypes.func,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    cultures: store.CultureReducer.cultures,
    culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    cities: store.CityReducer.cities,
})

const mapDispatchToProps = {
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchCultures: CultureAction.fetchCultures,
    fetchCulturesFamilies: CulturesFamilyAction.fetchCulturesFamilies,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchCities: CityAction.fetchCities,
    setHelpLink: HomeAction.setHelpLink,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritorySettingsApp)
