import { Button, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import ProgressBar from 'components/progress/ProgressBar'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import SimpleSelectionTable from 'components/datatable/SimpleSelectionTable'

const SelectionTableModal = ({
    isOpen = false,
    onClose = () => { },
    onValidate = () => { },
    title = '',

    listData = [],
    defaultSelectionList = [],

    listHeaders = [],
    listTitle = '',
    selectionListHeaders,
    selectionListTitle = '',
    maxHeightTable = '55vh',

    filterField,
    filterFunction = list => list,
    defaultFilter = {},

    titleBackgroundColor = '#4f88b5',
    color = 'white',
    openProgressBar = false,
}) => {
    const [selectedList, setSelectedList] = useState(defaultSelectionList)

    useEffect(() => {
        setSelectedList(defaultSelectionList)
    }, [defaultSelectionList, isOpen])

    return (
        <DialogMUI
            fullWidth
            maxWidth='xl'
            open={isOpen}
        >
            <DialogTitleMUI style={{ backgroundColor: titleBackgroundColor, color }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {title}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container justifyContent='center'>
                    {!openProgressBar && (
                        <SimpleSelectionTable
                            onChange={setSelectedList}

                            listData={listData}
                            selectedList={selectedList}

                            listHeaders={listHeaders}
                            selectedListHeaders={selectionListHeaders}
                            listTitle={listTitle}
                            selectedListTitle={selectionListTitle}
                            maxHeightTable={maxHeightTable}

                            filterField={filterField}
                            filterFunction={filterFunction}
                            defaultFilter={defaultFilter}
                        />
                    )}
                    {openProgressBar && (
                        <Grid item xs={12} style={{ paddingTop: '5px' }}>
                            <ProgressBar indeterminate />
                        </Grid>
                    )}
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={() => onValidate(selectedList)} variant='contained'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

SelectionTableModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.bool.isRequired,
    onValidate: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,

    listData: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            // every key from listHeaders and selectionListHeaders should exist in this object
        })),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            // every key from listHeaders and selectionListHeaders should exist in this object
        })),
    ]).isRequired,
    defaultSelectionList: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
    ]),

    listHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
    listTitle: PropTypes.string.isRequired,
    selectionListHeaders: PropTypes.arrayOf(PropTypes.string),
    selectionListTitle: PropTypes.string.isRequired,
    maxHeightTable: PropTypes.string,

    filterField: PropTypes.func, // can be a function or a component, filter and setFilter are passed in props
    filterFunction: PropTypes.func, // (list, filter) => return filteredList
    defaultFilter: PropTypes.shape({}),

    titleBackgroundColor: PropTypes.string,
    color: PropTypes.string,
    openProgressBar: PropTypes.bool,
}

export default SelectionTableModal