import ReferencialAppList from '../../ReferencialAppList'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import UsageAction from '../actions/UsageAction'
import HomeAction from '../../../../home/actions/HomeAction'
import Checkbox from '../../../../components/forms/Checkbox'
import DtoUsageAgri from '../dto/DtoUsageAgri'
import { getSandreLabel } from '../../../../utils/StringUtil'
import DtoSandreCode from '../../../dto/DtoSandreCode'
import ReferencialAction from '../../../action/ReferencialAction'

const headersList = ['id', 'name', 'category', 'eligibilityIcon', 'cultureIcon', 'simplifiedCultureIcon', 'multipleEntryIcon', 'calculAutoIcon', 'volumeRealEntryIcon', 'showVolumeIfCounterIcon']
const headersExport = ['id', 'name', 'category', 'eligibility', 'culture', 'simplifiedCulture', 'multipleEntry', 'calculAuto', 'volumeRealEntry', 'showVolumeIfCounter']

class UsagesApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            usagesFormatted: [],
        }
    }

    componentDidMount = () => {
        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes().then(() => {
                this.setUsages()
            })
        } else {
            this.setUsages()
        }
        this.props.setHelpLink('', '')
    }

    setUsages = () => {
        this.props.fetchUsages().then(() => {
            const { usages, sandreCodes } = this.props
            const usagesFormatted = sortBy(usages, 'idUsage').map((u) => {
                return {
                    ...u,
                    name: u.description,
                    category: getSandreLabel(sandreCodes, 'USAGES.CATEGORIE', u.category),
                    eligibilityIcon: u.eligibility ? (<Checkbox col={ 12 } checked disabled />) : '',
                    cultureIcon: u.isCulture ? (<Checkbox col={ 12 } checked disabled />) : '',
                    multipleEntryIcon: u.multipleEntry ? (<Checkbox col={ 12 } checked disabled />) : '',
                    calculAutoIcon: u.calculAuto ? (<Checkbox col={ 12 } checked disabled />) : '',
                    volumeRealEntryIcon: u.volumeRealEntry ? (<Checkbox col={ 12 } checked disabled />) : '',
                    showVolumeIfCounterIcon: u.showVolumeIfCounter ? (<Checkbox col={ 12 } checked disabled />) : '',
                    simplifiedCultureIcon: u.simplifiedCulture ? (<Checkbox col={ 12 } checked disabled />) : '',
                    eligibility: u.eligibility ? i18n.yes : i18n.no,
                    culture: u.isCulture ? i18n.yes : i18n.no,
                    multipleEntry: u.multipleEntry ? i18n.yes : i18n.no,
                    calculAuto: u.calculAuto ? i18n.yes : i18n.no,
                    volumeRealEntry: u.volumeRealEntry ? i18n.yes : i18n.no,
                    showVolumeIfCounter: u.showVolumeIfCounter ? i18n.yes : i18n.no,
                    simplifiedCulture: u.simplifiedCulture ? i18n.yes : i18n.no,
                    headers: headersExport,
                }
            })
            this.setState({ usagesFormatted })
        })
    }

    render() {
        const { searchValue, usagesFormatted } = this.state
        const { usages, sandreCodes } = this.props

        if (sandreCodes.length) {
            return (
                <ReferencialAppList
                    title={i18n.usages}
                    data={usagesFormatted}
                    type={{ headers: headersList }}
                    referenceData={usages}
                    newAction={() => this.props.push('/territory/settings/usage/new')}
                    link='territory/settings/usage'
                    setTitleAction={this.props.setTitle([{
                        title: i18n.steering,
                        href: 'territory',
                    }, {
                        title: i18n.settings,
                        href: 'territory/settings',
                    }, {
                        title: i18n.usages,
                        href: 'territory/settings/usages',
                    }])}
                    customHeaders={{
                        eligibilityIcon: i18n.eligibility,
                        cultureIcon: i18n.culture,
                        multipleEntryIcon: i18n.multipleEntry,
                        calculAutoIcon: i18n.calculAuto,
                        volumeRealEntryIcon: i18n.volumeRealEntry,
                        showVolumeIfCounterIcon: i18n.showVolumeIfCounter,
                        simplifiedCultureIcon: i18n.simplifiedCulture,
                    }}
                    filter={searchValue}
                    showUpdateButton={false}
                    showPurgeButton={false}
                    showRefencialActionsButton={false}
                    round
                />
            )
        }
        return null
    }
}

UsagesApp.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    getLink: PropTypes.func,
    fetchUsages: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    setTitle: PropTypes.func,
    setHelpLink: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        usages: store.UsageReducer.usages,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

const mapDispatchToProps = {
    push,
    fetchUsages: UsageAction.fetchUsages,
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    setHelpLink: HomeAction.setHelpLink,
    setTitle: HomeAction.setTitle,
    toastrInfo: ToastrAction.info,
}
export default connect(mapStateToProps, mapDispatchToProps)(UsagesApp)
