/* eslint-disable camelcase */
import { push } from 'connected-react-router'
import { compact, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'

const headers = ['name', 'status', 'begin', 'end', 'division']

const StationSamplersPanel = ({ station = {} }) => {
    const dispatch = useDispatch()

    const {
        exploitationsLight,
    } = useSelector(store => ({
        exploitationsLight: store.AgriReducer.exploitationsLight,
    }), shallowEqual)

    const statusOptions = [
        { value: 1, name: i18n.used },
        { value: 2, name: i18n.notUsed },
        { value: 3, name: i18n.closed },
    ]
    const getTablePoints = (link_exploitations) => {
        const data = orderBy(compact(link_exploitations.map((linkExploit) => {
            const exploitation = exploitationsLight.find((e) => e.idExploitation === linkExploit.idExploitation)
            if (exploitation) {
                return {
                    ...linkExploit,
                    name: `${exploitation.name} [${exploitation.codification}]`,
                    status: getLabel(statusOptions, linkExploit.stateCode, 'name', 'value'),
                    begin: getDate(linkExploit.startDate),
                    end: getDate(linkExploit.endDate),
                    division: hasValue(linkExploit.distribution) ? `${linkExploit.distribution} %` : i18n.unknown,
                }
            }
            return null
        })), '')
        return (
            <Table
                title={i18n.samplers}
                data={orderBy(data, 'name')}
                paging
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers }}
                customHeaders={{ sharedIcon: i18n.shared }}
                onClick={s => dispatch(push(`/dossiers/${s.idExploitation}/dashboard`))}
                condensed
                sortable
                initialSort
            />
        )
    }

    return (
        <div id='exploitations'>
            {getTablePoints(station?.link_exploitationsAgri || [])}
        </div>
    )
}

StationSamplersPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationSamplersPanel)