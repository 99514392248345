import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { PATH_REFERENCIAL_CULTURES_FAMILY_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import {
    RECEIVE_CULTURES_FAMILIES,
    RECEIVE_CULTURES_FAMILY,
    RESET_CULTURES_FAMILIES,
} from '../constants/CulturesFamilyConstants'

const CulturesFamilyAction = {
    reset() {
        return { type: RESET_CULTURES_FAMILIES }
    },

    receiveCulturesFamilies(culturesFamilies) {
        return { type: RECEIVE_CULTURES_FAMILIES, culturesFamilies }
    },

    promiseCulturesFamilies() {
        return fetch(ApplicationConf.referencial.culturesFamilies(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCulturesFamilies() {
        return (dispatch) => {
            return CulturesFamilyAction.promiseCulturesFamilies()
                .then((json = []) => {
                    dispatch(CulturesFamilyAction.receiveCulturesFamilies(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.culturesFamilies} : ${err}`))
                    ToastrAction.error(i18n.fetchError + i18n.culturesFamilies)
                })
        }
    },

    receiveCulturesFamily(culturesFamily) {
        return { type: RECEIVE_CULTURES_FAMILY, culturesFamily }
    },

    promiseCulturesFamily(id) {
        return fetch(ApplicationConf.referencial.culturesFamily(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCulturesFamily(id) {
        return (dispatch) => {
            return CulturesFamilyAction.promiseCulturesFamily(id)
                .then((json = []) => {
                    dispatch(CulturesFamilyAction.receiveCulturesFamily(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.culturesFamily} : ${err}`))
                    ToastrAction.error(i18n.fetchError + i18n.culturesFamily)
                })
        }
    },

    updateCulturesFamily(culturesFamily, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveCulturesFamily(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(culturesFamily),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(CulturesFamilyAction.fetchCulturesFamily(code))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.culturesFamily} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.culturesFamily))
                })
        }
    },

    createCulturesFamily(culturesFamily) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culturesFamilies(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ id: -1, ...culturesFamily }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.id) {
                        dispatch(push(PATH_REFERENCIAL_CULTURES_FAMILY_LINK + json.id))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.culturesFamily} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.culturesFamily))
                })
        }
    },

    updateFamilyCultures(idFamily, idCultures) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.cultureFamilyCultures(idFamily), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(idCultures),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.culturesFamily} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.culturesFamily))
                })
        }
    },

}

export default CulturesFamilyAction
