import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { PATH_REFERENCIAL_AQUIFER, PATH_REFERENCIAL_AQUIFER_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import AppStore from '../../../../store/AppStore'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import { AQUIFER, RECEIVE_ALL_AQUIFER, RESET_AQUIFER } from '../constants/AquiferConstants'

const AquiferAction = {

    receiveAquifer(aquifer) {
        return { type: AQUIFER, aquifer }
    },

    receiveAquifers(aquifers) {
        return { type: RECEIVE_ALL_AQUIFER, aquifers }
    },

    promiseAquifers() {
        return fetch(ApplicationConf.referencial.aquifers(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchAquifer(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.aquifer(code), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(AquiferAction.receiveAquifer(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.aquifer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.aquifer))
                })
        }
    },

    fetchAquifers() {
        return (dispatch) => {
            return AquiferAction.promiseAquifers()
                .then((json = []) => {
                    dispatch(AquiferAction.receiveAquifers(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.aquifer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.aquifer))
                })
        }
    },

    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().AquiferReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    updateAquifer(aquifer, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveAquifer(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(aquifer),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(AquiferAction.fetchAquifer(code))
                        dispatch(ToastrAction.success(i18n.updateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.aquifer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.aquifer))
                })
        }
    },

    createAquifer(aquifer) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.aquifers(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(aquifer),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_AQUIFER_LINK + aquifer.id))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.aquifer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.aquifer))
                })
        }
    },

    deleteAquifer(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteAquifer(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_AQUIFER))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.aquifer} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.aquifer))
                })
        }
    },

    purgeAquifer() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeAquifer(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(AquiferAction.fetchAquifers())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.aquifers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.aquifers))
                })
        }
    },

    replaceAquifer(aquifer, aquiferReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceAquifer(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: aquifer.toString(),
                    replaceCode: aquiferReplace.toString(),
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.aquiferSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.replace + i18n.aquifer} : ${err}`))
                })
        }
    },

    reset() {
        return { type: RESET_AQUIFER }
    },

}

export default AquiferAction
