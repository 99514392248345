import { H_REFERENCIAL_SANDRE_CODES } from 'account/constants/AccessRulesConstants'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import SandreCodeApp from './SandreCodeApp'

const SandreCodeRouterApp = ({ match }) => {
    const dispatch = useDispatch()

    const navLinks = useMemo(() => {
        const { params: { code, field } } = match
        return [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: `/referencial/${REFERENCIAL_TYPE_NAME.sandreCode}/${field}/${code}/dashboard`,
            habilitation: H_REFERENCIAL_SANDRE_CODES,
        }].filter(l => componentHasHabilitations(l.habilitation))
    }, [match])

    useEffect(() => {
        dispatch(ReferencialAction.fetchSandreCodes())
        dispatch(ReferencialAction.fetchReferencialSandreCodes())
    }, [])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/referencial/sandreCode/:field/:code' to='/referencial/sandreCode/:field/:code/dashboard' />
                                <Route path='/referencial/sandreCode/:field/:code/dashboard' render={(props) => <SandreCodeApp {...props} />} />
                            </Switch>
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

SandreCodeRouterApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
            field: PropTypes.string,
        }),
    }),
}

export default SandreCodeRouterApp