import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import LogAction from '../../../../log/actions/LogAction'
import {
    checkAuth,
    checkAuthWithoutKicking,
    checkError,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import { setMessageModal } from '../../../../utils/FormUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import {
    RECEIVE_ALL_CENTRALS, RECEIVE_ALL_CENTRAL_SITUATIONS, RECEIVE_CENTRAL, RECEIVE_CENTRALS_LAST_SITUATIONS, RECEIVE_CENTRAL_CHANNELS,
    RECEIVE_CENTRAL_FILES,
    RECEIVE_CENTRAL_PICTURES,
    RECEIVE_CENTRAL_RANGES,
    RECEIVE_CENTRAL_TYPES, RESET_CENTRAL,
} from '../constants/CentralConstants'

const CentralAction = {
    receiveCentrals(centrals) {
        return { type: RECEIVE_ALL_CENTRALS, centrals }
    },
    promiseCentrals(withEquipment) {
        return fetch(ApplicationConf.materiel.allCentrals(withEquipment), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCentrals(withEquipment) {
        return (dispatch) => {
            return CentralAction.promiseCentrals(withEquipment)
                .then((json = []) => {
                    dispatch(CentralAction.receiveCentrals(json))
                    if (json.length) {
                        dispatch(CentralAction.fetchCentralsLastSituations())
                    }
                })
        }
    },

    receiveCentral(central) {
        return { type: RECEIVE_CENTRAL, central }
    },

    promiseCentral(id) {
        return fetch(ApplicationConf.materiel.central(id), {
            headers: getAuthorization(),
        })
            .then(checkAuthWithoutKicking)
            .then(getJson)
            .then(checkError)
    },

    fetchCentral: id => dispatch => {
        return CentralAction.promiseCentral(id)
            .then((json = []) => {
                dispatch(CentralAction.receiveCentral(json))
                return json
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.central} : ${e}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.central))
            })
    },

    fetchPreviousCentral: id => dispatch => CentralAction.promiseCentral(id)
        .catch(e => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.central} : ${e}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.central))
        }),

    receiveCentralTypes(centralTypes) {
        return { type: RECEIVE_CENTRAL_TYPES, centralTypes }
    },

    promiseCentralTypes(withEquipmentType) {
        return fetch(ApplicationConf.materiel.centralAllTypes(withEquipmentType), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchCentralTypes(withEquipmentType) {
        return (dispatch) => {
            return CentralAction.promiseCentralTypes(withEquipmentType)
                .then((json = {}) => {
                    dispatch(CentralAction.receiveCentralTypes(json))
                })
        }
    },
    updateCentralTypes(id, materielType) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.centralType(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(CentralAction.fetchCentralTypes(true))
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielType))
                })
        }
    },
    deleteCentralType(id, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.centralType(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(r => checkAuth(r, {
                    206: () => setMessageModal(i18n.deletingUsedMaterielTypeMessage),
                }))
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(CentralAction.fetchCentralTypes(true))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.materielType))
                })
        }
    },
    addCentralTypes(materielType, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.centralTypes(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(CentralAction.fetchCentralTypes(true))
                        dispatch(ToastrAction.success(i18n.addSuccess))
                        callback({ ...materielType, materielType: json.id })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.materielType))
                })
        }
    },

    updateCentral(central) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.central(central.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(central),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        dispatch(CentralAction.fetchCentral(central.id))
                        dispatch(CentralAction.fetchCentrals(true))
                        return true
                    }
                    throw new Error(json)
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.central)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    createCentral(central) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.centrals(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(central),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && hasValue(json.id)) {
                        dispatch(CentralAction.fetchCentrals(true))
                        dispatch(push(`/materiel/central/${json.id}`))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        return json.id
                    }
                    throw new Error(json)
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.central)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    deleteCentral(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.central(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(CentralAction.fetchCentrals(true))
                    dispatch(push('/materiel/central'))
                    dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.central} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.central))
                })
        }
    },

    receiveCentralSituations(centralSituations) {
        return { type: RECEIVE_ALL_CENTRAL_SITUATIONS, centralSituations }
    },

    receiveCentralsLastSituations(centralsLastSituations) {
        return { type: RECEIVE_CENTRALS_LAST_SITUATIONS, centralsLastSituations }
    },

    promiseCentralSituation(id) {
        return fetch(ApplicationConf.materiel.centralSituations(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCentralSituations: id => dispatch => {
        return CentralAction.promiseCentralSituation(id)
            .then(json => {
                dispatch(CentralAction.receiveCentralSituations(json))
            })
    },

    promiseCentralLastSituation: () => fetch(ApplicationConf.materiel.centralsLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCentralsLastSituations: () => dispatch => CentralAction.promiseCentralLastSituation()
        .then((json = []) => {
            dispatch(CentralAction.receiveCentralsLastSituations(json))
        }),

    saveCentralSituations(postCentral, shouldFetch = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.addCentralSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(postCentral),
            })
                .then(() => {
                    dispatch(CentralAction.fetchCentralsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationAddSuccess))
                    if (shouldFetch) {
                        dispatch(CentralAction.fetchCentralSituations(postCentral.idCentral))
                    }
                })
        }
    },
    deleteCentralSituation(id, idCentral) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.deleteCentralSituation(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(() => {
                    dispatch(CentralAction.fetchCentralsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationDeleteSuccess))
                    dispatch(CentralAction.fetchCentralSituations(idCentral))
                })
        }
    },
    updateCentralSituation(idSituation, centralSituation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.saveCentralSituation(idSituation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(centralSituation),
            })
                .then(() => {
                    dispatch(CentralAction.fetchCentralsLastSituations())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                    if (openCampaginModal) {
                        dispatch(openCampaginModal(centralSituation))
                    }
                })
        }
    },
    createEventAndUpdateSituation(urlEvent, event, centralSituation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(urlEvent, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            }).then(getJson)
                .then((json) => {
                    if (json.eventId) {
                        const result = Object.assign({}, centralSituation, {
                            eventCode: json.eventId,
                        })
                        dispatch(CentralAction.updateCentralSituation(result.id, result))
                        if (openCampaginModal) {
                            dispatch(openCampaginModal(result))
                        }
                    } else {
                        dispatch(ToastrAction.error(i18n.saveError))
                    }
                })
        }
    },

    receiveCentralRanges(centralRanges) {
        return { type: RECEIVE_CENTRAL_RANGES, centralRanges }
    },

    fetchCentralRanges(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.centralRange(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(CentralAction.receiveCentralRanges(json))
                })
        }
    },

    receiveCentralChannels(centralChannels) {
        return { type: RECEIVE_CENTRAL_CHANNELS, centralChannels }
    },

    promiseCentralChannels: id => fetch(ApplicationConf.materiel.centralChannel(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchCentralChannels(id, cb = () => { }) {
        return (dispatch) => {
            return CentralAction.promiseCentralChannels(id)
                .then(json => {
                    dispatch(CentralAction.receiveCentralChannels(json))
                    cb()
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.channels} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.channels))
                })
        }
    },

    fetchPreviousCentralChannels: id => dispatch => CentralAction.promiseCentralChannels(id)
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.channels} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.channels))
        }),

    updateAllCentralChannel: (centralId, channels) => dispatch => {
        return fetch(ApplicationConf.materiel.centralAllChannel(centralId), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(channels),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.insert >= 1) {
                    dispatch(CentralAction.fetchCentralChannels(centralId))
                }
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.channels} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.channels))
            })
    },

    updateCentralChannel(centralId, centralChannel, method = 'PUT', callback = () => { }) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.materiel.centralChannel(centralId), {
                method,
                headers: getAuthorization(),
                body: JSON.stringify(centralChannel),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1 || json.insert >= 1 || json.delete >= 1) {
                        dispatch(CentralAction.fetchCentralChannels(centralId, () => {
                            dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                            callback()
                            dispatch(WaitAction.waitStop())
                        }))
                    } else {
                        throw new Error('Incorrect result number')
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.channels} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.channels))
                })
        }
    },

    receiveCentralPictures(centralPictures) {
        return {
            type: RECEIVE_CENTRAL_PICTURES,
            centralPictures,
        }
    },

    fetchCentralPictures(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.centralPicture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(CentralAction.receiveCentralPictures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                })
        }
    },

    receiveCentralFiles(centralFiles) {
        return {
            type: RECEIVE_CENTRAL_FILES,
            centralFiles,
        }
    },

    fetchCentralFiles(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.centralFile(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(CentralAction.receiveCentralFiles(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
                })
        }
    },
    resetCentral() {
        return {
            type: RESET_CENTRAL,
        }
    },
}

export default CentralAction
