import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { H_INSTALLATION_CAMPAIGN } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import ProgressCard from '../../../components/card/ProgressCard'
import SieauAction from '../../../components/sieau/SieauAction'
import { PATH_INSTALLATION, PATH_INSTALLATION_CAMPAIGN } from '../../../home/constants/RouteConstants'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import InstallationDashboardCampaigns from './InstallationDashboardCampaigns'


class InstallationCampaigns extends Component {
    state = {
        progress: 0,
        dataLoaded: false,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_INSTALLATION_CAMPAIGN)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        AppStore.dispatch(HomeAction.setHelpLink('installation', ''))
        this.props.loadInstallationCampaigns(p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))
        this.props.forceFetch('title', [{
            title: i18n.installation,
            href: PATH_INSTALLATION,
        }, {
            title: i18n.campaigns,
            href: PATH_INSTALLATION_CAMPAIGN,
        }])
    }

    render = () => {
        const {
            progress,
            dataLoaded,
        } = this.state
        return dataLoaded ? (
            <InstallationDashboardCampaigns />
        ) : (
            <ProgressCard progress={progress} className='padding-1' />
        )
    }
}

InstallationCampaigns.propTypes = {
    getLink: PropTypes.func,
    forceFetch: PropTypes.func,
    loadInstallationCampaigns: PropTypes.func,
    push: PropTypes.func,
}

const mapDispatchToPorops = {
    forceFetch: SieauAction.forceFetch,
    loadInstallationCampaigns: CampaignAction.loadInstallationCampaigns,
    push,
}

export default connect(null, mapDispatchToPorops)(InstallationCampaigns)
