import Card from 'components/card/Card'
import Table from 'components/datatable/Table'
import { exportPictureIcon } from 'components/echart/EChartUtils'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import ReactEcharts from 'echarts-for-react'
import { every, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { getLabel } from 'utils/StoreUtils'
import { searchAllCharacters, substringText } from 'utils/StringUtil'

const Graph = ({
    selected = [],
    analysis = [],
    parameterKey = 'parameter',
    resultKey = 'result',
}) => {
    const {
        parameters,
        units,
        operation,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        operation: store.OperationReducer.operation,
    }), shallowEqual)

    const filteredAnalysis = analysis.filter(({ [parameterKey]: parameter }) => selected.includes(`${parameter}`))

    const data = filteredAnalysis.map(({ [resultKey]: result, [parameterKey]: parameter, unit }) => {
        const { labelWithCode = '', shortLabel = '', name } = parameters.find(({ code }) => `${parameter}` === code) || {}
        return {
            value: result,
            parameter: labelWithCode,
            paramShortLabel: shortLabel || name,
            unit: getLabel(units, unit, 'symbol'),
        }
    })
    const options = {
        series: [{
            type: 'bar',
            data,
            areaStyle: {
                normal: {
                    color: 'blue',
                    opacity: 1,
                },
            },
            itemStyle: {
                normal: {
                    color: 'blue',
                    opacity: 1,
                },
            },
        }],
        xAxis: [{
            type: 'category',
            data: data.map(({ paramShortLabel, unit }) => `${paramShortLabel} ${unit}`),
            axisTick: {
                alignWithLabel: true,
            },
            axisLabel: {
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
            axisPointer: {
                show: true,
                type: 'line',
            },
        }],
        tooltip: {
            formatter: ([, { data: { parameter, value, unit } }]) => `${parameter}</br>${value} ${unit}`,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            top: '8%',
            left: '3%',
            right: '4%',
            bottom: '80',
            containLabel: true,
        },
        toolbox: {
            right: '4%',
            top: '2%',
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: `operation_${getDate(operation.dateStart)}`,
                },
            },
        },
        name: i18n.analysis,
    }
    return (
        <ReactEcharts
            option={options}
            style={{ minHeight: '70vh' }}
            notMerge
        />
    )
}

Graph.propTypes = {
    selected: PropTypes.arrayOf(PropTypes.number),
    analysis: PropTypes.arrayOf(PropTypes.shape({})),
    parameterKey: PropTypes.string,
    resultKey: PropTypes.string,
}

const OperationGraph = ({
    analysis = [],
    parameterKey = 'parameter',
    resultKey = 'result',
    hideDataType = false,
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)
    const [dataType, setDataType] = useState()
    const [selection, setSelection] = useState()
    const [listParam, setListParam] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [selected, setSelected] = useState(() => analysis.map(({ [parameterKey]: parameter }) => `${parameter}`))

    const parameterList = useMemo(() => {
        const parametersFromAnalysis = analysis.map(({ [parameterKey]: parameter }) => parameters.find(({ code }) => code === `${parameter}`)).filter(p => !!p)
        const selectionParameters = selection !== '-1' ? listParam.map(param => parametersFromAnalysis.find(({ code }) => code === param)).filter(p => !!p) : parametersFromAnalysis
        const searchValueFormated = searchAllCharacters(searchValue)
        return searchValueFormated ? selectionParameters.filter(({ code = '', name = '', mnemonique = '' }) => searchAllCharacters(`${code}${name}${mnemonique}`).includes(searchValueFormated)) : selectionParameters
    }, [analysis, listParam, parameterKey, parameters, searchValue, selection])

    const formatParameters = parameterList.map(({ code = '', name = '' }) => {
        const isSelected = !!selected.find(param => param === code)
        const tooltip = name.length > 40 ? { tooltip: name, className: 'tooltipped' } : {}
        return {
            code: { value: code },
            name: { value: substringText(name, 40), ...tooltip },
            nullValue: { value: <i className={'material-icons'} >{isSelected ? 'check_box' : 'check_box_outline_blank'}</i> },
            isSelected,
            lineColor: isSelected ? '#b8d2ff' : '#fff',
        }
    })

    const getFilteredParametersFromAnamysis = type => {
        switch (type) {
            case 'detected': return analysis.filter(({ remarkCode }) => remarkCode !== '0' && remarkCode !== '2').map(({ [parameterKey]: parameter }) => parameter)
            case 'quantified': return analysis.filter(({ remarkCode }) => remarkCode !== '0' && remarkCode !== '2' && remarkCode !== '10').map(({ [parameterKey]: parameter }) => parameter)
            default: return analysis.map(({ [parameterKey]: parameter }) => parameter)
        }
    }

    const checkAll = () => {
        setSelected(uniq([...selected, ...formatParameters.map(({ code: { value: code } }) => code)]))
    }

    const uncheckAll = () => {
        setSelected(selected.filter(param => !formatParameters.find(({ code: { value: code } }) => param === code)))
    }

    const actions = every(formatParameters, ({ isSelected }) => isSelected) ? [{
        onClick: uncheckAll,
        iconName: 'check_box_outline_blank',
        tooltip: i18n.unselectAll,
    }] : [{
        onClick: checkAll,
        iconName: 'check_box',
        tooltip: i18n.selectAll,
    }]

    const dataTypes = [
        {
            code: 'all',
            label: i18n.allData,
        },
        {
            code: 'detected',
            label: i18n.detectedData,
        },
        {
            code: 'quantified',
            label: i18n.quantifiedData,
        },
    ]

    return (
        <div className='row no-margin'>
            <div className='col s3 no-margin'>
                <div className='row no-padding'>
                    {
                        !hideDataType && (
                            <div className='row no-margin padding-top-1'>
                                <Select
                                    col={12}
                                    label={i18n.dataTypes}
                                    options={dataTypes}
                                    value={dataType}
                                    onChange={v => {
                                        setDataType(v)
                                        setSelected(getFilteredParametersFromAnamysis(v))
                                    }}
                                />
                            </div>
                        )
                    }
                    <div className={`row no-margin${hideDataType ? ' padding-top-1' : ''}`}>
                        <SelectionSelect
                            col={12}
                            value={selection}
                            onChange={(newListParam, newSelection) => {
                                setSelection(newSelection)
                                setListParam(newListParam)
                            }}
                        />
                    </div>
                    <div className='row no-margin'>
                        <Input
                            title={i18n.search}
                            value={searchValue}
                            col={12}
                            onChange={setSearchValue}
                        />
                    </div>
                    <Card
                        title={`${i18n.analyzedParameters} (${selected.length})`}
                        actions={actions}
                    >
                        <div className='row no-margin'>
                            <Table
                                showTitle={false}
                                condensed
                                data={formatParameters}
                                type={{ headers: ['code', 'name', 'nullValue'] }}
                                sortable
                                maxHeight='40vh'
                                onClick={({ code: { value: code }, isSelected }) => setSelected(isSelected ? selected.filter(param => param !== code) : [...selected, code])}
                                paging
                                nbPerPageLabel={nbPerPageLabel}
                                color
                            />
                        </div>
                    </Card>
                </div>
            </div>
            <div className='col s9'>
                <Graph
                    selected={selected}
                    analysis={analysis}
                    parameterKey={parameterKey}
                    resultKey={resultKey}
                />
            </div>
        </div>
    )
}

OperationGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({})),
    parameterKey: PropTypes.string,
    resultKey: PropTypes.string,
    hideDataType: PropTypes.bool,
}

export default OperationGraph