import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { H_CAMPAIGN_PLUVIO_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import DashboardCampaign from '../../../campaign/components/campaignDashboard/DashboardCampaign'
import ProgressCard from '../../../components/card/ProgressCard'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import PluviometerDto from '../../dto/PluviometerDto'


class PluviometryCampaignDashboard extends Component {
    state = {
        dataLoaded: false,
        progress: 0,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_CAMPAIGN_PLUVIO_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.loadCampaignDashboard(p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))
    }

    componentDidUpdate = prevProps => {
        const {
            id,
        } = this.props.match.params
        if (id !== prevProps.match.params.id && id !== 'new' && id !== 'duplicate') {
            this.props.fetchCampaign('pluviometry', id)
            this.props.fetchCampaignStation('pluviometry', id)
            this.props.fetchCampaignProgress('pluviometry', id)
        }
    }

    render = () => {
        const {
            dataLoaded,
            progress,
        } = this.state
        if (dataLoaded) {
            const {
                pluviometers,
            } = this.props
            const { params } = this.props.match
            return (
                <DashboardCampaign
                    params={params}
                    stations={pluviometers}
                    stationType={'pluviometry'}
                />
            )
        }
        return <ProgressCard progress={progress} />
    }
}

PluviometryCampaignDashboard.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),

    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),

    loadCampaignDashboard: PropTypes.func,
    fetchCampaign: PropTypes.func,
    fetchCampaignStation: PropTypes.func,
    fetchCampaignProgress: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    pluviometers: store.PluviometryReducer.pluviometers,
})

const mapDispatchToProps = {
    loadCampaignDashboard: CampaignAction.loadCampaignDashboard,
    fetchCampaign: CampaignAction.fetchCampaign,
    fetchCampaignStation: CampaignAction.fetchCampaignStation,
    fetchCampaignProgress: CampaignAction.fetchCampaignProgress,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PluviometryCampaignDashboard)
