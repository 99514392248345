import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import { sortBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import Textarea from '../../../../components/forms/Textarea'
import Icon from '../../../../components/icon/Icon'
import { arrayOf } from '../../../../utils/StoreUtils'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import InstallationAction from '../../../actions/InstallationAction'
import Row from '../../../../components/react/Row'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import { getSetting } from '../../../../utils/SettingUtils'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import {
    getVisitCheckBoxChange,
    getVisitSandreSelectChange,
    getVisitSelectChange,
    getVisitSimpleDatePickerChange,
} from '../../../../utils/VisitUtils'
import DtoManagementUnit from '../../../../referencial/components/managementUnit/dto/DtoManagementUnit'
import Select from '../../../../components/forms/Select'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { INSTALLATION_TYPE } from '../../../constants/InstallationConstants'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import DtoSector from 'referencial/components/sector/dto/DtoSector'
import { Button, Dialog } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
// import SandreSelect from 'components/forms/SandreSelect'
// import NewSelect from 'components/forms/NewSelect'
import useSandreList from 'utils/customHook/useSandreList'

const styleLabelSelect = {
    fontSize: '13px',
}

const InstallationInfosPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    station = {},
    disabled = false,
    readMode = false,
    newPoint = false,
    noEditCode = false,
}) => {
    const dispatch = useDispatch()

    const {
        sandreCodes,
        managementUnits,
        applicationSettings,
        sectors,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        sectors: store.SectorReducer.sectors,
    }))

    const [newCode, setNewCode] = useState()
    const [open, setOpen] = useState()

    const states = useSandreList(SANDRE.CODE_ETAT)

    useEffect(() => {
        if (!managementUnits.length) {
            dispatch(ManagementUnitAction.fetchManagementUnits())
        }
    }, [])

    const onSaveBssCode = () => {
        dispatch(InstallationAction.changeBssCode({ ...station, code: newCode, fromCode: station.code, toCode: newCode }, () => {
            setOpen(false)
            onChange({ code: newCode })
        }))
    }

    const isDisplayed = (panel) => {
        return getSetting(applicationSettings, `PANEL_${station.installationType}_${panel}`) !== 'hidden'
    }

    return (
        <div className='row no-margin padding-top-1 padding-bottom-1'>
            <Row>
                <Input
                    col={ readMode || noEditCode ? 6 : 5 }
                    value={ station.code }
                    title={ i18n.code }
                    disabled={ !newPoint }
                    onChange={ v => {
                        onChange({ code: v })
                    } }
                    readMode={ readMode }
                    className='no-padding-right'
                />
                { !readMode && <div className='col s1 padding-top-11-px' style={noEditCode ? { display: 'none' } : {}}><Icon icon='edit' onClick={ () => setOpen(true) } /></div> }
                <NumberField
                    col={ 6 }
                    value={ station.id }
                    title={ i18n.internalIdentifier }
                    readMode={ readMode }
                    disabled={ !readMode }
                />
            </Row>
            <Row>
                <Input
                    col={ 6 }
                    value={ station.name }
                    title={ i18n.name }
                    onChange={ v => {
                        onChange({ name: v })
                        onChangeVisit({ previousValue: station.name, newValue: v, field: i18n.name })
                    } }
                    disabled={ disabled }
                    readMode={ readMode }
                />
                <Input
                    col={ 6 }
                    value={ station.mnemonic }
                    title={ i18n.mnemonic }
                    onChange={ v => {
                        onChange({ mnemonic: v })
                        onChangeVisit({ previousValue: station.mnemonic, newValue: v, field: i18n.mnemonic })
                    } }
                    disabled={ disabled }
                    readMode={ readMode }
                />
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <SimpleDatePicker
                    col={6}
                    id='creationDate'
                    label={i18n.creationDate}
                    value={station.creationDate}
                    onChange={v => {
                        onChange({ creationDate: v })
                        onChangeVisit(getVisitSimpleDatePickerChange(i18n.creationDate, station.creationDate, v))
                    }}
                    disabled={ disabled }
                    readMode={ readMode }
                />
                <SimpleDatePicker
                    col={6}
                    id='decommissioningDate'
                    label={i18n.decommissioningDate}
                    value={station.endDate}
                    onChange={v => {
                        onChange({ endDate: v })
                        onChangeVisit(getVisitSimpleDatePickerChange(i18n.decommissioningDate, station.endDate, v))
                    }}
                    disabled={ disabled }
                    readMode={ readMode }
                />
            </Row>
            <Row displayIf={station.installationType !== INSTALLATION_TYPE.STEP}>
                <Select
                    col={ 6 }
                    styleLabel={{ ...styleLabelSelect }}
                    value={ station.managementCode }
                    label={ i18n.managementUnit }
                    options={ sortBy(managementUnits.filter((u) => !u.parent), 'id') }
                    onChange={ v => {
                        onChange({ managementCode: v, subManagementCode: null })
                        // onChangeVisit(getVisitSelectChange(managementUnits, 'subManagementCode', i18n.subManagementUnit, station.subManagementCode, null))
                        onChangeVisit(getVisitSelectChange(managementUnits, 'managementCode', i18n.managementUnit, station.managementCode, v))
                    } }
                    keyValue='managementCode'
                    keyLabel='labelWithCode'
                    integerValue
                    disabled={ disabled }
                    readMode={ readMode }
                />
                <Select
                    col={ 6 }
                    styleLabel={{ ...styleLabelSelect }}
                    value={ station.subManagementCode }
                    label={ i18n.subManagementUnit }
                    options={ sortBy(managementUnits.filter((u) => u.parent === station.managementCode), 'id') }
                    onChange={ v => {
                        onChange({ subManagementCode: v })
                        onChangeVisit(getVisitSelectChange(managementUnits, 'subManagementCode', i18n.subManagementUnit, station.subManagementCode, v))
                    } }
                    keyValue='managementCode'
                    keyLabel='labelWithCode'
                    integerValue
                    disabled={ disabled }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Select
                    col={ 6 }
                    styleLabel={{ ...styleLabelSelect }}
                    value={ station.stateCode }
                    label={ i18n.state }
                    options={states}
                    onChange={ v => {
                        onChange({ stateCode: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.CODE_ETAT, i18n.state, station.stateCode, v))
                    } }
                    keyvalue='code'
                    integerValue
                    disabled={ disabled }
                    readMode={readMode}
                    data-cy='state'
                />
                <Checkbox
                    col={ 6 }
                    checked={ station.exploitationCode === 1 }
                    label={ i18n.exploitation }
                    onChange={ v => {
                        onChange({ exploitationCode: v ? 1 : 0 })
                        onChangeVisit(getVisitCheckBoxChange(i18n.exploitation, station.exploitationCode, v))
                    } }
                    disabled={readMode }
                />
            </Row>
            <Row displayIf={(station.installationType === INSTALLATION_TYPE.BOREHOLE
                || station.installationType === INSTALLATION_TYPE.STEP
                || station.installationType === INSTALLATION_TYPE.CAPTURE
                || station.installationType === INSTALLATION_TYPE.LIFTING_STATION
                || station.installationType === INSTALLATION_TYPE.PUMPING
                || station.installationType === INSTALLATION_TYPE.TREATMENT
                || station.installationType === INSTALLATION_TYPE.TANK
                || station.installationType === INSTALLATION_TYPE.AEP_PRODUCTION
                || station.installationType === INSTALLATION_TYPE.AEP_DISINFECTION
                || station.installationType === INSTALLATION_TYPE.AEP_OVERPRESSURE
                || station.installationType === INSTALLATION_TYPE.SECTOR_COUNT)}
            >
                <Select
                    col={6}
                    styleLabel={{ ...styleLabelSelect }}
                    value={station.sectorId}
                    label={i18n.sector}
                    options={sectors}
                    onChange={v => {
                        onChange({ sectorId: v })
                        onChangeVisit(getVisitSelectChange(sectors, 'sectorId', i18n.sector, station.sectorId, v))
                    }}
                    disabled={disabled}
                    readMode={readMode}
                />
                <Checkbox
                    col={6}
                    checked={station.floodZone}
                    label={i18n.floodZone}
                    onChange={v => {
                        onChange({ floodZone: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.floodZone, station.floodZone, v))
                    }}
                    disabled={readMode}
                />
            </Row>
            <Row>
                <div hidden={!(station.installationType === INSTALLATION_TYPE.PUMPING
                    || station.installationType === INSTALLATION_TYPE.TREATMENT
                    || station.installationType === INSTALLATION_TYPE.BOREHOLE
                    || station.installationType === INSTALLATION_TYPE.LIFTING_STATION
                )}
                >
                    <SimpleDatePicker
                        col={6}
                        id='effectiveReceiptDate'
                        label={i18n.effectiveReceiptDate}
                        value={station.effectiveReceiptDate}
                        onChange={v => {
                            onChange({ effectiveReceiptDate: v })
                            onChangeVisit(getVisitSimpleDatePickerChange(i18n.effectiveReceiptDate, station.effectiveReceiptDate, v))
                        }}
                        disabled={ disabled }
                        readMode={ readMode }
                    />
                </div>
                <div hidden={ !(station.installationType === INSTALLATION_TYPE.PUMPING
                            || station.installationType === INSTALLATION_TYPE.TREATMENT
                            || station.installationType === INSTALLATION_TYPE.BOREHOLE
                            || station.installationType === INSTALLATION_TYPE.TANK)}
                >
                    <SimpleDatePicker
                        col={6}
                        id='prodDate'
                        label={i18n.prodDate}
                        value={station.prodDate}
                        onChange={v => {
                            onChange({ prodDate: v })
                            onChangeVisit(getVisitSimpleDatePickerChange(i18n.prodDate, station.prodDate, v))
                        }}
                        disabled={ disabled }
                        readMode={ readMode }
                    />
                </div>
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <Textarea
                    col={ 12 }
                    value={ station.descriptive }
                    title={ i18n.descriptive }
                    onChange={ v => {
                        onChange({ descriptive: v })
                        onChangeVisit({ previousValue: station.descriptive, newValue: v, field: i18n.descriptive })
                    } }
                    disabled={ disabled }
                    readMode={ readMode }
                />
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <Textarea
                    col={ 12 }
                    value={ station.comments }
                    title={ i18n.comment }
                    onChange={ v => {
                        onChange({ comments: v })
                        onChangeVisit({ previousValue: station.comments, newValue: v, field: i18n.comment })
                    } }
                    disabled={ disabled }
                    readMode={ readMode }
                />
            </Row>
            <Row displayIf={ isDisplayed('INFOS_COMPL') }>
                <Input
                    col={ 6 }
                    value={ station.declarationNumber }
                    title={ i18n.declarationNumber }
                    onChange={ v => {
                        onChange({ declarationNumber: v })
                        onChangeVisit({ previousValue: station.declarationNumber, newValue: v, field: i18n.declarationNumber })
                    } }
                    disabled={ disabled }
                    readMode={ readMode }
                />
            </Row>
            <Dialog
                fullWidth
                maxWidth='xs'
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.changeCode}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpen(false)} />
                </DialogTitleMUI>
                <DialogContentMUI className='padding-top-1 padding-bottom-0'>
                    <Input
                        col={ 5 }
                        title={ i18n.code }
                        onChange={setNewCode}
                    />
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={onSaveBssCode} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        </div>
    )
}

InstallationInfosPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    managementUnits: arrayOf(DtoManagementUnit),
    applicationSettings: arrayOf(SieauParameterDto),
    sectors: arrayOf(DtoSector),
    newPoint: PropTypes.bool,
    noEditCode: PropTypes.bool,
}

export default InstallationInfosPanel
