import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { PATH_REFERENCIAL_CULTURE, PATH_REFERENCIAL_CULTURES_RPG_LINK, PATH_REFERENCIAL_CULTURE_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import {
    checkAuth,
    checkError,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import { CultureActionConstant } from '../reducers/CultureReducer'

const CultureAction = {

    updateCultureRPG(cultureRPG, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.cultureRPG(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(cultureRPG),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(CultureAction.fetchCultureRPG(code))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.cultureRPG} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.cultureRPG))
                })
        }
    },

    createCultureRPG(cultureRPG) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culturesRPG(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(cultureRPG),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.code) {
                        dispatch(push(PATH_REFERENCIAL_CULTURES_RPG_LINK + json.code))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.cultureRPG} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.cultureRPG))
                })
        }
    },

    deleteCultureRPG(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.cultureRPG(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_CULTURE))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.cultureRPG} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.cultureRPG))
                })
        }
    },

    promiseCultureRPG(code) {
        return fetch(ApplicationConf.referencial.cultureRPG(code), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCultureRPG(code) {
        return (dispatch) => {
            return CultureAction.promiseCultureRPG(code)
                .then((json = []) => {
                    dispatch(CultureActionConstant.RECEIVE_CULTURE_RPG(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.cultureRPG} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.cultureRPG))
                })
        }
    },

    promiseCulturesRPG() {
        return fetch(ApplicationConf.referencial.culturesRPG(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCulturesRPG() {
        return (dispatch) => {
            return CultureAction.promiseCulturesRPG()
                .then((json = []) => {
                    dispatch(CultureActionConstant.RECEIVE_ALL_CULTURES_RPG(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.culturesRPG} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.culturesRPG))
                })
        }
    },

    promiseRPG(year) {
        return fetch(ApplicationConf.referencial.RPG(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({ year }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchRPG(year = moment().year()) {
        return (dispatch) => {
            return CultureAction.promiseRPG(year)
                .then((json = []) => {
                    dispatch(CultureActionConstant.RECEIVE_ALL_RPG(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${`${i18n.fetchError}RPG`} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError}RPG`))
                })
        }
    },

    fetchImportRPGCSV(file, insertOrUpdate = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadCsvRPG(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent: file, insertOrUpdate }),
            })
                .then(checkAuth)
                .then(checkError)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertLines > 0) {
                        dispatch(ToastrAction.success(`RPG<br/>${i18n.insertNumber} : ${json.insertLines}<br/>${i18n.addError}${json.linesWithError}`))
                        dispatch(CultureAction.fetchRPG())
                    } else {
                        dispatch(ToastrAction.success(`RPG<br/>${i18n.noUpdate}<br/>${i18n.addError}${json.linesWithError}`))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError}RPG : ${err}`))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    promiseCultures() {
        return fetch(ApplicationConf.referencial.cultures(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchCultures() {
        return (dispatch) => {
            return CultureAction.promiseCultures()
                .then((json = []) => {
                    dispatch(CultureAction.fetchCulturesRPG())
                    dispatch(CultureActionConstant.RECEIVE_ALL_CULTURE(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.cultures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.cultures))
                })
        }
    },

    fetchCulture(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culture(code), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(CultureActionConstant.RECEIVE_CULTURE(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.culture} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.culture))
                })
        }
    },

    updateCulture(culture, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveCulture(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(culture),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(CultureAction.fetchCulture(code))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.culture} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.culture))
                })
        }
    },

    createCulture(culture) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.cultures(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(culture),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.id) {
                        dispatch(push(PATH_REFERENCIAL_CULTURE_LINK + json.id))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.culture} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.culture))
                })
        }
    },

    deleteCulture(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteCulture(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_CULTURE))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.culture} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.culture))
                })
        }
    },

    purgeCulture() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeCulture(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(CultureAction.fetchCultures())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.cultures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.cultures))
                })
        }
    },

    replaceCulture(culture, cultureReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceCulture(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: culture.toString(),
                    replaceCode: cultureReplace.toString(),
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.culture} : ${err}`))
                })
        }
    },

    fetchCulturesVentilations() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culturesVentilations(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    dispatch(CultureActionConstant.RECEIVE_ALL_CULTURES_VENTILATIONS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.cultures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.cultures))
                })
        }
    },

    createCulturesVentilations(culturesVentilations = []) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culturesVentilations(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(culturesVentilations),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (!json.error) {
                        if (culturesVentilations.length) {
                            dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        }
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.cultures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.cultures))
                })
        }
    },

    updateCulturesVentilations(culturesVentilations = []) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culturesVentilations(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(culturesVentilations),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (!json.error) {
                        if (culturesVentilations.length) {
                            dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        }
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.cultures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.cultures))
                })
        }
    },

    deleteCulturesVentilations(culturesVentilations = []) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.culturesVentilations(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(culturesVentilations),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (!json.error) {
                        if (culturesVentilations.length) {
                            dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        }
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.cultures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.cultures))
                })
        }
    },

    reset: () => dispatch => dispatch(CultureActionConstant.RESET_CULTURE()),

}

export default CultureAction
