import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { isUndefined, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import { H_MAT_POWER_SUPPLY } from '../../../../account/constants/AccessRulesConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import { PATH_MATERIEL_POWER_SUPPLY } from '../../../../home/constants/RouteConstants'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../../installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import ReferencialAppList from '../../../../referencial/components/ReferencialAppList'
import { getDate } from '../../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { createIconMaterialAssignment, createIconMaterialState } from '../../../../utils/MaterielUtils'
import { getLabel } from '../../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import { LIST_PANEL, MAP_PANEL, MAT_HEADERS } from '../../../constants/MaterielConstants'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import MaterielFilterForm from '../../filterPanel/MaterielFilterForm'
import MaterielsMapComponent from '../../map/MaterielsMapComponent'
import PowerSupplyAction from '../actions/PowerSupplyAction'
import DtoPowerSupplySituation from '../dto/DtoPowerSupplySituation'
import PowerSupplyDto from '../dto/PowerSupplyDto'
import PowerSupplyTypeDto from '../dto/PowerSupplyTypeDto'

class PowerSuppliesApp extends Component {
    constructor(props) {
        super(props)
        const { idType } = queryString.parse(this.props.location.search)
        this.state = {
            view: LIST_PANEL,
            filter: {
                matType: isUndefined(idType) ? undefined : parseInt(idType),
            },
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_MAT_POWER_SUPPLY)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setHelpLink('materiel', '')
        if (!this.props.powerSupplies.length) {
            this.props.fetchPowerSupplies(true)
        }
        if (!this.props.powerSupplyTypes.length) {
            this.props.fetchPowerSupplyTypes()
        }
    }

    getHash = mat => searchAllCharacters(MAT_HEADERS.map(key => mat[key]).join(''))

    getFilterMateriels = materiels => {
        const {
            network,
            state,
            administrator,
            manufacturer,
            matType,
            displayEquipment,
            searchValue,
            displayOutOfService,
        } = this.state.filter
        const filterEquipment = !displayEquipment ? materiels.filter(({ isEquipment }) => !isEquipment) : materiels
        const filterAdministrator = administrator ? filterEquipment.filter(({ administratorId }) => administrator === administratorId) : filterEquipment
        const filterManufacturer = manufacturer ? filterAdministrator.filter(({ manufacturerId }) => manufacturer === manufacturerId) : filterAdministrator
        const filterStatusCode = hasValue(state) ? filterManufacturer.filter(({ statusCode }) => statusCode === state) : filterManufacturer
        const filterType = matType ? filterStatusCode.filter(({ materielType }) => materielType === matType) : filterStatusCode
        const filterNetwork = network ? filterType.filter(({ networkCode }) => network === networkCode) : filterType
        const filterOutOfService = displayOutOfService ? filterNetwork : filterNetwork.filter(({ statusCode }) => statusCode !== 0 && statusCode !== 5 && statusCode !== 6)
        const searchValueFormated = searchAllCharacters(searchValue)
        return searchValue ? filterOutOfService.filter(mat => this.getHash(mat).includes(searchValueFormated)) : filterOutOfService
    }

    getExportData = (materiels) => {
        if (!materiels.length) {
            return []
        }
        const [head, ...tail] = materiels
        return [{ ...head, headers: MAT_HEADERS }, ...tail]
    }

    onChangeView = view => {
        this.setState({ view })
    }
    onValidate = filter => {
        this.setState({ filter })
    }

    getCorrespondingSite = ({ siteType, siteCode }) => {
        switch (siteType) {
            case 1:
                return this.props.piezometers.find(({ id }) => id === siteCode)
            case 2:
                return this.props.pluviometers.find(({ id }) => id === siteCode)
            case 3:
                return this.props.qualitometers.find(({ id }) => id === siteCode)
            case 4:
                return this.props.hydrometricStations.find(({ id }) => id === siteCode)
            case 7:
                return this.props.installations.find(({ id }) => id === siteCode)
            default:
                return {}
        }
    }

    getPanel = powerSupplies => {
        if (this.state.view === MAP_PANEL) {
            return (
                <MaterielsMapComponent
                    materiels={ powerSupplies }
                    materielLink={ PATH_MATERIEL_POWER_SUPPLY }
                    singularTitle={ i18n.powerSupply }
                />
            )
        }
        const formattedData = powerSupplies.map(mat => ({
            ...mat,
            state: createIconMaterialState(mat.state, mat.statusCode),
            assignment: mat.statusCode === 1 && createIconMaterialAssignment(mat.assignment, mat.siteType) || '',
            nullValue: mat.isEquipment && <i className='material-icons' style={{ fontSize: '1.5rem' }}>devices_other</i>,
        }))
        return (
            <ReferencialAppList
                title={ i18n.powerSupplies }
                data={ formattedData }
                exportData={ this.getExportData(powerSupplies) }
                type={{ headers: ['nullValue', ...MAT_HEADERS] } }
                newAction={() => this.props.push('/materiel/powerSupply/new')}
                showNewButton={true}
                showPurgeButton={false}
                setTitleAction={ SieauAction.forceFetch('title', [{
                    title: i18n.materiel,
                    href: 'materiel',
                }, {
                    title: i18n.powerSupplies,
                    href: 'materiel/powerSupply',
                }])}
                onClick={({ materielId }) => this.props.push(`/materiel/powerSupply/${materielId}`)}
                lastUpdate={null}
                searchable={false}
            />
        )
    }

    getPowerSupplyType = () => this.props.powerSupplyTypes.filter(({ id, label }) => !!this.props.powerSupplies.find(({ powerSupplyType }) => powerSupplyType === id) && label).map(t => ({
        id: t.id,
        label: t.label,
    }))

    render() {
        const materiels = this.props.powerSupplies.map(mat => {
            const lastSituation = this.props.powerSuppliesLastSituations.find(s => s.idPowerSupply === mat.id)
            const labelState = lastSituation ? getLabel(this.props.materielStates, lastSituation.statusCode) : ''
            const site = lastSituation && lastSituation.statusCode === 1 && lastSituation.siteType && lastSituation.siteCode && this.getCorrespondingSite(lastSituation) || {}
            const labelAssignment = (lastSituation && lastSituation.statusCode === 1 && lastSituation.siteName) || site.code || ''
            const city = site.townCode && this.props.cities.find((o) => o.code === site.townCode) || undefined
            const cityLabel = city ? `${city.name} - [${city.code}]` : (site.townCode || '')
            const departmentLabel = city && city.departmentNumber ? city.departmentNumber : ''
            return {
                materielId: mat.id,
                type: getLabel(this.props.powerSupplyTypes, mat.materielType),
                manufacturer: getLabel(this.props.contributors, mat.manufacturerId, 'labelDisplay', 'id'),
                state: labelState,
                statusCode: lastSituation && lastSituation.statusCode,
                stateDate: lastSituation && getDate(lastSituation.situationDate),
                city: cityLabel,
                administratorId: mat.administrator,
                serialNumber: mat.serialNumber,
                immoNum: mat.reference,
                assignment: labelAssignment,
                siteType: lastSituation && lastSituation.siteType,
                departmentShort: departmentLabel,
                purchaseDate: getDate(mat.purchaseDate),
                manufacturerId: mat.manufacturerId,
                networkCode: mat.networkCode,
                materielType: mat.materielType,
                isEquipment: mat.isEquipment,
                siteCode: lastSituation && lastSituation.siteCode,
                administrator: getLabel(this.props.contributors, mat.administrator, 'labelDisplay', 'code'),
            }
        })
        const filteredMateriels = this.getFilterMateriels(materiels)
        return (
            <div className='col no-padding s12'>
                <div className='row no-margin'>
                    <div className='col s12'>
                        <div className='row no-margin-bottom'>
                            <MaterielFilterForm
                                view={this.state.view}
                                filter={this.state.filter}
                                changeView={this.onChangeView}
                                materiels={materiels}
                                typeList={orderBy(this.getPowerSupplyType(), 'label')}
                                onValidate={this.onValidate}
                                materielType='POWER_SUPPLY'
                            />
                        </div>
                    </div>
                    {this.getPanel(filteredMateriels)}
                </div>
            </div>
        )
    }
}

PowerSuppliesApp.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.object,
    }),
    allowModifications: PropTypes.bool,
    getLink: PropTypes.func,
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    powerSuppliesLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupplySituation)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    resetAllActions: PropTypes.func,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    push: PropTypes.func,
    fetchPowerSupplies: PropTypes.func,
    fetchPowerSupplyTypes: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchCities: PropTypes.func,
    setHelpLink: PropTypes.func,
}

const mapStateToProps = store => ({
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    powerSuppliesLastSituations: store.PowerSupplyReducer.powerSuppliesLastSituations,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    qualitometers: store.QualityReducer.qualitometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installations: store.InstallationReducer.installations,
    materielStates: store.MaterielReducer.materielStates,
    cities: store.CityReducer.cities,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    push,
    fetchPowerSupplies: PowerSupplyAction.fetchPowerSupplies,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    setHelpLink: HomeAction.setHelpLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerSuppliesApp)
