import fetch from 'isomorphic-fetch'
import {
    CREATE_WATERMASS_STATE,
    DELETE_WATERMASS_STATE,
    RECEIVE_WATERMASS_STATES,
    RESET_WATERMASS,
    RESET_WATERMASS_STATIONS,
    STATIONS_WITH_WATERMASS,
    UPDATE_WATERMASS_STATE,
    WATERMASS,
    WATERMASSES,
} from '../constants/WatermassConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../../../conf/ApplicationConf'
import {
    checkAuth,
    checkError,
    genericCreate,
    genericDelete,
    genericFetch,
    genericPromise,
    genericUpdate,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../../components/sieau/SieauAction'
import { push } from 'connected-react-router'
import {
    PATH_REFERENCIAL_WATERMASS,
    PATH_REFERENCIAL_WATERMASS_ITEM_LINK,
} from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'

const WatermassAction = {
    receiveWatermass(watermass) {
        return { type: WATERMASS, watermass }
    },

    fetchWatermass(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.watermass(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(WatermassAction.receiveWatermass(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watermass} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.watermass))
                })
        }
    },

    receiveWatermasses(watermasses) {
        return { type: WATERMASSES, watermasses }
    },

    promiseWatermasses() {
        return fetch(ApplicationConf.referencial.watermasses(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchWatermasses() {
        return (dispatch) => {
            dispatch(SieauAction.receiveProps('watermasses'))
            return WatermassAction.promiseWatermasses()
                .then((json = {}) => {
                    dispatch(WatermassAction.receiveWatermasses(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watermasses} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.watermasses))
                })
        }
    },

    receiveStationsWithWatermass(stations) {
        return { type: STATIONS_WITH_WATERMASS, stations }
    },

    fetchStationsWithWatermass(watermassCode) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.watermassStations(watermassCode), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(WatermassAction.receiveStationsWithWatermass(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watermassStations} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.watermassStations))
                })
        }
    },

    createWatermass(watermass) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.watermasses(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(watermass),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_WATERMASS_ITEM_LINK + watermass.code))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.watermass} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.watermass))
                })
        }
    },

    updateWatermass(watermass, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveWatermass(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(watermass),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(WatermassAction.fetchWatermass(code))
                        dispatch(ToastrAction.success(i18n.watermassSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watermass} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.watermass))
                })
        }
    },

    fetchSynchroSandreWatermass() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateWatermass(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(WatermassAction.fetchWatermass())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.watermasses + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.watermasses))
                })
        }
    },

    deleteWatermass(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteWatermass(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_WATERMASS))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.watermass} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.watermass))
                })
        }
    },

    purgeWatermass() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeWatermass(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(WatermassAction.fetchWatermasses())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.watermasses} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.watermasses))
                })
        }
    },

    replaceWatermass(watermass, watermassReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceWatermass(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: watermass.toString(),
                    replaceCode: watermassReplace.toString(),
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.watermassSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.watermass} : ${err}`))
                })
        }
    },

    promiseWatermassStates(id) {
        return genericPromise(ApplicationConf.referencial.watermassState(id))
    },
    fetchWatermassStates(id) {
        return genericFetch(WatermassAction.promiseWatermassStates(id), RECEIVE_WATERMASS_STATES)
    },
    updateWatermassState(state) {
        return genericUpdate(ApplicationConf.referencial.watermassState(state.qualitometer), UPDATE_WATERMASS_STATE, state)
    },
    createWatermassState(state) {
        return genericCreate(ApplicationConf.referencial.watermassState(state.qualitometer), CREATE_WATERMASS_STATE, state)
    },
    deleteWatermassState(code, state) {
        return genericDelete(ApplicationConf.referencial.watermassState(code), DELETE_WATERMASS_STATE, { code, state })
    },

    resetWatermassStations() {
        return { type: RESET_WATERMASS_STATIONS }
    },

    reset() {
        return { type: RESET_WATERMASS }
    },
}

export default WatermassAction
