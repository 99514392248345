import ActionComponent from 'components/ActionComponent'
import { push } from 'connected-react-router'
import { flatten } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { H_CAMPAIGN_PIEZO_MAT } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import MaterialEquivalentCard from '../../../campaign/components/equivalentCard/MaterialEquivalentCard'
import PowerSupplyEquivalentCard from '../../../campaign/components/equivalentCard/PowerSupplyEquivalentCard'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoStationCampaign from '../../../campaign/dto/DtoStationCampaign'
import Card from '../../../components/card/Card'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import CentralAssignatedDto from '../../../materiel/components/central/dto/CentralAssignatedDto'
import CentralDto from '../../../materiel/components/central/dto/CentralDto'
import CentralTypeDto from '../../../materiel/components/central/dto/CentralTypeDto'
import DtoCentralSituation from '../../../materiel/components/central/dto/DtoCentralSituation'
import DtoEquipmentSituation from '../../../materiel/components/equipment/dto/DtoEquipmentSituation'
import EquipmentAssignatedDto from '../../../materiel/components/equipment/dto/EquipmentAssignatedDto'
import EquipmentDto from '../../../materiel/components/equipment/dto/EquipmentDto'
import EquipmentTypeDto from '../../../materiel/components/equipment/dto/EquipmentTypeDto'
import DtoPowerSupplySituation from '../../../materiel/components/powerSupply/dto/DtoPowerSupplySituation'
import PowerSupplyAssignatedDto from '../../../materiel/components/powerSupply/dto/PowerSupplyAssignatedDto'
import PowerSupplyDto from '../../../materiel/components/powerSupply/dto/PowerSupplyDto'
import PowerSupplyTypeDto from '../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import DtoSensorSituation from '../../../materiel/components/sensor/dto/DtoSensorSituation'
import DtoSensorType from '../../../materiel/components/sensor/dto/DtoSensorType'
import SensorAssignatedDto from '../../../materiel/components/sensor/dto/SensorAssignatedDto'
import SensorDto from '../../../materiel/components/sensor/dto/SensorDto'
import DtoSimSituation from '../../../materiel/components/sim/dto/DtoSimSituation'
import DtoSimType from '../../../materiel/components/sim/dto/DtoSimType'
import SimAssignatedDto from '../../../materiel/components/sim/dto/SimAssignatedDto'
import SimDto from '../../../materiel/components/sim/dto/SimDto'
import DtoTelecomSituation from '../../../materiel/components/telecom/dto/DtoTelecomSituation'
import TelecomAssignatedDto from '../../../materiel/components/telecom/dto/TelecomAssignatedDto'
import TelecomDto from '../../../materiel/components/telecom/dto/TelecomDto'
import TelecomTypeDto from '../../../materiel/components/telecom/dto/TelecomTypeDto'
import DtoVariousMaterielSituation from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import DtoVariousMaterielType from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielAssignatedDto from '../../../materiel/components/variousMateriel/dto/VariousMaterielAssignatedDto'
import VariousMaterielDto from '../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import {
    MAT_CENTRAL,
    MAT_DIVERS,
    MAT_EQUIPMENT,
    MAT_SENSOR,
    MAT_SIM,
    MAT_TELECOM,
} from '../../../materiel/constants/MaterielConstants'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { getDate } from '../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getLabel } from '../../../utils/StoreUtils'
import DtoPiezometerLight from '../../dto/DtoPiezometerLight'

class PiezometryCampaignMaterielsApp extends ActionComponent {
    state = {
        displayMode: 'stations',
        dataLoaded: false,
        progress: 0,
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_CAMPAIGN_PIEZO_MAT)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.loadCampaignMaterielsApp(this.props.match.params.id, p => this.setState({ progress: p }))
            .then(() => {
                this.setState({ dataLoaded: true })
                this.setTitle(this.props.campaign)
            })
        this.setActionCampaignMateriel()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.setState({ dataLoaded: false, progress: 0 })
            this.props.loadCampaignMaterielsApp(this.props.match.params.id, p => this.setState({ progress: p }))
                .then(() => {
                    this.setState({ dataLoaded: true })
                    this.setTitle(this.props.campaign)
                })
        }
    }

    setActionCampaignMateriel = () => {
        const actions = {
            export: () => ({
                data: this.getExportData(),
                exportType: 'xlsx',
                titleFile: `${i18n.campaigns}_${i18n.piezometry}_${i18n.materiel}`,
            }),
        }
        this.setActions(actions)
    }

    setTitle = ({ name, id }) => {
        this.props.forceFetch('title', [{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: i18n.campaigns,
            href: 'piezometry/campaign',
        }, {
            title: name,
            href: `piezometry/campaign/${id}`,
        }, {
            title: i18n.materiels,
            href: `piezometry/campaign/${id}/materiel`,
        }])
    }

    getMaterialExport = (station, materials, campaignMaterials, lastSituations, materialsTypes, keyType, keyId, keyToDisplay) => {
        const mats = campaignMaterials.filter(c => c.siteCode === station.id)
        const listMaterials = mats.map(mat => {
            const matEquivalent = materials.find(m => {
                const lastSituation = lastSituations.find(situation => situation[keyId] === m.id)
                return m[keyType] === mat[keyType] && m[keyToDisplay] && lastSituation && lastSituation.statusCode === 2
            }) || {}
            const obj = {
                station: station.code,
                situationDate: getDate(mat.situationDate),
                serialNumber: mat[keyToDisplay],
                type: getLabel(materialsTypes, mat[keyType]),
                serialNumberEquivalent: matEquivalent[keyToDisplay] || '',
                typeEquivalent: getLabel(materialsTypes, matEquivalent[keyType]),
            }
            return obj
        })
        return listMaterials
    }

    getExportData = () => {
        const data = this.props.campaignStations.map(campaignStation => {
            const station = this.props.piezometers.find(p => p.id === campaignStation.stationId)
            if (station) {
                return [
                    ...this.getMaterialExport(station, this.props.centrals, this.props.campaignMaterielsCentrals, this.props.centralsLastSituations, this.props.centralTypes, 'centralType', 'idCentral', 'serialNumber'),
                    ...this.getMaterialExport(station, this.props.equipments, this.props.campaignMaterielsEquipments, this.props.equipmentsLastSituations, this.props.equipmentTypes, 'equipmentType', 'idEquipment', 'serialNumber'),
                    ...this.getMaterialExport(station, this.props.sensors, this.props.campaignMaterielsSensors, this.props.sensorsLastSituations, this.props.sensorTypes, 'sensorType', 'idSensor', 'serialNumber'),
                    ...this.getMaterialExport(station, this.props.sims, this.props.campaignMaterielsSims, this.props.simsLastSituations, this.props.simTypes, 'simType', 'idSim', 'serialNumber'),
                    ...this.getMaterialExport(station, this.props.telecoms, this.props.campaignMaterielsTelecoms, this.props.telecomsLastSituations, this.props.telecomTypes, 'telecomType', 'idTelecom', 'imei'),
                    ...this.getMaterialExport(station, this.props.powerSupplies, this.props.campaignMaterielsPowerSupplies, this.props.powerSuppliesLastSituations, this.props.powerSupplyTypes, 'powerSupplyType', 'idPowerSupply', 'serialNumber'),
                    ...this.getMaterialExport(station, this.props.variousMateriels, this.props.campaignMaterielsVariousMateriels, this.props.variousMaterielsLastSituations, this.props.variousMaterielTypes, 'variousMaterielType', 'idVarious', 'serialNumber'),
                ]
            }
            return []
        })
        const dataToExport = flatten(data)
        if (dataToExport.length) {
            dataToExport[0].headers = [
                'station',
                'situationDate',
                'serialNumber',
                'type',
                'serialNumberEquivalent',
                'typeEquivalent',
            ]
        }
        return dataToExport
    }

    containsMateriels = materiels => {
        return materiels.central.length ||
               materiels.sensor.length ||
               materiels.sim.length ||
               materiels.variousMateriel.length ||
               materiels.powerSupply.length ||
               materiels.telecom.length ||
               materiels.equipment.length
    }

    getCentralCard = campaignMateriels => !!campaignMateriels.length && (
        <MaterialEquivalentCard
            iconName={'branding_watermark'}
            type={MAT_CENTRAL}
            tooltipIcon={i18n.central}
            keyType={'centralType'}
            keyToDisplay={'serialNumber'}
            keyMaterial={'idCentral'}
            materials={this.props.centrals}
            campaignMaterials={campaignMateriels}
            lastSituations={this.props.centralsLastSituations}
            materialsTypes={this.props.centralTypes}
        />
    )

    getEquipmentCard = campaignMateriels => !!campaignMateriels.length && (
        <MaterialEquivalentCard
            iconName={'devices_other'}
            type={MAT_EQUIPMENT}
            tooltipIcon={i18n.equipment}
            keyType={'equipmentType'}
            keyToDisplay={'serialNumber'}
            keyMaterial={'idEquipment'}
            materials={this.props.equipments}
            campaignMaterials={campaignMateriels}
            lastSituations={this.props.equipmentsLastSituations}
            materialsTypes={this.props.equipmentTypes}
        />
    )

    getSensorCard = campaignMateriels => !!campaignMateriels.length && (
        <MaterialEquivalentCard
            iconName={'settings_input_hdmi'}
            type={MAT_SENSOR}
            tooltipIcon={i18n.sensor}
            keyType={'sensorType'}
            keyToDisplay={'serialNumber'}
            keyMaterial={'idSensor'}
            materials={this.props.sensors}
            campaignMaterials={campaignMateriels}
            lastSituations={this.props.sensorsLastSituations}
            materialsTypes={this.props.sensorTypes}
        />
    )

    getSimCard = campaignMateriels => !!campaignMateriels.length && (
        <MaterialEquivalentCard
            iconName={'sim_card'}
            type={MAT_SIM}
            tooltipIcon={i18n.sim}
            keyType={'simType'}
            keyToDisplay={'serialNumber'}
            keyMaterial={'idSim'}
            materials={this.props.sims}
            campaignMaterials={campaignMateriels}
            lastSituations={this.props.simsLastSituations}
            materialsTypes={this.props.simTypes}
        />
    )

    getTelecomCard = campaignMateriels => !!campaignMateriels.length && (
        <MaterialEquivalentCard
            iconName={'router'}
            type={MAT_TELECOM}
            tooltipIcon={i18n.telecom}
            keyType={'telecomType'}
            keyToDisplay={'imei'}
            keyMaterial={'idTelecom'}
            materials={this.props.telecoms}
            campaignMaterials={campaignMateriels}
            lastSituations={this.props.telecomsLastSituations}
            materialsTypes={this.props.telecomTypes}
        />
    )

    getPowerSupplyCard = campaignMateriels => !!campaignMateriels.length && (
        <PowerSupplyEquivalentCard
            campaignMaterials={campaignMateriels}
        />
    )

    getVariousMaterielCard = campaignMateriels => !!campaignMateriels.length && (
        <MaterialEquivalentCard
            iconName={'more_horiz'}
            type={MAT_DIVERS}
            tooltipIcon={i18n.variousMateriel}
            keyType={'materielType'}
            keyToDisplay={'serialNumber'}
            keyMaterial={'id'}
            materials={this.props.variousMateriels}
            campaignMaterials={campaignMateriels}
            lastSituations={this.props.variousMaterielsLastSituations}
            materialsTypes={this.props.variousMaterielTypes}
        />
    )

    getStationCard = matStation => {
        const { station, ...materiels } = matStation
        if (station && this.containsMateriels(materiels)) {
            const stationCode = station.code ? `[${station.code}]` : ''
            return (
                <Card title={ `${stationCode} ${getLabel(this.props.cities, station.townCode)} - ${station.name}` } active={ false }>
                    <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1'>
                        <div className='col s12'>
                            { this.getCentralCard(materiels.central) }
                            { this.getSensorCard(materiels.sensor) }
                            { this.getSimCard(materiels.sim) }
                            { this.getTelecomCard(materiels.telecom) }
                            { this.getVariousMaterielCard(materiels.variousMateriel) }
                            { this.getPowerSupplyCard(materiels.powerSupply) }
                            { this.getEquipmentCard(materiels.equipment) }
                        </div>
                    </div>
                </Card>
            )
        }
        return null
    }

    getPanelStations = materiels => materiels.map(this.getStationCard)

    getMaterielStation = () => this.props.campaignStations.map(station => ({
        station: this.props.piezometers.find(p => p.id === station.stationId),
        central: this.props.campaignMaterielsCentrals.filter(c => c.siteCode === station.stationId),
        sensor: this.props.campaignMaterielsSensors.filter(s => s.siteCode === station.stationId),
        sim: this.props.campaignMaterielsSims.filter(s => s.siteCode === station.stationId),
        telecom: this.props.campaignMaterielsTelecoms.filter(t => t.siteCode === station.stationId),
        variousMateriel: this.props.campaignMaterielsVariousMateriels.filter(v => v.siteCode === station.stationId),
        powerSupply: this.props.campaignMaterielsPowerSupplies.filter(p => p.siteCode === station.stationId),
        equipment: this.props.campaignMaterielsEquipments.filter(e => e.siteCode === station.stationId),
    })).filter(s => s.station)

    getPowerSupplyByStation = matStation => {
        const recapPowerSupply = matStation.reduce((acc, { central, powerSupply, sensor, sim, telecom, equipment, variousMateriel }) =>
            [
                ...central,
                ...powerSupply,
                ...sensor,
                ...sim,
                ...telecom,
                ...equipment,
                ...variousMateriel,
            ].filter(e => !!e.powerSupplyType).reduce((acc2, e) => ({
                ...acc2,
                [e.powerSupplyType]: acc2[e.powerSupplyType] && acc2[e.powerSupplyType] + (e.pileNumber || 1) || e.pileNumber || 1,
            }), acc), {})
        return Object.keys(recapPowerSupply).map(key => ({
            materielType: (this.props.powerSupplyTypes.find(pst => pst.id == key) || {}).name,
            nbPowerSupply: recapPowerSupply[key],
        }))
    }

    getPanel = () => {
        const materiels = this.getMaterielStation()
        const recapPowerSupply = this.getPowerSupplyByStation(materiels)
        const results = this.getPanelStations(materiels)
        return results.filter(s => !!s).length ? (
            <Card maxWidth={1200} className='transparent no-box-shadow padding-bottom-1' contentClassName={ 'transparent' }>
                {
                    !!recapPowerSupply.length && (
                        <div className='row no-margin padding-top-1'>
                            <Table
                                title={i18n.powerSupplies}
                                type={{ headers: ['materielType', 'nbPowerSupply'] }}
                                data={recapPowerSupply}
                                showNbElements={false}
                                active={false}
                            />
                        </div>
                    )
                }
                <div className='row no-margin padding-top-1'>
                    { results }
                </div>
            </Card>
        ) : (
            <Card>
                <div className='row no-margin padding-top-1 padding-left-3 padding-bottom-1' >
                    <h5>{ i18n.noMateriel }</h5>
                </div>
            </Card>
        )
    }

    render() {
        if (this.state.dataLoaded) {
            return this.getPanel()
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

PiezometryCampaignMaterielsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    campaign: PropTypes.instanceOf(CampaignDto),
    campaignStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    campaignMaterielsEquipments: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentAssignatedDto)),
    campaignMaterielsCentrals: PropTypes.arrayOf(PropTypes.instanceOf(CentralAssignatedDto)),
    campaignMaterielsSensors: PropTypes.arrayOf(PropTypes.instanceOf(SensorAssignatedDto)),
    campaignMaterielsSims: PropTypes.arrayOf(PropTypes.instanceOf(SimAssignatedDto)),
    campaignMaterielsPowerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyAssignatedDto)),
    campaignMaterielsVariousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielAssignatedDto)),
    campaignMaterielsTelecoms: PropTypes.arrayOf(PropTypes.instanceOf(TelecomAssignatedDto)),
    centrals: PropTypes.arrayOf(PropTypes.instanceOf(CentralDto)),
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    sensors: PropTypes.arrayOf(PropTypes.instanceOf(SensorDto)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    equipments: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentDto)),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(SimDto)),
    telecoms: PropTypes.arrayOf(PropTypes.instanceOf(TelecomDto)),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorType)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimType)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    centralsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralSituation)),
    powerSuppliesLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupplySituation)),
    sensorsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorSituation)),
    variousMaterielsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    equipmentsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipmentSituation)),
    simsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimSituation)),
    telecomsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecomSituation)),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    campaign: store.CampaignReducer.campaign,
    campaignStations: store.CampaignReducer.campaignStations,
    piezometers: store.PiezometryReducer.piezometersLight,
    campaignMaterielsEquipments: store.CampaignReducer.campaignMaterielsEquipments,
    campaignMaterielsCentrals: store.CampaignReducer.campaignMaterielsCentrals,
    campaignMaterielsSensors: store.CampaignReducer.campaignMaterielsSensors,
    campaignMaterielsSims: store.CampaignReducer.campaignMaterielsSims,
    campaignMaterielsPowerSupplies: store.CampaignReducer.campaignMaterielsPowerSupplies,
    campaignMaterielsVariousMateriels: store.CampaignReducer.campaignMaterielsVariousMateriels,
    campaignMaterielsTelecoms: store.CampaignReducer.campaignMaterielsTelecoms,
    centrals: store.CentralReducer.centrals,
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    sensors: store.SensorReducer.sensors,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    equipments: store.EquipmentReducer.equipments,
    sims: store.SimReducer.sims,
    telecoms: store.TelecomReducer.telecoms,
    centralTypes: store.CentralReducer.centralTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sensorTypes: store.SensorReducer.sensorTypes,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    simTypes: store.SimReducer.simTypes,
    telecomTypes: store.TelecomReducer.telecomTypes,
    centralsLastSituations: store.CentralReducer.centralsLastSituations,
    powerSuppliesLastSituations: store.PowerSupplyReducer.powerSuppliesLastSituations,
    sensorsLastSituations: store.SensorReducer.sensorsLastSituations,
    variousMaterielsLastSituations: store.VariousMaterielReducer.variousMaterielsLastSituations,
    equipmentsLastSituations: store.EquipmentReducer.equipmentsLastSituations,
    simsLastSituations: store.SimReducer.simsLastSituations,
    telecomsLastSituations: store.TelecomReducer.telecomsLastSituations,
})

const mapDispatchToProps = {
    loadCampaignMaterielsApp: CampaignAction.loadCampaignMaterielsApp,
    forceFetch: SieauAction.forceFetch,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryCampaignMaterielsApp)
