import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import Row from '../../../../components/react/Row'
import NumberField from '../../../../components/forms/NumberField'
import DtoDistributionUnit from '../../../../distributionUnit/dto/DtoDistributionUnit'
import DtoCaptureStation from '../../../../installation/components/capture/dto/DtoCaptureStation'
import DtoCaptureDownstreamDistribUnit from '../dto/DtoCaptureDownstreamDistribUnit'
import { getExport } from '../../../../utils/linkUtils'
import { orderBy, sum } from 'lodash'
import { arrayOf } from '../../../../utils/StoreUtils'
import { push } from 'connected-react-router'
import DtoAssociatedStation from '../../../../station/dto/DtoAssociatedStation'

const CaptureDownstreamDistribUnitPanel = ({
    station = {},
    captureDownstreamDistribUnits = [],
    distributionUnits = [],
    associatedSites = [],
    reduxPush = push,
}) => {
    const stationDownstreamUnits = captureDownstreamDistribUnits.filter(cddu => cddu.idStation === station.id)
    const tableDataDownstream = orderBy(stationDownstreamUnits.map(d => {
        const distribUnit = distributionUnits.find(du => du.id === d.downstreamIdStation) || {}
        return {
            ...distribUnit,
            downstreamLevel: d.downstreamLevel,
            decreePopulation: distribUnit.decreePopulation || 0,
            population: distribUnit.decreePopulation,
        }
    }), ['downstreamLevel', 'decreePopulation'], ['asc', 'desc'])

    const stationAssociated = associatedSites.filter(as => as.stationLinkedType === 6)
    const tableDataAssociated = orderBy(stationAssociated.map(d => {
        const distribUnit = distributionUnits.find(du => du.code === d.stationLinkedCode) || {}
        return {
            ...distribUnit,
            downstreamLevel: d.downstreamLevel,
            decreePopulation: distribUnit.decreePopulation || 0,
            population: distribUnit.decreePopulation,
        }
    }), ['downstreamLevel', 'decreePopulation'], ['asc', 'desc'])

    const headersAssociated = ['code', 'name', 'population']
    const headersDownstream = ['code', 'name', 'population', 'downstreamLevel']
    const exportActionAssociated = getExport(tableDataDownstream, i18n.downstreamUDI, headersAssociated)
    const exportActionDownstream = getExport(tableDataDownstream, i18n.downstreamUDI, headersDownstream)

    return (
        <Row>
            <Row>
                <NumberField
                    col={6}
                    title={ i18n.population }
                    value={ `${sum([...tableDataDownstream.map(d => d.decreePopulation), ...tableDataAssociated.map(d => d.decreePopulation)]) || '0'} habs` }
                    readMode={true}
                />
            </Row>
            <Row className='padding-1 no-padding-top no-padding-bottom'>
                <Table
                    title={ i18n.directUDI }
                    condensed
                    className='blue'
                    data={ tableDataAssociated }
                    type={{ headers: headersAssociated }}
                    sortable={ !!tableDataAssociated.length }
                    actions={ [exportActionAssociated] }
                    onClick= { udi => reduxPush(`station/distributionUnit/${udi.id}`) }
                />
            </Row>
            <Row className='padding-1 no-padding-top no-padding-bottom margin-top-1' noMargin={false}>
                <Table
                    title={ i18n.downstreamUDI }
                    condensed
                    className='blue'
                    data={ tableDataDownstream }
                    type={{ headers: headersDownstream }}
                    sortable={ !!tableDataDownstream.length }
                    actions={ [exportActionDownstream] }
                    onClick= { udi => reduxPush(`station/distributionUnit/${udi.id}`) }
                />
            </Row>
        </Row>

    )
}

CaptureDownstreamDistribUnitPanel.propTypes = {
    station: PropTypes.instanceOf(DtoCaptureStation),
    captureDownstreamDistribUnits: arrayOf(DtoCaptureDownstreamDistribUnit),
    distributionUnits: arrayOf(DtoDistributionUnit),
    associatedSites: arrayOf(DtoAssociatedStation),
    reduxPush: PropTypes.func,
}

const mapStateToProps = store => ({
    captureDownstreamDistribUnits: store.DistributionUnitReducer.captureDownstreamDistribUnits,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    associatedSites: store.StationReducer.associatedSites,
})

const mapDispatchToProps = {
    reduxPush: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureDownstreamDistribUnitPanel)