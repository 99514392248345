import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Checkbox from '../../../components/forms/Checkbox'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getLogin, getSetting, getUser } from '../../../utils/SettingUtils'
import moment from 'moment'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import InstallationInfosPanel from '../installations/components/InstallationInfosPanel'
import InstallationComElecPanel from '../installations/components/InstallationComElecPanel'
import InstallationAccessSitePanel from '../installations/components/InstallationAccessSitePanel'
import InstallationAccessCasingPanel from '../installations/components/InstallationAccessCasingPanel'
import StationLocationInfoPanel from '../../../station/components/location/StationLocationInfoPanel'
import Row from '../../../components/react/Row'
import InstallationStaffSafetyPanel from '../installations/components/InstallationStaffSafetyPanel'
import DtoTreatmentStation from './dto/DtoTreatmentStation'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import NumberField from '../../../components/forms/NumberField'
import { arrayOf } from '../../../utils/StoreUtils'
import { downloadURI } from '../../../utils/ExportDataUtil'
import {
    getVisitCheckBoxChange,
    getVisitDatePickerChange,
    getVisitSelectChange,
} from '../../../utils/VisitUtils'
import Select from '../../../components/forms/Select'
import { getDate } from '../../../utils/DateUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import LocalisationMapModal from '../installations/components/modal/LocalisationMapModal'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import StationSamplersPanel from 'station/components/link/StationSamplersPanel'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import ElecmecEquipmentPanel from '../installations/components/ElecmecEquipmentPanel'

class TreatmentStationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            usedWater: {},
            treatment: new DtoTreatmentStation({}),
            readMode: true,
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationTreatment(this.props.installation.id).then(() => {
                this.setState({ treatment: { ...this.props.installationTreatment, idStation: this.props.installation.id } })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () => this.props.push('/installation'))
    }

    onSave = (visit) => {
        const { installation, treatment } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    treatment,
                }
            ).then(() => this.setReadOnlyMode())
        }
        return this.props.updateInstallationTreatment(
            installation,
            treatment
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, treatment: this.props.installationTreatment })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) => this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeTreatment = (newObject) => this.setState({ treatment: { ...this.state.treatment, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, readMode, treatment, localizeMap } = this.state
        const mode = { readMode, editMode: !readMode }
        const { accountHabilitations } = this.props
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_ST_TRAITEMENT,
        }

        const paramsTreatment = {
            station: treatment,
            onChange: this.onChangeTreatment,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <div className='row padding-top-1 margin-left-2 padding-bottom-5' id='TreatmentStationDescriptionPanel'>
                <div className='col s9' style={ { marginBottom: '100px' } }>
                    <Card title={ i18n.description } >
                        <Row>
                            <InstallationInfosPanel { ...params } />
                            <Row>
                                <Checkbox
                                    col={ 6 }
                                    checked={ treatment.monitoringProgram }
                                    label={ i18n.monitoringProgram }
                                    onChange={ v => {
                                        this.onChangeTreatment({ monitoringProgram: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.monitoringProgram, treatment.monitoringProgram, v))
                                    } }
                                    disabled={ readMode }
                                />
                                <Input
                                    col={ 6 }
                                    value={ treatment.exemptionOrder }
                                    title={ i18n.exemptionOrder }
                                    onChange={ v => {
                                        this.onChangeTreatment({ exemptionOrder: v })
                                        this.onChangeVisit({ previousValue: treatment.exemptionOrder, newValue: v, field: i18n.exemptionOrder })
                                    } }
                                    readMode={ readMode }
                                />
                            </Row>
                            <Row>
                                <NumberField
                                    col={ 6 }
                                    value={ treatment.nominalCapacity }
                                    title={ i18n.nominalCapacity }
                                    onChange={ (v) => {
                                        this.onChangeTreatment({ nominalCapacity: v })
                                        this.onChangeVisit({ previousValue: treatment.nominalCapacity, newValue: v, field: i18n.nominalCapacity })
                                    } }
                                    readMode={ readMode }
                                />
                                <NumberField
                                    col={ 6 }
                                    value={ treatment.nominalHourlyCapacity }
                                    title={ i18n.nominalHourlyCapacity }
                                    onChange={ (v) => {
                                        this.onChangeTreatment({ nominalHourlyCapacity: v })
                                        this.onChangeVisit({ previousValue: treatment.nominalHourlyCapacity, newValue: v, field: i18n.nominalHourlyCapacity })
                                    } }
                                    readMode={ readMode }
                                />
                            </Row>
                            <Row>
                                <Input
                                    col={ 6 }
                                    value={ treatment.treatedParams }
                                    title={ i18n.treatedParams }
                                    onChange={ (v) => {
                                        this.onChangeTreatment({ treatedParams: v })
                                        this.onChangeVisit({ previousValue: treatment.treatedParams, newValue: v, field: i18n.treatedParams })
                                    } }
                                    readMode={ readMode }
                                />
                                <Input
                                    col={6}
                                    value={treatment.processingSector}
                                    title={i18n.processingSector}
                                    onChange={(v) => {
                                        this.onChangeTreatment({ processingSector: v })
                                        this.onChangeVisit({ previousValue: treatment.processingSector, newValue: v, field: i18n.processingSector })
                                    }}
                                    readMode={readMode}
                                />
                            </Row>
                            <Row>
                                <Checkbox
                                    col={ 6 }
                                    checked={ treatment.autorisationDecree }
                                    label={ i18n.autorisationDecree }
                                    onChange={ (v) => {
                                        this.onChangeTreatment({ autorisationDecree: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.autorisationDecree, treatment.autorisationDecree, v))
                                    } }
                                    disabled={ readMode }
                                />
                                <Input
                                    col={ 6 }
                                    value={ treatment.numDecree }
                                    title={ i18n.numDecree }
                                    onChange={ (v) => {
                                        this.onChangeTreatment({ numDecree: v })
                                        this.onChangeVisit({ previousValue: treatment.numDecree, newValue: v, field: i18n.numDecree })
                                    } }
                                    readMode={ readMode }
                                />
                            </Row>
                            <Row>
                                <Input
                                    col={ 6 }
                                    value={ getDate(treatment.autorisationDecreeDate) }
                                    title={ i18n.autorisationDecreeDate }
                                    onChange={ v => {
                                        onChangeDate(v, v2 => this.onChangeTreatment({ autorisationDecreeDate: v2 }))
                                        this.onChangeVisit(getVisitDatePickerChange(i18n.autorisationDecreeDate, treatment.autorisationDecreeDate, v))
                                    } }
                                    readMode={ readMode }
                                />
                                <NumberField
                                    col={ 6 }
                                    value={ treatment.decreeDuration }
                                    title={ i18n.decreeDuration }
                                    onChange={ (v) => {
                                        this.onChangeTreatment({ decreeDuration: v })
                                        this.onChangeVisit({ previousValue: treatment.decreeDuration, newValue: v, field: i18n.decreeDuration })
                                    } }
                                    readMode={ readMode }
                                />
                            </Row>
                            <Row>
                                <Checkbox
                                    col={ 6 }
                                    checked={ treatment.rejectAutorisation }
                                    label={ i18n.rejectAutorisation }
                                    onChange={ (v) => {
                                        this.onChangeTreatment({ rejectAutorisation: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.rejectAutorisation, treatment.rejectAutorisation, v))
                                    } }
                                    disabled={ readMode }
                                />
                                <Input
                                    col={ 6 }
                                    value={ getDate(treatment.rejectAutorisationDate) }
                                    title={ i18n.rejectAutorisationDate }
                                    onChange={ v => {
                                        onChangeDate(v, v2 => this.onChangeTreatment({ rejectAutorisationDate: v2 }))
                                        this.onChangeVisit(getVisitDatePickerChange(i18n.rejectAutorisationDate, treatment.rejectAutorisationDate, v))
                                    } }
                                    readMode={ readMode }
                                />
                            </Row>
                            <Row className='padding-1'>
                                <InstallationEquipmentsPanel { ...params } />
                            </Row>
                            <Row className='padding-1'>
                                <BuildingsPanel {...params} />
                            </Row>
                            <Row
                                className='no-margin-bottom padding-left-1 padding-right-1 margin-top-1'
                                noMargin={false}
                                displayIf={this.isDisplayed('ELECMEC')}
                            >
                                <ElecmecEquipmentPanel {...paramsTreatment} />
                            </Row>
                        </Row>
                    </Card>
                    <Card title={ i18n.communicationElectricity } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ELEC_COM') }>
                        <InstallationComElecPanel { ...params } />
                    </Card>
                    <Card title={ i18n.accessSite } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ACCESS_SITE') }>
                        <InstallationAccessSitePanel { ...params } />
                    </Card>
                    <Card title={ i18n.accessCasing } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ACCESS_CASING') }>
                        <InstallationAccessCasingPanel { ...params } />
                    </Card>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('STAFF_SAFETY') }>
                        <InstallationStaffSafetyPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('CONTRIBUTORS') }>
                        <StationPiezoContributorPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('USAGES') }>
                        <StationUsagePanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('ARRANGEMENTS') }>
                        <StationArrangementPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={accountHabilitations.some((h) => h.habilitation === AGRI) && this.isDisplayed('SAMPLERS')}>
                        <StationSamplersPanel {...params}/>
                    </Row>
                </div>
                <div className='col s3' style={ { marginBottom: '100px' } }>
                    <StationUpdatePanel station={ installation } />
                    <Card noMargin={ false } className='margin-top-1 padding-bottom-1'>
                        <Row className={ `${!readMode && 'padding-top-1' || ''}` }>
                            <Input
                                col={ 12 }
                                title={ i18n.dataOrigin }
                                value={ installation.dataOrigin }
                                onChange={ v => {
                                    this.onChangeInstallation({ dataOrigin: v })
                                    this.onChangeVisit({ previousValue: installation.dataOrigin, newValue: v, field: i18n.dataOrigin })
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.status }
                                value={ installation.status }
                                onChange={ v => {
                                    this.onChangeInstallation({
                                        status: v,
                                        statusLogin: getLogin(),
                                        statusDate: moment().valueOf(),
                                    })
                                    this.onChangeVisit(getVisitSelectChange(this.props.status, 'code', i18n.status, installation.status, v))
                                } }
                                options={ this.props.status }
                                keyValue='code'
                                integerValue
                                { ...mode }
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.statusDate) }
                                title={ i18n.controlDoneAt }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ statusDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.controlDoneAt, installation.statusDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.controlDoneBy }
                                value={ installation.statusLogin }
                                keyValue='name'
                                onChange={ v => {
                                    this.onChangeInstallation({ statusLogin: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.users.map(u => ({ code: u.login, name: u.name || u.login })), 'name', i18n.controlDoneBy, installation.statusLogin, v))
                                } }
                                options={ this.props.users.map(u => ({ code: u.login, name: u.name || u.login })) } { ...mode }
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.endDate) }
                                title={ i18n.deliberationDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ endDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.deliberationDate, installation.endDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } value={ installation.closeComment } title={ i18n.become }
                                onChange={ v => {
                                    this.onChangeInstallation({ closeComment: v })
                                    this.onChangeVisit({ previousValue: installation.closeComment, newValue: v, field: i18n.become })
                                } } { ...mode }
                            />
                        </Row>
                        <Row>
                            <Checkbox col={ 12 } label={ i18n.confidential } checked={ installation.confidential === '1' }
                                onChange={ v => {
                                    this.onChangeInstallation({ confidential: v ? '1' : '0' })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.confidential, installation.confidential === '1', v))
                                } } disabled={ this.state.readMode }
                            />
                        </Row>
                    </Card>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={ i18n.contact }
                            contactCode={ installation.ownerCode }
                            onChange={ v => {
                                this.onChangeInstallation({ ownerCode: v })
                                this.onChangeVisit(getVisitSelectChange(this.props.contacts, 'code', i18n.contact, installation.ownerCode, v))
                            } }
                            readMode={ readMode }
                        />
                    </Row>
                    <LastEventPanel id={ installation.id } events={ this.props.installationEvents } stationId={ installation.id }
                        stationType='installation'
                    />
                    <Card
                        title={ i18n.map }
                        noMargin={ false }
                        className='margin-top-1'
                        actions={
                            !this.state.readMode ?
                                [{
                                    iconName: 'my_location',
                                    onClick: () => this.setState({ localizeMap: true }),
                                }] : []
                        }
                    >
                        <StationMapDashboardPanel station={ this.props.installation } type={ 'installation' } />
                        <LocalisationMapModal
                            onChangeInstallation={this.onChangeInstallation}
                            installation={installation}
                            readMode={readMode}
                            open={localizeMap}
                            onClose={ () => this.setState({ localizeMap: false }) }
                        />
                    </Card>
                    <Card title={ i18n.localisation } noMargin={ false } className='margin-top-1'>
                        <StationLocationInfoPanel
                            onChange={ this.onChangeInstallation }
                            onChangeVisit={ this.onChangeVisit }
                            station={ installation }
                            readMode={ readMode }
                        />
                    </Card>
                    <LinkedStationsPanel
                        noMargin={ false }
                        className='margin-top-1'
                        onReMount={ this.props.onReMount }
                        station={ installation }
                        { ...mode }
                    />
                </div>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

TreatmentStationDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationTreatment: store.InstallationReducer.installationTreatment,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationTreatment: InstallationAction.updateInstallationTreatment,
    fetchInstallationTreatment: InstallationAction.fetchInstallationTreatment,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentStationDescriptionPanel)
