import Card from 'components/card/Card'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import AnalysisAction from 'quality/actions/AnalysisAction'
import OperationAction from 'quality/actions/OperationAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import { H_CAMPAIGN_QUALITO_TRACKING } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CampaignTrackingOperation from '../../../campaign/components/campaignTracking/CampaignTrackingOperation'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'

const QualityCampaignTrackingApp = ({
    match,
}) => {
    const dispatch = useDispatch()

    const {
        campaign,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
    }), shallowEqual)

    useEffect(() => {
        if (!componentHasHabilitations(H_CAMPAIGN_QUALITO_TRACKING)) {
            dispatch(push('/unauthorized'))
        }
    }, [])

    const [operations, setOperations] = useState([])
    const [analysis, setAnalysis] = useState([])

    useTitle(() => [
        {
            title: i18n.quality,
            href: '/quality',
        },
        {
            title: i18n.campaigns,
            href: '/quality/campaign',
        },
        {
            title: campaign.id + (campaign.name && ` - ${campaign.name}` || ''),
            href: `/quality/campaign/${campaign.id}/dashboard`,
        },
        {
            title: i18n.tracking,
            href: `/quality/campaign/${campaign.id}/tracking`,
        },
    ], [campaign])

    const {
        isLoaded,
    } = useProgressDispatch(() => {
        const id = parseInt(match.params.id)
        return [
            dispatch(CampaignAction.fetchCampaignStation('quality', id)),
            OperationAction.getQualityCampaignOperation(id).then(listOpe => setOperations(listOpe.map(o => new DtoOperation(o)))),
            AnalysisAction.getAnalysis({ campaign: id, lightMode: true }).then(listAna => setAnalysis(listAna.map(a => new DtoAnalysisLight(a)))),
        ]
    })

    return (
        <>
            {
                !isLoaded && (
                    <div style={{ margin: '10' }}>
                        <Card cardStyle={{ padding: '10' }}>
                            <ProgressBar withMessage indeterminate />
                        </Card>
                    </div>
                )
            }
            {
                isLoaded && (
                    <CampaignTrackingOperation
                        operations={operations}
                        analysis={analysis}
                    />
                )
            }
        </>
    )
}

QualityCampaignTrackingApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default QualityCampaignTrackingApp
