import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { EVENTS } from 'alerting/constants/AlertConstants'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import Icon from 'components/icon/Icon'
import GlobalCardList from 'components/list/cardList/GlobalCardList'
import { WhiteCard } from 'components/styled/Card'
import EventsAction from 'events/actions/EventsAction'
import { EVENT_TYPES } from 'events/constants/EventsConstants'
import { groupBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import EventCard from 'station/components/event/EventCard'
import { getEventColor } from 'utils/ColorUtil'
import { getDate, getDateWithHour, getHour, getYearOrString } from 'utils/DateUtil'
import { onChangeDate, onChangeHour } from 'utils/FormUtils'
import { getLabel } from 'utils/StoreUtils'

const STEP_TYPE = 'STEP_TYPE'
const STEP_FORM = 'STEP_FORM'

const EventsTab = ({
    events = [],
    station = {},
    onReload = () => { },
    showTitle = true,
}) => {
    const dispatch = useDispatch()

    const {
        accountUser,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
    }))

    const [eventStep, setEventStep] = useState(STEP_TYPE)
    const [isOpen, setIsOpen] = useState(false)
    const [event, setEvent] = useState({
        eventDate: moment().valueOf(),
        date: moment().valueOf(),
        endDate: moment().valueOf(),
        eventHour: moment().valueOf(),
        eventType: 'C',
        graph: '1',
        chgtpiles: '0',
        bankExport: '0',
        razstation: '0',
        contactCode: accountUser.contactCode,
    })


    const eventsDesc = events.sort((a, b) => b.date - a.date)
    const groups = groupBy(eventsDesc, e => getYearOrString(getDate(e.date), i18n.thisYear))
    const eventData = Object.keys(groups).map(key => ({
        title: key,
        cards: groups[key].map(e => ({
            content: (
                <EventCard
                    event={e}
                    link={`/station/${station.typeName}/${station.id}/event/`}
                    stationType={station.typeName}
                />
            ),
            color: getEventColor(e.eventType),
        })),
    }))
    const reset = () => {
        setEventStep(STEP_TYPE)
        setIsOpen(false)
        setEvent({
            eventDate: moment().valueOf(),
            date: moment().valueOf(),
            endDate: moment().valueOf(),
            eventHour: moment().valueOf(),
            eventType: 'C',
            graph: '1',
            chgtpiles: '0',
            bankExport: '0',
            razstation: '0',
            contactCode: accountUser.contactCode,
        })
    }
    return (
        <>
            <WhiteCard
                actions={[{ onClick: () => setIsOpen(true), iconName: 'note_add' }]}
                className='scrollable-card'
                contentClassName={'transparent'}
                title={showTitle && `${i18n.events} (${events.length} ${i18n.elements})`}
            >
                <GlobalCardList
                    data={eventData}
                    collapsible
                    smallTitle
                />
            </WhiteCard>
            <Dialog
                fullWidth
                maxWidth='lg'
                onClose={() => setIsOpen(false)}
                open={isOpen}
            >
                <DialogTitle>
                    {
                        eventStep === STEP_TYPE && i18n.SelectEventTypeToCreate
                    }
                    {
                        eventStep === STEP_FORM && (
                            <div className='row no-margin valign-wrapper'>
                                <div className='col s11'>
                                    {i18n.defineEvent}
                                </div>
                                <div className='col s1'>
                                    <Icon icon={getLabel(EVENT_TYPES, event.eventType, 'icon')} clickable={false} />
                                </div>
                            </div>
                        )
                    }
                </DialogTitle>
                <DialogContent>
                    {
                        eventStep === STEP_TYPE && (
                            <div className='padding-1'>
                                <div className='row no-margin'>
                                    {
                                        EVENT_TYPES.filter(e => ['S', 'C'].includes(e.code)).map(eventType => (
                                            <div className='row no-margin padding-bottom-1'>
                                                <Checkbox
                                                    label={
                                                        <div className='row no-margin valign-wrapper'>
                                                            <Icon icon={eventType.icon} />
                                                            <h6 className='padding-left-1'>
                                                                {i18n[eventType.label]}
                                                            </h6>
                                                        </div>
                                                    }
                                                    checked={event.eventType === eventType.code}
                                                    onChange={() => setEvent(p => ({ ...p, eventType: eventType.code }))}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        eventStep === STEP_FORM && (
                            <div className='padding-1'>
                                <div className='row no-margin'>
                                    <Input col={6} value={getDate(event.date)} title={i18n.startDate} onChange={v => onChangeDate(v, v2 => setEvent(p => ({ ...p, date: v2, startDate: v2 })))} />
                                    <Input col={6} value={getHour(event.eventHour)} title={i18n.startHour} onChange={v => onChangeHour(v, v2 => setEvent(p => ({ ...p, eventHour: v2 })))} />
                                </div>
                                {
                                    event.eventType !== 'C' && (
                                        <div className='row no-margin'>
                                            <Input col={6} value={getDate(event.endDate)} title={i18n.endDate} onChange={v => onChangeDate(v, v2 => setEvent(p => ({ ...p, endDate: getDateWithHour(v2, event.endDate).valueOf() })))} />
                                            <Input col={6} value={getHour(event.endDate)} title={i18n.endHour} onChange={v => onChangeHour(v, v2 => setEvent(p => ({ ...p, endDate: getDateWithHour(event.endDate, v2).valueOf() })))} />
                                        </div>
                                    )
                                }
                                <div className='row no-margin padding-bottom-1'>
                                    <Textarea col={12} value={event.comment} title={i18n.comment} onChange={v => setEvent(p => ({ ...p, comment: v }))} />
                                </div>
                            </div>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    {
                        eventStep === STEP_FORM && (
                            <div style={{ marginRight: 'auto', marginLeft: '0' }}>
                                <Button
                                    variant='outlined'
                                    onClick={() => setEventStep(STEP_TYPE)}
                                >
                                    {i18n.previous}
                                </Button>
                            </div>
                        )
                    }
                    <Button
                        variant='outlined'
                        onClick={reset}
                    >
                        {i18n.cancel}
                    </Button>
                    {
                        eventStep === STEP_TYPE && (
                            <Button
                                variant='contained'
                                onClick={() => setEventStep(STEP_FORM)}
                            >
                                {i18n.next}
                            </Button>
                        )
                    }
                    {
                        eventStep === STEP_FORM && (
                            <Button
                                variant='contained'
                                onClick={() => dispatch(EventsAction.addEvent(station.typeName, station.id, event)).then(() => {
                                    reset()
                                    onReload(station, EVENTS)
                                })}
                            >
                                {i18n.register}
                            </Button>
                        )
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}

EventsTab.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({})),
    station: PropTypes.shape({}),
    onReload: PropTypes.func,
    showTitle: PropTypes.bool,
}

export default EventsTab