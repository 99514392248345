import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import ContributorDto from '../../referencial/components/contributor/dto/ContributorDto'
import CityDto from '../../referencial/components/city/dto/CityDto'
import SectionList from '../list/section/SectionList'
import Icon from '../icon/Icon'
import Input from '../forms/Input'
import ContactGroupDto from '../../referencial/components/contact/dto/ContactGroupDto'
import SelectionTable from 'components/datatable/SelectionTable'
import { secondaryBlue } from 'utils/constants/ColorTheme'

const SelectEmailCms = ({
    mailContacts = [],
    idContactsGroups = [],
    mailContributors = [],
    mailCities = [],
    mailReceivers = [],
    mailUdi = [],
    onChangeEmails = () => { },
    contactsGroups = [],
    contacts = [],
    contributors = [],
    udiContributors=[],
    cities = [],
    personnalizeEmail = true,
}) => {
    const getEmailPersonnalize = () => {
        if (personnalizeEmail) {
            return (
                <SectionList className='margin-bottom-1' color={secondaryBlue} collapsible title={i18n.personalizedEmail} actions={[{ iconName: 'note_add', label: i18n.add, onClick: () => onChangeEmails({ mailReceivers: [...mailReceivers, ''] }) }]}>
                    {mailReceivers.map((email, index) => (
                        <div className='col s12 no-margin valign-wrapper padding-top-1'>
                            <div className='col s1'>
                                <Icon icon='clear' onClick={() => onChangeEmails({ mailReceivers: mailReceivers.filter((_, i) => i !== index) })} />
                            </div>
                            <div className='col s11'>
                                <Input title={i18n.email} value={email} onChange={v => {
                                    const newMail = [...mailReceivers]
                                    newMail[index] = v
                                    onChangeEmails({ mailReceivers: newMail })
                                }}
                                />
                            </div>
                        </div>
                    ))}
                </SectionList>
            )
        }
        return null
    }

    return (
        <div>
            { getEmailPersonnalize() }
            {contactsGroups.length ? (
                <SectionList
                    className='margin-bottom-1'
                    color={secondaryBlue}
                    collapsible
                    title={(
                        <div className='col s12 no-margin valign-wrapper' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{i18n.contactsGroups}</span>
                            <span className='right smallSubListTitle'>{`${idContactsGroups.length} / ${contactsGroups.length}`}</span>
                        </div>
                    )}
                >
                    <SelectionTable
                        maxHeight='45vh'
                        listTitle={i18n.contactsGroups}
                        listData={contactsGroups.filter((group) => !idContactsGroups.find((code) => group.code === code))}
                        listHeaders={['name', 'numberOfMembers']}
                        selectedHeaders={['name', 'numberOfMembers']}
                        selectedData={contactsGroups.filter((group) => idContactsGroups.find((code) => group.code === code))}
                        selectedTitle={i18n.toContact}
                        onAdd={(group) => onChangeEmails({ idContactsGroups: [...idContactsGroups, group.code] })}
                        onDelete={(group) => onChangeEmails({ idContactsGroups: idContactsGroups.filter((code) => code !== group.code) })}
                        addAll={() => onChangeEmails({ idContactsGroups: contactsGroups.map((c) => c.code) })}
                        deleteAll={() => onChangeEmails({ idContactsGroups: [] })}
                    />
                </SectionList>
            ) : ''}
            {contacts.length ? (
                <SectionList
                    className='margin-bottom-1'
                    color={secondaryBlue}
                    collapsible
                    title={(
                        <div className='col s12 no-margin valign-wrapper' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{i18n.contacts}</span>
                            <span className='right smallSubListTitle'>{`${mailContacts.length} / ${contacts.length}`}</span>
                        </div>
                    )}
                >
                    <SelectionTable
                        maxHeight='45vh'
                        listTitle={i18n.contacts}
                        listData={contacts.filter((contact) => !mailContacts.find((email) => contact.email === email))}
                        listHeaders={['name', 'email']}
                        selectedHeaders={['name', 'email']}
                        selectedData={contacts.filter((contact) => mailContacts.find((email) => contact.email === email))}
                        selectedTitle={i18n.toContact}
                        onAdd={(contact) => onChangeEmails({ mailContacts: [...mailContacts, contact.email] })}
                        onDelete={(contact) => onChangeEmails({ mailContacts: mailContacts.filter((email) => email !== contact.email) })}
                        addAll={() => onChangeEmails({ mailContacts: contacts.map((c) => c.email) })}
                        deleteAll={() => onChangeEmails({ mailContacts: [] })}
                    />
                </SectionList>
            ) : ''}
            {contributors.length ? (
                <SectionList
                    className='margin-bottom-1'
                    color={secondaryBlue}
                    collapsible
                    title={(
                        <div className='col s12 no-margin valign-wrapper' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{i18n.contributors}</span>
                            <span className='right smallSubListTitle'>{`${mailContributors.length} / ${contributors.length}`}</span>
                        </div>
                    )}
                >
                    <SelectionTable
                        maxHeight='45vh'
                        listTitle={i18n.contributors}
                        listData={contributors.filter((c) => !mailContributors.find((email) => c.email === email))}
                        listHeaders={['name', 'email']}
                        selectedHeaders={['name', 'email']}
                        selectedData={contributors.filter((c) => mailContributors.find((email) => c.email === email))}
                        selectedTitle={i18n.toContact}
                        onAdd={(c) => onChangeEmails({ mailContributors: [...mailContributors, c.email] })}
                        onDelete={(c) => onChangeEmails({ mailContributors: mailContributors.filter((email) => email !== c.email) })}
                        addAll={() => onChangeEmails({ mailContributors: contributors.map((c) => c.email) })}
                        deleteAll={() => onChangeEmails({ mailContributors: [] })}
                    />
                </SectionList>
            ) : ''}
            {cities.length ? (
                <SectionList
                    className='margin-bottom-1'
                    color={secondaryBlue}
                    collapsible
                    title={(
                        <div className='col s12 no-margin valign-wrapper' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{i18n.cities}</span>
                            <span className='right smallSubListTitle'>{`${mailCities.length} / ${cities.length}`}</span>
                        </div>
                    )}
                >
                    <SelectionTable
                        maxHeight='45vh'
                        listTitle={i18n.cities}
                        listData={cities.filter((c) => !mailCities.find((email) => c.email === email))}
                        listHeaders={['name', 'email']}
                        selectedHeaders={['name', 'email']}
                        selectedData={cities.filter((c) => mailCities.find((email) => c.email === email))}
                        selectedTitle={i18n.toContact}
                        onAdd={(c) => onChangeEmails({ mailCities: [...mailCities, c.email] })}
                        onDelete={(c) => onChangeEmails({ mailCities: mailCities.filter((email) => email !== c.email) })}
                        addAll={() => onChangeEmails({ mailCities: cities.map((c) => c.email) })}
                        deleteAll={() => onChangeEmails({ mailCities: [] })}
                    />
                </SectionList>
            ) : ''}
            {udiContributors.length ? (
                <SectionList
                    className='margin-bottom-1'
                    color={secondaryBlue}
                    collapsible
                    title={(
                        <div className='col s12 no-margin valign-wrapper' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{i18n.installation}</span>
                            <span className='right smallSubListTitle'>{`${mailUdi.length} / ${udiContributors.length}`}</span>
                        </div>
                    )}
                >
                    <SelectionTable
                        maxHeight='45vh'
                        listTitle={i18n.installation}
                        listData={udiContributors.filter((c) => !mailUdi.find((email) => c.email === email))}
                        listHeaders={['name', 'email']}
                        selectedHeaders={['name', 'email']}
                        selectedData={udiContributors.filter((c) => mailUdi.find((email) => c.email === email))}
                        selectedTitle={i18n.toContact}
                        onAdd={(c) => onChangeEmails({ mailUdi: [...mailUdi, c.email] })}
                        onDelete={(c) => onChangeEmails({ mailUdi: mailUdi.filter((email) => email !== c.email) })}
                        addAll={() => onChangeEmails({ mailUdi: udiContributors.map((c) => c.email) })}
                        deleteAll={() => onChangeEmails({ mailUdi: [] })}
                    />
                </SectionList>
            ) : ''}
        </div>
    )
}

SelectEmailCms.propTypes = {
    mailContacts: PropTypes.arrayOf(PropTypes.string),
    mailContributors: PropTypes.arrayOf(PropTypes.string),
    mailCities: PropTypes.arrayOf(PropTypes.string),
    mailReceivers: PropTypes.arrayOf(PropTypes.string),
    mailUdi: PropTypes.arrayOf(PropTypes.string),
    onChangeEmails: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    udiContributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contactsGroups: PropTypes.arrayOf(PropTypes.instanceOf(ContactGroupDto)),
    idContactsGroups: PropTypes.arrayOf(PropTypes.number),
    personnalizeEmail: PropTypes.bool,
}

export default SelectEmailCms