import ProgressCard from 'components/card/ProgressCard'
import Tabs from 'components/Tabs'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import TaxonAction from 'referencial/components/taxon/actions/TaxonAction'
import i18n from 'simple-react-i18n'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import HydrobioMonitoringFilter from './HydrobioMonitoringFilter'
import HydrobioMonitoringTable from './HydrobioMonitoringTable'
import PropTypes from 'prop-types'
import { getStationTitle } from 'utils/StationUtils'
import OperationAction from 'quality/components/operation/actions/OperationAction'
import HydrobioMonitoringGraph from './HydrobioMonitoringGraph'
import { hasValue } from 'utils/NumberUtil'
import queryString from 'query-string'
import IndiceMonitoringTable from './IndiceMonitoringTable'
import QualityAction from 'quality/actions/QualityAction'

const RESULT = 'RESULT'
const GRAPH = 'GRAPH'
const INDICE = 'INDICE'

const HydrobioMonitoringApp = ({
    match,
    location,
}) => {
    const dispatch = useDispatch()
    const { idStation } = match.params
    const query = queryString.parse(location.search)

    const {
        hydrobioOperations,
        hydrobioLists,
        qualitometer,
        searchIndices,
    } = useSelector(store => ({
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        hydrobioLists: store.OperationReducer.hydrobioLists,
        qualitometer: store.QualityReducer.qualitometer,
        searchIndices: store.QualityReducer.searchIndices,
    }))

    const [filter, setFilter] = useState({
        startDate: parseInt(query.startDate) || undefined,
        endDate: parseInt(query.endDate) || undefined,
    }) // update
    /*
    filter = {
        startDate, // number
        endDate, // number
        taxons, // array[string]
        selection, // number
        selectionResults, // array of string
        threshold, // number

        producers, // array[number]
        samplers, // array[number]
        determiners, // array[number]
        point,
        support, // string
    }
    */

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: getStationTitle(qualitometer),
        href: `station/quality/${idStation}`,
    }, {
        title: i18n.hydrobioMonitoring,
        href: `station/quality/${idStation}/hydrobioMonitoring`,
    }], [qualitometer])

    const { progress, isLoaded } = useProgressDispatch(() => {
        return [
            dispatch(ContributorAction.fetchContributors()),
            dispatch(TaxonAction.fetchTaxons()),
            dispatch(SupportAction.fetchSupports()),
            dispatch(OperationAction.fetchRemarks()),
            dispatch(OperationAction.fetchQualitometerHydrobioOperations(idStation)),
            dispatch(OperationAction.fetchAllHydrobioList(idStation)),
        ]
    })

    useEffect(() => {
        if (qualitometer?.code) {
            dispatch(QualityAction.fetchSearchIndices({ stations: [qualitometer.code] }))
        }
    }, [dispatch, qualitometer.code])

    const hydrobioListsFormated = useMemo(() => {
        return hydrobioLists.map(list => {
            const operation = hydrobioOperations.find(o => o.id === list.idOperation)
            const { enumerationA = 0, enumerationB = 0, enumerationC = 0, enumerationC2 = 0 } = list
            return {
                ...list,
                date: operation?.date,
                support: hasValue(operation?.support) ? `${operation?.support}` : undefined,
                total: enumerationA + enumerationB + enumerationC + enumerationC2,
                // startDate: operation?.startDate,
                // endDate: operation?.endDate,
            }
        })
    }, [hydrobioLists, hydrobioOperations])

    const hydrobioOperationsFiltered = useMemo(() => {
        const startDateFilter = filter.startDate ? hydrobioOperations.filter(o => o.date >= filter.startDate) : hydrobioOperations
        const endDateFilter = filter.endDate ? startDateFilter.filter(o => o.date <= filter.endDate) : startDateFilter
        const supportFilter = filter.support ? endDateFilter.filter(o => `${o.support}` === filter.support) : endDateFilter

        const producerFilter = filter.producers ? supportFilter.filter(o => filter.producers.includes(o.producer)) : supportFilter
        const samplerFilter = filter.samplers ? producerFilter.filter(o => filter.samplers.includes(o.sampler)) : producerFilter
        return filter.determiners ? samplerFilter.filter(o => filter.determiners.includes(o.determiner)) : samplerFilter
    }, [filter.determiners, filter.endDate, filter.producers, filter.samplers, filter.startDate, filter.support, hydrobioOperations])

    const hydrobioListsFiltered = useMemo(() => {
        const operationFilter = hydrobioListsFormated.filter(l => hydrobioOperationsFiltered.some(o => o.id === l.idOperation))

        return filter.taxons?.length ? operationFilter.filter(l => filter.taxons.includes(l.taxonCode)) : operationFilter
    }, [filter.taxons, hydrobioListsFormated, hydrobioOperationsFiltered])

    const filteredIndices = useMemo(() => {
        return searchIndices.filter(i => hydrobioOperationsFiltered.some(o => o.id === i.idOperation && o.qualitometer === i.stationId))
    }, [hydrobioOperationsFiltered, searchIndices])

    return (
        <div style={{ padding: '0 10 0 20' }}>
            {
                !isLoaded && <ProgressCard progress={progress} />
            }
            {
                isLoaded && (
                    <div className='row no-margin'>
                        <HydrobioMonitoringFilter
                            onValidate={setFilter}
                            defaultFilter={filter}
                        />
                        <Tabs
                            defaultTab={RESULT}
                            tabs={[
                                {
                                    constant: RESULT,
                                    label: i18n.result,
                                },
                                {
                                    constant: GRAPH,
                                    label: i18n.graph,
                                },
                                {
                                    constant: INDICE,
                                    label: i18n.indice,
                                },
                            ]}
                        >
                            {
                                tab => (
                                    <>
                                        {
                                            tab === RESULT && (
                                                <HydrobioMonitoringTable
                                                    hydrobioLists={hydrobioListsFiltered}
                                                    hydrobioOperations={hydrobioOperationsFiltered}
                                                />
                                            )
                                        }
                                        {
                                            tab === GRAPH && (
                                                <HydrobioMonitoringGraph
                                                    hydrobioLists={hydrobioListsFiltered}
                                                    hydrobioOperations={hydrobioOperationsFiltered}
                                                />
                                            )
                                        }
                                        {
                                            tab === INDICE && (
                                                <IndiceMonitoringTable
                                                    indices={filteredIndices}
                                                    operations={hydrobioOperationsFiltered}
                                                />
                                            )
                                        }
                                    </>
                                )
                            }
                        </Tabs>
                    </div>
                )
            }
        </div>
    )
}

HydrobioMonitoringApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            idStation: PropTypes.string,
        }),
    }),
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
}

export default HydrobioMonitoringApp