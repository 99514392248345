import {
    RECEIVE_ALL_EQUIPMENT,
    RECEIVE_ALL_EQUIPMENT_SITUATIONS,
    RECEIVE_EQUIPMENT,
    RECEIVE_EQUIPMENT_FILES,
    RECEIVE_EQUIPMENT_PICTURES,
    RECEIVE_EQUIPMENT_TYPES,
    RECEIVE_EQUIPMENTS_LAST_SITUATIONS,
    RESET_EQUIPMENT,
} from '../constants/EquipmentConstants'
import { hasValue } from '../../../../utils/NumberUtil'
import { push } from 'connected-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import i18n from 'simple-react-i18n'
import ApplicationConf from 'conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import CentralAction from '../../central/actions/CentralAction'
import PowerSupplyAction from '../../powerSupply/actions/PowerSupplyAction'
import SensorAction from '../../sensor/actions/SensorAction'
import SimAction from '../../sim/actions/SimAction'
import TelecomAction from '../../telecom/actions/TelecomAction'
import VariousMaterielAction from '../../variousMateriel/actions/VariousMaterielAction'
import { setMessageModal } from '../../../../utils/FormUtils'
import LogAction from '../../../../log/actions/LogAction'

const EquipmentAction = {
    receiveEquipments(equipments) {
        return { type: RECEIVE_ALL_EQUIPMENT, equipments }
    },

    promiseEquipments() {
        return fetch(ApplicationConf.materiel.equipments(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchEquipments() {
        return (dispatch) => {
            return EquipmentAction.promiseEquipments()
                .then((json = []) => {
                    dispatch(EquipmentAction.receiveEquipments(json))
                    dispatch(EquipmentAction.fetchEquipmentsLastSituations())
                })
        }
    },

    receiveEquipment(equipment) {
        return { type: RECEIVE_EQUIPMENT, equipment }
    },

    promiseEquipment(id) {
        return fetch(ApplicationConf.materiel.equipment(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchEquipment: id => dispatch => {
        return EquipmentAction.promiseEquipment(id)
            .then((json = {}) => {
                dispatch(EquipmentAction.receiveEquipment(json.equipment))
                return json
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.equipment} : ${e}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.equipment))
            })
    },

    receiveEquipmentTypes(equipmentTypes) {
        return { type: RECEIVE_EQUIPMENT_TYPES, equipmentTypes }
    },

    promiseEquipmentTypes() {
        return fetch(ApplicationConf.materiel.equipmentTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchEquipmentTypes() {
        return (dispatch) => {
            return EquipmentAction.promiseEquipmentTypes()
                .then((json = {}) => {
                    dispatch(EquipmentAction.receiveEquipmentTypes(json))
                })
        }
    },
    updateEquipmentTypes(id, materielType) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipmentType(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(EquipmentAction.fetchEquipmentTypes())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielType))
                })
        }
    },
    deleteEquipmentType(id, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipmentType(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(r => checkAuth(r, {
                    206: () => setMessageModal(i18n.deletingUsedMaterielTypeMessage),
                }))
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(EquipmentAction.fetchEquipmentTypes())
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.materielType))
                })
        }
    },
    addEquipmentTypes(materielType, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipmentTypes(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert > 0 && json.id) {
                        dispatch(EquipmentAction.fetchEquipmentTypes())
                        dispatch(ToastrAction.success(i18n.addSuccess))
                        callback({ ...materielType, materielType: json.id })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.materielType))
                })
        }
    },

    updateEquipment(materiels) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.materiel.equipment(materiels.equipment.equipmentId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materiels),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        if (materiels.equipment.centralIntegrated) {
                            dispatch(CentralAction.fetchCentrals(true))
                        }
                        if (materiels.equipment.sensorIntegrated) {
                            dispatch(SensorAction.fetchSensors(true))
                        }
                        if (materiels.equipment.powerSupplyIntegrated) {
                            dispatch(PowerSupplyAction.fetchPowerSupplies(true))
                        }
                        if (materiels.equipment.simIntegrated) {
                            dispatch(SimAction.fetchSims(true))
                        }
                        if (materiels.equipment.telecomIntegrated) {
                            dispatch(TelecomAction.fetchTelecoms(true))
                        }
                        if (materiels.equipment.variousMaterielIntegrated) {
                            dispatch(VariousMaterielAction.fetchVariousMateriels(true))
                        }
                        dispatch(EquipmentAction.fetchEquipments())
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        dispatch(EquipmentAction.fetchEquipment(materiels.equipment.equipmentId))
                        dispatch(WaitAction.waitStop())
                        return true
                    }
                    dispatch(WaitAction.waitStop())
                    throw new Error(json)
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.equipment)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    createEquipment(materiels) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.materiel.equipments(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materiels),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && hasValue(json.id)) {
                        if (materiels.equipment.centralIntegrated) {
                            dispatch(CentralAction.fetchCentrals(true))
                        }
                        if (materiels.equipment.sensorIntegrated) {
                            dispatch(SensorAction.fetchSensors(true))
                        }
                        if (materiels.equipment.powerSupplyIntegrated) {
                            dispatch(PowerSupplyAction.fetchPowerSupplies(true))
                        }
                        if (materiels.equipment.simIntegrated) {
                            dispatch(SimAction.fetchSims(true))
                        }
                        if (materiels.equipment.telecomIntegrated) {
                            dispatch(TelecomAction.fetchTelecoms(true))
                        }
                        if (materiels.equipment.variousMaterielIntegrated) {
                            dispatch(VariousMaterielAction.fetchVariousMateriels())
                        }
                        dispatch(EquipmentAction.fetchEquipments())
                        dispatch(push(`/materiel/equipment/${json.id}`))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        dispatch(WaitAction.waitStop())
                        return json.id
                    }
                    dispatch(WaitAction.waitStop())
                    throw new Error(json)
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.equipment)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    duplicateEquipment: (id, nbEquipment) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.materiel.duplicateEquipment(id, nbEquipment), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({}),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.insert > 0) {
                    dispatch(CentralAction.fetchCentrals(true))
                    dispatch(SensorAction.fetchSensors(true))
                    dispatch(PowerSupplyAction.fetchPowerSupplies(true))
                    dispatch(SimAction.fetchSims(true))
                    dispatch(TelecomAction.fetchTelecoms(true))
                    dispatch(VariousMaterielAction.fetchVariousMateriels())
                    dispatch(EquipmentAction.fetchEquipments())
                    dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                    dispatch(WaitAction.waitStop())
                } else {
                    throw new Error(json)
                }
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.duplicate} ${i18n.equipment} ${err}`))
                dispatch(ToastrAction.error(`${i18n.duplicate} ${i18n.equipment}`))
            })
    },

    deleteEquipment(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipment(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(CentralAction.fetchCentrals(true))
                    dispatch(SensorAction.fetchSensors(true))
                    dispatch(PowerSupplyAction.fetchPowerSupplies(true))
                    dispatch(SimAction.fetchSims(true))
                    dispatch(TelecomAction.fetchTelecoms(true))
                    dispatch(VariousMaterielAction.fetchVariousMateriels())
                    dispatch(EquipmentAction.fetchEquipments())
                    dispatch(push('/materiel/equipment'))
                    dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.equipment} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.equipment))
                })
        }
    },

    receiveEquipmentSituations(equipmentSituations) {
        return { type: RECEIVE_ALL_EQUIPMENT_SITUATIONS, equipmentSituations }
    },

    fetchEquipmentSituations(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipmentSituations(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(EquipmentAction.receiveEquipmentSituations(json))
                })
        }
    },

    saveEquipmentSituation(situation, shouldFetch = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.createEquipmentSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(EquipmentAction.fetchEquipmentsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationAddSuccess))
                    if (shouldFetch) {
                        dispatch(EquipmentAction.fetchEquipmentSituations(situation.idEquipment))
                    }
                })
        }
    },

    deleteEquipmentSituation(id, idEquipment) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipmentSituation(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(() => {
                    dispatch(EquipmentAction.fetchEquipmentsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationDeleteSuccess))
                    dispatch(EquipmentAction.fetchEquipmentSituations(idEquipment))
                })
        }
    },

    updateEquipmentSituation(idSituation, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipmentSituation(idSituation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(EquipmentAction.fetchEquipmentsLastSituations())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                    if (openCampaginModal) {
                        openCampaginModal(situation)
                    }
                })
        }
    },

    createEventAndUpdateSituation(urlEvent, event, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(urlEvent, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            }).then(getJson)
                .then((json) => {
                    if (json.eventId) {
                        const result = {
                            ...situation,
                            eventCode: json.eventId,
                        }
                        dispatch(EquipmentAction.updateEquipmentSituation(result.id, result))
                        if (openCampaginModal) {
                            dispatch(openCampaginModal(result))
                        }
                    } else {
                        dispatch(ToastrAction.error(i18n.saveError))
                    }
                })
        }
    },

    receiveEquipmentLastSituations(equipmentsLastSituations) {
        return { type: RECEIVE_EQUIPMENTS_LAST_SITUATIONS, equipmentsLastSituations }
    },

    promiseEquipmentsLastSituations: () => fetch(ApplicationConf.materiel.equipmentsLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchEquipmentsLastSituations: () => dispatch => EquipmentAction.promiseEquipmentsLastSituations()
        .then((json = []) => {
            dispatch(EquipmentAction.receiveEquipmentLastSituations(json))
        }),

    receiveEquipmentPictures(equipmentPictures) {
        return { type: RECEIVE_EQUIPMENT_PICTURES, equipmentPictures }
    },

    fetchEquipmentPictures(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipmentPicture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(EquipmentAction.receiveEquipmentPictures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                })
        }
    },

    receiveEquipmentFiles(equipmentFiles) {
        return { type: RECEIVE_EQUIPMENT_FILES, equipmentFiles }
    },

    fetchEquipmentFiles(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.equipmentFile(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(EquipmentAction.receiveEquipmentFiles(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
                })
        }
    },

    resetEquipment() {
        return { type: RESET_EQUIPMENT }
    },
}

export default EquipmentAction
