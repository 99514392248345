import { goBack } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import AppStore from '../store/AppStore'
import { execByType } from './StationUtils'

const checkHabilitation = user => {
    if (user && user.login) {
        if (user.metadata === '1' || user.isAdmin === '1') {
            return {
                isEnableAccess: true,
                isEnableEdit: true,
            }
        }
        AppStore.dispatch(goBack())
        AppStore.dispatch(ToastrAction.error(i18n.AccessRightDeny))
    }
    return {}
}

const getUserBookmarksByStationType = (bookmarks, stationType) => {
    const bookmark = bookmarks && execByType(stationType, {
        piezometry: () => bookmarks.filter(bk => bk.stationType === 1),
        pluviometry: () => bookmarks.filter(bk => bk.stationType === 2),
        quality: () => bookmarks.filter(bk => bk.stationType === 3),
        hydrometry: () => bookmarks.filter(bk => bk.stationType === 4),
        productionUnit: () => bookmarks.filter(bk => bk.stationType === 5),
        distributionUnit: () => bookmarks.filter(bk => bk.stationType === 6),
        installation: () => bookmarks.filter(bk => bk.stationType === 7),
        resource: () => bookmarks.filter(bk => bk.stationType === 9),
        agriculture: () => bookmarks.filter(bk => bk.stationType === 10),
        catchment: () => bookmarks.filter(bk => bk.stationType === 11),
        default: () => [],
    })
    return bookmark && bookmark.length && bookmark.map(ub => ub.identifiant) || []
}

const getBookmarksIds = (bookmarks, stationType, codesIndex) => {
    const codes = getUserBookmarksByStationType(bookmarks, stationType)
    return (codes || []).map(code => codesIndex[code]?.id).filter(s => !!s)
}

const NUMBER = 0
const FLOAT = 1
const SANDRE = 2
const BOOLEAN = 3
const STRING = 4
const SUBREQUEST = 5
const DATE = 6

const PARAM_OPTIONS = [
    { id: NUMBER, name: 'numberValue' },
    { id: FLOAT, name: 'floatvalue' },
    { id: SANDRE, name: 'sandre' },
    { id: BOOLEAN, name: 'boolean' },
    { id: STRING, name: 'string' },
    { id: SUBREQUEST, name: 'subRequest' },
    { id: DATE, name: 'date' },
]

export {
    checkHabilitation, getUserBookmarksByStationType, getBookmarksIds, PARAM_OPTIONS,
    NUMBER, FLOAT, SANDRE, BOOLEAN, STRING, SUBREQUEST, DATE,
}
