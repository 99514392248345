import { Button, Grid, Icon } from '@mui/material'
import { styled } from '@mui/styles'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from 'connected-react-router'
import IntervenantCard from 'exploitations/components/cards/IntervenantCard'
import IntervenantModal from 'exploitations/components/modal/IntervenantModal'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoIntervenantDeclaration from 'survey/dto/DtoIntervenantDeclaration'
import { deepEqual } from 'utils/FormUtils'
import { formatSiret } from 'utils/StringUtil'

const ButtonStep = styled(Button)({
    width: '100%',
})

class DeclarationSamplerStep extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            samplerLoaded: false,
            preleveur: new ContributorDto({}),
        }
    }

    componentDidMount() {
        this.getPreleveur()
    }

    getPreleveur = () => {
        this.setState({ samplerLoaded: false })
        const { declaration, contributors } = this.props
        this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
            if (!contributors.length) {
                this.props.fetchContributors().then(() => {
                    this.setPreleveur()
                })
            } else {
                this.setPreleveur()
            }
        })
    }

    setPreleveur = () => {
        const { contributors, exploitation, declaration, declarationContributors } = this.props
        const originalPreleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        const updatedPreleveur = declarationContributors.find(
            (updateC) =>
                originalPreleveur.id === updateC.idContributor &&
                declaration.idSurvey === updateC.surveyId &&
                updateC.contactType === 1
        )
        const preleveur = {
            ...originalPreleveur,
            ...updatedPreleveur,
        }
        this.setState({
            preleveur,
            samplerLoaded: true,
            editMode: false,
        })
    }

    toggleEdit = () => {
        const { editMode } = this.state
        this.setState({ editMode: !editMode })
    }

    onSave = (intervenant) => {
        const { declaration, exploitation, contributors } = this.props
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode)
        const originalPreleveur = new DtoIntervenantDeclaration({
            ...preleveur,
            idContributor: preleveur.id,
            surveyId: declaration.idSurvey,
        })
        const updatedPreleveur = new DtoIntervenantDeclaration({
            ...intervenant,
            idContributor: intervenant.id,
            surveyId: declaration.idSurvey,
        })
        this.setState({ openModalIntervenant: false })
        if (!deepEqual(originalPreleveur, updatedPreleveur)) {
            this.props
                .updateDeclarationContributor(
                    declaration.idSurvey,
                    new DtoIntervenantDeclaration({
                        ...intervenant,
                        idContributor: preleveur.id,
                        surveyId: declaration.idSurvey,
                        contactType: 1,
                    }),
                )
                .then(() => {
                    this.getPreleveur()
                })
        } else {
            this.getPreleveur()
        }
    }

    onCancel = () => {
        this.setState({ openModalIntervenant: false })
        this.getPreleveur()
    }

    onChangeInfo = (key, value) => {
        const { preleveur } = this.state
        const updatedPreleveur = {
            ...preleveur,
            [key]: value === '' ? undefined : value,
        }
        this.setState({ preleveur: updatedPreleveur })
    }

    getHeadInfo = () => {
        const { sandreCodes, exploitation } = this.props
        const { preleveur } = this.state
        const typesStructure = sandreCodes.filter((c) => c.field === 'INTERVENANTS.TYPE_STRUCTURE')
        if (preleveur) {
            const foundType = typesStructure.find((c) => c.code === preleveur.structureType) || {}
            return (
                <Grid item style={{ padding: '0 16px' }}>
                    <p className='bold'>
                        {`${i18n.codification} : `}
                        {exploitation.codification || ''}
                    </p>
                    <p className='bold'>
                        {`${i18n.name} : `}
                        {preleveur.internalIdentifier || preleveur.name}
                    </p>
                    <p className='bold'>
                        {`${i18n.type} : `}
                        {foundType.name}
                    </p>
                    <p className='bold'>
                        {`${i18n.siret} : `}
                        {formatSiret(preleveur.siret)}
                    </p>
                </Grid>
            )
        }
        return ''
    }

    getModalIntervenant = () => {
        const { preleveur, openModalIntervenant } = this.state
        return openModalIntervenant && (
            <IntervenantModal
                intervenant={preleveur}
                open={openModalIntervenant}
                onCancel={this.onCancel}
                onValidate={this.onSave}
                declarationMode
            />
        )
    }

    render() {
        const { preleveur, samplerLoaded } = this.state
        const { dataLoaded, readMode } = this.props
        return (
            <Grid
                item
                container
                direction='column'
                justifyContent='space-between'
                alignItems='stretch'
            >
                <Grid item container direction='column' alignItems='center' style={{ overflowY: 'auto' }}>
                    {samplerLoaded && dataLoaded ? (
                        <>
                            {this.getHeadInfo()}
                            <Grid item container justifyContent='center'>
                                <Grid item xs={4}>
                                    <IntervenantCard
                                        intervenant={preleveur}
                                        title={i18n.sampler}
                                        actions={readMode ? [] : [{ onClick: () => this.setState({ openModalIntervenant: true }), iconName: 'edit' }]}
                                        contributor
                                        round
                                    />
                                </Grid>
                            </Grid>
                            {this.getModalIntervenant()}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <ProgressBar indeterminate />
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        height: 60,
                        backgroundColor: 'white',
                        borderTop: '1px solid grey',
                        width: 'calc(100% - 60px)',
                    }}
                >
                    <Grid item xs={2}>
                        <ButtonStep variant='contained' color='primary' onClick={this.props.previousStep}>
                            <Icon>keyboard_arrow_left</Icon>
                        </ButtonStep>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={2}>
                        <ButtonStep variant='contained' color='primary' onClick={this.props.nextStep}>
                            <Icon>keyboard_arrow_right</Icon>
                        </ButtonStep>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

DeclarationSamplerStep.propTypes = {
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    onChangeDeclaration: PropTypes.func.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    fetchContributors: PropTypes.func,
    updateDeclarationContributor: PropTypes.func,
    fetchDeclarationContributors: PropTypes.func,
    previousStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    dataLoaded: PropTypes.bool,
    readMode: PropTypes.bool,
    push: PropTypes.func,
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declarationContributors: store.AgriReducer.declarationContributors,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

const mapDispatchToProps = {
    updateDeclarationContributor: AgriAction.updateDeclarationContributor,
    fetchDeclarationContributors: AgriAction.fetchDeclarationContributors,
    fetchContributors: ContributorAction.fetchContributors,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationSamplerStep)
