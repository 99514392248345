import { isEqual, maxBy, omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import WaitAction from 'wait/WaitAction'
import MaterielAction from '../../../../../materiel/actions/MaterielAction'
import TelecomAction from '../../../../../materiel/components/telecom/actions/TelecomAction'
import TelecomPanel from '../../../../../materiel/components/telecom/components/TelecomPanel'
import DtoTelecomSituation from '../../../../../materiel/components/telecom/dto/DtoTelecomSituation'
import TelecomDto from '../../../../../materiel/components/telecom/dto/TelecomDto'
import TelecomTypeDto from '../../../../../materiel/components/telecom/dto/TelecomTypeDto'
import DtoMaterielState from '../../../../../materiel/dto/DtoMaterielState'
import { getTelecomSetting } from '../../../../../materiel/utils/MaterielUtils'
import StationAction from '../../../../actions/StationAction'
import DtoStation from '../../../../dto/DtoStation'
import DtoStationTelecomAssignment from '../../../../dto/materiel/DtoStationTelecomAssignment'
import SituationDetailPanel from '../SituationDetailPanel'

class TelecomSituationDetail extends Component {
    state = {
        telecom: {},
        telecomDefaultParam: {},
        previousTelecomSetting: {},
    }

    componentDidMount() {
        const {
            stationTelecomAssignments,
            materielStates,
            telecoms,
            telecomTypes,
        } = this.props
        if (!materielStates.length) {
            this.props.fetchMaterielStates()
        }
        if (!telecoms.length) {
            this.props.fetchTelecoms()
        }
        if (!telecomTypes.length) {
            this.props.fetchTelecomTypes()
        }
        this.fetchPreviousTelecomSetting(stationTelecomAssignments)
    }

    componentDidUpdate(prevProps) {
        const { stationTelecomAssignments } = this.props
        if (stationTelecomAssignments.length !== prevProps.stationTelecomAssignments.length) {
            this.fetchPreviousTelecomSetting(stationTelecomAssignments)
        }
    }

    fetchPreviousTelecomSetting = stationTelecomAssignments => {
        const lastSituation = maxBy(stationTelecomAssignments, 'situationDate')
        if (lastSituation) {
            const lastMaterielId = lastSituation.idTelecom
            this.props.fetchTelecom(lastMaterielId).then(json =>
                this.setState({ previousTelecomSetting: getTelecomSetting(json) }))
        }
    }

    getTelecomTitle = ({ imei, serialNumber, reference }, { label }) => {
        const code = imei || serialNumber || reference || ''
        return label ? `${label} - ${code}` : code || i18n.unknownMaterial
    }

    setTelecomTypeParam = () => {
        const { telecomType } = this.state.telecom
        const type = this.props.telecomTypes.find(t => t.id === telecomType) || {}
        this.setState({
            telecom: {
                ...this.state.telecom,
                ...omit(type, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers']),
            },
        })
    }

    getTelecomPanel = isEditMode => {
        const { telecom } = this.state
        if (telecom.id) {
            const telecomType = this.props.telecomTypes.find(t => t.id === telecom.telecomType) || {}
            return (
                <div>
                    <div className='col s8 offset-s2'>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={() => this.setState({
                                telecom: {
                                    ...this.state.telecomDefaultParam,
                                },
                            })}
                            >
                                {i18n.defaultSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={this.setTelecomTypeParam}>
                                {i18n.typeSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className={`col s12 btn ${this.props.stationTelecomAssignments.length ? '' : 'disabled'}`} onClick={() => this.setState({
                                telecom: {
                                    ...telecom,
                                    ...this.state.previousTelecomSetting,
                                },
                            })}
                            >
                                {i18n.previousSettings}
                            </a>
                        </div>
                    </div>
                    <div className='row col s10 offset-s1'>
                        <div className='card no-padding'>
                            <div className='col s12 card-title active'>
                                {this.getTelecomTitle(telecom, telecomType)}
                            </div>
                            <div className={'card-content margin-top-1'}>
                                <div className='row no-margin'>
                                    <TelecomPanel id={telecom.id}
                                        telecom={telecom}
                                        onChange={changes => this.setState({ telecom: { ...telecom, ...changes } })}
                                        disabled={!isEditMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    fetchTelecom = id => {
        this.props.waitStart()
        this.props.fetchTelecom(id).then(json => {
            this.setState({ telecom: json, telecomDefaultParam: json })
            this.props.waitStop()
        })
    }

    updateTelecom = () => {
        if (!isEqual(this.state.telecom, this.state.telecomDefaultParam)) {
            this.props.updateTelecom(this.state.telecom)
        }
    }

    resetTelecom = () => {
        this.props.resetTelecom()
        this.setState({ telecom: {}, telecomDefaultParam: {} })
    }

    render = () => {
        const {
            station,
            telecoms,
            telecomsLastSituations,
            telecomTypes,
            stationTelecomAssignments,
            addTelecomSituation,
            saveTelecomSituation,
            deleteTelecomSituation,
            params,
        } = this.props
        return (
            <SituationDetailPanel
                params={params}
                getMaterielPanel={isEditMode => this.getTelecomPanel(isEditMode)}
                station={station}
                materiels={telecoms}
                lastSituations={telecomsLastSituations}
                materielTypes={telecomTypes}
                stationMaterielAssignments={stationTelecomAssignments}
                addSituation={addTelecomSituation}
                saveSituation={saveTelecomSituation}
                deleteSituation={deleteTelecomSituation}
                fetchMateriel={this.fetchTelecom}
                updateMateriel={this.updateTelecom}
                resetMateriel={this.resetTelecom}
                keyMaterielType='telecomType'
                keyMaterielId='idTelecom'
            />
        )
    }
}

TelecomSituationDetail.propTypes = {
    params: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    station: PropTypes.instanceOf(DtoStation),

    stationTelecomAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationTelecomAssignment)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    telecom: PropTypes.instanceOf(TelecomDto),
    telecoms: PropTypes.arrayOf(PropTypes.instanceOf(TelecomDto)),
    telecomsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecomSituation)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),

    resetTelecom: PropTypes.func,
    updateTelecom: PropTypes.func,
    fetchTelecom: PropTypes.func,
    fetchTelecoms: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchTelecomTypes: PropTypes.func,
    saveTelecomSituation: PropTypes.func,
    addTelecomSituation: PropTypes.func,
    deleteTelecomSituation: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
}

const mapStateToProps = store => ({
    stationTelecomAssignments: store.StationReducer.stationTelecomAssignments,
    materielStates: store.MaterielReducer.materielStates,
    telecom: store.TelecomReducer.telecom,
    telecoms: store.TelecomReducer.telecoms,
    telecomsLastSituations: store.TelecomReducer.telecomsLastSituations,
    telecomTypes: store.TelecomReducer.telecomTypes,
})

const mapDispatchToProps = {
    resetTelecom: TelecomAction.resetTelecom,
    updateTelecom: TelecomAction.updateTelecom,
    fetchTelecom: TelecomAction.fetchTelecom,
    fetchTelecoms: TelecomAction.fetchTelecoms,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchTelecomTypes: TelecomAction.fetchTelecomTypes,
    saveTelecomSituation: StationAction.saveTelecomSituation,
    addTelecomSituation: StationAction.addTelecomSituation,
    deleteTelecomSituation: StationAction.deleteTelecomSituation,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
}

export default connect(mapStateToProps, mapDispatchToProps)(TelecomSituationDetail)