import { Grid } from '@mui/material'
import CatchmentAction from 'catchment/actions/CatchmentAction'
import Card from 'components/card/Card'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getStationArrowNav } from 'utils/ActionUtils'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import CatchmentForm from './CatchmentForm'
import CatchmentPointsTable from './CatchmentPointsTable'
import CatchmentSDAGETable from './CatchmentSDAGETable'

const CatchmentDescriptionApp = ({ match }) => {
    const {
        catchmentProps,
        catchments,
    } = useSelector(store => ({
        catchmentProps: store.CatchmentReducer.catchment,
        catchments: store.CatchmentReducer.catchments,
    }), shallowEqual)

    const [catchment, setCatchment] = useState(catchmentProps)

    const dispatch = useDispatch()

    const { id } = match.params

    const [readMode, setReadMode] = useState(id !== 'new')

    useTitle(() => {
        if (id !== 'new') {
            return [{
                title: i18n.catchments,
                href: 'catchment',
            }, {
                title: catchmentProps.code || i18n.unknown,
                href: `station/catchment/${catchmentProps.id}`,
            }, {
                title: i18n.dashboard,
                href: `station/catchment/${catchmentProps.id}`,
            }]
        }
        return [{
            title: i18n.catchments,
            href: 'catchment',
        }, {
            title: i18n.new,
            href: 'station/catchment/new',
        }]
    }, [catchmentProps])

    useUpdateEffect(() => {
        setCatchment(catchmentProps)
    }, [catchmentProps])

    useActions(() => {
        if (readMode) {
            return {
                edit: () => setReadMode(false),
                arrowNav: getStationArrowNav('catchment', catchments, catchmentProps.id, s => dispatch(push(`/station/catchment/${s.id}/description`))),
                delete: () => dispatch(CatchmentAction.deleteCatchment(id)).then(result => result?.delete && dispatch(push('/catchment'))),
            }
        }
        return {
            cancel: () => {
                if (id !== 'new') {
                    setReadMode(true)
                    setCatchment(catchmentProps)
                } else {
                    dispatch(push('/catchment'))
                }
            },
            save: () => {
                if (id === 'new') {
                    dispatch(CatchmentAction.insertCatchment(catchment)).then(result => {
                        if (result?.insert) {
                            dispatch(push(`/station/catchment/${result.id}/description`))
                            setReadMode(true)
                        }
                    })
                } else {
                    dispatch(CatchmentAction.updateCatchment(id, catchment)).then(result => {
                        if (result?.update) {
                            dispatch(CatchmentAction.fetchCatchment(id))
                            setReadMode(true)
                        }
                    })
                }
            },
        }
    }, [readMode, catchment])

    return (
        <Grid style={{ margin: '0 10 100 15', paddingTop: 10 }}>
            <Grid>
                <Card title={i18n.description}>
                    <CatchmentForm catchment={catchment} setCatchment={setCatchment} readMode={readMode} />
                </Card>
            </Grid>
            <Grid className='padding-top-1'>
                <CatchmentPointsTable catchmentPoints={catchment.catchmentPoints} setCatchment={setCatchment} readMode={readMode} />
            </Grid>
            <Grid className='padding-top-1'>
                <CatchmentSDAGETable sdages={catchment.sdages} setCatchment={setCatchment} readMode={readMode} />
            </Grid>
        </Grid>
    )
}

CatchmentDescriptionApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
}

export default CatchmentDescriptionApp