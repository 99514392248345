import React, { useMemo, useState } from 'react'
import Checkbox from 'components/forms/Checkbox'
import { push } from 'connected-react-router'
import HomeAction from 'home/actions/HomeAction'
import { sortBy } from 'lodash'
import { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CULTURE,
    PATH_REFERENCIAL_CULTURE_NEW,
} from '../../../../home/constants/RouteConstants'
import CulturesFamilyAction from '../../culturesFamilies/actions/CulturesFamilyAction'
import CulturesFamiliesApp from '../../culturesFamilies/components/CulturesFamiliesApp'
import CultureAction from '../actions/CultureAction'
import { CULTURES, CULTURES_RPG, CULTURES_FAMLILIES, seasonList } from '../constants/CultureConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useActions from 'utils/customHook/useActions'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import SearchTable from 'referencial/components/SearchTable'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import { hasValue } from 'utils/NumberUtil'
import CulturesRPGApp from './CulturesRPGApp'

const headersList = ['id', 'family', 'name', 'teteRotationIcon', 'legumineuseIcon', 'periodeSemence', 'referencialIcon', 'limitCropRotationsIcon', 'culturesRPG', 'statusLabel']
const headersExport = ['id', 'family', 'name', 'teteRotation', 'legumineuse', 'periodeSemence', 'referencial', 'limitCropRotations', 'culturesRPG', 'statusLabel']

const CulturesApp = () => {
    const {
        cultures,
        culturesFamilies,
    } = useSelector(store => ({
        cultures: store.CultureReducer.cultures,
        culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])
    const [culturesFormatted, setculturesFormatted] = useState([])
    const [panel, setPanel] = useState(CULTURES)

    const dispatch = useDispatch()

    useEffect(() => {
        const status = [{
            value: 1,
            label: i18n.activated,
        }, {
            value: 2,
            label: i18n.disabled,
        }]
        const newCulturesFormatted = sortBy(cultures, 'id').map((c) => {
            const periode = seasonList.find(({ value }) => value === c.periodeSemence) || {}
            const family = culturesFamilies.find(({ id }) => id === c.idFamille) || {}
            return {
                ...c,
                periodeSemence: periode.label,
                family: family.name,
                teteRotationIcon: c.teteRotation ? (<Checkbox col={ 12 } checked disabled />) : '',
                legumineuseIcon: c.legumineuse ? (<Checkbox col={ 12 } checked disabled />) : '',
                referencialIcon: c.referentiel ? (<Checkbox col={ 12 } checked disabled />) : '',
                limitCropRotationsIcon: c.limitCropRotations ? (<Checkbox col={ 12 } checked disabled />) : '',
                teteRotation: c.teteRotation ? i18n.yes : i18n.no,
                legumineuse: c.legumineuse ? i18n.yes : i18n.no,
                referencial: c.referentiel ? i18n.yes : i18n.no,
                limitCropRotations: c.limitCropRotations ? i18n.yes : i18n.no,
                culturesRPG: c.linkCulturesRPG.join(', '),
                statusLabel: hasValue(c.status) ? (status.find((s) => s.value === c.status) || {}).label : '',
                headers: headersExport,
            }
        })
        setculturesFormatted(newCulturesFormatted)
    }, [culturesFamilies])

    useEffect(() => dispatch(CulturesFamilyAction.fetchCulturesFamilies()), [cultures])

    useEffect(() => {
        dispatch(CultureAction.fetchCultures())
        dispatch(HomeAction.setHelpLink('', ''))
        $('ul.tabs').tabs()
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.cultures,
        href: PATH_REFERENCIAL_CULTURE,
    }], [])

    const onChangePanel = (newPanel) => {
        if (panel !== newPanel) {
            setPanel(newPanel)
        }
    }

    const data = useMemo(() => getExportReferencialData(culturesFormatted), [culturesFormatted])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_CULTURE_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headersList),
                        titleFile: i18n.cultures,
                    },
                },
            },
        }
    }, [exportData])

    return (
        <div className='row no-margin'>
            <div className='col-s12 margin-left-1 margin-right-1'>
                <ul className='tabs tabs-fixed-width'>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(CULTURES) }>{ i18n.cultures }</a>
                    </li>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(CULTURES_RPG) }>{ i18n.culturesRPG }</a>
                    </li>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(CULTURES_FAMLILIES) }>{ i18n.culturesFamilies }</a>
                    </li>
                </ul>
            </div>
            { panel === CULTURES && (
                <SearchTable
                    title={i18n.cultures}
                    sessionKey={REFERENCIAL_TYPE_NAME.culture}
                    data={data}
                    setExportData={setExportData}
                    headers={headersList}
                    customHeaders={{
                        teteRotationIcon: i18n.teteRotation,
                        legumineuseIcon: i18n.legumineuse,
                        referencialIcon: i18n.referencial,
                        limitCropRotationsIcon: i18n.limitCropRotations,
                    }}
                    onClick={culture => dispatch(push(`/referencial/culture/${culture.id}/dashboard`))}
                />
            )}
            { panel === CULTURES_RPG && <CulturesRPGApp /> }
            { panel === CULTURES_FAMLILIES && <CulturesFamiliesApp /> }
        </div>
    )
}

export default CulturesApp
