import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import SelectedElementsModal from 'components/modal/SelectedElementsModal'
import { push } from 'connected-react-router'
import { every, orderBy } from 'lodash'
import moment from 'moment'
import SimpleOptionsToolPanel from 'piezometry/components/validation/tools/SimpleOptionsToolPanel'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_PLUVIO_GRAPHIC } from '../../../account/constants/AccessRulesConstants'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import ProgressCard from '../../../components/card/ProgressCard'
import Checkbox from '../../../components/forms/Checkbox'
import Select from '../../../components/forms/Select'
import SieauAction from '../../../components/sieau/SieauAction'
import CollapseTopBar from '../../../components/topBar/CollapseToBar'
import HomeAction from '../../../home/actions/HomeAction'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import DtoStationType from '../../../referencial/dto/DtoStationType'
import DtoFilter from '../../../station/dto/DtoFilter'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { arrayOf, getComponentWithId } from '../../../utils/StoreUtils'
import PluviometryAction from '../../actions/PluviometryAction'
import DtoPluvioMeasures from '../../dto/measures/DtoPluvioMeasures'
import PluviometryGraphicChart from './PluviometryGraphicChart'
import { getEndDay, getStartDay } from '../../../utils/DateUtil'

class PluviometryGraphicApp extends ActionComponent {
    constructor(props) {
        super(props)

        const filter = {
            pluvioGrouped: 'SUM_DAY',
            dataType: 1,
            startDate: getStartDay(moment().valueOf()).subtract(1, 'month') - 1,
            endDate: getEndDay(moment().valueOf()).valueOf(),
            threshold: null,
            graphicType: 'bar',
            stacked: true,
        }
        this.state = {
            filter,
            tmpFilter: filter,
            selectedStations: [],
            dataLoaded: false,
            dataLoading: false,
            progress: 0,
            isOpen: false,
            previousSelectedStations: [],
            graphicTitle: '',
        }
    }

    onChangeStations = (selectedStations) => {
        this.setState({ previousSelectedStations: selectedStations })
    }

    changeFilter = changes => this.setState({ tmpFilter: { ...this.state.tmpFilter, ...changes } })

    onValidate = () => {
        this.setState({ isOpen: false, selectedStations: this.state.previousSelectedStations })
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_PLUVIO_GRAPHIC)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (!this.props.pluviometers.length) {
            this.props.fetchPluviometers().then(() => getComponentWithId('#collapse-top-bar').collapse())
        } else {
            getComponentWithId('#collapse-top-bar').collapse()
        }
        if (!this.props.dontSetTitle) {
            this.props.setTitle([{
                title: i18n.pluviometry,
                href: 'pluviometry',
            }, {
                title: i18n.graphics,
                href: 'pluviometry/graphic',
            }])
        }
        this.setActions({})
        if (!this.props.pluviometryDataTypes.length) {
            this.props.fetchPluviometryDataTypes()
        }
    }

    visualizeChart = () => {
        const { selectedStations, tmpFilter } = this.state
        const { dataType, pluvioGrouped, startDate, endDate } = tmpFilter
        if (!tmpFilter.startDate || !tmpFilter.endDate) {
            this.props.toastrError(i18n.noDateSelectedError)
        } else if (every(Object.keys(this.state.selectedStations), c => !(this.state.selectedStations[c] || this.state.selectedStations[c] === 0))) {
            this.props.toastrError(i18n.noStationSelectedError)
        } else {
            getComponentWithId('#collapse-top-bar').collapse()
            if (selectedStations.length) {
                this.setState({ dataLoading: true, dataLoaded: false, progress: 0, filter: tmpFilter })
                const inputs = selectedStations.map(s => ({
                    stationId: s.id,
                    dataType,
                    groupFunc: pluvioGrouped,
                    chartMode: true,
                    startDate,
                    endDate,
                }))
                this.props.loadPluvioChronicMeasures(inputs, p => this.setState({ progress: p })).then(() => {
                    this.setState({ dataLoaded: true })
                    if (this.oneHasNoData()) {
                        this.props.toastrWarning(i18n.noSelectedStationDataOverThisPeriod)
                    }
                })
            }
        }
    }

    oneHasNoData = () => this.props.pluvioMeasures.some(m => !m.measures.length)
    hasSomeData = () => this.props.pluvioMeasures.some(m => m.measures.length)

    getChart = () => {
        if (this.state.dataLoaded) {
            if (this.hasSomeData()) {
                return (
                    <div className='row padding-1 relative no-overflow'>
                        <div className='col s9'>
                            <Card className='padding-top-1 padding-bottom-1 padding-left-1'>
                                <PluviometryGraphicChart
                                    filter={this.state.filter}
                                    graphicOptions={this.state}
                                />
                            </Card>
                        </div>
                        <div className='col s3 no-margin'>
                            <SimpleOptionsToolPanel
                                updateState={ ch => this.setState(ch) }
                            />
                        </div>
                    </div>
                )
            }
            return (
                <Card className='padding-top-1 padding-bottom-1 padding-left-1'>
                    <h5>{i18n.noDataToDisplay}</h5>
                </Card>
            )
        }
        if (this.state.dataLoading) {
            return <ProgressCard progress={this.state.progress} />
        }
        return null
    }

    render() {
        const { tmpFilter } = this.state
        if (this.props.pluviometers.length) {
            const groups = [
                { value: 'ALL', label: i18n.allMeasures },
                { value: 'SUM_HOUR', label: i18n.hourTotal },
                { value: 'SUM_DAY', label: i18n.dailyTotal },
                { value: 'SUM_WEEK', label: i18n.weeklyTotal },
                { value: 'SUM_DECADE', label: i18n.decadeTotal },
                { value: 'SUM_MONTH', label: i18n.monthlyTotal },
                { value: 'SUM_YEAR', label: i18n.yearTotal },
            ]
            const graphicTypes = [
                { value: 'bar', label: i18n.histogram },
                { value: 'line', label: i18n.lineGraph },
            ]
            return (
                <div>
                    <div className='col s12 padding-left-1 padding-right-1'>
                        <div className='padding-left-2 padding-right-2'>
                            <CollapseTopBar>
                                <Card greyContent>
                                    <div className='row no-margin padding-top-1'>
                                        <SimpleDatePicker
                                            col={3}
                                            label={i18n.startDate}
                                            onChange={(value) => this.changeFilter({ startDate: getStartDay(value) - 1 }) }
                                            id='startDate'
                                            value={tmpFilter.startDate + 1 }
                                            max={tmpFilter.endDate}
                                        />
                                        <SimpleDatePicker
                                            col={3}
                                            label={i18n.endDate}
                                            onChange={(value) => this.changeFilter({ endDate: getEndDay(value).valueOf() })}
                                            id='endDate'
                                            value={tmpFilter.endDate}
                                            min={tmpFilter.startDate}
                                        />
                                        <div className='col s2 input-field'>
                                            <label className='sieau-input select-label' >{i18n.selectStations}</label>
                                            <i className='material-icons blue-text clickable qualityGraphicsStationIcon tooltipped' data-tooltip={i18n.stations} onClick={() => this.setState({ isOpen: true, previousSelectedStations: this.state.selectedStations })}>list</i>
                                        </div>
                                    </div>
                                    <div className='row no-margin padding-bottom-1 valign-wrapper'>
                                        <Select col={2} label={i18n.pluvioTotal} options={groups} onChange={(value) => this.changeFilter({ pluvioGrouped: value })} value={tmpFilter.pluvioGrouped} noNullValue noSort />
                                        <Select col={2} label={i18n.chronic} options={orderBy(this.props.pluviometryDataTypes, 'id')} onChange={(value) => this.changeFilter({ dataType: value })} value={tmpFilter.dataType} noSort/>
                                        <Select col={2} label={i18n.graphicType} options={graphicTypes} onChange={(value) => this.changeFilter({ graphicType: value })} value={tmpFilter.graphicType} />
                                        <Checkbox col={1} label={i18n.stackData} checked={tmpFilter.stacked} onChange={(value) => this.changeFilter({ stacked: value })} />
                                        <div className='col s5'>
                                            <a className='waves-effect waves-light btn right' onClick={this.visualizeChart}><i className='material-icons left'>search</i>{i18n.visualize}</a>
                                        </div>
                                    </div>
                                </Card>
                            </CollapseTopBar>
                        </div>
                    </div>
                    <div className='col s12'>
                        {this.getChart()}
                    </div>
                    <Dialog
                        onClose={() => this.setState({ isOpen: false, selectedStations: this.state.previousSelectedStations })}
                        fullWidth
                        maxWidth='lg'
                        scroll='body'
                        open={this.state.isOpen}
                    >
                        <DialogTitle>{i18n.selectStations}</DialogTitle>
                        <DialogContent>
                            <SelectedElementsModal
                                selectedList={this.state.previousSelectedStations}
                                onChange={this.onChangeStations}
                                list={this.props.pluviometers}
                                elementType={STATION_TYPE_NAME.pluviometry}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ isOpen: false, selectedStations: this.state.previousSelectedStations })} >
                                {i18n.close}
                            </Button>
                            <Button onClick={this.onValidate} variant='contained' color='primary'>
                                {i18n.validate}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
        return (
            <div className='padding-top-1 padding-bottom-1 padding-left-2'>
                <Card className='padding-top-1 padding-bottom-1 padding-left-2'>
                    <h5>{i18n.noPluviometers}</h5>
                </Card>
            </div>
        )
    }
}

PluviometryGraphicApp.propTypes = {
    defaultSelectedData: PropTypes.object,
    dontSetTitle: PropTypes.bool,
    getLink: PropTypes.func,
    toastrWarning: PropTypes.func,
    toastrError: PropTypes.func,
    reset: PropTypes.func,
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    stationTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationType)),
    filters: PropTypes.arrayOf(PropTypes.instanceOf(DtoFilter)),
    fetchPluviometers: PropTypes.func,
    setTitle: PropTypes.func,
    push: PropTypes.func,
    pluvioMeasures: arrayOf(DtoPluvioMeasures),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    fetchPluviometryDataTypes: PropTypes.func,
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
    stationTypes: store.ReferencialReducer.stationTypes,
    filters: store.StationReducer.filters,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
})

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
    reset: SieauAction.reset,
    setTitle: HomeAction.setTitle,
    fetchPluviometers: PluviometryAction.fetchPluviometers,
    loadPluvioChronicMeasures: PluviometryAction.loadPluvioChronicMeasures,
    toastrWarning: ToastrAction.warning,
    push,
    fetchPluviometryDataTypes: PluviometryAction.fetchPluviometryDataTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(PluviometryGraphicApp)
