import { Card } from '@mui/material'
import ActionComponent from 'components/ActionComponent'
import SimpleKeyFigurePanel from 'components/keyFigures/SimpleKeyFigurePanel'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { mainWhite } from 'utils/constants/ColorTheme'
import { getFullDateMini } from 'utils/DateUtil'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getLogin } from '../../../../utils/SettingUtils'
import AdministrationAction from '../../../actions/AdministrationAction'
import UserAction from '../actions/UserAction'
import DtoUserStatistic from '../dto/DtoUserStatistic'
import KeyFigureDto from '../dto/KeyFigureDto'
import UserDto from '../dto/UserDto'
import AccessApplicationChartPanel from './detailPanel/charts/AccessApplicationChartPanel'
import PlatformApplicationChartPanel from './detailPanel/charts/PlatformApplicationChartPanel'
import UserDetailPanel from './detailPanel/UserDetailPanel'

class UserApp extends ActionComponent {
    constructor(props) {
        super(props)
    }

    componentWillUnmount() {
        if (this.props.match.params.login === getLogin()) {
            this.props.resetProfileLoginPicture()
        } else {
            this.props.resetProfilePicture()
        }
    }

    componentWillMount() {
        this.props.setHelpLink('administration', '92')
        this.setTitle(this.props)
    }

    setTitle = (props) => {
        this.props.forceFetch('title', [
            {
                title: i18n.administration,
                href: 'administration',
            },
            {
                title: i18n.users,
                href: 'administration/user',
            },
            {
                title: props.match.params.login,
                href: `administration/user/${props.match.params.login}`,
            },
        ])
    }

    componentDidMount() {
        $('ul.tabs').tabs()
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (this.props.match.params.login !== nextProps.match.params.login) {
            this.props.fetchUser(nextProps.match.params.login)
            this.setTitle(nextProps)
        }
        if (!nextState.user) {
            this.setState({
                user: nextProps.user,
            })
        }
    }

    getUpdatePanel = () => {
        if (this.props.user.loginMaj || this.props.user.updateDate) {
            return (
                <Card
                    sx={{
                        borderRadius: '4px',
                        boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)',
                        padding: '12px',
                        backgroundColor: mainWhite,
                        marginBottom: '12px',
                    }}
                >
                    <span>{i18n.updatedAt} {getFullDateMini(this.props.user.updateDate)} {i18n.by} {this.props.user.loginMaj}</span>
                </Card>
            )
        }
        return null
    }

    checkKeyFigures = () => {
        const { userKeyFigures } = this.props
        return userKeyFigures.filter(u => u.value && u.value !== '0')?.length > 0
    }

    render() {
        return (
            <div className='row' style={{ margin: 0, marginLeft: -10 }}>
                <div className='padding-1'>
                    <div className='col s9'>
                        <UserDetailPanel view={this.props.view} userLogin={this.props.match.params.login} />
                    </div>
                    <div className='col s3'>
                        {this.getUpdatePanel()}
                        {this.checkKeyFigures() && (
                            <SimpleKeyFigurePanel
                                data={this.props.userKeyFigures}
                                singleLine
                            />
                        )}
                        <AccessApplicationChartPanel
                            userStatistics={this.props.userStatistics.filter(({ eventType }) => eventType === 'LOGIN')}
                        />
                        <PlatformApplicationChartPanel
                            userStatistics={this.props.userStatistics.filter(({ eventType }) => eventType === 'PLATFORM')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

UserApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            login: PropTypes.string,
        }),
    }),
    user: PropTypes.instanceOf(UserDto),
    userKeyFigures: PropTypes.arrayOf(PropTypes.instanceOf(KeyFigureDto)),
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
    getLink: PropTypes.func,
    fetchProfilePicture: PropTypes.func,
    fetchUser: PropTypes.func,
    forceFetch: PropTypes.func,
    resetProfilePicture: PropTypes.func,
    resetProfileLoginPicture: PropTypes.func,
    setHelpLink: PropTypes.func,
    view: PropTypes.string,
}

const mapStateToProps = (store) => {
    return {
        user: store.UserReducer.user,
        userKeyFigures: store.UserReducer.userKeyFigures,
        userStatistics: store.UserReducer.userStatistics,
    }
}

const mapDispatchToProps = {
    fetchProfilePicture: AdministrationAction.fetchProfilePicture,
    fetchUser: UserAction.fetchUser,
    forceFetch: SieauAction.forceFetch,
    resetProfileLoginPicture: AdministrationAction.resetProfileLoginPicture,
    resetProfilePicture: AdministrationAction.resetProfilePicture,
    setHelpLink: HomeAction.setHelpLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserApp)
