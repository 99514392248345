import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { PATH_REFERENCIAL_COUNTRY, PATH_REFERENCIAL_COUNTRY_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import { RECEIVE_COUNTRIES, RECEIVE_COUNTRY } from '../constants/CountryConstants'

const CountryAction = {

    receiveCountries(countries) {
        return { type: RECEIVE_COUNTRIES, countries }
    },

    promiseCountries() {
        return fetch(ApplicationConf.referencial.countries(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },

    fetchCountries() {
        return (dispatch) => {
            return CountryAction.promiseCountries()
                .then((json) => {
                    dispatch(CountryAction.receiveCountries(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.countries}`))
                })
        }
    },

    receiveCountry(country) {
        return { type: RECEIVE_COUNTRY, country }
    },

    promiseCountry(countryCode) {
        return fetch(ApplicationConf.referencial.country(countryCode), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },

    fetchCountry(countryCode) {
        return (dispatch) => {
            return CountryAction.promiseCountry(countryCode)
                .then((json) => {
                    dispatch(CountryAction.receiveCountry(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.country}`))
                })
        }
    },

    updateCountry(country) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.countries(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(country),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(CountryAction.fetchCountry(country.countryCode))
                        dispatch(ToastrAction.success(i18n.countrySuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.country} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.country))
                })
        }
    },

    createCountry(country) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.countries(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(country),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_COUNTRY_LINK + country.countryCode))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.country} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.country))
                })
        }
    },

    deleteCountry(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.country(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_COUNTRY))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.country} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.country))
                })
        }
    },
}

export default CountryAction
