import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getSandreLabel } from 'utils/StringUtil'
import ColorfulCard from '../../components/card/ColorfulCard'
import { PATH_REFERENCIAL_CONTACT_LINK } from '../../home/constants/RouteConstants'
import DtoCampaignInstallationWithStats from '../../installation/components/campaign/dto/DtoCampaignInstallationWithStats'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import { getColorCampaign } from '../../utils/CampaignUtil'
import { getDate } from '../../utils/DateUtil'
import { sieauTooltip } from '../../utils/FormUtils'
import CampaignDto from '../dto/CampaignDto'
import DtoCampaignProgress from '../dto/DtoCampaignProgress'
import { getCampaignIcon, getCampaignProgress, getProgressBar, getQualityCampaignIcon } from '../utils/CampaignUtils'

const CampaignCard = ({
    progress = {},
    campaign = {},
    contact = {},
    stationType = '',
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }))
    const dispatch = useDispatch()
    const {
        id,
        idCampaign,
        name,
        statut,
        isArchived,
        campaignType,
        beginningApplication,
        endingApplication,
        campaignStatus,
    } = campaign
    const {
        nbStation = 0,
        nbStationValidated = 0,
        startDate,
        endDate,
        progress: progressValidated,
        progressTotal,
    } = progress
    const progressValue = getCampaignProgress(progressValidated, progressTotal)
    return (
        <ColorfulCard color={getColorCampaign(isArchived, progressValue, statut)} >
            <div className={`row no-margin valign-wrapper clickable ${statut === 1 ? 'grey lighten-2' : ''}`} style={{ height: '60px' }}>
                <div className='col s11 valign-wrapper' onClick={() => dispatch(push(`/${stationType}/campaign/${id || idCampaign}`))}>
                    <div className='col s1 valign-wrapper' style={{ placeContent: 'center' }}>
                        {stationType === 'quality' ? getQualityCampaignIcon(campaignType, 'medium') : getCampaignIcon(campaignType, 'medium')}
                    </div>
                    <div className='col s5'>
                        <p className='padding-top-1 collection-title' style={{ fontWeight: 'bold' }}>{name}</p>
                        {
                            statut === 2 && (
                                <div className='padding-top-1 padding-bottom-1 collection-content'>{`${stationType === 'installation' ? i18n.nbInstallationsValidated : i18n.nbStationsValidated} : ${nbStationValidated} /${nbStation}`}</div>
                            )
                        }
                    </div>
                    <div className='col s3'>
                        <div className='padding-top-1 padding-bottom-1 collection-content'>{getSandreLabel(sandreCodes, 'CAMPAGNES.STATUT', statut || campaignStatus) ?? i18n.unknowStatus}</div>
                    </div>
                    {
                        statut === 1 && (
                            <div className='col s3 center-align'>
                                <i className='material-icons medium'>create</i>
                            </div>
                        )
                    }
                    {
                        statut !== 1 && (
                            <div className='col s3'>
                                <p>{startDate ? getDate(startDate) : beginningApplication} - {endDate ? getDate(endDate) : endingApplication}</p>
                                {
                                    statut === 2 && stationType === 'quality' && getProgressBar(progressValidated, progressTotal, progressValue, i18n.analyseOn, i18n.analysisOn)
                                }
                                {
                                    statut === 2 && stationType !== 'quality' && getProgressBar(progressValidated, progressTotal, progressValue)
                                }
                            </div>
                        )
                    }
                </div>
                <div className='col s1'>
                    <div className='clickable event-img-max-size center-align'
                        {...sieauTooltip(contact.name || i18n.unknown, null, 'bottom')}
                        onClick={() => contact.code && dispatch(push(`${PATH_REFERENCIAL_CONTACT_LINK}${contact.code}`))}
                    >
                        <i className={'material-icons medium'}>account_circle</i>
                    </div>
                </div>
            </div>
        </ColorfulCard>
    )
}

CampaignCard.propTypes = {
    campaign: PropTypes.oneOfType([
        PropTypes.instanceOf(CampaignDto),
        PropTypes.instanceOf(DtoCampaignInstallationWithStats),
    ]),
    progress: PropTypes.instanceOf(DtoCampaignProgress),
    contact: PropTypes.instanceOf(ContactDto),
    stationType: PropTypes.string,
}

export default CampaignCard
