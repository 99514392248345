'use strict'
import { H_AGRI_SURVEYS_DASHBOARD } from 'account/constants/AccessRulesConstants'
import AgriAction from 'agriAdministration/actions/AgriAction'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import Row from 'components/react/Row'
import { push } from 'connected-react-router'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoExploitationExportFull from 'exploitations/dto/DtoExploitationExportFull'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoMatEventsType from 'materiel/components/variousMateriel/dto/DtoMatEventsType'
import DtoVariousMaterielSituation from 'materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from 'materiel/components/variousMateriel/dto/VariousMaterielDto'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import CityDto from 'referencial/components/city/dto/CityDto'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedAction from 'referencial/components/watershed/actions/WatershedAction'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { arrayOf } from 'utils/StoreUtils'
import ScenarioDashboardApp from './ScenarioDashboardApp'
import CultureAction from 'referencial/components/cultures/actions/CultureAction'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import UsageAction from 'referencial/components/usage/actions/UsageAction'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import CulturesFamilyAction from 'referencial/components/culturesFamilies/actions/CulturesFamilyAction'
import ProgressCard from 'components/card/ProgressCard'

class ScenarioApp extends Component {
    state = {
        scenarioLoaded: false,
        exploitationsLoaded: false,
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_AGRI_SURVEYS_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
        const {
            match,
            exploitations,
            sandreCodes,
            contributors,
            cities,
            exploitationsExportFullData,
            variousMateriels,
            variousMaterielsLastSituations,
            variousMaterielTypes,
            watersheds,
            managementUnits,
            matEventsTypes,
            agriTanksTypes,
            culturesFamilies,
            cultures,
            usages,
            modesIrrigations,
            installationsWithGeo,
        } = this.props
        const { params } = match
        this.props.fetchScenario(params.id).then(() => {
            this.setState({ scenarioLoaded: true })
        })
        this.props.fetchRulesScenarios()
        if (!exploitations.length) {
            this.props.fetchExploitations()
        }
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!contributors.length) {
            this.props.fetchContributors()
        }
        if (!cities.length) {
            this.props.fetchCities()
        }
        if (!exploitationsExportFullData.length) {
            this.props.fetchExploitationsExportFullData().then(() => {
                this.setState({ exploitationsLoaded: true })
            })
        } else {
            this.setState({ exploitationsLoaded: true })
        }
        if (!variousMateriels.length) {
            this.props.fetchVariousMateriels()
        }
        if (!variousMaterielsLastSituations.length) {
            this.props.fetchVariousMaterielsLastSituations()
        }
        if (!variousMaterielTypes.length) {
            this.props.fetchVariousMaterielTypes()
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!managementUnits.length) {
            this.props.fetchManagementUnits()
        }
        if (!matEventsTypes.length) {
            this.props.fetchMatEventsTypes()
        }
        if (!agriTanksTypes.length) {
            this.props.fetchTanksTypes()
        }
        if (!culturesFamilies.length) {
            this.props.fetchCulturesFamilies()
        }
        if (!cultures.length) {
            this.props.fetchCultures()
        }
        if (!usages.length) {
            this.props.fetchUsages()
        }
        if (!modesIrrigations.length) {
            this.props.fetchModesIrrigations()
        }
        if (!installationsWithGeo.length) {
            this.props.fetchInstallationsWithGeo()
        }
    }

    componentWillUnmount() {
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
    }

    getLink = () => {
        const { params: { id } } = this.props.match
        return [{
            href: `/scenario/${id}`,
            icons: 'dashboard',
            name: i18n.scenario,
        }]
    }

    render() {
        const { scenarioLoaded, exploitationsLoaded } = this.state
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={this.getLink()}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                {scenarioLoaded && exploitationsLoaded ? (
                                    <Switch>
                                        <Redirect exact from='/scenario/:id' to='/scenario/:id/dashboard' />
                                        <Route path='/scenario/:id/dashboard' render={(props) => <ScenarioDashboardApp {...props} />} />
                                    </Switch>
                                ) : (
                                    <Row className='padding-1'>
                                        <ProgressCard indeterminate round />
                                    </Row>
                                )}
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

ScenarioApp.defaultProps = {
    getLink: ('', ''),
}

ScenarioApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
    children: PropTypes.element,

    exploitations: arrayOf(DtoExploitation),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(ContributorDto),
    cities: arrayOf(CityDto),
    exploitationsExportFullData: arrayOf(DtoExploitationExportFull),
    variousMateriels: arrayOf(VariousMaterielDto),
    variousMaterielsLastSituations: arrayOf(DtoVariousMaterielSituation),
    variousMaterielTypes: arrayOf(DtoVariousMaterielType),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    modesIrrigations: PropTypes.arrayOf(PropTypes.instanceOf({})),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),

    getLink: PropTypes.func,
    fetchScenario: PropTypes.func,
    fetchExploitationsExportFullData: PropTypes.func,
    fetchExploitations: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchCities: PropTypes.func,
    setFormationPath: PropTypes.func,
    setHelpLink: PropTypes.func,
    fetchVariousMateriels: PropTypes.func,
    fetchVariousMaterielsLastSituations: PropTypes.func,
    fetchVariousMaterielTypes: PropTypes.func,
    fetchWatersheds: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchMatEventsTypes: PropTypes.func,
    fetchTanksTypes: PropTypes.func,
    fetchRulesScenarios: PropTypes.func,
    fetchCultures: PropTypes.func,
    fetchCulturesFamilies: PropTypes.func,
    fetchUsages: PropTypes.func,
    fetchModesIrrigations: PropTypes.func,
    fetchInstallationsWithGeo: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
    exploitations: store.AgriReducer.exploitations,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    cities: store.CityReducer.cities,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    variousMaterielsLastSituations: store.VariousMaterielReducer.variousMaterielsLastSituations,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    watersheds: store.WatershedReducer.watersheds,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    matEventsTypes: store.VariousMaterielReducer.matEventsTypes,
    agriTanksTypes: store.AgriReducer.agriTanksTypes,
    cultures: store.CultureReducer.cultures,
    culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    usages: store.UsageReducer.usages,
    modesIrrigations: store.AgriReducer.modesIrrigations,
    installationsWithGeo: store.InstallationReducer.installationsWithGeo,
})

const mapDispatchToProps = {
    fetchVariousMateriels: VariousMaterielAction.fetchVariousMateriels,
    fetchVariousMaterielsLastSituations: VariousMaterielAction.fetchVariousMaterielsLastSituations,
    fetchMatEventsTypes: VariousMaterielAction.fetchMatEventsTypes,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    fetchScenario: AgriAction.fetchScenario,
    fetchExploitationsExportFullData: AgriAction.fetchExploitationsExportFullData,
    fetchExploitations: AgriAction.fetchExploitations,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchContributors: ContributorAction.fetchContributors,
    fetchCities: CityAction.fetchCities,
    setFormationPath: HomeAction.setFormationPath,
    setHelpLink: HomeAction.setHelpLink,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchTanksTypes: AgriAction.fetchTanksTypes,
    fetchRulesScenarios: AgriAction.fetchRulesScenarios,
    fetchCultures: CultureAction.fetchCultures,
    fetchCulturesFamilies: CulturesFamilyAction.fetchCulturesFamilies,
    fetchUsages: UsageAction.fetchUsages,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchInstallationsWithGeo: InstallationAction.fetchInstallationsWithGeo,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioApp)
