import React from 'react'
import { push } from 'connected-react-router'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import SearchTable from 'referencial/components/SearchTable'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import useTitle from 'utils/customHook/useTitle'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_COUNTRY,
    PATH_REFERENCIAL_COUNTRY_NEW,
} from '../../../../home/constants/RouteConstants'
import ReferencialAction from '../../../action/ReferencialAction'
import { REFERENCIAL_TYPE_NAME, SANDRE } from '../../../constants/ReferencialConstants'
import CountryAction from '../actions/CountryAction'

const headers = ['code', 'number', 'name', 'tel', 'level']

const CountriesApp = () => {
    const {
        countries,
        sandreCodes,
    } = useSelector(store => ({
        countries: store.CountryReducer.countries,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const countrySandreCodes = useSandreList(SANDRE.PAYS_RGPD)
    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        if (sandreCodes.length === 0) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        dispatch(CountryAction.fetchCountries())
        dispatch(HomeAction.setHelpLink('', ''))
    }, [dispatch, sandreCodes])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.country,
        href: PATH_REFERENCIAL_COUNTRY,
    }], [])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_COUNTRY_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.aquifers,
                    },
                },
            },
        }
    }, [exportData])

    const countriesItem = useMemo(() => getExportReferencialData(countries).map(a => {
        const foundRGPD = countrySandreCodes.find(c => c.code === a.rgpdLevel)
        return {
            name: a.name,
            id: a.countryCode,
            code: a.countryCode,
            number: a.countryNum,
            tel: a.telCodeIndicator,
            level: foundRGPD ? foundRGPD.name : null,
            headers,
        }
    }), [countries, countrySandreCodes])

    return (
        <SearchTable
            title={i18n.country}
            sessionKey={REFERENCIAL_TYPE_NAME.country}
            data={countriesItem}
            setExportData={setExportData}
            headers={headers}
            onClick={country => dispatch(push(`/referencial/country/${country.id}/dashboard`))}
        />
    )
}

export default CountriesApp
