import React, { Component } from 'react'
import { arrayOf } from '../../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import Checkbox from '../../../../components/forms/Checkbox'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import PropTypes from 'prop-types'
import Job from '../../../dto/DtoJob'
import { connect } from 'react-redux'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import Button from '../../../../components/forms/Button'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import SelectionTableModal from '../../../../components/modal/SelectionTableModal'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../../store/AppStore'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import PluviometryAction from '../../../../pluviometry/actions/PluviometryAction'
import Table from '../../../../components/datatable/Table'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'

class ERA5Panel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cityPopupOpen: false,
        }
    }

    componentDidMount() {
        if (!this.props.cities.length) {
            AppStore.dispatch(CityAction.fetchCities())
        }
        if (!this.props.pluviometryDataTypes.length) {
            AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return {
            cities: [],
            daily: true,
            rain: true,
            importMode: 'DATA_ADD_NEW_DATA',
            takePrincipal: true,
            ...newFilters }
    }

    render() {
        const disabled = !this.props.isEditMode
        const filters = this.getFilters()
        const era5DataTypes = ['rain', 'radiation', 'temperature', 'etp']
        const dataTypes = era5DataTypes.map(eraDt => {
            return {
                nullValue: <Checkbox checked={ filters[eraDt] } onChange={ v => this.onChangeFilters({ [eraDt]: v }) } disabled={ disabled } />,
                name: i18n[eraDt],
                dataType: <Select value={ filters[`${eraDt}Dt`] } options={ this.props.pluviometryDataTypes } onChange={ v => this.onChangeFilters({ [`${eraDt}Dt`]: v })} disabled={ disabled || !filters[eraDt] }/>,
            }
        })
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <fieldset>
                        <legend>&nbsp;{i18n.dataTypes}&nbsp;</legend>
                        <div className='row no-margin'>
                            <Row className='padding-top-1'>
                                <Table data={ dataTypes } showTitle={false} type={ { headers: ['nullValue', 'name', 'dataType'] }}
                                    condensed sortable showNbElements={ false }
                                />
                            </Row>
                        </div>
                    </fieldset>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <fieldset className='width-100'>
                        <legend>&nbsp;{ i18n.parameters }&nbsp;</legend>
                        <Row className='valign-wrapper'>
                            <Select col={ 3 } options={ getIntegrationModes() } obligatory={true} label={ i18n.dataIntegration } value={ filters.importMode } labelSpan={ integrationModeHelpIcon() }
                                onChange={ v => this.onChangeFilters({ importMode: v }) }
                                disabled={ disabled }
                            />
                            <Checkbox col={ 2 } label={ i18n.dailyTotal } onChange={ v => this.onChangeFilters({ daily: v }) } disabled checked={ filters.daily } />
                            <Checkbox col={ 2 } label={ i18n.principalCity } onChange={ v => this.onChangeFilters({ takePrincipal: v }) } disabled={ disabled } checked={ filters.takePrincipal } />
                            <SimpleDatePicker
                                label={i18n.startDate}
                                value={filters.startDate}
                                onChange={date => this.onChangeFilters({ startDate: date })}
                                col={2}
                                disabled={ disabled }
                            />
                            <SimpleDatePicker
                                label={i18n.endDate}
                                value={filters.endDate}
                                onChange={date => this.onChangeFilters({ endDate: date })}
                                col={2}
                                disabled={ disabled }
                            />
                        </Row>
                    </fieldset>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <fieldset className='width-100'>
                        <legend>&nbsp;{ i18n.cities }&nbsp;</legend>
                        <Row>
                            <Button col={ 4 } title={ `${filters.cities.length} ${i18n.cities}` } onClick={ () => this.setState({ cityPopupOpen: true })}/>
                        </Row>
                    </fieldset>
                </div>
                <SelectionTableModal
                    isOpen={this.state.cityPopupOpen}
                    onClose={() => this.setState({ cityPopupOpen: false })}
                    onValidate={list => {
                        this.onChangeFilters({ cities: list })
                        this.setState({ cityPopupOpen: false })
                    }}
                    title={i18n.cities}

                    listData={this.props.cities}
                    defaultSelectionList={filters.cities}

                    listHeaders={['code', 'name']}
                    listTitle={i18n.cities}
                    selectionListTitle={i18n.selectedElements}
                    maxHeightTable={'48vh'}

                    filterField={({
                        filter,
                        setFilter,
                    }) => (
                        <div className='row no-margin' style={{ padding: '10px 0px' }}>
                            <Input
                                col={6}
                                title={i18n.search}
                                value={filter.searchValue}
                                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                            />
                        </div>
                    )}
                    filterFunction={(list, { searchValue }) => {
                        const searchValueFormat = searchAllCharacters(searchValue)
                        return searchValue ? list.filter(p => searchAllCharacters([p.code, p.name].join('   ')).includes(searchValueFormat)) : list
                    }}
                />
            </div>
        )
    }
}

ERA5Panel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    cities: arrayOf(CityDto),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
})

export default connect(mapStateToProps)(ERA5Panel)