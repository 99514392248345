import { max, maxBy, min, minBy, sum, take, takeRight, uniq } from 'lodash'
import moment from 'moment'
import { getThresholdColorCode } from './ColorUtil'
import { hasValue } from './NumberUtil'

const ENVELOP_MEASURE = 0.8

const getUrlPicture = pictureName => `map_arrow_${pictureName}.png`

const getDirection = (lastMeasure, trend) => {
    const minTrend = Math.round(trend / ENVELOP_MEASURE)
    const maxTrend = Math.round(trend * ENVELOP_MEASURE)
    if (lastMeasure > maxTrend) {
        return 'up_'
    } else if (lastMeasure <= (maxTrend) && lastMeasure >= minTrend) {
        return 'right_'
    }
    return 'down_'
}

const getColor = trend => { // deprecated
    switch (trend) {
        case 4:
            return 'grey'
        case 3:
            return 'red'
        case 2:
            return 'orange'
        default:
            return 'green'
    }
}

const getThresholdTrend = (lastMeasure, value) => {
    return (lastMeasure >= value) ? 3 : 1
}

const getOverrunthresholdTrend = (lastMeasure, value) => {
    return (lastMeasure <= value) ? 3 : 1
}

const getTrendLevel = ({ lastMeasure, thresholds }) => {
    if (thresholds?.length) {
        const trends = uniq(thresholds.map(({ value, isOverrunThreshold }) => {
            if (hasValue(lastMeasure)) {
                if (isOverrunThreshold === '0') {
                    return getOverrunthresholdTrend(lastMeasure, value)
                }
                return getThresholdTrend(lastMeasure, value)
            }
            return 1
        }))
        return trends.includes(1) && trends.includes(3) ? 2 : trends[0]
    }
    return 1
}

const getTrendThreshold = ({ lastMeasure, thresholds }, isPiezoDepth) => {
    if (thresholds?.length && hasValue(lastMeasure)) {
        const trendThresholds = thresholds.filter(t => t.isOverrunThreshold === '1').filter(({ value }) => {
            return isPiezoDepth ? lastMeasure <= value : lastMeasure >= value
        })
        const trendOverrunThresholds = thresholds.filter(t => t.isOverrunThreshold !== '1').filter(({ value }) => {
            return isPiezoDepth ? lastMeasure >= value : lastMeasure <= value
        })
        return (isPiezoDepth ? [minBy(trendThresholds, 'value'), maxBy(trendOverrunThresholds, 'value')] : [maxBy(trendThresholds, 'value'), minBy(trendOverrunThresholds, 'value')]).filter(t => !!t)
    }
    return []
}


const getTrendThresholdColor = (thresholds, threshold, measureDate, lastMeasure, dataType) => {
    const nbHoursBefore = dataType?.alertLimit || 120
    const dateBeforeNbHours = moment(moment().subtract(nbHoursBefore, 'hour'))
    if ((measureDate && measureDate.isBefore(dateBeforeNbHours)) || !hasValue(lastMeasure) || thresholds.filter(t => !t.name && !t.title).length) {
        return '#B0B0B0'
    }
    if (!thresholds.length) {
        return '#B0B0B0'
    }
    if (threshold && (threshold.name || threshold.title)) {
        return threshold.htmlColor || getThresholdColorCode(threshold.color)
    }
    return '#008000'
}

const TrendLevelObject = (measures = [], thresholds = [], dataType) => {
    if (measures && measures.length) {
        const lastMeasure = measures[0]
        const last3Measures = takeRight(take(measures, 4), 3)
        const trend = sum(last3Measures) / last3Measures.length
        const maxValue = max(measures)
        const average = sum(measures) / measures.length
        const minValue = min(measures)
        return {
            lastMeasure,
            trend,
            average,
            thresholds,
            max: maxValue,
            min: minValue,
            dataType,
        }
    }
    return {
        lastMeasure: null,
        trend: null,
        average: null,
        thresholds: null,
        max: null,
        min: null,
        dataType: null,
    }
}

export {
    getUrlPicture,
    getDirection,
    getColor,
    TrendLevelObject,
    getTrendLevel,
    getTrendThreshold,
    getTrendThresholdColor,
}
