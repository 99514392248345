import { Grid, Dialog, Button } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import Table from 'components/datatable/Table'
import HomeAction from 'home/actions/HomeAction'
import { lowerCase, orderBy, sortBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import TerritoryWaterTurnsRestrictionsModal from './modal/TerritoryWaterTurnsRestrictionsModal'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from 'components/forms/Checkbox'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Textarea from 'components/forms/Textarea'
import { getLogin } from 'utils/SettingUtils'
import { sieauTooltip } from 'utils/FormUtils'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import { getDate } from 'utils/DateUtil'
import RestrictionsFilterPanel from './panel/RestrictionsFilterPanel'
import { SAMPLE_TYPES } from 'agriAdministration/constants/AgriConstants'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import notificationIcon from 'assets/pictures/logos/notification.png'
import RestrictionsNotifDialog from './modal/RestrictionsNotifDialog'

const headers = ['level', 'label', 'description', 'comment', 'displayedColor']
const exportHeaders = ['level', 'label', 'description', 'comment', 'color']

const emptyFilter = {
    searchValue: '',
    resourceType: undefined,
}

const TerritoryRestrictionsApp = ({}) => {
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const [openModalNotifs, setOpenModalNotifs] = useState(false)
    const [modalExemptionData, setModalExemptionData] = useState({ open: false })

    const [selectedRestriction, setSelectedRestriction] = useState()
    const [isNew, setIsNew] = useState(null)
    const [readMode, setReadMode] = useState(true)
    const [filters, setFilters] = useState(emptyFilter)
    const [filterNotifs, setFilterNotifs] = useState()

    useEffect(() => {
        dispatch(AgriAction.fetchRestrictions())
        dispatch(HomeAction.setTitle([{
            title: i18n.steering,
            href: 'territory',
        }, {
            title: i18n.restrictionsManagement,
            href: 'territor/restrictions',
        }]))
    }, [])

    const {
        restrictions,
        droughtSectorsRestrictions,
        managementUnits,
        managementUnitsRestrictions,
        sandreCodes,
    } = useSelector(store => ({
        restrictions: store.AgriReducer.restrictions,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        managementUnitsRestrictions: store.ManagementUnitReducer.managementUnitsRestrictions,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [localManagementUnitsRestrictions, setManagementUnitsRestrictions] = useState(managementUnitsRestrictions)
    const [localDroughtSectorsRestrictions, setDroughtSectorsRestrictions] = useState(droughtSectorsRestrictions)

    const typeRestriction = useApplicationSetting('agriTypeRestriction', setting => setting ? parseInt(setting) : 1)

    const sortedRestrictions = useMemo(() => {
        return sortBy(restrictions, 'level').map(d => ({
            ...d,
            displayedColor: (
                <div style={{ backgroundColor: d.color, width: '20px', border: 'solid black 1px' }}>&nbsp;</div>
            ),
        }))
    }, [restrictions])

    const onExport = () => {
        const data = sortedRestrictions.map(restriction => ({
            level: { value: restriction.level },
            label: { value: restriction.label },
            description: { value: restriction.description },
            comment: { value: restriction.comment },
            color: { value: restriction.color, color: restriction.color },
        }))
        return {
            data: data.length ? [
                { ...data[0], headers: exportHeaders },
                ...data.slice(1),
            ] : [],
            titleFile: i18n.restrictions,
        }
    }

    useEffect(() => {
        setManagementUnitsRestrictions(managementUnitsRestrictions)
    }, [managementUnitsRestrictions])

    useEffect(() => {
        setDroughtSectorsRestrictions(droughtSectorsRestrictions)
    }, [droughtSectorsRestrictions])

    const onSave = (dataUGE, dataDroughtSectors) => {
        setReadMode(true)
        dispatch(ManagementUnitAction.updateManagementUnitsRestrictions(dataUGE)).then(() => {
            dispatch(ManagementUnitAction.fetchManagementUnitsRestrictions())
        })
        dispatch(AgriAction.updateDroughtSectorsRestrictions(dataDroughtSectors)).then(() => {
            dispatch(AgriAction.fetchDroughtSectorsRestrictions())
        })
    }

    useActions(() => {
        return readMode ? {
            new: () => {
                setIsNew(true)
                setSelectedRestriction({})
                setOpenModal(true)
            },
            export: () => onExport(sortedRestrictions),
            edit: () => setReadMode(false),
            share: [{
            //     id: 'mail',
            //     onClick: openPopupMail,
            //     img: emailIcon,
            //     label: 'Mail',
            // },
            // {
                id: 'notif',
                onClick: () => setOpenModalNotifs(true),
                img: notificationIcon,
                label: i18n.notification,
            }],
        } : {
            save: () => onSave(localManagementUnitsRestrictions, localDroughtSectorsRestrictions),
            cancel: () => {
                dispatch(ManagementUnitAction.fetchManagementUnitsRestrictions())
                dispatch(AgriAction.fetchDroughtSectorsRestrictions())
                setReadMode(true)
            },
        }
    }, [readMode, sortedRestrictions, localManagementUnitsRestrictions, localDroughtSectorsRestrictions])

    const onUpdate = (data) => {
        const otherRestrictions = restrictions.filter(d => d.id !== data.id)
        if (otherRestrictions.some((r) => r.level === data.level)) {
            dispatch(ToastrAction.warning(i18n.levelAlreadyExist))
        } else {
            const newData = [
                ...otherRestrictions,
                data,
            ]
            dispatch(AgriAction.updateRestrictions(newData)).then(() => {
                setOpenModal(false)
                dispatch(AgriAction.fetchRestrictions())
            })
        }
    }

    const onDelete = (deletedRes) => {
        const filteredData = restrictions.filter(sr => sr.id !== deletedRes.id)
        dispatch(AgriAction.updateRestrictions(filteredData)).then(() => {
            dispatch(AgriAction.fetchRestrictions())
        })
    }

    const headersRestrictions = useMemo(() => {
        if (typeRestriction === 1) {
            return ['nullValue', 'droughtSector', 'type', 'normal', ...restrictions.map((r) => r.id), 'exemption', 'updateDate', 'nullValue2']
        }
        return ['nullValue', 'managementUnit', 'type', 'normal', ...restrictions.map((r) => r.id), 'exemption', 'updateDate', 'nullValue2']
    }, [restrictions, typeRestriction])

    const [customHeadersRestrictions, customWidthHeaders] = useMemo(() => {
        let obj = {}
        let costumWidths = typeRestriction === 1 ? {
            nullValue: '2%',
            droughtSector: '20%',
            normal: '7%',
            exemption: '2%',
            updateDate: '5%',
        } : {
            nullValue: '2%',
            managementUnit: '20%',
            normal: '7%',
            exemption: '2%',
            updateDate: '5%',
        }
        restrictions.forEach((r) => {
            obj[r.id] = r.label
            costumWidths[r.id] = '7%'
        })
        return [obj, costumWidths]
    }, [restrictions, typeRestriction])

    const dashboardData = useMemo(() => {
        if (typeRestriction === 1) {
            const onChangeValue = (idSector, resourceType, idRestriction) => {
                const newRestrictions = [
                    ...localDroughtSectorsRestrictions.filter((ugeR) => !(ugeR.idSector === idSector && ugeR.resourceType === resourceType)),
                    {
                        idSector,
                        idRestriction,
                        resourceType,
                        updateLogin: getLogin(),
                        updateDate: null,
                    },
                ]
                setDroughtSectorsRestrictions(newRestrictions)
            }
            const getCheckboxs = (idSector, resourceType) => {
                const normalChecked = !localDroughtSectorsRestrictions.some((ugeR) => ugeR.idSector === idSector && ugeR.resourceType === resourceType) || localDroughtSectorsRestrictions.some((ugeR) => ugeR.idSector === idSector && ugeR.resourceType === resourceType && ugeR.idRestriction === -1)
                let obj = {
                    normal: {
                        value: (
                            <Checkbox
                                col
                                checked={normalChecked}
                                onChange={() => onChangeValue(idSector, resourceType, -1)}
                                disabled={readMode}
                            />
                        ),
                        sortValue: normalChecked,
                    },
                }
                restrictions.forEach((r) => {
                    const isChecked = localDroughtSectorsRestrictions.some((ugeR) => ugeR.idSector === idSector && ugeR.resourceType === resourceType && ugeR.idRestriction === r.id)
                    obj[r.id] = {
                        value: (
                            <Checkbox
                                col
                                checked={isChecked}
                                onChange={() => onChangeValue(idSector, resourceType, r.id)}
                                disabled={readMode}
                            />
                        ),
                        sortValue: isChecked,
                    }
                })
                return obj
            }
            const droughtSectors = sandreCodes.filter((s) => s.field === 'PREL_AGRI.SECTEUR_SECHERESSE')
            const valuesFormatted = orderBy([
                ...localDroughtSectorsRestrictions.map((dsR) => {
                    const ds = droughtSectors.find((u) => u.code === dsR.idSector) || {}
                    const restr = restrictions.find((r) => r.id === dsR.idRestriction) || {}
                    return {
                        idSector: { value: dsR.idSector },
                        nullValue: { value: <div style={{ backgroundColor: restr?.color || '#52D100', width: '20px', border: 'solid black 1px' }}>&nbsp;</div>, sortValue: restr.level },
                        droughtSector: { value: ds.name },
                        ...getCheckboxs(dsR.idSector, dsR.resourceType),
                        exemption: { value: readMode ? (!dsR.exemption ? '' : <i className='material-icons' {...sieauTooltip(dsR.exemption, null, 'left')}>info</i>) : <Icon onClick={() => setModalExemptionData({ open: true, restriction: dsR, title: `${`${ds.name} - `}${restr.label}` })}>edit</Icon> },
                        updateDate: { value: getDate(dsR.updateDate) },
                        exemptionValue: { value: dsR.exemption },
                        resourceType: { value: dsR.resourceType },
                        type: { value: dsR.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial },
                        name: ds.name,
                        resource: dsR.resourceType,
                        nullValue2: {
                            value: readMode && (
                                <Icon
                                    onClick={() => {
                                        setFilterNotifs({ sampleType: dsR.resourceType, droughtSector: dsR.idSector })
                                        setOpenModalNotifs(true)
                                    }}
                                >
                                    share
                                </Icon>
                            ),
                        },
                    }
                }),
                ...[...droughtSectors.map((ds) => ({ ...ds, resourceType: 1 })), ...droughtSectors.map((ds) => ({ ...ds, resourceType: 2 }))].filter((ds) => !localDroughtSectorsRestrictions.some((ugeR) => ugeR.idSector === ds.code && ugeR.resourceType === ds.resourceType)).flatMap((ds) => {
                    return [{
                        idSector: { value: ds.idSector },
                        nullValue: { value: <div style={{ backgroundColor: '#52D100', width: '20px', border: 'solid black 1px' }}>&nbsp;</div>, sortValue: -1 },
                        droughtSector: { value: ds.name },
                        ...getCheckboxs(ds.code, ds.resourceType),
                        type: { value: ds.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial },
                        resourceType: { value: ds.resourceType },
                        exemption: { value: readMode ? undefined: <Icon onClick={() => setModalExemptionData({ open: true, restriction: { idSector: ds.code, idRestriction: -1, resourceType: ds.resourceType }, title: `${`${ds.name} - `}${i18n.normal}` })}>edit</Icon> },
                        name: ds.name,
                        resource: ds.resourceType,
                        nullValue2: {
                            value: readMode && (
                                <Icon
                                    onClick={() => {
                                        setFilterNotifs({ sampleType: ds.resourceType, droughtSector: ds.idSector })
                                        setOpenModalNotifs(true)
                                    }}
                                >
                                    share
                                </Icon>
                            ),
                        },
                    }]
                }),
            ], ['name', 'resource'])
            const valuesFiltered = valuesFormatted.filter((v) => lowerCase(`${v.idSector?.value} ${v.droughtSector?.value} ${v.exemptionValue?.value} ${v.updateDate?.value}`).includes(lowerCase(filters.searchValue)))
            const typeFiltered = filters.resourceType ? valuesFiltered.filter((v) => v.resourceType?.value === filters.resourceType) : valuesFiltered
            return typeFiltered
        }
        const onChangeValue = (managementCode, resourceType, idRestriction) => {
            const newRestrictions = [
                ...localManagementUnitsRestrictions.filter((ugeR) => !(ugeR.managementCode === managementCode && ugeR.resourceType === resourceType)),
                {
                    managementCode,
                    idRestriction,
                    resourceType,
                    updateLogin: getLogin(),
                    updateDate: null,
                },
            ]
            setManagementUnitsRestrictions(newRestrictions)
        }
        const getCheckboxs = (managementCode, resourceType) => {
            const normalChecked = !localManagementUnitsRestrictions.some((ugeR) => ugeR.managementCode === managementCode && ugeR.resourceType === resourceType) || localManagementUnitsRestrictions.some((ugeR) => ugeR.managementCode === managementCode && ugeR.resourceType === resourceType && ugeR.idRestriction === -1)
            let obj = {
                normal: {
                    value: (
                        <Checkbox
                            col
                            checked={normalChecked}
                            onChange={() => onChangeValue(managementCode, resourceType, -1)}
                            disabled={readMode}
                        />
                    ),
                    sortValue: normalChecked,
                },
            }
            restrictions.forEach((r) => {
                const isChecked = localManagementUnitsRestrictions.some((ugeR) => ugeR.managementCode === managementCode && ugeR.resourceType === resourceType && ugeR.idRestriction === r.id)
                obj[r.id] = {
                    value: (
                        <Checkbox
                            col
                            checked={isChecked}
                            onChange={() => onChangeValue(managementCode, resourceType, r.id)}
                            disabled={readMode}
                        />
                    ),
                    sortValue: isChecked,
                }
            })
            return obj
        }
        const valuesFormatted = orderBy([
            ...localManagementUnitsRestrictions.map((ugeR) => {
                const uge = managementUnits.find((u) => u.managementCode === ugeR.managementCode) || {}
                const restr = restrictions.find((r) => r.id === ugeR.idRestriction) || {}
                return {
                    managementCode: { value: ugeR.managementCode },
                    nullValue: { value: <div style={{ backgroundColor: restr?.color || '#52D100', width: '20px', border: 'solid black 1px' }}>&nbsp;</div>, sortValue: restr.level },
                    managementUnit: { value: `[${uge.id}] ${uge.name}` },
                    ...getCheckboxs(ugeR.managementCode, ugeR.resourceType),
                    exemption: { value: readMode ? (!ugeR.exemption ? '' : <i className='material-icons' {...sieauTooltip(ugeR.exemption, null, 'left')}>info</i>) : <Icon onClick={() => setModalExemptionData({ open: true, restriction: ugeR, title: `${`[${uge.id}] ${uge.name}` + ' - '}${restr.label}` })}>edit</Icon> },
                    updateDate: { value: getDate(ugeR.updateDate) },
                    exemptionValue: { value: ugeR.exemption },
                    resourceType: { value: ugeR.resourceType },
                    type: { value: ugeR.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial },
                    name: `[${uge.id}] ${uge.name}`,
                    resource: ugeR.resourceType,
                    nullValue2: {
                        value: readMode && (
                            <Icon
                                onClick={() => {
                                    setFilterNotifs({ sampleType: ugeR.resourceType, managementUnit: uge.parent || uge.managementCode, subManagementUnit: uge.parent ? uge.managementCode : undefined })
                                    setOpenModalNotifs(true)
                                }}
                            >
                                share
                            </Icon>
                        ),
                    },
                }
            }),
            ...[...managementUnits.map((m) => ({ ...m, resourceType: 1 })), ...managementUnits.map((m) => ({ ...m, resourceType: 2 }))].filter((m) => !localManagementUnitsRestrictions.some((ugeR) => ugeR.managementCode === m.managementCode && ugeR.resourceType === m.resourceType)).flatMap((u) => {
                return [{
                    managementCode: { value: u.managementCode },
                    nullValue: { value: <div style={{ backgroundColor: '#52D100', width: '20px', border: 'solid black 1px' }}>&nbsp;</div>, sortValue: -1 },
                    managementUnit: { value: `[${u.id}] ${u.name}` },
                    ...getCheckboxs(u.managementCode, u.resourceType),
                    type: { value: u.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial },
                    resourceType: { value: u.resourceType },
                    exemption: { value: readMode ? undefined: <Icon onClick={() => setModalExemptionData({ open: true, restriction: { managementCode: u.managementCode, idRestriction: -1, resourceType: u.resourceType }, title: `${`[${u.id}] ${u.name}` + ' - '}${i18n.normal}` })}>edit</Icon> },
                    name: `[${u.id}] ${u.name}`,
                    resource: u.resourceType,
                    nullValue2: {
                        value: readMode && (
                            <Icon
                                onClick={() => {
                                    setFilterNotifs({ sampleType: u.resourceType, managementUnit: u.parent || u.managementCode, subManagementUnit: u.parent ? u.managementCode : undefined })
                                    setOpenModalNotifs(true)
                                }}
                            >
                                share
                            </Icon>
                        ),
                    },
                }]
            }),
        ], ['name', 'resource'])
        const valuesFiltered = valuesFormatted.filter((v) => lowerCase(`${v.managementCode.value} ${v.managementUnit.value} ${v.exemptionValue?.value} ${v.updateDate?.value}`).includes(lowerCase(filters.searchValue)))
        const typeFiltered = filters.resourceType ? valuesFiltered.filter((v) => v.resourceType.value === filters.resourceType) : valuesFiltered
        return typeFiltered
    }, [localManagementUnitsRestrictions, managementUnits, restrictions, readMode, filters, typeRestriction, localDroughtSectorsRestrictions, sandreCodes])

    return (
        <Grid className='padding-1'>
            <Table
                title={i18n.restrictions}
                data={sortedRestrictions}
                nbPerPageLabel={nbPerPageLabel}
                type={{ headers }}
                alterable={!readMode}
                onAlter={(restriction) => {
                    setSelectedRestriction(sortedRestrictions.find(s => s.id === restriction.id))
                    setOpenModal(true)
                }}
                customHeaders={{
                    displayedColor: i18n.color,
                }}
                deletable={!readMode}
                onDelete={onDelete}
                condensed
                sortable
                paging
                round
            />
            <Grid className='padding-top-1'>
                <RestrictionsFilterPanel onValidate={setFilters} />
            </Grid>
            <Grid className='padding-top-1'>
                <Table
                    title={i18n.dashboard}
                    data={dashboardData}
                    nbPerPageLabel={nbPerPageLabel}
                    type={{ headers: headersRestrictions }}
                    customHeaders={customHeadersRestrictions}
                    customWidthHeaders={customWidthHeaders}
                    sortable
                    paging
                    round
                    color
                />
            </Grid>
            <TerritoryWaterTurnsRestrictionsModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                restriction={selectedRestriction}
                onUpdate={onUpdate}
                isNew={isNew}
            />
            {openModalNotifs && (
                <RestrictionsNotifDialog
                    filter={filterNotifs}
                    open={openModalNotifs}
                    onClose={() => {
                        setFilterNotifs({})
                        setOpenModalNotifs(false)
                    }}
                />
            )}
            {modalExemptionData.open && (
                <Dialog open={modalExemptionData.open} maxWidth='lg'>
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {modalExemptionData.title}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setModalExemptionData({ open: false })} />
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        <div className='padding-top-1' style={{ width: '50vw' }}>
                            <Textarea
                                title={ i18n.exemption }
                                value={ modalExemptionData.restriction.exemption }
                                onChange={(v) => setModalExemptionData({ ...modalExemptionData, restriction: { ...modalExemptionData.restriction, exemption: v } })}
                                rows={10}
                            />
                        </div>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                if (typeRestriction === 1) {
                                    const newRestrictions = [
                                        ...localDroughtSectorsRestrictions.filter((dsR) => !(dsR.idSector === modalExemptionData.restriction.idSector && dsR.resourceType === modalExemptionData.restriction.resourceType)),
                                        {
                                            ...modalExemptionData.restriction,
                                            updateLogin: getLogin(),
                                            updateDate: null,
                                        },
                                    ]
                                    setDroughtSectorsRestrictions(newRestrictions)
                                } else {
                                    const newRestrictions = [
                                        ...localManagementUnitsRestrictions.filter((ugeR) => !(ugeR.managementCode === modalExemptionData.restriction.managementCode && ugeR.resourceType === modalExemptionData.restriction.resourceType)),
                                        {
                                            ...modalExemptionData.restriction,
                                            updateLogin: getLogin(),
                                            updateDate: null,
                                        },
                                    ]
                                    setManagementUnitsRestrictions(newRestrictions)
                                }
                                setModalExemptionData({ open: false })
                            }}
                        >
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                </Dialog>
            )}
        </Grid>
    )
}

export default TerritoryRestrictionsApp
