import pdf from 'assets/pictures/export/pdf.png'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import { getDate, getHour } from '../../../utils/DateUtil'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Select from 'components/forms/Select'
import OperationAction from 'quality/actions/OperationAction'
import OldOperationAction from 'quality/components/operation/actions/OperationAction'
import QualityAction from 'quality/actions/QualityAction'

const SampleModal = ({
    isOpen = false,
    close = () => {},

    analyse = {},
}) => {
    const dispatch = useDispatch()

    const {
        cities,
        contacts,
        producers,
        laboratories,
        qualifications,
        status,
        supports,
        parameters,
        units,
        remarks,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contacts: store.ContactReducer.contacts,
        producers: store.ContributorReducer.producers,
        laboratories: store.ContributorReducer.laboratories,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        supports: store.SupportReducer.supports,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        remarks: store.OperationReducer.remarks,
    }), shallowEqual)

    const [operation, setOperation] = useState({})
    const [sample, setSample] = useState({})
    const [qualitometer, setQualitometer] = useState({})

    useEffect(() => {
        if (isOpen) {
            OperationAction.getOperation(analyse.operation, analyse.qualitometer).then(setOperation)
            OldOperationAction.promiseSample(analyse.qualitometer, analyse.operation).then(setSample)
            QualityAction.promiseQualitometer(analyse.qualitometer).then(setQualitometer)
        } else {
            setOperation({})
            setSample({})
            setQualitometer({})
        }
    }, [analyse.operation, analyse.qualitometer, isOpen])

    return (
        <DialogMUI
            maxWidth='xl'
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: 'auto',
                    maxHeight: 'auto',
                },
            }}
            open={isOpen}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.resultDetails}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={close} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <StyledFieldSet>
                            <StyledLegend>{i18n.station}</StyledLegend>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={4}>
                                    <Input
                                        title={i18n.stationCode}
                                        value={qualitometer.code}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input
                                        title={i18n.creationDate}
                                        value={getDate(qualitometer.creation)}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input
                                        title={i18n.closeDate}
                                        value={getDate(qualitometer.close)}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        options={cities}
                                        value={qualitometer.townCode}
                                        label={i18n.city}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        options={contacts}
                                        value={qualitometer.contactCode}
                                        label={i18n.contact}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledFieldSet>
                            <StyledLegend>{i18n.sample}</StyledLegend>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={3}>
                                    <Input
                                        title={i18n.date}
                                        value={getDate(operation.date)}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input
                                        title={i18n.hour}
                                        value={getHour(operation.date)}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Input
                                        title={i18n.analysisNumber}
                                        value={sample.laboNumber}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    {sample.producerRef ? <img className='clickable right' style={{ height: '42px', paddingRight: '10px' }} src={pdf} /> : null}
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        options={producers}
                                        value={operation.producer}
                                        label={i18n.producer}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledFieldSet>
                            <StyledLegend>{i18n.result}</StyledLegend>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={2}>
                                    <Input
                                        title={i18n.date}
                                        value={getDate(analyse.analysisDate)}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        options={supports}
                                        value={operation.support}
                                        label={i18n.support}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        options={status}
                                        value={analyse.status}
                                        label={i18n.status}
                                        keyLabel='name'
                                        keyValue='code'
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        options={qualifications}
                                        value={analyse.qualification}
                                        label={i18n.qualification}
                                        keyLabel='name'
                                        keyValue='code'
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        options={parameters}
                                        value={analyse.parameter}
                                        label={i18n.parameter}
                                        keyLabel='labelWithCode'
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input
                                        title={i18n.result}
                                        value={analyse.result}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        options={units}
                                        value={analyse.unit}
                                        label={i18n.unit}
                                        keyLabel='symbolWithName'
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        options={remarks}
                                        value={analyse.remark}
                                        label={i18n.remark}
                                        keyLabel='name'
                                        keyValue='code'
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Input
                                        title={i18n.quantificationLimit}
                                        value={analyse.lq}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        options={laboratories}
                                        value={analyse.labo}
                                        label={i18n.laboratory}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='flex-end'>
                    <Grid container item xs={3} justifyContent='flex-end'>
                        <Button onClick={() => dispatch(push(`/station/quality/${analyse.qualitometer}`))} variant='contained' color='primary'>
                            {i18n.accessToTheFile}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

SampleModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    analyse: PropTypes.shape({
        // DtoAnalysis
        // calculateThresholdResult
    }),
}

export default SampleModal