/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { arrayOf } from '../../../../utils/StoreUtils'
import DtoSTEPTerritoryEmissions from '../dto/DtoSTEPTerritoryEmissions'
import Row from '../../../../components/react/Row'
import Table from '../../../../components/datatable/Table'
import InstallationAction from '../../../actions/InstallationAction'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import ProgressCard from '../../../../components/card/ProgressCard'
import { nbPerPageLabelMedium } from '../../../../referencial/constants/ReferencialConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getStationTitle } from '../../../../utils/StationUtils'
import ActionComponent from '../../../../components/ActionComponent'
import { multiLinesi18n } from '../../../../utils/StringUtil'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_STATION_INSTALLATION_STEP } from '../../../../account/constants/AccessRulesConstants'
import { exportFile } from 'utils/ExportDataUtil'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import Card from 'components/card/Card'
import { Button } from '@mui/material'
import { isEmpty, sortBy } from 'lodash'
import DtoSTEP from '../dto/DtoSTEP'

const headers = ['dataOrigin', 'activityCode', 'nbEstablishments', 'parameterCode', 'name', 'kgFlowEntrepriseDay',
    'kgFlowEmployeeDay', 'nbEmployees', 'totalFlowEmployees', 'totalFlowEntreprises', 'totalEmissionsEstablishments']
const headersSubscribers = ['parameterCode', 'label', 'emission']
const headersSubscribersExport = ['parameterCode', 'label', 'totalFlowTheo']

class STEPTerritoryApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            artisansLoaded: false,
            industriesLoaded: false,
            subscribersLoaded: false,
            activityFilter: [],
            basinFilter: [],
        }
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_STATION_INSTALLATION_STEP)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const { installation } = this.props
        this.props.fetchSTEPTerritoryArtisans({ code: installation.code }).then(() => {
            this.setState({ artisansLoaded: true })
        })

        this.props.fetchSTEPTerritoryIndustries({ code: installation.code }).then(() => {
            this.setState({ industriesLoaded: true })
        })

        this.props.fetchSTEPTerritorySubscribers(installation.code).then(() => {
            this.setState({ subscribersLoaded: true })
        })

        if (!this.props.activities.length) {
            this.props.fetchActivities()
        }

        if (isEmpty(this.props.installationSTEP)) {
            this.props.fetchInstallationSTEP(installation.id)
        }

        this.setTitle()
        this.setActions({})
    }

    setTitle = () => {
        const { installation } = this.props

        this.props.forceFetch('title', [{
            title: i18n.installation,
            href: 'installation',
        }, {
            title: getStationTitle(installation),
            href: `station/installation/${installation.id}`,
        }, {
            title: i18n.emissions,
            href: `station/installation/${installation.id}/STEPTerritory`,
        }])
    }

    getTerritoryArtisansExport = () => {
        const { STEPTerritoryArtisans } = this.props
        exportFile({
            data: STEPTerritoryArtisans.map((d, i) => {
                return i === 0 ? { ...d, headers } : d
            }),
            titleFile: i18n.theoreticalEmissionsArtisans,
        })
    }

    getTerritoryIndustriesExport = () => {
        const { STEPTerritoryIndustries } = this.props
        exportFile({
            data: STEPTerritoryIndustries.map((d, i) => {
                return i === 0 ? { ...d, headers } : d
            }),
            titleFile: i18n.theoreticalIndustrialEmissions,
        })
    }

    getTerritorySubscribersExport = () => {
        const { STEPTerritorySubscribers } = this.props
        exportFile({
            data: STEPTerritorySubscribers.map((d, i) => {
                return i === 0 ? { ...d, headers: headersSubscribers, customHeaders: headersSubscribersExport } : d
            }),
            titleFile: i18n.theoreticalEmissionsDomesticCustomers,
        })
    }

    getTableActions = (getExportData = () => {}) => {
        return [
            {
                iconName: 'file_download',
                tooltip: i18n.export,
                color: 'black',
                onClick: () => getExportData(),
            },
        ]
    }

    filter = () => {
        const { activityFilter, basinFilter } = this.state
        const { installation } = this.props

        const data = {
            code: installation.code,
            activities: activityFilter.length ? activityFilter : null,
            basins: basinFilter.length ? basinFilter : null,
        }
        this.props.fetchSTEPTerritoryArtisans(data)
        this.props.fetchSTEPTerritoryIndustries(data)
    }

    render() {
        const { artisansLoaded, industriesLoaded, subscribersLoaded, activityFilter, basinFilter } = this.state
        const { STEPTerritoryArtisans, STEPTerritoryIndustries, STEPTerritorySubscribers, activities, installationSTEP } = this.props

        const customHeaders = {
            dataOrigin: i18n.origin,
            parameterCode: multiLinesi18n(i18n.parameterCode),
            kgFlowEntrepriseDay: multiLinesi18n(i18n.kgFlowEntrepriseDay),
            kgFlowEmployeeDay: multiLinesi18n(i18n.kgFlowEmployeeDay),
            totalFlowEmployees: multiLinesi18n(i18n.totalFlowEmployees),
            totalFlowEntreprises: multiLinesi18n(i18n.totalFlowEntreprises),
            totalEmissionsEstablishments: multiLinesi18n(i18n.totalEmissionsEstablishments),
            nbEstablishments: i18n.numberInstitutions,
            nbEmployees: i18n.effective,
        }

        return (
            <Row className='padding-left-2 padding-top-1'>
                <Card className='padding-top-1 padding-bottom-1 margin-bottom-1' noMargin={false}>
                    <Row>
                        <Row>
                            <MultiAutocomplete
                                col={6}
                                displayWithCode
                                label={i18n.activities}
                                elements={sortBy(activities, 'code')}
                                clearFunction
                                values={activityFilter}
                                onChange={(values) => this.setState({ activityFilter: values })}
                            />
                            <MultiAutocomplete
                                col={6}
                                label={i18n.collectionBasins}
                                elements={installationSTEP?.link_basins?.map(d => d.basinName ? d : { ...d, basinName: `[${d.basinCode}]` })}
                                clearFunction
                                keyName={'basinName'}
                                keyId={'idBasin'}
                                values={basinFilter}
                                onChange={(values) => this.setState({ basinFilter: values })}
                            />
                        </Row>
                        <Row className='padding-right-1'>
                            <Button onClick={this.filter} className='right' variant='contained' color='primary'>
                                {i18n.search}
                            </Button>
                        </Row>
                    </Row>
                </Card>
                <Row >
                    {artisansLoaded &&
                        (
                            <Table
                                actions={this.getTableActions(this.getTerritoryArtisansExport)}
                                data={STEPTerritoryArtisans}
                                type={{ headers }}
                                customHeaders={customHeaders}
                                title={i18n.theoreticalEmissionsArtisans}
                                condensed sortable paging
                                nbPerPageLabel={nbPerPageLabelMedium}
                                invertedHeaderStyle
                            />
                        ) || <ProgressCard indeterminate={true} />
                    }
                </Row>
                <Row className='margin-top-1' noMargin={false}>
                    {industriesLoaded &&
                        (
                            <Table
                                actions={this.getTableActions(this.getTerritoryIndustriesExport)}
                                data={STEPTerritoryIndustries}
                                type={{ headers }}
                                customHeaders={customHeaders}
                                condensed sortable paging
                                nbPerPageLabel={nbPerPageLabelMedium}
                                title={i18n.theoreticalIndustrialEmissions}
                                invertedHeaderStyle
                            />
                        ) || <ProgressCard indeterminate={true} />
                    }
                </Row>
                <Row className='margin-top-1' noMargin={false}>
                    {subscribersLoaded &&
                        (
                            <Table
                                actions={this.getTableActions(this.getTerritorySubscribersExport)}
                                data={STEPTerritorySubscribers}
                                type={{ headers: headersSubscribers }}
                                customHeaders={{
                                    emission: i18n.totalFlowTheo,
                                }}
                                condensed sortable paging
                                nbPerPageLabel={nbPerPageLabelMedium}
                                title={i18n.theoreticalEmissionsDomesticCustomers}
                                invertedHeaderStyle
                            />
                        ) || <ProgressCard indeterminate={true} />
                    }
                </Row>
            </Row>
        )
    }
}

STEPTerritoryApp.propTypes = {
    STEPTerritoryArtisans: arrayOf(DtoSTEPTerritoryEmissions),
    STEPTerritoryIndustries: arrayOf(DtoSTEPTerritoryEmissions),
    STEPTerritorySubscribers: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        shortLabel: PropTypes.string,
        Emission: PropTypes.number,
    })),
    forceFetch: PropTypes.func,
    push: PropTypes.func,
    fetchSTEPTerritoryArtisans: PropTypes.func,
    fetchSTEPTerritoryIndustries: PropTypes.func,
    fetchSTEPTerritorySubscribers: PropTypes.func,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    installation: PropTypes.instanceOf(DtoInstallation),
    installationSTEP: PropTypes.instanceOf(DtoSTEP),
}

const mapStateToProps = store => ({
    installation: store.InstallationReducer.installation,
    STEPTerritoryArtisans: store.InstallationReducer.STEPTerritoryArtisans,
    STEPTerritoryIndustries: store.InstallationReducer.STEPTerritoryIndustries,
    STEPTerritorySubscribers: store.InstallationReducer.STEPTerritorySubscribers,
    activities: store.InstallationReducer.activities,
    installationSTEP: store.InstallationReducer.installationSTEP,
})

const mapDispatchToProps = {
    fetchSTEPTerritoryArtisans: InstallationAction.fetchSTEPTerritoryArtisans,
    fetchSTEPTerritoryIndustries: InstallationAction.fetchSTEPTerritoryIndustries,
    fetchSTEPTerritorySubscribers: InstallationAction.fetchSTEPTerritorySubscribers,
    forceFetch: SieauAction.forceFetch,
    push,
    fetchActivities: InstallationAction.fetchActivities,
    fetchInstallationSTEP: InstallationAction.fetchInstallationSTEP,
}


export default connect(mapStateToProps, mapDispatchToProps)(STEPTerritoryApp)
