import React, { Component } from 'react'
import PropTypes from 'prop-types'

const $ = window.$

class CollapseTopBar extends Component {
    constructor(props) {
        super(props)
        this.state = { open: this.props.open }
    }

    collapse() {
        this.setState({ open: !this.state.open })
        setTimeout(this.props.onOpen, 50)
    }

    componentDidMount() {
        this.setHeight()
        if ($('.collapse-top-bar').prev().length !== 0) {
            $('.collapse-top-bar').prev().css({ zIndex: '13', position: 'relative' })
        }
    }

    componentDidUpdate() {
        this.setHeight()
    }

    setHeight() {
        $('.banner').css('top', ($('.banner').outerHeight() * -1) + 2)
        $('.banner > .col.s12').css('opacity', this.state.open ? '1' : '0')
        $('.banner').css('background', this.state.open ? 'white' : 'transparent')
    }

    render() {
        return (
            <div className='collapse-top-bar' id='collapse-top-bar'>
                <div className={`banner ${this.state.open && 'open'} row no-margin`} id='banner'>
                    <div className='relative content-wrapper' >
                        <div className='ribbon'>
                            <div className='arrow'>
                                <a onClick={() => this.collapse()} id='open-menu'><i className='material-icons'>expand_more</i></a>
                                <a onClick={() => this.collapse()} id='close-menu'><i className='material-icons'>expand_less</i></a>
                            </div>
                        </div>
                        <div className='left-align col s12 no-padding child-wrapper'>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CollapseTopBar.propTypes = {
    children: PropTypes.element,
    onOpen: PropTypes.func,
    open: PropTypes.bool,
}

export default CollapseTopBar
