import React from 'react'
import ActionComponent from '../../../../components/ActionComponent'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ApplicationConf from 'conf/ApplicationConf'
import SieauAction from '../../../../components/sieau/SieauAction'
import AppStore from 'store/AppStore'
import UnitAction from '../actions/UnitAction'
import ReferencialAction from '../../../action/ReferencialAction'
import DtoReferencial from '../../../dto/DtoReferencial'
import i18n from 'simple-react-i18n'
import UnitDto from '../dto/UnitDto'
import Input from '../../../../components/forms/Input'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { isEqual, omit } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import ReplaceModal from '../../ReplaceModal'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_UNIT_ENTITY,
    PATH_REFERENCIAL_UNIT_ENTITY_LINK,
    PATH_REFERENCIAL_UNIT_ENTITY_NEW,
} from '../../../../home/constants/RouteConstants'
import { push } from 'connected-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import DeleteModal from '../../DeleteModal'
import { sandreUnitDefaultLink } from '../../../../conf/SieauConstants'
import { getUser } from '../../../../utils/SettingUtils'
import HomeAction from 'home/actions/HomeAction'
import logoSandre from 'assets/pictures/logo_sandre.png'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class UnitApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            unit: new UnitDto({}),
            readMode: true,
        }
    }

    changeCode = (value) => {
        this.changeStateAttribute('code', value)
    }

    changeSymbol = (value) => {
        this.changeStateAttribute('symbol', value.toString())
    }

    changeStatus = (value) => {
        this.changeStateAttribute('status', value.toString())
    }

    changeName = (value) => {
        this.changeStateAttribute('name', value)
    }

    changeInternationalName = (value) => {
        this.changeStateAttribute('internationalName', value)
    }

    changeAuthor = (value) => {
        this.changeStateAttribute('author', value)
    }

    changeInternalCode = (value) => {
        this.changeStateAttribute('internalCode', value)
    }

    changeComment = (value) => {
        this.changeStateAttribute('comment', value)
    }

    changeStateAttribute = (attr, value) => {
        const unit = Object.assign({}, this.state.unit)
        unit[attr] = value
        this.setState({ unit })
    }

    setReadMode = () => {
        this.setState({ readMode: true })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        AppStore.dispatch(UnitAction.reset())
    }

    componentWillMount() {
        if (this.props.match.params.code !== 'new') {
            AppStore.dispatch(UnitAction.fetchUnit(this.props.match.params.code))
            this.setReadMode()
        } else {
            this.setEditMode()
        }
        AppStore.dispatch(ReferencialAction.fetchReferencialStatus())
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarUnit(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.code !== this.props.match.params.code) {
            AppStore.dispatch(UnitAction.fetchUnit(nextProps.match.params.code))
        } else if (nextProps.match.params.code === 'new') {
            AppStore.dispatch(SieauAction.forceFetch('title', [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.referenceUnit,
                    href: PATH_REFERENCIAL_UNIT_ENTITY,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_UNIT_ENTITY_NEW,
                },
            ]))
        } else {
            if (!isEqual(nextProps.unit, this.props.unit)) {
                AppStore.dispatch(SieauAction.forceFetch('title', [
                    {
                        title: i18n.referencials,
                        href: PATH_REFERENCIAL,
                    },
                    {
                        title: i18n.referenceUnit,
                        href: PATH_REFERENCIAL_UNIT_ENTITY,
                    },
                    {
                        title: this.props.match.params.code + (nextProps.unit.name ? ` - ${nextProps.unit.name}` : ''),
                        href: PATH_REFERENCIAL_UNIT_ENTITY_LINK + this.props.match.params.code,
                    },
                ]))
                this.setState({ unit: nextProps.unit })
            }
            if (!isEqual(this.props.isEditMode, nextProps.isEditMode) || !isEqual(this.props.unit.code, nextProps.unit.code) || !isEqual(this.props.units, nextProps.units)) {
                this.toNavbarUnit(nextProps)
            }
        }
    }

    getUnitLinks = () => {
        return (
            [{
                href: sandreUnitDefaultLink + this.props.unit.code,
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            }]
        )
    }

    onReplace = newCode => {
        AppStore.dispatch(UnitAction.replaceUnit(this.state.unit.code, newCode.code))
    }

    getSandreCodeDisabled() {
        if (this.props.match.params.code !== 'new') {
            return (
                <Input
                    col={ 3 }
                    title={ i18n.sandreCode }
                    value={ this.state.unit.code }
                    onChange={ this.changeCode }
                    maxLength={ 5 }
                    disabled
                />
            )
        }
        return (
            <Input
                col={ 3 }
                title={ i18n.sandreCode }
                value={ this.state.unit.code }
                onChange={ this.changeCode }
                maxLength={ 5 }
            />
        )
    }

    getRequiredFunction = (actions) => {
        AppStore.dispatch(actions)
        this.setReadMode()
    }

    toNavbarUnit(props = this.props) {
        const actions = (() => {
            if (props.match.params.code === 'new' && (!props.unit || !props.unit.code)) {
                return {
                    save: () => {
                        const existCode = this.props.units.find(u => u.code === this.state.unit.code)
                        if (existCode) {
                            AppStore.dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                        } else if (!this.state.unit.code) {
                            AppStore.dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                        } else {
                            this.getRequiredFunction(UnitAction.createUnit(this.state.unit))
                        }
                    },
                    cancel: () => {
                        AppStore.dispatch(push(PATH_REFERENCIAL_UNIT_ENTITY))
                        this.setReadMode()
                    },
                }
            }
            if (!this.state.readMode) {
                return {
                    save: () => {
                        this.getRequiredFunction(UnitAction.updateUnit(this.state.unit, this.state.unit.code))
                    },
                    cancel: () => {
                        this.setState({ unit: props.unit })
                        this.setReadMode()
                    },
                }
            }
            return {
                edit: () => {
                    this.setEditMode()
                },
                deleteCheck: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.unitCountDelete(this.props.match.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <DeleteModal
                            onDelete={ () => AppStore.dispatch(UnitAction.deleteUnit(this.state.unit.code)) }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                replace: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.unitCountDelete(this.props.match.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <ReplaceModal
                            elements={ props.units }
                            title={ `${i18n.unit} [${this.state.unit.code}]` }
                            label={ i18n.unit }
                            onReplace={ this.onReplace }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                links: this.getUnitLinks(),
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidUpdate() {
        this.toNavbarUnit()
    }

    componentDidMount() {
        this.toNavbarUnit()
    }

    render() {
        const disabled = {
            active: !this.state.readMode,
            disabled: this.state.readMode,
        }

        const activeIcon = this.props.unit.status === '3' ? <i className='material-icons'>lock</i> : null

        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s12 m2 l2' />
                                <div className='col s12 m8 l8'>
                                    <div className='row no-margin padding-top-3-px'>
                                        { this.getSandreCodeDisabled() }
                                        <Input
                                            col={ 3 }
                                            title={ i18n.symbol }
                                            value={ this.state.unit.symbol }
                                            onChange={ this.changeSymbol }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Select
                                            options={ this.props.referencialStatus }
                                            label={ i18n.status }
                                            col={ 2 }
                                            onChange={ this.changeStatus }
                                            value={ parseInt(this.state.unit.status) }
                                            nullLabel='&nbsp;'
                                            { ...disabled }
                                        />
                                        <div className='col s1 padding-top-10-px'>
                                            { activeIcon }
                                        </div>
                                        <div className='center padding-top-10-px'>
                                            <Checkbox
                                                col={ 3 }
                                                label={ i18n.active }
                                                checked={ this.state.unit.active === '1' }
                                                onChange={ (e) => this.changeStateAttribute('active', e ? '1' : '0') }
                                                { ...disabled }
                                            />
                                        </div>
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 6 }
                                            title={ i18n.name }
                                            value={ this.state.unit.name }
                                            onChange={ this.changeName }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <SimpleDatePicker
                                            id='creationDate'
                                            col={3}
                                            label={i18n.creationDate}
                                            value={this.state.unit.creationDate}
                                            onChange={ creationDate => this.setState({ ...this.state.unit, creationDate }) }
                                            {...disabled}
                                        />
                                        <SimpleDatePicker
                                            id='updateDate'
                                            col={3}
                                            label={i18n.modificationDate}
                                            value={this.state.unit.updateDate}
                                            onChange={ updateDate => this.setState({ ...this.state.unit, updateDate }) }
                                            {...disabled}
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 6 }
                                            title={ i18n.internationalName }
                                            value={ this.state.unit.internationalName }
                                            onChange={ this.changeInternationalName }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 6 }
                                            title={ i18n.author }
                                            value={ this.state.unit.author }
                                            onChange={ this.changeAuthor }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Textarea
                                            col={ 6 }
                                            title={ i18n.internalCode }
                                            value={ this.state.unit.internalCode }
                                            onChange={ this.changeInternalCode }
                                            { ...disabled }
                                        />
                                        <Textarea
                                            col={ 6 }
                                            title={ i18n.comment }
                                            value={ this.state.unit.comment }
                                            onChange={ this.changeComment }
                                            { ...disabled }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UnitApp.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    unit: PropTypes.instanceOf(UnitDto),
    referencialStatus: PropTypes.instanceOf(DtoReferencial),
    getLink: PropTypes.func,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => {
    return {
        unit: store.UnitReducer.unit,
        units: store.UnitReducer.units,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }
}

export default connect(mapStateToProps)(UnitApp)
