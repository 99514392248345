import React, { useMemo, useState } from 'react'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import Card from '../../../components/card/Card'
import ReferencialAction from '../../action/ReferencialAction'
import DtoSandreCode from '../../dto/DtoSandreCode'
import { getUser } from 'utils/SettingUtils'
import { hasValue } from 'utils/NumberUtil'
import SandreCodePanel from '../sandreCodes/SandreCodePanel'

const LexiconApp = ({ match: { params: { field, code } } }) => {
    const {
        sandreCodes,
        referencialSandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    }), shallowEqual)

    const [sandreCode, setSandreCode] = useState(new DtoSandreCode({}))
    const [readMode, setReadMode] = useState(field !== 'new')

    const dispatch = useDispatch()

    const defaultSandreCode = useMemo(() => sandreCodes.find(sc => sc.field === field && sc.code === parseInt(code)) || {}, [code, field, sandreCodes])

    useEffect(() => {
        if (field !== 'new') {
            setSandreCode(defaultSandreCode)
        } else {
            setSandreCode({ field, code: Math.max(...sandreCodes.filter(s => s.field === field).map(s => s.code)) + 1 })
        }
    }, [defaultSandreCode, field, sandreCodes])

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        if (!referencialSandreCodes.length) {
            dispatch(ReferencialAction.fetchReferencialSandreCodes())
        }
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: '/referencial',
    }, {
        title: i18n.lexicons,
        href: '/referencial/lexicons',
    }, {
        title: field !== 'new' ? field : i18n.new,
        href: `/referencial/lexicon/${field}/${code}/dashboard`,
    }], [field, code])

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        if (field === 'new' || !readMode) {
            return {
                save: () => {
                    if (hasValue(sandreCode.code) && sandreCode.field && sandreCode.name) {
                        if (sandreCode.mnemonique) {
                            const regex = new RegExp('^[A-Z0-9_-]*$')
                            if (!regex.test(sandreCode.mnemonique)) {
                                dispatch(ToastrAction.warning(i18n.mnemoniqueRule))
                            }
                        }
                        if (field === 'new') {
                            if (sandreCodes.find(s => s.field === sandreCode.field && s.code === sandreCode.code)) {
                                dispatch(ToastrAction.error(i18n.undefinedOrTakenCodeAndName))
                                return
                            }
                            dispatch(ReferencialAction.addSandreCode(sandreCode)).then(() => {
                                dispatch(ReferencialAction.fetchSandreCodes())
                                dispatch(push(`/referencial/lexicon/${sandreCode.field}/${sandreCode.code}/dashboard`))
                            })
                            setReadMode(true)
                        } else {
                            dispatch(ReferencialAction.updateSandreCode(sandreCode)).then(() => {
                                dispatch(ReferencialAction.fetchSandreCodes())
                            })
                            setReadMode(true)
                        }
                    } else {
                        dispatch(ToastrAction.error(i18n.allFieldsAreRequired))
                    }
                },
                cancel: () => {
                    if (field === 'new') {
                        dispatch(push('/referencial/lexicons'))
                    } else {
                        setSandreCode(defaultSandreCode)
                        setReadMode(true)
                    }
                },
            }
        }
        return {
            edit: () => {
                setReadMode(false)
            },
        }
    }, [field, readMode, defaultSandreCode, sandreCode])

    return (
        <div className='row padding-top-1'>
            <div className='col s10 offset-s1'>
                <Card className='padding-1' col={12}>
                    <SandreCodePanel
                        sandreCode={sandreCode}
                        readMode={readMode}
                        onChange={value => setSandreCode(sandreObj => ({ ...sandreObj, ...value }))}
                        field={field}
                    />
                </Card>
            </div>
        </div>
    )
}

LexiconApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            field: PropTypes.string,
            code: PropTypes.string,
        }),
    }),
}

export default LexiconApp
