import AdministrationAction from 'administration/actions/AdministrationAction'
import { flatten, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import QualityAction from '../../../quality/actions/QualityAction'
import { hasValue } from '../../../utils/NumberUtil'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import SuperMultiAutocomplete from '../SuperMultiAutocomplete'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import AccountAction from 'account/actions/AccountAction'
import { THRESHOLD } from 'quality/constants/QualityConstants'

const DEFAULT_THRESHOLD_VALUE = '-1'

const ThresholdSelect = ({
    col,
    title = '',
    selected,
    disabled = false,
    onChange = () => {},
    thresholdType = THRESHOLD.PC,
    fieldRef = `SEUILS_${thresholdType}`,
}) => {
    const {
        thresholds,
        qualityThresholds,
        applicationSettings,
        accountUserSettings,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        accountUserSettings: store.AccountReducer.accountUserSettings,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [selectedState, setSelectedState] = useState()

    const thresholdSettings = useApplicationSetting(fieldRef, setting => (setting && setting !== DEFAULT_THRESHOLD_VALUE) ? parseInt(setting) : undefined)
    const thresholdAccountSettings = useAccountSetting(fieldRef, setting => (setting && setting !== DEFAULT_THRESHOLD_VALUE) ? parseInt(setting) : undefined)

    useEffect(() => setSelectedState(selected), [selected])

    useEffect(() => {
        dispatch(QualityAction.fetchThresholds())
        if (!applicationSettings.length) {
            dispatch(AdministrationAction.fetchApplicationSettings())
        }
        if (!accountUserSettings.length) {
            dispatch(AccountAction.fetchAccountUserSettings())
        }

        setSelectedState(selected || thresholdAccountSettings || thresholdSettings)
        onChange(selected || thresholdAccountSettings || thresholdSettings)
    }, [])

    useEffect(() => {
        if (hasValue(selectedState) && `${selectedState}` !== DEFAULT_THRESHOLD_VALUE && !qualityThresholds.find(t => t.thresholdCode == selectedState && t.thresholdType == thresholdType)) {
            dispatch(QualityAction.fetchQualityThresholds([{ code: selectedState, thresholdType }]))
        }
    }, [selectedState])


    useUpdateEffect(() => {
        onChange(undefined)
    }, [thresholdType])

    const label = useMemo(() => {
        const found = qualityThresholds.find(t => t.thresholdCode == selectedState && t.thresholdType == thresholdType)
        const length = (found?.thresholds || []).length
        return (title || i18n.threshold) + ((selectedState || selectedState == 0) && `${selectedState}` !== DEFAULT_THRESHOLD_VALUE ? ` (${length} ${i18n.thresholds})` : '')
    }, [title, selectedState, thresholdType, qualityThresholds])

    const formatThresholds = useCallback((thresholdFiltered, dephValue, orderedThresholds) => {
        return flatten(thresholdFiltered.map(({ id, code, name }) => {
            const childrens = formatThresholds(orderedThresholds.filter(({ referenceCode }) => referenceCode == code), dephValue + 1, orderedThresholds)
            return [
                {
                    id,
                    code,
                    name,
                    elements: childrens.map(c => ({ id: c.id, code: c.code, name: c.name })),
                },
            ]
        }))
    }, [])

    const thresholdsFiltered = useMemo(() => thresholds.filter(th => (th.thresholdType || '0') === thresholdType), [thresholdType, thresholds])
    const thresholdsOrdered = useMemo(() => orderBy(thresholdsFiltered.filter(th => th.status == 1), 'name'), [thresholdsFiltered])
    const formatedThresholds = useMemo(() => formatThresholds(thresholdsOrdered.filter(({ referenceCode }) => !referenceCode), 0, thresholdsOrdered), [formatThresholds, thresholdsOrdered])

    const thresholdsFormatted = useMemo(() => thresholdsOrdered.map(c => ({ id: c.id, code: c.code, name: c.name })), [thresholdsOrdered])

    const updateSettings = (newValue) => {
        dispatch(AdministrationAction.updateSetting(fieldRef, !newValue ? DEFAULT_THRESHOLD_VALUE : `${newValue}`))
    }

    return (
        <SuperMultiAutocomplete
            col={col}
            label={label}
            options={thresholdsFormatted}
            optionsGroups={formatedThresholds}
            onChange={value => {
                onChange(value)
                setSelectedState(value)
                updateSettings(value)
            }}
            values={selectedState}
            disabled={disabled}
            groupsCanBeSelected
            groupsAreValues
        />
    )
}


ThresholdSelect.propTypes = {
    title: PropTypes.string,
    col: PropTypes.number,
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    thresholdType: PropTypes.string,
    fieldRef: PropTypes.string,
}

export default ThresholdSelect