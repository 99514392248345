import { Button, Grid, Tooltip } from '@mui/material'
import React from 'react'
import { isInstallationType } from 'utils/CampaignUtil'
import { downloadURI, exportFile } from 'utils/ExportDataUtil'
import i18n from 'simple-react-i18n'
import { getExportAction, getInstallationName } from 'utils/VisitUtils'
import { getDate } from 'utils/DateUtil'
import PropTypes from 'prop-types'
import DtoCampaignAction from '../dto/DtoCampaignAction'
import DtoVisit from '../dto/DtoVisit'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import { arrayOf } from 'utils/StoreUtils'
import InstallationAction from 'installation/actions/InstallationAction'
import { push } from 'connected-react-router'
import InstVisitActions from '../validation/InstVisitActions'
import ProgressBar from 'components/progress/ProgressBar'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet } from 'components/StyledElements'
import Icon from 'components/icon/Icon'

const headers = ['risk', 'localisation', 'equipmentType', 'responsible', 'category', 'deadline', 'interventionType', 'comment', 'correctionDate', 'commentCorrection']

const PopupTitle = ({
    campaignAction = {},
    visit = {},
    sandreCodes = [],
}) => {
    const equipmentType = isInstallationType(campaignAction.installationType)

    return (
        <StyledFieldSet style={{ position: 'sticky', top: '10' }}>
            <Grid container className='padding-left-1' style={{ fontWeight: 'bold' }}>
                <Grid item xs={6}>
                    <Grid>
                        {`${i18n.visit} : ${getDate(visit.visitDate)}`}
                    </Grid>
                    <Grid>
                        {`${i18n.campaign} : ${campaignAction.campaignName}`}
                    </Grid>
                    <Grid>
                        {`${i18n.actions} : ${visit.link_actions?.filter(la => la.correctionDate || la.commentCorrection).length} / ${visit.link_actions.length} ${i18n.finalize}`}
                    </Grid>
                </Grid>
                <Grid container item xs={6} justifyContent='flex-end' alignItems='center'>
                    <Icon
                        tooltip={i18n.export}
                        icon='file_download'
                        onClick={() => exportFile({
                            data: [
                                {
                                    ...getExportAction(sandreCodes, equipmentType, visit.link_actions[0]),
                                    headers,
                                },
                                ...visit.link_actions.slice(1).map(d => getExportAction(sandreCodes, equipmentType, d)),
                            ],
                            titleFile: `${i18n.actions}_${campaignAction.installationName || campaignAction.code}`,
                        })}
                    />
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

PopupTitle.propTypes = {
    campaignAction: PropTypes.instanceOf(DtoCampaignAction),
    visit: PropTypes.instanceOf(DtoVisit),
    sandreCodes: arrayOf(DtoSandreCode),
}

const PopupActions = ({
    campaignAction = {},
    visit = {},
}) => {
    const dispatch = useDispatch()

    return (
        <Grid container justifyContent='space-around'>
            <Grid className='center'>
                {
                    visit.statusVisit > 2 ? (
                        <Tooltip title={i18n.noAccessVisitFinalized}>
                            <span>
                                <Button
                                    variant='contained'
                                    disabled
                                >
                                    {i18n.modifVisit}
                                </Button>
                            </span>
                        </Tooltip>
                    ) : (
                        <Button
                            variant='contained'
                            onClick={() => dispatch(push(`/installation/campaign/${campaignAction.idCampaign}/visit/${campaignAction.idInstallation}/5`))}
                        >
                            {i18n.modifVisit}
                        </Button>
                    )
                }
            </Grid>
            <Grid className='center'>
                <Button
                    variant='contained'
                    onClick={() => {
                        dispatch(InstallationAction.getEditionInstallation(campaignAction.idCampaign, campaignAction.idInstallation)).then(json => {
                            downloadURI(json.targetPath)
                        })
                    }}
                >
                    {i18n.exportVisit}
                </Button>
            </Grid>
            <Grid className='center'>
                <Button
                    variant='contained'
                    onClick={() => dispatch(push(`/installation/campaign/${campaignAction.idCampaign}/visit/${campaignAction.idInstallation}/operatorFeedback`))}
                >
                    {i18n.operatorFeedback}
                </Button>
            </Grid>
        </Grid>
    )
}

PopupActions.propTypes = {
    campaignAction: PropTypes.instanceOf(DtoCampaignAction),
    visit: PropTypes.instanceOf(DtoVisit),
    setClose: PropTypes.func,
}

const CampaignActionPopup = ({
    campaignAction = {},
    dataLoaded = false,
    open = false,
    setClose = {},
}) => {
    const {
        visit,
        sandreCodes,
        installationsTypes,
    } = useSelector((store) => ({
        visit: store.InstallationReducer.visit,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    const installationName = getInstallationName(installationsTypes, campaignAction)

    return (
        <DialogMUI open={open} fullWidth maxWidth='lg'>
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item >
                        {installationName}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={setClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <PopupTitle
                    campaignAction={campaignAction}
                    visit={visit}
                    sandreCodes={sandreCodes}
                />
                {
                    dataLoaded && <InstVisitActions visit={visit} /> || <ProgressBar indeterminate withMessage />
                }
            </DialogContentMUI>
            <DialogActionsMUI>
                <PopupActions
                    campaignAction={campaignAction}
                    visit={visit}
                    setClose={setClose}
                />
            </DialogActionsMUI>
        </DialogMUI>
    )
}

CampaignActionPopup.propTypes = {
    campaignAction: PropTypes.instanceOf(DtoCampaignAction),
    dataLoaded: PropTypes.bool,
    open: PropTypes.bool,
    setClose: PropTypes.func,
}

export default CampaignActionPopup