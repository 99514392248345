import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'
import i18n from 'simple-react-i18n'
import SieauAction from '../../components/sieau/SieauAction'
import { hasValue } from '../../utils/NumberUtil'
import { orderBy } from 'lodash'
import User from '../../account/dto/User'
import {
    PATH_REFERENCIAL_AQUIFER,
    PATH_REFERENCIAL_CITY,
    PATH_REFERENCIAL_CONTACT,
    PATH_REFERENCIAL_CONTRIBUTOR,
    PATH_REFERENCIAL_CULTURE,
    PATH_REFERENCIAL_DASHBOARD,
    PATH_REFERENCIAL_FRACTION,
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
    PATH_REFERENCIAL_METHOD,
    PATH_REFERENCIAL_NETWORK,
    PATH_REFERENCIAL_PARAMETER,
    PATH_REFERENCIAL_SECTOR,
    PATH_REFERENCIAL_SUPPORT,
    PATH_REFERENCIAL_TAXON,
    PATH_REFERENCIAL_UNIT_ENTITY,
    PATH_REFERENCIAL_UNIT_MANAGEMENT,
    PATH_REFERENCIAL_WATERMASS,
    PATH_REFERENCIAL_WATERSHED,
} from '../../home/constants/RouteConstants'
import { goBack } from 'connected-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import {
    H_REFERENCIAL_ACTIVITY,
    H_REFERENCIAL_AQUIFER,
    H_REFERENCIAL_CITY,
    H_REFERENCIAL_CONTACT,
    H_REFERENCIAL_CONTRIBUTOR,
    H_REFERENCIAL_CULTURE,
    H_REFERENCIAL_DASHBOARD,
    H_REFERENCIAL_FRACTION,
    H_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
    H_REFERENCIAL_LEXICONS,
    H_REFERENCIAL_MAT_TYPES,
    H_REFERENCIAL_METHOD,
    H_REFERENCIAL_NETWORK,
    H_REFERENCIAL_PARAMETER,
    H_REFERENCIAL_RPG,
    H_REFERENCIAL_SANDRE_CODES,
    H_REFERENCIAL_SECTOR,
    H_REFERENCIAL_SUPPORT,
    H_REFERENCIAL_TAXON,
    H_REFERENCIAL_UNIT,
    H_REFERENCIAL_UNIT_MANAGMENT,
    H_REFERENCIAL_WATERMASS,
    H_REFERENCIAL_WATERSHED,
} from '../../account/constants/AccessRulesConstants'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import { Redirect, Route, Switch } from 'react-router-dom'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import ActivitiesApp from './activity/components/ActivitiesApp'
import CulturesApp from './cultures/components/CulturesApp'
import ManagementUnitsApp from './managementUnit/components/ManagementUnitsApp'
import MatTypesApp from './matTypes/MatTypesApp'
import LexiconsApp from './lexicons/LexiconsApp'
import SandreCodesApp from './sandreCodes/SandreCodesApp'
import WatershedsApp from './watershed/components/WatershedsApp'
import WatermassesApp from './watermass/components/WatermassesApp'
import UnitsApp from './unit/components/UnitsApp'
import FractionsApp from './fraction/components/FractionsApp'
import NetworksApp from './network/components/NetworksApp'
import HydrogeologicalEntitiesApp from './hydrogeologicalEntity/components/HydrogeologicalEntitiesApp'
import ContactsApp from './contact/components/ContactsApp'
import AquifersApp from './aquifers/components/AquifersApp'
import CitiesApp from './city/components/CitiesApp'
import ContributorsApp from './contributor/components/ContributorsApp'
import SupportsApp from './support/components/SupportsApp'
import MethodsApp from './methods/components/MethodsApp'
import TaxonsApp from './taxon/components/TaxonsApp'
import ParametersApp from './parameter/components/ParametersApp'
import ReferencialDashboardApp from './dashboard/components/ReferencialDashboardApp'
import CountriesApp from './country/components/CountriesApp'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import RPGApp from './cultures/components/RPGApp'
import SectorsApp from './sector/components/SectorsApp'

class ReferencialApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEnableAccess: false,
        }
    }

    componentDidMount() {
        const { accountUser } = this.props
        this.checkAbillitation(accountUser)
        this.props.setHelpLink('referentiels', '')
    }

    componentDidUpdate(nextProps) {
        const { match, accountUser } = this.props
        if (accountUser !== nextProps.accountUser) {
            this.checkAbillitation(nextProps.accountUser)
        }
        if (match.params.code !== nextProps.match.params.code) {
            this.props.reset('referencial')
        }
    }

    checkAbillitation = (user) => {
        if (user && hasValue(user.login)) {
            if (user.metadata === '1' || user.isAdmin === '1') {
                this.setState({
                    isEnableAccess: true,
                })
            } else {
                this.props.goBack()
                this.props.error(i18n.AccessRightDeny)
            }
        }
    }

    isEditMode = (isEditMode) => {
        this.setState({
            isEditMode,
        })
    }

    getDashboardLinks = () => {
        const dashboardLink = [
            {
                href: PATH_REFERENCIAL_DASHBOARD,
                icons: 'dashboard',
                name: i18n.dashboard,
                habilitation: H_REFERENCIAL_DASHBOARD,
            }]
        const referencialList = orderBy([
            {
                href: PATH_REFERENCIAL_CITY,
                icons: 'location_city',
                name: i18n.cities,
                habilitation: H_REFERENCIAL_CITY,
            },
            {
                href: PATH_REFERENCIAL_CONTACT,
                icons: 'contacts',
                name: i18n.contacts,
                habilitation: H_REFERENCIAL_CONTACT,
            },
            {
                href: PATH_REFERENCIAL_NETWORK,
                icons: 'language',
                name: i18n.networks,
                habilitation: H_REFERENCIAL_NETWORK,
            },
            {
                href: PATH_REFERENCIAL_FRACTION,
                icons: 'gradient',
                name: i18n.fractions,
                habilitation: H_REFERENCIAL_FRACTION,
            },
            {
                href: PATH_REFERENCIAL_CONTRIBUTOR,
                icons: 'domain',
                name: i18n.contributors,
                habilitation: H_REFERENCIAL_CONTRIBUTOR,
            },
            {
                href: PATH_REFERENCIAL_WATERMASS,
                icons: 'blur_circular',
                name: i18n.watermasses,
                habilitation: H_REFERENCIAL_WATERMASS,
            },
            {
                href: PATH_REFERENCIAL_METHOD,
                icons: 'style',
                name: i18n.methods,
                habilitation: H_REFERENCIAL_METHOD,
            },
            {
                href: PATH_REFERENCIAL_PARAMETER,
                icons: 'bubble_chart',
                name: i18n.parameters,
                habilitation: H_REFERENCIAL_PARAMETER,
            },
            {
                href: PATH_REFERENCIAL_TAXON,
                icons: 'bug_report',
                name: i18n.taxon,
                habilitation: H_REFERENCIAL_TAXON,
            },
            {
                href: PATH_REFERENCIAL_SUPPORT,
                icons: 'widgets',
                name: i18n.supports,
                habilitation: H_REFERENCIAL_SUPPORT,
            },
            {
                href: PATH_REFERENCIAL_UNIT_ENTITY,
                icons: 'nature',
                name: i18n.units,
                habilitation: H_REFERENCIAL_UNIT,
            },
            {
                href: PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
                icons: 'explicit',
                name: i18n.hydrogeologicalEntities,
                habilitation: H_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
            },
            {
                href: PATH_REFERENCIAL_WATERSHED,
                icons: 'landscape',
                name: i18n.watersheds,
                habilitation: H_REFERENCIAL_WATERSHED,
            },
            {
                href: PATH_REFERENCIAL_AQUIFER,
                icons: 'line_weight',
                name: i18n.aquifers,
                habilitation: H_REFERENCIAL_AQUIFER,
            },
            {
                href: '/referencial/sandreCodes',
                icons: 'list',
                name: i18n.sandreNomenclature,
                habilitation: H_REFERENCIAL_SANDRE_CODES,
            },
            {
                href: '/referencial/lexicons',
                icons: 'list',
                name: i18n.lexicons,
                habilitation: H_REFERENCIAL_LEXICONS,
            },
            {
                href: '/referencial/materielTypes',
                icons: 'router',
                name: i18n.materielTypes,
                habilitation: H_REFERENCIAL_MAT_TYPES,
            },
            {
                href: PATH_REFERENCIAL_UNIT_MANAGEMENT,
                icons: 'device_hub',
                name: i18n.managementUnit,
                habilitation: H_REFERENCIAL_UNIT_MANAGMENT,
            },
            {
                href: PATH_REFERENCIAL_CULTURE,
                icons: 'local_florist',
                name: i18n.cultures,
                habilitation: H_REFERENCIAL_CULTURE,
            },
            {
                href: '/referencial/activity',
                icons: 'list',
                name: i18n.activities,
                habilitation: H_REFERENCIAL_ACTIVITY,
            },
            {
                href: '/referencial/rpg',
                icons: 'border_all',
                name: 'RPG',
                habilitation: H_REFERENCIAL_RPG,
            },
            {
                href: PATH_REFERENCIAL_SECTOR,
                icons: 'location_searching',
                name: i18n.sectors,
                habilitation: H_REFERENCIAL_SECTOR,
            },
        ], 'name', 'asc')
        return [...dashboardLink, ...referencialList].filter(r => componentHasHabilitations(r.habilitation))
    }

    render() {
        const navBarLinks = this.getDashboardLinks()
        return (
            <div>
                <FixedSlideNav links={ navBarLinks } />
                <div className='content-page no-padding fullscreen'>
                    <div className='row no-margin'>
                        <div className='col s12 no-padding'>
                            <BoundaryError>
                                <Switch>
                                    <Redirect exact path='/referencial' to='/referencial/dashboard' />
                                    <Route path='/referencial/dashboard' render={(props) => <ReferencialDashboardApp {...props} />} />
                                    <Route path='/referencial/parameter' render={(props) => <ParametersApp {...props} />} key='parameter' />
                                    <Route path='/referencial/taxon' render={(props) => <TaxonsApp {...props} />} />
                                    <Route path='/referencial/method' render={(props) => <MethodsApp {...props} />} />
                                    <Route path='/referencial/support' render={(props) => <SupportsApp {...props} />} />
                                    <Route path='/referencial/contributor' render={(props) => <ContributorsApp {...props} />} />
                                    <Route path='/referencial/city' render={(props) => <CitiesApp {...props} />} />
                                    <Route path='/referencial/aquifer' render={(props) => <AquifersApp {...props} />} />
                                    <Route path='/referencial/contact' render={(props) => <ContactsApp {...props} />} />
                                    <Route path='/referencial/hydrogeologicalEntity' render={(props) => <HydrogeologicalEntitiesApp {...props} />} />
                                    <Route path='/referencial/network' render={(props) => <NetworksApp {...props} />} />
                                    <Route path='/referencial/fraction' render={(props) => <FractionsApp {...props} />} />
                                    <Route path='/referencial/unit' render={(props) => <UnitsApp {...props} />} />
                                    <Route path='/referencial/watermass' render={(props) => <WatermassesApp {...props} />} />
                                    <Route path='/referencial/watershed' render={(props) => <WatershedsApp {...props} />} />
                                    <Route path='/referencial/sandreCodes' render={(props) => <SandreCodesApp {...props} />} />
                                    <Route path='/referencial/lexicons' render={(props) => <LexiconsApp {...props} />} />
                                    <Route path='/referencial/rpg' render={(props) => <RPGApp {...props} />} />
                                    <Route path='/referencial/materielTypes' render={(props) => <MatTypesApp {...props} />} />
                                    <Route path='/referencial/managementUnit' render={(props) => <ManagementUnitsApp {...props} />} />
                                    <Route path='/referencial/country' render={(props) => <CountriesApp {...props} />} />
                                    <Route path='/referencial/culture' render={(props) => <CulturesApp {...props} />} />
                                    <Route path='/referencial/activity' render={(props) => <ActivitiesApp {...props} />} />
                                    <Route path='/referencial/develop' render={(props) => <DevelopmentApp {...props} />} />
                                    <Route path='/referencial/sector' render={(props) => <SectorsApp {...props} />} />
                                </Switch>
                            </BoundaryError>
                            {/* { this.props.children && React.cloneElement(
                                this.props.children,
                                {
                                    getLink: this.props.getLink,
                                    isEnableEdit: this.state.isEnableEdit,
                                    changeEditMode: this.isEditMode,
                                    isEditMode: this.state.isEditMode,
                                    isEnableAccess: this.state.isEnableAccess
                                }
                            ) } */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ReferencialApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            type: PropTypes.string,
            code: PropTypes.string,
        }),
    }),
    children: PropTypes.element,
    getLink: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
    setHelpLink: PropTypes.func,
    reset: PropTypes.func,
    goBack: PropTypes.func,
    error: PropTypes.func,
}

const mapStateToProps = store => ({
    accountUser: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    setHelpLink: HomeAction.setHelpLink,
    reset: SieauAction.reset,
    error: ToastrAction.error,
    goBack,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencialApp)
