import { H_REFERENCIAL_PARAMETER } from 'account/constants/AccessRulesConstants'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import { checkAbillitation } from 'referencial/util/ReferencialUtils'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import ParameterApp from './ParameterApp'
import ParameterMapApp from './ParameterMapApp'
import ParameterSourcePanel from './ParameterSourcePanel'
import { goBack } from 'connected-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import ParameterAction from '../actions/ParameterAction'

const ParameterRouterApp = ({ match: { params: { code } } }) => {
    const {
        user,
    } = useSelector(store => ({
        user: store.UserReducer.user,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [isEnableEdit, setisEnableEdit] = useState(false)
    const [isEnableAccess, setIsEnableAccess] = useState(false)

    const navLinks = useMemo(() => {
        const dashboardLink = [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: `/referencial/${REFERENCIAL_TYPE_NAME.parameter}/${code}/dashboard`,
            habilitation: H_REFERENCIAL_PARAMETER,
        }].filter(l => componentHasHabilitations(l.habilitation))

        if (code === 'new') {
            return dashboardLink
        }
        return [
            ...dashboardLink,
            {
                icons: 'map',
                name: i18n.cartography,
                href: `/referencial/${REFERENCIAL_TYPE_NAME.parameter}/${code}/map`,
                habilitation: H_REFERENCIAL_PARAMETER,
            }, {
                icons: 'water',
                name: i18n.source,
                href: `/referencial/${REFERENCIAL_TYPE_NAME.parameter}/${code}/source`,
                habilitation: H_REFERENCIAL_PARAMETER,
            },
        ].filter(l => componentHasHabilitations(l.habilitation))
    }, [code])

    useEffect(() => {
        checkAbillitation(user, () => {
            setisEnableEdit(true)
            setIsEnableAccess(true)
        }, () => {
            goBack()
            dispatch(ToastrAction.error(i18n.AccessRightDeny))
        })
    })

    useEffect(() => {
        dispatch(ParameterAction.fetchParameters())
        dispatch(ParameterAction.fetchParameter(code))
    }, [dispatch, code])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Switch>
                                <Redirect exact from='/referencial/parameter/:code' to='/referencial/parameter/:code/dashboard' />
                                <Route path='/referencial/parameter/:code/dashboard' render={(props) => <ParameterApp {...props} isEnableEdit={isEnableEdit} isEnableAccess={isEnableAccess} key='parameterId' />} />
                                <Route path='/referencial/parameter/:code/map' render={(props) => <ParameterMapApp {...props} />} />
                                <Route path='/referencial/parameter/:code/source' render={(props) => <ParameterSourcePanel {...props} />} />
                            </Switch>
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

ParameterRouterApp.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            code: PropTypes.string,
        }),
    }),
}

export default ParameterRouterApp