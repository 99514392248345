import ApplicationConf from 'conf/ApplicationConf'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import LogAction from '../../../../log/actions/LogAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import { RECEIVE_ACTIVITY, RECEIVE_ALL_ACTIVITY, RESET_ACTIVITY } from '../constants/ActivityConstants'

const ActivityAction = {

    receiveActivity(activity) {
        return { type: RECEIVE_ACTIVITY, activity }
    },

    receiveActivities(activities) {
        return { type: RECEIVE_ALL_ACTIVITY, activities }
    },

    promiseActivities() {
        return fetch(ApplicationConf.referencial.activities(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchActivity(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.activity(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(ActivityAction.receiveActivity(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.activity} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.activity))
                })
        }
    },

    fetchActivities() {
        return (dispatch) => {
            return ActivityAction.promiseActivities()
                .then((json = []) => {
                    dispatch(ActivityAction.receiveActivities(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.activities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.activities))
                })
        }
    },

    updateActivity(activity, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveActivity(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(activity),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update || json.insert) {
                        dispatch(ActivityAction.fetchActivity(activity.codeactivite))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        dispatch(push(`/referencial/activity/${activity.codeactivite}`))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.activity} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.activity))
                })
        }
    },

    createActivity(activity) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.activities(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(activity),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(`/referencial/activity/${activity.codeactivite}`))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.activity} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.activity))
                })
        }
    },

    deleteActivity(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteActivity(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push('/referencial/activity/'))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.activity} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.activity))
                })
        }
    },

    purgeActivity() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeActivity(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(ActivityAction.fetchActivities())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.activities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.activities))
                })
        }
    },

    reset() {
        return { type: RESET_ACTIVITY }
    },

}

export default ActivityAction
