import { Button, Dialog, Grid } from '@mui/material'
import ColorPicker from 'components/forms/ColorPicker'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Textarea from 'components/forms/Textarea'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoWaterTurnsRestriction from 'territory/dto/DtoWaterTurnsRestriction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'

const TerritoryWaterTurnsRestrictionsModal = ({
    openModal = false,
    isNew = false,
    restriction = {},
    onUpdate = () => { },
    setOpenModal = () => { },
}) => {
    const dispatch = useDispatch()

    const [level, setLevel] = useState(null)
    const [label, setLabel] = useState(null)
    const [description, setDescription] = useState(null)
    const [comment, setComment] = useState(null)
    const [color, setColor] = useState(null)

    useEffect(() => {
        setLevel(restriction.level)
        setLabel(restriction.label)
        setDescription(restriction.description)
        setComment(restriction.comment)
        setColor(restriction.color)
    }, [restriction])

    const onValidate = () => {
        if (hasValue(level)) {
            const newService = new DtoWaterTurnsRestriction({
                id: restriction.id,
                level,
                label,
                description,
                comment,
                color,
            })
            onUpdate(newService)
        } else {
            dispatch(ToastrAction.warning(i18n.pleaseCompleteAllRequiredField))
        }
    }

    return (
        <Dialog open={openModal} maxWidth='lg'>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {isNew ? i18n.newRestriction : i18n.editRestriction}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpenModal(false)} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container className='padding-top-1' style={{ minHeight: '350px' }} alignContent='flex-start'>
                    <Grid item xs={6} className='padding-right-1'>
                        <NumberField
                            col={12}
                            title={i18n.level}
                            value={level}
                            onChange={setLevel}
                            obligatory
                        />
                    </Grid>
                    <Grid item xs={6} className='padding-left-1'>
                        <Input
                            col={12}
                            title={i18n.label}
                            value={label}
                            onChange={setLabel}
                        />
                    </Grid>
                    <Grid item xs={6} className='padding-right-1'>
                        <Textarea
                            col={12}
                            title={i18n.description}
                            value={description}
                            onChange={setDescription}
                        />
                    </Grid>
                    <Grid item xs={6} className='padding-left-1'>
                        <Textarea
                            col={12}
                            title={i18n.comment}
                            value={comment}
                            onChange={setComment}
                        />
                    </Grid>
                    <Grid item xs={3} className='padding-top-1'>
                        <ColorPicker
                            label={i18n.color}
                            value={color}
                            onChange={setColor}
                        />
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={() => onValidate()} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

TerritoryWaterTurnsRestrictionsModal.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    isNew: PropTypes.bool,
    onUpdate: PropTypes.func,
    restriction: PropTypes.instanceOf(DtoWaterTurnsRestriction),
}

export default TerritoryWaterTurnsRestrictionsModal
