import { push } from 'connected-react-router'
import { isEqual, omit, sortBy } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import ActionComponent from '../../../../components/ActionComponent'
import Card from '../../../../components/card/Card'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import SieauAction from '../../../../components/sieau/SieauAction'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CULTURE,
    PATH_REFERENCIAL_CULTURES_FAMILY_LINK,
    PATH_REFERENCIAL_CULTURES_FAMILY_NEW,
} from '../../../../home/constants/RouteConstants'
import { getExport } from '../../../../utils/linkUtils'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import { nbPerPageLabel } from '../../../constants/ReferencialConstants'
import CultureAction from '../../cultures/actions/CultureAction'
import { seasonList } from '../../cultures/constants/CultureConstants'
import DtoCulture from '../../cultures/dto/DtoCulture'
import CulturesFamilyAction from '../actions/CulturesFamilyAction'
import DtoCulturesFamily from '../dto/DtoCulturesFamily'
import SelectCulturesModal from './SelectCulturesModal'

moment.locale('fr')

const headersCulturesList = ['id', 'family', 'name', 'teteRotationIcon', 'legumineuseIcon', 'periodeSemence', 'referencialIcon']
const headersCulturesExport = ['id', 'family', 'name', 'teteRotation', 'legumineuse', 'periodeSemence', 'referencial']

class CulturesFamilyApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            isEditMode: props.isEditMode,
            culturesFamily: {},
            openModalSelectCulture: false,
            culturesLoaded: false,
            culturesIds: [],
        }
    }

    componentDidMount() {
        const { params } = this.props.match
        if (params.id !== 'new') {
            this.changeEditMode(false)
            this.props.fetchCulturesFamily(params.id)
        } else {
            this.changeEditMode(true)
        }
        this.props.fetchCulturesFamilies()
        this.props.fetchCultures().then(() => this.setCultures())
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarCulturesFamily()
    }

    setCultures = () => {
        const { params } = this.props.match
        if (params.id !== 'new') {
            const { cultures } = this.props
            const culturesIds = cultures.filter((c) => c.idFamille && c.idFamille === parseInt(params.id)).map((c) => c.id)
            this.setState({ culturesIds, culturesLoaded: true })
        }
    }

    changeStateAttribute = (attr, value) => {
        this.setState(({ culturesFamily }) => ({
            culturesFamily: {
                ...culturesFamily,
                [attr]: value,
            },
        }))
    }

    componentWillUnmount = () => this.props.resetCulturesFamily()

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    toNavbarCulturesFamily() {
        const { params } = this.props.match
        const { isEditMode } = this.state
        const actions = (() => {
            if (params.id === 'new' && (!this.props.culturesFamily || !this.props.culturesFamily.id)) {
                return {
                    save: () => this.onCreate(),
                    cancel: () => {
                        this.props.push(PATH_REFERENCIAL_CULTURE)
                        this.changeEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => this.onSaveUpdate(),
                    cancel: () => this.onCancelUpdate(),
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    onCancelUpdate = () => {
        const { culturesFamily } = this.props
        this.setState({ culturesLoaded: false, culturesFamily })
        this.setCultures()
        this.changeEditMode(false)
    }

    onCreate = () => {
        const { culturesFamily } = this.state
        this.props.createCulturesFamily(culturesFamily)
        this.props.fetchCultures().then(() => {
            this.setCultures()
        })
        this.changeEditMode(false)
    }

    onSaveUpdate = () => {
        const { culturesFamily, culturesIds } = this.state
        this.setState({ culturesLoaded: false })
        this.props.updateCulturesFamily(culturesFamily, culturesFamily.id)
        this.props.updateFamilyCultures(culturesFamily.id, culturesIds).then(() => {
            this.props.fetchCultures().then(() => {
                this.setCultures()
            })
        })
        this.changeEditMode(false)
    }

    componentDidUpdate = prevProps => {
        const { culturesFamily } = this.props
        const { params } = this.props.match
        const { isEditMode } = this.state
        if (!isEqual(prevProps.culturesFamily, culturesFamily)) {
            this.setState({ culturesFamily })
        }
        if (!isEqual(isEditMode, prevProps.isEditMode)) {
            this.toNavbarCulturesFamily()
        }
        if (prevProps.match.params.id !== params.id) {
            this.props.fetchCulturesFamily(params.id)
        } else if (params.id === 'new') {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.culturesFamilies,
                    href: PATH_REFERENCIAL_CULTURE,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_CULTURES_FAMILY_NEW,
                },
            ])
        } else if (!isEqual(prevProps.culturesFamily, culturesFamily)) {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.culturesFamilies,
                    href: PATH_REFERENCIAL_CULTURE,
                },
                {
                    title: params.id + (culturesFamily.name ? ` - ${culturesFamily.name}` : ''),
                    href: PATH_REFERENCIAL_CULTURES_FAMILY_LINK + params.id,
                },
            ])
            this.setState({ culturesFamily })
            this.toNavbarCulturesFamily()
        }
    }

    getCulturesTables = () => {
        const { cultures, culturesFamily } = this.props
        const { params } = this.props.match
        const { culturesIds } = this.state
        if (params.id !== 'new') {
            const { isEditMode } = this.state
            const formattedCultures = cultures.filter((c) => culturesIds.includes(c.id)).map((c) => {
                const periode = seasonList.find(({ value }) => value === c.periodeSemence) || {}
                return {
                    ...c,
                    periodeSemence: periode.label,
                    family: culturesFamily.name,
                    teteRotationIcon: c.teteRotation ? (<Checkbox col={ 12 } checked disabled />) : '',
                    legumineuseIcon: c.legumineuse ? (<Checkbox col={ 12 } checked disabled />) : '',
                    referencialIcon: c.referentiel ? (<Checkbox col={ 12 } checked disabled />) : '',
                    teteRotation: c.teteRotation ? i18n.yes : i18n.no,
                    legumineuse: c.legumineuse ? i18n.yes : i18n.no,
                    referencial: c.referentiel ? i18n.yes : i18n.no,
                    headers: headersCulturesExport,
                }
            })
            const actions = [!isEditMode ?
                getExport(formattedCultures, `${culturesFamily.name}-${i18n.cultures}`, headersCulturesExport)
                : {
                    tooltip: i18n.select,
                    iconName: 'compare_arrows',
                    onClick: () => this.setState({ openModalSelectCulture: true }),
                },
            ]
            return (
                <div className='margin-top-1' style={{ maxWidth: 1000, margin: 'auto' }}>
                    <Table
                        title={formattedCultures.length > 1 ? i18n.cultures : i18n.culture}
                        data={sortBy(formattedCultures, 'id')}
                        actions={actions}
                        paging
                        nbPerPageLabel={ nbPerPageLabel }
                        type={{ headers: headersCulturesList }}
                        customHeaders={{
                            teteRotationIcon: i18n.teteRotation,
                            legumineuseIcon: i18n.legumineuse,
                            referencialIcon: i18n.referencial,
                        }}
                        condensed
                        sortable
                        initialSort
                    />
                </div>
            )
        }
        return null
    }

    getSelectCulturesModal = () => {
        const { openModalSelectCulture, culturesIds } = this.state
        return openModalSelectCulture && (
            <SelectCulturesModal
                selectedIds={culturesIds}
                open={ openModalSelectCulture }
                onCancel={() => this.setState({ openModalSelectCulture: false }) }
                onValidate={this.onValidateSelectCultures}
            />
        )
    }

    onValidateSelectCultures = (selectedCultures) => {
        const culturesIds = selectedCultures.map((c) => c.id)
        this.setState({ openModalSelectCulture: false, culturesIds })
    }

    render() {
        const { isEditMode, culturesFamily, culturesLoaded } = this.state
        const disabled = {
            active: isEditMode,
            disabled: !isEditMode,
        }

        return (
            <div className='row no-margin padding-1'>
                <Card title={i18n.description} maxWidth={1000}>
                    <div className='padding-1'>
                        <div className='row no-margin padding-top-3-px'>
                            <Input
                                col={6}
                                title={i18n.name}
                                value={culturesFamily.name}
                                onChange={value => this.changeStateAttribute('name', value)}
                                maxLength={255}
                                {...disabled}
                            />
                            <NumberField
                                col={6}
                                title={i18n.id}
                                value={culturesFamily.id}
                                onChange={value => {
                                    this.changeStateAttribute('id', value)
                                }}
                                disabled
                            />
                        </div>
                    </div>
                </Card>
                {culturesLoaded && this.getCulturesTables()}
                {this.getSelectCulturesModal()}
            </div>
        )
    }
}

CulturesFamilyApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    culturesFamily: PropTypes.instanceOf(DtoCulturesFamily),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    getLink: PropTypes.func,
    isEnableEdit: PropTypes.bool,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => ({
    cultures: store.CultureReducer.cultures,
    culturesFamily: store.CulturesFamilyReducer.culturesFamily,
})

const mapDispatchToProps = {
    push,
    toastrError: ToastrAction.error,
    setPopup: SieauAction.setPopup,
    fetchCultures: CultureAction.fetchCultures,
    resetCulturesFamily: CulturesFamilyAction.reset,
    createCulturesFamily: CulturesFamilyAction.createCulturesFamily,
    updateCulturesFamily: CulturesFamilyAction.updateCulturesFamily,
    fetchCulturesFamily: CulturesFamilyAction.fetchCulturesFamily,
    fetchCheckDelete: ReferencialAction.fetchCheckDelete,
    updateFamilyCultures: CulturesFamilyAction.updateFamilyCultures,
    fetchCulturesFamilies: CulturesFamilyAction.fetchCulturesFamilies,
    setTitle: HomeAction.setTitle,
}

export default connect(mapStateToProps, mapDispatchToProps)(CulturesFamilyApp)
