import ActionComponent from 'components/ActionComponent'
import { NUMBER } from 'components/keyFigures/KeyFigureConstants'
import { push } from 'connected-react-router'
import { groupBy, maxBy, orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Card from '../../components/card/Card'
import KeyFigurePanel from '../../components/keyFigures/KeyFigurePanel'
import SectionList from '../../components/list/section/SectionList'
import ProgressCircleChart from '../../components/progress/ProgressCircleChart'
import DtoHydrometricStation from '../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../piezometry/dto/DtoPiezometer'
import PluviometerDto from '../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../quality/dto/DtoQualitometerLight'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import { getDate, getHour, getYearOrString } from '../../utils/DateUtil'
import { getPercentage, hasValue } from '../../utils/NumberUtil'
import { getUser } from '../../utils/SettingUtils'
import { getLabel } from '../../utils/StoreUtils'
import { i18nize, searchAllCharacters } from '../../utils/StringUtil'
import { CAMPAIGN_STATUT, CAMPAIGN_TYPES } from '../constants/CampaignConstants'
import CampaignDto from '../dto/CampaignDto'
import DtoCampaignProgress from '../dto/DtoCampaignProgress'
import CampaignCard from './CampaignCard'
import FormFilterCampaigns from './FormFilterCampaigns'

class DashboardCampaigns extends ActionComponent {
    state = {
        filter: {
            searchValue: '',
            startDate: '',
            endDate: '',
            type: null,
            statut: null,
        },
        sortBy: 'statut',
    }

    componentDidMount = () => {
        this.setComponentActions()

        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
    }

    getCampaignExport = () => {
        const data = this.getFilteredCampaigns().map(c => {
            const {
                nbStation = 0,
                nbStationValidated = 0,
            } = this.props.campaignsProgress.find(cp => cp.id === c.id) || {}
            return {
                name: c.name,
                startDate: { value: c.beginningApplication, format: 'dd/MM/yyyy', cellType: 'date' },
                endDate: { value: c.endingApplication, format: 'dd/MM/yyyy', cellType: 'date' },
                stationsNumber: { value: nbStation, format: '0', cellType: 'number' },
                contolStationNumber: { value: nbStationValidated, format: '0', cellType: 'number' },
                progressionPercent: { value: getPercentage(nbStationValidated, nbStation), format: '0', cellType: 'number' },
                lastUpdate: { value: moment(c.updateDate).format('DD/MM/YYYY'), format: 'dd/MM/yyyy', cellType: 'date' },
                lastUpdateLogin: c.updateLogin,
                statuts: getLabel(i18nize(CAMPAIGN_STATUT), c.statut),
                campaignType: getLabel(i18nize(CAMPAIGN_TYPES), c.campaignType) || i18n.unknownType,
            }
        }).sort((a, b) => moment(b.startDate, 'DD/MM/YYYY').valueOf() - moment(a.startDate, 'DD/MM/YYYY').valueOf())
        if (data.length) {
            data[0].headers = Object.keys(data[0])
        }
        return data
    }

    setComponentActions = () => {
        const { stationType } = this.props
        const actions = getUser().consultant === '1' ? {
            export: () => ({
                data: this.getCampaignExport(),
                exportType: 'xlsx',
                titleFile: `${i18n.campaigns}_${i18n[stationType]}`,
            }),
        } : {
            new: () => this.props.push(`/${stationType}/campaign/new/dashboard`),
            export: () => ({
                data: this.getCampaignExport(),
                exportType: 'xlsx',
                titleFile: `${i18n.campaigns}_${i18n[stationType]}`,
            }),
        }
        this.setActions(actions)
    }

    getCampaignCard = campaign => {
        const {
            id,
            contactCode,
        } = campaign
        const contact = this.props.contacts.find(c => contactCode === c.code)
        const progress = this.props.campaignsProgress.find(cp => cp.id === id)
        return (
            <CampaignCard
                campaign={ campaign }
                progress={ progress }
                contact={ contact }
                stationType={ this.props.stationType }
            />
        )
    }

    getHash = campaign => searchAllCharacters(['name', 'laboratory'].map(key => campaign[key]).join() + getDate(campaign.startDate) + getDate(campaign.endDate))

    getFilteredCampaigns = () => {
        const {
            searchValue,
            startDate,
            endDate,
            type,
            statut,
        } = this.state.filter
        const { campaigns } = this.props
        const {
            dataDrafts = [],
            dataCurrent = [],
            dataCompleted = [],
            dataArchived = [],
        } = this.getCampaignGroupByType(campaigns)
        const campaignsByStatus = (() => {
            switch (statut) {
                case 1: return dataDrafts
                case 2: return dataCurrent
                case 3: return dataCompleted
                case 4: return dataArchived
                default: return campaigns
            }
        })()
        const searchCharacters = searchAllCharacters(searchValue)
        const filterStatus = hasValue(searchValue) ? campaignsByStatus.filter(c => this.getHash(c).includes(searchCharacters)) : campaignsByStatus
        const filterStartDate = hasValue(startDate) ? filterStatus.filter(c => +moment(c.beginningApplication, 'DD/MM/YYYY') >= startDate) : filterStatus
        const filterEndDate = hasValue(endDate) ? filterStartDate.filter(c => +moment(c.endingApplication, 'DD/MM/YYYY') <= endDate) : filterStartDate
        return !hasValue(type) || type == -1 ? filterEndDate : filterEndDate.filter(c => c.campaignType == type)
    }

    getPanelList = (groups, title) => Object.keys(groups).map(key => {
        const groupCard = orderBy(groups[key], 'statut').map(c => this.getCampaignCard(c))
        return (
            <SectionList title={ title || key } openFirst>
                { groupCard }
            </SectionList>
        )
    })

    getPanelListStatut = (groups, title) => {
        const groupCard = groups.map(c => this.getCampaignCard(c))
        return (
            <SectionList title={ title } openFirst>
                { groupCard }
            </SectionList>
        )
    }

    getCampaignByReferent = campaigns => {
        const groups = groupBy(
            campaigns,
            c => hasValue(c.contactCode) ? getLabel(this.props.contacts, c.contactCode) : i18n.unknownReferent
        )
        return (
            <div>
                { this.getPanelList(groups) }
            </div>
        )
    }

    getCampaignByType = campaigns => {
        const groups = groupBy(
            campaigns,
            ({ campaignType = 4 }) => campaignType
        )
        const campaignTypes = i18nize(CAMPAIGN_TYPES)
        const list = Object.keys(groups).map(key => {
            const groupCard = groups[key].map(c => this.getCampaignCard(c))
            const title = getLabel(campaignTypes, key) || i18n.unknownType
            return (
                <SectionList title={title || key} openFirst>
                    { groupCard}
                </SectionList>
            )
        })
        return (
            <div>
                { list }
            </div>
        )
    }

    getKeyFigures = ({ nbCampaignNotDraft, nbCampaignValidated, nbStation }) => [{
        title: 'inProgress',
        value: nbCampaignNotDraft - nbCampaignValidated,
        typeValue: NUMBER,
    }, {
        title: 'total',
        value: this.props.campaigns.length,
        typeValue: NUMBER,
    }, {
        title: 'stations',
        value: nbStation,
        typeValue: NUMBER,
    }]

    getLastUpdates = campaigns => {
        const filterCampaignUpdate = campaigns.filter(o => o.updateDate && o.name)
        const lastCampaignUpdate = maxBy(filterCampaignUpdate, 'updateDate')
        if (lastCampaignUpdate) {
            const updateLogin = lastCampaignUpdate.updateLogin ? lastCampaignUpdate.updateLogin : i18n.unknown
            const updateDate = getDate(lastCampaignUpdate.updateDate, 'DD/MM/YYYY')
            const updateHour = getHour(lastCampaignUpdate.updateDate, 'HH:mm')
            return (
                <div className='row no-margin'>
                    <div className='col s12'>
                        <h6>{ lastCampaignUpdate.name }</h6>
                        <p>{ `${i18n.by} ${updateLogin} ${i18n.atDate} ${updateDate} ${i18n.atHour} ${updateHour}` }</p>
                    </div>
                </div>
            )
        }
        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <h6>{ i18n.noUpdate }</h6>
                </div>
            </div>
        )
    }

    getCampaignGroupByType = campaigns => groupBy(campaigns, c => {
        switch (c.statut) {
            case 1: return 'dataDrafts'
            case 2: return 'dataCurrent'
            case 3: return 'dataCompleted'
            case 4: return 'dataArchived'
            default: return 'dataWithoutStatut'
        }
    })

    groupByYear = data => groupBy(data, c => getYearOrString(c.beginningApplication, i18n.thisYear))

    getCampaignByStatut = campaigns => {
        const {
            dataDrafts = [],
            dataCurrent = [],
            dataCompleted = [],
            dataArchived = [],
            dataWithoutStatut = [],
        } = this.getCampaignGroupByType(campaigns)
        return (
            <div>
                { !!dataDrafts.length && this.getPanelListStatut(dataDrafts, i18n.inAnticipation) }
                { !!dataCurrent.length && this.getPanelListStatut(dataCurrent, i18n.inProgress) }
                { !!dataCompleted.length && this.getPanelListStatut(dataCompleted, i18n.finalize) }
                { !!dataArchived.length && this.getPanelListStatut(dataArchived.map(a => ({ ...a, isArchived: true })), i18n.archived) }
                { !!dataWithoutStatut.length && this.getPanelListStatut(dataWithoutStatut, i18n.unknowStatus) }
            </div>
        )
    }

    getCampaignByDate = campaigns => {
        return (
            <div>
                { this.getPanelList(this.groupByYear(campaigns)) }
            </div>
        )
    }

    getProgressCircleChart = (title, total, nb) => (
        <Card title={ title }>
            <ProgressCircleChart
                total={ total }
                complete={ nb }
                title={ `${getPercentage(nb, total)} %` }
            />
        </Card>
    )

    getEmpty() {
        return (
            <div style={ { background: 'white', border: '1px solid #35609F' } }>
                <div style={ { padding: '10%' } }>
                    <div className='col s12 text-align-center'>
                        <i className='material-icons medium'>nature_people</i>
                    </div>
                    <div className='center font-size-20'>{ i18n.NoneResultForThisResearch }</div>
                </div>
            </div>
        )
    }

    render = () => {
        const {
            filter,
            sortBy,
        } = this.state
        const {
            campaigns,
            campaignsProgress,
            stationType,
        } = this.props
        const keyFigures = campaignsProgress.reduce((acc, cp) => {
            const camp = campaigns.find(c => c.id === cp.id)
            return {
                nbStation: acc.nbStation + cp.nbStation,
                nbStationValidated: acc.nbStationValidated + cp.nbStationValidated,
                nbCampaignValidated: camp && camp.statut !== 1 && cp.nbStation !== 0 && cp.nbStation === cp.nbStationValidated ? acc.nbCampaignValidated + 1 : acc.nbCampaignValidated,
                progressTotal: acc.progressTotal + cp.progressTotal,
                progress: acc.progress + cp.progress,
            }
        }, { nbStation: 0, nbStationValidated: 0, nbCampaignValidated: 0, progressTotal: 0, progress: 0 })

        const campaignsFilter = this.getFilteredCampaigns()

        const nbCampaignNotDraft = campaigns.filter(c => c.statut !== 1).length

        return (
            <div className='row no-margin padding-left-1'>
                <div className='col s12 m9 padding-top-1'>
                    <FormFilterCampaigns
                        filter={ filter }
                        sortBy={ sortBy }
                        onChange={ obj => this.setState(obj) }
                        onValidate={tmpFilter => this.setState({ filter: tmpFilter }) }
                        stationType={stationType}
                        onReset={ () => this.setState({
                            filter: {
                                searchValue: '', startDate: '', endDate: '', type: null, statut: null,
                            },
                        }) }
                    />
                    { !campaignsFilter.length && (this.state.filter.startDate ||
                        this.state.filter.endDate || this.state.filter.statut ||
                        this.state.filter.type || this.state.filter.searchValue) ?
                        this.getEmpty()
                        :
                        <div className='row' style={ { border: '1px solid #35609F', backgroundColor: 'white' } }>
                            { sortBy === 'referent' && this.getCampaignByReferent(campaignsFilter) }
                            { sortBy === 'type' && this.getCampaignByType(campaignsFilter) }
                            { sortBy === 'date' && this.getCampaignByDate(campaignsFilter) }
                            { sortBy === 'statut' && this.getCampaignByStatut(campaignsFilter) }
                        </div>
                    }
                </div>
                <div className='col s12 m3'>
                    <div className='row no-margin'>
                        <div className='col s12'>
                            <KeyFigurePanel
                                data={ this.getKeyFigures({ ...keyFigures, nbCampaignNotDraft }) }
                                showAllValues
                            />
                        </div>
                        <div className='col s12'>
                            { this.getProgressCircleChart(i18n.campaignsProgress, nbCampaignNotDraft, keyFigures.nbCampaignValidated) }
                            {this.getProgressCircleChart(i18n.visitsProgress, keyFigures.progressTotal, keyFigures.progress) }
                        </div>
                        <div className='col s12'>
                            <div className='card'>
                                <div className='card-title activator'>
                                    { i18n.lastUpdate }
                                </div>
                                <div className='card-content no-padding'>
                                    { this.getLastUpdates(campaignsFilter) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

DashboardCampaigns.propTypes = {
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    campaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    campaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    stationType: PropTypes.string,
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    push,
    toastInfo: ToastrAction.info,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCampaigns)
