import { Button, Grid, Tooltip } from '@mui/material'
import Icon from 'components/icon/Icon'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserAction from '../../administration/components/user/actions/UserAction'
import StationAction from '../../station/actions/StationAction'
import { getLogin } from '../../utils/SettingUtils'
import RequestStep from './userFilter/RequestStep'
import { StyledSpan } from 'components/StyledElements'
import SummaryStep from './userFilter/SummaryStep'
import CountUp from 'react-countup'
import UsersStep from './userFilter/UsersStep'
import UsersDisplay from './UsersDisplay'
import StepperDialog from './StepperDialog'
import { keys } from 'lodash'
import Table from 'components/datatable/Table'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'

const ResultsDisplay = ({
    requestData = [],
}) => {
    const headers = keys(requestData?.[0])
    if (headers.length <= 1) {
        return (
            <Grid item>
                <Grid container justifyContent='center' alignItems='center' >
                    <Grid item>
                        <Icon size='large' icon='manage_search' />
                    </Grid>
                    <Grid item style={{ paddingLeft: '5px' }}>
                        <StyledSpan>{i18n.nbResults} : <CountUp style={{ fontSize: '20px' }} end={requestData.length} duration={1} /></StyledSpan>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid item>
            <Table
                data={requestData}
                type={{ headers }}
                title={i18n.results}
                paging condensed sortable
                nbPerPageLabel={nbPerPageLabelTiny}
            />
        </Grid>
    )
}

ResultsDisplay.propTypes = {
    requestData: PropTypes.shape({}),
}

const STEP = {
    REQUEST: 0,
    USERS: 1,
    SUMMARY: 2,
}

const FilterInsertModal = ({
    selectedFilter = null,
    module = 'SIES',
    isNew = false,
    setOpen = () => { },
    onChange = () => { },
    open = false,
}) => {
    const dispatch = useDispatch()

    const [stateAdministrators, setStateAdministrators] = useState([])
    const [stateUsers, setStateUsers] = useState([])
    const [userOption, setUserOption] = useState(0)
    const [filterData, setFilterData] = useState({})
    const [requestData, setRequestData] = useState([])
    const [openTooltip, setOpenTooltip] = useState(false)

    const {
        filters,
        applicativeUsers,
    } = useSelector(store => ({
        filters: store.StationReducer.filters,
        applicativeUsers: store.UserReducer.applicativeUsers,
    }))
    const dipatch = useDispatch()

    useEffect(() => {
        if (!applicativeUsers.length) {
            dispatch(UserAction.fetchApplicativeUsers())
        }
    }, [])

    const executeFilter = () => {
        dispatch(StationAction.fetchFilterResultsTmp({
            request: filterData.filterSelection,
            parameters: filterData.link_parameters,
        })).then(json => {
            setRequestData(json || [])
        })
    }

    useEffect(() => {
        if (open && !isNew) {
            dispatch(UserAction.fetchUsersOfFilter(module, selectedFilter)).then(() => {
                setFilterData(filters.find(f => f.id === selectedFilter && f.module === module) || {})
            })
        }
        if (filterData.filterSelection) {
            executeFilter()
        }
    }, [open])

    useEffect(() => {
        if (isNew) {
            setStateUsers([])
            setStateAdministrators([])
        }
    }, [isNew])

    const closeDialog = () => {
        setStateAdministrators([])
        setStateUsers([])
        setFilterData({})
        setRequestData([])
        setUserOption(0)
        setOpenTooltip(false)
        setOpen(false)
    }

    const deleteFilter = () => dispatch(StationAction.deleteFilter({ module, code: filterData.codeList })).then(closeDialog)

    const saveFilter = () => {
        const filtersData = {
            ...filterData,
            module,
            loginMaj: getLogin(),
            listUser: userOption === 0 ? [] : stateUsers.map(user => user.login),
            listContributor: userOption === 0 ? [] : stateAdministrators,
        }

        if (userOption === 1 && stateUsers.length === 0 && stateAdministrators.length === 0) {
            dispatch(ToastrAction.warning(i18n.youMustEnterAtLeastOneUser))
        } else if (!filterData.filterSelection) {
            dispatch(ToastrAction.warning(i18n.pleaseCompleteAllRequiredField))
        } else {
            if (isNew) {
                dipatch(StationAction.createFilter(filtersData, v => onChange(v))).then(() => {
                    dispatch(StationAction.fetchFilters())
                })
            } else {
                dispatch(StationAction.updateFilter(filtersData)).then(() => {
                    dispatch(StationAction.fetchFilters())
                })
            }

            closeDialog()
        }
    }

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.request,
                    constant: STEP.REQUEST,
                },
                {
                    label: i18n.users,
                    constant: STEP.USERS,
                },
                {
                    label: i18n.synthesis,
                    constant: STEP.SUMMARY,
                },
            ]}
            open={open}
            title={i18n.filtersUsers}
            closeDialog={closeDialog}
            renderSaveButton={ () =>
                <Button onClick={saveFilter} disabled={!filterData.nameList} variant='contained' color='primary'>{i18n.register}</Button>
            }
            contentStyle={{ padding: '0 12' }}
            renderDeleteButton={() => (
                <Tooltip
                    placement='top-start'
                    arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <Grid style={{ padding: 5 }}>
                            <span style={{ fontSize: '14px' }}>{i18n.sureDeleteFilter}</span>
                            <Grid container justifyContent='flex-end' className='padding-top-1'>
                                <Button variant='outlined' color='primary' className='white margin-right-1' onClick={() => setOpenTooltip(false)}>{i18n.no}</Button>
                                <Button onClick={deleteFilter} variant='contained' >{i18n.yes}</Button>
                            </Grid>
                        </Grid>
                    }
                >
                    <Button
                        onClick={() => setOpenTooltip(true)}
                        disabled={isNew}
                        className={`${!isNew ? 'red' : ''}`}
                        variant='contained'
                    >
                        {i18n.delete}
                    </Button>
                </Tooltip>
            )}
        >
            {
                step => (
                    <>
                        {step === STEP.REQUEST && (
                            <RequestStep
                                filterData={filterData}
                                setFilterData={setFilterData}
                                executeFilter={executeFilter}
                            >
                                <ResultsDisplay requestData={requestData} />
                            </RequestStep>
                        )}
                        {
                            step === STEP.USERS && (
                                <UsersStep
                                    stateUsers={stateUsers}
                                    userOption={userOption}
                                    setUserOption={setUserOption}
                                    stateAdministrators={stateAdministrators}
                                    setStateAdministrators={setStateAdministrators}
                                    setStateUsers={setStateUsers}
                                    isNew={isNew}
                                >
                                    <UsersDisplay
                                        userOption={userOption}
                                        stateAdministrators={stateAdministrators}
                                        stateUsers={stateUsers}
                                    />
                                </UsersStep>
                            )
                        }
                        {
                            step === STEP.SUMMARY && (
                                <SummaryStep
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                >
                                    <ResultsDisplay requestData={requestData} />
                                    <UsersDisplay
                                        userOption={userOption}
                                        stateAdministrators={stateAdministrators}
                                        stateUsers={stateUsers}
                                    />
                                </SummaryStep>
                            )
                        }
                    </>
                )
            }
        </StepperDialog>
    )
}

FilterInsertModal.propTypes = {
    selectedFilter: PropTypes.number,
    module: PropTypes.string,
    open: PropTypes.bool,
    onChange: PropTypes.func,
    setOpen: PropTypes.func,
    isNew: PropTypes.bool,
}


export default FilterInsertModal
