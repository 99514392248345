import { Grid } from '@mui/material'
import UnitChartPanel from 'productionUnit/components/UnitChartPanel'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import LinkedStationsPanel from 'station/components/associatedStation/LinkedStationsPanel'
import StationDescription from 'station/components/description/StationDescription'
import { hasValue } from 'utils/NumberUtil'
import PropTypes from 'prop-types'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { OPTIONS } from 'resource/constants/ResourceConstants'
import { CardFollowUpNitrates } from 'quality/components/qualityComponents/FollowUpNitrates'
import { CardFollowUpPesticides } from 'quality/components/qualityComponents/FollowUpPesticides'
import { getBeginingOfTheYear, getEndOfTheYear } from 'utils/DateUtil'

const ResourceDashboardApp = ({
    resourceId,
}) => {
    const {
        resource,
        associatedSites,
    } = useSelector(store => ({
        resource: store.ResourceReducer.resource,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const [uncheckedStations, setUncheckedStations] = useState([])

    const startPeriod = useApplicationSetting(OPTIONS.RESOURCE_START_PERIOD, setting => setting ? getBeginingOfTheYear(parseInt(setting)) : undefined)
    const endPeriod = useApplicationSetting(OPTIONS.RESOURCE_END_PERIOD, setting => setting ? getEndOfTheYear(parseInt(setting)) : undefined)
    const nitratesThreshold = useApplicationSetting(OPTIONS.RESOURCE_NITRATES_THRESHOLD, setting => setting ? parseFloat(setting) : undefined)
    const pesticideThreshold1 = useApplicationSetting(OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_1, setting => setting ? parseFloat(setting) : undefined)
    const pesticideThreshold2 = useApplicationSetting(OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_2, setting => setting ? parseFloat(setting) : undefined)
    const pesticides = useApplicationSetting(OPTIONS.RESOURCE_PESTICIDES, setting => setting?.split(',').filter(c => !!c) || [])
    const listSumPesticides = useApplicationSetting(OPTIONS.RESOURCE_LIST_SUM_PESTICIDES, setting => {
        const list = setting?.split(',').filter(p => !!p)
        if (list?.length) {
            return list
        }
        return pesticides
    })
    const excludeProducer = useApplicationSetting(OPTIONS.RESOURCE_PRODUCERS_EXCLUDED, setting => setting?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])

    useTitle(() => [{
        title: i18n.resource,
        href: 'resource',
    }, {
        title: getStationTitle(resource),
        href: `station/resource/${resourceId}`,
    }], [resource, resourceId])

    const qualitometerIds = useMemo(() => {
        return associatedSites?.filter(a => a?.typeName === STATION_TYPE_NAME.quality)?.map(q => q?.stationLinkedId) ?? []
    }, [associatedSites])

    return (
        <Grid container style={{ padding: '5 5 100 5' }} spacing={1}>
            <Grid item xs={9}>
                <StationDescription
                    id={resourceId}
                    stationTypes={['resource']}
                    noOwner
                />
                {
                    hasValue(resource.id) && (
                        <UnitChartPanel
                            id={resource.id}
                            uncheckedStations={uncheckedStations}
                            setDataLoaded={() => { }}
                            fromStationType='RESOURCE'
                        />
                    )
                }
            </Grid>
            <Grid item xs={3}>
                <LinkedStationsPanel
                    onReMount={() => { }}
                    dashboardMode
                    checkable
                    changeUncheckedStations={setUncheckedStations}
                    station={resource}
                    withColors
                />
            </Grid>
            {
                !!qualitometerIds?.length && (
                    <Grid item xs={12}>
                        <CardFollowUpNitrates
                            stationIds={qualitometerIds}
                            startPeriod={startPeriod}
                            endPeriod={endPeriod}
                            producerExcluded={excludeProducer}
                            nitratesThreshold={nitratesThreshold}
                        />
                    </Grid>
                )
            }
            {
                !!qualitometerIds?.length && (
                    <Grid item xs={12}>
                        <CardFollowUpPesticides
                            stationIds={qualitometerIds}
                            startPeriod={startPeriod}
                            endPeriod={endPeriod}
                            producerExcluded={excludeProducer}
                            threshold1={pesticideThreshold1}
                            threshold2={pesticideThreshold2}
                            pesticides={pesticides}
                            listSumPesticides={listSumPesticides}
                        />
                    </Grid>
                )
            }
        </Grid>
    )
}

ResourceDashboardApp.propTypes = {
    resourceId: PropTypes.string,
}

export default ResourceDashboardApp