import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { useSelector } from 'react-redux'
import { getExport } from 'utils/linkUtils'
import { getLabel } from 'utils/StoreUtils'
import { orderBy } from 'lodash'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import Table from 'components/datatable/Table'

const headers = ['year', 'producer', 'type', 'result', 'comment']

const DistributionUnitCounterTable = ({
    data = [],
}) => {
    const {
        distributionCounterTypes,
        contributors,
    } = useSelector(store => ({
        distributionCounterTypes: store.DistributionUnitReducer.distributionCounterTypes,
        contributors: store.ContributorReducer.contributors,
    }))

    const distriCounters = orderBy(data, 'year', 'desc').map((c, index) => ({
        ...c,
        index,
    }))

    const tableData = distriCounters.map((dat) => {
        return {
            ...dat,
            type: getLabel(distributionCounterTypes, dat.reportCode, 'reportComment', 'reportCode'),
            comment: dat.reportComment,
            producer: getLabel(contributors, dat.productorCode),
        }
    })

    const actions = [getExport(tableData, i18n.counters, headers)]

    return (
        <>
            <Table
                title={i18n.counters}
                condensed
                data={tableData}
                type={{ headers }}
                sortable={!!tableData.length}
                actions={actions}
                round paging
                nbPerPageLabel={nbPerPageLabel}
            />
        </>
    )
}

DistributionUnitCounterTable.propTypes = {
    data: PropTypes.array,
}

export default DistributionUnitCounterTable