import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import DatePicker from '../../../components/forms/DatePicker'
import Checkbox from '../../../components/forms/Checkbox'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getLogin, getSetting, getUser } from '../../../utils/SettingUtils'
import moment from 'moment'
import { hasValue } from '../../../utils/NumberUtil'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import InstallationInfosPanel from '../installations/components/InstallationInfosPanel'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import InstallationComElecPanel from '../installations/components/InstallationComElecPanel'
import InstallationAccessSitePanel from '../installations/components/InstallationAccessSitePanel'
import InstallationAccessCasingPanel from '../installations/components/InstallationAccessCasingPanel'
import StationLocationInfoPanel from '../../../station/components/location/StationLocationInfoPanel'
import Row from '../../../components/react/Row'
import InstallationStaffSafetyPanel from '../installations/components/InstallationStaffSafetyPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import InstallationSampleCharacteristicsPanel from '../installations/components/InstallationSampleCharacteristicsPanel'
import InstallationOuvragePanel from '../installations/components/InstallationOuvragePanel'
import StationTanksPanel from '../../../station/components/link/StationTanksPanel'
import Select from '../../../components/forms/Select'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import HydrogeologicalEntityAction from '../../../referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import StationAction from '../../../station/actions/StationAction'
import QualityAction from '../../../quality/actions/QualityAction'
import LocalisationMapModal from '../installations/components/modal/LocalisationMapModal'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import { arrayOf } from 'utils/StoreUtils'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import StationSamplersPanel from 'station/components/link/StationSamplersPanel'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import AgriAction from 'agriAdministration/actions/AgriAction'

class AgriPrelDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: { ...props.installation },
            readMode: true,
        }
    }

    componentDidMount() {
        const {
            installation,
            watersheds,
            aquifers,
            hydrogeologicalEntities,
            installationAgriTanks,
            natures,
            fieldModes,
            status,
            agriTanksTypes,
        } = this.props
        if (installation.id) {
            this.setState({ installation })
        }
        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!aquifers.length) {
            this.props.fetchAquifers()
        }
        if (!hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }
        if (!installationAgriTanks.length) {
            this.props.fetchInstallationAgriTanks()
        }
        if (!natures.length) {
            this.props.fetchNatures()
        }
        if (!fieldModes.length) {
            this.props.fetchFieldModes()
        }
        if (!status.length) {
            this.props.fetchStatus()
        }
        if (!agriTanksTypes.length) {
            this.props.fetchTanksTypes()
        }

        // this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
        //     if (this.props.onChangeVisit) {
        //         this.setState({ readMode: false })
        //     } else {
        //         this.setReadOnlyMode()
        //     }
        // })
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => this.props.push('/installation')

    getInstallationObject = (installation) => {
        return {
            ...installation,
            usages: [],
            contributors: [],
            arrangements: [],
            cartographyData: [],
            localisation: {},
        }
    }

    getObjectIfHasChanged = (propsObject, stateObject) => {
        if (!isEqual(propsObject, stateObject)) {
            return stateObject
        }
        return null
    }

    onSave = () => {
        return this.props.updateInstallation(
            this.state.installation
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation })
                this.setReadOnlyMode()
            },
            delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        this.setState({ readMode: true })

        // const { installationVisits } = this.props
        // const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null

        const actions = {
            // ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }
    onChangeInstallation = (newObject) => {
        this.setState({ installation: { ...this.state.installation, ...newObject } })
    }

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, readMode, localizeMap } = this.state
        const { accountHabilitations, installationEvents } = this.props
        const mode = { readMode, editMode: !readMode }
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <div className='row padding-top-1 margin-left-2 padding-bottom-5'>
                <div className='col s9' style={{ marginBottom: '100px' }}>
                    <Card title= { i18n.description } >
                        <InstallationInfosPanel { ...params }/>
                        <Row className='padding-1'>
                            <BuildingsPanel {...params} />
                        </Row>
                    </Card>
                    <Card title= { i18n.characteristics } className='margin-top-1' noMargin={false}>
                        <InstallationSampleCharacteristicsPanel { ...params }/>
                    </Card>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false}>
                        <StationTanksPanel {...params}/>
                    </Row>
                    <Card title={ i18n.communicationElectricity } className='margin-top-1' noMargin={false} displayIf={this.isDisplayed('ELEC_COM')}>
                        <InstallationComElecPanel { ...params }/>
                    </Card>
                    <Card title={ i18n.accessSite } className='margin-top-1' noMargin={false} displayIf={this.isDisplayed('ACCESS_SITE')}>
                        <InstallationAccessSitePanel { ...params }/>
                    </Card>
                    <Card title={ i18n.accessCasing } className='margin-top-1' noMargin={false} displayIf={this.isDisplayed('ACCESS_CASING')}>
                        <InstallationAccessCasingPanel { ...params }/>
                    </Card>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={this.isDisplayed('STAFF_SAFETY')}>
                        <InstallationStaffSafetyPanel { ...params }/>
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={this.isDisplayed('CONTRIBUTORS')}>
                        <StationPiezoContributorPanel {...params}/>
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={this.isDisplayed('USAGES')}>
                        <StationUsagePanel {...params}/>
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={this.isDisplayed('ARRANGEMENTS')}>
                        <StationArrangementPanel {...params}/>
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={accountHabilitations.some((h) => h.habilitation === AGRI) && this.isDisplayed('SAMPLERS')}>
                        <StationSamplersPanel {...params}/>
                    </Row>
                </div>
                <div className='col s3' style={{ marginBottom: '100px' }}>
                    <StationUpdatePanel station={installation}/>
                    <Card noMargin={false} className='margin-top-1 padding-bottom-1'>
                        <Row className={`${!readMode && 'padding-top-1' || ''}`}>
                            <Input col={12} title={i18n.dataOrigin} value={installation.dataOrigin} onChange={v => this.onChangeInstallation({ dataOrigin: v })} {...mode}/>
                        </Row>
                        <Row>
                            <Select
                                col={12}
                                label={i18n.status}
                                value={installation.status}
                                onChange={v => this.onChangeInstallation({
                                    status: v,
                                    statusLogin: getLogin(),
                                    statusDate: moment().valueOf(),
                                })}
                                options={this.props.status}
                                keyValue='code'
                                integerValue
                                {...mode}
                            />
                        </Row>
                        <Row className={`${!readMode && 'padding-bottom-1' || ''}`}>
                            <DatePicker col={12} value={installation.statusDate} onChange={v => this.onChangeInstallation({ statusDate: v })}
                                id='statusDate' title={i18n.controlDoneAt} {...mode}
                            />
                        </Row>
                        <Row>
                            <Select
                                col={12}
                                label={i18n.controlDoneBy}
                                value={installation.statusLogin}
                                onChange={v => this.onChangeInstallation({ statusLogin: v })}
                                options={this.props.users.map(u => ({ code: u.login, name: u.name || u.login }))}
                                keyValue='code'
                                {...mode}
                            />
                        </Row>
                        <Row className={`${!readMode && 'padding-bottom-1' || ''}`}>
                            <DatePicker col={12} value={installation.endDate} id='deliberationDate' title={i18n.deliberationDate}
                                onChange={(_, v) => this.onChangeInstallation({ endDate: v })} {...mode}
                            />
                        </Row>
                        <Row>
                            <Input col={12} value={installation.closeComment} title={i18n.become}
                                onChange={v => this.onChangeInstallation({ closeComment: v })} {...mode}
                            />
                        </Row>
                        <Row>
                            <Checkbox col={12} label={i18n.confidential} checked={hasValue(installation.confidential) ? installation.confidential == '1' : null}
                                onChange={v => this.onChangeInstallation({ confidential: v ? '1' : '0' })} disabled={this.state.readMode}
                            />
                        </Row>
                    </Card>
                    <Row className='padding-top-1'>
                        <Card title={i18n.work} className='padding-bottom-1'>
                            <InstallationOuvragePanel {...params} />
                        </Card>
                    </Row>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={ i18n.contact }
                            contactCode={installation.ownerCode}
                            onChange={ v => this.onChangeInstallation({ ownerCode: v }) }
                            readMode={readMode}
                        />
                    </Row>
                    <LastEventPanel id={installation.id} events={installationEvents} stationId={installation.id}
                        stationType='installation'
                    />
                    <Card
                        title={ i18n.map }
                        noMargin={ false }
                        className='margin-top-1'
                        actions={
                            !this.state.readMode ?
                                [{
                                    iconName: 'my_location',
                                    onClick: () => this.setState({ localizeMap: true }),
                                }] : []
                        }
                    >
                        <StationMapDashboardPanel noMarkerTooltip station={this.props.installation} type={'installation'} />
                        <LocalisationMapModal
                            onChangeInstallation={this.onChangeInstallation}
                            installation={installation}
                            readMode={readMode}
                            open={localizeMap}
                            onClose={ () => this.setState({ localizeMap: false }) }
                        />
                    </Card>
                    <Card title={i18n.localisation} noMargin={false} className='margin-top-1'>
                        <StationLocationInfoPanel
                            onChange={this.onChangeInstallation}
                            onChangeVisit={this.onChangeVisit}
                            station={installation}
                            readMode={readMode}
                        />
                    </Card>
                    <LinkedStationsPanel
                        noMargin={false}
                        className='margin-top-1'
                        onReMount={this.props.onReMount}
                        station={installation}
                        {...mode}
                    />
                </div>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM?.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

AgriPrelDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = store => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    watersheds: store.WatershedReducer.watersheds,
    aquifers: store.AquiferReducer.aquifers,
    hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    installationAgriTanks: store.InstallationReducer.installationAgriTanks,
    natures: store.StationReducer.natures,
    fieldModes: store.StationReducer.fieldModes,
    status: store.QualityReducer.status,
    cities: store.CityReducer.cities,
    users: store.UserReducer.users,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    installation: store.InstallationReducer.installation,
    installationTable: store.InstallationReducer.installationTable,
    // installationVisits: store.InstallationReducer.installationVisits,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    agriTanksTypes: store.AgriReducer.agriTanksTypes,
})

const mapDispatchToProps = {
    push,
    updateInstallation: InstallationAction.updateInstallation,
    info: ToastrAction.info,
    fetch: SieauAction.fetch,
    delete: SieauAction.delete,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationAgriTanks: InstallationAction.fetchInstallationAgriTanks,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchHydrogeologicalEntities: HydrogeologicalEntityAction.fetchHydrogeologicalEntities,
    fetchNatures: StationAction.fetchNatures,
    fetchFieldModes: StationAction.fetchFieldModes,
    fetchStatus: QualityAction.fetchStatus,
    fetchTanksTypes: AgriAction.fetchTanksTypes,
    // fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
}

export default connect(mapStateToProps, mapDispatchToProps)(AgriPrelDescriptionPanel)
