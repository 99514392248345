import { Grid } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import Card from 'components/card/Card'
// import { push } from 'connected-react-router'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import HomeAction from 'home/actions/HomeAction'
import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import DtoService from 'territory/dto/DtoService'
import useActions from 'utils/customHook/useActions'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import TerritoryServiceModal from '../../territory/components/modal/TerritoryServiceModal'

const headers = ['technicalId', 'productCode', 'designation', 'startDate', 'endDate']

const ExploitationsServicesSettingsApp = () => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState(null)
    const [openModal, setOpenModal] = useState(null)
    const [tmpSearch, setTmpSearch] = useState(null)

    const [selectedService, setSelectedService] = useState(new DtoService({}))
    const [isNew, setIsNew] = useState(null)
    const [readMode, setReadMode] = useState(true)

    useEffect(() => {
        dispatch(AgriAction.fetchServices())
        dispatch(HomeAction.setTitle([
            {
                title: i18n.folders,
                href: 'dossiers',
            }, {
                title: i18n.settings,
                href: 'dossiers/settings',
            }, {
                title: i18n.services,
                href: 'dossiers/settings/services',
            },
        ]))
    }, [])

    const {
        services,
    } = useSelector(store => ({
        services: store.AgriReducer.services,
    }), shallowEqual)

    const getHash = (element) => {
        return searchAllCharacters(headers.map(key => element[key]))
    }

    const getData = () => {
        const includesValue = searchAllCharacters(search)
        const filteredData = hasValue(search) ? services.filter(i => getHash(i).includes(includesValue)) : services

        return sortBy(filteredData, 'idService').map(service => ({
            ...service,
            startDate: getDate(service.startDate),
            endDate: getDate(service.endDate),
        }))
    }

    const onExport = () => {
        const data = getData()
        return {
            data: data.length ? [
                { ...data[0], headers },
                ...data.slice(1),
            ] : [],
            titleFile: i18n.services,
        }
    }

    useActions(() => {
        return readMode ? {
            new: () => {
                setIsNew(true)
                setSelectedService({})
                setOpenModal(true)
            },
            export: onExport,
            edit: () => setReadMode(false),
        } : {
            cancel: () => setReadMode(true),
        }
    }, [readMode])

    const onDelete = (service) => {
        dispatch(AgriAction.deleteService(service.idService)).then(() => {
            dispatch(AgriAction.fetchServices())
        })
    }

    return (
        <Grid className='row no-margin padding-1'>
            <Card round>
                <Grid className='row padding-top-1 no-margin'>
                    <Input
                        col={2}
                        title={i18n.search}
                        value={tmpSearch}
                        onChange={setTmpSearch}
                        onEnterKeyPress={setTmpSearch}
                    />
                    <a className='margin-1 waves-effect waves-teal center btn-flat primary-button right' onClick={() => setSearch(tmpSearch)}>
                        {i18n.search}
                    </a>
                </Grid>
            </Card>
            <Grid className='margin-top-1'>
                <Table
                    title={i18n.services}
                    data={getData()}
                    nbPerPageLabel={nbPerPageLabel}
                    type={{ headers }}
                    alterable={!readMode}
                    onAlter={(service) => {
                        setSelectedService(services.find(s => s.idService === service.idService))
                        setOpenModal(true)
                    }}
                    deletable={!readMode}
                    onDelete={onDelete}
                    condensed
                    sortable
                    paging
                    round
                />
            </Grid>
            <TerritoryServiceModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                service={selectedService}
                isNew={isNew}
            />
        </Grid>
    )
}

export default ExploitationsServicesSettingsApp